import { Button, Spinner } from '@material-tailwind/react'
import { observer } from 'mobx-react-lite'
import React, { memo, useEffect, useState } from 'react'

import AuthStore from '../../../stores/AuthStore'
import backendApis from '../../../utils/backendApis'
import CheckInItemComponent from '../item/checkInItemComponent'
import HorizontalList from '../item/horizontalList'
import VerticalList from '../item/verticalList'

const IS_FROM = 'CheckInMain'
const dailyCheckInItemSection = () => {
  const [dealItemsInfo, setDealItemsInfo] = useState([])
  const [loadNum, setLoadNum] = useState(0)
  const [itemObjectList, setItemObjectList] = useState([])
  const [reloadToggle, setReloadToggle] = useState(false)
  const [categoryId, setCategoryId] = useState(-1)
  const SCREEN_WIDTH = window.innerWidth
  const SCREEN_HEIGHT = window.innerHeight

  const loadMore = async () => {
    const currentObjects = itemObjectList.slice(0, 32)
    setItemObjectList((arr) => arr.slice(32))

    // Fetch item info
    const itemsInfoList = await backendApis.getItemsInfoByItemIndices(
      currentObjects.map((item) => parseInt(item?.index, 10)),
      IS_FROM,
    )
    if (!itemsInfoList?.data || itemsInfoList?.data?.length === 0) {
      return
    }

    // Update for reco metadata
    const metaDataMap = new Map(
      currentObjects.map((item) => [item.index, item]),
    )
    const itemsInfoListWithMetaData = itemsInfoList?.data?.map((item) => {
      const metaData = metaDataMap.get(item?.index)
      if (metaData) {
        delete metaData?.index
        return { ...item, ...metaData }
      }
      return item
    })

    setDealItemsInfo((prevItems) => [
      ...prevItems,
      ...itemsInfoListWithMetaData,
    ])

    setLoadNum(loadNum + itemsInfoListWithMetaData.length)
  }

  const fetchItems = async (loadedNum, categoryId = 'total') => {
    const recItemList = await backendApis.getRecommendSkusFromRecServer(
      categoryId === 'total' ? -1 : categoryId,
      null,
      IS_FROM,
    )
    if (recItemList?.status !== 2000 || recItemList?.data?.length === 0) {
      return
    }

    // Convert item objects
    const isObjectList = typeof recItemList?.data[0] === 'object'
    let recItemObjectList = recItemList?.data
    if (!isObjectList) {
      recItemObjectList = recItemList?.data.map((item) => {
        return { index: item }
      })
    }
    if (!recItemObjectList || recItemObjectList?.length === 0) {
      return
    }

    // Fetch item info
    setItemObjectList(recItemObjectList.slice(32)) // 불러올 index는 빼야함.
    const currentObjects = recItemObjectList.slice(loadedNum, loadedNum + 32)
    const itemsInfoList = await backendApis.getItemsInfoByItemIndices(
      currentObjects.map((item) => parseInt(item?.index, 10)),
      IS_FROM,
    )
    if (!itemsInfoList?.data || itemsInfoList?.data?.length === 0) {
      return
    }

    // Update for reco metadata
    const metaDataMap = new Map(
      recItemObjectList.slice(32).map((item) => [item.index, item]),
    )
    const itemsInfoListWithMetaData = itemsInfoList.data.map((item) => {
      const metaData = metaDataMap.get(item?.index)
      if (!metaData) return item
      delete metaData.index
      return { ...item, recoMeta: metaData }
    })

    setDealItemsInfo(itemsInfoListWithMetaData)
    setLoadNum(loadNum + itemsInfoListWithMetaData.length)
  }

  const CategorySelectSection = () => {
    const categoryIds = [-1, 3, 5, 0, 1, 6, 8, 9]
    const CategoryName = (categoryId) => {
      if (categoryId === -1) {
        return '전체'
      }
      if (categoryId === 3) {
        return '식품'
      }
      if (categoryId === 5) {
        return '생활'
      }
      if (categoryId === 0) {
        return '패션'
      }
      if (categoryId === 1) {
        return '뷰티'
      }
      if (categoryId === 6) {
        return '건강'
      }
      if (categoryId === 8) {
        return '가전'
      }
      if (categoryId === 9) {
        return '리빙'
      }
    }
    const renderCategory = memo(({ index, style, data }) => {
      return (
        <div
          key={`${index}`}
          aria-hidden
          onClick={async () => {
            if (categoryId !== data[index]) {
              setCategoryId(data[index])
              await fetchItems(0, data[index])
            }
          }}
          className={`flex flex-1 text-sm py-2 ${
            categoryId === data[index]
              ? 'bg-red-100 text-[#EB4F47] font-bold'
              : 'bg-[#F7F7F7] text-gray-500'
          }  rounded-lg items-center justify-center mx-4`}
          style={{ ...style, width: style?.width - 8 }}
        >
          {CategoryName(data[index])}
        </div>
      )
    })
    return (
      <div className='flex flex-col items-center justify-center flex-1 h-10 mt-2 mr-4'>
        <HorizontalList
          screenWidth={SCREEN_WIDTH}
          itemHeight={28}
          itemWidth={60}
          itemCount={categoryIds?.length || 0}
          itemData={categoryIds}
          renderItem={renderCategory}
          isItemList={false}
        />
      </div>
    )
  }

  const renderItem = (index, data, isScrolling) => {
    if (!data) return <CheckInItemComponent key={`${index}`} empty />
    return (
      <CheckInItemComponent
        key={`${index}`}
        itemInfo={data}
        isScrolling={isScrolling}
      />
    )
  }

  useEffect(() => {
    if (AuthStore.token) {
      fetchItems(0, categoryId)
    }
  }, [AuthStore.token, reloadToggle])

  return (
    <div className='bg-white'>
      {dealItemsInfo?.length > 0 ? (
        <div className='px-2 py-2'>
          <CategorySelectSection />
          <VerticalList
            itemData={dealItemsInfo}
            height={SCREEN_HEIGHT}
            renderItem={renderItem}
            loadMore={loadMore}
            isFrom='CheckInMain'
            isItemList
            columnCount={2}
          />
        </div>
      ) : (
        <div className='flex flex-col items-center justify-start h-screen'>
          <div className='flex flex-row items-center justify-center mt-20 text-lg font-bold text-blue-500 animate-pulse'>
            <Spinner className='w-4 h-4' />
            <span className='ml-2'>상품 정보를 불러오는 중입니다.</span>
          </div>
          <Button
            onClick={() => setReloadToggle(!!reloadToggle)}
            className='mt-2'
            color='gray'
          >
            새로고침
          </Button>
        </div>
      )}
    </div>
  )
}
export default observer(dailyCheckInItemSection)
