import commaNumber from 'comma-number'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import AnimatedNumbers from 'react-animated-numbers'

import { Check, Close, DailyCheckInEnvelope } from '../../images/dailyCheckIn'
import backendApis from '../../utils/backendApis'

const DailyCheckInScreenRewardReceiveModal = observer(
  ({
    visible,
    close,
    rewardAmountForDisplay,
    isConsecutiveBonusAdded = false,
    consecutiveBonus = 1,
    isCashbackPurchase = false,
    openRBSheet,
    ...params
  }) => {
    const randomNumber = Math.random()
    const [displayAmount, setDisplayAmount] = useState(0)
    const [showPlus, setShowPlus] = useState(false)
    const [isDisabled, setIsDisabled] = useState(true)
    const [localConsecutiveBonus, setLocalConsecutiveBonus] = useState(0)
    const [consecutiveCheckInData, setConsecutiveCheckInData] = useState([])

    const consecutiveDays =
      consecutiveCheckInData?.consecutiveCheckInLog?.length || 1

    const EnteringHistorySection = () => {
      const dayCount = 7
      const renderCheckpoints = (count) => {
        const checkpoints = []
        const dayNameGenerator = (date) => {
          const engDayName = moment(date).format('ddd')
          switch (engDayName) {
            case 'Mon':
              return '월'
            case 'Tue':
              return '화'
            case 'Wed':
              return '수'
            case 'Thu':
              return '목'
            case 'Fri':
              return '금'
            case 'Sat':
              return '토'
            case 'Sun':
              return '일'
            default:
              return '월'
          }
        }

        for (let i = 1; i <= dayCount; i += 1) {
          checkpoints.push(
            <React.Fragment key={i}>
              <div className='flex flex-col items-center justify-center text-xs'>
                <div className='font-semibold text-[1vw] text-gray-500'>
                  {dayNameGenerator(moment().add(i - count, 'day'))}
                </div>
                <div
                  className={`flex rounded-full w-[7vw] h-[7vw] items-center justify-center ${
                    count >= i
                      ? 'bg-[#EB4F47]'
                      : [3, 5, 7].includes(i)
                      ? 'bg-[#FFE9EA]'
                      : 'bg-[#F9F9F9]'
                  }`}
                >
                  {count >= i && <Check className='w-5 h-5' />}
                  {count < i && (
                    <div
                      className={`flex w-10 h-6 font-bold text-center items-center justify-center ${
                        [3, 5, 7].includes(i)
                          ? 'text-red-500'
                          : 'text-[#D0D0D0]'
                      }`}
                    >
                      {i === 3 ? '3배' : i === 5 ? '5배' : i === 7 ? '7배' : i}
                    </div>
                  )}
                </div>
              </div>

              {i !== dayCount && <div className='w-2 h-1' />}
            </React.Fragment>,
          )
        }
        return checkpoints
      }

      return (
        <div className='z-10 flex flex-col items-center w-full'>
          <div className='flex flex-row items-center justify-center'>
            {renderCheckpoints(consecutiveDays)}
          </div>
        </div>
      )
    }

    const NudgeTextComponent = () => {
      const nudgeMessages = [
        { threshold: 3, multiplier: 3 },
        { threshold: 5, multiplier: 5 },
        { threshold: 7, multiplier: 7 },
      ]

      for (const message of nudgeMessages) {
        if (consecutiveDays === 3) {
          return (
            <div className='py-4 font-bold text-center text-[5vw]'>
              벌써 3일 연속! 3배로 드릴게요 <br />
              이틀 뒤엔 5배로 드려요!
            </div>
          )
        }
        if (consecutiveDays === 5) {
          return (
            <div className='py-4 font-bold text-center text-[5vw]'>
              오늘은 포인트를 5배로 드려요!
              <br />
              내일 모레는 무려 7배!
            </div>
          )
        }
        if (consecutiveDays === 7) {
          return (
            <div className='py-4 font-bold text-center text-[5vw]'>
              대단해요! 7배로 드릴게요 <br />
              내일 다시 1일차로 도전해봐요
            </div>
          )
        }
        if (consecutiveDays < message.threshold) {
          return (
            <div className='py-4 font-bold text-center text-[5vw]'>
              매일 출석하면 <br /> {message.threshold}일차에{' '}
              {message.multiplier}배로 드려요!
            </div>
          )
        }
        return (
          <div className='py-4 font-bold text-center text-[5vw]'>
            매일매일 출석하면 <br />
            포인트를 최대 7배로 드려요!
          </div>
        )
      }

      return null // or any default message if needed
    }

    useEffect(() => {
      const setConsecutiveBonus = async () => {
        if (params.rewardType === 'initial-check-in') {
          const result = await backendApis.getConsecutiveCheckIn()
          if (result?.status === 200) {
            setConsecutiveCheckInData(result.data?.consecutiveCheckInInfo)
          }
        }
      }
      setConsecutiveBonus()
      setTimeout(() => {
        setIsDisabled(false)
      }, 1000)
    }, [])

    useEffect(() => {
      const setRewardDisplay = () => {
        if (!rewardAmountForDisplay) return
        if (isConsecutiveBonusAdded) {
          const original = Math.floor(rewardAmountForDisplay / consecutiveBonus)
          setDisplayAmount(original)
        } else {
          setDisplayAmount(rewardAmountForDisplay)
        }
      }
      setRewardDisplay()
    }, [rewardAmountForDisplay, isConsecutiveBonusAdded, consecutiveBonus])

    return (
      <div>
        {/* 딤드 배경 */}
        <button
          type='button'
          aria-label='background'
          className='fixed top-0 left-0 z-10 flex items-center justify-center w-full h-full bg-gray-800 opacity-70'
          onClick={() => {
            close()
          }}
          disabled={isDisabled}
        />
        {/* 모달 상자 */}
        <div>
          <div className='w-[80vw] top-[20%] left-[10vw] fixed z-30'>
            <DailyCheckInEnvelope className='w-full h-full' />
          </div>
          <button
            type='button'
            className='top-[16%] left-[78vw] fixed z-30'
            disabled={isDisabled && rewardAmountForDisplay > 0}
            onClick={() => {
              close()
            }}
          >
            <Close className='w-6 h-6' />
          </button>
        </div>
        {/* 모달 내용 + 받기 버튼 */}
        <div
          className={`flex flex-col fixed w-[80vw] left-[10vw] items-center z-40 ${
            params?.rewardType === 'initial-check-in'
              ? 'top-[20%]'
              : 'top-[28%]'
          }`}
        >
          {params?.rewardType !== 'initial-check-in' && (
            <>
              <div className='mt-3 mb-2 text-lg font-bold'>축하합니다!</div>
              <div className='mb-2 text-xs text-gray-300'>
                {isCashbackPurchase ? '캐시백 예정금액' : '오늘의 당첨금액'}
              </div>
            </>
          )}
          {params?.rewardType === 'initial-check-in' && (
            <div className='py-2 bg-white'>
              <NudgeTextComponent />
              <div className='mb-2 text-xs text-gray-300'>
                <EnteringHistorySection />
              </div>
            </div>
          )}
          <div className='flex flex-row items-center justify-end mr-1 text-4xl font-bold text-red-500'>
            {isDisabled && (
              <AnimatedNumbers
                includeComma
                animateToNumber={displayAmount}
                fontStyle={{ fontSize: 36, marginRight: 4 }}
                configs={[{ mass: 1, tension: 320, friction: 50 }]}
              />
            )}
            {!isDisabled && (
              <div className='tracking-widest' style={{ fontSize: 36 }}>
                {commaNumber(displayAmount)}
              </div>
            )}
            <div className='text-3xl font-bold text-red-500 '>원</div>
            {showPlus && (
              <div className='flex flex-row items-baseline justify-center'>
                <div className='flex flex-row ml-2 text-2xl font-bold text-red-500'>
                  +
                  <AnimatedNumbers
                    includeComma
                    animateToNumber={localConsecutiveBonus}
                    fontStyle={{ fontSize: 24 }}
                    configs={[{ mass: 1, tension: 320, friction: 100 }]}
                  />
                </div>

                <div className='mb-1 text-2xl font-bold text-red-500'>원</div>
              </div>
            )}
          </div>

          <button
            type='button'
            className={`py-3 mt-1 w-[48vw] items-center font-bold text-base text-white rounded-2xl border ${
              isDisabled ? 'bg-gray-300' : 'bg-red-500'
            }`}
            disabled={isDisabled}
            onClick={() => {
              if (isConsecutiveBonusAdded) {
                if (!showPlus) {
                  setShowPlus(true)
                  window.location.href = '#vibrate.'
                  setLocalConsecutiveBonus(
                    rewardAmountForDisplay - displayAmount,
                  )
                } else {
                  setShowPlus(false)
                  close()
                  openRBSheet()
                  // setShowGoogleModal(true)
                }
              } else if (params?.isFirstTime) {
                params?.setModalConfig({
                  visible: true,
                  modalType: 'DailyCheckInNewUserRewardMultiply',
                })
              } else if (params?.rewardType === 'initial-check-in') {
                close()
                openRBSheet()
              } else if (params?.rewardType === 'periodic-bonus-mission') {
                close()
                if (randomNumber < 0.05) {
                  window.location.href = `#navigate.${JSON.stringify({
                    screen: 'DailyCheckInCashBackDealScreen',
                  })}`
                } else {
                  window.location.href = `#navigate.${JSON.stringify({
                    screen: 'DailyCheckInCompatibleBrowsingMission',
                    prop: {
                      missionType: 'periodic-bonus-mission',
                      missionName: '시간 출석하기',
                      rewardType: 'point',
                      rewardAmount: 10,
                      defaultLeftTime: 30,
                      defaultCategory: 'total',
                      showEnvelopeList: true,
                      showBrandDeal: true,
                      bannerUri: '',
                    },
                  })}`
                }
              } else {
                close()
              }
            }}
          >
            {isConsecutiveBonusAdded ? '보너스 받기!' : '올포인트 받기'}
          </button>
          {params?.rewardType === 'initial-check-in' &&
            consecutiveCheckInData.checkInStreak > 1 && (
              <div className='absolute -bottom-[16vh] px-4 py-2 rounded-full shadow-md text-xs font-semibold italic bg-white'>
                {consecutiveCheckInData.checkInStreak}일 연속 출석 중!
              </div>
            )}
        </div>
      </div>
    )
  },
)

export default DailyCheckInScreenRewardReceiveModal
