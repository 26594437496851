import commaNumber from 'comma-number'
import { observer } from 'mobx-react-lite'
import React from 'react'

import THModalStore from '../../../stores/treasureHunt/THModalStore'
import THUserStore from '../../../stores/treasureHunt/THUserStore'
import ImageTextComponent from './ImageTextComponent'

const TopButtons = observer(() => {
  return (
    <div className='flex flex-row w-full justify-around items-center p-2 py-0 relative'>
      {/* <BannerButton
        mission={{
          title: '친구와 보물찾기를 같이 켜면 보상을 더 받아요 🔥',
        }}
        noButton
      /> */}
      <ImageTextComponent
        text={`오늘 내가 모은 캐시:<point> ${commaNumber(
          THUserStore.dailyPoint ?? 0,
        )}원`}
      />

      {/* <BannerButton
        mission={{
          title: '친구 초대하고 2,000원 받기',
        }}
      /> */}
    </div>
  )
})

export default TopButtons
