import React from 'react'

const AltoonTagComponent = ({ tag, type }) => {
  function tagConverter(tag) {
    if (tag === 'hot') {
      return '#인기'
    }
    if (tag === 'new') {
      return '#신작'
    }
    if (tag === 'complete') {
      return '#완결'
    }
    return `#${tag}`
  }

  function styleConverter(tag) {
    if (tag === 'hot') {
      return `bg-white ${
        type === 'detailPage' ? 'border-[0.2vw]' : 'border-[0.4vw]'
      } border-red-500 text-red-500`
    }
    if (tag === 'new') {
      return `bg-white 
      ${
        type === 'detailPage' ? 'border-[0.2vw]' : 'border-[0.4vw]'
      } border-green-500 text-green-500`
    }
    if (tag === 'complete') {
      return `bg-[#EBEBEB] text-gray-700 border-gray-400 ${
        type === 'detailPage' ? 'border-[0.2vw]' : 'border-[0.4vw]'
      }`
    }
    return `bg-[#EBEBEB] text-[#757575] border-[#EBEBEB] ${
      type === 'detailPage' ? 'border-[0.2vw]' : 'border-[0.4vw]'
    }`
  }

  return (
    <div
      className={`flex items-center font-semibold rounded px-2 py-[0.8vw] mr-2 mb-2 ${styleConverter(
        tag,
      )}`}
    >
      {tagConverter(tag)}
    </div>
  )
}

export default AltoonTagComponent
