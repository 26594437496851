import commaNumber from 'comma-number'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useSearchParam } from 'react-use'

import TreasureHuntAction from '../actions/TreasureHuntAction'
import THTutorial from '../components/treasureHunt/THTutorial'
import THTutorialSecond from '../components/treasureHunt/THTutorialSecond'
import TreasureHuntButton from '../components/treasureHunt/TreasureHuntButton'
import TreasureHuntTimer from '../components/treasureHunt/TreasureHuntTimer'
import Background from '../components/treasureHunt/molecules/Background'
import BackpackEntryButton from '../components/treasureHunt/molecules/BackpackEntryButton'
import BottomButtons from '../components/treasureHunt/molecules/BottomButtons'
import CashShopButton from '../components/treasureHunt/molecules/CashShopButton'
import CollectionButton from '../components/treasureHunt/molecules/CollectionButton'
import FindButton from '../components/treasureHunt/molecules/FindButton'
import FriendButton from '../components/treasureHunt/molecules/FriendButton'
import FriendList from '../components/treasureHunt/molecules/FriendList'
import HeaderComponent from '../components/treasureHunt/molecules/HeaderComponent'
import LuckEntryButton from '../components/treasureHunt/molecules/LuckEntryButton'
import MatchItem from '../components/treasureHunt/molecules/MatchItem'
import MatchListItems from '../components/treasureHunt/molecules/MatchListItems'
import MissionEntryButton from '../components/treasureHunt/molecules/MissionEntryButton'
import MyFace from '../components/treasureHunt/molecules/MyFace'
import PetButton from '../components/treasureHunt/molecules/PetButton'
import TopButtons from '../components/treasureHunt/molecules/TopButtons'
import AuthStore from '../stores/AuthStore'
import ToastStore from '../stores/ToastStore'
import ABStore from '../stores/treasureHunt/ABStore'
import THBottomSheetStore from '../stores/treasureHunt/THBottomSheetStore'
import THModalStore from '../stores/treasureHunt/THModalStore'
import THUserStore from '../stores/treasureHunt/THUserStore'
import TreasureHuntAchievementController from '../utils/TreasureHuntAchievementController'
import backendApis from '../utils/backendApis'
import {
  codepushVersionCheck,
  isEffective,
  pickRandomOneArray,
} from '../utils/utility'

const searchTextMap = [
  '왠지 재밌는 걸 찾을 것 같아요!',
  '쉿! 방금 무슨 소리 나지 않았나요?',
  '동물 중에는 유니콘도 있다고 해요!',
  '방금 무슨 소리가 들렸나요?',
  '같은 동물이 많으면 보너스가 있대요!',
  '용을 찾으면 부자가 될 수 있대요!',
]
let hidden
let visibilityChange
if (typeof document.hidden !== 'undefined') {
  // Opera 12.10 and Firefox 18 and later support
  hidden = 'hidden'
  visibilityChange = 'visibilitychange'
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden'
  visibilityChange = 'msvisibilitychange'
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden'
  visibilityChange = 'webkitvisibilitychange'
}

const TreasureHuntPage = observer(() => {
  const token = useSearchParam('token')
  const [matchList, setMatchList] = useState([])
  const [CTAClickable, setCTAClickable] = useState(false)
  const [searchText, setSearchText] = useState(
    pickRandomOneArray(searchTextMap),
  )
  const [isVisibilityChange, setIsVisibilityChange] = useState(false)
  const [starboxTierImage, setStarboxTierImage] = useState('starboxTierOne')

  const energyMachinePressed = useRef(false)

  useEffect(() => {
    document.addEventListener(
      visibilityChange,
      (visibility) => {
        if (document.visibilityState === 'visible') {
          setTimeout(() => {
            TreasureHuntAction.initTreasureHunt(AuthStore?.token, {
              routeParams: THUserStore?.routeParams,
              codepushVersion: THUserStore?.codepushVersion,
            })
            setIsVisibilityChange(true)
          }, 500)
        }
      },
      false,
    )
  }, [])

  useEffect(() => {
    if (THUserStore?.tutorialStep === 0) {
      THUserStore?.set('point', 0)
      if (!THUserStore?.isLoading) {
        if (THUserStore?.tutorialMinorStep <= 0) {
          TreasureHuntAction.setTutorialMinorStep(0)
        }
        THBottomSheetStore.setType({
          type: 'tutorial',
          config: {
            subtitle: '특별 이벤트',
            subtitleClass: 'text-[#FF5951]',
            title: '화면에 뜬 캐릭터를 누르면 바로 20원을 받아요',
            subImg: '../images/treasureHunt/emoji/face/4.png',
            mainImg: null,
            CTAText: '누르고 20원 받기',
            tutorialMinorStep: 1,
          },
          titleOff: true,
        })
      }
    }
  }, [THUserStore?.isLoading])

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      TreasureHuntAction.initTreasureHunt(token || AuthStore?.token, {
        routeParams: THUserStore?.routeParams,
        codepushVersion: THUserStore?.codepushVersion,
      })
    }
  }, [])

  useEffect(() => {
    if (THUserStore?.tutorialStep === 3) {
      if (THUserStore?.tutorialMinorStep < 8) {
        TreasureHuntAction.setTutorialMinorStep(8)
      }
      THBottomSheetStore.setType({
        type: 'tutorial',
        config: {
          subtitle: '근처의 사람을 찾을때 필요해요!',
          subtitleClass: 'text-[#FF5951]',

          title: '보물찾기를 이용하려면\n위치 권한을 허용해주세요',
          subImg: '../images/treasureHunt/tutorial/pin.png',
          // mainImg: '../images/treasureHunt/tutorial/push.png',
          CTAText: '위치 권한 허용하기',
          tutorialMinorStep: 9,
          onClick: () => {
            // TreasureHuntAction.alarmAgree()
            TreasureHuntAction.postMessage({ type: 'launchGPS' })
            TreasureHuntAction.setTutorialStep(4)
            TreasureHuntAction.endTutorial()
            setTimeout(() => {
              TreasureHuntAction.setTutorialMinorStep(10)
            }, 3000)
          },
        },
        titleOff: true,
      })
    }
  }, [THUserStore?.tutorialStep])

  useEffect(() => {
    setMatchList(THUserStore?.matchList)
  }, [THUserStore?.matchList])

  useEffect(() => {
    setCTAClickable(
      THUserStore?.energy > 0 &&
        THUserStore?.isAllCleared === true &&
        !THUserStore?.isFindingTreasure &&
        THUserStore?.tutorialStep !== 3,
    )
    if (THUserStore?.tutorialStep === 1) {
      setCTAClickable(true)
    }
  }, [
    THUserStore?.isAllCleared,
    THUserStore?.energy,
    THUserStore?.tutorialStep,
    THUserStore?.isFindingTreasure,
  ])

  useEffect(() => {
    const serviceEndNoticePopedAt = window.localStorage.getItem(
      'serviceEndNoticePopedAt',
    )

    if (
      moment().isSameOrAfter(moment('2023-11-24')) &&
      moment().format('YYYY-MM-DD') !== serviceEndNoticePopedAt
    ) {
      window.localStorage.setItem(
        'serviceEndNoticePopedAt',
        moment().format('YYYY-MM-DD'),
      )
      setTimeout(() => {
        THModalStore.openServiceEndNotice()
      }, 2000)
    } else if (!serviceEndNoticePopedAt) {
      setTimeout(() => {
        THModalStore.openServiceEndNotice()
        window.localStorage.setItem(
          'serviceEndNoticePopedAt',
          moment().format('YYYY-MM-DD'),
        )
      }, 2000)
    }
  }, [])

  useEffect(() => {
    if (moment().isSameOrAfter(moment('2023-12-01'))) {
      TreasureHuntAction.navigateRN({
        screen: 'MainStackAMainTabNavigator',
        payload: {},
      })
    }
  }, [])

  useEffect(() => {
    if (THUserStore?.energy < THUserStore?.maxEnergy) {
      THUserStore?.set('isChargingEnergy', true)
    }
  }, [THUserStore?.energy])

  useEffect(() => {
    if (THUserStore?.luck >= 75) {
      setStarboxTierImage('starboxTierTwo')
    } else if (THUserStore?.luck >= 10 && THUserStore?.luck < 75) {
      setStarboxTierImage('starboxTierOne')
    } else if (THUserStore?.luck < 10) {
      setStarboxTierImage('starboxGray')
    }
  }, [THUserStore?.luck])

  const getLuckStyle = () => {
    if (THUserStore?.luck < 10) {
      return {
        backgroundColor: '#d9d9d9',
        color: 'black',
      }
    }
    if (THUserStore?.luck >= 10 && THUserStore?.luck < 75) {
      return {
        backgroundColor: '#E8FEDB',
        color: 'white',
      }
    }
    if (THUserStore?.luck >= 75) {
      return {
        backgroundColor: '#F9F0CE',
        color: 'white',
      }
    }
    // 다른 조건들에 대한 스타일을 추가하고 싶으면 여기에 추가할 수 있습니다.
    return {} // 기본 스타일 (조건이 충족되지 않을 때)
  }

  if (THUserStore?.isLoading)
    return (
      <div className='loadingPage flex flex-col jua'>
        <div className='slowRotateCenter pigNose'>😎</div>
        <div>로딩 중...</div>
      </div>
    )

  return (
    <div
      className={`fixed flex bg-white flex-col justify-between h-[100vh] w-[100vw] overflow-hidden
    `}
    >
      <HeaderComponent />
      {Number(THUserStore?.tutorialStep) >= 3 && <TopButtons />}

      <div className='flex flex-1 py-4 relative'>
        <Background />
        <div className=' w-full h-full p-6 relative z-[10]  '>
          {!THUserStore?.isFindingTreasure && (
            <MatchListItems matchList={matchList} />
          )}

          {(THUserStore?.tutorialStep < 2 ||
            (THUserStore?.oldTutorialOn && !THUserStore?.tutorialEndAt)) && (
            <MatchItem
              isTutorial
              item={{
                reward: 20,
                emojiId: 15,
                type: 'face',
                name: '캐릭터',
                position: {
                  left: 70,
                  top: 20,
                },
                isBot: true,
                tag: null,
              }}
              index={0}
            />
          )}
          {isEffective(THUserStore?.energyMachineEndAt ?? '2023-01-01') && (
            <div
              className='z-[8] absolute -bottom-[6vw] left-4 '
              onClick={() => {
                if (
                  isEffective(THUserStore?.energyMachineEndAt ?? '2023-01-01')
                ) {
                  if (
                    moment().format('YYYY-MM-DD') !==
                    moment(
                      THUserStore?.energyMachineReceivedAt ?? '2023-01-01',
                    ).format('YYYY-MM-DD')
                  ) {
                    if (energyMachinePressed?.current) return
                    energyMachinePressed.current = true
                    TreasureHuntAction.energyMachineReward()
                  } else {
                    ToastStore.toastOn({
                      message: '오늘은 이미 번개를 받으셨어요!',
                      duration: 2000,
                      type: 'emoji',
                      emoji: '😘',
                    })
                  }
                } else {
                  THModalStore.setType({ type: 'energyMachine' })
                }
              }}
            >
              <img
                className='w-[15vw]'
                src='../images/treasureHunt/energyMachine/machine.png'
                alt='energyMachine'
              />
              {isEffective(THUserStore?.energyMachineEndAt ?? '2023-01-01') &&
                moment().format('YYYY-MM-DD') !==
                  moment(
                    THUserStore?.energyMachineReceivedAt ?? '2023-01-01',
                  ).format('YYYY-MM-DD') && (
                  <img
                    className='absolute w-[4vw] h-[4vw] top-0 -right-0'
                    src='../images/treasureHunt/ping.png'
                    alt='ping'
                  />
                )}
            </div>
          )}
        </div>

        <MyFace searchText={searchText} />
        <div
          className='z-[10] absolute top-[2vw] text-[2.5vw] font-bold rounded-md border-2 p-2 py-1 left-2 border-[#313138] text-[#313138]'
          onClick={() => {
            THModalStore.openServiceEndNotice()
          }}
        >
          서비스 종료 안내
        </div>
      </div>

      <div className='z-[0] ml-4 flex flex-row mb-0 justify-between items-end relative '>
        {Number(THUserStore?.tutorialStep) >= 3 ? (
          <div className='flex flex-row'>
            <BackpackEntryButton className='w-[14vw] h-[14vw] ' />
            <CashShopButton className='w-[14vw] h-[14vw] ml-2' />
            {THUserStore?.lastFindTreasureAt ? (
              <>
                <LuckEntryButton className='w-[14vw] h-[14vw] ml-2' />
                <MissionEntryButton className='w-[14vw] h-[14vw] ml-2' />
              </>
            ) : (
              <>
                <MissionEntryButton className='w-[14vw] h-[14vw] ml-2' />
                <div className='w-[14vw] ml-2' />
              </>
            )}
          </div>
        ) : (
          <div className='w-[62vw]' />
        )}
        <div
          id='THCTA'
          className='relative flex flex-1 justify-center items-center'
        >
          {THUserStore?.lastFindTreasureAt && (
            <>
              <div
                className='absolute -top-[10vw] left-1/2 -translate-x-1/2 px-4 py-1 flex flex-row justify-center items-center whitespace-nowrap rounded-full'
                style={getLuckStyle()}
                onClick={() => {
                  THBottomSheetStore.setType({
                    type: 'luck',
                    config: {},
                  })
                }}
              >
                <img
                  className='w-[6vw] h-[6vw] ml-1'
                  src={`../images/treasureHunt/luck/starbox/${starboxTierImage}.png`}
                  alt='star'
                />
                <div
                  className={`
                  whitespace-nowrap font-bold px-2 justify-center items-center text-center text-[4vw]
                  ${
                    THUserStore?.luck >= 75
                      ? 'text-[#FF980B]'
                      : 'text-[#57AF1E]'
                  }
                  `}
                >
                  {commaNumber(THUserStore?.luck ?? 0)}
                </div>
              </div>

              <div className='absolute -top-[20vw] left-1/2 -translate-x-1/2 px-4 py-1 flex flex-row justify-center items-center whitespace-nowrap rounded-full pointer-events-none'>
                <div
                  className='absolute left-1/2 -translate-x-1/2  -top-[0vw] 
        whitespace-nowrap text-center font-bold text-[#FF847D] bg-[#FF847D] 
        rounded-full px-2 py-1 text-[3vw]'
                >
                  <img
                    className='w-5 h-5 absolute -bottom-2 left-1/2 -translate-x-1/2'
                    src='../images/treasureHunt/triangle.png'
                    alt='triangle'
                  />
                  <div
                    className='left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 
          absolute w-full h-full text-white flex flex-col justify-center items-center '
                  >
                    {THUserStore?.luck < 10 && '탐색 실패 위험'}
                    {THUserStore?.luck >= 10 &&
                      THUserStore?.luck < 75 &&
                      '탐색 실패 방지'}
                    {THUserStore?.luck >= 75 && '탐색 대성공 확률 40%'}
                  </div>
                  {THUserStore?.luck < 10 && '탐색 실패 위험'}
                  {THUserStore?.luck >= 10 &&
                    THUserStore?.luck < 75 &&
                    '탐색 실패 방지'}
                  {THUserStore?.luck >= 75 && '탐색 대성공 확률 40%'}
                </div>
              </div>
            </>
          )}
          <FindButton
            clickable={CTAClickable}
            onDisabledClick={() => {
              if (THUserStore?.tutorialStep === 3) {
                THBottomSheetStore.setType({
                  type: 'tutorial',
                  config: {
                    subtitle: '근처의 사람을 찾을때 필요해요!',
                    subtitleClass: 'text-[#FF5951]',

                    title: '보물찾기를 이용하려면\n위치 권한을 허용해주세요',
                    subImg: '../images/treasureHunt/tutorial/pin.png',
                    // mainImg: '../images/treasureHunt/tutorial/push.png',
                    CTAText: '위치 권한 허용하기',
                    tutorialMinorStep: 9,
                    onClick: () => {
                      // TreasureHuntAction.alarmAgree()
                      TreasureHuntAction.postMessage({ type: 'launchGPS' })
                      TreasureHuntAction.setTutorialStep(4)
                      TreasureHuntAction.endTutorial()
                      setTimeout(() => {
                        TreasureHuntAction.setTutorialMinorStep(10)
                      }, 3000)
                    },
                  },
                  titleOff: true,
                })
              }
            }}
            onClick={() => {
              setSearchText(pickRandomOneArray(searchTextMap))

              if (THUserStore?.tutorialStep === 1) {
                TreasureHuntAction.setTutorialMinorStep(4)
                TreasureHuntAction?.setTutorialStep(2)
                THUserStore?.set('isFindingTreasure', true)
                TreasureHuntAction?.fakeFindTreasure()
                setCTAClickable(false)
                return
              }
              if (process.env.NODE_ENV === 'development') {
                THUserStore?.set('isFindingTreasure', true)
                TreasureHuntAction.processFindTreasure({
                  latitude: 37.785834,
                  longitude: -122.406417,
                })
              } else {
                TreasureHuntAction.triggerFindTreasure()
              }
            }}
          />
          {moment().isSameOrBefore(
            moment(THUserStore?.tutorialEndAt).add(10, 'minutes'),
          ) &&
            THUserStore?.tutorialEndAt &&
            THUserStore?.point < 500 &&
            CTAClickable && (
              <img
                className='absolute w-10 h-10 bottom-3 right-[0vw] z-[50] pulsate-fwd-lg pointer-events-none'
                alt='txt'
                src='../images/treasureHunt/finger.png'
              />
            )}
        </div>
      </div>
      <div className='mt-2 '>
        <FriendList />
      </div>
      {THUserStore?.isFindingTreasure && (
        <div className='z-[20] w-full h-full absolute bg-black/20 pointer-events-none' />
      )}
      {THUserStore?.oldTutorialOn && !THUserStore?.tutorialEndAt && (
        <THTutorial />
      )}
      {(THUserStore?.tutorialMinorStep === 1 ||
        THUserStore?.tutorialMinorStep === 3) && <THTutorialSecond />}

      <TreasureHuntAchievementController />
    </div>
  )
})

export default TreasureHuntPage
