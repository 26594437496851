import commaNumber from 'comma-number'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import TreasureHuntAction from '../../../actions/TreasureHuntAction'
import THBottomSheetStore from '../../../stores/treasureHunt/THBottomSheetStore'
import THModalStore from '../../../stores/treasureHunt/THModalStore'
import THUserStore from '../../../stores/treasureHunt/THUserStore'
import backendApis from '../../../utils/backendApis'
import { isEffective } from '../../../utils/utility'
import EventIcon from './EventIcon'

const MissionList = observer(() => {
  const [diceEventPurchaseEndAt, setDiceEventPurchaseEndAt] = useState(null)
  const [searchButtonEnforceShowEndAt, setSearchButtonEnforceShowEndAt] =
    useState(null)
  const [
    purchaseSearchButtonEnforceShowEndAt,
    setpurchaseSearchButtonEnforceShowEndAt,
  ] = useState(null)
  const [goldenEggAt, setGoldenEggAt] = useState('2023-01-01')

  useEffect(() => {
    setGoldenEggAt(THUserStore?.goldenEggEndAt)
  }, [THUserStore?.goldenEggEndAt])

  useEffect(() => {
    // TreasureHuntAction.getAllFriends()
    const diceEndAt = window?.localStorage.getItem('diceEventPurchaseEndAt')
    setDiceEventPurchaseEndAt(diceEndAt)
    if (isEffective(moment(diceEndAt).toDate())) {
      THUserStore?.set('diceEventPurchaseOn', true)
    }
  }, [THUserStore?.diceEventPurchaseOn])

  useEffect(() => {
    // TreasureHuntAction.getAllFriends()
    const endAt = window?.localStorage.getItem('searchButtonEnforceShowEndAt')
    setSearchButtonEnforceShowEndAt(endAt)
    if (isEffective(moment(endAt).toDate())) {
      THUserStore?.set('searchButtonEnforceOn', true)
    }
  }, [THUserStore?.searchButtonEnforceOn])

  useEffect(() => {
    // TreasureHuntAction.getAllFriends()
    const endAt = window?.localStorage.getItem(
      'purchaseSearchButtonEnforceShowEndAt',
    )
    setpurchaseSearchButtonEnforceShowEndAt(endAt)
    if (isEffective(moment(endAt).toDate())) {
      THUserStore?.set('purchaseSearchButtonEnforceOn', true)
    }
  }, [THUserStore?.purchaseSearchButtonEnforceOn])

  return (
    <div className='flex flex-row space-x-2 whitespace-nowrap flex-shrink-0'>
      {!isEffective(moment(THUserStore?.searchButtonEnforceEndAt).toDate()) &&
        isEffective(moment(searchButtonEnforceShowEndAt).toDate()) &&
        THUserStore?.searchButtonEnforceOn && (
          <EventIcon
            event={{
              expiryTimestamp: moment(searchButtonEnforceShowEndAt).toDate(),
              tag: true,
              tagType: 'timer',
              src: 'searchButtonEnforce',
              codepushVersion: '4.0.1',
              onClick: () => {
                THModalStore.setType({
                  type: 'searchButtonEnforce',
                  config: {
                    title: '탐색버튼 강화하기',
                    description: '친구를 초대하고 탐색버튼을 강화해보세요!',
                    text: `초대하고 강화하기`,
                    isFromEventButton: true,
                  },
                })
              },
            }}
          />
        )}
      {!isEffective(moment(THUserStore?.searchButtonEnforceEndAt).toDate()) &&
        isEffective(moment(purchaseSearchButtonEnforceShowEndAt).toDate()) &&
        THUserStore?.purchaseSearchButtonEnforceOn && (
          <EventIcon
            event={{
              expiryTimestamp: moment(
                purchaseSearchButtonEnforceShowEndAt,
              ).toDate(),
              tag: true,
              tagType: 'timer',
              src: 'purchaseSearchButtonEnforce',
              codepushVersion: '4.0.1',
              onClick: () => {
                THModalStore.setType({
                  type: 'searchButtonEnforce',
                  config: {
                    title: '탐색버튼 강화하기',
                    description: '상품을 구매하고 탐색버튼을 강화해보세요!',
                    text: `구매하고 강화하기`,
                    isFromEventButton: true,
                    isPurchase: true,
                  },
                })
              },
            }}
          />
        )}
      {isEffective(moment(diceEventPurchaseEndAt).toDate()) &&
        moment().format('YYYY-MM-DD') !== THUserStore?.purchaseDiceEventEndAt &&
        THUserStore?.diceEventPurchaseOn && (
          <EventIcon
            event={{
              expiryTimestamp: moment(diceEventPurchaseEndAt).toDate(),
              tag: true,
              tagType: 'timer',
              src: 'diceEventPurchase',
              codepushVersion: '4.0.1',
              onClick: () => {
                const payload = {
                  cloverCount: 6,
                  enteringComponent: 'TreasureHunt_DiceEventPurchase',
                  text: '보물찾기',
                  source:
                    'https://assets.ilevit.com/a24c6aab-fb8f-414d-93c6-b842a7741937.png',
                  rewardText: `상품을 구매하고 황금 클로버 6개를 받아보세요`,
                }

                TreasureHuntAction.navigateRN({
                  screen: 'TreasureHuntMissionPurchaseScreen',
                  payload,
                })
              },
            }}
          />
        )}
      {THUserStore?.uploadChallenge && !THUserStore?.uploadChallenge?.isEnd && (
        <EventIcon
          event={{
            expiryTimestamp: moment(
              THUserStore?.uploadChallenge?.endAt,
            )?.toDate(),
            tag: true,
            tagType: 'timer',
            src: 'uploadChallenge',
            codepushVersion: '4.0.1',
            onClick: () => {
              THBottomSheetStore.setType({
                type: 'uploadChallenge',
              })
            },
          }}
        />
      )}
      {(isEffective(moment(THUserStore?.createdAt).add(7, 'd').toDate()) ||
        isEffective(moment(THUserStore?.bambooInviteEndt).toDate())) && (
        <EventIcon
          event={{
            expiryTimestamp: moment(THUserStore?.bambooInviteEndt).toDate(),
            tag: isEffective(
              moment(THUserStore?.createdAt).add(7, 'd').toDate(),
            )
              ? '6,000원'
              : true,
            tagType: isEffective(
              moment(THUserStore?.createdAt).add(7, 'd').toDate(),
            )
              ? 'text'
              : 'timer',
            src: 'bambooInvite',
            codepushVersion: '4.0.1',
            onClick: () => {
              THBottomSheetStore.setType({
                type: 'bambooInvite',
                config: {
                  title: '친구 초대',
                },
              })
            },
          }}
        />
      )}
      {(isEffective(moment(THUserStore?.createdAt).add(7, 'd').toDate()) ||
        isEffective(moment(THUserStore?.cashbackEndAt).toDate())) && (
        <EventIcon
          event={{
            expiryTimestamp: moment(THUserStore?.cashbackEndAt).toDate(),
            tag: isEffective(
              moment(THUserStore?.createdAt).add(7, 'd').toDate(),
            )
              ? '100%'
              : true,
            tagType: isEffective(
              moment(THUserStore?.createdAt).add(7, 'd').toDate(),
            )
              ? 'text'
              : 'timer',
            src: 'cashback',
            codepushVersion: '4.0.5',
            onClick: () => {
              backendApis.THInsert({
                data: {
                  enteringComponent: 'TreasureHunt_CashBack',
                  type: 'cashBackClick',
                },
                collection: 'clickLog',
              })
              const payload = {
                enteringComponent: 'TreasureHunt_CashBack',
                text: '보물찾기',
                source:
                  'https://assets.ilevit.com/2d50b4d9-5fcd-4d5f-bd4d-0ebc6d8cecd2.png',
                myPoint: `${commaNumber(THUserStore?.point)}원` ?? '로딩 중',
              }
              TreasureHuntAction.navigateRN({
                screen: 'TreasureHuntPurchaseScreen',
                payload,
              })
            },
          }}
        />
      )}
      {isEffective(moment(THUserStore?.goldOnlyEndAt).toDate()) && (
        <EventIcon
          event={{
            expiryTimestamp: moment(THUserStore?.goldOnlyEndAt).toDate(),
            tag: true,
            tagType: 'timer',
            src: 'goldOnly',
            codepushVersion: '4.2.2',
            onClick: () => {
              backendApis.THInsert({
                data: {
                  enteringComponent: 'TreasureHunt_Gold',
                  type: 'GoldPurchaseClick',
                },
                collection: 'clickLog',
              })
              const payload = {
                enteringComponent: 'TreasureHunt_Gold',
                text: '보물찾기',
                source:
                  'https://assets.ilevit.com/a89aa2a3-1b0b-43d3-910c-136b83c8dc92.png',
                loadTreasureHuntItemsForRevenue: true,
                rewardText: `상품을 구매하고 황금클로버 8개를 받아보세요`,
              }
              TreasureHuntAction.navigateRN({
                screen: 'TreasureHuntMissionPurchaseScreen',
                payload,
              })
            },
          }}
        />
      )}
      {isEffective(moment(THUserStore?.goldOnlyEnergyEndAt).toDate()) && (
        <EventIcon
          event={{
            expiryTimestamp: moment(THUserStore?.goldOnlyEnergyEndAt).toDate(),
            tag: true,
            tagType: 'timer',
            src: 'goldOnlyEnergy',
            codepushVersion: '4.2.2',
            onClick: () => {
              const payload = {
                enteringComponent: 'TreasureHunt_GoldEnergy',
                text: '보물찾기',
                source:
                  'https://assets.ilevit.com/6d2fb11f-0ace-422e-a7f5-368da8516638.png',
                loadTreasureHuntItemsForRevenue: true,
                rewardText: `상품을 구매하고 에너지 10개를 받아보세요`,
              }
              TreasureHuntAction.navigateRN({
                screen: 'TreasureHuntMissionPurchaseScreen',
                payload,
              })
            },
          }}
        />
      )}
      {isEffective(moment(goldenEggAt ?? '2023-01-01').toDate()) && (
        <EventIcon
          event={{
            expiryTimestamp: moment(goldenEggAt ?? '2023-01-01').toDate(),
            tag: true,
            tagType: 'timer',
            src: 'goldenEgg',
            // codepushVersion: '4.0.1',
            onClick: () => {
              THModalStore.setType({
                type: 'goldenEgg',
                config: {
                  rewardName: `황금알 발견!`,
                  src: `../images/treasureHunt/modal/goldenEgg.png`,
                  description:
                    '황금알이 사라지기전에 \n탐색을 통해 부화기를 찾아보세요',
                  imgClassName: 'w-full h-full',
                  text: '탐색하기',
                },
              })
            },
          }}
        />
      )}
    </div>
  )
})

export default MissionList
