import commaNumber from 'comma-number'
import { observer } from 'mobx-react-lite'
import React from 'react'

import TreasureHuntAction from '../../../actions/TreasureHuntAction'
import AuthStore from '../../../stores/AuthStore'
import RouteStore from '../../../stores/RouteStore'
import THBottomSheetStore from '../../../stores/treasureHunt/THBottomSheetStore'
import THUserStore from '../../../stores/treasureHunt/THUserStore'
import backendApis from '../../../utils/backendApis'

const MissionEntryButton = observer(({ className }) => {
  return (
    <div
      className={`relative pointer-events-auto flex justify-center items-center rounded-full ${className}`}
      onClick={() => {
        THBottomSheetStore.setType({
          type: 'mission',
          config: {},
        })
        THUserStore.set('missionPingOn', false)
      }}
    >
      <img
        className='w-[16vw] absolute absolute-center'
        src='../images/treasureHunt/bottomButtons/mission.png'
        alt='mission'
      />
      {THUserStore?.missionPingOn && (
        <img
          className='absolute -right-1 -top-1 w-4 h-4'
          src='../images/treasureHunt/ping.png'
          alt='ping'
        />
      )}
    </div>
  )
})

export default MissionEntryButton
