import commaNumber from 'comma-number'
import { random } from 'lodash'
import { toJS } from 'mobx'
import moment from 'moment'

import AuthStore from '../stores/AuthStore'
import ModalStore from '../stores/ModalStore'
import RouteStore from '../stores/RouteStore'
import ToastStore from '../stores/ToastStore'
import ABStore from '../stores/treasureHunt/ABStore'
import THBottomSheetStore from '../stores/treasureHunt/THBottomSheetStore'
import THModalStore from '../stores/treasureHunt/THModalStore'
import THUserStore from '../stores/treasureHunt/THUserStore'
import backendApis from '../utils/backendApis'
import { translateUnitVariable, translateVariable } from '../utils/utility'

class TreasureHuntAction {
  postMessage(data) {
    window?.ReactNativeWebView?.postMessage(JSON.stringify(data))
  }

  haptic() {
    this.postMessage({ type: 'haptic', haptic: true })
  }

  async initTreasureHunt(token, params) {
    const collectionPing = window.localStorage.getItem('collecitonPingOn')
    if (collectionPing === 'true') {
      THUserStore.set('collecitonPingOn', true)
    } else {
      THUserStore.set('collecitonPingOn', false)
    }
    const result = await backendApis.initTreasureHunt(token, params)
    if (result?.status === 200) {
      const userData = result?.data?.userData

      const isAllCleared = result?.data?.matchList?.every(
        (match) => match?.clickedAt !== undefined,
      )
      THUserStore.setMultiple({
        matchList: result?.data?.matchList,
        ...result?.data?.userData,
        isAllCleared,
        dailyMission: result?.data?.dailyMission,
        collection: result?.data?.collection,
        dailyEnergy: result?.data?.dailyEnergy,
        dailyPoint: result?.data?.dailyPoint,
        friends: result?.data?.friends,
        currentFriend: { ...result?.data?.friends?.[0], index: 0 },
        friendMission: result?.data?.friendMission,
        achievementList: result?.data?.achievementList,
        achievement: result?.data?.achievement,
        closet: result?.data?.closet,
      })
      if (userData?.energy < userData?.maxEnergy) {
        THUserStore.set('isChargingEnergy', true)
      }
      if (userData?.initModal) {
        setTimeout(() => {
          THModalStore.setType(userData?.initModal)
        }, 800)
        backendApis.THSet({
          set: {
            initModal: null,
          },
          collection: 'user',
        })
      }
      if (userData?.initModalList) {
        for (const init of userData?.initModalList) {
          setTimeout(() => {
            THModalStore.setType(init)
          }, 800)
        }
        backendApis.THSet({
          set: {
            initModalList: [],
          },
          collection: 'user',
        })
      }
      if (
        !userData?.pet ||
        userData?.pet?.status === 'home' ||
        userData?.pet?.status === 'gift'
      ) {
        THUserStore.set('petPingOn', true)
      }
      if (userData?.tutorialEndAt) {
        this.postMessage({ type: 'launchGPS' })
      }

      if (result?.data?.friends) {
        for (const friend of result?.data?.friends) {
          let breakflag = false
          for (const mission of friend?.missions) {
            if (mission?.type === 'action') {
              if (mission?.tag === 'search') {
                const friendNudgeSearch = window.localStorage.getItem(
                  `friendNudgeSearch_${THUserStore?.currentFriend?.friendId?.toString()}`,
                )
                if (moment().format('YYYY-MM-DD') !== friendNudgeSearch) {
                  breakflag = true
                  break
                }
              } else {
                breakflag = true
              }
              break
            } else if (mission?.type === 'reward') {
              breakflag = true
              break
            }
          }
          if (breakflag) {
            THUserStore.set('friendPingOn', true)

            break
          }
        }
      }

      if (result?.data?.challenges) {
        for (const challenge of result?.data?.challenges) {
          THUserStore.set([challenge?.type], challenge)
          if (challenge?.type === 'uploadChallenge') {
            THUserStore.set('selectedImage', challenge?.uploadedFileUri)
          }
        }
      }

      if (result?.data?.dailyMission) {
        const dailyMission = result?.data?.dailyMission
        const missionClickedAt = window.localStorage.getItem('missionClickedAt')
        const luckClickedAt = window.localStorage.getItem('luckClickedAt')
        const now = moment().format('YYYY-MM-DD')

        const timeText = this.attendanceTimeCalculator()
        if (
          !dailyMission?.[`attendanceStarbox${timeText}`] ||
          now !== luckClickedAt
        ) {
          THUserStore.set('luckPingOn', true)
          window.localStorage.setItem('luckClickedAt', now)
        }
        if (now !== missionClickedAt) {
          THUserStore.set('missionPingOn', true)
          window.localStorage.setItem('missionClickedAt', now)
        }
      }

      setTimeout(() => {
        THUserStore.set('isLoading', false)
      }, 800)
    } else {
      ToastStore.networkError()
    }
  }

  attendanceTimeCalculator() {
    const HHmm = moment().format('HHmm')
    if (HHmm >= '0000' && HHmm < '0600') {
      return 'Dawn'
    }
    if (HHmm >= '0600' && HHmm < '1200') {
      return 'Morning'
    }
    if (HHmm >= '1200' && HHmm < '1800') {
      return 'Lunch'
    }
    if (HHmm >= '1800' && HHmm < '2400') {
      return 'Dinner'
    }
  }

  async launchGPSUpdate({ gpsData }) {
    if (gpsData?.errorCode === 0) {
      THUserStore.set('tutorialStep', 4)
      this.postMessage({ type: 'navigateToSettings' })
      return
    }
    if (gpsData?.errorCode > 0) {
      ToastStore.GPSError()
      return
    }

    const result = backendApis.launchGPSUpdate({ gpsData })
    if (result?.status === 201) {
      this.postMessage({ type: 'navigateToSettings' })
    }
  }

  async navigateRN({ screen, payload }) {
    await backendApis.THSet({
      set: {
        nativePayload: payload,
      },
      collection: 'user',
    })
    window?.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'navigate',
        data: {
          screen,
          payload,
        },
      }),
    )
  }

  async triggerFindTreasure() {
    this.postMessage({ type: 'findTreasure' })
    THUserStore.set('isFindingTreasure', true)
  }

  async processFindTreasure(gpsData) {
    if (gpsData?.errorCode === 0) {
      ToastStore.toastOn({
        type: 'error',
        message: '위치 정보 권한 제공에 동의해주세요 🛰',
        duration: 2000,
      })
      return
    }
    if (gpsData?.errorCode === 404) {
      ToastStore.toastOn({
        type: 'error',
        message: '고객센터로 문의해주세요 [404 Permission]',
        duration: 2000,
      })
      return
    }
    if (gpsData?.errorCode) {
      ToastStore.toastOn({
        type: 'error',
        message: '위치 정보 데이터를 받아오지 못했어요! 🛰',
        duration: 2000,
      })
      return
    }
    const result = await backendApis.findTreasureTH({
      gpsData,
      codepushVersion: THUserStore?.codepushVersion,
    })

    if (result?.status === 200) {
      const matchList = [...result?.data?.matchList]
      if (result?.data?.lastFindTreasureAt) {
        THUserStore?.set('lastFindTreasureAt', new Date())
      }
      setTimeout(() => {
        THUserStore.set('isFindingTreasure', false)
        if (result?.data?.isGrandSuccess) {
          THUserStore.set('alert.isOn', true)
          THUserStore.set('alert.type', 'grandSuccess')
          this.haptic()
        }

        if (result?.data?.isFaceMatchThree) {
          THModalStore?.setType({
            type: 'reward',
            config: {
              rewardName: '사람 매치 성공! [특별]',
              description: `같은 사람 캐릭터 3개를 발견했어요!\n매치 성공 보상을 받으세요!`,
              src: `../images/treasureHunt/emoji/face/${result?.data?.sameFaceEmojiId}.png`,
              text: `${result?.data?.faceMatchThreeReward}원 받기`,
            },
          })
          THUserStore.increase('point', result?.data?.faceMatchThreeReward)
          THUserStore.increase('dailyPoint', result?.data?.faceMatchThreeReward)
        } else if (result?.data?.isAnimalMatchThree) {
          THModalStore?.setType({
            type: 'reward',
            config: {
              rewardName: '동물 매치 성공!',
              description: `같은 동물 캐릭터 3개를 발견했어요!\n매치 성공 보상을 받으세요!`,
              src: `../images/treasureHunt/emoji/animal/${result?.data?.sameAnimalEmojiId}.png`,
              text: `${result?.data?.animalMatchThreeReward}원 받기`,
            },
          })
          THUserStore.increase('point', result?.data?.animalMatchThreeReward)
          THUserStore.increase(
            'dailyPoint',
            result?.data?.animalMatchThreeReward,
          )
        }

        if (result?.data?.eventType) {
          if (result?.data?.eventType === 'cashback') {
            THUserStore.set('cashbackEndAt', new Date())
          } else if (result?.data?.eventType === 'goldOnly') {
            THUserStore.set('goldOnlyEndAt', new Date())
          } else if (result?.data?.eventType === 'goldOnlyEnergy') {
            THUserStore.set('goldOnlyEnergyEndAt', new Date())
          } else if (result?.data?.eventType === 'bambooInvite') {
            THUserStore.set('bambooInviteEndt', new Date())
          } else if (result?.data?.eventType === 'goldenEgg') {
            THUserStore?.set(
              'goldenEggEndAt',
              moment().add(10, 'minutes').toDate(),
            )
          }
        }
      }, 2000)

      if (result?.data?.luckSubtract) {
        THUserStore.increase('luck', -result?.data?.luckSubtract ?? 0)
      }
      THUserStore.set('matchList', matchList)
      THUserStore.set('isAllCleared', false)
      if (THUserStore?.energy === THUserStore?.maxEnergy) {
        THUserStore.set('lastEnergyChargeAt', new Date())
      }
      THUserStore.increase('energy', -1)

      // 보물친구 미션 업데이트 ㄱㄱ
      const lastSearchAt = window.localStorage.getItem('lastSearchAt')
      if (moment().format('YYYY-MM-DD') !== lastSearchAt) {
        window.localStorage.setItem(
          'lastSearchAt',
          moment().format('YYYY-MM-DD'),
        )
        backendApis.updateFriendSearchMission()
      }

      THUserStore.increase('dailyFindTreasureCount.count', 1)
      window.localStorage.setItem(
        'dailyFindTreasureCount',
        JSON.stringify({
          date: moment().format('YYYY-MM-DD'),
          count: THUserStore?.dailyFindTreasureCount?.count,
        }),
      )

      backendApis.THincrease({
        option: { upsert: true },
        inc: {
          searchCumulativeCount: 1,
        },
        collection: 'achievement',
      })
      THUserStore?.increase('achievement.searchCumulativeCount', 1)
    } else if (result?.status === 201) {
      ToastStore.toastOn({
        type: 'error',
        message: '아직 클릭하지 않은 보상이 있어요!',
        duration: 2000,
      })
    } else if (result?.status === 202) {
      ToastStore.toastOn({
        type: 'error',
        message: '번개가 부족해요! ⚡️',
        duration: 2000,
      })
    } else {
      ToastStore.networkError()
    }
  }

  async clickMatch({ match, index }) {
    if (match.type === 'event' && match.emojiId === 5) {
      backendApis.THincrease({
        option: { upsert: true },
        inc: {
          lunchFairyFoundCount: 1,
        },
        collection: 'achievement',
      })
      THUserStore?.increase('achievement.lunchFairyFoundCount', 1)
    }
    if (match.type === 'specialAnimal' && match.tag === '특별') {
      backendApis.THincrease({
        option: { upsert: true },
        inc: {
          specialAnimalFoundCount: 1,
        },
        collection: 'achievement',
      })
      THUserStore?.increase('achievement.specialAnimalFoundCount', 1)
    }
    const result = await backendApis.clickMatchTH({ match, index })
    if (result?.status === 200) {
      THUserStore.increase('point', result?.data?.point)
      THUserStore.increase('dailyPoint', result?.data?.point)
      THUserStore.push('clickedIndices', index)
      if (result?.data?.isAllCleared) {
        setTimeout(() => {
          THUserStore.set('isAllCleared', true)
          if (THUserStore?.tutorialStep === 2) {
            this.setTutorialStep(3)
            this.setTutorialMinorStep(6)
          }
          THUserStore.set('clickedIndices', [])
        }, 950)
      }
      if (result?.data?.foundFriend) {
        THModalStore.setType({
          type: 'reward',
          config: {
            rewardName: '보물친구 발견!',
            description: `보물친구 발견 보상을 받으세요!`,
            src: `../images/treasureHunt/emoji/face/16.png`,
            text: '보물친구한테 가기',
            onClick: () => {
              const friend = THUserStore?.friends?.find(
                (item) =>
                  item?.friendId?.toString() === match?.userId?.toString(),
              )
              const missions = friend?.missions ?? []
              const target = missions.find((item) => item?.tag === 'find')
              target.type = 'reward'
              friend.lastFoundAt = new Date()
              const targetIndex = missions?.friends?.findIndex(
                (item) => item?.tag === 'find',
              )
              missions[targetIndex] = target
              friend.missions = missions
              const friendIndex = THUserStore?.friends?.findIndex(
                (item) =>
                  item?.friendId?.toString() === match?.userId?.toString(),
              )
              const friends = [...THUserStore?.friends]
              friends[friendIndex] = friend
              THUserStore.set('friends', friends)
              THUserStore?.set('currentFriend', friend)
              RouteStore?.navigate(
                `/treasureHunt/friend?token=${AuthStore?.token}`,
              )
            },
          },
        })
      }

      if (result?.data?.missionCleared) {
        let temp = THUserStore?.dailyMission?.missions
        temp = temp?.map((mission) => {
          if (
            mission?._id?.toString() ===
            result?.data?.missionCleared?._id?.toString()
          ) {
            return { ...mission, clearedAt: new Date() }
          }
          return mission
        })
        THUserStore.set('dailyMission', {
          ...THUserStore?.dailyMission,
          missions: temp,
        })
        THModalStore.setType({
          type: 'reward',
          config: {
            rewardName: '미션 완료',
            description: `보상 받기 버튼을 클릭해서 보상을 받으세요`,
            src: `../images/treasureHunt/confetti.png`,
            text: '확인',
            onClick: () => {
              THBottomSheetStore.setType({
                type: 'mission',
                config: {},
              })
            },
          },
        })
      }
      if (result?.data?.limitReached) {
        THModalStore.setType({
          type: 'reward',
          config: {
            rewardName: '일일 보상 한도 달성',
            description: `오늘 받을 수 있는 보상을 다 받았어요!\n내일 다시 탐색해보세요!`,
            src: `../images/treasureHunt/point.png`,
            text: '닫기',
          },
        })
      }
      if (!result?.data?.isAlreadyCaptured) {
        // THModalStore.setType({
        //   type: 'reward',
        //   config: {
        //     rewardName: '새로운 캐릭터 발견!',
        //     description: `캐릭터 도감을 확인해보세요!`,
        //     src: `../images/treasureHunt/emoji/${match?.type}/${match?.emojiId}.png`,
        //   },
        // })
        if (THUserStore?.lastFindTreasureAt) {
          ToastStore.toastOn({
            type: 'emoji',
            emoji: '🤩',
            message: '새로운 캐릭터 발견! 도감을 확인해보세요',
            duration: 2000,
          })
        }

        const temp = THUserStore?.collection?.[match?.type] ?? []
        temp.push({
          emojiId: match?.emojiId,
          capturedAt: new Date(),
        })
        THUserStore.set('collection', {
          ...THUserStore?.collection,
          [match?.type]: temp,
        })
        THUserStore.set('collecitonPingOn', true)
        window.localStorage.setItem('collecitonPingOn', 'true')
      }
      this.haptic()
      // 보상 애니메이션 시작
    } else if (result?.status === 201) {
      ToastStore.toastOn({
        type: 'error',
        message: '이미 보상을 받았어요!',
        duration: 2000,
      })
    } else {
      ToastStore.networkError()
    }
  }

  async getMissionReward({ mission }) {
    const result = await backendApis.getMissionRewardTH({ mission })
    if (result?.status === 200) {
      // 모달 띄우기
      THModalStore.setType({
        type: 'reward',
        config: {
          rewardName: '보상 획득',
          description: `미션 완료 보상으로 <${mission?.rewardType}> ${
            mission?.rewardAmount
          }${translateUnitVariable(mission?.rewardType)}  획득!`,
          src: `../images/treasureHunt/${mission.rewardType}.png`,
          onClick: () => {
            this.haptic()
          },
          text: '받기',
        },
      })
      // 미션 완료 딱지
      let temp = THUserStore?.dailyMission?.missions
      temp = temp?.map((item) => {
        if (mission?._id?.toString() === item?._id?.toString()) {
          return { ...item, receivedAt: new Date() }
        }
        return item
      })
      const tempMatchList = [...THUserStore?.matchList]
      for (const index of THUserStore?.clickedIndices) {
        tempMatchList[index].clickedAt = new Date()
      }
      THUserStore.set('matchList', tempMatchList)
      THUserStore.set('dailyMission', {
        ...THUserStore?.dailyMission,
        missions: temp,
      })
      THUserStore.increase(mission?.rewardType, mission?.rewardAmount)
      if (mission?.rewardType === 'point') {
        THUserStore.increase('dailyPoint', mission?.rewardAmount)
      }
      THUserStore.increase('dailyMissionClearCount.count', 1)
      window.localStorage.setItem(
        'dailyMissionClearCount',
        JSON.stringify({
          date: moment().format('YYYY-MM-DD'),
          count: THUserStore?.dailyMissionClearCount?.count,
        }),
      )
    } else if (result?.status === 201) {
      ToastStore.toastOn({
        type: 'error',
        message: '미션을 완료하지 못했어요!',
        duration: 2000,
      })
    } else if (result?.status === 202) {
      ToastStore.toastOn({
        type: 'error',
        message: '이미 보상을 받았어요!',
        duration: 2000,
      })
    } else if (result?.status === 203) {
      ToastStore.toastOn({
        type: 'error',
        message: '미션을 찾지 못했어요!',
        duration: 2000,
      })
    } else {
      ToastStore.networkError()
    }
  }

  async energyCooltime() {
    const res = await backendApis.energyCooltime()
    if (res?.status === 200) {
      THUserStore.increase('energy', 1)
      THUserStore.increase('dailyEnergy', 1)
      THUserStore.set('lastEnergyChargeAt', new Date())
      THUserStore.set('isChargingEnergy', false)
      setTimeout(() => {
        THUserStore.set('isChargingEnergy', true)
      }, 200)
      this.haptic()
    } else {
      ToastStore.networkError()
    }
  }

  async getDailyPointBonusData() {
    const res = await backendApis.getDailyPointBonusData()
    if (res?.status === 200) {
      THModalStore.setType({
        type: 'dailyPointBonus',
        config: {
          tomorowRewardAmount: res?.data?.tomorowRewardAmount,
          rewardAmount: res?.data?.rewardAmount,
        },
      })
      THUserStore.set('dailyPointBonus', {
        ...THUserStore?.dailyPointBonus,
        isReserved: false,
      })
    } else {
      ToastStore.networkError()
    }
  }

  async getDailyPointBonus({ selected, tomorowRewardAmount }) {
    const res = await backendApis.getDailyPointBonus({ selected })
    if (res?.status === 200) {
      if (selected === 'tomorrow') {
        THModalStore.setType({
          type: 'reward',
          config: {
            rewardName: '예약 성공!',
            description: `내일 들어오면 보너스가 자동 지급돼요`,
            src: `../images/treasureHunt/emoji/face/28.png`,
            text: '확인',
          },
        })
        THUserStore.set('dailyPointBonus', {
          ...THUserStore?.dailyPointBonus,
          isReserved: true,
          tomorowRewardAmount,
        })
      } else {
        THModalStore.setType({
          type: 'reward',
          config: {
            rewardName: '보상 받기 성공!',
            description: `보너스로 ${res?.data?.rewardAmount}원을 받았어요`,
            src: `../images/treasureHunt/point.png`,
            text: '받기',
          },
        })
        THUserStore.increase('point', res?.data?.rewardAmount)
        THUserStore.increase('dailyPoint', res?.data?.rewardAmount)
      }

      THUserStore.set('dailyPointBonusAt', res?.data?.dailyPointBonusAt)
      THModalStore?.close()

      this.haptic()
    } else {
      ToastStore.networkError()
    }
  }

  async collectPetGift() {
    const res = await backendApis.collectPetGift()
    if (res?.status === 200) {
      THModalStore.setType({
        type: 'reward',
        config: {
          rewardName: '기념품 받기 성공!',
          description: `야옹이가 <${res?.data?.rewardType}> ${
            res?.data?.rewardAmount
          }${translateUnitVariable(res?.data?.rewardType)}을 선물했어요!`,
          src: `../images/treasureHunt/${res?.data?.rewardType}.png`,
          text: '받기',
        },
      })
      if (res?.data?.rewardType === 'point') {
        THUserStore.increase('point', res?.data?.rewardAmount)
        THUserStore.increase('dailyPoint', res?.data?.rewardAmount)
      } else {
        THUserStore.increase('energy', res?.data?.rewardAmount)
      }

      THUserStore.set('pet', res?.data?.pet)
      this.haptic()
    } else {
      ToastStore.networkError()
    }
  }

  async sendPetTravel() {
    const res = await backendApis.sendPetTravel()
    if (res?.status === 200) {
      THModalStore.setType({
        type: 'reward',
        config: {
          rewardName: '여행 보내기 성공!',
          description: `야옹이가 여행을 떠났어요!`,
          src: `../images/treasureHunt/pet/2.png`,
          text: '확인',
        },
      })
      THUserStore.set('pet', res?.data?.pet)
    } else {
      ToastStore.networkError()
    }
  }

  async alarmAgree() {}

  async setTutorialStep(num) {
    backendApis.THSet({
      set: {
        tutorialStep: num,
      },
      collection: 'user',
    })
    THUserStore?.set('tutorialStep', num)
  }

  async setTutorialMinorStep(num) {
    backendApis.THSet({
      set: {
        tutorialMinorStep: num,
      },
      collection: 'user',
    })
    THUserStore?.set('tutorialMinorStep', num)
  }

  async setOldTutorialOn() {
    backendApis.THSet({
      set: {
        oldTutorial: true,
      },
      collection: 'user',
    })
  }

  async endTutorial() {
    backendApis.THSet({
      set: {
        tutorialEndAt: new Date(),
      },
      collection: 'user',
    })
    THUserStore?.set('tutorialEndAt', new Date())
    THModalStore.showNextModal()
  }

  async fakeFindTreasure() {
    setTimeout(async () => {
      THUserStore?.set('isFindingTreasure', false)

      const result = await backendApis.findTreasureTH({
        gpsData: { left: 0, right: 0 },
        fakeSearch: true,
      })

      if (result?.status === 200) {
        const matchList = [...result?.data?.matchList]
        setTimeout(() => {
          THUserStore.set('isFindingTreasure', false)
        }, 2000)
        THUserStore.set('matchList', matchList)
        THUserStore.set('isAllCleared', false)
        if (THUserStore?.energy === THUserStore?.maxEnergy) {
          THUserStore.set('lastEnergyChargeAt', new Date())
        }
        THUserStore.increase('energy', -1)
        this.setTutorialMinorStep(5)
      } else if (result?.status === 201) {
        ToastStore.toastOn({
          type: 'error',
          message: '아직 클릭하지 않은 보상이 있어요!',
          duration: 2000,
        })
      } else if (result?.status === 202) {
        ToastStore.toastOn({
          type: 'error',
          message: '번개가 부족해요! ⚡️',
          duration: 2000,
        })
      } else {
        ToastStore.networkError()
      }
    }, 2000)
  }

  async linkShare({
    shareType,
    title,
    description,
    imageUrl,
    itemId,
    payload,
  }) {
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'share',
        data: {
          shareType,
          title,
          description,
          imageUrl,
          itemId,
          payload,
        },
      }),
    )
  }

  async kakaoLinkShare({
    shareType,
    title,
    description,
    imageUrl,
    itemId,
    payload,
    templateId,
  }) {
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'kakaoShare',
        data: {
          shareType,
          title,
          description,
          imageUrl,
          itemId,
          payload,
          templateId,
        },
      }),
    )
  }

  async deleteFriend({ friendId }) {
    const result = await backendApis.deleteFriend({ friendId })
    if (result?.status === 200) {
      THUserStore.set('friends', result?.data?.friends)
      THUserStore.set('currentFriend', {
        ...result?.data?.friends?.[0],
        index: 0,
      })
    } else if (result?.status === 201) {
      THModalStore.setType({
        type: 'reward',
        config: {
          rewardName: '친구 삭제 실패',
          description: `오늘은 이미 친구를 삭제했어요`,
          src: `../images/treasureHunt/emoji/face/57.png`,
          text: '확인',
        },
      })
    } else {
      ToastStore.networkError()
    }
  }

  async friendHello() {
    const result = await backendApis.friendMissionExecute({
      friend: THUserStore?.currentFriend,
      tag: 'hello',
    })
    if (result?.status === 200) {
      THUserStore.set('currentFriend', {
        ...result?.data?.currentFriend,
        index: THUserStore?.currentFriend?.index,
      })
      const temp = [...THUserStore?.friends]
      temp[THUserStore?.currentFriend?.index] = {
        ...result?.data?.currentFriend,
        index: THUserStore?.currentFriend?.index,
      }
      THUserStore.set('friends', temp)
      console.log(temp)
      THModalStore.setType({
        type: 'reward',
        config: {
          rewardName: '인사하기 성공!',
          description: `친구에게 인사를 보냈어요!\n이제 <gift>선물상자 보상을 받아보세요!`,
          src: `../images/treasureHunt/emoji/face/17.png`,
          text: '확인',
        },
      })
    } else {
      ToastStore.networkError()
    }
  }

  async friendGiveGift() {
    const result = await backendApis.friendMissionExecute({
      friend: THUserStore?.currentFriend,
      tag: 'gift',
    })
    if (result?.status === 200) {
      THUserStore.set('currentFriend', {
        ...result?.data?.currentFriend,
        index: THUserStore?.currentFriend?.index,
      })
      const temp = [...THUserStore?.friends]
      temp[THUserStore?.currentFriend?.index] = {
        ...result?.data?.currentFriend,
      }
      THUserStore.set('friends', temp)
      THModalStore.setType({
        type: 'reward',
        config: {
          rewardName: '선물하기 성공!',
          description: `친구에게 성공적으로 선물을 보냈어요!`,
          src: `../images/treasureHunt/emoji/face/17.png`,
          text: '확인',
        },
      })
    } else {
      ToastStore.networkError()
    }
  }

  async friendNudgeToSearch() {
    // 오늘 찔렀는지 로컬스토리지에서 확인하기
    const friendNudgeSearch = window.localStorage.getItem(
      `friendNudgeSearch_${THUserStore?.currentFriend?.friendId?.toString()}`,
    )
    if (moment().format('YYYY-MM-DD') === friendNudgeSearch) {
      ToastStore.toastOn({
        type: 'error',
        message: '오늘은 이미 콕! 찔렀어요',
        duration: 2000,
      })
      return
    }
    // 공유 트리거 하기
    const result = await backendApis.friendMissionExecute({
      friend: THUserStore?.currentFriend,
      tag: 'search',
    })
    if (result?.status === 200) {
      THModalStore.setType({
        type: 'reward',
        config: {
          rewardName: '콕 찌르기 성공!',
          description: `친구에게 메세지를 전달했어요!`,
          src: `../images/treasureHunt/emoji/face/17.png`,
          text: '확인',
        },
      })
      window.localStorage.setItem(
        `friendNudgeSearch_${THUserStore?.currentFriend?.friendId?.toString()}`,
        moment().format('YYYY-MM-DD'),
      )
    } else {
      ToastStore.networkError()
    }
    // 모달띄우기
  }

  async friendFind() {
    // 오늘 넛지 했는지 로컬스토리지에서 확인하기ㅛㅕ
    // 공유 트리거 하기
    // 모달띄우기
  }

  async rewardFriendMission({ tag }) {
    // 보상 지급
    // rewardAt 셋하기
    // 모달띄우기
    const result = await backendApis.rewardFriendMission({
      friend: THUserStore?.currentFriend,
      tag,
    })
    if (result?.status === 200) {
      THUserStore.set('currentFriend', {
        ...result?.data?.currentFriend,
        index: THUserStore?.currentFriend?.index,
      })
      const temp = [...THUserStore?.friends]
      temp[THUserStore?.currentFriend?.index] = {
        ...result?.data?.currentFriend,
      }
      THUserStore.set('friends', temp)
      if (tag === 'hello') {
        THModalStore.setType({
          type: 'reward',
          config: {
            rewardName: '인사미션 성공!',
            description: `인사 미션 보상을 받았어요!\n<gift>1개 획득!`,
            src: `../images/treasureHunt/emoji/face/17.png`,
            text: '보상 받기',
          },
        })
      } else if (tag === 'search') {
        THModalStore.setType({
          type: 'reward',
          config: {
            rewardName: '탐색시키기 미션 성공!',
            description: `탐색시키기 미션 보상을 받았어요!\n<gift>1개 획득!`,
            src: `../images/treasureHunt/emoji/face/17.png`,
            text: '보상 받기',
          },
        })
      } else if (tag === 'find') {
        THModalStore.setType({
          type: 'reward',
          config: {
            rewardName: '발견하기 미션 성공!',
            description: `발견하기 미션 보상을 받았어요!\n<gift>1개 획득!`,
            src: `../images/treasureHunt/emoji/face/17.png`,
            text: '보상 받기',
          },
        })
      }

      THUserStore.increase('gift', 1)
    } else {
      ToastStore.networkError()
    }
  }

  async openGift() {
    if (THUserStore?.gift <= 0) {
      ToastStore.toastOn({
        type: 'error',
        message: '선물이 없어요!',
        duration: 2000,
      })
      return
    }
    const result = await backendApis.openGift()
    if (result?.status === 200) {
      THUserStore?.increase('gift', -1)

      if (result?.data?.rewardAmount === 0) {
        THModalStore.setType({
          type: 'reward',
          config: {
            rewardName: '선물상자 열기',
            description: `이런.. 꽝이 나왔어요!`,
            src: `../images/treasureHunt/emoji/face/80.png`,
            text: '보상 받기',
          },
        })
      } else {
        THModalStore.setType({
          type: 'reward',
          config: {
            rewardName: '선물상자 열기',
            description: `선물상자에서 <${result?.data?.rewardType}> ${
              result?.data?.rewardAmount
            }${translateUnitVariable(result?.data?.rewardType)} 획득!`,
            src: `../images/treasureHunt/${result?.data?.rewardType}.png`,
            text: '보상 받기',
          },
        })
        if (result?.data?.rewardType === 'point') {
          THUserStore.increase('dailyPoint', result?.data?.rewardAmount)
        }
        THUserStore?.increase(
          result?.data?.rewardType,
          result?.data?.rewardAmount,
        )
      }
    } else if (result?.status === 201) {
      ToastStore.toastOn({
        type: 'error',
        message: '선물이 없어요!',
        duration: 2000,
      })
    } else {
      ToastStore.networkError()
    }
  }

  async giveMissionReward({ type }) {
    const result = await backendApis.giveMissionReward({ type })
    if (result?.status === 200) {
      THUserStore.increase('dailyMissionClearCount.count', 1)
      window.localStorage.setItem(
        'dailyMissionClearCount',
        JSON.stringify({
          date: moment().format('YYYY-MM-DD'),
          count: THUserStore?.dailyMissionClearCount?.count,
        }),
      )
      THUserStore.set('dailyMission', result?.data?.dailyMission)
      THModalStore.setType({
        type: 'reward',
        config: {
          rewardName: '미션 성공!',
          description: `미션 보상으로 <${result?.data?.rewardType}> ${
            result?.data?.rewardAmount
          }${translateUnitVariable(result?.data?.rewardType)} 획득!`,
          src: `../images/treasureHunt/${result?.data?.rewardType}.png`,
          text: '받기',
        },
      })
      THUserStore.increase(result?.data?.rewardType, result?.data?.rewardAmount)
    } else if (result?.status === 204) {
      THModalStore.setType({
        type: 'reward',
        config: {
          rewardName: '미션 실패',
          description: `시간이 초과되었어요!`,
          src: `../images/treasureHunt/emoji/face/77.png`,
          text: '확인',
        },
      })
    } else {
      ToastStore.networkError()
    }
  }

  async startUploadChallenge() {
    const result = await backendApis.startUploadChallenge()
    if (result?.status === 200) {
      THUserStore.set('uploadChallenge', {
        ...result?.data,
      })
      THBottomSheetStore.setType({
        type: 'uploadChallenge',
        config: {
          title: `${
            ABStore?.uploadChallenge === 'b' ? '삼행시 챌린지' : '게시글 챌린지'
          }`,
        },
      })
    } else {
      ToastStore.networkError()
    }
  }

  async giveUploadChallengeReward() {
    const result = await backendApis.giveUploadChallengeReward()
    if (result?.status === 200) {
      THUserStore.set('uploadChallenge', {
        ...THUserStore?.uploadChallenge,
        isEnd: true,
      })
      THModalStore.setType({
        type: 'reward',
        config: {
          rewardName: '챌린지 완료',
          description: `보상으로 <point> ${result?.data?.rewardAmount}원을 받았어요!`,
          src: `../images/treasureHunt/point.png`,
          text: '보상 받기',
        },
      })
      THBottomSheetStore.close()
      THUserStore.increase('point', result?.data?.rewardAmount)
      THUserStore.increase('dailyPoint', result?.data?.rewardAmount)
    } else {
      ToastStore.networkError()
    }
  }

  async openStarbox({ type }) {
    const result = await backendApis.openStarbox({ type })
    if (result?.status === 200) {
      THUserStore.increase(type, -1)
      THUserStore.increase('luck', result?.data?.luckAdded)
    } else if (result?.status === 201) {
      THModalStore.setType({
        type: 'reward',
        config: {
          rewardName: '별상자 사용 실패',
          description: `별상자가 부족해요!`,
          src: `../images/treasureHunt/emoji/face/77.png`,
          text: '확인',
        },
      })
    } else {
      ToastStore.networkError()
    }
  }

  async giveReward({ rewardAmount, rewardType, tag }) {
    const result = await backendApis.giveReward({
      rewardAmount,
      rewardType,
      tag,
    })
    if (result?.status === 200) {
      THUserStore.increase(rewardType, rewardAmount)
      if (rewardType === 'point') {
        THUserStore.increase('dailyPoint', rewardAmount)
      }
    } else {
      ToastStore.networkError()
    }
  }

  async sendRN(type) {
    if (type === 'cashback') {
      const payload = {
        enteringComponent: 'TreasureHunt_CashBack',
        text: '보물찾기',
        source:
          'https://assets.ilevit.com/2d50b4d9-5fcd-4d5f-bd4d-0ebc6d8cecd2.png',
        myPoint: `${commaNumber(THUserStore?.point)}원` ?? '로딩 중',
      }
      this.navigateRN({
        screen: 'TreasureHuntPurchaseScreen',
        payload,
      })
    } else if (type === 'goldOnly') {
      const payload = {
        enteringComponent: 'TreasureHunt_Gold',
        text: '보물찾기',
        source:
          'https://assets.ilevit.com/a89aa2a3-1b0b-43d3-910c-136b83c8dc92.png',
        loadTreasureHuntItemsForRevenue: true,
        rewardText: `상품을 구매하고 황금클로버 8개를 받아보세요`,
      }
      this.navigateRN({
        screen: 'TreasureHuntMissionPurchaseScreen',
        payload,
      })
    } else if (type === 'goldOnlyEnergy') {
      const payload = {
        enteringComponent: 'TreasureHunt_GoldEnergy',
        text: '보물찾기',
        source:
          'https://assets.ilevit.com/6d2fb11f-0ace-422e-a7f5-368da8516638.png',
        loadTreasureHuntItemsForRevenue: true,
        rewardText: `상품을 구매하고 에너지 10개를 받아보세요`,
      }
      this.navigateRN({
        screen: 'TreasureHuntMissionPurchaseScreen',
        payload,
      })
    }
  }

  async purchaseScratchCard() {
    const result = await backendApis.purchaseScratchCard()
    if (result?.status === 200) {
      THUserStore.increase('point', -5)
      THModalStore.setType({
        type: 'scratch',
        config: {
          winningNumber: result?.data?.winningNumber,
          myNumberOne: result?.data?.myNumberOne,
          myNumberTwo: result?.data?.myNumberTwo,
          rewardOne: result?.data?.rewardOne,
          rewardTwo: result?.data?.rewardTwo,
          reward: result?.data?.reward,
        },
      })
      backendApis.THincrease({
        option: { upsert: true },
        inc: {
          scartchPurchaseCount: 1,
        },
        collection: 'achievement',
      })
      THUserStore?.increase('achievement.scartchPurchaseCount', 1)
    } else if (result?.status === 201) {
      ToastStore.toastOn({
        type: 'error',
        message: '캐시가 부족해요!',
        duration: 2000,
      })
    } else {
      ToastStore.networkError()
    }
  }

  async achievementHandler({ type, level }) {
    if (!THUserStore?.achievement?.[type]?.[level]?.clearedAt) {
      const result = await backendApis.achievementHandler({ type, level })
      if (result?.status === 200) {
        THUserStore?.set('achievement', result?.data?.achievement)
        THModalStore.setType({
          type: 'reward',
          config: {
            rewardName: '업적 달성!',
            description: `업적 보상으로 <${result?.data?.rewardType}> ${
              result?.data?.rewardAmount
            }${translateUnitVariable(result?.data?.rewardType)} 획득!`,
            src: `../images/treasureHunt/${result?.data?.rewardType}.png`,
            text: '받기',
            onClick: () => {
              this.haptic()
              THBottomSheetStore.setType({
                type: 'achievement',
                config: {},
              })
            },
          },
        })
      } else if (result?.status === 201) {
        // console.log('done')
        // } else {
        ToastStore.networkError()
      }
    }
  }

  async handleEventPopUp({ item }) {
    const { type, emojiId } = item
    if (type === 'event') {
      if (emojiId === 14) {
        THUserStore.set('goldOnlyEndAt', moment().add(3, 'h').toDate())
      }
      if (emojiId === 12) {
        THUserStore.set('cashbackEndAt', moment().add(3, 'h').toDate())
      }
      if (emojiId === 13) {
        THUserStore.set('bambooInviteEndt', moment().add(3, 'h').toDate())
      }
      if (emojiId === 10) {
        THUserStore.set('diceEventPurchaseEndAt', moment().add(3, 'h').toDate())
      }
      if (emojiId === 12) {
        THUserStore.set('cashbackEndAt', moment().add(3, 'h').toDate())
      }
      if (emojiId === 11 && item?.modalData?.config?.isFree) {
        THUserStore.set(
          'searchButtonEnforceEndAt',
          moment().add(1, 'd').toDate(),
        )
      }
    }
  }

  async selectCloset({ closetType, closetId }) {
    THUserStore.set('characterInfo.closetType', closetType)
    THUserStore.set('characterInfo.closetId', closetId)
    backendApis.THSet({
      set: {
        'characterInfo.closetType': closetType,
        'characterInfo.closetId': closetId,
      },
      collection: 'user',
    })
  }

  async selectClosetFace({ emojiId }) {
    THUserStore.set('characterInfo.emojiId', emojiId)
    backendApis.THSet({
      set: {
        'characterInfo.emojiId': emojiId,
      },
      collection: 'user',
    })
  }

  async closetDraw() {
    const result = await backendApis.closetDraw()
    if (result?.status === 200) {
      THModalStore.setType({
        type: 'reward',
        config: {
          rewardName: `축하해요!`,
          description: `${result?.data?.itemTitle} 획득했어요!`,
          src: `../images/treasureHunt/closet/showcase/${result?.data?.closetType}/${result?.data?.closetId}.png`,
          text: '받기',
          earnEffect: true,
        },
      })
      THUserStore.set('closet', result?.data?.closet)
      THUserStore.increase('point', -300)
      this.haptic()
    } else if (result?.status === 201) {
      ToastStore.toastOn({
        type: 'error',
        message: '캐시가 부족해요!',
        duration: 2000,
      })
    } else if (result?.status === 202) {
      ToastStore.toastOn({
        type: 'error',
        message: '더 뽑을 장식이 없어요!',
        duration: 2000,
      })
    } else {
      ToastStore.networkError()
    }
  }

  async giveGoldenEggReward() {
    const result = await backendApis.giveGoldenEggReward()
    if (result?.status === 200) {
      THModalStore.setType({
        type: 'reward',
        config: {
          rewardName: `황금 클로버`,
          description: `<starboxTierTwo> 2개를 획득했어요`,
          src: `../images/treasureHunt/starboxTierTwo.png`,
          text: '받기',
        },
      })

      THUserStore.increase('starboxTierTwo', 2)
      this.haptic()
    } else {
      ToastStore.networkError()
    }
  }

  async energyMachineReward() {
    const result = await backendApis.energyMachineReward()
    if (result?.status === 200) {
      THModalStore.setType({
        type: 'reward',
        config: {
          rewardName: `번개생산기`,
          description: `번개생산기로 <energy> 10개를 획득했어요`,
          src: `../images/treasureHunt/energy.png`,
          text: '받기',
        },
      })

      THUserStore.increase('energy', 10)
      THUserStore.set('energyMachineReceivedAt', new Date())
      this.haptic()
    } else if (result?.status === 201) {
      ToastStore.toastOn({
        message: '오늘은 이미 번개를 받으셨어요!',
        duration: 2000,
        type: 'emoji',
        emoji: '😘',
      })
    } else if (result?.status === 202) {
      ToastStore.toastOn({
        message: '번개생산기가 유효하지 않아요 !',
        duration: 2000,
        type: 'emoji',
        emoji: '😘',
      })
    } else {
      ToastStore.networkError()
    }
  }
}

export default new TreasureHuntAction()
