import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import TreasureHuntAction from '../actions/TreasureHuntAction'
import TreasureHuntButton from '../components/treasureHunt/TreasureHuntButton'
import AuthStore from '../stores/AuthStore'
import UserStore from '../stores/UserStore'
import WatermelonUserStore from '../stores/WatermelonUserStore'
import THBottomSheetStore from '../stores/treasureHunt/THBottomSheetStore'
import backendApis from '../utils/backendApis'

const MVNOPreLaunchCarousel = observer(() => {
  const [carouselIndex, setCarouselIndex] = useState(1)
  const [isFading, setIsFading] = useState(true)
  const [isChanging, setIsChanging] = useState(false) // New state to manage transition

  useEffect(() => {
    if (isChanging) {
      setIsFading(false) // Start with fade-out
      const timer2 = setTimeout(() => {
        setCarouselIndex((prev) => prev + 1)
      }, 250) // Wait for 500ms for the fade-out to complete
      const timer1 = setTimeout(() => {
        setIsFading(true) // Then fade-in
        setIsChanging(false) // Reset isChanging state
      }, 500) // Wait for 500ms for the fade-out to complete

      return () => {
        clearTimeout(timer1)
        clearTimeout(timer2)
      }
    }
  }, [isChanging])

  const handleButtonClick = async () => {
    THBottomSheetStore.setType({
      type: 'mvnoPreLaunch',
    })
  }

  const renderDescription = (text) =>
    text.split('\n').map((line, index) => (
      <span key={Math.random()}>
        {line}
        {index < text.split('\n').length - 1 && <br />}
      </span>
    ))

  const Button = ({ title, description, price, lowPrice, gap }) => {
    const [clicked, setClicked] = useState(false)

    if (!clicked) {
      return (
        <div
          onClick={() => {
            setClicked(true)
          }}
          className='flex flex-row justify-center items-center border-[#f8afac] border-2 p-2 rounded-xl my-2 bg-[#FFF3F2] shadow-md'
        >
          <div className='h-[20vw] text-[5vw] flex justify-center items-center rounded-xl font-bold mr-4 '>
            {`${description}? 클릭!`}
          </div>
        </div>
      )
    }

    return (
      <div className='flex flex-row justify-center items-center border-[#ef8a85] border-2 p-2 rounded-xl my-2'>
        <div className='bg-[#FFF3F2] text-[#FF5951] w-[20vw] h-[20vw] flex justify-center items-center rounded-xl font-bold mr-4'>
          {title}
        </div>
        <div className='flex-1 flex flex-col justify-start items-center'>
          <div className='flex-1 flex w-full font-bold items-end text-[#000] text-[6vw]'>
            {`${price}`}&nbsp;
            <span className='text-[4.5vw] text-[#FF5951]'>{`${gap} 할인`}</span>
          </div>
          <div className='flex-1 flex w-full text-[3vw] text-[#727886]'>
            {`현재 알뜰폰 최저가: ${lowPrice}`}&nbsp;
          </div>
        </div>
      </div>
    )
  }

  if (WatermelonUserStore.mvnoRegistered) {
    return (
      <div className='fixed flex flex-col w-full h-full text-[#313138] bg-white p-4 text-[4vw] overflow-y-scroll pb-[30vw]'>
        <div className='flex flex-col mt-4'>
          <div className='font-bold text-[#313138] text-[6vw]'>
            사전 신청 등록 성공
          </div>
          <div className='font-bold text-[#313138] text-[6vw]'>
            등록해주셔서 감사합니다!
          </div>

          <img
            className='mt-6'
            src='../images/mvnoPreLaunch/registered.png'
            alt='registered'
          />

          <div className='font-bold text-[#313138] text-[5vw] mt-[10vw]'>
            정식 출시에 맞춰서
          </div>
          <div className='font-bold text-[#313138] text-[5vw]'>
            등록하신 번호로 안내드리겠습니다.
          </div>
        </div>

        {/* <div className='fixed w-full p-4 z-[11] bottom-0 left-[50%] -translate-x-1/2'>
          <TreasureHuntButton
            className='border-white text-[5vw]'
            style={{ width: '100%', padding: '4vw' }}
            clickable
            text='공유하기'
            onClick={() => {
              backendApis.commonInsert({
                data: {
                  type: 'clickShareButton',
                  gameType: WatermelonUserStore?.gameType,
                },
                collection: 'mvno_clickLog',
              })
              TreasureHuntAction.linkShare({
                shareType: 'Watermelon_LinkShare',
                title: '[양파게임] 무료 양파 1Kg에 도전하세요!',
                description: '작물을 합쳐서 양파를 만들어내면 양파 1Kg가 무료!',
                imageUrl:
                  'https://assets.ilevit.com/e9741bf3-54c4-4fef-aa5c-3c80292086e1.png',
                payload: {
                  link: 'https://alwayzshop.page.link/jsYwkTF2PcueiXZ39',
                },
                templateId: 100565,
              })
            }}
          />
        </div> */}
      </div>
    )
  }

  return (
    <div className='fixed flex flex-col w-full h-full text-[#313138] bg-white p-4 text-[4vw] overflow-y-scroll pb-[30vw]'>
      <div className='flex flex-col mt-4'>
        <div className='font-bold text-[#313138] text-[6vw]'>
          초저가에 진심인,
        </div>
        <div className='font-bold text-[#313138] text-[6vw]'>
          <span className='text-[#FF5951]'>올웨이즈 모바일</span> 출시 예정!
        </div>
      </div>

      <div className='mt-2 text-[#727886]'>
        알뜰폰의 본질은 <span className='font-bold'>가격</span>입니다
      </div>
      <div className='text-[#727886]'>
        본질에 집중한 <span className='font-bold'>우주에서 제일 싼 요금제</span>
      </div>

      <div className='flex flex-col w-full'>
        <div className='font-bold text-[5vw] mb-2 mt-6'>요금제 확인하기</div>
        <Button
          title='월 7GB'
          description='웹 서핑과 카톡만 한다면'
          lowPrice='16,400원'
          price='12,900원'
          gap='21%'
        />
        <Button
          title='월 15GB'
          description='밖에서 음악을 많이 듣는다면'
          lowPrice='20,900원'
          price='15,900원'
          gap='24%'
        />
        <Button
          title='매일 2GB'
          description='매일 1시간 이상 영상을 본다면'
          lowPrice='35,900원'
          price='29,900원'
          gap='20%'
        />
        <Button
          title='월 100GB'
          description='걱정 없이 맘껏 쓰고 싶다면'
          lowPrice='38,200원'
          price='33,900원'
          gap='11%'
        />
        <div className='text-[#727886] text-[3vw] flex self-end'>
          *월 제공 데이터 소진시 1Mbps로 무제한, 통화/문자 무제한
        </div>
      </div>

      <div className='flex flex-col mt-6'>
        <div className='font-bold text-[#313138] text-[6vw]'>
          <span className='text-[#FF5951]'>올웨이즈</span>에서 초저가 쇼핑하고
        </div>
        <div className='font-bold text-[#313138] text-[6vw]'>
          초저가 요금제로 통신비 절약하자!
        </div>
        <div className='mt-2 text-[#727886]'>복잡한 혜택은 그만!</div>
        <div className='text-[#727886]'>
          '진짜' <span className='font-bold'>원가 이하</span>로 내려가는 요금제,
          들어보셨습니까?
        </div>
      </div>

      <div className='flex flex-row w-full justify-center items-center mt-4 space-x-4'>
        <div className='flex-1 flex flex-col p-4 bg-[#F6F7F9] justify-center items-center rounded-xl'>
          <div className='bg-white rounded-full text-[#FF5951] p-2 font-bold py-1'>
            Step 1
          </div>
          <img
            className='w-[20vw] h-[20vw]'
            src='../images/mvnoPreLaunch/shop.png'
            alt='shop'
          />
          <div className='font-bold text-[#313138] text-[4vw] text-center'>
            {renderDescription(`초저가 쇼핑\n월 3만원 이상`)}
          </div>
        </div>
        <div className='flex-1 flex flex-col p-4 bg-[#F6F7F9] justify-center items-center rounded-xl'>
          <div className='bg-white rounded-full text-[#FF5951] p-2 font-bold py-1'>
            Step 2
          </div>
          <img
            className='w-[20vw] h-[20vw]'
            src='../images/mvnoPreLaunch/coin.png'
            alt='coin'
          />
          <div className='font-bold text-[#313138] text-[4vw] text-center'>
            {renderDescription(`알뜰폰보다 알뜰한\n초저가 요금제 GET!`)}
          </div>
        </div>
      </div>

      <div className='fixed w-full p-4 z-[11] bottom-0 left-[50%] -translate-x-1/2'>
        <TreasureHuntButton
          className='border-white text-[5vw]'
          style={{ width: '100%', padding: '4vw' }}
          clickable
          text='사전 신청하기'
          onClick={handleButtonClick}
        />
      </div>
    </div>
  )
})

export default MVNOPreLaunchCarousel
