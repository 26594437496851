import commaNumber from 'comma-number'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { config } from 'react-transition-group'
import { useInterval } from 'react-use'

import ApplePangAction from '../../actions/ApplePangAction'
import ImageButton from '../../components/atoms/ImageButton'
import TreasureHuntButton from '../../components/treasureHunt/TreasureHuntButton'
import UserStore from '../../stores/ApplePang/UserStore'
import ToastStore from '../../stores/ToastStore'
import THModalStore from '../../stores/treasureHunt/THModalStore'
import backendApis from '../../utils/backendApis'
import { random } from '../../utils/utility'
import Block from './Block'
import Ranking from './Ranking'
import TimerBar from './TimerBar'

const direction = [
  [0, 1],
  [0, -1],
  [1, 0],
  [-1, 0],
]

const rankData = {
  0: 500,
  1: 1000,
  2: 1500,
  3: 2000,
  4: 3000,
  5: 4000,
  6: 6000,
  7: 7000,
  8: 8000,
  9: 9000,
  10: 10000,
}

const MAXTIME = 45
const COMBO_TIMEOUT = 2500
const MAX_RANK = 10

let visibilityChange
if (typeof document.hidden !== 'undefined') {
  // Opera 12.10 and Firefox 18 and later support
  visibilityChange = 'visibilitychange'
} else if (typeof document.msHidden !== 'undefined') {
  visibilityChange = 'msvisibilitychange'
} else if (typeof document.webkitHidden !== 'undefined') {
  visibilityChange = 'webkitvisibilitychange'
}

const ApplePang = observer(() => {
  const [selected, setSelected] = useState([])
  const [score, setScore] = useState(0)
  const [resetTrigger, setResetTrigger] = useState(0)
  const [combo, setCombo] = useState(0)
  const [lastComboAt, setLastComboAt] = useState(moment())
  const [adjacentList, setAdjacentList] = useState([])
  const [gameOver, setGameOver] = useState(true)
  const [showFeverText, setShowFeverText] = useState(false)
  const [shouldComboTransition, setShouldComboTransition] = useState(false)
  const [comboScale, setComboScale] = useState(0)
  const [feverMode, setFeverMode] = useState(0)
  const [bestScore, setBestScore] = useState(0)
  const [rank, setRank] = useState(0)
  const [preStart, setPreStart] = useState(false)
  const [countDown, setCountDown] = useState(0)
  const [showTutorial, setShowTutorial] = useState(false)
  const [makeTenTrigger, setMakeTenTrigger] = useState(0)
  const [totalHideList, setTotalHideList] = useState([])
  const [allResetTrigger, setAllResetTrigger] = useState(0)
  const [difficulty, setDifficulty] = useState(5)

  const comboRef = useRef(null)
  const comboDisappearTimeout = useRef()

  useEffect(() => {
    const body = document.querySelector('body')
    body.style.overflow = 'hidden'

    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'allowiPhoneGesture',
        boolean: false,
      }),
    )
  }, [])

  useEffect(() => {
    document.addEventListener(
      visibilityChange,
      (visibility) => {
        if (document.visibilityState === 'visible') {
          setTimeout(() => {
            if (gameOver) {
              fetchUser()
            }
          }, 500)
        }
      },
      false,
    )
  }, [])

  useEffect(() => {
    fetchUser()
  }, [])

  useEffect(() => {
    setRank(getRank(bestScore))
  }, [bestScore])

  useEffect(() => {
    if (comboScale === 0) {
      setShouldComboTransition(true)
      setComboScale(1.2)
      comboDisappearTimeout.current = setTimeout(() => {
        setComboScale(0.1)
        setCombo(0)
        setFeverMode(0)
      }, COMBO_TIMEOUT)
    }
  }, [comboScale])

  useInterval(
    () => {
      if (countDown > 0) {
        setCountDown((prev) => prev - 1)
      } else {
        setPreStart(false)
      }
    },
    !showTutorial && preStart ? 1000 : null,
  )

  const fetchUser = async () => {
    const res = await backendApis.getApplePangUser()
    if (res?.status === 200) {
      UserStore.setMultiple(res.data)
      setBestScore(res?.data?.bestScore)
      if (res?.data?.initModalList) {
        for (const init of res?.data?.initModalList) {
          THModalStore.setType({
            type: init?.type,
            config: { ...init?.config, forceOpen: true },
          })
        }
        backendApis.commonSet({
          set: {
            initModalList: [],
          },
          collection: 'apple_pang_user',
        })
      }
    } else {
      ToastStore.networkError()
    }
  }

  const getRank = (score) => {
    score = Number(score)
    if (score < 1000) {
      return 0
    }
    if (score < 2000) {
      return 1
    }
    if (score < 3500) {
      return 2
    }
    if (score < 5000) {
      return 3
    }
    if (score < 6000) {
      return 4
    }
    if (score < 7000) {
      return 5
    }
    if (score < 8000) {
      return 6
    }
    if (score < 8500) {
      return 7
    }
    if (score < 9000) {
      return 8
    }
    if (score < 10000) {
      return 9
    }
    return 10
  }

  const handleMatchTen = (data) => {
    // handle combo
    clearTimeout(comboDisappearTimeout.current)
    setComboScale(0)
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'haptic',
        hapticType: 'soft',
      }),
    )
    clearTimeout(comboRef.current)
    if (lastComboAt.diff(moment(), 'ms') > -COMBO_TIMEOUT) {
      if (combo < 2) {
        setFeverMode(0)
      }
      if (combo === 5) {
        setFeverMode(1)
      } else if (combo === 20) {
        setFeverMode(2)
      }
      setCombo((prev) => prev + 1)
    } else {
      setCombo(1)
    }
    comboRef.current = setTimeout(() => {
      setCombo(0)
    }, COMBO_TIMEOUT)

    // handle adjacentList
    // let temp = []
    // if (combo >= 3) {
    //   // add adjacent elems to adjacentList
    //   const added = [...selected, data]
    //   added.forEach((item) => {
    //     const [x, y] = item.split(',').map(Number)
    //     direction.forEach(([dx, dy]) => {
    //       const nx = x + dx
    //       const ny = y + dy
    //       if (nx < 0 || nx > 5 || ny < 0 || ny > 5) return
    //       const data = `${nx},${ny}`
    //       if (!selected.includes(data) && !temp.includes(data)) {
    //         temp = [...temp, data]
    //       }
    //     })
    //   })
    // }
    // setAdjacentList([...temp])

    setSelected([])
    setScore((prev) => prev + (Math.min(combo, 10) + selected?.length) * 10)
    setResetTrigger((prev) => Math.max(prev + 1, 0))
    setLastComboAt(moment())
    // setTotalHideList([...selected, data, ...totalHideList])
    setTimeout(() => {
      checkAll()
    }, 20)
  }

  useEffect(() => {
    if (totalHideList?.length > 0) {
      checkAll()
    }
  }, [totalHideList])

  const checkAll = () => {
    const type = checkAvailable()
    if (type === 'makeTen') {
      setMakeTenTrigger((prev) => prev + 1)
    } else if (type === 'reset') {
      setAllResetTrigger((prev) => prev + 1)
    }
  }

  const checkAvailable = () => {
    let elems = document.querySelectorAll('.element')
    elems = Array.from(elems)
      .filter((elem) => elem.innerText !== '')
      .map((item) => +item.innerText)

    if (elems?.length === 0) {
      setTotalHideList([])
      return 'reset'
    }

    const result = findCombinationsThatSumToTarget(elems)
    if (result?.length >= 2) {
      return 'normal'
    }
    return 'makeTen'
  }

  const addTime = (callback) => {
    callback((prev) => prev + 10)
  }

  const handleGameOver = () => {
    ApplePangAction.getRankingAll()
    setGameOver(true)
    setFeverMode(0)
    setCombo(0)
    setSelected([])

    let newbest = false
    if (bestScore < score) {
      newbest = true
      setBestScore(score)
      backendApis.commonSet({
        set: {
          bestScore: score,
        },
        collection: 'apple_pang_user',
      })
    }
    THModalStore.setType({
      type: 'applePang',
      config: {
        forceOpen: true,
        rewardName: newbest ? '최고 기록 달성!' : '게임 종료',
        src: `../images/applePang/ranking/${getRank(score)}.png`,
        description: newbest
          ? `새 기록 ${commaNumber(score)}`
          : `내 점수 ${commaNumber(score)}`,
      },
    })
  }

  const findCombinationsThatSumToTarget = (array, target = 10) => {
    let result = null

    // Helper function to find combinations
    function findCombinations(start, combo) {
      const sum = combo.reduce((a, b) => a + b, 0)
      if (sum === target && combo.length > 1) {
        result = [...combo]
        return true
      }
      if (sum >= target) return false

      for (let i = start; i < array.length; i++) {
        combo.push(array[i])
        if (findCombinations(i + 1, combo)) {
          return true
        }
        combo.pop()
      }
      return false
    }

    findCombinations(0, [])
    return result
  }

  const gameStart = async () => {
    const res = await backendApis.startApplePang()
    if (res?.status === 200) {
      setGameOver(false)
      setScore(0)
      setCombo(0)
      setResetTrigger((prev) => Math.max(0, prev + 1))
      setPreStart(true)
      setCountDown(3)
      setMakeTenTrigger(0)
      setTotalHideList([])
      setAllResetTrigger(0)

      UserStore.increase('energy', -1)
      const tutorialPoped = window.localStorage.getItem('tutorialPoped')
      if (!tutorialPoped) {
        window.localStorage.setItem('tutorialPoped', moment().format())
        setShowTutorial(true)
      }
    } else if (res?.status === 201) {
      ToastStore.toastOn({
        message: '에너지가 부족해요',
        type: 'error',
      })
      THModalStore.setType({
        type: 'applePang',
        config: {
          forceOpen: true,
          rewardName: '에너지 충전',
          src: `../images/applePang/energy.png`,
          description: '카카오톡 공유만해도 에너지 5개 획득!',
          text: '공유하기',
          onClick: () => {
            ApplePangAction.kakaoLinkShare({
              shareType: 'ApplePang_LinkShare',
              title: '[탭텐] 화제의 "그" 숫자만들기 게임!',
              description: 'IQ 200만 가능하다는 멜론 랭크에 도전해보세요!',
              imageUrl:
                'https://assets.ilevit.com/53aae331-6f90-4765-a547-05666754b854.png',
              payload: {},
              templateId: 108277,
            })
          },
        },
      })
    } else {
      ToastStore.networkError()
    }
  }

  return (
    <div
      className='flex flex-col justify-between w-[100vw] h-[100vh] items-center p-4 overflow-hidden'
      style={{
        backgroundImage: `url(../../images/applePang/feverBackground${feverMode}.gif)`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        fontFamily: 'tmoney',
        overflow: 'hidden',
      }}
    >
      {showTutorial && (
        <div
          className='w-full h-full fixed bg-black/90 top-0 z-[999999] text-white'
          onClick={() => {
            setShowTutorial(false)
          }}
        >
          <img
            className='w-[70%] absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2'
            alt='explain'
            src='../images/applePang/explain.png'
          />
          <div className='w-[70%] absolute left-1/2 -translate-x-1/2 top-[70%] -translate-y-1/2 text-center font-bold text-[6vw]'>
            터치해서 게임 시작하기
          </div>
        </div>
      )}
      {!showTutorial && preStart && (
        <div className='w-full h-full fixed bg-black/70 top-0 z-[999999] text-white'>
          <div className='absolute left-1/2 -translate-x-1/2 top-1/3 -translate-y-1/2 font-bold text-[6vw] w-full px-4 text-center'>
            시간이 끝나기 전에
            <br />
            최대한 많은 점수를 얻어보세요!
          </div>
          <div className='absolute left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 font-bold text-[12vw]'>
            {countDown}
          </div>
        </div>
      )}
      {gameOver && (
        <div
          className='absolute left-4 top-4 font-bold text-black text-[6vw]'
          onClick={() => {
            window?.ReactNativeWebView?.postMessage(
              JSON.stringify({
                type: 'close',
              }),
            )
          }}
        >
          {'<'}
        </div>
      )}
      {!gameOver ? (
        <div className='absolute right-4 top-4'>
          <ImageButton
            clickable
            className=' w-[8vw] h-[8vw]'
            src='../images/applePang/close.png'
            onClick={() => {
              handleGameOver()
            }}
          />
        </div>
      ) : (
        <div
          className='absolute right-4 top-4 flex flex-row justify-center items-center rounded-full bg-white px-2 py-1'
          onClick={() => {
            // 초대 모달
          }}
        >
          <ImageButton
            clickable
            className=' w-[6vw]  mr-1 '
            src='../images/applePang/energy.png'
            onClick={() => {}}
          />
          <div className='font-bold flex flex-row text-[#FE7D07]'>
            {UserStore.energy}/5
          </div>
          <ImageButton
            clickable
            className=' w-[4vw] ml-2'
            src='../images/applePang/plus.png'
            onClick={() => {
              THModalStore.setType({
                type: 'applePang',
                config: {
                  forceOpen: true,
                  rewardName: '에너지 충전',
                  src: `../images/applePang/energy.png`,
                  description: '카카오톡 공유만해도 에너지 5개 획득!',
                  text: '공유하기',

                  onClick: () => {
                    ApplePangAction.kakaoLinkShare({
                      shareType: 'ApplePang_LinkShare',
                      title: '[탭텐] 화제의 "그" 숫자만들기 게임!',
                      description:
                        'IQ 200만 가능하다는 멜론 랭크에 도전해보세요!',
                      imageUrl:
                        'https://assets.ilevit.com/53aae331-6f90-4765-a547-05666754b854.png',
                      payload: {},
                      templateId: 108277,
                    })
                  },
                },
              })
            }}
          />
        </div>
      )}
      <div className='flex flex-col font-bold mt-[12vw] text-[8vw] text-center w-full space-y-4 text-black'>
        {gameOver && (
          <div className='w-full px-4 pt-4 h-full'>
            <div className='flex flex-row w-full justify-between items-center bg-white/70 rounded-full px-4 py-2'>
              <div className='text-[5vw] flex justify-start items-center relative'>
                <div>내 랭크&nbsp;</div>
                <img
                  alt='rank'
                  src={`../images/applePang/ranking/${Math.min(
                    rank,
                    MAX_RANK,
                  )}.png`}
                  className='w-[8vw] h-[8vw]'
                />
              </div>
              <div className='text-[5vw] flex justify-start items-center relative'>
                <div>다음 목표&nbsp;</div>
                <img
                  alt='rank'
                  src={`../images/applePang/ranking/${Math.min(
                    rank + 1,
                    MAX_RANK,
                  )}.png`}
                  className='w-[8vw] h-[8vw]'
                />
              </div>
              <ImageButton
                clickable
                className=' w-[8vw] h-[8vw]'
                src='../images/applePang/info.png'
                onClick={() => {
                  THModalStore.setType({
                    type: 'applePangRank',
                    config: {
                      forceOpen: true,
                      rewardName: '랭크 정보',
                      currentRank: getRank(bestScore),
                      nextRank: Math.min(getRank(bestScore) + 1, MAX_RANK),
                      rankData,
                    },
                  })
                }}
              />
            </div>

            <div className='text-[6vw] mt-4'>
              {`최고 기록: ${commaNumber(bestScore)}`}
            </div>
            <div className='flex flex-row w-full justify-around text-[4vw] my-4'>
              <div
                className={`
                px-4 py-1 border-2 rounded-full border-[#55C2EC] 
                ${difficulty === 4 ? 'bg-[#55C2EC] text-white' : 'text-[#555]'}
                `}
                onClick={() => {
                  setDifficulty(4)
                }}
              >
                쉬움
              </div>
              <div
                className={`
                px-4 py-1 border-2 rounded-full border-[#55C2EC] 
                ${difficulty === 5 ? 'bg-[#55C2EC] text-white' : 'text-[#555]'}
                `}
                onClick={() => {
                  setDifficulty(5)
                }}
              >
                노멀
              </div>
              <div
                className={`
                px-4 py-1 border-2 rounded-full border-[#55C2EC] 
                ${difficulty === 6 ? 'bg-[#55C2EC] text-white' : 'text-[#555]'}
                `}
                onClick={() => {
                  setDifficulty(6)
                }}
              >
                어려움
              </div>
            </div>
          </div>
        )}

        {!gameOver && (
          <div className='pt-[4vw]'>{`점수: ${commaNumber(score)}`}</div>
        )}
        {!preStart && !gameOver && (
          <TimerBar
            inittime={MAXTIME}
            handleGameOver={handleGameOver}
            addTime={addTime}
            combo={combo}
            MAXTIME={MAXTIME}
          />
        )}
        {!showFeverText && combo > 0 && (
          <div
            style={{
              paddingTop: '8px',
              color: feverMode > 0 ? '#FF4A4A' : 'white',
              fontWeight: 'bold',
              fontSize: '2rem',
              zIndex: 10,
              transform: `scale(${comboScale})`,
              // transform: `scale(1.2)`,
              transition: shouldComboTransition ? 'all 0.1s ease-in-out' : '',
              textShadow: `2px 3px ${feverMode > 0 ? '#D62929' : '#965F42'}`,
            }}
            className='textBorder'
          >{`${combo} Combo`}</div>
        )}
      </div>

      {gameOver ? (
        <div className='w-full justify-center items-center flex flex-1 relative mb-4'>
          <Ranking />
        </div>
      ) : (
        <div
          className='all duration-300 flex flex-row rounded-xl
          absolute left-1/2 -translate-x-1/2 top-[115vw] -translate-y-1/2
          '
          style={{
            width: `${90 + (feverMode ?? 0) * 3}vw`,
          }}
        >
          {Array.from({ length: difficulty }).map((_, i) => (
            <div key={i} className='w-full flex-1 '>
              {Array.from({ length: difficulty }).map((_, j) => (
                <Block
                  key={i + j}
                  selected={selected}
                  setSelected={setSelected}
                  handleMatchTen={handleMatchTen}
                  loc={`${j},${i}`}
                  resetTrigger={resetTrigger}
                  adjacentList={adjacentList}
                  setCombo={setCombo}
                  makeTenTrigger={makeTenTrigger}
                  allResetTrigger={allResetTrigger}
                  difficulty={difficulty}
                />
              ))}
            </div>
          ))}
        </div>
      )}

      <div className='w-full space-y-4'>
        {gameOver && (
          <TreasureHuntButton
            className={'w-full py-4 text-[5vw]'}
            clickable
            text='게임시작'
            onClick={() => {
              gameStart()
            }}
          />
        )}
        <div> </div>
      </div>
    </div>
  )
})

export default ApplePang
