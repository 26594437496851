import { observable, toJS } from 'mobx'
import moment from 'moment'

import { AB } from '../../utils/utility'
import AuthStore from '../AuthStore'

const ABStore = observable({
  uploadChallenge: 'b',
  winningReview: 'b',
  purchaseTest: 'b',
  attendanceTest: 'b',

  initialize({ userId }) {
    this.uploadChallenge = AB(userId, 'uploadChallenge1020', [100])
    this.attendanceTest = AB(userId, 'attendanceTest1127', [90])
    this.winningReview = AB(userId, 'wnRv1123', [50])
    this.purchaseTest = AB(userId, 'purchaseTest1124', [90])
    // this.tutorialEnforce = AB(userId, 'tutorial1010', [50])
    // this.tutorialEnforceSecond = AB(userId, 'tutorialSecond1010', [50])
  },
})

export default ABStore
