import moment from 'moment'
import { createRef, useEffect, useRef, useState } from 'react'

import AltoonUserStore from '../../stores/AltoonUserStore'
import ToonModal from '../templates/ToonModal'

const {
  faChevronRight,
  faCircleChevronRight,
  faCircleChevronLeft,
  faListUl,
} = require('@fortawesome/free-solid-svg-icons')
const { FontAwesomeIcon } = require('@fortawesome/react-fontawesome')
const { useNavigate } = require('react-router-dom')

// 커스텀 훅: 뷰 로그 상태 관리
function useViewLog(viewLog) {
  const [ticketLastUsedAt, setTicketLastUsedAt] = useState('')
  const [viewedChapterData, setViewedChapterData] = useState([])

  useEffect(() => {
    setTicketLastUsedAt(viewLog?.ticketLastUsedAt || 0)
    setViewedChapterData(viewLog?.viewedChapterIdList || [])
  }, [viewLog])

  return { ticketLastUsedAt, viewedChapterData }
}

const ChapterHandler = ({
  currentChapter,
  previousChapter,
  nextChapter,
  toonInfo,
  rentPeriod,
  viewLog,
  isBottomSection = false,
  guideOn,
}) => {
  const navigate = useNavigate()
  const [modalType, setModalType] = useState('')
  const [modalProp, setModalProp] = useState([])
  const [openTicketModal, setOpenTicketModal] = useState(false)
  const { ticketLastUsedAt, viewedChapterData } = useViewLog(viewLog)
  const [isEpisodeListModalOpen, setIsEpisodeListModalOpen] = useState(false)
  const [unwrappedChapterList, setUnwrappedChapterList] = useState([])

  useEffect(() => {
    const chapterList = Array.from(AltoonUserStore.currentToonChapterInfo)
    const unwrappedChapterList = chapterList.map((chapter) => ({
      ...chapter,
      contents: Array.from(chapter.contents),
    }))
    setUnwrappedChapterList(
      unwrappedChapterList.filter((chapter) =>
        moment().isAfter(chapter?.publishedAt),
      ),
    )
  }, [currentChapter])

  useEffect(() => {
    // 현재 챕터의 버튼을 찾습니다.
    const currentChapterButton = document.getElementById(
      `chapter-button-${currentChapter}`,
    )
    if (currentChapterButton) {
      // 스크롤을 이동시킵니다.
      currentChapterButton.scrollIntoView({
        behavior: 'auto',
        block: 'nearest',
      })
    }
  }, [currentChapter, isEpisodeListModalOpen])

  const handleChapterList = () => {
    setIsEpisodeListModalOpen(!isEpisodeListModalOpen)
  }

  const handlePreviousChapter = () => {
    handleChapter(previousChapter)
  }

  const handleNextChapter = () => {
    handleChapter(nextChapter)
  }

  const handleChapter = (targetChapter) => {
    // console.log('targetChapter:', targetChapter)
    if (
      (!targetChapter ||
        moment().isSameOrBefore(
          moment(targetChapter?.publishedAt).subtract(7, 'days'),
        )) &&
      AltoonUserStore?.userData?.isAdmin !== true
    ) {
      // console.log('11')

      openModal('noNextChapter', targetChapter)
    } else if (
      targetChapter?.isFree === false ||
      moment().isBefore(targetChapter?.publishedAt)
    ) {
      // console.log('viewedChapterData:', viewedChapterData)
      const viewedChapter = viewedChapterData.find(
        (item) => item.chapterId.toString() === targetChapter?._id,
      )
      if (!viewedChapter) {
        if (
          AltoonUserStore?.userData?.toonCoin >=
          AltoonUserStore.currentToonPrice
        ) {
          openModal('useCoin', targetChapter)
        } else {
          setModalType('lackCoin')
          setModalProp({ ticketLastUsedAt, toonInfo })
          setOpenTicketModal(true)
        }
      } else {
        const daysSinceFirstViewed = moment()
          .startOf('day')
          .diff(moment(viewedChapter.firstViewedAt).startOf('day'), 'days')
        // console.log('daysSinceFirstViewed:', daysSinceFirstViewed)
        // console.log('rentPeriod:', rentPeriod)
        if (daysSinceFirstViewed > rentPeriod) {
          openModal('rentExpired', targetChapter)
        } else {
          navigateToChapter(targetChapter, true)
        }
      }
    } else {
      navigateToChapter(targetChapter, true)
    }
    setIsEpisodeListModalOpen(false)
  }

  const openModal = (type, chapter) => {
    setModalType(type)
    setModalProp({
      chapter,
      toonInfo,
      episode: chapter?.episode,
      rentPeriod,
    })
    setOpenTicketModal(true)
  }

  const navigateToChapter = (targetChapter, isReload = false) => {
    navigate(`/altoon-reader`, {
      state: {
        chapterInfo: targetChapter,
        toonInfo,
        episode: targetChapter?.episode,
        rentPeriod,
        isReload,
      },
      replace: true,
    })
  }

  return (
    <div className='flex justify-center'>
      {!isBottomSection ? (
        <NextChapterButton
          nextChapter={nextChapter}
          toonInfo={toonInfo}
          onButtonClick={handleNextChapter}
        />
      ) : (
        <>
          {previousChapter && (
            <GuideButton
              guideOn={guideOn}
              onButtonClick={handlePreviousChapter}
              icon={faCircleChevronLeft}
            />
          )}
          {isEpisodeListModalOpen && (
            <>
              <div
                className='fixed top-0 left-0 w-full h-full bg-white opacity-0 z-[-1]'
                onClick={async () => {
                  setIsEpisodeListModalOpen(false)
                }}
              />
              <div
                className='grid grid-cols-1 gap-1 pt-1 w-[30vw]'
                style={{
                  position: 'absolute',
                  bottom: toonInfo.toonType?.includes('HorizontalScroll')
                    ? '180%'
                    : '110%', // 조건에 따라 위치를 변경합니다.
                  right: 35, // 버튼의 오른쪽 끝에 정렬합니다.
                  height: '200px', // 모달의 높이를 설정합니다.
                  backgroundColor: '#000000ba', // 모달의 배경색을 설정합니다.
                  border: '1px solid black', // 모달의 테두리를 설정합니다.
                  overflow: 'auto', // 모달의 내용이 넘칠 경우 스크롤이 생기도록 설정합니다.
                }}
              >
                {/* 챕터 리스트를 렌더링합니다. */}
                {unwrappedChapterList.length > 0 &&
                  unwrappedChapterList.map((chapter, idx) => {
                    return (
                      <button
                        type='button'
                        key={chapter.episode}
                        id={`chapter-button-${chapter.episode}`} // 각 버튼에 고유한 id를 부여합니다.
                        className={`px-3 py-2 font-bold text-left ${
                          chapter.chapterTitle.length > 6
                            ? 'text-xs'
                            : 'text-md'
                        } ${
                          chapter.episode === currentChapter
                            ? 'text-blue-500'
                            : 'text-white'
                        }`}
                        onClick={() => {
                          // 현재 챕터와 다른 챕터를 클릭했을 때만 처리.
                          if (chapter.episode !== currentChapter) {
                            handleChapter({ ...chapter })
                          }
                        }}
                      >
                        {`${chapter.chapterTitle}`}
                      </button>
                    )
                  })}
              </div>
            </>
          )}
          <EpidsodeListButton
            guideOn={guideOn}
            onButtonClick={handleChapterList}
          />
          <GuideButton
            guideOn={guideOn}
            onButtonClick={handleNextChapter}
            icon={faCircleChevronRight}
          />
        </>
      )}
      {openTicketModal && (
        <ToonModal
          modalName={modalType}
          toonId={toonInfo._id}
          coinCount={
            AltoonUserStore?.userData?.toonCoin > 0
              ? AltoonUserStore?.userData?.toonCoin || 0
              : 0
          }
          modalTop='50%'
          modalProp={modalProp}
          setOpenTicketModal={setOpenTicketModal}
        />
      )}
    </div>
  )
}

// 다음 챕터 버튼 컴포넌트
const NextChapterButton = ({ nextChapter, toonInfo, onButtonClick }) => (
  <button
    type='button'
    onClick={onButtonClick}
    className='w-full flex flex-row justify-between items-center border-[0.5vw] border-x-transparent mb-3 p-3'
  >
    <div className='flex items-center'>
      <img
        src={nextChapter?.detailThumbnail}
        alt={toonInfo?.title}
        className='w-[36vw] h-auto rounded-md'
      />
      <div className='flex-column justify-start items-start text-left ml-5 text-[4.6vw]'>
        다음화 보기
        <div> {nextChapter?.chapterTitle}</div>
      </div>
    </div>
    <div className='text-right'>
      <FontAwesomeIcon icon={faChevronRight} />
    </div>
  </button>
)

// 가이드 버튼 컴포넌트
const GuideButton = ({ guideOn, onButtonClick, icon }) => (
  <button
    type='button'
    onClick={onButtonClick}
    className='w-12 h-12 text-white flex items-center rounded-xl font-semibold text-md'
    style={{ display: guideOn ? 'flex' : 'none' }}
  >
    <FontAwesomeIcon icon={icon} size='2xl' />
  </button>
)

const EpidsodeListButton = ({ guideOn, onButtonClick }) => (
  <button
    type='button'
    onClick={onButtonClick}
    className='w-12 h-12 text-white flex items-center rounded-xl font-semibold text-md'
    style={{ display: guideOn ? 'flex' : 'none' }}
  >
    <FontAwesomeIcon icon={faListUl} size='2xl' />
  </button>
)

export default ChapterHandler
