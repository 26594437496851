import { observer } from 'mobx-react-lite'
import React from 'react'

import THBottomSheetStore from '../../../stores/treasureHunt/THBottomSheetStore'
import THUserStore from '../../../stores/treasureHunt/THUserStore'

const BackpackEntryButton = observer(({ className }) => {
  return (
    <div
      className={`relative pointer-events-auto flex justify-center items-center rounded-full ${className}`}
      onClick={() => {
        THBottomSheetStore.setType({
          type: 'backpack',
          config: {},
        })
      }}
    >
      <img
        className='w-[16vw] absolute absolute-center'
        src='../images/treasureHunt/bottomButtons/backpack.png'
        alt='luck'
      />
      {(THUserStore?.petPingOn || THUserStore?.collecitonPingOn) && (
        <img
          className='absolute -right-1 -top-1 w-4 h-4'
          src='../images/treasureHunt/ping.png'
          alt='ping'
        />
      )}
    </div>
  )
})

export default BackpackEntryButton
