import commaNumber from 'comma-number'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useInterval } from 'react-use'

import TreasureHuntAction from '../../actions/TreasureHuntAction'
import WatermelonAction from '../../actions/WatermelonAction'
import AuthStore from '../../stores/AuthStore'
import WatermelonUserStore from '../../stores/WatermelonUserStore'
import THBottomSheetStore from '../../stores/treasureHunt/THBottomSheetStore'
import THModalStore from '../../stores/treasureHunt/THModalStore'
import backendApis from '../../utils/backendApis'
import { codepushVersionCheck, random } from '../../utils/utility'
import TreasureHuntButton from './TreasureHuntButton'

const MVNOPreLaunch = observer(() => {
  // State 변수를 사용하여 핸드폰 번호를 저장합니다.
  const [phoneNumber, setPhoneNumber] = useState('')
  const [name, setName] = useState('')

  // 핸드폰 번호 입력이 변경될 때 호출되는 핸들러 함수
  const handlePhoneNumberChange = (event) => {
    // 입력된 값에서 숫자만 추출합니다.
    const input = event.target.value

    const numericValue = input.replace(/\D/g, '')

    // '-'를 적절한 위치에 추가합니다.
    let formattedPhoneNumber = ''
    if (numericValue.length >= 4) {
      formattedPhoneNumber += `${numericValue.slice(0, 3)}-`
      if (numericValue.length >= 8) {
        formattedPhoneNumber += `${numericValue.slice(3, 7)}-`
        formattedPhoneNumber += numericValue.slice(7, 11)
      } else {
        formattedPhoneNumber += numericValue.slice(3)
      }
    } else {
      formattedPhoneNumber = numericValue
    }

    setPhoneNumber(formattedPhoneNumber)
  }

  const handleName = (event) => {
    setName(event.target.value)
  }

  return (
    <div className='default flex relative justify-start flex-col items-center w-full p-4 pt-6 text-[#313138] h-[60vh] overflow-y-scroll space-y-6'>
      <div className='w-full'>
        <div className='font-bold mb-2'>이름</div>
        <input
          className='border-2 border-blue-gray-200 rounded-xl'
          type='text'
          value={name}
          placeholder='김알뜰'
          onChange={handleName}
        />
      </div>
      <div className='w-full'>
        <div className='font-bold mb-2'>핸드폰 번호</div>
        <input
          className='border-2 border-blue-gray-200 rounded-xl'
          type='text'
          value={phoneNumber}
          placeholder='010-1234-5678'
          onChange={handlePhoneNumberChange}
        />
      </div>

      <TreasureHuntButton
        clickable={phoneNumber?.length === 13 && name?.length > 0}
        className='w-full text-[4vw] py-4 mt-2'
        text='신청하기'
        onClick={async () => {
          backendApis.registerMVNOPreLaunch({
            phoneNumber,
            name,
          })
          THModalStore.setType({
            type: 'reward',
            config: {
              forceOpen: true,
              rewardName: '사전 신청 완료!',
              description: `정식 출시에 맞춰서 등록된 번호로\n문자가 발송될 예정입니다.\n\n감사합니다!`,
              src: `../images/treasureHunt/confetti.png`,
              text: '확인',
              removeFont: true,
              onClick: () => {
                THBottomSheetStore.close()
                WatermelonUserStore.set('mvnoRegistered', true)
              },
            },
          })
        }}
      />
    </div>
  )
})

export default MVNOPreLaunch
