import { observable, toJS } from 'mobx'

import { sleep } from '../../utils/utility'
import THUserStore from './THUserStore'

const THModalStore = observable({
  type: 'none',
  queue: [],
  config: {},
  triggerClose: false,

  set(key, value) {
    if (key?.includes('.')) {
      const [parentKey, childKey] = key?.split('.')
      this[parentKey][childKey] = value
    } else {
      this[key] = value
    }
  },

  setType(obj) {
    this.queue.push(obj)
    if (this?.type !== 'none') {
      return
    }
    if (THUserStore?.tutorialEndAt || THUserStore?.tutorialMinorStep >= 9) {
      this.showNextModal()
    } else if (obj?.config?.forceOpen) {
      this.showNextModal()
    }
  },

  showNextModal() {
    const curr = this.queue.shift()
    this.type = curr?.type ?? 'none'
    this.config = curr?.config ?? {}
    if (this?.config?.blockiPhoneGesture) {
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({
          type: 'allowiPhoneGesture',
          boolean: false,
        }),
      )
    }
  },

  close() {
    this.triggerClose = true
    setTimeout(() => {
      this.triggerClose = false
    }, 290)
  },

  handleClose() {
    if (this?.config?.blockiPhoneGesture) {
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({
          type: 'allowiPhoneGesture',
          boolean: true,
        }),
      )
    }
    this.type = 'none'
    this.config = {}
    this.key = Math.random()

    if (this.queue.length > 0) {
      this.showNextModal()
    }
  },

  pushQueue(obj) {
    this.queue.push(obj)
  },

  openBambooInviteModal() {
    this.setType({
      type: 'infoList',
      config: {
        title: '친구초대 안내',
        textArray: [
          '링크를 통해서 시작해야 보상을 받을 수 있어요',
          '보상이 지급되지 않았다면, 링크를 통해 재시도 해주세요',
          '가입한지 48시간안에 초대링크를 통해 보물찾기를 시작해야해요',
          '탈퇴 후 재가입하는 경우는 인정되지 않아요',
          '부적절한 방법으로 캐시를 적립한 회원은 캐시 적립이 취소돼요',
          '해당 이벤트는 당사 사정에 의해 사전 공지 없이 내용이 변경되거나 종료될 수 있어요',
          '자세한 문의는 고객센터로 문의해주세요',
        ],
      },
    })
  },

  openServiceEndNotice() {
    this.setType({
      type: 'serviceEndNotice',
      config: {
        title: '서비스 종료 안내',
        textArray: [
          '안녕하세요,',
          '',
          `11월 30일부터 
‘보물찾기’ 서비스는 종료될 예정이에요.
이후부터는 캐시 획득, 캐시 교환을 비롯한 보물찾기와 관련한 모든 기능을 이용할 수 없어요.

다만 고객님이 최대한 많은 혜택을 받으실 수 있도록, 종료일까지 교환소 상품들의 가격을 10% 인하할 예정이에요.

그동안 보물찾기를 아껴주신 고객님께 
정말 감사드려요.

이번에 느끼신 불편함과 아쉬우셨던 점들을 잊지 않고, 더 나은 서비스와 더 큰 혜택으로 돌아올게요.`,
          '',
          '보물찾기와 관련하여 궁금하신 점이 있다면, 올웨이즈 고객센터로 언제든지 연락주세요.',
          '',
          '--------------------------------',
          '',
          'Q. 서비스 종료 후 제 개인정보들은 어떻게 되나요?',
          '서비스 종료와 동시에 보물친구, 개인정보, 게임 내역 등 모든 정보는 안전하게 파기돼요.',
          '',
          'Q. 아직 도착하지 않은 상품들은 어떻게 되나요?',
          `주문을 하신 상품들은 모두 순차적으로 배송이 될 예정이에요.
분실/파손/교환 문의는 고객센터를 통해서 문의주시면 모든 불편이 해소되실때까지 도와드릴게요.`,
        ],
      },
    })
  },

  openLuckModal() {
    this.setType({
      type: 'infoList',
      config: {
        title: '행운기능 안내',
        textArray: [
          '행운이 높을수록 발견하는 캐릭터 수가 많아져요!',
          '행운은 많은 포인트를 얻기위해서 필수적이에요',
          '행운이 75 이상이면 탐색을 대성공할 확률이 높아져요',
          '행운이 10 이하일때는 탐색에 실패할 때가 있어요',
          '황금 클로버는 친구초대와 상품구매 등으로 얻을 수 있어요',
          '보물친구와 미션을 통해서 클로버를 획득할 수 있어요',
          '자세한 문의는 고객센터로 문의해주세요',
        ],
      },
    })
  },
})

export default THModalStore
