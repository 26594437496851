import React, { useEffect, useRef, useState } from 'react'
import { useInterval } from 'react-use'

const TimerBar = ({ handleGameOver, inittime, addTime, combo, MAXTIME }) => {
  const [remainingTime, setRemainingTime] = useState(inittime)

  useEffect(() => {
    if (combo > 0 && combo % 50 === 0) {
      addTime(setRemainingTime)
    }
  }, [combo])

  useInterval(
    () => {
      if (remainingTime <= 0) {
        setRemainingTime((prev) => prev - 0.1)
        handleGameOver()
        return
      }
      setRemainingTime((prev) => prev - 0.1)
    },
    remainingTime > 0 ? 100 : null,
  )

  useEffect(() => {
    if (remainingTime < 0) {
      handleGameOver()
    }
  }, [remainingTime])

  return (
    <div className='w-full h-[24px] flex flex-start items-center relative mb-[20vw]'>
      <div
        style={{
          position: 'absolute',
          width: `100%`,
          height: '24px',
          backgroundColor: '#666',
          borderRadius: 99,
          border:
            remainingTime > 10 ? '1px solid #65ABFF' : '1px solid #D62929 ',
          borderLeft: '0px',
        }}
      />
      <div
        className='self-center font-bold absolute left-1/2 -translate-x-1/2 text-white text-[4vw] z-[34]'
        style={{
          textShadow:
            '1px 1px #1067BF, -1px 1px #1067BF, -1px -1px #1067BF, 1px -1px #1067BF',
        }}
      >
        {Math.round(remainingTime)}
      </div>
      <div
        className='transition-all duration-100 ease-linear '
        style={{
          zIndex: 33,
          width: `${(remainingTime / MAXTIME) * 100}%`,
          height: '24px',
          backgroundColor: remainingTime > 10 ? '#90C3FF' : '#FF4A4A',
          borderRadius: 99,
          borderTop:
            remainingTime > 10 ? '1px solid #65ABFF' : '1px solid #D62929 ',
          borderRight:
            remainingTime > 10 ? '1px solid #65ABFF' : '1px solid #D62929 ',
          borderBottom:
            remainingTime > 10 ? '4px solid #65ABFF' : '4px solid #D62929 ',
        }}
      />
    </div>
  )
}

export default TimerBar
