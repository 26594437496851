import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { FiChevronLeft } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import { useSearchParam } from 'react-use'

import MainToonComponent from '../components/toon/mainToonComponent'
import backendApis from '../utils/backendApis'

const AltoonFarmLandingPage = observer(() => {
  const navigate = useNavigate()
  const token = useSearchParam('token')
  const fromFarm = useSearchParam('fromFarm')
  const [recommendingToons, setRecommendingToons] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      const toonResult = await backendApis.loadRecommendingToons('hot')
      if (toonResult?.status === 200) {
        setRecommendingToons(toonResult.data)
      }
    }
    fetchData()
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'allowiPhoneGesture',
        boolean: false,
      }),
    )
  }, [])

  const HeaderComponent = () => {
    return (
      <>
        <div className='flex flex-col items-center w-full text-[5.2vw] px-4 mt-2 py-4 font-bold text-center'>
          올툰에서 인기있는 작품을 추천드려요
        </div>
      </>
    )
  }

  const GoBackComponent = () => {
    return (
      <section className='fixed top-0 left-0 z-20 py-2 '>
        <button
          type='button'
          className='mx-2 p-2 whitespace-nowrap bg-[#a3a3a375] rounded-full shadow-sm'
          onClick={async () => {
            window.location.href = '#goBack'
          }}
        >
          <FiChevronLeft className='w-8 h-8' />
        </button>
      </section>
    )
  }

  const ToonListSection = () => {
    return (
      <div className='w-full px-4'>
        {recommendingToons?.map((toon, index) => (
          <MainToonComponent
            key={toon?._id}
            toon={toon}
            index={index}
            type='onboarding'
          />
        ))}
      </div>
    )
  }

  const MainButton = () => {
    return (
      <button
        type='button'
        className='w-4/5 py-4 mt-10 rounded font-bold shadow-md text-[4vw] bg-red-500 text-white'
        onClick={() => {
          navigate(`/altoon-main?token=${token}`)
        }}
      >
        더 많은 작품 보러가기
      </button>
    )
  }

  return (
    <div className='w-full pb-28 flex flex-col items-center'>
      {fromFarm !== 'false' && (
        <img
          src='/images/toon/altoon_alfarmBanner.png'
          className='w-full'
          alt='banner'
        />
      )}
      <GoBackComponent />
      <HeaderComponent />
      <ToonListSection />
      <MainButton />
    </div>
  )
})

export default AltoonFarmLandingPage
