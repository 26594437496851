import commaNumber from 'comma-number'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import TreasureHuntAction from '../../../actions/TreasureHuntAction'
import RouteStore from '../../../stores/RouteStore'
import THBottomSheetStore from '../../../stores/treasureHunt/THBottomSheetStore'
import THModalStore from '../../../stores/treasureHunt/THModalStore'
import THUserStore from '../../../stores/treasureHunt/THUserStore'
import backendApis from '../../../utils/backendApis'
import { isEffective } from '../../../utils/utility'
import EventIcon from './EventIcon'
import FriendItem from './FriendItem'
import MissionList from './MissionList'

const FriendList = observer(() => {
  return (
    <div className='flex z-[2] px-2 py-2 pt-4 mb-0 bg-black/30 items-center overflow-x-scroll space-x-2'>
      {RouteStore?.currentRoute === 'home' && <MissionList />}
      {THUserStore?.friends?.map((friend, index) => (
        <FriendItem key={friend?.friendId} friend={friend} index={index} />
      ))}
      {THUserStore?.friends?.length < 10 && (
        <div
          className='flex-shrink-0'
          onClick={() => {
            //  초대 모달 띄우기
            backendApis.THInsert({
              data: {
                type: 'clickFriendAddButton',
              },
              collection: 'clickLog',
            })
            THModalStore.setType({
              type: 'linkShare',
              config: {
                buttonText: '초대하기',
                title: '보물친구',
                shareType: 'TreasureHunt_FriendAdd',
                description:
                  '보물찾기 친구를 추가하고\n매일 추가 보상을 받아보세요',
                src: '../images/treasureHunt/modal/friendAdd.png',
                onClick: () => {
                  TreasureHuntAction.linkShare({
                    shareType: 'TreasureHunt_FriendAdd',
                    title: '[보물찾기] 보물친구 맺고 매일 추가 보상 받자!',
                    description: '하루 1분으로 매달 귀걸이를 무료로 받아보세요',
                    imageUrl:
                      'https://assets.ilevit.com/80ec3d27-37c3-456d-a487-ec5650ff3546.png',
                    payload: {},
                  })
                  // THModalStore.close()
                },
              },
            })
          }}
        >
          <div
            className={`
      relative default justify-center items-center border-[3px] rounded-full border-[white]/0`}
          >
            <img
              className='w-[12vw] h-[12vw]'
              style={{
                borderRadius: 999,
              }}
              alt='profile'
              src='../images/treasureHunt/friend/plus.png'
            />
          </div>
          <div className='default justify-center items-center font-bold text-[#fff] text-[3.5vw] '>
            친구추가
          </div>
        </div>
      )}
      {THUserStore?.friends?.length > 0 && (
        <div
          className='flex-shrink-0'
          onClick={() => {
            //  삭제  모달 띄우기
            THModalStore.setType({
              type: 'friendDelete',
              config: {
                buttonText: '보물친구 신청하기',
                title: '보물친구',
                shareType: 'TreasureHunt_FriendAdd',
                description:
                  '보물찾기 친구를 추가하고\n매일 추가 보상을 받아보세요',
                src: '../images/linkShare/modal/friendAdd.png',
              },
            })
          }}
        >
          <div
            className={`
      relative default justify-center items-center border-[3px] rounded-full border-[white]/0`}
          >
            <img
              className='w-[12vw] h-[12vw]'
              style={{
                borderRadius: 999,
              }}
              alt='profile'
              src='../images/treasureHunt/friend/minus.png'
            />
          </div>
          <div className='default justify-center items-center font-bold text-[#fff] text-[3.5vw] '>
            친구삭제
          </div>
        </div>
      )}
    </div>
  )
})

export default FriendList
