import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import TreasureHuntAction from '../actions/TreasureHuntAction'
import TreasureHuntButton from '../components/treasureHunt/TreasureHuntButton'
import AuthStore from '../stores/AuthStore'
import UserStore from '../stores/UserStore'

const TreasureHuntCarousel = observer(() => {
  const [carouselIndex, setCarouselIndex] = useState(1)
  const [isFading, setIsFading] = useState(true)
  const [isChanging, setIsChanging] = useState(false) // New state to manage transition
  const navigate = useNavigate()

  useEffect(() => {
    if (isChanging) {
      setIsFading(false) // Start with fade-out
      const timer2 = setTimeout(() => {
        setCarouselIndex((prev) => prev + 1)
      }, 250) // Wait for 500ms for the fade-out to complete
      const timer1 = setTimeout(() => {
        setIsFading(true) // Then fade-in
        setIsChanging(false) // Reset isChanging state
      }, 500) // Wait for 500ms for the fade-out to complete

      return () => {
        clearTimeout(timer1)
        clearTimeout(timer2)
      }
    }
  }, [isChanging])

  const handleButtonClick = async () => {
    if (carouselIndex >= 3) {
      navigate(`/treasureHunt`)
      TreasureHuntAction.initTreasureHunt(AuthStore?.token, {
        routeParams: UserStore?.routeParams,
        codepushVersion: UserStore?.codepushVersion,
      })
      return
    }
    setIsChanging(true)
  }

  return (
    <div className='fixed flex w-full h-full'>
      <div className='default fixed w-full h-full z-[10] bg-black/70' />
      <div
        className={`
          default fixed w-full h-full z-[10] bg-black/70 transition-all duration-500
          ${isFading ? 'opacity-100' : 'opacity-0'}
          `}
        style={{
          backgroundImage: `url(../images/treasureHunt/carousel/${carouselIndex}.png)`,
        }}
      />

      <div className='absolute w-full p-4 z-[11] bottom-0'>
        <TreasureHuntButton
          className='w-full py-3 border-2 border-white text-[5vw]'
          clickable
          text={`${carouselIndex < 3 ? '다음으로' : '보물찾기 시작하기'}`}
          onClick={handleButtonClick}
        />
        <img
          className='flex absolute z-[101] w-[12vw] pulsate-fwd pointer-events-none right-10 bottom-0'
          alt='txt'
          src='../images/treasureHunt/finger.png'
        />
      </div>
    </div>
  )
})

export default TreasureHuntCarousel
