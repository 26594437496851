import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import AnimatedNumbers from 'react-animated-numbers'
import { Toaster, toast } from 'react-hot-toast'
import { FiChevronLeft } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import { useSearchParam } from 'react-use'

import MoneyTreeBottomSheetItem from '../components/MoneyTreeBottomSheetItem'
import ConfirmAlert from '../components/atoms/confirmAlert'
import GaugeBar from '../components/gaugeBar'
import {
  BubblePop,
  DefaultProfileImage,
  Gps,
  MoneyTree,
  MoneyTreeBackground,
  MoneyTreeBackgroundPing,
  MoneyTreeBody,
  MoneyTreeIllustration,
  MoneyTreeReward,
  MoneyTreeRewardBubble,
  MoneyTreeRewardPopped,
  MoneyTreeTwinkle,
  NearMatchBonus,
  NearMatchBonusNudge,
  ShakeIcon,
  ShakeIconGreen,
  TutorialTouch,
  YellowMoney,
} from '../images/dailyCheckIn'
import DailyCheckInModalHandler from '../modals/dailyCheckIn/DailyCheckInModalHandler'
import AuthStore from '../stores/AuthStore'
import UserStore from '../stores/UserStore'
import backendApis from '../utils/backendApis'

let buttonPressed = false
let hidden
let visibilityChange
let bonusMultiple = 1
if (typeof document.hidden !== 'undefined') {
  // Opera 12.10 and Firefox 18 and later support
  hidden = 'hidden'
  visibilityChange = 'visibilitychange'
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden'
  visibilityChange = 'msvisibilitychange'
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden'
  visibilityChange = 'webkitvisibilitychange'
}

const MoneyTreePageMain = observer(() => {
  const navigate = useNavigate()
  const token = useSearchParam('token')
  const alwayzInvitorId = useSearchParam('alwayzInvitorId')
  const checkInInvitorName = useSearchParam('checkInInvitorName')
  const code = useSearchParam('code')

  const [location, setLocation] = useState({ longitude: null, latitude: null })

  // 모달 관련 useState
  const [openLoginModal, setOpenLoginModal] = useState(false)
  const [openGPSFailModal, setOpenGPSFailModal] = useState(false)
  const [openGPSModal, setOpenGPSModal] = useState(false)
  const [isTutorialModalOpen, setIsTutorialModalOpen] = useState(false)
  const [isRewardModalOpen, setIsRewardModalOpen] = useState(false)
  const [openCountLimitModal, setOpenCountLimitModal] = useState(false)
  const [modalConfig, setModalConfig] = useState({ visible: false })

  const [isLoading, setIsLoading] = useState(false)
  const [isMatching, setIsMatching] = useState(false)
  const [matchingType, setMatchingType] = useState(null)
  const [tryCount, setTryCount] = useState(0)
  const [nearByGeoData, setNearByGeoData] = useState(null)
  const [todayMatchCount, setTodayMatchCount] = useState(0)
  const [rewardedPoint, setRewardedPoint] = useState(0)
  const [matchSuccess, setMatchSuccess] = useState(false)
  const [detectedShake, setDetectedShake] = useState(false)
  const [rewardClicked, setRewardClicked] = useState(false)
  const [startAnimation, setStartAnimation] = useState(false)
  const [rewardClickedAnimation, setRewardClickedAnimation] = useState(false)
  const [specificId, setSpecificId] = useState(null)
  const [isInvitor, setIsInvitor] = useState(false)

  const [missionConfig, setMissionConfig] = useState({
    todayMatchCount: { isShow: true, isDisable: true },
    telepathyCount: { isShow: true, isDisable: false },
  })

  const delay = (ms) => new Promise((res) => setTimeout(res, ms))
  const triggerShakeToast = () =>
    toast(`흔들어서 2배 보너스를 받으세요!`, {
      id: 'shake bonus guide',
      duration: 2000,
      position: 'top-center',
      style: {
        paddingLeft: 8,
        paddingTop: 20,
        paddingBottom: 20,
      },
      icon: '💓',
    })
  const fetchGeoData = async () => {
    if (!location?.longitude || !location?.latitude) {
      window.location.href = '#gpsPermission.'
      await backendApis.logGeoLocation(
        UserStore?.userInfo?.longitude,
        UserStore?.userInfo?.latitude,
      )
      setLocation({
        longitude: UserStore?.userInfo?.longitude,
        latitude: UserStore?.userInfo?.latitude,
      })
    }
    if (UserStore?.userInfo?.gpsError) {
      setOpenGPSModal(true)
    }
  }

  const todayMatchData = async () => {
    const matchData = await backendApis.todayMatchData()
    if (matchData?.status === 200) {
      setTodayMatchCount(matchData?.data?.length)
      if (matchData?.data?.length === 3) {
        setMissionConfig({
          ...missionConfig,
          todayMatchCount: {
            ...missionConfig?.todayMatchCount,
            isDisable: false,
          },
        })
      }
    }
  }

  const getNearByGeoData = async (retryCount = 0) => {
    if (retryCount < 1) {
      await backendApis.logGeoLocation(
        UserStore?.userInfo?.longitude,
        UserStore?.userInfo?.latitude,
      )
    }
    const result = await backendApis.getNearByGeoData(
      location?.longitude,
      location?.latitude,
    )
    if (result?.data?.length > 0) {
      window.location.href = '#vibrate.'
      setMatchingType('near')
      bonusMultiple = 10
      const matchData = result?.data[0]
      const logResult = await backendApis.logMoneyTreeMatch(
        matchData,
        'near',
        bonusMultiple,
      )
      const rewardedPoint = await backendApis.moneyTreeMatchReward(
        logResult?.data,
        true,
      )

      setNearByGeoData(matchData)
      setMatchSuccess(true)
      setTimeout(() => {
        setStartAnimation(true)
        setTimeout(() => {
          setIsRewardModalOpen(true)
        }, 3000)
      }, 1500)

      if (logResult?.status === 200 && rewardedPoint?.status === 200) {
        setRewardedPoint(rewardedPoint?.data?.rewardAmount)
      } else {
        setOpenGPSFailModal(true)
      }
    } else if (retryCount < 3) {
      await delay(1000)
      window.location.href = '#vibrate.'

      await getNearByGeoData(retryCount + 1)
    } else {
      setMatchingType('any')
      bonusMultiple = 1
      await delay(1000)
      const result = await backendApis.getAnyGeoData()
      window.location.href = '#vibrate.'

      const matchData = result?.data
      const logResult = await backendApis.logMoneyTreeMatch(
        matchData,
        'any',
        bonusMultiple,
      )
      const rewardedPoint = await backendApis.moneyTreeMatchReward(
        logResult?.data,
        false,
      )

      setNearByGeoData(matchData)
      setMatchSuccess(true)
      setTimeout(() => {
        setStartAnimation(true)
        setTimeout(() => {
          setIsRewardModalOpen(true)
        }, 3000)
      }, 1500)

      if (logResult?.status === 200 && rewardedPoint?.status === 200) {
        setRewardedPoint(rewardedPoint?.data?.rewardAmount)
      } else {
        setOpenGPSFailModal(true)
      }
    }
  }

  const getSpecificIdGeoData = async (specificId) => {
    // 오늘 이미 매칭된 사람인지 확인
    const checkIfMatchedToday = await backendApis.checkIfMatchedToday(
      alwayzInvitorId,
    )
    if (checkIfMatchedToday?.data === false) {
      const isNewUserCheckIn = await backendApis.getIsCheckInInviteNewUser()
      const inviteLogResult = await backendApis.getMoneyTreeInviteLog()
      // 특정 userId랑 매칭시켜주기
      const result = await backendApis.getSpecificGeoData(specificId)
      if (result?.data) {
        window.location.href = '#vibrate.'
        setMatchingType('specific')
        bonusMultiple = 5
        const matchData = result?.data
        if (inviteLogResult?.data) {
          if (
            inviteLogResult?.data?.todayInviteHistory.filter(
              (item) => item.userId === specificId && item.isNewUser === true,
            ).length > 0
          ) {
            bonusMultiple = 100
          }
        }
        // 매치 생성
        const logResult = await backendApis.logMoneyTreeMatch(
          matchData,
          'specific',
          bonusMultiple,
        )
        // History Logging
        const rewardedPoint = await backendApis.moneyTreeMatchReward(
          logResult?.data,
          true,
        )
        await backendApis.moneyTreeInviteLogging(
          matchData,
          isInvitor,
          isNewUserCheckIn?.data,
        )

        setNearByGeoData(matchData)
        setMatchSuccess(true)
        setIsInvitor(false)
        setTimeout(() => {
          setStartAnimation(true)
          setTimeout(() => {
            setIsRewardModalOpen(true)
          }, 3000)
        }, 1500)

        if (logResult?.status === 200 && rewardedPoint?.status === 200) {
          setRewardedPoint(rewardedPoint?.data?.rewardAmount)
        } else {
          setOpenGPSFailModal(true)
        }
      }
    }
  }

  const inviteHandler = async () => {
    if (alwayzInvitorId) {
      const isNewUserCheckIn = await backendApis.getIsCheckInInviteNewUser()
      if (UserStore.userInfo.needsLogin) {
        setOpenLoginModal(true)
      } else if (
        !UserStore.userInfo.needsLogin &&
        alwayzInvitorId &&
        isNewUserCheckIn?.data === true
      ) {
        // 초대받아 들어온 고객이 신규 고객일 때
        if (code === 'MoneyTreeInvite') {
          if (alwayzInvitorId === UserStore?.userInfo?._id) {
            return
          }
          const checkIfMatchedToday = await backendApis.checkIfMatchedToday(
            alwayzInvitorId,
          )
          const inviteParticipatedLog =
            await backendApis.getMoneyTreeInviteLog()
          const participateCount =
            inviteParticipatedLog?.data?.inviteParticipatedAt?.length || 0
          const invitorInviteData =
            await backendApis.getMoneyTreeInviteLogWithId(alwayzInvitorId)
          const invitorInviteCount =
            invitorInviteData?.data?.todayInviteHistory?.length || 0

          if (invitorInviteCount < 3) {
            if (checkIfMatchedToday?.data === false && participateCount < 1) {
              // 매칭 안 했고, 참여도 안 했을 때 (정상 참여)
              setModalConfig({
                ...modalConfig,
                visible: true,
                modalType: 'MoneyTreeFriendInviteReceived',
                condition: 'available',
                userName: checkInInvitorName,
              })
              setSpecificId(alwayzInvitorId)
              await backendApis.logNewUserBenefitLog(code, alwayzInvitorId)
            } else if (
              checkIfMatchedToday?.data === false &&
              participateCount >= 1
            ) {
              // 매칭은 안 했지만 이미 참여했을 때
              setModalConfig({
                ...modalConfig,
                visible: true,
                modalType: 'MoneyTreeFriendInviteReceived',
                condition: 'participatedToday',
                userName: checkInInvitorName,
              })
            } else {
              // 그 외 (이미 매칭이 된 친구일 때 등)
              setModalConfig({
                ...modalConfig,
                visible: true,
                modalType: 'MoneyTreeFriendInviteReceived',
                condition: 'matchedToday',
                userName: checkInInvitorName,
              })
            }
          } else {
            setModalConfig({
              ...modalConfig,
              visible: true,
              modalType: 'MoneyTreeFriendInviteReceived',
              condition: 'invitorMatchedFull',
              userName: checkInInvitorName,
            })
          }
        }
      } else if (
        !UserStore.userInfo.needsLogin &&
        alwayzInvitorId &&
        (!isNewUserCheckIn?.data ||
          isNewUserCheckIn?.data === 'false' ||
          isNewUserCheckIn?.data === 'leftUser')
      ) {
        if (code === 'MoneyTreeInvite') {
          // 기존 고객일 때
          if (alwayzInvitorId === UserStore?.userInfo?._id) {
            return
          }
          const checkIfMatchedToday = await backendApis.checkIfMatchedToday(
            alwayzInvitorId,
          )
          const inviteParticipatedLog =
            await backendApis.getMoneyTreeInviteLog()
          const participateCount =
            inviteParticipatedLog?.data?.inviteParticipatedAt?.length || 0
          const invitorInviteData =
            await backendApis.getMoneyTreeInviteLogWithId(alwayzInvitorId)
          const invitorInviteCount =
            invitorInviteData?.data?.todayInviteHistory?.length || 0

          if (invitorInviteCount < 3) {
            if (checkIfMatchedToday?.data === false && participateCount < 1) {
              // 매칭 안 했고, 참여도 안 했을 때 (정상 참여)
              setModalConfig({
                ...modalConfig,
                visible: true,
                modalType: 'MoneyTreeFriendInviteReceived',
                condition: 'available',
                userName: checkInInvitorName,
              })
              setSpecificId(alwayzInvitorId)
            } else if (
              checkIfMatchedToday?.data === false &&
              participateCount >= 1
            ) {
              // 매칭은 안 했지만 이미 참여했을 때
              setModalConfig({
                ...modalConfig,
                visible: true,
                modalType: 'MoneyTreeFriendInviteReceived',
                condition: 'participatedToday',
                userName: checkInInvitorName,
              })
            } else {
              // 그 외 (이미 매칭이 된 친구일 때 등)
              setModalConfig({
                ...modalConfig,
                visible: true,
                modalType: 'MoneyTreeFriendInviteReceived',
                condition: 'matchedToday',
                userName: checkInInvitorName,
              })
            }
          } else {
            setModalConfig({
              ...modalConfig,
              visible: true,
              modalType: 'MoneyTreeFriendInviteReceived',
              condition: 'invitorMatchedFull',
              userName: checkInInvitorName,
            })
          }
        }
      }
    }
  }

  const inviteMissionLog = async (manualClick) => {
    const inviteLogResult = await backendApis.getMoneyTreeInviteLog()
    if (inviteLogResult?.data?.todayInviteHistory) {
      setMissionConfig({
        ...missionConfig,
        telepathyCount: {
          ...missionConfig?.telepathyCount,
          isDisable:
            inviteLogResult?.data?.todayInviteHistory?.length >= 3 &&
            inviteLogResult?.data?.todayInviteHistory?.filter(
              (user) => user.isRewarded !== true,
            ).length === 0,
        },
      })
    }

    if (
      inviteLogResult?.data?.todayInviteHistory?.filter(
        (user) => user.isRewarded !== true,
      ).length > 0
    ) {
      // 초대 성공했지만 초대자가 아직 보상 안 받은 경우
      setModalConfig({
        ...modalConfig,
        visible: true,
        modalType: 'MoneyTreeFriendInvite',
        inviteLog: inviteLogResult?.data?.todayInviteHistory || [],
        receiveReward: (friendId, userName, profileUrl) => {
          setModalConfig({ ...modalConfig, visible: false })
          setIsInvitor(true)
          setSpecificId(friendId)
          delay(1000)
          setModalConfig({
            ...modalConfig,
            visible: true,
            modalType: 'MoneyTreeFriendInviteShakeNudge',
            userName,
            profileUrl,
          })
        },
      })
    } else if (
      (inviteLogResult?.data?.todayInviteHistory?.filter(
        (user) => user.isRewarded !== true,
      ).length === 0 ||
        !inviteLogResult?.data?.todayInviteHistory) &&
      manualClick
    ) {
      // 미션 CTA로 클릭해서 모달 연 경우
      setModalConfig({
        ...modalConfig,
        visible: true,
        modalType: 'MoneyTreeFriendInvite',
        inviteLog: inviteLogResult?.data?.todayInviteHistory || [],
        receiveReward: (friendId, userName, profileUrl) => {
          setModalConfig({ ...modalConfig, visible: false })
          setIsInvitor(true)
          setSpecificId(friendId)
          delay(1000)
          setModalConfig({
            ...modalConfig,
            visible: true,
            modalType: 'MoneyTreeFriendInviteShakeNudge',
            userName,
            profileUrl,
          })
        },
      })
    }
  }

  useEffect(() => {
    if (token) {
      AuthStore.setToken(token)
      if (token) {
        setModalConfig({
          ...modalConfig,
          visible: true,
          modalType: 'MoneyTreeCloseNotice',
        })
        // setIsTutorialModalOpen(true)
        // fetchGeoData()
        // todayMatchData()
        // inviteHandler()
        // inviteMissionLog(false)
        // window.location.href = '#detectShake.'
      }
    }
    // const handleVisibilityChange = (visibility) => {
    //   if (document[hidden]) {
    //     setIsLoading(true)
    //     setTimeout(() => {
    //       window.location.href = `#pop.${JSON.stringify({
    //         number: 1,
    //       })}`
    //       setIsLoading(false)
    //     }, 500)

    //     window.location.href = '#stopDetectShake'
    //     UserStore.setUserInfo({
    //       ...UserStore?.userInfo,
    //       shakeDetected: null,
    //     })
    //   }
    // }

    // document.addEventListener(visibilityChange, handleVisibilityChange, false)

    // return () => {
    //   document.removeEventListener(visibilityChange, handleVisibilityChange)
    // }
  }, [token])

  // useEffect(() => {
  //   const setGPSData = async () => {
  //     setLocation({
  //       longitude: UserStore?.userInfo?.longitude,
  //       latitude: UserStore?.userInfo?.latitude,
  //     })
  //     if (UserStore?.userInfo?.gpsError >= 0) {
  //       setOpenGPSModal(true)
  //     }
  //   }
  //   const startMatching = async () => {
  //     if (isMatching) return
  //     if (todayMatchCount > 50) {
  //       setOpenCountLimitModal(true)
  //       return
  //     }
  //     window.location.href = '#vibrate.'
  //     setMatchingType(null)
  //     bonusMultiple = 1

  //     if (!matchSuccess) {
  //       setIsMatching(true)
  //       setIsTutorialModalOpen(false)
  //       setModalConfig({ ...modalConfig, visible: false })
  //       window.location.href = '#stopDetectShake.'
  //       setDetectedShake(false)

  //       setTimeout(async () => {
  //         if (!location.longitude || !location.latitude) {
  //           setIsMatching(false)
  //           setOpenGPSFailModal(true)
  //           return
  //         }
  //         if (specificId) {
  //           await getSpecificIdGeoData(specificId)
  //         } else {
  //           await getNearByGeoData()
  //         }
  //         setIsMatching(false)
  //       }, 2000)
  //     }
  //   }
  //   setGPSData()
  //   if (UserStore?.userInfo?.needsLogin && openLoginModal === false) {
  //     setOpenLoginModal(true)
  //     return
  //   }
  //   if (UserStore?.userInfo?.shakeDetected) {
  //     setDetectedShake(true)
  //     if (!matchSuccess) {
  //       startMatching()
  //     }
  //   }
  // }, [UserStore?.userInfo])

  // useEffect(() => {
  //   if (isMatching) {
  //     const interval = setInterval(() => {
  //       setTryCount((tryCount) => (tryCount + 1) % 4)
  //     }, 1000)
  //     return () => clearInterval(interval)
  //   }
  // }, [isMatching])

  const HeaderComponent = () => {
    return (
      <section className='flex flex-row overflow-hidden py-2 z-10 relative items-center justify-between'>
        <button
          type='button'
          className='py-2 px-3 whitespace-nowrap'
          onClick={() => {
            if (isMatching || isLoading) return
            navigate(`/daily-check-in?token=${token}`)
            window.location.href = '#stopDetectShake.'
            UserStore.setUserInfo({
              ...UserStore?.userInfo,
              shakeDetected: null,
            })
          }}
        >
          <FiChevronLeft className='w-8 h-8 stroke-gray-600' />
        </button>
        <div>
          {!isMatching && !matchSuccess && UserStore?.accumulatedPoints > 0 && (
            <PointStatusComponent />
          )}
        </div>
        <button
          type='button'
          className='py-2 pr-4 whitespace-nowrap'
          style={{ fontSize: '4vw' }}
          onClick={() => {
            setModalConfig({
              ...modalConfig,
              visible: true,
              modalType: 'MoneyTreeNotice',
            })
          }}
        >
          유의사항
        </button>
      </section>
    )
  }

  const TextComponent = () => {
    const LoadingComponent = () => {
      return (
        <div
          style={{ fontSize: '6vw' }}
          className='relative -mb-4 flex flex-col items-center justify-center w-full z-10 text-[#003029]'
        >
          <div>같이 흔들고 있는</div>
          <div>친구를 찾고 있어요</div>

          <div
            style={{
              position: 'absolute',
              fontSize: '8vw',
            }}
          >
            <span className={tryCount >= 1 ? 'dot active' : 'dot'}>.</span>
            <span className={tryCount >= 2 ? 'dot active' : 'dot'}>.</span>
            <span className={tryCount >= 3 ? 'dot active' : 'dot'}>.</span>
          </div>
          <img
            src={UserStore.userInfo?.userImageUri}
            alt=''
            style={{ width: '16vw', height: '16vw' }}
            className='mt-8 rounded-full border-white border-4'
          />
        </div>
      )
    }

    const MatchedUserComponent = () => {
      return (
        <div
          style={{ fontSize: '6vw' }}
          className='flex flex-col items-center justify-between w-full z-10 text-[#003029] text-center'
        >
          <div>
            {nearByGeoData?.userName === undefined
              ? '익명의 누군가와 '
              : nearByGeoData?.userName.length < 7
              ? `${nearByGeoData?.userName}님과 `
              : `${nearByGeoData?.userName.slice(0, 6)}...님과 `}
            매칭 완료!
          </div>
          <div className='flex flex-row items-center justify-center w-full mt-8 z-10'>
            <div className='flex justify-end w-1/2'>
              <img
                src={UserStore.userInfo?.userImageUri}
                alt=''
                style={{ width: '16vw', height: '16vw' }}
                className='border-white border-4 rounded-full  mr-2'
              />
            </div>
            <div className='relative w-1/2'>
              {nearByGeoData?.thumbnailUserImageUri ? (
                <img
                  src={nearByGeoData?.thumbnailUserImageUri}
                  alt=''
                  style={{ width: '16vw', height: '16vw' }}
                  className={
                    matchingType === 'any'
                      ? 'border-white border-4 rounded-full'
                      : 'border-[#1BA796] border-4 rounded-full'
                  }
                />
              ) : (
                <DefaultProfileImage
                  style={{ width: '16vw', height: '16vw' }}
                  className={
                    matchingType === 'any'
                      ? 'border-white border-4 rounded-full'
                      : 'border-[#1BA796] border-4 rounded-full'
                  }
                />
              )}
              {matchingType === 'near' && (
                <>
                  <NearMatchBonus className='absolute w-28 -top-6 left-12' />
                  <div
                    className='absolute flex justify-center items-center bg-[#FF6363] text-white w-8 h-8 bottom-0 left-10 rounded-full'
                    style={{ fontSize: '3.5vw' }}
                  >
                    x10
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )
    }

    return (
      <div
        style={{
          position: 'absolute',
          top: '8vh',
          left: '10vw',
          width: '80vw',
        }}
        className='z-10'
      >
        {!isMatching && !matchSuccess && (
          <>
            <div
              style={{ fontSize: '6vw' }}
              className='text-[#003029] text-center'
            >
              핸드폰을 흔들어보세요
            </div>
            <div
              style={{ fontSize: '4vw' }}
              className='flex flex-row justify-center items-center pt-2 text-[#003029] text-opacity-50 text-center'
            >
              친구와 같이 흔들면 포인트를{' '}
              <div className='text-red-500 mx-1'>10배</div> 더 많이 받아요!
            </div>
          </>
        )}
        {isMatching && <LoadingComponent />}
        {matchSuccess && <MatchedUserComponent />}
      </div>
    )
  }

  const DescriptionComponent = () => {
    return (
      <button
        type='button'
        style={{
          position: 'absolute',
          top: '20vh',
          left: '40vw',
          fontSize: '4vw',
          width: '20vw',
          zIndex: 20,
        }}
        // className='flex flex-row items-center justify-around text-[#077F5F] bg-white rounded-full pl-2 pr-4 py-1 z-10'
        onClick={() => {
          if (isMatching || matchSuccess) return
          setIsTutorialModalOpen(true)
        }}
      >
        <MoneyTreeIllustration className='' />
      </button>
    )
  }

  const PointStatusComponent = () => {
    return (
      <div
        style={
          rewardClicked
            ? {
                position: 'absolute',
                top: '20vh',
                left: '32vw',
                width: '36vw',
                backgroundColor: '#A0A0A0',
                color: 'white',
              }
            : {
                backgroundColor: 'white',
                color: '#003029',
              }
        }
        className='flex flex-row items-center justify-around rounded-full pl-2 pr-4 py-2 z-10 opacity-90'
      >
        <YellowMoney className='w-6 h-6 mr-2' />
        <div className='flex flex-row items-center'>
          <AnimatedNumbers
            includeComma
            animateToNumber={UserStore?.accumulatedPoints || 0}
            fontStyle={{ fontSize: '4vw' }}
            configs={[{ mass: 1, tension: 320, friction: 70 }]}
          />
          <div className='mt-1 ml-1' style={{ fontSize: '3vw' }}>
            원
          </div>
        </div>
      </div>
    )
  }

  const NoticeComponent = () => {
    return (
      <button
        type='button'
        style={{
          zIndex: 20,
          position: 'absolute',
          bottom: '66vh',
          left: '10vw',
          width: '80vw',
          height: '30vw',
          backgroundColor: 'white',
          borderWidth: 3,
          borderColor: '#008E5B',
          borderRadius: 12,
          paddingVertical: 36,
          paddingHorizontal: 16,
          fontSize: '8vw',
        }}
        onClick={() => {
          setModalConfig({
            ...modalConfig,
            visible: true,
            modalType: 'MoneyTreeCloseNotice',
          })
        }}
      >
        서비스 종료 안내 <br /> <div style={{ fontSize: '4vw' }}>(~6/30)</div>
      </button>
    )
  }

  const TreeComponent = () => {
    return (
      <button
        type='button'
        onClick={() => {
          if (isMatching || matchSuccess) return
          setIsTutorialModalOpen(true)
        }}
      >
        <MoneyTreeTwinkle
          style={{
            zIndex: 20,
            position: 'absolute',
            bottom: '42vh',
            left: '20vw',
            width: '60vw',
            height: '46vw',
          }}
          className='animate-pulse w-full'
        />
        <MoneyTree
          style={{
            zIndex: 20,
            position: 'absolute',
            bottom: '42vh',
            left: '20vw',
            width: '60vw',
          }}
          className={
            isMatching
              ? `animate-wiggle z-10 w-full`
              : detectedShake
              ? 'animate-shake z-10 w-full'
              : `z-10 w-full`
          }
        />
        <MoneyTreeBody
          style={{
            zIndex: 10,
            position: 'absolute',
            bottom: '30vh',
            left: '20vw',
            width: '60vw',
          }}
          className='z-0 w-full'
        />
      </button>
    )
  }

  const MissionComponent = () => {
    return (
      <div
        style={{
          position: 'absolute',
          bottom: '0vh',
          width: '100vw',
          height: '28vh',
        }}
        className='bg-white rounded-t-2xl'
      >
        <div
          className='flex justify-center items-center pt-2'
          style={{ fontSize: '5vw' }}
        >
          돈나무 미션
        </div>

        <div className='px-4 mt-2'>
          <MoneyTreeBottomSheetItem
            leftImage='ShakeIconGreen'
            title='3번 흔들기'
            reward='포인트 2배!'
            addedComponent={
              <div className='my-2'>
                <GaugeBar
                  currentExp={todayMatchCount > 3 ? 3 : todayMatchCount}
                  totalExp={3}
                  moneyTree
                />
              </div>
            }
            content={
              todayMatchCount < 3
                ? `${3 - todayMatchCount}번 더 흔들면 보상이 2배!`
                : todayMatchCount > 3
                ? '내일 또 보너스를 받아보세요'
                : '흔들어서 보상을 받으세요!'
            }
            moveTo={async () => {
              if (buttonPressed) return
              buttonPressed = true
              buttonPressed = false
            }}
            button={
              <button
                type='button'
                style={{ width: '20vw', fontSize: '4vw' }}
                className={
                  todayMatchCount > 3 || todayMatchCount < 3
                    ? 'bg-[#B5CFCA] px-2 py-2 rounded-lg justify-center text-white'
                    : 'bg-[#008E5B] px-2 py-2 rounded-lg justify-center text-white'
                }
                onClick={() => {
                  if (todayMatchCount === 3) {
                    triggerShakeToast()
                  }
                }}
                disabled={todayMatchCount > 3 || todayMatchCount < 3}
              >
                {todayMatchCount > 3
                  ? '미션완료'
                  : todayMatchCount < 3
                  ? '미션 중'
                  : '보상받기'}
              </button>
            }
            disabled={missionConfig?.todayMatchCount?.isDisable}
          />
          <div className='w-full h-1 my-1 border-t border-[#ECEBEB]' />
          <MoneyTreeBottomSheetItem
            leftImage='DefaultProfileImage'
            title='텔레파시로 흔들기'
            reward='포인트 100배!'
            content='친구랑 원격으로 돈나무를 흔들어보세요'
            moveTo={() => {}}
            button={
              <button
                type='button'
                style={{ width: '20vw', fontSize: '4vw' }}
                className={
                  missionConfig.telepathyCount.isDisable
                    ? 'bg-[#B5CFCA ] px-2 py-2 rounded-lg justify-center text-white'
                    : 'bg-[#008E5B] px-2 py-2 rounded-lg justify-center text-white'
                }
                onClick={() => {
                  inviteMissionLog(true)
                }}
              >
                {missionConfig.telepathyCount.isDisable
                  ? '미션완료'
                  : '초대하기'}
              </button>
            }
          />
        </div>
      </div>
    )
  }

  const MoneyTreeRewardComponent = () => {
    return (
      <div
        style={{ top: '44vh', left: '22vw' }}
        className={`z-20 absolute ${startAnimation ? 'animate-falling' : ''} ${
          isRewardModalOpen ? 'scale-150 translate-x-1/2 -translate-y-1/2' : ''
        } transition-transform duration-300 ease-in-out`}
      >
        <MoneyTreeReward className='w-20 h-20' />
      </div>
    )
  }

  const LoadingIndicator = () => {
    return (
      <div>
        <div className='fixed bg-gray-800 opacity-70 z-10 inset-0 w-full h-full' />
        <div style={{ left: '40%', top: '40%' }} className='z-20 fixed'>
          <ClipLoader
            color='#ff3e3e'
            loading={isLoading}
            size={80}
            aria-label='Loading Spinner'
            data-testid='loader'
          />
        </div>
      </div>
    )
  }

  return (
    <div
      className='bg-[#12B9A2] w-full jua overflow-hidden fixed'
      style={{ height: '100vh' }}
    >
      <HeaderComponent />
      <MoneyTreeBackground className='absolute top-0 z-0 w-screen h-screen object-cover' />
      <MoneyTreeBackgroundPing
        style={{
          zIndex: 10,
          position: 'absolute',
          bottom: '42vh',
          left: '20vw',
          width: '60vw',
        }}
        className={isMatching ? 'animate-ping w-full' : 'w-full'}
      />
      <TextComponent />
      {/* {!isMatching && !matchSuccess && <DescriptionComponent />} */}
      {!isMatching && !matchSuccess && <NoticeComponent />}
      <TreeComponent />
      {matchSuccess && !isRewardModalOpen && <MoneyTreeRewardComponent />}

      {!isMatching && !matchSuccess && <MissionComponent />}

      {isLoading && <LoadingIndicator />}
      {openLoginModal && (
        <ConfirmAlert
          modalTitle='로그인을 해주세요'
          modalContent='로그인을 해주셔야 출석체크를 사용할 수 있어요.'
          buttonText='확인'
          buttonLink={() => {
            setOpenLoginModal(false)
            window.location.href = `#navigate.${JSON.stringify({
              screen: 'MainBMyInfoScreen',
            })}`
          }}
          showModal
          setShowModal={setOpenLoginModal}
          onlyOneButton
          noFontWeight
        />
      )}
      {openGPSFailModal && (
        <ConfirmAlert
          modalTitle='현재 위치를 가져오지 못했어요.'
          modalContent='잠시 후에 다시 시도해주세요. 문제가 반복된다면 올웨이즈의 업데이트 버전을 확인해주세요.'
          buttonText='확인'
          buttonLink={async () => {
            setOpenGPSFailModal(false)
            fetchGeoData()
            todayMatchData()
          }}
          showModal
          setShowModal={setOpenGPSFailModal}
          onlyOneButton
          noFontWeight
        />
      )}
      {openGPSModal && (
        <>
          <div className='fixed inset-0 z-50 flex items-center justify-center px-5 overflow-x-hidden overflow-y-auto outline-none '>
            <div className='w-80 p-4 m-auto shadow-lg rounded-2xl relative flex flex-col max-w-xl mx-auto bg-white border-0 outline-none '>
              <div className='flex flex-col items-center justify-between h-full w-full'>
                <Gps className='w-32' />
                <h1 className='m-2 text-2xl text-gray-800'>
                  GPS 권한을 허용해주세요
                </h1>

                <div className='p-2 text-center text-base text-gray-600'>
                  <div>제공된 위치 정보를 통해 친구를 찾아요</div>
                  <div>허용하지 않으면 서비스를 이용할 수 없어요</div>
                  <div className='text-sm mt-2'>
                    *허용 방법: '권한 &gt; 위치 &gt; 허용'
                  </div>
                </div>

                <div className='flex flex-col items-center justify-between w-full gap-4 mt-6'>
                  <button
                    type='button'
                    onClick={() => {
                      setOpenGPSModal(false)
                      window.location.href = '#navigateToSettings.'
                      window.location.reload()
                    }}
                    className='py-3 px-4 bg-[#19AB97] text-white w-full transition ease-in duration-200 text-center text-base rounded-full'
                  >
                    허용
                  </button>
                  <button
                    type='button'
                    className='py-3 px-4 bg-gray-200 text-gray-500 w-full transition ease-in duration-200 text-center text-base rounded-full'
                    onClick={() => {
                      setOpenGPSModal(false)
                      navigate(`/daily-check-in?token=${token}`)
                    }}
                  >
                    허용하지 않음
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='fixed inset-0 z-40 bg-black opacity-90' />
        </>
      )}
      {isTutorialModalOpen && (
        <div className='z-20 fixed top-0 bottom-0 left-0 right-0 flex flex-col items-center justify-center p-12 bg-black bg-opacity-80 transition-opacity text-white'>
          <div
            style={{
              position: 'absolute',
              top: '8vh',
              left: '10vw',
              width: '80vw',
              fontSize: '6vw',
            }}
          >
            <div className='text-center'>핸드폰을 흔들어보세요</div>
            <div
              className='flex flex-row justify-center items-center pt-2 text-opacity-50 text-center'
              style={{ fontSize: '4vw' }}
            >
              친구와 같이 흔들면 포인트를{' '}
              <div className='text-red-500 mx-1'>10배</div> 더 많이 받아요!
            </div>
          </div>

          <div className='py-8'>
            <ShakeIcon className='animate-shake z-30 w-52 h-52' />
          </div>
          <div style={{ fontSize: '6vw' }} className='text-center'>
            핸드폰을 흔들면
          </div>
          <div style={{ fontSize: '6vw' }} className='text-center'>
            돈나무가 흔들려요!
          </div>

          <button
            type='button'
            className='w-2/5 text-center rounded-full px-3 py-2 border-white border-2 mt-8'
            style={{ fontSize: '4vw' }}
            onClick={() => {
              setIsTutorialModalOpen(false)
            }}
          >
            확인
          </button>
        </div>
      )}
      {isRewardModalOpen && (
        <div
          className='z-30 fixed top-0 bottom-0 left-0 right-0 flex flex-col items-center justify-center p-12 bg-black bg-opacity-90 transition-opacity text-white'
          style={{ fontSize: '5vw' }}
        >
          {!rewardClicked && (
            <>
              {matchingType === 'near' && (
                <div>
                  <NearMatchBonus className='w-28 ml-32' />
                </div>
              )}
              {(matchingType === 'near' || matchingType === 'specific') && (
                <div style={{ fontSize: '8vw' }}>함께</div>
              )}
              <div style={{ fontSize: '8vw' }}>흔들기 성공!</div>
              <div className='flex flex-row items-center justify-center mt-4 z-10'>
                <img
                  src={UserStore.userInfo?.userImageUri}
                  alt=''
                  style={{ width: '16vw', height: '16vw' }}
                  className='border-white border-4 rounded-full -mr-4 z-20'
                />

                {nearByGeoData?.thumbnailUserImageUri ? (
                  <img
                    src={nearByGeoData?.thumbnailUserImageUri}
                    alt=''
                    style={{ width: '16vw', height: '16vw' }}
                    className={
                      matchingType === 'any'
                        ? 'border-white border-4 rounded-full'
                        : 'border-[#1BA796] border-4 rounded-full'
                    }
                  />
                ) : (
                  <DefaultProfileImage
                    style={{ width: '16vw', height: '16vw' }}
                    className={
                      matchingType === 'any'
                        ? 'border-white border-4 rounded-full'
                        : 'border-[#1BA796] border-4 rounded-full'
                    }
                  />
                )}
              </div>

              <button
                type='button'
                className='py-16 transform scale-120 w-28'
                onClick={() => {
                  setRewardClickedAnimation(true)
                  window.location.href = '#vibrate.'

                  setTimeout(() => {
                    setSpecificId(null)
                    setRewardClicked(true)
                    setMatchSuccess(false)
                    setIsMatching(false)
                    setNearByGeoData(null)
                    setStartAnimation(false)
                    setRewardClickedAnimation(false)
                    setDetectedShake(false)
                    setTodayMatchCount(todayMatchCount + 1)
                    UserStore?.setAccumulatedPoints(
                      UserStore?.accumulatedPoints + rewardedPoint,
                    )
                    UserStore.setUserInfo({
                      ...UserStore?.userInfo,
                      shakeDetected: null,
                    })
                    window.location.href = '#detectShake.'
                  }, 380)
                }}
              >
                {rewardClickedAnimation && (
                  <BubblePop className='absolute top-16 z-30 w-28 h-28' />
                )}
                {!rewardClickedAnimation && (
                  <MoneyTreeRewardBubble className='absolute top-16 animate-wiggle z-30 w-28 h-28' />
                )}
                {(matchingType === 'near' || matchingType === 'specific') && (
                  <div
                    className='absolute top-16 -right-2 z-40 flex justify-center items-center bg-[#FF6363] text-white w-10 h-10 rounded-full'
                    style={{ fontSize: '4vw' }}
                  >
                    {bonusMultiple > 1 && `x${bonusMultiple}`}
                  </div>
                )}
                <YellowMoney className='ml-4 mt-4 z-20 w-20 h-20' />
                <TutorialTouch className='absolute animate-pulse top-36 left-20 z-20 w-12' />
              </button>

              <div>방울을 터뜨려서</div>
              <div>보상을 확인해보세요!</div>
            </>
          )}

          {rewardClicked && (
            <>
              <PointStatusComponent />

              <div className='pt-10 pb-4'>
                <MoneyTreeRewardPopped className='animate-wiggle z-30 w-52 h-52' />
              </div>
              <div
                style={{ fontSize: '6vw' }}
                className='flex flex-col items-center'
              >
                <div className='flex flex-row items-end'>
                  <AnimatedNumbers
                    includeComma
                    animateToNumber={rewardedPoint}
                    fontStyle={{ fontSize: '7vw' }}
                    configs={[{ mass: 1, tension: 320, friction: 70 }]}
                  />
                  <div className='ml-1 mt-1'>원 획득!</div>
                </div>
                {todayMatchCount === 4 && matchingType === 'any' && (
                  <div
                    style={{
                      fontSize: '4vw',
                      marginLeft: 2,
                    }}
                    className='animate-pulse text-red-500'
                  >
                    ({Math.floor(rewardedPoint / 2)}원 + 미션 보너스{' '}
                    {rewardedPoint - Math.floor(rewardedPoint / 2)}원)
                  </div>
                )}
                {matchingType !== 'any' && bonusMultiple > 1 && (
                  <div
                    style={{
                      fontSize: '5vw',
                      marginLeft: 2,
                    }}
                    className='animate-pulse text-red-500'
                  >
                    ({Math.floor(rewardedPoint / bonusMultiple)}원 + 미션 보너스{' '}
                    {rewardedPoint - Math.floor(rewardedPoint / bonusMultiple)}
                    원)
                  </div>
                )}
              </div>
              <div style={{ position: 'absolute', top: '77vh', right: '6vw' }}>
                <NearMatchBonusNudge className='w-44' />
              </div>
              <button
                type='button'
                className='bg-white w-3/5 text-[#328C80] text-center rounded-3xl p-4'
                style={{
                  position: 'absolute',
                  top: '86vh',
                  left: '15vw',
                  width: '70vw',
                  boxShadow:
                    '0px 6px 6px rgba(0, 0, 0, 0.25), inset 0px -4px 5px rgba(9, 65, 5, 0.37)',
                  fontSize: '5vw',
                }}
                onClick={async () => {
                  const result = await backendApis.getCheckInPoint(token)
                  if (result.status === 200) {
                    UserStore?.setAccumulatedPoints(
                      result?.data[0]?.totalCheckInPoints,
                    )
                  }
                  setMatchingType(null)
                  bonusMultiple = 1
                  setIsRewardModalOpen(false)
                  setRewardClicked(false)
                }}
              >
                확인
              </button>
            </>
          )}
        </div>
      )}
      {openCountLimitModal && (
        <ConfirmAlert
          modalTitle='오늘 가능한 횟수를 초과했어요'
          modalContent='매일 50번까지만 돈나무를 흔들 수 있어요'
          buttonText='확인'
          buttonLink={() => {
            setOpenCountLimitModal(false)
            navigate(`/daily-check-in?token=${token}`)
            window.location.href = '#stopDetectShake.'
          }}
          showModal
          setShowModal={setOpenCountLimitModal}
          onlyOneButton
          noFontWeight
        />
      )}
      {modalConfig?.visible && (
        <DailyCheckInModalHandler
          setModalConfig={setModalConfig}
          {...modalConfig}
        />
      )}
      {/* <Toaster
        position='top-center'
        reverseOrder={false}
        gutter={8}
        containerClassName=''
        containerStyle={{
          position: 'absolute',
          top: '15%',
        }}
        toastOptions={{
          // Define default options
          className: '',
          duration: 2000,
          style: {
            background: '#363636',
            color: '#fff',
          },
        }}
      /> */}
    </div>
  )
})

export default MoneyTreePageMain
