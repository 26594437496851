import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef, useState } from 'react'
import { FiChevronLeft } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import { useSearchParam } from 'react-use'

import MypageToonComponent from '../components/toon/mypageToonComponent'
import AuthStore from '../stores/AuthStore'
import backendApis from '../utils/backendApis'

const AltoonMyPage = observer(() => {
  const token = useSearchParam('token')
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const [recentlyViewedToons, setRecentlyViewedToons] = useState([])
  const [likedToons, setLikedToons] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      const recentlyViewedDataResult =
        await backendApis.loadRecentlyViewedData()
      const recentlyViewedToonsResult =
        await backendApis.loadRecentlyViewedToons()
      if (
        recentlyViewedDataResult?.status === 200 &&
        recentlyViewedToonsResult?.status === 200
      ) {
        const mergedData = recentlyViewedToonsResult.data.map((toon) => {
          const matchedData = recentlyViewedDataResult.data.find(
            (data) => data.toonId === toon._id,
          )

          return {
            ...toon,
            lastViewedAt: matchedData?.lastViewedAt,
          }
        })

        const sortedData = mergedData.sort((a, b) => {
          const aTime = new Date(a.lastViewedAt).getTime()
          const bTime = new Date(b.lastViewedAt).getTime()

          return bTime - aTime
        })

        setRecentlyViewedToons(sortedData)
      }
    }

    const fetchLikedToons = async () => {
      const result = await backendApis.loadLikedToons()
      if (result?.status === 200) {
        setLikedToons(result.data)
      }
    }
    fetchData()
    fetchLikedToons()
  }, [])

  useEffect(() => {
    setIsLoading(true)
    AuthStore.setToken(token)
    setIsLoading(false)
  }, [])

  const HeaderComponent = () => {
    return (
      <section className='z-10 py-2 flex flex-row items-center justify-between overflow-hidden font-bold'>
        <button
          type='button'
          className='px-3 py-2 whitespace-nowrap'
          onClick={async () => {
            navigate(-1)
          }}
        >
          <FiChevronLeft className='w-8 h-8' />
        </button>
        <div className='text-2xl px-3 py-2 whitespace-nowrap'>보관함</div>
        <div className='w-16' />
      </section>
    )
  }

  const ToonList = () => {
    const [selectedType, setSelectedType] = useState('viewed')
    const [borderStyle, setBorderStyle] = useState({})

    const viewedRef = useRef(null)
    const likedRef = useRef(null)

    useEffect(() => {
      let activeRef
      switch (selectedType) {
        case 'viewed':
          activeRef = viewedRef
          break
        case 'liked':
          activeRef = likedRef
          break
        default:
          break
      }

      if (activeRef && activeRef.current) {
        const rect = activeRef.current.getBoundingClientRect()
        setBorderStyle({
          width: rect.width,
          left: rect.left,
        })
      }
    }, [selectedType])

    const filteredToons =
      selectedType === 'viewed' ? recentlyViewedToons : likedToons

    const getButtonStyle = (genre) => {
      if (genre === selectedType) {
        return 'px-4 font-bold text-[#E4443B]' // 선택된 탭 스타일
      }
      return 'px-4 text-gray-500' // 기본 스타일
    }

    return (
      <div className='flex flex-col items-center mb-[12vh]'>
        <div className='w-full flex flex-row justify-between px-10 py-2 mb-4 text-[4.2vw] border-b border-[#F3F3F3] relative'>
          <div
            style={borderStyle}
            className='absolute -bottom-[0.3vh] h-[0.4vh] bg-[#E4443B] transition-all duration-300'
          />

          <button
            ref={viewedRef}
            type='button'
            className={getButtonStyle('viewed')}
            onClick={() => setSelectedType('viewed')}
          >
            최근 감상한 작품
          </button>
          <button
            ref={likedRef}
            type='button'
            className={getButtonStyle('liked')}
            onClick={() => setSelectedType('liked')}
          >
            좋아한 작품
          </button>
        </div>

        <div className='w-full px-4 flex flex-col'>
          {filteredToons?.length === 0 && (
            <MypageToonComponent type={selectedType} isEmpty />
          )}
          {filteredToons?.length > 0 &&
            filteredToons?.map((toon, index) => (
              <MypageToonComponent
                key={toon?._id}
                toon={toon}
                index={index}
                type={selectedType}
              />
            ))}
        </div>
      </div>
    )
  }

  const BottomSection = () => {
    return (
      <div className='fixed bottom-0 w-full h-[9vh] flex flex-row justify-between items-center bg-white border-t border-[#F5F5F5] text-sm'>
        <button
          type='button'
          className='w-full flex flex-col items-center text-center'
          onClick={() => {
            navigate(`/altoon-main?token=${token}`)
          }}
        >
          <img
            src='/images/toon/ic_gnb_home_D.png'
            alt=''
            className='w-6 h-6 mx-auto mb-1'
          />
          홈
        </button>
        <button
          type='button'
          className='w-full text-center'
          onClick={() => {
            navigate(`/altoon-mypage?token=${token}`)
          }}
        >
          <img
            src='/images/toon/ic_gnb_box_B_active.png'
            alt=''
            className='w-6 h-6 mx-auto mb-1'
          />
          보관함
        </button>
        <button
          type='button'
          className='w-full text-center'
          onClick={() => {
            navigate(`/altoon-ticket?token=${token}`)
          }}
        >
          <img
            src='/images/toon/ic_ticket_line_A.png'
            alt=''
            className='w-6 h-6 mx-auto mb-1'
          />
          내 코인
        </button>
      </div>
    )
  }

  const LoadingIndicator = () => {
    return (
      <div>
        <div className='fixed inset-0 z-10 w-full h-full bg-gray-800 opacity-70' />
        <div style={{ left: '40%', top: '40%' }} className='fixed z-20'>
          <ClipLoader
            color='#ff3e3e'
            loading={isLoading}
            size={80}
            aria-label='Loading Spinner'
            data-testid='loader'
          />
        </div>
      </div>
    )
  }

  return (
    <div className='w-full overflow-hidden'>
      <HeaderComponent />
      <ToonList />
      <BottomSection />
      {isLoading && <LoadingIndicator />}
    </div>
  )
})

export default AltoonMyPage
