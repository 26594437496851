import { observable } from 'mobx'
import { toast } from 'react-hot-toast'

const ToastStore = observable({
  toastBlock: false,
  type: 'error',
  duration: 2000,
  message: '다시 시도해주세요',
  timeOutRef: null,
  backgroundOn: false,

  toastOn({ type, message, duration = 2000, emoji }) {
    toast.remove()

    this.backgroundOn = true
    setTimeout(() => {
      this.backgroundOn = false
    }, duration)
    if (type === 'error') {
      toast.error(message, duration)
    } else if (type === 'success') {
      toast.success(message, duration)
    } else if (type === 'emoji') {
      toast(message, { icon: emoji, duration })
    }
  },
  networkError(tag) {
    this.toastOn({
      type: 'error',
      message: `데이터를 불러오지 못했어요 ${tag || ''}`,
      duration: 2000,
    })
  },

  GPSError() {
    this.toastOn({
      type: 'error',
      message: '위치 데이터를 불러오지 못했어요. 재접속 해주세요',
      duration: 2000,
    })
  },
})

export default ToastStore
