import commaNumber from 'comma-number'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useInterval } from 'react-use'

import TreasureHuntAction from '../../actions/TreasureHuntAction'
import WatermelonAction from '../../actions/WatermelonAction'
import AuthStore from '../../stores/AuthStore'
import WatermelonUserStore from '../../stores/WatermelonUserStore'
import THBottomSheetStore from '../../stores/treasureHunt/THBottomSheetStore'
import THModalStore from '../../stores/treasureHunt/THModalStore'
import THUserStore from '../../stores/treasureHunt/THUserStore'
import backendApis from '../../utils/backendApis'
import { codepushVersionCheck, random } from '../../utils/utility'
import TreasureHuntButton from './TreasureHuntButton'
import WatermelonRankItem from './WatermelonRankItem'
import BottomButtons from './molecules/BottomButtons'
import DailyMission from './molecules/DailyMission'
import DailyMissionList from './molecules/DailyMissionList'
import WatermelonDailyMission from './molecules/WatermelonDailyMission'

const WatermelonContacts = observer(() => {
  const [currentTab, setCurrentTab] = useState('all')
  const scrollContainerRef = useRef(null)
  const loadingThrottle = useRef(false)
  const [showButton, setShowButton] = useState(true)

  useEffect(() => {
    const handleScroll = (e) => {
      const bottom =
        e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
      if (
        bottom &&
        WatermelonUserStore?.contactSkip <=
          WatermelonUserStore?.contactList?.length &&
        !loadingThrottle.current
      ) {
        // Action to perform at the end of the scroll
        loadingThrottle.current = true
        setShowButton(false)
        setTimeout(() => {
          loadingThrottle.current = false
          setShowButton(true)
        }, 500)
        WatermelonUserStore.set('isContactInviteLoad', true)
        WatermelonAction.getRankingLocal({
          contacts: WatermelonUserStore?.contactList,
          skip: WatermelonUserStore?.contactSkip,
        })
      }
    }

    const scrollContainer = scrollContainerRef.current
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  useEffect(() => {
    WatermelonUserStore.set('isContactInviteLoad', true)
    WatermelonAction.checkContactPermission()
  }, [])

  return (
    <div
      ref={scrollContainerRef}
      className='default flex relative justify-start flex-col items-center w-full p-4 pt-6 text-[#313138] h-[60vh] overflow-y-scroll space-y-6'
      style={{
        fontFamily: 'maplestory',
      }}
    >
      {WatermelonUserStore?.uninstallUsers?.map((user, index) => (
        <div className='w-full flex flex-row items-center' key={Math.random()}>
          <div className='mr-2 font-bold text-[4vw] truncate w-[22vw]'>
            {user?.name}
          </div>
          <div className='text-[3.5vw] text-[#8E8D9B]'>{`
            ${user?.phoneNumber?.slice(0, 3)}-${user?.phoneNumber?.slice(
            3,
            7,
          )}-${user?.phoneNumber?.slice(7, 11)}
            `}</div>
          <TreasureHuntButton
            className='self-end ml-auto text-[4vw]'
            clickable
            text='도전권'
            secondTextImg='../images/treasureHunt/energy.png'
            secondText='5개'
            onClick={() => {
              THModalStore.setType({
                type: 'watermelonRule',
                config: {
                  title: '연락처 초대',
                  forceOpen: true,
                  src: 'contactInviteModal',
                  onClick: () => {
                    window.ReactNativeWebView?.postMessage(
                      JSON.stringify({
                        type: 'sendTextMessage',
                        data: {
                          message: {
                            phoneNumber: user?.phoneNumber,
                          },
                          shareType: 'Watermelon_ContactInvite',
                          title: '[양파게임] 무료 양파 1Kg에 도전하세요!',
                          description:
                            '작물을 합쳐서 양파를 만들어내면 양파 1Kg가 무료!',
                          imageUrl:
                            'https://assets.ilevit.com/e9741bf3-54c4-4fef-aa5c-3c80292086e1.png',
                          payload: {},
                        },
                      }),
                    )
                  },
                  text: '초대하기',
                },
              })
            }}
          />
        </div>
      ))}

      <TreasureHuntButton
        clickable={
          WatermelonUserStore.contactSkip <=
            WatermelonUserStore?.contactList?.length &&
          !loadingThrottle.current &&
          showButton
        }
        className='w-full text-[4vw] py-4 mt-2'
        text='더 불러오기'
        onClick={() => {
          setShowButton(false)
          setTimeout(() => {
            loadingThrottle.current = false
            setShowButton(true)
          }, 500)
          WatermelonUserStore.set('isContactInviteLoad', true)
          WatermelonAction.getRankingLocal({
            contacts: WatermelonUserStore?.contactList,
            skip: WatermelonUserStore?.contactSkip,
          })
        }}
      />
    </div>
  )
})

export default WatermelonContacts
