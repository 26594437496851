import { observer } from 'mobx-react-lite'
import React from 'react'

import THUserStore from '../../../stores/treasureHunt/THUserStore'
import BackgroundItem from './BackgroundItem'

const Background = observer(() => {
  return (
    <div className='absolute w-full h-full flex justify-center items-center'>
      <BackgroundItem
        className='w-[35vw] h-[35vw]'
        isAnimating={THUserStore?.isFindingTreasure}
        delay={0}
      />
      <BackgroundItem
        className='w-[70vw] h-[70vw]'
        isAnimating={THUserStore?.isFindingTreasure}
        delay={2}
      />
      <BackgroundItem
        className='w-[100vw] h-[100vw]'
        isAnimating={THUserStore?.isFindingTreasure}
        delay={4}
      />
    </div>
  )
})

export default Background
