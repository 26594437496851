import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { ClipLoader } from 'react-spinners'

import AltoonCMSUserStore from '../../stores/AltoonCMS/AltoonCMSUserStore'
import altoonCMSBackendApis from '../../utils/altoonCMSBackendApis'

const ToonCommentAdminScreen = observer(() => {
  const itemsPerPage = 100
  const [currentPage, setCurrentPage] = useState(1)
  const [totalItems, setTotalItems] = useState(0) // 전체 아이템 수 상태
  const [tempData, setTempData] = useState([])

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }

  const fetchData = async () => {
    const result = await altoonCMSBackendApis.toonComment({
      skip: (currentPage - 1) * itemsPerPage,
      limit: itemsPerPage,
    })
    if (result?.status === 200) {
      setTempData(result?.data?.result)
      setTotalItems(result?.data?.totalCount)
    }
  }

  const LoadingIndicator = () => {
    return (
      <div>
        <div className='fixed inset-0 z-30 w-full h-full bg-gray-800 opacity-70' />
        <div style={{ left: '50%', top: '40%' }} className='fixed z-20'>
          <ClipLoader
            color='#ff3e3e'
            loading
            size={40}
            aria-label='Loading Spinner'
            data-testid='loader'
          />
        </div>
      </div>
    )
  }

  useEffect(() => {
    AltoonCMSUserStore.setIsLoading(true)
    fetchData()
    setTimeout(() => {
      AltoonCMSUserStore.setIsLoading(false)
    }, 500)
  }, [currentPage]) // currentPage 또는 itemsPerPage가 변경될 때마다 요청

  const Pagination = ({ totalPages, currentPage, onPageChange }) => {
    let pages = []

    // 이전 페이지 추가
    if (currentPage > 1) {
      pages.push(currentPage - 1)
    }

    // 현재 페이지 추가
    pages.push(currentPage)

    // 이후 3개 페이지 추가
    for (let i = 1; i <= 3; i++) {
      if (currentPage + i < totalPages) {
        pages.push(currentPage + i)
      }
    }

    // 첫 페이지 추가
    if (!pages.includes(1)) {
      // '...' 표시와 함께 마지막 페이지 번호 추가
      pages = [1, '...', ...pages]
    }
    // 마지막 페이지 추가

    if (!pages.includes(totalPages)) {
      // '...' 표시와 함께 마지막 페이지 번호 추가
      pages = [...pages, '...', totalPages]
    }

    return (
      <div>
        {pages.map((page) => (
          <button
            type='button'
            key={page}
            onClick={() => page !== '...' && onPageChange(page)}
            style={
              page === currentPage
                ? { margin: 5, padding: 5, backgroundColor: 'red' }
                : { margin: 5, padding: 5 }
            }
          >
            {page}
          </button>
        ))}
      </div>
    )
  }

  const RegisteredToonList = ({
    commentData,
    currentPage,
    handlePageChange,
  }) => {
    return (
      <div>
        {commentData?.length > 0 && (
          <table>
            <thead className='whitespace-nowrap text-[1.4vh] border-b-2'>
              <tr>
                <th>id</th>
                <th>toonId</th>
                <th>chapterId</th>
                <th>userId</th>
                <th>userName</th>
                <th>comment</th>
                <th>createdAt</th>
                <th>liked</th>
              </tr>
            </thead>
            <tbody style={{ fontSize: 12, textAlign: 'center' }}>
              {commentData?.map((item) => (
                <tr key={item._id} className='border-b'>
                  <td style={{ fontSize: 4, width: '5vw', textWrap: 'nowrap' }}>
                    {item._id}
                  </td>
                  <td style={{ fontSize: 4, width: '5vw', textWrap: 'nowrap' }}>
                    {item.toonId}
                  </td>
                  <td style={{ fontSize: 4, width: '5vw', textWrap: 'nowrap' }}>
                    {item.chapterId}
                  </td>
                  <td style={{ fontSize: 4, width: '5vw', textWrap: 'nowrap' }}>
                    {item.userId}
                  </td>
                  <td style={{ fontSize: 8, width: '5vw', textWrap: 'nowrap' }}>
                    {item?.userName?.length > 20
                      ? `${item.userName.slice(0, 20)}..`
                      : item.userName}
                  </td>
                  <td style={{ padding: 8, fontSize: 12, width: '50vw' }}>
                    {item.comment}
                  </td>
                  <td style={{ fontSize: 8 }}>
                    {moment(item.createdAt).format('YYYY-MM-DD HH:mm')}
                  </td>
                  <td>{item.liked}</td>
                  <td>
                    <button
                      type='button'
                      style={{ margin: 4, width: '5vw', textWrap: 'nowrap' }}
                      onClick={async () => {
                        const deleteConfirmation =
                          window.confirm('삭제하시겠습니까?')

                        if (deleteConfirmation) {
                          const result =
                            await altoonCMSBackendApis.deleteToonComment({
                              commentId: item._id,
                            })
                          if (result?.status === 200) {
                            alert('삭제되었습니다.')
                            fetchData()
                            return
                          }
                          alert(
                            '오류가 발생하였습니다. 오기입 위험이 있으니 DB를 확인해주세요.',
                          )
                        }
                      }}
                    >
                      삭제하기
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        <Pagination
          totalPages={Math.ceil(totalItems / itemsPerPage)}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>
    )
  }

  return (
    <div className='w-full my-10 min-w-7xl max-w-10xl select-text'>
      <div className='p-4 bg-white rounded-xl'>
        <div className='py-4 font-bold text-[2.6vh]'>올툰 댓글 모니터링</div>
        {tempData?.length > 0 && (
          <RegisteredToonList
            commentData={tempData}
            currentPage={currentPage}
            handlePageChange={handlePageChange}
          />
        )}
        {!tempData?.length > 0 && <LoadingIndicator />}
      </div>
    </div>
  )
})

export default ToonCommentAdminScreen
