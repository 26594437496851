import commaNumber from 'comma-number'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import AltoonAction from '../../actions/AltoonAction'
import { CloseThin } from '../../images/dailyCheckIn'
import AltoonUserStore from '../../stores/AltoonUserStore'
import AuthStore from '../../stores/AuthStore'
import ToastStore from '../../stores/ToastStore'
import UserStore from '../../stores/UserStore'
import backendApis from '../../utils/backendApis'
import BlackTextTimer from '../BlackTextTimer'
import TreasureHuntButton from '../treasureHunt/TreasureHuntButton'

const ToonModal = observer(
  ({
    modalName = 'ticketInfo',
    toonId,
    coinCount = 0,
    modalProp = [],
    setOpenTicketModal = '',
    setModalType = '',
    setModalProp = '',
    modalTop,
    scrollRef = 0,
  }) => {
    const navigate = useNavigate()
    const toonPrice = modalProp.toonInfo?.toonPrice || 300

    if (modalName === 'close') {
      return (
        <>
          <div className='fixed top-0 left-0 w-full h-full bg-gray-800 opacity-70 z-20' />
          <div className='absolute top-[30vh] left-[10vw] z-30 w-4/5 h-[30vh] bg-white font-bold flex flex-col items-center justify-between rounded-xl'>
            <div className='px-8 py-4 mb-2 text-[4vw] font-medium '>
              <div className='mb-4 text-[6vw] font-bold text-center'>
                공지사항
              </div>
              <div>
                <div className='mb-2 relative leading-relaxed'>
                  올툰은 더 좋은 서비스를 제공하기 위해 잠깐의 정비기간을 갖고자
                  해요.
                </div>
                <div className='relative leading-relaxed'>
                  더 재밌는 작품들을 더 많이 갖고 최대한 빨리 돌아올테니, 잠시만
                  기다려주세요!
                </div>
              </div>
            </div>

            <div className='w-full'>
              <button
                type='button'
                className='w-full bg-[#EB4F47] hover:bg-red-700 transition ease-in duration-200 py-4 text-white rounded-b-lg'
                onClick={async () => {
                  window.location.href = `#navigate.${JSON.stringify({
                    screen: 'MainALargeCategoryTabNavigator',
                  })}`
                }}
              >
                확인
              </button>
            </div>
          </div>
        </>
      )
    }

    if (modalName === 'ticketPurchased') {
      return (
        <>
          <div className='fixed top-0 left-0 w-full h-full bg-gray-800 opacity-70 z-20' />
          <div className='absolute top-[30vh] left-[10vw] z-30 w-4/5 h-[30vh] bg-white font-bold flex flex-col items-center justify-between rounded-xl'>
            <div className='px-8 py-4 mb-2 text-[4vw] font-medium '>
              <div className='mb-4 text-[6vw] font-bold text-center'>안내</div>
              <div>
                <div className='mb-2 relative leading-relaxed'>
                  이미 첫구매 혜택을 받으셨어요.
                </div>
                <div className='relative leading-relaxed'>
                  첫구매 혜택은 한 번만 받을 수 있어요.
                </div>
              </div>
            </div>

            <div className='w-full'>
              <button
                type='button'
                className='w-full bg-[#EB4F47] hover:bg-red-700 transition ease-in duration-200 py-4 text-white rounded-b-lg'
                onClick={async () => {
                  navigate(`/altoon-ticket?token=${AuthStore.token}`)
                }}
              >
                확인
              </button>
            </div>
          </div>
        </>
      )
    }

    if (modalName === 'ticketExchange') {
      const goodsKOR = [
        { goods: 'ticket', goodsKOR: '열람권' },
        { goods: 'toonCoin', goodsKOR: '코인' },
      ]
      const goodsType = modalProp.goods
      const goodsObj = goodsKOR.find((item) => item.goods === goodsType)
      const goodsNameKOR = goodsObj ? goodsObj.goodsKOR : '본 상품'
      const coinCount =
        AltoonUserStore?.userData?.toonCoin > 0
          ? AltoonUserStore?.userData?.toonCoin || 0
          : 0

      const Title = () => {
        return <div className='pt-4'>{goodsNameKOR}을 충전할까요?</div>
      }

      const StatusComponent = () => {
        return (
          <>
            <img
              src={`/images/toon/${goodsType}.png`}
              alt='goods'
              className='w-[16vw] h-[16vw] mx-1 p-2'
            />
            {goodsType === 'ticket' && (
              <div className='px-[6vw] py-1 self-center flex flex-row items-center justify-center bg-[#d8d8d89a] text-[3vw] rounded-lg'>
                <img
                  src='/images/toon/toonCoin.png'
                  alt='goods'
                  className='w-[4vw] h-[4vw] mx-1'
                />
                보유 코인 : {coinCount}C
              </div>
            )}
            {goodsType === 'toonCoin' && (
              <div className='px-[6vw] py-1 self-center flex flex-row items-center justify-center bg-[#d8d8d89a] text-[3vw] rounded-lg'>
                <img
                  src='/images/toon/toonCoin.png'
                  alt='goods'
                  className='w-[4vw] h-[4vw] mx-1'
                />
                충전하실 금액을 선택해주세요
              </div>
            )}
          </>
        )
      }

      const ItemList = () => {
        const ItemComponent = ({
          itemId,
          rewardType,
          rewardAmount,
          cost,
          unit,
        }) => {
          return (
            <button
              type='button'
              className='py-2 flexRow items-center justify-between w-full border-b'
              onClick={() => {
                setOpenTicketModal(false)
                if (goodsType === 'toonCoin') {
                  navigate(
                    `/altoon-item?token=${AuthStore?.token}&itemId=${itemId}`,
                  )
                } else {
                  setModalProp({
                    goodsNameKOR,
                    rewardType,
                    rewardAmount,
                    cost,
                    unit,
                    coinCount,
                  })
                  setModalType('ticketExchangeConfirm')
                  setOpenTicketModal(true)
                }
              }}
            >
              <div className='flexRow items-center justify-start text-[4.2vw] font-semibold'>
                <img
                  src={`/images/toon/${rewardType}.png`}
                  alt='ticket'
                  className='w-[7vw] h-[7vw] mr-2'
                />
                {goodsNameKOR}
                <div className='ml-1'>
                  {goodsType === 'toonCoin'
                    ? `${commaNumber(rewardAmount)}C`
                    : `${commaNumber(rewardAmount)}개`}
                </div>
              </div>
              <TreasureHuntButton
                className={`font-bold text-[3vw] shadow-none px-4 ${
                  goodsType === 'ticket'
                    ? 'bg-[#EB4F47] hover:bg-red-700'
                    : 'bg-[#FF8317] hover:bg-orange-800'
                }`}
                style={{}}
                clickable={goodsType === 'toonCoin' ? true : coinCount > cost}
                text={`${commaNumber(cost)} ${unit}`}
                onClick={() => {
                  setOpenTicketModal(false)
                  if (goodsType === 'toonCoin') {
                    navigate(
                      `/altoon-item?token=${AuthStore?.token}&itemId=${itemId}`,
                    )
                  } else {
                    setModalProp({
                      goodsNameKOR,
                      rewardType,
                      rewardAmount,
                      cost,
                      unit,
                      coinCount,
                    })
                    setModalType('ticketExchangeConfirm')
                    setOpenTicketModal(true)
                  }
                }}
              />
            </button>
          )
        }
        if (goodsType === 'ticket') {
          return (
            <>
              <div className='p-4 w-full flexCol justify-center items-center'>
                <ItemComponent
                  rewardType='ticket'
                  rewardAmount={1}
                  cost={300}
                  unit='C'
                />
                <ItemComponent
                  rewardType='ticket'
                  rewardAmount={5}
                  cost={1500}
                  unit='C'
                />
                <ItemComponent
                  rewardType='ticket'
                  rewardAmount={10}
                  cost={2700}
                  unit='C'
                />
                <ItemComponent
                  rewardType='ticket'
                  rewardAmount={20}
                  cost={5400}
                  unit='C'
                />
                <ItemComponent
                  rewardType='ticket'
                  rewardAmount={50}
                  cost={13500}
                  unit='C'
                />
              </div>
            </>
          )
        }
        if (goodsType === 'toonCoin') {
          return (
            <>
              <div className='p-4 w-full flexCol justify-center items-center'>
                <ItemComponent
                  itemId='65a48f90ac6f4c5c255acd36'
                  rewardType='toonCoin'
                  rewardAmount={1250}
                  cost={1500}
                  unit='원'
                />
                <ItemComponent
                  itemId='65a4bef1ac6f4c5c255acd38'
                  rewardType='toonCoin'
                  rewardAmount={2500}
                  cost={3000}
                  unit='원'
                />
                <ItemComponent
                  itemId='65a4bf55ac6f4c5c255acd39'
                  rewardType='toonCoin'
                  rewardAmount={5000}
                  cost={6000}
                  unit='원'
                />
                <ItemComponent
                  itemId='65a4bf7bac6f4c5c255acd3a'
                  rewardType='toonCoin'
                  rewardAmount={10000}
                  cost={12000}
                  unit='원'
                />
                <ItemComponent
                  itemId='65a4c038ac6f4c5c255acd3b'
                  rewardType='toonCoin'
                  rewardAmount={25000}
                  cost={30000}
                  unit='원'
                />
              </div>
            </>
          )
        }
      }
      const ButtonComponent = () => {
        return (
          <div className='w-full flex flex-row text-[4.2vw]'>
            <button
              type='button'
              className={`py-4 w-full 
              ${
                goodsType === 'ticket'
                  ? 'bg-[#EB4F47] hover:bg-red-700'
                  : 'bg-[#FF8317] hover:bg-orange-800'
              }
                transition ease-in duration-200 text-white rounded-b-lg`}
              onClick={async () => {
                setOpenTicketModal(false)
              }}
            >
              확인
            </button>
          </div>
        )
      }
      return (
        <>
          <div className='fixed top-0 left-0 w-full h-full bg-gray-800 opacity-70 z-20' />
          <div
            style={{ top: `${modalTop}px` }}
            className='absolute top-[16vw] left-[10vw] z-30 w-4/5 h-[146vw] bg-white font-bold flex flex-col items-center justify-between rounded-xl'
          >
            <div />
            <div className='px-4 text-center flex flex-col items-center justify-center text-lg'>
              <Title />
              <StatusComponent />
            </div>
            <ItemList />
            <ButtonComponent />
          </div>
        </>
      )
    }

    if (modalName === 'ticketExchangeConfirm') {
      const Title = () => {
        return (
          <div className='py-4'>{modalProp.goodsNameKOR}을 충전할까요?</div>
        )
      }

      const InfoSection = () => {
        return (
          <div className='font-normal text-[4vw] pb-2'>
            <div>
              코인 {commaNumber(modalProp.cost)}
              {modalProp.unit}으로
            </div>
            <div>
              <span className='font-bold'>
                {' '}
                {modalProp.goodsNameKOR} {modalProp.rewardAmount}개
              </span>
              를 충전할게요
            </div>
          </div>
        )
      }

      const StatusComponent = () => {
        return (
          <div className='px-[6vw] py-1 self-center flex flex-row items-center justify-center bg-[#d8d8d89a] text-[3vw] rounded-lg'>
            <img
              src='/images/toon/toonCoin.png'
              alt='goods'
              className='w-[4vw] h-[4vw] mx-1'
            />
            보유 코인 : {commaNumber(modalProp.coinCount)}C
          </div>
        )
      }

      const ButtonComponent = () => {
        return (
          <button
            type='button'
            className='my-4 py-2 w-4/5 bg-[#EB4F47] hover:bg-red-700 transition ease-in duration-200 text-[4.2vw] text-white rounded'
            onClick={async () => {
              const result = await backendApis.exchangeToonCoin(
                modalProp.rewardType,
                modalProp.rewardAmount,
                modalProp.cost,
              )
              if (result?.status === 200) {
                if (modalProp.rewardType === 'ticket') {
                  if (AltoonUserStore?.userData?.ticket) {
                    AltoonUserStore.increase(
                      'userData.ticket',
                      modalProp.rewardAmount,
                    )
                  } else {
                    AltoonUserStore.set(
                      'userData.ticket',
                      modalProp.rewardAmount,
                    )
                  }
                }

                AltoonUserStore.increase(
                  'userData.toonCoin',
                  modalProp.cost * -1,
                )
              } else {
                ToastStore.networkError()
              }
              setOpenTicketModal(false)
            }}
          >
            교환하기
          </button>
        )
      }
      return (
        <>
          <button
            type='button'
            aria-label='close'
            className='absolute top-[26vw] right-[10vw] w-[10vw] h-[10vw] flex items-center justify-center z-40'
            onClick={() => {
              setOpenTicketModal(false)
            }}
          >
            <CloseThin className='' />
          </button>
          <div className='fixed top-0 left-0 w-full h-full bg-gray-800 opacity-70 z-20' />
          <div
            style={{ top: `${modalTop}px` }}
            className='absolute top-[40vw] left-[10vw] z-30 w-4/5 h-[100vw] bg-white font-bold flex flex-col items-center justify-around rounded-xl'
          >
            <div />
            <div className='px-4 text-center flex flex-col items-center justify-center text-lg'>
              <Title />
              <img
                src='/images/toon/ic_ticketandpoint_big.png'
                alt='ticket'
                className='w-[16vw] h-[16vw] mx-1 mb-2'
              />
              <InfoSection />
              <StatusComponent />
            </div>
            <ButtonComponent />
          </div>
        </>
      )
    }

    if (modalName === 'bulkPurchase') {
      return (
        <>
          <div className='fixed top-0 left-0 w-full h-full bg-gray-800 opacity-70 z-20' />
          <div
            style={{ top: `${modalTop}px` }}
            className='fixed top-[25vh] left-[10vw] z-30 w-4/5 h-[40vh] bg-white font-bold flex flex-col items-center justify-between rounded-xl'
          >
            <button
              type='button'
              className='px-4 py-2 mr-2 mt-2 mb-1 flex flex-row items-center justify-center self-end bg-[#FAEBEB] text-[2vw] rounded-full'
              onClick={() => {
                navigate(`/altoon-ticket?token=${AuthStore.token}`)
              }}
            >
              내 코인
              <img
                src='/images/toon/toonCoin.png'
                alt='toonCoin'
                className='w-4 h-4 mx-1'
              />
              : {`${commaNumber(AltoonUserStore?.userData?.toonCoin)}`}C
            </button>
            <div className='px-4 py-4 text-center flex flex-col items-center justify-center text-[4.5vw]'>
              <div>전체 회차를 한번에 대여하시겠어요?</div>
              <div className='px-[16vw] py-[4vw] m-2 border rounded flexCol items-start text-[3.8vw]'>
                <div className='self-center pb-2'>
                  총 유료회차 {commaNumber(modalProp?.paidChapterCount)}개
                </div>
                <div className='text-gray-600 text-[3vw]'>
                  정가:{' '}
                  <span className='line-through'>
                    {commaNumber(modalProp?.paidChapterCount * toonPrice)}C
                  </span>
                </div>
                <div className=' text-[3.4vw]'>
                  할인가: {commaNumber(modalProp?.totalPrice)}C
                </div>
              </div>
              <div className='text-start pt-2 text-xs text-[2.6vw] text-gray-500'>
                <div className=''>
                  * 모든 회차를 3일 동안 무제한으로 볼 수 있어요
                </div>
                <div className=''>* 결제 후 환불은 불가능해요</div>
              </div>
            </div>

            <div className='w-full flex flex-row text-[4.2vw]'>
              <button
                type='button'
                className='py-4 w-full bg-gray-200 hover:bg-gray-300 text-gray-500 transition ease-in duration-200 rounded-bl-lg'
                onClick={async () => {
                  setOpenTicketModal(false)
                }}
              >
                취소
              </button>
              <button
                type='button'
                className='py-4 w-full bg-[#EB4F47] hover:bg-red-700 transition ease-in duration-200 text-white rounded-br-lg'
                onClick={async () => {
                  if (
                    AltoonUserStore?.userData?.toonCoin < modalProp?.totalPrice
                  ) {
                    setOpenTicketModal(false)
                    modalProp?.handleLackCoin()
                  } else {
                    setOpenTicketModal(false)
                    modalProp?.handleSuccess()
                  }
                }}
              >
                전체 구매하기
              </button>
            </div>
          </div>
        </>
      )
    }

    if (modalName === 'useCoin') {
      const [isLoading, setIsLoading] = useState(false)
      return (
        <>
          <div className='fixed top-0 left-0 w-full h-full bg-gray-800 opacity-70 z-20' />
          <div
            style={{ top: `${modalTop}px` }}
            className={`fixed ${
              modalProp?.toonInfo?.toonType === 'HorizontalScroll_reverse'
                ? 'left-[17vw]'
                : 'right-[17vw]'
            } top-[30vh] w-[66vw] z-30 h-[30vh] bg-white font-bold flex flex-col items-center justify-between rounded-xl`}
          >
            <div />
            <div className='p-4 text-center flex flex-col items-center justify-center text-lg'>
              <div>코인 {toonPrice}C를 사용해서</div>
              <div>다음 회차를 보시겠어요?</div>
              <button
                type='button'
                className='px-4 py-1 mr-2 mt-4 self-center flex flex-row items-center justify-center bg-[#d8d8d89a] text-[4vw] rounded-lg'
                onClick={() => {
                  navigate(`/altoon-ticket?token=${AuthStore.token}`)
                }}
              >
                보유
                <img
                  src='/images/toon/toonCoin.png'
                  alt='toonCoin'
                  className='w-4 h-4 mx-1'
                />
                : {`${AltoonUserStore?.userData?.toonCoin}`}C
              </button>
              <div className='pt-4 text-xs text-[2.6vw] text-gray-500'>
                {modalProp?.chapter?.onlyPaidCoin
                  ? '* 본 회차는 유료로 결제한 코인으로만 볼 수 있어요'
                  : '* 코인은 미션을 통해 무료로 받을 수 있어요'}
              </div>
            </div>

            <div className='w-full flex flex-row text-[4.2vw]'>
              <button
                type='button'
                className='py-4 w-full bg-gray-200 hover:bg-gray-300 text-gray-500 transition ease-in duration-200 rounded-bl-lg'
                onClick={async () => {
                  setOpenTicketModal(false)
                }}
              >
                취소
              </button>
              <button
                type='button'
                className='py-4 w-full bg-[#EB4F47] hover:bg-red-700 transition ease-in duration-200 text-white rounded-br-lg'
                onClick={async () => {
                  if (isLoading) {
                    return
                  }
                  setIsLoading(true)
                  if (modalProp?.chapter?.onlyPaidCoin) {
                    // 유료결제코인으로만 구매 가능한 회차인 경우
                    if (AltoonUserStore?.userData?.paidCoin < toonPrice) {
                      ToastStore.toastOn({
                        type: 'error',
                        message: '무료로 획득한 코인으로는 볼 수 없는 회차에요',
                        duration: 2000,
                      })
                      setOpenTicketModal(false)
                      return
                    }
                  }

                  const result = await backendApis.useToonCoin(
                    toonId,
                    modalProp?.chapter?._id,
                    toonPrice,
                  )
                  AltoonUserStore?.increase('userData.toonCoin', toonPrice * -1)
                  if (AltoonUserStore?.userData?.paidCoin >= toonPrice) {
                    AltoonUserStore?.increase(
                      'userData.paidCoin',
                      toonPrice * -1,
                    )
                  }
                  setOpenTicketModal(false)
                  if (result.status === 200) {
                    navigate(`/altoon-reader`, {
                      state: {
                        chapterInfo: modalProp.chapter,
                        toonInfo: modalProp.toonInfo,
                        episode: modalProp.episode,
                        rentPeriod: modalProp.rentPeriod,
                        newSwiperIndex: 0,
                      },
                      replace: true,
                    })
                    if (scrollRef) {
                      scrollRef.scrollTop = 0
                    }
                  } else {
                    ToastStore.networkError()
                    navigate(`/altoon-main?token=${AuthStore.token}`)
                  }
                  setIsLoading(false)
                }}
              >
                다음 화 보기
              </button>
            </div>
          </div>
        </>
      )
    }

    if (modalName === 'lackCoin') {
      return (
        <>
          <div className='fixed top-0 left-0 w-full h-full bg-gray-800 opacity-70 z-20' />
          <div
            className={`fixed top-[30vh] ${
              modalProp?.toonInfo?.toonType === 'HorizontalScroll_reverse'
                ? 'left-[16vw]'
                : 'right-[16vw]'
            } z-30 w-[66vw] h-[30vh] bg-white font-bold flex flex-col items-center justify-between rounded-xl`}
            style={{ top: `${modalTop}px` }}
          >
            <div className='self-end flex flex-col items-center'>
              <div className='px-4 py-2 mr-2 mt-2 mb-1 flex flex-row items-center justify-center bg-[#FAEBEB] text-[3vw] rounded-full'>
                내 코인
                <img
                  src='/images/toon/toonCoin.png'
                  alt='toonCoin'
                  className='w-4 h-4 mx-1'
                />{' '}
                : {`${AltoonUserStore?.userData?.toonCoin}`}C
              </div>
            </div>
            <div className='px-4 mb-2 text-[4vw] text-center font-medium'>
              <div className='text-[5vw] font-bold'>코인이 부족해요</div>
              <div className='pt-2 text-xs text-[2.6vw] text-gray-500'>
                * 코인은 미션을 통해 무료로 받을 수 있어요
              </div>
            </div>

            <div className='w-full'>
              <button
                type='button'
                className='w-1/2 py-4 bg-gray-200 hover:bg-gray-300 text-gray-500 transition ease-in duration-200 rounded-bl-lg'
                onClick={async () => {
                  navigate(`/altoon-detail?toonId=${modalProp?.toonInfo?._id}`)
                  setOpenTicketModal(false)
                }}
              >
                취소
              </button>
              <button
                type='button'
                className='w-1/2 py-4 bg-[#EB4F47] hover:bg-red-700 transition ease-in duration-200 text-white rounded-br-lg'
                onClick={async () => {
                  navigate(`/altoon-ticket?token=${AuthStore.token}`)
                  setOpenTicketModal(false)
                }}
              >
                확인
              </button>
            </div>
          </div>
        </>
      )
    }

    if (modalName === 'rentExpired') {
      return (
        <>
          <div className='fixed top-0 left-0 w-full h-full bg-gray-800 opacity-70 z-20' />
          <div
            className='fixed top-[30vh] left-[10vw] z-30 w-4/5 h-[40vh] bg-white font-bold flex flex-col items-center justify-between rounded-xl'
            style={{ top: `${modalTop}px` }}
          >
            <div className='px-4 py-2 mr-2 mt-2 self-end flex flex-row items-center justify-center bg-[#FAEBEB] text-[2vw] rounded-full'>
              보유 코인:
              <img
                src='/images/toon/toonCoin.png'
                alt='toonCoin'
                className='w-4 h-4 mx-1'
              />
              {`${AltoonUserStore?.userData?.toonCoin}`}C
            </div>
            <div className='px-4 text-center'>
              <div className='text-lg'>무료 열람기간이 만료되었어요.</div>
              <div className='text-lg'>본 회차를 다시 보시겠어요?</div>
              <div className='pt-1 text-xs text-gray-500 font-bold'>
                {modalProp?.chapter?.onlyPaidCoin
                  ? '* 본 회차는 유료로 결제한 코인으로만 볼 수 있어요'
                  : `* 코인이 ${toonPrice || 300}C 사용돼요`}
              </div>
            </div>

            <div className='w-full flex flex-row text-[4.2vw]'>
              <button
                type='button'
                className='py-4 w-full bg-gray-200 hover:bg-gray-300 text-gray-500 transition ease-in duration-200 rounded-bl-lg'
                onClick={async () => {
                  setOpenTicketModal(false)
                }}
              >
                취소
              </button>
              <button
                type='button'
                className='py-4 w-full bg-[#EB4F47] hover:bg-red-700 transition ease-in duration-200 text-white rounded-br-lg'
                onClick={async () => {
                  if (AltoonUserStore?.userData?.toonCoin >= toonPrice) {
                    if (modalProp?.chapter?.onlyPaidCoin) {
                      // 유료결제코인으로만 구매 가능한 회차인 경우
                      if (AltoonUserStore?.userData?.paidCoin < toonPrice) {
                        ToastStore.toastOn({
                          type: 'error',
                          message:
                            '무료로 획득한 코인으로는 볼 수 없는 회차에요',
                          duration: 2000,
                        })
                        setOpenTicketModal(false)
                        return
                      }
                    }
                    const result = await backendApis.useToonCoin(
                      toonId,
                      modalProp?.chapter?._id,
                      toonPrice,
                    )
                    AltoonUserStore?.increase(
                      'userData.toonCoin',
                      toonPrice * -1,
                    )
                    if (AltoonUserStore?.userData?.paidCoin >= toonPrice) {
                      AltoonUserStore?.increase(
                        'userData.paidCoin',
                        toonPrice * -1,
                      )
                    }
                    setOpenTicketModal(false)
                    if (result.status === 200) {
                      navigate(`/altoon-reader`, {
                        state: {
                          chapterInfo: modalProp.chapter,
                          toonInfo: modalProp.toonInfo,
                          episode: modalProp.episode,
                          rentPeriod: modalProp.rentPeriod,
                          newSwiperIndex: 0,
                        },
                        replace: true,
                      })
                      if (scrollRef) {
                        scrollRef.scrollTop = 0
                      }
                    } else {
                      ToastStore.networkError()
                      navigate(`/altoon-main?token=${AuthStore.token}`)
                    }
                  } else {
                    ToastStore.toastOn({
                      type: 'error',
                      message: '코인이 부족해요',
                      duration: 2000,
                    })
                    setOpenTicketModal(false)
                  }
                }}
              >
                회차 보기
              </button>
            </div>
          </div>
        </>
      )
    }

    if (modalName === 'ratingChapter') {
      const ratingArray = [1, 2, 3, 4, 5]
      const [ratingScore, setRatingScore] = useState(
        modalProp?.ratingScore || 5,
      )
      const GetRatingComponent = ({ index }) => {
        if (ratingScore >= index) {
          return (
            <button
              type='button'
              aria-label='rating'
              onClick={() => {
                setRatingScore(index)
              }}
              style={{ padding: 0 }}
            >
              <img
                alt=''
                style={{
                  marginRight: 2,
                  width: '9vw',
                  height: '9vw',
                }}
                src='/images/toon/star.png'
              />
            </button>
          )
        }
        return (
          <button
            type='button'
            aria-label='rating'
            onClick={() => {
              setRatingScore(index)
            }}
            style={{ padding: 0 }}
          >
            <img
              alt=''
              style={{
                marginRight: 2,
                width: '9vw',
                height: '9vw',
              }}
              src='/images/StarNone.png'
            />
          </button>
        )
      }

      return (
        <>
          <button
            type='button'
            aria-label='close'
            className='fixed top-0 left-0 w-full h-full bg-gray-800 opacity-70 z-20'
            onClick={() => {
              setOpenTicketModal(false)
            }}
          />
          <div
            style={{ top: modalProp?.modalTop }}
            className={`fixed ${
              modalProp?.toonInfo?.toonType === 'HorizontalScroll_reverse'
                ? 'left-[18vw]'
                : 'right-[18vw]'
            } top-[30vh] z-30 h-[30vh] w-[60vw] bg-white font-bold flex flex-col items-center justify-between rounded-xl`}
          >
            <div className='h-2' />
            <div className='flex flex-col items-center text-[8vw]'>
              <div>{`${ratingScore}`}</div>
              <div className='flex flex-row py-2'>
                {ratingArray.map((value, index) => {
                  return <GetRatingComponent key={value} index={value} />
                })}
              </div>
              <div className='pt-1 text-[2.8vw] text-gray-500 font-bold'>
                별을 눌러서 별점을 매겨주세요
              </div>
            </div>
            <div className='w-full flex flex-row text-[4.2vw]'>
              <button
                type='button'
                className='py-4 w-full bg-gray-200 hover:bg-gray-300 text-gray-500 transition ease-in duration-200 rounded-bl-lg'
                onClick={async () => {
                  setOpenTicketModal(false)
                }}
              >
                취소
              </button>
              <button
                type='button'
                className='py-4 w-full bg-[#EB4F47] hover:bg-red-700 transition ease-in duration-200 text-white rounded-br-lg'
                disabled={ratingScore === 0}
                onClick={async () => {
                  const result = await backendApis.logToonChapterRating(
                    toonId,
                    modalProp?.chapterId,
                    ratingScore,
                  )
                  if (result?.status === 200) {
                    ToastStore.toastOn({
                      type: 'emoji',
                      emoji: '🤩',
                      message: '별점이 등록되었어요!',
                      duration: 2000,
                    })
                    setOpenTicketModal(false)
                  } else {
                    ToastStore.toastOn({
                      type: 'error',
                      message:
                        '알 수 없는 오류가 발생했어요.\n 잠시후 다시 시도해주세요',
                      duration: 2000,
                    })
                    setOpenTicketModal(false)
                  }
                }}
              >
                확인
              </button>
            </div>
          </div>
        </>
      )
    }

    if (modalName === 'noNextChapter') {
      return (
        <>
          <div className='fixed top-0 left-0 w-full h-full bg-gray-800 opacity-70 z-20' />
          <div className='fixed top-[30vh] left-[10vw] z-30 w-4/5 h-[30vh] bg-white font-bold flex flex-col items-center justify-between rounded-xl'>
            <div className='h-10' />
            <div className='px-4 mb-2 text-[4vw] text-center font-medium'>
              <div className='text-[5vw] font-bold'>아직 다음 화가 없어요</div>
              <div className='mb-2 text-[5vw] font-bold'>
                다음 화가 연재되면 알려드릴까요?
              </div>
            </div>
            <div className='w-full'>
              <button
                type='button'
                className='w-1/2 py-4 bg-gray-200 hover:bg-gray-300 text-gray-500 transition ease-in duration-200 rounded-bl-lg'
                onClick={async () => {
                  navigate(`/altoon-detail?toonId=${modalProp?.toonInfo?._id}`)
                  setOpenTicketModal(false)
                }}
              >
                취소
              </button>
              <button
                type='button'
                className='w-1/2 py-4 bg-[#EB4F47] hover:bg-red-700 transition ease-in duration-200 text-white rounded-br-lg'
                onClick={async () => {
                  const alarmSet = await backendApis.setToonAlarm(
                    'noNextChapter',
                    modalProp?.toonInfo?._id,
                  )
                  if (alarmSet.status === 200) {
                    ToastStore.toastOn({
                      type: 'emoji',
                      emoji: '🔔',
                      message: '다음 회차가 연재되면 알려드릴게요!',
                      duration: 2000,
                    })
                  } else {
                    ToastStore.toastOn({
                      type: 'error',
                      message:
                        '알 수 없는 오류가 발생했어요.\n 잠시후 다시 시도해주세요',
                      duration: 2000,
                    })
                  }
                  navigate(`/altoon-detail?toonId=${modalProp?.toonInfo?._id}`)
                  setOpenTicketModal(false)
                }}
              >
                확인
              </button>
            </div>
          </div>
        </>
      )
    }

    if (modalName === 'deleteComment') {
      return (
        <>
          <div className='fixed top-0 left-0 w-full h-full bg-gray-800 opacity-70 z-20' />
          <div
            className={`fixed top-[30vh] ${
              modalProp?.toonInfo?.toonType === 'HorizontalScroll_reverse'
                ? 'left-[10vw]'
                : 'right-[10vw]'
            } z-30 w-4/5 h-[20vh] bg-white font-bold flex flex-col items-center justify-between rounded-xl`}
          >
            <div className='h-[3vh]' />
            <div className='mb-2 text-[5vw] font-bold'>
              댓글을 삭제하시겠어요?
            </div>
            <div className='w-full'>
              <button
                type='button'
                className='w-1/2 py-4 bg-gray-200 hover:bg-gray-300 text-gray-500 transition ease-in duration-200 rounded-bl-lg'
                onClick={() => {
                  setOpenTicketModal(false)
                }}
              >
                취소
              </button>
              <button
                type='button'
                className='w-1/2 py-4 bg-[#EB4F47] hover:bg-red-700 transition ease-in duration-200 text-white rounded-br-lg'
                onClick={async () => {
                  const result = await backendApis.modifyToonComment(
                    'delete',
                    modalProp?.commentId,
                  )
                  if (result.status === 200) {
                    ToastStore.toastOn({
                      type: 'emoji',
                      emoji: '✅',
                      message: '댓글이 삭제되었어요',
                      duration: 2000,
                    })
                  } else {
                    ToastStore.toastOn({
                      type: 'error',
                      message:
                        '알 수 없는 오류가 발생했어요.\n 잠시후 다시 시도해주세요',
                      duration: 2000,
                    })
                  }
                  setOpenTicketModal(false)
                }}
              >
                확인
              </button>
            </div>
          </div>
        </>
      )
    }

    if (modalName === 'blockComment') {
      return (
        <>
          <div className='fixed top-0 left-0 w-full h-full bg-gray-800 opacity-70 z-20 content-center' />
          <div
            className={`fixed top-[30vh] ${
              modalProp?.toonInfo?.toonType === 'HorizontalScroll_reverse'
                ? 'left-[10vw]'
                : 'right-[10vw]'
            } z-30 h-[20vh] w-[80vw] bg-white font-bold flex flex-col items-center justify-between rounded-xl`}
          >
            <div className='h-[3vh]' />
            <div className='mb-2 text-[5vw] font-bold'>
              댓글을 차단하시겠어요?
            </div>
            <div className='w-full'>
              <button
                type='button'
                className='w-1/2 py-4 bg-gray-200 hover:bg-gray-300 text-gray-500 transition ease-in duration-200 rounded-bl-lg'
                onClick={() => {
                  setOpenTicketModal(false)
                }}
              >
                취소
              </button>
              <button
                type='button'
                className='w-1/2 py-4 bg-[#EB4F47] hover:bg-red-700 transition ease-in duration-200 text-white rounded-br-lg'
                onClick={async () => {
                  const result = await backendApis.modifyToonComment(
                    'block',
                    modalProp?.commentId,
                  )
                  if (result.status === 200) {
                    ToastStore.toastOn({
                      type: 'emoji',
                      emoji: '⛔',
                      message: '댓글이 차단되었어요',
                      duration: 2000,
                    })
                  } else {
                    ToastStore.toastOn({
                      type: 'error',
                      message:
                        '알 수 없는 오류가 발생했어요.\n 잠시후 다시 시도해주세요',
                      duration: 2000,
                    })
                  }
                  setOpenTicketModal(false)
                }}
              >
                확인
              </button>
            </div>
          </div>
        </>
      )
    }

    if (modalName === 'reportComment') {
      return (
        <>
          <div className='fixed top-0 left-0 w-full h-full bg-gray-800 opacity-70 z-20' />
          <div
            className={`absolute top-[30vh] ${
              modalProp?.toonInfo?.toonType === 'HorizontalScroll_reverse'
                ? 'left-[10vw]'
                : 'right-[10vw]'
            } z-30 w-[80vw] h-[20vh] bg-white font-bold flex flex-col items-center justify-between rounded-xl`}
          >
            <div className='h-[3vh]' />
            <div className='mb-2 text-[5vw] font-bold'>
              댓글을 신고하시겠어요?
            </div>
            <div className='w-full'>
              <button
                type='button'
                className='w-1/2 py-4 bg-gray-200 hover:bg-gray-300 text-gray-500 transition ease-in duration-200 rounded-bl-lg'
                onClick={() => {
                  setOpenTicketModal(false)
                }}
              >
                취소
              </button>
              <button
                type='button'
                className='w-1/2 py-4 bg-[#EB4F47] hover:bg-red-700 transition ease-in duration-200 text-white rounded-br-lg'
                onClick={async () => {
                  const result = await backendApis.modifyToonComment(
                    'report',
                    modalProp?.commentId,
                  )
                  if (result.status === 200) {
                    ToastStore.toastOn({
                      type: 'emoji',
                      emoji: '🚨',
                      message:
                        '댓글이 신고되었어요. 부적절한 내용이 있는지 빠르게 확인하고 조치할게요.',
                      duration: 2000,
                    })
                  } else {
                    ToastStore.toastOn({
                      type: 'error',
                      message:
                        '알 수 없는 오류가 발생했어요.\n 잠시후 다시 시도해주세요',
                      duration: 2000,
                    })
                  }
                  setOpenTicketModal(false)
                }}
              >
                확인
              </button>
            </div>
          </div>
        </>
      )
    }

    if (modalName === 'shareKakao') {
      const isFinishedToday = modalProp?.rewardedCount > 2
      const shareKakao = () => {
        AltoonAction.kakaoLinkShare({
          shareType: 'Altoon_toonShare',
          title: `[올툰] ${
            UserStore?.userInfo?.userName
              ? `${UserStore?.userInfo?.userName}님이`
              : '친구가'
          } 추천해요!`,
          description: '무료로 즐기는 꿀잼 웹툰, 올툰',
          imageUrl:
            'https://assets.ilevit.com/8a6787fd-acc0-4b28-aa59-993fe40a9240.png',
          payload: {},
          templateId: 104208,
        })
      }
      const ShareFriendComponent = ({ empty }) => {
        if (empty) {
          return (
            <button
              type='button'
              className='mx-2'
              onClick={() => {
                if (isFinishedToday) {
                  setOpenTicketModal(false)
                  return
                }
                shareKakao()
                setOpenTicketModal(false)
              }}
            >
              <img
                src='/images/toon/blackRoundPlus.png'
                alt='goods'
                className='w-[12vw] h-[12vw] mx-1'
              />
            </button>
          )
        }
        return (
          <div className='mx-2'>
            <img
              src='/images/toon/greenRoundCheck.png'
              alt='goods'
              className='w-[12vw] h-[12vw] mx-1'
            />
          </div>
        )
      }
      const renderShareFriendComponents = () => {
        const rewardedCount = modalProp?.rewardedCount || 0
        const components = []

        // rewardedCount가 3 이상이면, ShareFriendComponent를 3개 렌더
        if (rewardedCount >= 3) {
          for (let i = 0; i < 3; i++) {
            components.push(<ShareFriendComponent key={i} />)
          }
        } else {
          // rewardedCount가 1이나 2면, 해당 수만큼 ShareFriendComponent 렌더하고 나머지는 empty로 렌더
          for (let i = 0; i < rewardedCount; i++) {
            components.push(<ShareFriendComponent key={i} />)
          }
          for (let i = rewardedCount; i < 3; i++) {
            components.push(<ShareFriendComponent key={i} empty />)
          }
        }

        return components
      }

      return (
        <>
          <div className='fixed top-0 left-0 w-full h-full bg-gray-800 opacity-70 z-20' />
          <button
            type='button'
            aria-label='close'
            className='absolute top-[46vw] right-[10vw] w-[10vw] h-[10vw] flex items-center justify-center z-40'
            onClick={() => {
              setOpenTicketModal(false)
            }}
          >
            <CloseThin className='' />
          </button>
          <div className='absolute top-[60vw] left-[10vw] z-30 w-4/5 h-[70vw] bg-white font-bold flex flex-col items-center justify-between rounded-xl'>
            <div className='h-[3vh]' />
            <div className='pt-[10vw] mb-2 text-[5vw] font-bold flexCol '>
              링크 공유만 해도
              <span className='flexRow'>
                <img
                  src='/images/toon/ticket.png'
                  alt='goods'
                  className='w-6 h-6 mx-1'
                />
                <span className='text-[#FF5E5E] mr-1'>1개</span> 받아요
              </span>
            </div>
            <div className='flexRow py-[4vw]'>
              {renderShareFriendComponents()}
            </div>
            <div className='text-[#A5A5A5] py-[5vw]'>
              {isFinishedToday
                ? '오늘은 이미 3번 받으셨어요!'
                : '매일 3번까지 받을 수 있어요!'}
            </div>
            <button
              type='button'
              className='w-full py-4 bg-[#EB4F47] hover:bg-red-700 transition ease-in duration-200 text-white rounded-b-lg'
              onClick={async () => {
                if (isFinishedToday) {
                  setOpenTicketModal(false)
                  return
                }
                shareKakao()
                setOpenTicketModal(false)
              }}
            >
              {isFinishedToday ? '확인' : '카카오 공유하기'}
            </button>
          </div>
        </>
      )
    }
  },
)

export default ToonModal
