import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import {
  FiChevronDown,
  FiChevronLeft,
  FiChevronRight,
  FiChevronUp,
} from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import { useSearchParam } from 'react-use'

import AlwayzImage from '../components/atoms/AlwayzImage'
import ToonModal from '../components/templates/ToonModal'
import AltoonTagComponent from '../components/toon/AltoonTagComponent'
import THImageText from '../components/treasureHunt/THImageText'
import AltoonUserStore from '../stores/AltoonUserStore'
import AuthStore from '../stores/AuthStore'
import ToastStore from '../stores/ToastStore'
import backendApis from '../utils/backendApis'
import resize from '../utils/resize'

const AltoonDetailPage = observer(() => {
  const navigate = useNavigate()
  const token = AuthStore.token
  const toonId = useSearchParam('toonId')
  const rentPeriod = 3
  const scrollContainerRef = useRef(null)

  const [isLoading, setIsLoading] = useState(false)
  const [toonInfo, setToonInfo] = useState({})
  const [chapterInfo, setChapterInfo] = useState([])
  const [paidChapterCount, setPaidChapterCount] = useState()
  const [toonPrice, setToonPrice] = useState(300)
  const [previewDays, setPreviewDays] = useState(8)
  const [nextChapter, setNextChapter] = useState()
  const [viewedChapterData, setViewedChapterData] = useState([])
  const [showPreviewChapter, setShowPreviewChapter] = useState(false)
  const [openTicketModal, setOpenTicketModal] = useState(false)
  const [seeDescription, setSeeDescription] = useState(false)
  const [modalType, setModalType] = useState('')
  const [modalProp, setModalProp] = useState([])
  const [showBulkPurchaseButton, setShowBulkPurchaseButton] = useState(false)
  const [bulkPurchaseComplete, setBulkPurchaseComplete] = useState(false)

  const dayNameGenerator = (date) => {
    switch (date) {
      case 'Mon':
        return '월'
      case 'Tue':
        return '화'
      case 'Wed':
        return '수'
      case 'Thu':
        return '목'
      case 'Fri':
        return '금'
      case 'Sat':
        return '토'
      case 'Sun':
        return '일'
      default:
        return '월'
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      if (!toonId || toonId === 'undefined') {
        ToastStore.toastOn({
          type: 'error',
          message: '알 수 없는 오류가 발생했어요.\n 잠시후 다시 시도해주세요',
          duration: 2000,
        })
        return
      }
      const result = await backendApis.loadToonDetail(toonId)
      if (result?.status === 200) {
        const viewLog = await backendApis.loadToonViewLog(toonId)
        const chapterList = await backendApis.loadToonChapterInfo(
          toonId,
          !viewLog?.data?.viewedChapterIdList?.length || false,
        )
        const nextChapterInfo = await backendApis.loadChapterByEpisode(
          toonId,
          viewLog?.data?.lastViewedEpisode,
        )

        setViewedChapterData(viewLog?.data?.viewedChapterIdList || [])
        setNextChapter(nextChapterInfo?.data)

        setToonInfo(result?.data[0])
        setToonPrice(result?.data[0]?.toonPrice)
        if (result?.data[0]?.previewDays > 0) {
          setPreviewDays(result?.data[0]?.previewDays)
        }
        setChapterInfo(chapterList?.data)

        setPaidChapterCount(
          chapterList?.data?.filter((chapter) => !chapter.isFree)?.length,
        )
        if (
          result?.data[0]?.promotionInfo?.bulkPurchase?.eventStartedAt &&
          result?.data[0]?.promotionInfo?.bulkPurchase?.eventEndedAt
        ) {
          if (
            // 프로모션 기간이고,
            moment().isAfter(
              result?.data[0]?.promotionInfo?.bulkPurchase?.eventStartedAt,
            ) &&
            moment().isBefore(
              result?.data[0]?.promotionInfo?.bulkPurchase?.eventEndedAt,
            ) &&
            // 연재작품이 아니고,
            !result?.data[0]?.weekday &&
            // 전권 대여 기간이 끝났다면
            (!viewLog?.data?.lastBulkPurchasedAt ||
              moment().isAfter(
                moment(viewLog?.data?.lastBulkPurchasedAt).add(
                  rentPeriod,
                  'days',
                ),
              ))
          ) {
            setShowBulkPurchaseButton(true)
          }
        }

        AltoonUserStore.set('currentToonChapterInfo', chapterList?.data)
        AltoonUserStore.set('currentToonPrice', result?.data[0]?.toonPrice)
        await backendApis.logToonAction('AltoonDetailPage', 'enteredScreen', {
          toonId,
        })

        const savedPosition = window.localStorage.getItem(
          `detailPage_scrollPosition_${toonId}`,
        )
        if (savedPosition) {
          setTimeout(() => {
            scrollContainerRef.current.scrollTop = parseInt(savedPosition, 10)
          }, 100)
        }
      } else {
        ToastStore.toastOn({
          type: 'error',
          message: '알 수 없는 오류가 발생했어요.\n 잠시후 다시 시도해주세요',
          duration: 2000,
        })
      }

      setIsLoading(false)
    }
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'allowiPhoneGesture',
        boolean: false,
      }),
    )

    fetchData()
  }, [bulkPurchaseComplete])

  useEffect(() => {
    const handleScroll = (e) => {
      // 스크롤 위치 기억하는 용도
      window.localStorage.setItem(
        `detailPage_scrollPosition_${toonId}`,
        e.target.scrollTop,
      )
    }

    const scrollContainer = scrollContainerRef.current
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  const HeaderComponent = () => {
    return (
      <section className='fixed top-0 w-full h-16 bg-white jua z-10 py-2 flex flex-row items-center justify-between overflow-hidden'>
        <button
          type='button'
          aria-label='back'
          className='px-3 py-2 whitespace-nowrap'
          onClick={async () => {
            navigate(`/altoon-main?token=${token}`)
          }}
        >
          <FiChevronLeft className='w-8 h-8' />
        </button>
        <div className='text-2xl px-3 py-2 whitespace-nowrap'>
          {toonInfo?.title?.length > 10
            ? `${toonInfo?.title?.slice(0, 10)}...`
            : toonInfo?.title}
        </div>
        <div className='w-10' />
      </section>
    )
  }

  const ToonInfoSection = () => {
    return (
      <div className='flex flex-col items-start'>
        <div className='flex flex-row items-start justify-between py-4 mt-[10vh] rounded-lg'>
          <AlwayzImage
            src={resize(toonInfo?.thumbnail, 234, 328, 'fill')}
            alt={toonInfo?.title}
            className='w-[30vw] h-[42vw] rounded-lg flex-shrink-0'
            lazy
          />
          <div className='w-full flex flex-col pl-2'>
            {toonInfo?.bannerImage && (
              <img
                src={toonInfo?.bannerImage}
                alt={toonInfo?.title}
                className='w-full h-[16vh] rounded-t-lg'
              />
            )}
            <div className='p-2 text-xs'>
              <div className='text-lg font-semibold'>{toonInfo?.title}</div>
              <div className='flex flex-row'>
                <div>{toonInfo?.author}</div>
                {toonInfo?.weekday && (
                  <div className='text-[#999999] flex flex-row'>
                    <div className='mx-2'>|</div>
                    {toonInfo?.weekday?.map((day, index) => {
                      const dayString = dayNameGenerator(day)
                      return index === toonInfo.weekday.length - 1
                        ? `${dayString} 연재`
                        : `${dayString}, `
                    })}
                  </div>
                )}
              </div>
              <button
                type='button'
                className='flex flex-row items-end mt-2 py-2 font-semiBold text-gray-500 text-start'
                onClick={() => {
                  if (seeDescription) {
                    setSeeDescription(false)
                  } else {
                    setSeeDescription(true)
                  }
                }}
              >
                <THImageText
                  text={
                    !seeDescription && toonInfo?.description?.length > 120
                      ? `${toonInfo?.description
                          ?.slice(0, 120)
                          ?.replace(/\\n/g, '\n')}...`
                      : toonInfo?.description?.replace(/\\n/g, '\n')
                  }
                />

                {!seeDescription && toonInfo?.description?.length > 120 && (
                  <img
                    src='/images/toon/ic_detail_arrow.png'
                    alt='arrowDown'
                    className='w-[3.6vw] h-[3.6vw] ml-1'
                  />
                )}
                {!(!seeDescription && toonInfo?.description?.length > 120) && (
                  <div className='w-[10vw] h-[3.6vw] ml-1' />
                )}
              </button>
            </div>
          </div>
        </div>
        <div className='pb-4 flex flex-row text-[2.8vw] overflow-scroll flex-wrap'>
          {toonInfo?.tagList?.hot === true && (
            <AltoonTagComponent
              key={Math.random()}
              tag='hot'
              type='detailPage'
            />
          )}
          {toonInfo?.tagList?.new && (
            <AltoonTagComponent
              key={Math.random()}
              tag='new'
              type='detailPage'
            />
          )}
          {toonInfo?.tagList?.mainGenre?.map((tag) => (
            <AltoonTagComponent
              key={Math.random()}
              tag={tag}
              type='detailPage'
            />
          ))}
          {toonInfo?.tagList?.subGenre?.map((tag) => (
            <AltoonTagComponent
              key={Math.random()}
              tag={tag}
              type='detailPage'
            />
          ))}
        </div>
      </div>
    )
  }

  const FirstChapterButton = () => {
    const chapterCount = chapterInfo?.length

    return (
      <button
        type='button'
        className='flex flex-row justify-center items-center w-full mb-2 py-3 rounded bg-[#EB4F47] text-white text-[4vw] font-semibold'
        onClick={() => {
          if (viewedChapterData?.length > 0 && nextChapter) {
            if (
              moment().isSameOrBefore(
                moment(nextChapter?.publishedAt).subtract(previewDays, 'days'),
              )
            ) {
              navigate(`/altoon-reader`, {
                state: {
                  chapterInfo: chapterInfo[chapterCount - 1],
                  toonInfo,
                  episode: 1,
                  rentPeriod,
                },
                replace: false,
              })
            } else if (
              nextChapter?.isFree === false ||
              moment().isBefore(nextChapter?.publishedAt)
            ) {
              const viewedChapter = viewedChapterData?.find(
                (item) => item.chapterId.toString() === nextChapter?._id,
              )

              if (!viewedChapter) {
                if (AltoonUserStore?.userData?.toonCoin >= toonPrice) {
                  setModalType('useCoin')
                  setModalProp({
                    chapter: nextChapter,
                    toonInfo,
                    episode: nextChapter?.episode,
                    rentPeriod,
                  })
                  setOpenTicketModal(true)
                } else {
                  setModalType('lackCoin')
                  setModalProp({ toonInfo })
                  setOpenTicketModal(true)
                }
              } else {
                const daysSinceFirstViewed = moment()
                  .startOf('day')
                  .diff(
                    moment(viewedChapter.firstViewedAt).startOf('day'),
                    'days',
                  )
                if (daysSinceFirstViewed > rentPeriod) {
                  setModalType('rentExpired')
                  setModalProp({
                    chapter: nextChapter,
                    toonInfo,
                    episode: nextChapter?.episode,
                    rentPeriod,
                  })
                  setOpenTicketModal(true)
                } else {
                  navigate(`/altoon-reader`, {
                    state: {
                      chapterInfo: nextChapter,
                      toonInfo,
                      episode: nextChapter?.episode,
                      rentPeriod,
                    },
                    replace: false,
                  })
                }
              }
            } else {
              navigate(`/altoon-reader`, {
                state: {
                  chapterInfo: nextChapter,
                  toonInfo,
                  episode: nextChapter?.episode,
                  rentPeriod,
                },
                replace: false,
              })
            }
          } else {
            navigate(`/altoon-reader`, {
              state: {
                chapterInfo: chapterInfo[0],
                toonInfo,
                episode: 1,
                rentPeriod,
              },
              replace: false,
            })
          }
        }}
      >
        {viewedChapterData?.length > 0 &&
        nextChapter &&
        moment().isAfter(
          moment(nextChapter?.publishedAt).subtract(previewDays, 'days'),
        )
          ? `"${
              nextChapter?.chapterTitle?.length > 9
                ? `${nextChapter?.chapterTitle?.slice(0, 9)}..`
                : nextChapter?.chapterTitle
            }" 이어보기`
          : '첫 화 보기'}
        <FiChevronRight className='w-4 h-4 inline-block' />
      </button>
    )
  }

  const BulkPurchaseButton = () => {
    const chapterCount = chapterInfo?.length
    const totalPrice = toonInfo?.promotionInfo?.bulkPurchase?.totalPrice || 0

    return (
      <button
        type='button'
        className='fixed bottom-0 flex flex-row justify-between items-center w-full px-4 py-3 bg-gray-700 opacity-90 text-white text-[4vw] font-semibold z-20'
        onClick={async () => {
          setModalType('bulkPurchase')
          setModalProp({
            chapterCount,
            paidChapterCount,
            totalPrice,
            toonId,
            toonInfo,
            rentPeriod,
            handleLackCoin: async () => {
              setModalType('lackCoin')
              setModalProp({ toonInfo: modalProp?.toonInfo })
              setOpenTicketModal(true)
            },
            handleSuccess: async () => {
              if (
                AltoonUserStore?.userData?.toonCoin >=
                toonInfo?.promotionInfo?.bulkPurchase?.totalPrice
              ) {
                setIsLoading(true)
                const result = await backendApis.chapterBulkPurchase(
                  toonId,
                  rentPeriod,
                )
                if (result?.status === 200) {
                  AltoonUserStore?.increase(
                    'userData.toonCoin',
                    toonInfo?.promotionInfo?.bulkPurchase?.totalPrice * -1,
                  )

                  ToastStore.toastOn({
                    type: 'success',
                    message: '구매가 완료되었어요',
                    duration: 2000,
                  })
                  scrollContainerRef.current.scrollTop =
                    scrollContainerRef.current.scrollHeight
                  setBulkPurchaseComplete(true)
                } else {
                  ToastStore.toastOn({
                    type: 'error',
                    message: '구매에 실패했어요',
                    duration: 2000,
                  })
                }
                setIsLoading(false)
              }
            },
          })
          setOpenTicketModal(true)
        }}
      >
        <div>
          할인받고 전체 대여하기 (
          {moment(toonInfo?.promotionInfo?.bulkPurchase?.eventEndedAt).diff(
            moment(),
            'days',
          )}
          일 남음!)
        </div>
        <div className='px-4 py-2 rounded-full border-gray-300 border'>
          전체 대여
        </div>
      </button>
    )
  }

  const WeeklyNudge = () => {
    if (toonInfo?.weekday) {
      return (
        <div className='flexRow text-[3.6vw] whitespace-nowrap'>
          {toonInfo?.weekday?.length === 7 ? '매일 ' : '매주'}
          {toonInfo?.weekday?.length < 7 && (
            <div className='text-[#FF3E3E] font-semibold ml-1'>
              {toonInfo?.weekday?.map((day, index) => {
                const dayString = dayNameGenerator(day)
                return index === toonInfo.weekday.length - 1
                  ? `${dayString}요일마다 `
                  : `${dayString}, `
              })}
            </div>
          )}
          <div className='ml-1'>새로운 회차가 업데이트돼요</div>
        </div>
      )
    }
  }

  const TicketCountComponent = () => {
    return (
      <div className='pt-4 pb-2 flex flex-col justify-center items-end'>
        <button
          type='button'
          className='w-[40vw] px-2 py-2 flex flex-row items-center justify-center rounded bg-[#FFF6F6] text-[3.2vw] font-semibold'
          onClick={() => {
            navigate(`/altoon-ticket?token=${token}`)
          }}
        >
          보유 코인 :
          <img
            src='/images/toon/toonCoin.png'
            alt='toonCoin'
            className='w-[4vw] h-[4vw] mx-1'
          />
          {AltoonUserStore?.userData?.toonCoin > 0
            ? AltoonUserStore?.userData?.toonCoin || 0
            : 0}
          C
        </button>
      </div>
    )
  }

  const NoticeComponent = () => {
    return (
      <div className='w-full py-2 mt-2 mb-4 flexRow text-[3.8vw] bg-[#F6F6F6] font-semibold rounded-full'>
        <img
          src='/images/toon/megaphoneBlack.png'
          alt='megaphoneBlack'
          className='w-[4vw] h-[4vw] mr-2'
        />
        {toonInfo?.notice}
      </div>
    )
  }

  const ChapterList = () => {
    return (
      <div className='pb-10'>
        {(chapterInfo?.filter(
          (chapter) =>
            moment().isBefore(chapter?.publishedAt) &&
            moment().isAfter(
              moment(chapter?.publishedAt).subtract(previewDays, 'days'),
            ), // 미리보기 회차들
        )?.length > 0 ||
          AltoonUserStore?.userData?.isAdmin === true) && (
          <button
            type='button'
            className='px-2 py-3 w-full flex flex-row justify-between items-center border-y text-[3.8vw]'
            onClick={() => {
              setShowPreviewChapter(!showPreviewChapter)
            }}
          >
            {!showPreviewChapter && (
              <div className='flex flex-row'>
                {chapterInfo
                  ?.filter(
                    (chapter) =>
                      moment().isBefore(chapter?.publishedAt) &&
                      moment().isAfter(
                        moment(chapter?.publishedAt).subtract(
                          previewDays,
                          'days',
                        ), // 미리보기 회차들
                      ),
                  )
                  .slice(0, 4)
                  .map((chapter) => (
                    <div
                      className='relative '
                      key={chapter.chapterTitle || chapter?.episode}
                      style={{ marginRight: '-2vw' }} // 이미지가 겹치게 하기 위한 스타일
                    >
                      <img
                        src={chapter.detailThumbnail}
                        alt={toonInfo?.title}
                        className='w-[8vw] h-[8vw] rounded-full'
                      />
                      {chapter?.onlyPaidCoin && (
                        <img
                          src='/images/toon/onlyPaidCoinIcon.png'
                          alt='ticket'
                          className='absolute top-[1vw] right-[1vw] w-[6vw] h-[6vw]'
                        />
                      )}
                    </div>
                  ))}
              </div>
            )}
            <div>
              {showPreviewChapter
                ? '미리보기 접기'
                : `${
                    chapterInfo?.filter(
                      (chapter) =>
                        moment().isBefore(chapter?.publishedAt) &&
                        moment().isAfter(
                          moment(chapter?.publishedAt).subtract(
                            previewDays,
                            'days',
                          ),
                        ),
                    )?.length
                  }개의 미리보기가 있어요`}
            </div>
            {!showPreviewChapter && (
              <FiChevronDown className='w-[7vw] h-[7vw] mr-2' />
            )}
            {showPreviewChapter && (
              <FiChevronUp className='w-[7vw] h-[7vw] mr-2' />
            )}
          </button>
        )}
        {showPreviewChapter &&
          AltoonUserStore?.userData?.isAdmin === true &&
          chapterInfo
            ?.filter((chapter) => moment().isBefore(chapter?.publishedAt))
            .map((chapter) => (
              <button
                type='button'
                className={`my-2 flex flex-row items-center rounded-lg ${
                  viewedChapterData?.some(
                    (item) => item.chapterId.toString() === chapter._id,
                  )
                    ? 'bg-gray-100'
                    : 'bg-gray-200'
                }`}
                key={chapter.chapterTitle || chapter?.episode}
                onClick={() => {
                  const viewedChapter = viewedChapterData?.find(
                    (item) => item.chapterId.toString() === chapter._id,
                  )

                  if (!viewedChapter) {
                    if (AltoonUserStore?.userData?.toonCoin >= toonPrice) {
                      setModalType('useCoin')
                      setModalProp({
                        chapter,
                        toonInfo,
                        episode: chapter?.episode,
                        rentPeriod,
                      })
                      setOpenTicketModal(true)
                    } else {
                      setModalType('lackCoin')
                      setModalProp({ toonInfo })
                      setOpenTicketModal(true)
                    }
                  } else {
                    const daysSinceFirstViewed = moment()
                      .startOf('day')
                      .diff(
                        moment(viewedChapter.firstViewedAt).startOf('day'),
                        'days',
                      )
                    if (daysSinceFirstViewed > rentPeriod) {
                      setModalType('rentExpired')
                      setModalProp({
                        chapter,
                        toonInfo,
                        episode: chapter?.episode,
                        rentPeriod,
                      })
                      setOpenTicketModal(true)
                    } else {
                      navigate(`/altoon-reader`, {
                        state: {
                          chapterInfo: chapter,
                          toonInfo,
                          episode: chapter?.episode,
                          rentPeriod,
                        },
                        replace: false,
                      })
                    }
                  }
                }}
              >
                <div className='relative'>
                  <AlwayzImage
                    src={resize(
                      chapter.detailThumbnail,
                      toonInfo?.verticalThumbnail ? 360 : 234,
                      toonInfo?.verticalThumbnail ? 486 : 196,
                      'fill',
                    )}
                    alt={toonInfo?.title}
                    className={
                      toonInfo?.verticalThumbnail
                        ? 'w-[30vw] h-[43vw] rounded-lg resize-y self-stretch'
                        : 'w-[30vw] h-[25vw] rounded-lg resize-y self-stretch'
                    }
                    lazy
                  />
                  {chapter?.onlyPaidCoin && (
                    <img
                      src='/images/toon/onlyPaidCoinIcon.png'
                      alt='ticket'
                      className='absolute top-[1vw] right-[1vw] w-[6vw] h-[6vw]'
                    />
                  )}
                  <img
                    src='/images/toon/previewIcon.png'
                    alt='previewIcon'
                    className='absolute top-[1vw] left-[1vw] w-[12vw] h-[4vw]'
                  />
                </div>
                <div className='p-4 w-[60vw] h-[12vh] flex flex-col items-start justify-center font-semibold text-[3.8vw]'>
                  <div
                    className={`text-start ${
                      viewedChapterData?.some(
                        (item) => item.chapterId.toString() === chapter._id,
                      )
                        ? 'text-gray-400'
                        : 'text-gray-600'
                    }`}
                  >
                    {chapter.chapterTitle}
                  </div>
                  <div
                    className={`pt-1 flex flex-row items-center text-[3vw] font-medium ${
                      viewedChapterData?.some(
                        (item) => item.chapterId.toString() === chapter._id,
                      )
                        ? 'text-gray-400'
                        : 'text-gray-500'
                    }`}
                  >
                    <div>
                      {moment(chapter.publishedAt)
                        .startOf('day')
                        .diff(moment().startOf('day'), 'days')}
                      일 후 무료
                    </div>
                    <div className='flex items-center ml-1'>
                      {!viewedChapterData?.includes(chapter._id) ? (
                        <>
                          <img
                            src='/images/toon/toonCoin.png'
                            alt='toonCoin'
                            className='w-[4vw] h-[4vw] ml-1'
                          />
                          <div className='ml-1'>{toonPrice}C</div>
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </button>
            ))}
        {showPreviewChapter &&
          chapterInfo
            ?.filter(
              (chapter) =>
                moment().isBefore(chapter?.publishedAt) &&
                moment().isAfter(
                  moment(chapter?.publishedAt).subtract(previewDays, 'days'),
                ),
            )
            .map((chapter) => (
              <button
                type='button'
                className={`relative my-2 flex flex-row items-center rounded-lg ${
                  viewedChapterData?.some(
                    (item) => item.chapterId.toString() === chapter._id,
                  )
                    ? 'bg-gray-100'
                    : 'bg-gray-200'
                }`}
                key={chapter.chapterTitle || chapter?.episode}
                onClick={() => {
                  const viewedChapter = viewedChapterData?.find(
                    (item) => item.chapterId.toString() === chapter._id,
                  )

                  if (!viewedChapter) {
                    if (AltoonUserStore?.userData?.toonCoin >= toonPrice) {
                      setModalType('useCoin')
                      setModalProp({
                        chapter,
                        toonInfo,
                        episode: chapter?.episode,
                        rentPeriod,
                      })
                      setOpenTicketModal(true)
                    } else {
                      setModalType('lackCoin')
                      setModalProp({ toonInfo })
                      setOpenTicketModal(true)
                    }
                  } else {
                    const daysSinceFirstViewed = moment()
                      .startOf('day')
                      .diff(
                        moment(viewedChapter.firstViewedAt).startOf('day'),
                        'days',
                      )
                    if (daysSinceFirstViewed > rentPeriod) {
                      setModalType('rentExpired')
                      setModalProp({
                        chapter,
                        toonInfo,
                        episode: chapter?.episode,
                        rentPeriod,
                      })
                      setOpenTicketModal(true)
                    } else {
                      navigate(`/altoon-reader`, {
                        state: {
                          chapterInfo: chapter,
                          toonInfo,
                          episode: chapter?.episode,
                          rentPeriod,
                        },
                        replace: false,
                      })
                    }
                  }
                }}
              >
                <div className='relative'>
                  <AlwayzImage
                    src={resize(
                      chapter.detailThumbnail,
                      toonInfo?.verticalThumbnail ? 360 : 234,
                      toonInfo?.verticalThumbnail ? 486 : 196,
                      'fill',
                    )}
                    alt={toonInfo?.title}
                    className={
                      toonInfo?.verticalThumbnail
                        ? 'w-[30vw] h-[43vw] rounded-lg resize-y self-stretch'
                        : 'w-[30vw] h-[25vw] rounded-lg resize-y self-stretch'
                    }
                    lazy
                  />
                  {chapter?.onlyPaidCoin && (
                    <img
                      src='/images/toon/onlyPaidCoinIcon.png'
                      alt='ticket'
                      className='absolute top-[1vw] right-[1vw] w-[6vw] h-[6vw]'
                    />
                  )}
                  <img
                    src='/images/toon/previewIcon.png'
                    alt='ticket'
                    className='absolute top-[1vw] left-[1vw] w-[12vw] h-[4vw]'
                  />
                </div>
                <div className='p-4 w-[60vw] h-[12vh] flex flex-col items-start justify-center font-semibold text-[3.8vw]'>
                  <div
                    className={`text-start ${
                      viewedChapterData?.some(
                        (item) => item.chapterId.toString() === chapter._id,
                      )
                        ? 'text-gray-400'
                        : 'text-gray-600'
                    }`}
                  >
                    {chapter.chapterTitle}
                  </div>
                  <div
                    className={`pt-1 flex flex-row items-center text-[3vw] font-medium ${
                      viewedChapterData?.some(
                        (item) => item.chapterId.toString() === chapter._id,
                      )
                        ? 'text-gray-400'
                        : 'text-gray-500'
                    }`}
                  >
                    <div>
                      {moment(chapter.publishedAt)
                        .startOf('day')
                        .diff(moment().startOf('day'), 'days')}
                      일 후 무료
                    </div>
                    <div className='flex items-center ml-1'>
                      {!viewedChapterData?.includes(chapter._id) ? (
                        <>
                          <img
                            src='/images/toon/toonCoin.png'
                            alt='toonCoin'
                            className='w-[4vw] h-[4vw] ml-1'
                          />
                          <div className='ml-1'>{toonPrice}C</div>
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
                {!chapter.isFree &&
                  viewedChapterData?.some(
                    (item) =>
                      item.chapterId.toString() === chapter._id &&
                      moment()
                        .startOf('day')
                        .diff(
                          moment(item.firstViewedAt).startOf('day'),
                          'days',
                        ) > rentPeriod,
                  ) && (
                    <div className='absolute mt-[18vw] right-[6vw] p-2 text-xs text-gray-600 rounded-tr rounded-bl'>
                      열람 기간만료
                    </div>
                  )}
                {!chapter.isFree &&
                  viewedChapterData &&
                  !viewedChapterData?.some(
                    (item) =>
                      item.chapterId.toString() === chapter._id &&
                      moment()
                        .startOf('day')
                        .diff(
                          moment(item.firstViewedAt).startOf('day'),
                          'days',
                        ) > rentPeriod,
                  ) && (
                    <div className='absolute mt-[18vw] right-[6vw] p-2 text-[2.4vw] text-gray-600'>
                      {(() => {
                        const viewedChapter = viewedChapterData?.find(
                          (item) => item.chapterId.toString() === chapter._id,
                        )
                        if (viewedChapter && viewedChapter.firstViewedAt) {
                          const dateStr = moment(viewedChapter.firstViewedAt)
                            .startOf('D')
                            .add(3, 'days')
                            .format('M월 D일')
                          return `${dateStr}까지 무료열람`
                        }
                        return null // 아무 것도 반환하지 않을 때
                      })()}
                    </div>
                  )}
              </button>
            ))}
        {chapterInfo
          ?.filter((chapter) => moment().isAfter(chapter?.publishedAt))
          .map((chapter) => (
            <button
              type='button'
              className={`relative my-2 flex flex-row rounded-lg border-b border-[#f5f5f5] ${
                viewedChapterData?.some(
                  (item) =>
                    item.chapterId.toString() === chapter._id &&
                    !item.isBulkPurchased,
                )
                  ? 'bg-gray-100'
                  : ''
              }`}
              key={chapter.chapterTitle || chapter?.episode}
              onClick={() => {
                if (
                  chapter.isFree === false
                  // moment().isAfter(moment(chapter.publishedAt).add(previewDays, 'days'))
                ) {
                  const viewedChapter = viewedChapterData?.find(
                    (item) => item.chapterId.toString() === chapter._id,
                  )

                  if (!viewedChapter) {
                    if (AltoonUserStore?.userData?.toonCoin >= toonPrice) {
                      setModalType('useCoin')
                      setModalProp({
                        chapter,
                        toonInfo,
                        episode: chapter?.episode,
                        rentPeriod,
                      })
                      setOpenTicketModal(true)
                    } else {
                      setModalType('lackCoin')
                      setModalProp({ toonInfo })
                      setOpenTicketModal(true)
                    }
                  } else {
                    const daysSinceFirstViewed = moment()
                      .startOf('day')
                      .diff(
                        moment(viewedChapter.firstViewedAt).startOf('day'),
                        'days',
                      )
                    if (daysSinceFirstViewed > rentPeriod) {
                      setModalType('rentExpired')
                      setModalProp({
                        chapter,
                        toonInfo,
                        episode: chapter?.episode,
                        rentPeriod,
                      })
                      setOpenTicketModal(true)
                    } else {
                      navigate(`/altoon-reader`, {
                        state: {
                          chapterInfo: chapter,
                          toonInfo,
                          episode: chapter?.episode,
                          rentPeriod,
                        },
                        replace: false,
                      })
                    }
                  }
                } else {
                  navigate(`/altoon-reader`, {
                    state: {
                      chapterInfo: chapter,
                      toonInfo,
                      episode: chapter?.episode,
                      rentPeriod,
                    },
                    replace: false,
                  })
                }
              }}
            >
              <div className='relative'>
                <AlwayzImage
                  src={resize(
                    chapter.detailThumbnail,
                    toonInfo?.verticalThumbnail ? 360 : 234,
                    toonInfo?.verticalThumbnail ? 486 : 196,
                    'fill',
                  )}
                  alt={toonInfo?.title}
                  className={
                    toonInfo?.verticalThumbnail
                      ? 'w-[30vw] h-[43vw] rounded-lg resize-y self-stretch'
                      : 'w-[30vw] h-[25vw] rounded-lg resize-y self-stretch'
                  }
                  lazy
                />

                {chapter?.onlyPaidCoin && (
                  <img
                    src='/images/toon/onlyPaidCoinIcon.png'
                    alt='ticket'
                    className='absolute top-[1vw] right-[1vw] w-[6vw] h-[6vw]'
                  />
                )}
                {moment().format('YYYY-MM-DD') ===
                  moment(chapter?.publishedAt).format('YYYY-MM-DD') && (
                  <img
                    src='/images/toon/upIcon.png'
                    alt='ticket'
                    className='absolute top-[1vw] left-[1vw] w-[5vw] h-[3vw]'
                  />
                )}
              </div>
              <div className='p-4 w-[60vw] h-[12vh] flex flex-col items-start justify-center font-semibold text-[3.8vw]'>
                <div
                  className={`text-start ${
                    viewedChapterData?.some(
                      (item) => item.chapterId.toString() === chapter._id,
                    )
                      ? 'text-gray-400'
                      : ''
                  }`}
                >
                  {chapter.chapterTitle}
                </div>
                <div
                  className={`pt-1 flex flex-row items-center text-[3vw] font-medium ${
                    viewedChapterData?.some(
                      (item) => item.chapterId.toString() === chapter._id,
                    )
                      ? 'text-gray-400'
                      : 'text-gray-600'
                  }`}
                >
                  {chapter.chapterRatingCount !== 0 && (
                    <div className='flex flex-row items-center justify-end mr-2'>
                      <img
                        alt=''
                        style={{
                          marginRight: 2,
                          width: '2.4vw',
                          height: '2.4vw',
                        }}
                        src='/images/toon/star.png'
                      />
                      {Math.round(
                        (chapter?.chapterRatingScore /
                          chapter?.chapterRatingCount) *
                          100,
                      ) / 100 || 0}
                    </div>
                  )}
                  <div className=''>
                    {moment(chapter.publishedAt).format('YY.MM.DD')}
                  </div>
                  <div className='flex items-center ml-1'>
                    {chapter.isFree === false &&
                    // moment().isAfter(
                    //   moment(chapter.publishedAt).add(previewDays, 'days'),
                    // ) &&
                    !viewedChapterData?.includes(chapter._id) ? (
                      <>
                        <img
                          src='/images/toon/toonCoin.png'
                          alt='toonCoin'
                          className='w-[4vw] h-[4vw] ml-1'
                        />
                        <div className='ml-1'>{toonPrice}C</div>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
              {!chapter.isFree &&
                viewedChapterData?.some(
                  (item) =>
                    item.chapterId.toString() === chapter._id &&
                    moment()
                      .startOf('day')
                      .diff(moment(item.firstViewedAt).startOf('day'), 'days') >
                      rentPeriod,
                ) && (
                  <div className='absolute mt-[18vw] right-[6vw] p-2 text-xs text-gray-600 rounded-tr rounded-bl'>
                    열람 기간만료
                  </div>
                )}
              {!chapter.isFree &&
                viewedChapterData &&
                !viewedChapterData?.some(
                  (item) =>
                    item.chapterId.toString() === chapter._id &&
                    moment()
                      .startOf('day')
                      .diff(moment(item.firstViewedAt).startOf('day'), 'days') >
                      rentPeriod,
                ) && (
                  <div className='absolute mt-[18vw] right-[6vw] p-2 text-[2.4vw] text-gray-600'>
                    {(() => {
                      const viewedChapter = viewedChapterData?.find(
                        (item) => item.chapterId.toString() === chapter._id,
                      )
                      if (viewedChapter && viewedChapter.firstViewedAt) {
                        const dateStr = moment(viewedChapter.firstViewedAt)
                          .startOf('D')
                          .add(3, 'days')
                          .format('M월 D일')
                        return `${dateStr}까지 무료열람`
                      }
                      return null // 아무 것도 반환하지 않을 때
                    })()}
                  </div>
                )}
            </button>
          ))}
      </div>
    )
  }

  const ScrollButton = () => {
    return (
      <div type='button' className='w-[8vw] fixed bottom-[6vh] right-4'>
        <button
          type='button'
          className='rounded-full bg-white shadow-md'
          onClick={() => {
            if (scrollContainerRef.current) {
              scrollContainerRef.current.scrollTop = 0
            }
          }}
        >
          <img src='/images/toon/toTopIcon.png' alt='up' className='w-full' />
        </button>
        <button
          type='button'
          className='rounded-full bg-white shadow-md'
          onClick={() => {
            if (scrollContainerRef.current) {
              scrollContainerRef.current.scrollTop =
                scrollContainerRef.current.scrollHeight
            }
          }}
        >
          <img
            src='/images/toon/toBottomIcon.png'
            alt='up'
            className='w-full'
          />
        </button>
      </div>
    )
  }

  const LoadingIndicator = () => {
    return (
      <div>
        <div className='fixed inset-0 z-10 w-full h-full bg-gray-800 opacity-70' />
        <div style={{ left: '40%', top: '40%' }} className='fixed z-20'>
          <ClipLoader
            color='#ff3e3e'
            loading={isLoading}
            size={80}
            aria-label='Loading Spinner'
            data-testid='loader'
          />
        </div>
      </div>
    )
  }

  return (
    <div
      className={`${openTicketModal ? 'fixed' : ''} w-full overflow-y-scroll`}
      style={{ height: '100vh' }}
      ref={scrollContainerRef}
    >
      <HeaderComponent />
      {chapterInfo?.length > 0 && (
        <div className='px-4'>
          <ToonInfoSection />
          <FirstChapterButton />

          <WeeklyNudge />
          <TicketCountComponent />
          {toonInfo?.notice?.length > 0 && <NoticeComponent />}
          <ChapterList />
        </div>
      )}
      <ScrollButton />
      {isLoading && <LoadingIndicator />}
      {showBulkPurchaseButton && !bulkPurchaseComplete && (
        <BulkPurchaseButton />
      )}
      {openTicketModal && (
        <ToonModal
          modalName={modalType}
          toonId={toonId}
          coinCount={
            AltoonUserStore?.userData?.toonCoin > 0
              ? AltoonUserStore?.userData?.toonCoin || 0
              : 0
          }
          modalProp={modalProp}
          setOpenTicketModal={setOpenTicketModal}
        />
      )}
    </div>
  )
})

export default AltoonDetailPage
