import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import TreasureHuntAction from '../../../actions/TreasureHuntAction'
import AuthStore from '../../../stores/AuthStore'
import RouteStore from '../../../stores/RouteStore'
import THUserStore from '../../../stores/treasureHunt/THUserStore'
import { coolTimeCheck, shortenText } from '../../../utils/utility'

const FriendItem = observer(({ friend, index }) => {
  const [iconSrc, setIconSrc] = useState('none')
  const [isAtFriendPage, setIsAtFriendPage] = useState(
    window.location.pathname.includes('/treasureHunt/'),
  )

  useEffect(() => {
    const friendMissions = friend?.missions ?? []
    const friendClickedAt = window.localStorage.getItem(
      `friendClickedAt_${friend?.friendId}`,
    )
    const now = moment().format('YYYY-MM-DD')
    let tempSrc = 'none'

    for (const mission of friendMissions) {
      if (mission?.type === 'action') {
        if (mission?.tag === 'search') {
          const friendNudgeSearch = window.localStorage.getItem(
            `friendNudgeSearch_${THUserStore?.currentFriend?.friendId?.toString()}`,
          )
          if (moment().format('YYYY-MM-DD') !== friendNudgeSearch) {
            tempSrc = 'ping'
            break
          }
        } else {
          tempSrc = 'ping'
        }
        break
      } else if (mission?.type === 'reward') {
        tempSrc = 'gift'
        break
      } else {
        tempSrc = 'none'
      }
    }

    if (tempSrc === 'ping' && friendClickedAt !== now) {
      setIconSrc(tempSrc)
      window.localStorage.setItem(
        `friendClickedAt_${friend?.friendId}`,
        moment().format('YYYY-MM-DD'),
      )
    } else if (tempSrc !== 'ping') {
      setIconSrc(tempSrc)
    }
  }, [friend])

  useEffect(() => {
    setIsAtFriendPage(RouteStore?.navigatePath?.includes('/treasureHunt/'))
  }, [RouteStore?.navigatePath])

  return (
    <div
      className='flex-shrink-0'
      onClick={() => {
        RouteStore?.navigate(`/treasureHunt/friend?token=${AuthStore?.token}`)
        if (
          friend?.friendId?.toString() !==
          THUserStore?.currentFriend?.friendId?.toString()
        ) {
          THUserStore.set('currentFriend', {
            ...friend,
            index,
          })
        }
        setIconSrc('none')
      }}
    >
      <div
        className={`
      relative default justify-center items-center border-[3px] rounded-full
      ${
        THUserStore?.currentFriend?.friendId === friend?.friendId &&
        isAtFriendPage
          ? 'border-[white]'
          : 'border-[#000]/0'
      }
      `}
      >
        <img
          className='w-[12vw] h-[12vw]'
          style={{
            borderRadius: 999,
          }}
          alt='profile'
          src={friend?.userImageUri ?? THUserStore?.DEFAULT_IMAGE}
        />
        {iconSrc !== 'none' && (
          <img
            className='absolute w-[5vw] -bottom-1 -right-1'
            alt='profile'
            src={`../images/treasureHunt/${iconSrc}.png`}
          />
        )}
      </div>
      <div className='default justify-center items-center font-bold text-[#313138] text-[3.5vw] '>
        {shortenText(friend?.userName ?? '보물친구', 4)}
      </div>
    </div>
  )
})

export default FriendItem
