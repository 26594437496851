import { observer } from 'mobx-react-lite'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'
import { Toaster } from 'react-hot-toast'
import ReactModal from 'react-modal'
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom'
import { useSearchParam } from 'react-use'

import './App.css'
import RandomPick from './components/deal/game/randomPick'
import RockPaperScissors from './components/deal/game/rockPaperScissors'
import Modal from './components/templates/modal'
import THBottomSheets from './components/treasureHunt/THBottomSheets'
import THModal from './components/treasureHunt/THModal'
import THSelectItem from './components/treasureHunt/THSelectItem'
import THAlert from './components/treasureHunt/molecules/THAlert'
import ApplePang from './games/applePang/ApplePang'
import Watermelon from './games/watermelon/Watermelon'
import WatermelonArcade from './games/watermelon/WatermelonArcade'
import WatermelonTeamGame from './games/watermelon/WatermelonTeamGame'
import AddressInfoPage from './pages/AddressInfoPage'
import AlfarmBridgePage from './pages/AlfarmBridgePage'
import AlfarmCoffeeBridgePage from './pages/AlfarmCoffeeBridgePage'
import AlfarmTrial from './pages/AlfarmTrial'
import AdminScreen from './pages/AltoonCMS/AdminScreen'
import AltoonCMSLoginScreen from './pages/AltoonCMS/AltoonCMSLoginScreen'
import FindPasswordScreen from './pages/AltoonCMS/FindPasswordScreen'
import AltoonCommentPage from './pages/AltoonCommentPage'
import AltoonDetailPage from './pages/AltoonDetailPage'
import AltoonEventPage from './pages/AltoonEventPage'
import AltoonFarmLandingPage from './pages/AltoonFarmLandingPage'
import AltoonItemPage from './pages/AltoonItemPage'
import AltoonMainPage from './pages/AltoonMainPage'
import AltoonMyPage from './pages/AltoonMyPage'
import AltoonOnboardingPage from './pages/AltoonOnboardingPage'
import AltoonReaderPage from './pages/AltoonReaderPage'
import AltoonTicketPage from './pages/AltoonTicketPage'
import AltoonTicketPurchasePage from './pages/AltoonTicketPurchasePage'
import AlwalkCashwalkPage from './pages/AlwalkCashwalkPage'
import CheckInDrawHistoryScreen from './pages/DailyCheckIn/CheckInDrawHistoryScreen'
import CheckInExchangeStorePage from './pages/DailyCheckIn/CheckInExchangeStorePage'
import CheckInGifticonUpgradeScreen from './pages/DailyCheckIn/CheckInGifticonUpgradeScreen'
import CheckInHistoryScreen from './pages/DailyCheckIn/CheckInHistoryScreen'
import CheckInPigPage from './pages/DailyCheckIn/CheckInPigPage'
import DailyCheckInPage from './pages/DailyCheckIn/DailyCheckInPage'
import DecoBokMessagePage from './pages/DecoBokMessagePage'
import DecoBokPage from './pages/DecoBokPage'
import EndPage from './pages/EndPage'
import ErrorPage from './pages/ErrorPage'
import ExpireHistoryPage from './pages/ExpireHistoryPage'
import GroupPurchase from './pages/GroupPurchase'
import GroupPurchaseRegister from './pages/GroupPurchaseRegister'
import GroupPurchaseWithdraw from './pages/GroupPurchaseWithdraw'
import MVNOPreLaunchCarousel from './pages/MVNOPreLaunchCarousel'
import MoneyTreePage from './pages/MoneyTreePage'
import MoneyTreePointExchangePage from './pages/MoneyTreePointExchangePage'
import RafflePage from './pages/RafflePage'
import TestPage from './pages/TestPage'
import TreasureBoxTutorialPage from './pages/TreasureBoxTutorialPage'
import TreasureHuntCarousel from './pages/TreasureHuntCarousel'
import TreasureHuntFriend from './pages/TreasureHuntFriend'
import TreasureHuntLocationAuth from './pages/TreasureHuntLocationAuth'
import TreasureHuntPage from './pages/TreasureHuntPage'
import ZeroWonStoreFriendItemPage from './pages/ZeroWonStoreFriendItemPage'
import ZeroWonStoreItemPage from './pages/ZeroWonStoreItemPage'
import ZeroWonStorePage from './pages/ZeroWonStorePage'
import BrandDealPage from './pages/deal/BrandDealPage'
import CategoryDealPage from './pages/deal/CategoryDealPage'
import LureDealPage from './pages/deal/LureDealPage'
import PriceSortDealPage from './pages/deal/PriceSortDealPage'
import SellerDealPage from './pages/deal/SellerDealPage'
import TimeDealPage from './pages/deal/TimeDealPage'
import TreasureDealPage from './pages/deal/TreasureDealPage'
import VisitedTimeDealPage from './pages/deal/VisitedTimeDealPage'
import AuthStore from './stores/AuthStore'
import ItemImpressionStore from './stores/ItemImpressionStore'
import ToastStore from './stores/ToastStore'
import UserStore from './stores/UserStore'
import RouteController from './utils/RouteController'
import { nativeMessageListener } from './utils/nativeMessageHandler'

moment.tz.setDefault('Asia/Seoul')

ReactModal.setAppElement('#root')

function App() {
  const [isLoading, setIsLoading] = useState(true)
  const coffeeFlag = useSearchParam('coffeeFlag')
  const channelType = useSearchParam('channelType')
  const token = useSearchParam('token')
  const impressionId = useSearchParam('impressionId')
  const previousIsFrom = useSearchParam('previousIsFrom')
  const givenJourneyBlockFromFront = useSearchParam('journeyBlock') || null // unused; for backward compatibility
  const givenJourneyBlockBase = useSearchParam('journeyBlockBase') || null
  const givenEnteringComponent =
    useSearchParam('givenEnteringComponent') || null

  // Should go in deal wrapper
  const handleVisibilityChange = async () => {
    if (document.hidden) {
      console.debug(`Webscreen going hidden`)
      // Flush impressions to server
      await ItemImpressionStore.uploadAndClearImpressions()
    } else {
      console.debug(`Webscreen now visible`)
      // Refresh visible impressions
      ItemImpressionStore.increaseScreenResetCount()
    }
  }

  // initialize deals
  useEffect(() => {
    ItemImpressionStore.setPreviousIsFrom(previousIsFrom)
    if (impressionId) ItemImpressionStore.setImpressionId(impressionId)

    document.addEventListener('visibilitychange', handleVisibilityChange)
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  // initialize token on searchParams
  useEffect(() => {
    if (AuthStore.token !== token && token) {
      AuthStore.setToken(token)
    }
  }, [token])

  // initialize with backend
  useEffect(() => {
    if (AuthStore.token) {
      const journeyBlockBase =
        givenJourneyBlockBase || givenJourneyBlockFromFront
      if (journeyBlockBase) {
        ItemImpressionStore.setJourneyBlockBase(journeyBlockBase)
      }
      if (givenEnteringComponent) {
        UserStore.setEnteringComponent(givenEnteringComponent)
      }

      Promise.all([UserStore.loadAbInfo()]).then(() => {
        // item shouldn't render before above steps are all done
        setIsLoading(false)
      })
    }
  }, [AuthStore.token, givenJourneyBlockBase, givenJourneyBlockFromFront])

  useEffect(() => {
    if (window.location.href.includes('mvnoprelaunch')) {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    if (window.location.href.includes('altoon-cms')) {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    if (coffeeFlag === 'true') {
      window.location.href = `/alfarmCoffeeBridgePage?channelType=${channelType}`
    }

    window.addEventListener('message', nativeMessageListener)
    document.addEventListener('message', nativeMessageListener)

    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'ready',
        data: {
          webscreenOptions: {
            hardwareBackPressEnabled: true,
          },
        },
      }),
    )

    return () => {
      window.removeEventListener('message', nativeMessageListener)
      document.removeEventListener('message', nativeMessageListener)
    }
  }, [])

  return (
    <BrowserRouter>
      {!isLoading && <RoutesWrapper />}

      <RoutesWebEventWrapper />

      <THBottomSheets />
      <Modal />
      <THModal />
      <Toaster
        position='top-center'
        toastOptions={{
          style: {
            marginTop: '35vw',
            fontFamily: 'maplestory',
            color: '#965F42',
            fontWeight: 'bold',
          },
        }}
      />
      <THAlert />

      <RouteController />
      {ToastStore.backgroundOn && (
        <div className='pointer-events-none z-[100] fixed w-[100vw] h-[100vh] bg-black/30' />
      )}
    </BrowserRouter>
  )
}

function RoutesWrapper() {
  const location = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return (
    <Routes>
      <Route exact path='/error' element={<ErrorPage />} />
      <Route exact path='/treasurebox' element={<EndPage />} />
      <Route
        exact
        path='/treasureboxtutorial'
        element={<TreasureBoxTutorialPage />}
      />
      <Route exact path='/deal/treasure-deal' element={<TreasureDealPage />} />
      <Route exact path='/deal/category-deal' element={<CategoryDealPage />} />
      <Route
        exact
        path='/deal/price-sort-deal'
        element={<PriceSortDealPage />}
      />
      <Route exact path='/deal/lure-deal' element={<LureDealPage />} />
      <Route exact path='/deal/brand-deal' element={<BrandDealPage />} />
      <Route exact path='/deal/time-deal' element={<TimeDealPage />} />
      <Route
        exact
        path='/deal/visited-time-deal'
        element={<VisitedTimeDealPage />}
      />
      <Route
        exact
        path='/deal/rock-paper-scissors'
        element={<RockPaperScissors />}
      />
      <Route exact path='/deal/seller-deal' element={<SellerDealPage />} />
      <Route exact path='/deal/random-pick' element={<RandomPick />} />
      <Route exact path='/daily-check-in' element={<DailyCheckInPage />} />
      <Route exact path='/check-in-pig' element={<CheckInPigPage />} />
      <Route exact path='/money-tree' element={<MoneyTreePage />} />
      <Route exact path='/raffle' element={<RafflePage />} />
      <Route exact path='/expire-history' element={<ExpireHistoryPage />} />
      <Route
        exact
        path='/exchange-store'
        element={<CheckInExchangeStorePage />}
      />
      <Route
        exact
        path='/check-in-exchange-history'
        element={<CheckInHistoryScreen />}
      />
      <Route
        exact
        path='/check-in-draw-history'
        element={<CheckInDrawHistoryScreen />}
      />
      <Route
        exact
        path='/check-in-gifticon-upgrade'
        element={<CheckInGifticonUpgradeScreen />}
      />
      <Route
        exact
        path='/money-tree-point-exchange'
        element={<MoneyTreePointExchangePage />}
      />
      <Route
        exact
        path='/alwalk-cashwalk-event'
        element={<AlwalkCashwalkPage />}
      />
      <Route exact path='/altoon-main' element={<AltoonMainPage />} />
      <Route exact path='/altoon-detail' element={<AltoonDetailPage />} />
      <Route exact path='/altoon-reader' element={<AltoonReaderPage />} />
      <Route exact path='/altoon-ticket' element={<AltoonTicketPage />} />
      <Route exact path='/altoon-item' element={<AltoonItemPage />} />
      <Route
        exact
        path='/altoon-ticket-purchase'
        element={<AltoonTicketPurchasePage />}
      />
      <Route exact path='/altoon-mypage' element={<AltoonMyPage />} />
      <Route exact path='/altoon-comment' element={<AltoonCommentPage />} />
      <Route
        exact
        path='/altoon-onboarding'
        element={<AltoonOnboardingPage />}
      />
      <Route
        exact
        path='/altoon-farm-landing'
        element={<AltoonFarmLandingPage />}
      />

      <Route exact path='/altoon-event-page' element={<AltoonEventPage />} />

      <Route
        exact
        path='/altoon-cms-login'
        element={<AltoonCMSLoginScreen />}
      />

      <Route exact path='/altoon-cms/main' element={<AdminScreen />} />

      <Route
        exact
        path='/altoon-cms-login/find-password'
        element={<FindPasswordScreen />}
      />

      <Route exact path='/alfarmBridgePage' element={<AlfarmBridgePage />} />
      <Route exact path='/alfarmTrial' element={<AlfarmTrial />} />
      <Route exact path='/groupPurchase' element={<GroupPurchase />} />
      <Route
        exact
        path='/groupPurchaseRegister'
        element={<GroupPurchaseRegister />}
      />
      <Route
        exact
        path='/groupPurchaseWithdraw'
        element={<GroupPurchaseWithdraw />}
      />
      <Route
        exact
        path='/alfarmCoffeeBridgePage'
        element={<AlfarmCoffeeBridgePage />}
      />
      <Route exact path='/alfarmBridgePage' element={<AlfarmBridgePage />} />
      <Route
        exact
        path='/zeroWonStore/zeroWonStorePage'
        element={<ZeroWonStorePage />}
      />
      <Route
        exact
        path='/zeroWonStore/addressInfo'
        element={<AddressInfoPage />}
      />
      <Route
        exact
        path='/zeroWonStore/zeroWonStoreItemPage'
        element={<ZeroWonStoreItemPage />}
      />
      <Route
        exact
        path='/zeroWonStore/zeroWonStoreFriendItemPage'
        element={<ZeroWonStoreFriendItemPage />}
      />
      <Route exact path='/zeroWonStore' element={<ZeroWonStorePage />} />
      <Route exact path='/' element={<TestPage />} />
      <Route exact path='/test' element={<TestPage />} />
      <Route exact path='/watermelon' element={<Watermelon />} />
      <Route exact path='/watermelon-arcade' element={<WatermelonArcade />} />
      <Route
        exact
        path='/watermelon/teamgame'
        element={<WatermelonTeamGame />}
      />
      <Route exact path='/treasureHunt' element={<TreasureHuntPage />} />
      <Route
        exact
        path='/treasureHunt/friend'
        element={<TreasureHuntFriend />}
      />
      <Route
        exact
        path='/treasureHuntCarousel'
        element={<TreasureHuntCarousel />}
      />
      <Route exact path='/treasureHunt/selectItem' element={<THSelectItem />} />
      <Route exact path='/mvnoprelaunch' element={<MVNOPreLaunchCarousel />} />
      <Route
        exact
        path='/treasureHunt/authorizationRequest'
        element={<TreasureHuntLocationAuth />}
      />
      <Route exact path='/applePang' element={<ApplePang />} />
    </Routes>
  )
}

function RoutesWebEventWrapper() {
  const location = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return (
    <Routes>
      <Route exact path='/bok-event' element={<DecoBokPage />} />
      <Route exact path='/bok-message' element={<DecoBokMessagePage />} />
    </Routes>
  )
}

export default observer(App)
