import { observer } from 'mobx-react-lite'
import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { FiChevronLeft } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import { useSearchParam } from 'react-use'

import CoinHistorySection from '../components/toon/coinHistorySection'
import MyTicketSection from '../components/toon/myTicketSection'
import TicketMissionSection from '../components/toon/ticketMissionSection'
import THImageText from '../components/treasureHunt/THImageText'
import AuthStore from '../stores/AuthStore'
import backendApis from '../utils/backendApis'

/**
 * 올툰 열람권 페이지 (미션 및 사용법)
 */
const AltoonTicketPage = observer(() => {
  const navigate = useNavigate()
  const toonListRef = useRef(null)
  const token = useSearchParam('token')
  const purchased = useSearchParam('purchased')
  const [selectedTab, setSelectedTab] = useState('mission')

  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'allowiPhoneGesture',
        boolean: false,
      }),
    )

    backendApis.logToonAction('AltoonTicketPage', 'enteredScreen', '')

    if (purchased) {
      setShowModal(true)
    }
  }, [purchased])

  const HeaderComponent = () => {
    return (
      <section className='w-full h-16 bg-white/10 z-10 py-2 flex flex-row items-center justify-between overflow-hidden font-bold'>
        <button
          type='button'
          aria-label='back'
          className='px-3 py-2 whitespace-nowrap'
          onClick={async () => {
            navigate(-1)
          }}
        >
          <FiChevronLeft className='w-8 h-8' />
        </button>
        <div className='text-2xl py-2 whitespace-nowrap'>코인</div>
        <div className='w-14' />
      </section>
    )
  }

  const MenuTab = forwardRef(() => {
    const [borderStyle, setBorderStyle] = useState({})
    const missionRef = useRef(null)
    const usedRef = useRef(null)
    const gainedRef = useRef(null)

    useEffect(() => {
      let activeRef
      switch (selectedTab) {
        case 'mission':
          activeRef = missionRef
          break
        case 'used':
          activeRef = usedRef
          break
        case 'gained':
          activeRef = gainedRef
          break
        default:
          break
      }

      if (activeRef && activeRef.current) {
        const rect = activeRef.current.getBoundingClientRect()
        setBorderStyle({
          width: rect.width,
          left: rect.left,
        })
      }
    }, [selectedTab])

    const getButtonStyle = (tab) => {
      if (tab === selectedTab) {
        return 'px-4 font-bold text-[#FF5951]' // 선택된 장르일 때의 스타일
      }
      return 'px-4 text-gray-500' // 기본 스타일
    }

    return (
      <div className='flex flex-col items-center pb-2'>
        <div className='w-full flex flex-row justify-around px-4 py-2 text-[4vw] border-b-[0.6vw] border-[#F3F3F3] relative overflow-y-hidden overflow-scroll whitespace-nowrap'>
          <div
            style={borderStyle}
            className='absolute -bottom-[0.2vh] h-[0.6vh] bg-[#FF5951] transition-all duration-300'
          />
          <button
            ref={missionRef}
            type='button'
            className={getButtonStyle('mission')}
            onClick={() => setSelectedTab('mission')}
          >
            미션
          </button>
          <button
            ref={usedRef}
            type='button'
            className={getButtonStyle('used')}
            onClick={() => setSelectedTab('used')}
          >
            사용내역
          </button>
          <button
            ref={gainedRef}
            type='button'
            className={getButtonStyle('gained')}
            onClick={() => setSelectedTab('gained')}
          >
            획득내역
          </button>
        </div>
      </div>
    )
  })

  const BottomSection = () => {
    return (
      <div className='fixed bottom-0 w-full h-[9vh] flex flex-row justify-between items-center bg-white border-t border-[#F5F5F5] text-sm'>
        <button
          type='button'
          className='w-full flex flex-col items-center text-center'
          onClick={() => {
            navigate(`/altoon-main?token=${token}`)
          }}
        >
          <img
            src='/images/toon/ic_gnb_home_D.png'
            alt=''
            className='w-6 h-6 mx-auto mb-1'
          />
          홈
        </button>
        <button
          type='button'
          className='w-full text-center'
          onClick={() => {
            navigate(`/altoon-mypage?token=${token}`)
          }}
        >
          <img
            src='/images/toon/ic_gnb_box_B.png'
            alt=''
            className='w-6 h-6 mx-auto mb-1'
          />
          보관함
        </button>
        <button
          type='button'
          className='w-full text-center'
          onClick={() => {
            navigate(`/altoon-ticket?token=${token}`)
          }}
        >
          <img
            src='/images/toon/ic_gnb_cash_B_active.png'
            alt=''
            className='w-6 h-6 mx-auto mb-1'
          />
          내 코인
        </button>
      </div>
    )
  }

  const PurchasedWelcomeModal = () => {
    return (
      <>
        <div className='bg-[#43434350] w-full h-full fixed top-0' />
        <div className='absolute w-[80vw] h-[30vh] top-[30vh] left-[10vw] rounded-lg text-center font-bold bg-white'>
          <div className='relative px-2 whitespace-normal top-[6vh] text-[5vw]'>
            <THImageText
              fontSize='4vw'
              text={'구매 완료!\n\n올툰의 재밌는 작품들을 즐겨보세요'}
            />
          </div>
          <button
            type='button'
            className='w-full py-3 text-white bg-red-500 absolute rounded-b-lg bottom-0 left-0'
            onClick={() => {
              setShowModal(false)
              navigate(`/altoon-ticket?token=${AuthStore?.token}`)
            }}
          >
            확인
          </button>
        </div>
      </>
    )
  }

  const NoticeComponent = () => {
    return (
      <div className='w-[88vw] mx-[6vw] py-2 mb-4 flexRow text-[3vw] bg-[#F6F6F6] rounded-lg'>
        <img
          src='/images/toon/megaphoneBlack.png'
          alt='megaphoneBlack'
          className='w-[4vw] h-[4vw] mr-2'
        />
        6월 10일부터 보유할 수 있는 무료 코인이 10,000C로 제한돼요
      </div>
    )
  }

  return (
    <div className='pb-20'>
      <HeaderComponent />
      <MyTicketSection />
      <NoticeComponent />
      <MenuTab ref={toonListRef} />
      {selectedTab === 'mission' && <TicketMissionSection ref={toonListRef} />}
      {selectedTab === 'used' && (
        <CoinHistorySection ref={toonListRef} type={selectedTab} />
      )}
      {selectedTab === 'gained' && (
        <CoinHistorySection ref={toonListRef} type={selectedTab} />
      )}
      <BottomSection />
      {showModal && <PurchasedWelcomeModal />}
    </div>
  )
})

export default AltoonTicketPage
