import commaNumber from 'comma-number'
import { useState } from 'react'
import { ClipLoader } from 'react-spinners'

import UserStore from '../../../stores/UserStore'
import backendApis from '../../../utils/backendApis'
import ConfirmAlert from '../../atoms/confirmAlert'
import ImageAtom from '../../atoms/imageAtom'

let buttonPressed = false

const GifticonItemComponent = ({ itemInfo, empty = false }) => {
  const disabled = UserStore?.accumulatedPoints < itemInfo?.teamPurchasePrice
  const discountRate = Math.floor(
    ((itemInfo?.individualPurchasePrice - itemInfo?.teamPurchasePrice) /
      itemInfo?.individualPurchasePrice) *
      100,
  )
  const [isLoading, setIsLoading] = useState(false)
  const [openNoticeModal, setOpenNoticeModal] = useState(false)
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [noticeModalInfo, setNoticeModalInfo] = useState({
    title: '',
    content: '',
    onClick: () => {},
  })
  const [selectedRewardInfo, setSelectedRewardInfo] = useState({
    rewardType: '',
    rewardAmount: '',
    rewardDetail: {},
  })

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const handleOnClick = () => {
    setSelectedRewardInfo({
      rewardType: 'gifticon',
      rewardAmount: itemInfo?.teamPurchasePrice,
      rewardDetail: itemInfo,
    })
    setOpenConfirmModal(true)
  }
  const LoadingIndicator = () => {
    return (
      <div>
        <div className='fixed inset-0 z-10 w-full h-full bg-gray-800 opacity-70' />
        <div style={{ left: '40%', top: '40%' }} className='fixed z-20'>
          <ClipLoader
            color='#ff3e3e'
            loading={isLoading}
            size={80}
            aria-label='Loading Spinner'
            data-testid='loader'
          />
        </div>
      </div>
    )
  }

  const EmptyComponent = () => {
    return (
      <div className='flex flex-col items-start justify-center flex-1 w-full p-1'>
        <div
          className='bg-white rounded-lg'
          style={{
            width: '98%',
            height: '98%',
            aspectRatio: 1,
          }}
        />
        <div
          className='bg-white rounded-lg'
          style={{
            height: 28,
            width: '90%',
            marginTop: 10,
            marginBottom: 20,
            paddingBottom: 6,
          }}
        />
      </div>
    )
  }
  const ItemImageComponent = () => {
    return (
      <div className='flex justify-center place-self-center'>
        <ImageAtom
          src={itemInfo?.mainImageUri}
          className='object-cover rounded-lg'
          width={256}
          height={256}
          style={{
            width: '98%',
            height: '98%',
            aspectRatio: 1,
            borderRadius: 12,
            borderWidth: 1,
            borderColor: 'rgba(0, 0, 0, 0.06)',
          }}
        />
      </div>
    )
  }
  const ItemTitleComponent = () => {
    return (
      <div
        className='overflow-hidden leading-tight line-clamp-2 text-ellipsis text-start'
        style={{
          marginTop: 10,
          fontSize: 14,
        }}
      >
        {itemInfo?.itemTitle}
      </div>
    )
  }
  const ItemPriceComponent = () => {
    return (
      <>
        {itemInfo?.individualPurchasePrice ? (
          <div
            style={{
              color: '#999999',
              textDecorationLine: 'line-through',
              fontSize: 14,
              marginTop: 1,
            }}
          >
            <span>
              정가: {commaNumber(itemInfo?.individualPurchasePrice)}원
            </span>
          </div>
        ) : (
          <></>
        )}
        <div
          className='flex flex-row items-end whitespace-nowrap'
          style={{ fontWeight: 'bold' }}
        >
          {discountRate > 0 && (
            <div
              style={{
                color: '#FF3E3E',
                fontSize: 16,
                marginRight: 3,
              }}
            >
              {discountRate}%
            </div>
          )}
          <div className='flex flex-row items-end'>
            <div style={{ fontSize: 16, fontWeight: 'bold' }}>
              {commaNumber(itemInfo?.teamPurchasePrice)}
            </div>
            <div style={{ marginLeft: 1, marginBottom: 1, fontSize: 15 }}>
              P
            </div>
          </div>
        </div>
      </>
    )
  }
  const ExchangeButtonComponent = () => {
    return (
      <div
        style={{ fontSize: '3.6vw' }}
        className={
          disabled
            ? 'w-full px-4 py-2 mt-2 border rounded-xl bg-gray-300 text-gray-500 font-bold'
            : 'w-full px-4 py-2 mt-2 border rounded-xl bg-red-500 text-white font-bold'
        }
      >
        {disabled ? '포인트가 부족해요' : `교환하기`}
      </div>
    )
  }
  if (empty) {
    return <EmptyComponent />
  }

  return (
    <>
      <button
        type='button'
        aria-hidden='true'
        className='flex items-center justify-center w-full'
        onClick={handleOnClick}
        disabled={disabled}
      >
        <div className='flex flex-col items-center justify-center flex-1 h-full'>
          <div className='flex flex-col p-1 justify-start flex-1 w-full h-full'>
            <ItemImageComponent />

            <div className='flex flex-col justify-between items-start flex-1 pb-2'>
              <ItemTitleComponent />
              <ItemPriceComponent />
              <ExchangeButtonComponent />
            </div>
          </div>
        </div>
      </button>
      {openConfirmModal && (
        <ConfirmAlert
          modalTitle='포인트 교환'
          modalContent={[
            `${commaNumber(
              selectedRewardInfo?.rewardAmount,
            )}포인트를 사용하여 교환하시겠어요?`,
            <br key='line-break1' />,
            <div className='mt-4 text-xs' key='itemInfo'>
              상품명:{' '}
              {selectedRewardInfo?.rewardDetail?.itemTitle.length > 24
                ? `${selectedRewardInfo?.rewardDetail?.itemTitle.slice(
                    0,
                    24,
                  )}...`
                : selectedRewardInfo?.rewardDetail?.itemTitle}{' '}
              <br />
              판매가:{' '}
              {commaNumber(
                selectedRewardInfo?.rewardDetail?.individualPurchasePrice,
              )}
              원
              <br />
              할인가:{' '}
              {commaNumber(selectedRewardInfo?.rewardDetail?.teamPurchasePrice)}
              P
              <br />
              유효기간 : 29일 ~ 30일 (상품별 상이)
              <br /> 발송방법: 올웨이즈에 가입한 휴대폰번호로 발송
            </div>,
          ]}
          buttonText='확인'
          buttonLink={async () => {
            if (buttonPressed) return
            buttonPressed = true
            setIsLoading(true)
            setOpenConfirmModal(false)
            const result = await backendApis.recordReceivedGifticon({
              ...selectedRewardInfo,
              gifticonType: 'normal',
            })
            if (result?.status === 200) {
              UserStore.setAccumulatedPoints(
                UserStore?.accumulatedPoints -
                  parseInt(selectedRewardInfo?.rewardAmount, 10),
              )
              setNoticeModalInfo({
                title: '교환 성공!',
                content:
                  '성공적으로 교환되었습니다. 2~3영업일 내로 최대한 빠르게 발송해드릴게요!',
                buttonText: '확인',
                onlyOneButton: true,
                onClick: () => {
                  setOpenNoticeModal(false)
                  scrollToTop()
                  setNoticeModalInfo({})
                },
              })
            } else if (result?.status === 202 || result?.status === 401) {
              setNoticeModalInfo({
                title: '안내',
                content:
                  '오늘 이미 상품을 교환하셨어요. \n 상품은 하루에 한번만 교환할 수 있어요',
                onlyOneButton: true,
                onClick: () => {
                  setOpenNoticeModal(false)
                  setNoticeModalInfo({})
                },
              })
            } else if (
              result?.status === 201 ||
              result?.status === 402 ||
              result?.status === 400
            ) {
              setNoticeModalInfo({
                title: '안내',
                content:
                  '고객 정보를 확인할 수 없어요. 고객센터에 문의해주세요.',
                onlyOneButton: true,
                onClick: () => {
                  setOpenNoticeModal(false)
                  setNoticeModalInfo({})
                },
              })
            } else {
              setNoticeModalInfo({
                title: '안내',
                content:
                  '인터넷 연결이 불안정하여 교환에 실패했어요. 다음에 다시 시도해 주세요',
                onlyOneButton: true,
                onClick: () => {
                  setOpenNoticeModal(false)
                  setNoticeModalInfo({})
                },
              })
            }
            setOpenNoticeModal(true)

            setSelectedRewardInfo({})
            buttonPressed = false
            setIsLoading(false)
          }}
          showModal
          setShowModal={setOpenConfirmModal}
        />
      )}
      {openNoticeModal && (
        <ConfirmAlert
          modalTitle={noticeModalInfo?.title}
          modalContent={noticeModalInfo?.content}
          buttonText={noticeModalInfo?.buttonText || '확인'}
          buttonLink={noticeModalInfo?.onClick}
          showModal
          onlyOneButton={noticeModalInfo?.onlyOneButton}
          setShowModal={setOpenNoticeModal}
        />
      )}
      {isLoading && <LoadingIndicator />}
    </>
  )
}

export default GifticonItemComponent
