import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React from 'react'

import TreasureHuntAction from '../../../actions/TreasureHuntAction'
import ToastStore from '../../../stores/ToastStore'
import THUserStore from '../../../stores/treasureHunt/THUserStore'
import THImageText from '../THImageText'
import TreasureHuntButton from '../TreasureHuntButton'

const ImageTextComponent = observer(({ text }) => {
  return (
    <div
      className='w-full flex flex-row justify-between rounded-lg items-center px-4 py-3 bg-[#F2F2F2] font-bold'
      onClick={() => {
        if (
          THUserStore?.dailyPoint >= 100 &&
          THUserStore?.dailyPointBonusAt !== moment().format('YYYY-MM-DD')
        ) {
          TreasureHuntAction.getDailyPointBonusData()
        } else {
          if (THUserStore?.dailyPoint < 100) {
            ToastStore.toastOn({
              type: 'emoji',
              message: '100원 이상 모아야 받을 수 있어요!',
              duration: 2000,
              emoji: '😱',
            })
            return
          }
          if (THUserStore?.dailyPointBonus?.isReserved) {
            ToastStore.toastOn({
              type: 'emoji',
              message: `내일 들어오면 ${THUserStore?.dailyPointBonus?.tomorowRewardAmount}원을 받을 수 있어요!`,
              emoji: '😘',
              duration: 2000,
            })
            return
          }
          ToastStore.toastOn({
            type: 'emoji',
            message: '보너스는 하루에 한 번만 받을 수 있어요!',
            duration: 2000,
            emoji: '😱',
          })
        }
      }}
    >
      <THImageText
        fontSize={4}
        style={{
          color: '#313138',
        }}
        text={text}
      />
      <TreasureHuntButton
        className={`
          z-[10] w-[20vw] font-bold text-[3.5vw] shadow-none whitespace-nowrap
          ${
            THUserStore?.dailyPoint >= 100 &&
            THUserStore?.dailyPointBonusAt !== moment().format('YYYY-MM-DD') &&
            'pulsate-fwd'
          }

          `}
        text={
          THUserStore?.dailyPointBonus?.isReserved ? '예약 완료' : '보너스 받기'
        }
        style={{
          padding: '1vw 0',
        }}
        clickable={
          THUserStore?.dailyPoint >= 100 &&
          THUserStore?.dailyPointBonusAt !== moment().format('YYYY-MM-DD')
        }
        onClick={() => {
          TreasureHuntAction.getDailyPointBonusData()
        }}
        // onDisabledClick={() => {
        //   if (THUserStore?.dailyPoint < 100) {
        //     ToastStore.toastOn({
        //       type: 'emoji',
        //       message: '100원 이상 모아야 받을 수 있어요!',
        //       duration: 2000,
        //       emoji: '😱',
        //     })
        //     return
        //   }
        //   if (THUserStore?.dailyPointBonus?.isReserved) {
        //     ToastStore.toastOn({
        //       type: 'emoji',
        //       message: `내일 들어오면 ${THUserStore?.dailyPointBonus?.tomorowRewardAmount}원을 받을 수 있어요!`,
        //       emoji: '😘',
        //       duration: 2000,
        //     })
        //     return
        //   }
        //   ToastStore.toastOn({
        //     type: 'emoji',
        //     message: '보너스는 하루에 한 번만 받을 수 있어요!',
        //     duration: 2000,
        //     emoji: '😱',
        //   })
        // }}
      />
    </div>
  )
})

export default ImageTextComponent
