import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSearchParam } from 'react-use'

import MainToonComponent from '../components/toon/mainToonComponent'
import { Check } from '../images/dailyCheckIn'
import backendApis from '../utils/backendApis'

const AltoonOnboardingPage = observer(() => {
  const navigate = useNavigate()
  const token = useSearchParam('token')
  const [contentTab, setContentTab] = useState('select')
  const [selectedGenres, setSelectedGenres] = useState([])
  const [recommendingToons, setRecommendingToons] = useState([])
  const genreList = [
    {
      name: 'daily',
      description: '보기만해도 힐링되는 일상 만화',
      imageUris: [
        'https://assets.ilevit.com/708232cc-1476-4615-9c01-e06e812380c8.jpeg',
        'https://assets.ilevit.com/451d91e1-5b7f-4feb-bf9a-76d42706c0ad.jpeg',
        'https://assets.ilevit.com/e1a29ade-7a1c-4e0f-9930-b405de576115.jpeg',
      ],
    },
    {
      name: 'instatoon',
      description: '꽁냥꽁냥 귀여운 연애 일상물',
      imageUris: [
        'https://assets.ilevit.com/237999cd-04de-4eb9-9664-e737966824e2.png',
      ],
    },
    {
      name: 'romance_fantasy',
      description: '설레면서도 새로운 판타지 로맨스',
      imageUris: [
        'https://assets.ilevit.com/67000707-677e-4f7e-82e7-270f01acbd65.jpeg',
        'https://assets.ilevit.com/06c51145-ad81-49e0-9268-f2e6962b2526.jpeg',
        'https://assets.ilevit.com/4a278b3a-b80a-4da4-972b-bdefa756c2ae.jpeg',
      ],
    },
    {
      name: 'comedy',
      description: '일상에 재미가 필요하다면 개그 만화',
      imageUris: [
        'https://assets.ilevit.com/8f8b9fb6-7d0e-482c-bc5a-0f71eee15779.png',
        'https://assets.ilevit.com/99237ed3-0fe1-4f4c-bf83-3c033ae5760b.jpeg',
      ],
    },
    {
      name: 'romance',
      description: '읽으면 읽을수록 심쿵하는 현대 로맨스',
      imageUris: [
        'https://assets.ilevit.com/e7c30090-ea48-431d-8115-fa5f5ace9df8.jpeg',
        'https://assets.ilevit.com/1396dc39-806d-4ecb-9f1d-e02bc7517f15.png',
        'https://assets.ilevit.com/cbf9df98-d002-49a3-9f38-4169ae8fdef3.jpeg',
      ],
    },
    {
      name: 'drama',
      description: '다양한 세상의 이야기를 담은 드라마',
      imageUris: [
        'https://assets.ilevit.com/d2f6c979-cafa-449b-82da-a86c95cd1708.jpeg',
        'https://assets.ilevit.com/5d44031f-a542-4767-ba4c-d8185d3cbfe8.jpeg',
      ],
    },
  ]

  const handleGenreClick = (genre) => {
    if (selectedGenres?.includes(genre?.name)) {
      setSelectedGenres(selectedGenres?.filter((g) => g !== genre?.name))
    } else {
      setSelectedGenres([...selectedGenres, genre?.name])
    }
  }

  useEffect(() => {
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'allowiPhoneGesture',
        boolean: false,
      }),
    )
  }, [])

  const HeaderComponent = () => {
    return (
      <>
        <button
          type='button'
          className='w-full text-end pt-4 text-[3vw] text-gray-400 underline'
          onClick={() => {
            navigate(-1)
          }}
        >
          건너뛰기
        </button>
        <div className='flex flex-col items-start w-full text-[5.2vw] py-2 font-bold text-center'>
          <div>
            {contentTab === 'select'
              ? '좋아하는 웹툰 취향을 모두 골라보세요'
              : '아래 작품을 추천드려요!'}
          </div>
          {contentTab === 'select' && (
            <div className='text-[4vw] pt-1'>
              마음에 드실만한 작품을 추천해드릴게요
            </div>
          )}
        </div>
      </>
    )
  }

  const GenreSelectComponent = ({ genre }) => {
    return (
      <button
        type='button'
        key={genre}
        onClick={() => handleGenreClick(genre)}
        className={`w-full py-4 my-2 flex flex-row items-center rounded border  ${
          selectedGenres.includes(genre?.name)
            ? 'border-gray-600 font-semibold '
            : ''
        }`}
      >
        {!selectedGenres.includes(genre?.name) && (
          <Check className='w-8 h-8 mx-4 border rounded-full bg-gray-300' />
        )}
        {selectedGenres.includes(genre?.name) && (
          <img
            src='../images/treasureHunt/selectCheck.png'
            alt='check'
            className='w-8 h-8 mx-4 border rounded-full'
          />
        )}
        <div className='flex flex-col font-bold text-[4vw] text-start'>
          {genre?.description}
          <div className='flex flex-row mt-2'>
            {genre?.imageUris?.map((uri) => (
              <img
                key={uri}
                src={uri}
                alt='genre'
                className='w-16 h-16 mr-2 border rounded'
              />
            ))}
          </div>
        </div>
      </button>
    )
  }

  const ConfirmButton = ({ selectedGenres }) => {
    return (
      <button
        type='button'
        className={`fixed bottom-[4vh] w-4/5 py-4 rounded font-bold shadow-md ${
          selectedGenres?.length === 0
            ? 'bg-gray-300 text-gray-600'
            : 'bg-red-500 text-white'
        }`}
        onClick={async () => {
          if (selectedGenres?.length === 0) {
            alert('작품을 선택해주세요')
          } else {
            setContentTab('recommend')
            const toonResult = await backendApis.loadRecommendingToons(
              selectedGenres,
            )
            console.log('toonResult', toonResult)
            if (toonResult?.status === 200) {
              setRecommendingToons(toonResult.data)
            }
          }
        }}
      >
        {selectedGenres?.length === 0 ? '작품을 골라주세요' : '완료'}
      </button>
    )
  }
  return (
    <div className='w-full px-4 pb-28 flex flex-col items-center'>
      <HeaderComponent />
      {contentTab === 'select' && (
        <>
          <div className='w-full'>
            {genreList?.map((genre) => (
              <GenreSelectComponent key={genre?.name} genre={genre} />
            ))}
          </div>
          <ConfirmButton selectedGenres={selectedGenres} />
        </>
      )}
      {contentTab === 'recommend' && (
        <>
          <div className='w-full mt-8'>
            {recommendingToons?.map((toon, index) => (
              <MainToonComponent
                key={toon?._id}
                toon={toon}
                index={index}
                type='onboarding'
              />
            ))}
          </div>
          <button
            type='button'
            className='w-4/5 py-4 mt-10 rounded font-bold shadow-md text-[4vw] bg-gray-300 text-gray-600'
            onClick={() => {
              navigate(`/altoon-main?token=${token}`)
            }}
          >
            메인으로 가기
          </button>
        </>
      )}
    </div>
  )
})

export default AltoonOnboardingPage
