import React, { useEffect, useState } from 'react'

const ProfileCarousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length)
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [images])
  console.log(images?.length)

  return (
    <div className='relative flex flex-row w-12 h-12'>
      <div
        className='absolute transition-transform duration-500'
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt='Profile'
            className='w-12 h-12 rounded-full'
          />
        ))}
      </div>
    </div>
  )
}

export default ProfileCarousel
