import React, { useEffect, useState } from 'react'

const BackgroundItem = ({ isAnimating, delay, className }) => {
  const [shouldRender, setShouldRender] = useState(isAnimating)

  useEffect(() => {
    let timeout
    if (isAnimating) {
      setShouldRender(true)
    } else {
      timeout = setTimeout(() => setShouldRender(false), delay)
    }
    return () => clearTimeout(timeout)
  }, [isAnimating, delay])

  return shouldRender ? (
    <div
      className={`opacity-0 ${className} absolute-center animation rounded-full border-[1px] border-[#979797]
      ${isAnimating ? 'fadeInOut' : ''}
      `}
      style={{ animationDelay: `${delay * 0.1}s` }}
    />
  ) : (
    <div
      className={`${className} absolute-center animation rounded-full border-[1px] border-[#EEEEEE]`}
    />
  )
}

export default BackgroundItem
