import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import AlwayzObserver from './AlwayzObserver'

// type AlwayzImageFit = 'contain' | 'cover' | 'fill' | 'initial' | 'none'

// type AlwayzImageProps = {
//   src: string
//   width: string
//   height: string
//   lazy?: boolean
//   placeholder?: React.ReactNode
//   onLoad?: (...args: any) => void
//   fit?: AlwayzImageFit
//   alt?: string
//   className?: string
//   onClick?: (e: MouseEvent<HTMLDivElement>) => void
// }

const browserCachedImages = new Set()

const AlwayzImage = memo(
  ({
    src,
    fit = 'initial',
    lazy = false,
    alt,
    placeholder = <DefaultPlaceHolder />,
    width,
    height,
    className,
    onLoad,
    onClick,
  }) => {
    const thisRef = useRef(null)
    const imgRef = useRef(null)
    const [, setIsLoaded] = useState(false)
    const [isIntersecting, setIsIntersecting] = useState(false)

    useEffect(() => {
      setIsLoaded(false)
    }, [src])

    const shouldRenderImg = useMemo(() => {
      if (browserCachedImages.has(src)) {
        return true
      }
      if (!lazy) {
        return true
      }
      if (lazy && isIntersecting) {
        return true
      }
      return false
    }, [isIntersecting, lazy, src])

    const onIntersect = useCallback((entry) => {
      setIsIntersecting(entry.isIntersecting)
    }, [])

    const onImageLoad = useCallback(() => {
      browserCachedImages.add(src)
      setIsLoaded(true)
      onLoad?.(thisRef, imgRef.current)
    }, [onLoad, src])

    useEffect(() => {
      // onLoad 이벤트는 img 엘리먼트에서 따로 처리
      if (shouldRenderImg) {
        const img = new Image()
        img.onload = onImageLoad
        img.onerror = () => {
          throw new Error('ImageLoadError')
        }
        img.src = src
      }
    }, [onImageLoad, shouldRenderImg, src])

    return (
      <div
        className={className}
        style={{
          width,
          height,
          isolation: 'isolate',
          overflow: 'hidden',
        }}
        ref={thisRef}
        onClick={onClick}
      >
        <AlwayzObserver callback={onIntersect} className='h-full'>
          {shouldRenderImg ? (
            <img
              src={src}
              style={{
                display: 'block',
                width: '100%',
                height: '100%',
                position: 'relative',
                opacity: browserCachedImages.has(src) ? 1 : 0,
                transition: 'opacity 0.15s ease-out',
                objectFit: fit,
              }}
              alt={alt}
            />
          ) : (
            placeholder
          )}
        </AlwayzObserver>
      </div>
    )
  },
)

const DefaultPlaceHolder = () => {
  return (
    <div
      style={{
        background: '#d8d8d8',
        width: '100%',
        height: '100%',
        position: 'relative',
      }}
    />
  )
}

export default AlwayzImage
