import React from 'react'

import { CheckInEnvelope, RedPoint, YellowMoney } from '../images/dailyCheckIn'

function GaugeBar({
  currentExp,
  totalExp,
  boost = false,
  checkIn = false,
  moneyTree = false,
}) {
  if (checkIn) {
    return (
      <div style={{ position: 'relative' }}>
        <div className='gauge-bg-checkin'>
          <div
            className='gauge-bar-checkin'
            style={{
              width: `${(currentExp / totalExp) * 100}%`,
            }}
          />
        </div>
      </div>
    )
  }
  if (moneyTree) {
    return (
      <div style={{ position: 'relative' }}>
        <div className='gauge-bg-moneytree text-center align-middle'>
          <div
            className='gauge-bar-moneytree'
            style={
              currentExp === 0
                ? {
                    width: `${5}%`,
                  }
                : {
                    width: `${(currentExp / totalExp) * 100}%`,
                  }
            }
          />
        </div>
        <div
          style={{
            position: 'absolute',
            left: '20vw',
            top: '0.3vh',
            bottom: '0.3vh',
            zIndex: 0.1,
            color: '#ED8000',
            fontSize: '2vw',
          }}
        >
          {currentExp} / {totalExp}
        </div>
        <div
          style={{
            width: '8vw',
            height: '8vw',
            position: 'absolute',
            right: '-5vw',
            bottom: '-1vh',
            zIndex: 0.1,
          }}
        >
          <YellowMoney className='animate-wiggle w-full h-full' />
        </div>
      </div>
    )
  }
  return (
    <div style={{ position: 'relative' }}>
      <div className={boost ? 'gauge-bg-boost' : 'gauge-bg'}>
        <div
          className={boost ? 'gauge-bar-boost' : 'gauge-bar'}
          style={
            boost
              ? {
                  width: `${(currentExp / totalExp) * 93}%`,
                }
              : {
                  width: `${(currentExp / totalExp) * 100}%`,
                }
          }
        />
      </div>
      {!boost && (
        <div
          style={{
            width: '16vw',
            position: 'absolute',
            right: '-14vw',
            bottom: '-1vh',
            zIndex: 0.1,
          }}
        >
          <CheckInEnvelope className='animate-wiggle w-6 h-8' />
        </div>
      )}
    </div>
  )
}

export default GaugeBar
