import commaNumber from 'comma-number'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'

import TreasureHuntAction from '../../../actions/TreasureHuntAction'
import useThrottle from '../../../hooks/useThrottle'
import ABStore from '../../../stores/treasureHunt/ABStore'
import THBottomSheetStore from '../../../stores/treasureHunt/THBottomSheetStore'
import THModalStore from '../../../stores/treasureHunt/THModalStore'
import THUserStore from '../../../stores/treasureHunt/THUserStore'
import { shortenText } from '../../../utils/utility'

const MatchItem = observer(
  ({ item, index, isTutorial = false, onClickProp = null, imgStyle = {} }) => {
    const throttledOnClick = useThrottle()

    const [clicked, setClicked] = useState(false)

    const onClick = async ({ item, index }) => {
      if (onClickProp) {
        onClickProp()
        return
      }
      if (item?.clickedAt) return
      if (item?.modalData) {
        TreasureHuntAction.handleEventPopUp({ item })
        THModalStore.setType(item?.modalData)
      }
      if (!isTutorial) {
        TreasureHuntAction.clickMatch({
          match: item,
          index,
        })
      } else {
        THUserStore.increase('point', 20)
        TreasureHuntAction.setTutorialMinorStep(2)
        if (!THUserStore?.oldTutorialOn) {
          THBottomSheetStore.setType({
            type: 'tutorial',
            config: {
              subtitle: '20원을 받았어요!',
              title: '탐색을 해서 더 많은 캐릭터를 찾아보세요!',
              subImg: '../images/treasureHunt/emoji/face/60.png',
              // mainImg: '../images/treasureHunt/tutorial/push.png',
              CTAText: '확인',
              onClick: () => {},
              nextStep: 1,
              tutorialMinorStep: 3,
            },
            titleOff: true,
          })
        }
      }

      setClicked(true)
      THUserStore?.set('pointBounceAnimationOn', true)
    }

    const handlePress = ({ item, index }) => {
      if (clicked) return
      throttledOnClick(() => {
        onClick({ item, index })
      }, 200)
    }

    const handleRelease = (e) => {}

    if (item?.clickedAt) return

    return (
      <div
        id='MatchItem'
        className={`flex flex-col absolute w-[18vw] h-[18vw] scale-up opacity-0 justify-center items-center
      ${clicked || item?.clickedAt ? 'z-[0]' : 'z-[1]'}

      `}
        style={{
          left: `${item?.position?.left}%`,
          top: `${item?.position?.top}%`,
          animationDelay: `${index * 0.3}s`,
          ...item?.imgStyle,
        }}
      >
        <div
          id='MathItemInner'
          className={`absolute flex flex-col justify-center items-center 
      transition-all duration-300
      ${clicked || item?.clickedAt ? 'scale-0' : 'scale-100'}
      `}
          onClick={() => {
            if (!THUserStore?.tutorialEndAt) {
              handlePress({ item, index })
            }
          }}
          onPointerDown={() => {}}
          onPointerUp={() => {
            handlePress({ item, index })
          }}
          onPointerCancel={handleRelease}
          onPointerLeave={handleRelease}
        >
          <img
            className='z-[10] w-[16vw]'
            alt='emoji'
            src={`../images/treasureHunt/emoji/${item?.type}/${item?.emojiId}.png`}
            style={imgStyle}
          />

          <div className='text-[#8E8D9B] text-[3.5vw] z-[0] font-bold bg-white p-1 pb-0 rounded-lg -mt-1 whitespace-nowrap'>
            {shortenText(
              `${
                item?.userId
                  ? `${item?.name?.slice(0, 1)}*${item?.name?.slice(2, 9)}`
                  : item?.name
              }`,
              8,
            )}
          </div>
          {item?.tag && (
            <div className='absolute whitespace-nowrap rounded-full -top-[6vw] py-[2px] px-[8px] bg-[#FF5951] text-white font-bold z-[11] text-[3vw] text-center'>
              {item?.tag}
            </div>
          )}

          {!THUserStore?.tutorialEndAt && (
            <img
              className='absolute w-10 h-10 bottom-3 -right-[7vw] z-[50] pulsate-fwd-lg pointer-events-none'
              alt='txt'
              src='../images/treasureHunt/finger.png'
            />
          )}
        </div>
        {item?.reward > 3 && (
          <img
            className={`
        transition-all duration-300 scale-0 opacity-0
        ${clicked || item?.clickedAt ? 'scale-100 opacity-100 fadeIn' : ''}
        `}
            src='../images/treasureHunt/confetti2.png'
            alt='confetti'
          />
        )}
        {(clicked || item?.clickedAt) && (
          <div
            id='MatchItemPoint'
            className='flex flex-col absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-[80%] justify-center items-center animation-fade-in-up'
          >
            <img
              className='w-8 h-8'
              src='../images/treasureHunt/point.png'
              alt='point'
            />
            <div className='bg-white/50 whitespace-nowrap px-4 py-1 rounded-full justify-center items-center font-bold'>{`+${commaNumber(
              item?.reward,
            )}원`}</div>
          </div>
        )}
      </div>
    )
  },
)

export default MatchItem
