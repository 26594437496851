import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import THUserStore from '../../../stores/treasureHunt/THUserStore'
import { pickRandomOneArray, random } from '../../../utils/utility'
import GaugeBar from './GaugeBar'

const MyFace = observer(({ searchText }) => {
  const [todayEmoji, setTodayEmoji] = useState(null)

  useEffect(() => {
    const temp = JSON.parse(window.localStorage.getItem('todayEmojiData'))
    if (!temp || temp?.date !== moment().format('YYYY-MM-DD')) {
      const data = {
        date: moment().format('YYYY-MM-DD'),
        emojiId: random(0, 101),
      }
      window.localStorage.setItem('todayEmojiData', JSON.stringify(data))
      setTodayEmoji(data)
    } else {
      setTodayEmoji(temp)
    }
  }, [])
  return (
    <div className='z-[20] pointer-events-none absolute-center mt-4 flex flex-col justify-center items-center'>
      {THUserStore?.tutorialEndAt &&
        THUserStore?.isFindingTreasure &&
        (THUserStore?.isFindingTreasure ||
          THUserStore?.matchList?.length === 0 ||
          THUserStore?.matchList?.every(
            (match) => match?.clickedAt !== undefined,
          )) && (
          <div className='-top-[15vw] whitespace-nowrap absolute text-center font-bold text-white bg-[#FF847D] rounded-full px-4 py-2'>
            {searchText}
            <img
              className='w-5 h-5 absolute -bottom-3 left-1/2 -translate-x-1/2'
              src='../images/treasureHunt/triangle.png'
              alt='triangle'
            />
          </div>
        )}
      <div className='relative z-[30] w-[12vw] h-[12vw]'>
        <img
          className='z-[30] w-[12vw] h-[12vw]'
          alt='emoji'
          src={`../images/treasureHunt/emoji/face/${
            THUserStore?.isFindingTreasure
              ? 64
              : THUserStore?.characterInfo?.emojiId ?? 0
          }.png`}
        />
        {!THUserStore?.isFindingTreasure && (
          <img
            className='absolute left-[50%] -translate-x-1/2 bottom-0'
            src={`../images/treasureHunt/closet/${
              THUserStore?.characterInfo?.closetType ?? 'empty'
            }/${THUserStore?.characterInfo?.closetId ?? 0}.png`}
            alt='character'
          />
        )}
      </div>
      <div className='text-[#8E8D9B] text-[3.5vw] z-[20] font-bold'>
        {`${THUserStore?.isFindingTreasure ? '주변 탐색 중..' : '나'}`}
      </div>
      {/* <GaugeBar currentExp={2} totalExp={100} /> */}
    </div>
  )
})

export default MyFace
