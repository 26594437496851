import React from 'react'
import { FiChevronLeft } from 'react-icons/fi'

const HeaderComponent = (checkInAlarmConsent, checkInAlarmTime) => {
  return (
    <section className='flex flex-row bg-[#F9D7D6] overflow-hidden py-2 z-0 relative items-center'>
      <button
        type='button'
        className='flex flex-1 px-3 py-2 whitespace-nowrap'
        onClick={() => {
          window.location.href = `#navigate.${JSON.stringify({
            screen: 'MainALargeCategoryTabNavigator',
          })}`
        }}
      >
        <FiChevronLeft className='w-8 h-8 stroke-gray-600' />
      </button>
      <div className='w-full p-3 text-lg font-bold text-center text-gray-800'>
        매일매일 출석체크
      </div>
      <button
        type='button'
        className='flex flex-1 py-2 pr-4 whitespace-nowrap'
        onClick={() => {
          window.location.href = `#navigate.${JSON.stringify({
            screen: 'CheckInSettingScreen',
            prop: {
              checkInAlarmConsent,
              checkInAlarmTime,
            },
          })}`
        }}
      >
        설정
      </button>
    </section>
  )
}

export default HeaderComponent
