export default function ConfirmAlert({
  modalTitle,
  imageSource,
  modalContent,
  buttonText,
  leftButtonText,
  buttonLink,
  noButton = false,
  onlyOneButton = false,
  showModal = false,
  setShowModal,
  noFontWeight = false,
}) {
  return (
    // <>
    //   {showModal && (
    <>
      <div className='fixed inset-0 z-50 flex items-center justify-center px-5 overflow-x-hidden overflow-y-auto outline-none '>
        <div className='w-[90vw] p-4 m-auto shadow-lg rounded-2xl relative flex flex-col bg-white border-0 outline-none '>
          <div className='flex flex-col justify-between h-full w-full'>
            {imageSource && (
              <div className='mb-5 text-lg leading-relaxed text-sub-500'>
                <img alt='' src={imageSource} />
              </div>
            )}
            {modalTitle && (
              <h1
                className={`m-2 text-xl ${
                  noFontWeight ? `` : `font-semibold`
                } text-gray-800`}
              >
                {modalTitle}
              </h1>
            )}
            <div className='p-2 text-base text-gray-600'>{modalContent}</div>

            {!noButton && (
              <div className='flex items-center justify-between w-full gap-4 mt-6'>
                {!onlyOneButton && (
                  <button
                    type='button'
                    className={`py-3 px-4 bg-gray-200 hover:bg-gray-300 text-gray-500 w-full transition ease-in duration-200 text-center text-base ${
                      noFontWeight ? '' : 'font-semibold'
                    } focus:outline-none rounded-xl`}
                    onClick={() => setShowModal(false)}
                  >
                    {leftButtonText ?? '취소'}
                  </button>
                )}
                <button
                  type='button'
                  onClick={buttonLink}
                  className={`py-3 px-4 bg-red-500 hover:bg-red-700  text-white w-full transition ease-in duration-200 text-center text-base ${
                    noFontWeight ? '' : 'font-semibold'
                  } focus:outline-none  rounded-xl`}
                >
                  {buttonText}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='fixed inset-0 z-40 bg-black opacity-25' />
    </>
    //   )}
    // </>
  )
}
