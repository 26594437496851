import commaNumber from 'comma-number'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'

import UserStore from '../../../stores/UserStore'
import backendApis from '../../../utils/backendApis'
import ConfirmAlert from '../../atoms/confirmAlert'
import ImageAtom from '../../atoms/imageAtom'
import GaugeBar from '../../gaugeBar'

let buttonPressed = false

const speedGifticonItemComponent = ({ itemInfo, empty = false }) => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [openNoticeModal, setOpenNoticeModal] = useState(false)
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [exchanged, setExchanged] = useState(false)
  const [noticeModalInfo, setNoticeModalInfo] = useState({
    title: '',
    content: '',
    onClick: () => {},
  })
  const [selectedRewardInfo, setSelectedRewardInfo] = useState({
    rewardType: '',
    rewardAmount: '',
    rewardDetail: {},
  })

  const [liveItemData, setLiveItemData] = useState({ ...itemInfo })
  const disabledByPoint =
    UserStore?.accumulatedPoints < liveItemData?.teamPurchasePrice
  const disabledByStock = liveItemData?.outOfStock
  const originalPrice = parseInt(liveItemData?.individualPurchasePrice, 10)
  const dealPrice = parseInt(liveItemData?.teamPurchasePrice, 10)

  useEffect(() => {
    const fetchItems = async () => {
      const itemData = await backendApis.getSpeedGifticonStock(itemInfo?._id)
      setLiveItemData(itemData?.data)
      setExchanged(false)
    }
    if (exchanged) {
      fetchItems()
    }
  }, [exchanged])

  const handleOnClick = () => {
    setSelectedRewardInfo({
      rewardType: 'gifticon',
      rewardAmount: liveItemData?.teamPurchasePrice,
      rewardDetail: liveItemData,
    })
    setOpenConfirmModal(true)
  }

  const ItemComponent = ({ liveItemData }) => {
    return (
      <button
        type='button'
        aria-hidden='true'
        className='flex flex-col items-center justify-center w-full'
        onClick={handleOnClick}
        disabled={
          disabledByPoint || disabledByStock || liveItemData?.stock <= 0
        }
      >
        <div className='flex flex-row w-full p-4 border rounded-lg'>
          <div className='flex flex-col'>
            {liveItemData?.mainImageUri && (
              <ImageAtom
                src={liveItemData?.mainImageUri}
                className='w-[40vw] h-full border border-gray-300 rounded-lg'
              />
            )}
            <div className='py-1 mt-2 flex flex-row justify-center items-center bg-[#FFF2F2] text-[4vw] text-[#EB4F47] font-bold rounded-lg'>
              {Math.floor(
                ((originalPrice - dealPrice) / originalPrice) * 100,
              ) || 0}
              % <span className='text-[2.8vw] text-black ml-1'>할인</span>
            </div>
          </div>
          <div className='w-full flex flex-col justify-start items-start pl-4'>
            <div className='w-full pb-2 flex flex-row justify-between items-center border-b border-gray-100 font-bold text-start text-[4.6vw]'>
              <div>
                {liveItemData?.itemTitle?.length > 10
                  ? `${liveItemData?.itemTitle?.slice(0, 10)}..`
                  : liveItemData?.itemTitle || '상품명'}
              </div>
              <div className='flex items-center px-2 py-1 text-[2.4vw] bg-gray-100 text-gray-600 rounded-lg whitespace-nowrap'>
                선착순
              </div>
            </div>
            <div className='flex flex-col justify-end py-2 items-start h-full'>
              <div className='flex flex-row items-center'>
                <div className='mr-2 flex flex-row items-center text-[4vw] font-bold whitespace-nowrap'>
                  할인가
                  <span className='text-[4.8vw] text-[#EB4F47] ml-0.5'>
                    {commaNumber(dealPrice) || 0}
                  </span>
                  <span className='text-[#EB4F47]'>원</span>
                </div>
                <div className='relative text-[3vw] text-[#EB4F47] font-semibold whitespace-nowrap'>
                  {commaNumber(originalPrice - dealPrice) || 0}원 할인
                </div>
              </div>
              {(!disabledByStock || liveItemData?.stock > 0) && (
                <>
                  <div className='mb-4 text-[3.4vw] text-[#B1B3B5]'>
                    {`${
                      commaNumber(liveItemData?.totalOrderQuantity) || 0
                    }명 교환완료`}
                  </div>
                  <div className='flex flex-row items-center'>
                    <GaugeBar
                      currentExp={
                        liveItemData?.totalStock - liveItemData?.stock || 0
                      }
                      totalExp={liveItemData?.totalStock || 0}
                      checkIn
                    />
                    <div className='ml-2 text-[2.8vw] text-[#B1B3B5] font-semibold whitespace-nowrap'>
                      {commaNumber(liveItemData?.stock) || 0}개 남음
                    </div>
                  </div>
                </>
              )}
              {(disabledByStock || liveItemData?.stock <= 0) && (
                <div className='mt-4 text-[3.6vw] text-[#B1B3B5] whitespace-nowrap'>
                  오늘 선착순 수량이 마감되었어요
                </div>
              )}
            </div>
          </div>
        </div>
        <div
          className={
            disabledByPoint || disabledByStock || liveItemData?.stock <= 0
              ? 'w-full px-4 py-2 my-3 border rounded-xl bg-[#E0E0E0] text-[#9E9E9E] font-bold'
              : 'w-full px-4 py-2 my-3 border rounded-xl bg-[#EB4F47] text-white font-bold'
          }
        >
          {disabledByPoint && '포인트가 부족해요'}
          {(disabledByStock || liveItemData?.stock <= 0) &&
            !disabledByPoint &&
            `선착순 종료`}
          {!disabledByPoint &&
            !disabledByStock &&
            liveItemData?.stock > 0 &&
            `교환하기`}
        </div>
      </button>
    )
  }

  const LoadingIndicator = () => {
    return (
      <div>
        <div className='fixed inset-0 z-10 w-full h-full bg-gray-800 opacity-70' />
        <div style={{ left: '40%', top: '40%' }} className='fixed z-20'>
          <ClipLoader
            color='#ff3e3e'
            loading={isLoading}
            size={80}
            aria-label='Loading Spinner'
            data-testid='loader'
          />
        </div>
      </div>
    )
  }

  if (empty) {
    return (
      <div className='flex flex-row items-center justify-start flex-1 w-full p-2'>
        <div className='w-[30vw] h-[30vw] mr-5 border border-gray-300 bg-gray-100 rounded-lg' />
        <div className='w-[50vw] flex flex-col items-start justify-start'>
          <div className='mb-2 w-full h-[10vw] bg-gray-100' />
          <div className='flex flex-col w-full h-[15vw] items-start justify-start bg-gray-100' />
        </div>
      </div>
    )
  }

  return (
    <>
      <ItemComponent liveItemData={liveItemData} />
      {openConfirmModal && (
        <ConfirmAlert
          modalTitle='포인트 교환'
          modalContent={[
            `${commaNumber(
              selectedRewardInfo?.rewardAmount,
            )}포인트를 사용하여 교환하시겠어요?`,
            <br key='line-break1' />,
            <div className='mt-4 text-xs' key='liveItemData'>
              상품명:{' '}
              {selectedRewardInfo?.rewardDetail?.itemTitle.length > 24
                ? `${selectedRewardInfo?.rewardDetail?.itemTitle.slice(
                    0,
                    24,
                  )}...`
                : selectedRewardInfo?.rewardDetail?.itemTitle}{' '}
              <br />
              판매가:{' '}
              {commaNumber(
                selectedRewardInfo?.rewardDetail?.individualPurchasePrice,
              )}
              원
              <br />
              할인가:{' '}
              {commaNumber(selectedRewardInfo?.rewardDetail?.teamPurchasePrice)}
              P
              <br />
              유효기간 : 29일 ~ 30일 (상품별 상이)
              <br /> 발송방법: 올웨이즈에 가입한 휴대폰번호로 발송
            </div>,
          ]}
          buttonText='확인'
          buttonLink={async () => {
            if (buttonPressed) return
            buttonPressed = true
            setIsLoading(true)
            setOpenConfirmModal(false)
            const result = await backendApis.recordReceivedGifticon({
              ...selectedRewardInfo,
              gifticonType: 'speed',
            })
            if (result?.status === 200) {
              UserStore.setAccumulatedPoints(
                UserStore?.accumulatedPoints -
                  parseInt(selectedRewardInfo?.rewardAmount, 10),
              )
              setNoticeModalInfo({
                title: '교환 성공!',
                content:
                  '성공적으로 교환되었습니다. 2~3영업일 내로 최대한 빠르게 발송해드릴게요!',
                buttonText: '확인',
                onlyOneButton: true,
                onClick: () => {
                  setExchanged(true)
                  setOpenNoticeModal(false)
                  // scrollToTop()
                  setNoticeModalInfo({})
                },
              })
            } else if (result?.status === 201) {
              setNoticeModalInfo({
                title: '안내',
                content:
                  '고객 정보를 확인할 수 없어요. 고객센터에 문의해주세요.',
                onlyOneButton: true,
                onClick: () => {
                  setExchanged(true)
                  setOpenNoticeModal(false)
                  setNoticeModalInfo({})
                },
              })
            } else if (result?.status === 202) {
              setNoticeModalInfo({
                title: '안내',
                content:
                  '오늘 이미 상품을 교환하셨어요. \n 상품은 하루에 한번만 교환할 수 있어요',
                onlyOneButton: true,
                onClick: () => {
                  setExchanged(true)

                  setOpenNoticeModal(false)
                  setNoticeModalInfo({})
                },
              })
            } else if (result?.status === 203) {
              setNoticeModalInfo({
                title: '안내',
                content:
                  '기프티콘 교환 시간이 만료되었어요. 새로고침 후 다시 시도해주세요',
                onlyOneButton: true,
                onClick: () => {
                  navigate(-1)
                  setExchanged(true)

                  setOpenNoticeModal(false)
                  setNoticeModalInfo({})
                },
              })
            } else if (result?.status === 204) {
              setNoticeModalInfo({
                title: '안내',
                content:
                  '선착순 교환 수량이 마감되었어요. 내일 다시 시도해주세요',
                onlyOneButton: true,
                onClick: () => {
                  setExchanged(true)
                  setOpenNoticeModal(false)
                  setNoticeModalInfo({})
                },
              })
            } else {
              setNoticeModalInfo({
                title: '안내',
                content:
                  '인터넷 연결이 불안정하여 교환에 실패했어요. 다음에 다시 시도해 주세요',
                onlyOneButton: true,
                onClick: () => {
                  setExchanged(true)
                  setOpenNoticeModal(false)
                  setNoticeModalInfo({})
                },
              })
            }

            setOpenNoticeModal(true)

            setSelectedRewardInfo({})
            buttonPressed = false
            setIsLoading(false)
          }}
          showModal
          setShowModal={setOpenConfirmModal}
        />
      )}
      {openNoticeModal && (
        <ConfirmAlert
          modalTitle={noticeModalInfo?.title}
          modalContent={noticeModalInfo?.content}
          buttonText={noticeModalInfo?.buttonText || '확인'}
          buttonLink={noticeModalInfo?.onClick}
          showModal
          onlyOneButton={noticeModalInfo?.onlyOneButton}
          setShowModal={setOpenNoticeModal}
        />
      )}
      {isLoading && <LoadingIndicator />}
    </>
  )
}

export default speedGifticonItemComponent
