import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import useThrottle from '../../hooks/useThrottle'

const ImageButton = observer(
  ({ src, style, onClick, clickable, className, isPingOn, pingStyle }) => {
    const [isPressed, setIsPressed] = useState(false)
    const throttledOnClick = useThrottle()

    const handlePress = () => {
      if (!clickable) return
      throttledOnClick(onClick, 500)
    }

    const handleRelease = () => {
      if (!clickable) return
      setIsPressed(false)
    }

    return (
      <div
        style={style}
        className={`
        ${className} relative default flex-row justify-center items-center
        transition-all      
        ${isPressed ? 'transform scale-[0.85]' : ''}
        `}
        onPointerDown={() => {
          setIsPressed(true)
        }}
        onPointerUp={handlePress}
        onPointerCancel={handleRelease}
        onPointerLeave={handleRelease}
      >
        <img className='w-full h-full' alt='button' src={src} />
        {isPingOn && (
          <div
            className='default absolute w-[4.5vw] h-[4.5vw] top-[0vw] right-[0vw]'
            style={pingStyle}
          >
            <img alt='ping' src='../images/utility/exclamation.png' />
          </div>
        )}
      </div>
    )
  },
)

export default ImageButton
