import AuthStore from '../stores/AuthStore'
import fetchWrapper from './fetchWrapper'

const API_URI = process.env.REACT_APP_API_URI

const fetcher = async (url, token, method = 'GET', params = {}) => {
  const resource =
    method === 'GET' ? `${url}?${new URLSearchParams(params)}` : url
  const init = ['POST', 'PUT', 'DELETE'].includes(method)
    ? {
        body: JSON.stringify(params),
        headers: {},
      }
    : { headers: {} }
  init.method = method
  init.headers['Content-Type'] = 'application/json'
  init.headers['x-access-token'] = token
  try {
    const res = await fetch(API_URI + resource, init)
    const data = await res.json()
    return data
  } catch (err) {
    return null
  }
}

const tempToken = localStorage.getItem('@altoon@provider@token@')
class AltoonCMSBackendApis {
  async attemptLogin(params = {}) {
    const result = await fetcher(
      '/toons/altoon-cms-login',
      tempToken ?? AuthStore?.token,
      'POST',
      params,
    )
    return result
  }

  async changePassword(token, params = {}) {
    const result = await fetcher(
      '/toons/altoon-cms-change-passwords',
      token,
      'POST',
      params,
    )
    return result
  }

  async loadProviderInfo(token, params = {}) {
    const result = await fetcher(
      '/toons/altoon-cms-provider-info',
      token,
      'GET',
      params,
    )
    return result
  }

  async loadProviderList(token, params = {}) {
    const result = await fetcher(
      '/toons/altoon-cms-provider-list',
      token,
      'GET',
      params,
    )
    return result
  }

  async loadDashboardInfo(token, params = {}) {
    const result = await fetcher(
      '/toons/altoon-cms-dashboard-info',
      token,
      'GET',
      params,
    )
    return result
  }

  async getToonData(params = {}) {
    const result = await fetcher(
      '/toons/toon-data',
      tempToken ?? AuthStore?.token,
      'GET',
      params,
    )
    return result
  }

  async getToonDataByProviderId(params = {}) {
    const result = await fetcher(
      '/toons/toon-data-by-provider-id',
      tempToken ?? AuthStore?.token,

      'GET',
      params,
    )
    return result
  }

  async loadToonBanner(params = {}) {
    const result = await fetcher(
      '/toons/toon-banner',
      tempToken ?? AuthStore?.token,
      'GET',
      params,
    )
    return result
  }

  async loadToonEventPage(params = {}) {
    const result = await fetcher(
      '/toons/toon-event-page',
      tempToken ?? AuthStore?.token,
      'GET',
      params,
    )
    return result
  }

  async getChapterDataByToonId(params = {}) {
    const result = await fetcher(
      '/toons/chapter-data-by-id',
      tempToken ?? AuthStore?.token,
      'GET',
      params,
    )
    return result
  }

  async loadRecommendingToons(params = {}) {
    const result = await fetcher(
      '/toons/load-recommending-toons',
      tempToken ?? AuthStore?.token,
      'GET',
      params,
    )
    return result
  }

  async promoteToon(params = {}) {
    const result = await fetcher(
      '/toons/promote-toon',
      tempToken ?? AuthStore?.token,
      'POST',
      params,
    )
    return result
  }

  async dePromoteToon(params = {}) {
    const result = await fetcher(
      '/toons/depromote-toon',
      tempToken ?? AuthStore?.token,
      'POST',
      params,
    )
    return result
  }

  async updateToonInfo(params = {}) {
    const result = await fetcher(
      '/toons/update-toon-info',
      tempToken ?? AuthStore?.token,
      'POST',
      params,
    )
    return result
  }

  async updateChapterInfo(params = {}) {
    const result = await fetcher(
      '/toons/update-toon-chapter',
      tempToken ?? AuthStore?.token,
      'POST',
      params,
    )
    return result
  }

  async updateProviderInfo(params = {}) {
    const result = await fetcher(
      '/toons/update-toon-provider',
      tempToken ?? AuthStore?.token,
      'POST',
      params,
    )
    return result
  }

  async updateBannerInfo(params = {}) {
    const result = await fetcher(
      '/toons/update-toon-banner',
      tempToken ?? AuthStore?.token,
      'POST',
      params,
    )
    return result
  }

  async updatePromotionInfo(params = {}) {
    const result = await fetcher(
      '/toons/update-toon-promotion',
      tempToken ?? AuthStore?.token,
      'POST',
      params,
    )
    return result
  }

  async registerProvider(params = {}) {
    const result = await fetcher(
      '/toons/register-provider',
      tempToken ?? AuthStore?.token,
      'POST',
      params,
    )
    return result
  }

  async registerToon(params = {}) {
    const result = await fetcher(
      '/toons/register-toon',
      tempToken ?? AuthStore?.token,
      'POST',
      params,
    )
    return result
  }

  async registerToonChapter(params = {}) {
    const result = await fetcher(
      '/toons/register-toon-chapter',
      tempToken ?? AuthStore?.token,
      'POST',
      params,
    )
    return result
  }

  async registerBanner(params = {}) {
    const result = await fetcher(
      '/toons/register-toon-banner',
      tempToken ?? AuthStore?.token,
      'POST',
      params,
    )
    return result
  }

  async registerToonPromotion(params = {}) {
    const result = await fetcher(
      '/toons/register-toon-promotion',
      tempToken ?? AuthStore?.token,
      'POST',
      params,
    )
    return result
  }

  async addToonCoin(params = {}) {
    const result = await fetcher(
      '/toons/add-toon-coin',
      tempToken ?? AuthStore?.token,
      'POST',
      params,
    )
    return result
  }

  async toonComment(params = {}) {
    const result = await fetcher(
      '/toons/toon-comment',
      tempToken ?? AuthStore?.token,
      'GET',
      params,
    )
    return result
  }

  async deleteToonComment(params = {}) {
    const result = await fetcher(
      '/toons/delete-toon-comment',
      tempToken ?? AuthStore?.token,
      'PUT',
      params,
    )
    return result
  }

  async deleteToonPromotion(params = {}) {
    const result = await fetcher(
      '/toons/delete-toon-promotion',
      tempToken ?? AuthStore?.token,
      'PUT',
      params,
    )
    return result
  }
}

export default new AltoonCMSBackendApis()
