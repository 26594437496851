import commaNumber from 'comma-number'
import moment from 'moment'

const ExpireHistoryComponent = ({ data }) => {
  return (
    <div
      className='w-full flex flex-row justify-between items-center py-2'
      style={{ borderBottomWidth: 0.5 }}
    >
      <div className='py-4 flex flex-col w-4/6'>
        <div className='font-semibold text-lg'>
          {data?.month ? `${data?.month}월 ` : ''}포인트 소멸
        </div>
        <div className='pb-1 text-sm text-gray-600'>
          {moment(data?.updatedAt).format('YYYY.MM.DD')}
        </div>
      </div>
      <div className='w-2/6 text-lg font-semibold text-end'>
        {data?.actualExpiredPoints > 0 &&
          `- ${commaNumber(data?.actualExpiredPoints) || 0}P`}
        {!data?.actualExpiredPoints &&
          `- ${commaNumber(data?.expiredPoints) || 0}P`}
      </div>
    </div>
  )
}

export default ExpireHistoryComponent
