import React, { useEffect, useRef, useState } from 'react'
import { useInterval } from 'react-use'

import { random } from '../../utils/utility'

const Block = ({
  selected,
  setSelected,
  loc,
  handleMatchTen,
  resetTrigger,
  setCombo,
  adjacentList,
  makeTenTrigger,
  allResetTrigger,
  difficulty,
}) => {
  const [num, setNum] = useState(random(1, 8))

  const [clicked, setClicked] = useState(false)
  const [isPressed, setIsPressed] = useState(false)
  const [hide, setHide] = useState(false)
  const [effectOnly, setEffectOnly] = useState(false)

  useEffect(() => {
    // 10 넘었을때 clicked 초기화
    if (clicked && selected.length === 0) {
      setClicked(false)
    }
  }, [selected])

  const clickEffect = () => {
    setEffectOnly(true)
    setTimeout(() => {
      setEffectOnly(false)
    }, 100)
  }

  useEffect(() => {
    if (clicked || adjacentList.includes(loc)) {
      setIsPressed(true)
      setClicked(true)
      setTimeout(() => {
        setIsPressed(false)
        setClicked(false)
      }, 100)
      setHide(true)
    }
  }, [resetTrigger])

  useEffect(() => {
    if (makeTenTrigger > 0) {
      setNum(10)
      clickEffect()
    }
  }, [makeTenTrigger])

  useEffect(() => {
    if (allResetTrigger > 0) {
      setHide(false)
      setNum(random(1, 8))
      clickEffect()
    }
  }, [allResetTrigger])

  const handleRelease = () => {
    setIsPressed(false)
  }

  const handlePress = () => {
    if (hide) return
    setIsPressed(true)
    const data = `${loc},${num}`
    setClicked(true)
    // check loc is in
    if (selected.includes(data)) {
      setClicked(false)
      setSelected((prev) => {
        prev = prev.filter((item) => item !== data)
        return [...prev]
      })
      return
    }
    //
    const temp = [...selected, data]
    // selected의 데이터중 숫자의 합이 10 이상이면? select 비우기
    let sum = 0
    temp.forEach((item) => {
      const num = item.split(',')[2]
      sum += +num
    })
    if (sum > 10) {
      // empty selected
      setSelected([])
      setCombo(0)
    } else if (sum === 10) {
      handleMatchTen(data)
    } else {
      setSelected(temp)
    }
  }

  return (
    <div
      className={`
      element
      border-2
      duration-100 rounded-lg  border-none
      m-1 flex-1 aspect-square justify-center items-center flex  font-bold text-black
      ${isPressed ? 'scale-[80%]' : 'scale-100'}
      ${clicked && 'scale-110'}      
      ${hide && 'duration-300 bg-white opacity-0'}
      ${effectOnly && 'scale-110'}
      ${difficulty === 6 && 'text-[5.5vw]'}
      ${difficulty === 5 && 'text-[6vw]'}
      ${difficulty === 4 && 'text-[6.5vw]'}

      `}
      onPointerDown={handlePress}
      onPointerUp={handleRelease}
      onPointerCancel={handleRelease}
      onPointerLeave={handleRelease}
      style={{
        backgroundImage: `${
          hide
            ? ''
            : `url(../../images/applePang/${
                effectOnly || clicked ? 'selected' : 'normal'
              }.png)`
        }`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    >
      {hide ? '' : num}
    </div>
  )
}

export default Block
