import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import TreasureHuntAction from '../actions/TreasureHuntAction'
import RouteStore from '../stores/RouteStore'
import ToastStore from '../stores/ToastStore'
import THUserStore from '../stores/treasureHunt/THUserStore'
import backendApis from './backendApis'

const TreasureHuntAchievementController = observer(() => {
  useEffect(() => {
    if (THUserStore?.achievementList?.length === 0) {
      return
    }
    if (THUserStore?.dailyPoint >= 500) {
      TreasureHuntAction?.achievementHandler({ type: 'earn', level: 0 })
    }
    if (THUserStore?.dailyPoint >= 1000) {
      TreasureHuntAction?.achievementHandler({ type: 'earn', level: 1 })
    }
    if (THUserStore?.dailyPoint >= 1500) {
      TreasureHuntAction?.achievementHandler({ type: 'earn', level: 2 })
    }
  }, [THUserStore?.dailyPoint, THUserStore?.achievementList?.length])

  useEffect(() => {
    let dailyFindTreasureCount = window.localStorage.getItem(
      'dailyFindTreasureCount',
    )
    if (!dailyFindTreasureCount) {
      window.localStorage.setItem(
        'dailyFindTreasureCount',
        JSON.stringify({
          date: moment().format('YYYY-MM-DD'),
          count: 0,
        }),
      )
      dailyFindTreasureCount = {
        date: moment().format('YYYY-MM-DD'),
        count: 0,
      }
    } else {
      dailyFindTreasureCount = JSON.parse(dailyFindTreasureCount)
      if (dailyFindTreasureCount?.date !== moment().format('YYYY-MM-DD')) {
        window.localStorage.setItem(
          'dailyFindTreasureCount',
          JSON.stringify({
            date: moment().format('YYYY-MM-DD'),
            count: 0,
          }),
        )
        dailyFindTreasureCount = {
          date: moment().format('YYYY-MM-DD'),
          count: 0,
        }
      }
    }
    THUserStore?.set('dailyFindTreasureCount', dailyFindTreasureCount)
  }, [])
  useEffect(() => {
    if (THUserStore?.dailyFindTreasureCount?.count >= 20) {
      TreasureHuntAction?.achievementHandler({ type: 'search', level: 0 })
    }
    if (THUserStore?.dailyFindTreasureCount?.count >= 30) {
      TreasureHuntAction?.achievementHandler({ type: 'search', level: 1 })
    }
    if (THUserStore?.dailyFindTreasureCount?.count >= 40) {
      TreasureHuntAction?.achievementHandler({ type: 'search', level: 2 })
    }
  }, [THUserStore?.dailyFindTreasureCount?.count])

  useEffect(() => {
    let dailyMissionClearCount = window.localStorage.getItem(
      'dailyMissionClearCount',
    )
    console.log(dailyMissionClearCount)
    console.log(dailyMissionClearCount?.date)
    if (!dailyMissionClearCount) {
      window.localStorage.setItem(
        'dailyMissionClearCount',
        JSON.stringify({
          date: moment().format('YYYY-MM-DD'),
          count: 0,
        }),
      )
      dailyMissionClearCount = {
        date: moment().format('YYYY-MM-DD'),
        count: 0,
      }
    } else {
      dailyMissionClearCount = JSON.parse(dailyMissionClearCount)
      if (dailyMissionClearCount?.date !== moment().format('YYYY-MM-DD')) {
        window.localStorage.setItem(
          'dailyMissionClearCount',
          JSON.stringify({
            date: moment().format('YYYY-MM-DD'),
            count: 0,
          }),
        )
        dailyMissionClearCount = {
          date: moment().format('YYYY-MM-DD'),
          count: 0,
        }
      }
    }
    THUserStore?.set('dailyMissionClearCount', dailyMissionClearCount)
  }, [])
  useEffect(() => {
    console.log(
      'THUserStore?.dailyMissionClearCount',
      THUserStore?.dailyMissionClearCount.count,
    )
    if (THUserStore?.dailyMissionClearCount?.count >= 3) {
      TreasureHuntAction?.achievementHandler({ type: 'missionClear', level: 0 })
    }
    if (THUserStore?.dailyMissionClearCount?.count >= 5) {
      TreasureHuntAction?.achievementHandler({ type: 'missionClear', level: 1 })
    }
    if (THUserStore?.dailyMissionClearCount?.count >= 7) {
      TreasureHuntAction?.achievementHandler({ type: 'missionClear', level: 2 })
    }
  }, [THUserStore?.dailyMissionClearCount?.count])

  useEffect(() => {
    const fetchDailyLogCount = async () => {
      const result = await backendApis.fetchDailyLogCount()
      if (result?.status === 200) {
        if (result?.data?.dailyLogCount >= 3) {
          TreasureHuntAction?.achievementHandler({
            type: 'consecutiveAttendance',
            level: 0,
          })
        }
        if (result?.data?.dailyLogCount >= 5) {
          TreasureHuntAction?.achievementHandler({
            type: 'consecutiveAttendance',
            level: 1,
          })
        }
        if (result?.data?.dailyLogCount >= 7) {
          TreasureHuntAction?.achievementHandler({
            type: 'consecutiveAttendance',
            level: 2,
          })
        }
      } else {
        ToastStore.networkError()
      }
    }
    fetchDailyLogCount()
  }, [])

  useEffect(() => {
    if (THUserStore?.achievement?.lunchFairyFoundCount >= 10) {
      TreasureHuntAction?.achievementHandler({ type: 'lunchFairy', level: 0 })
    }
    if (THUserStore?.achievement?.lunchFairyFoundCount >= 30) {
      TreasureHuntAction?.achievementHandler({ type: 'lunchFairy', level: 1 })
    }
    if (THUserStore?.achievement?.lunchFairyFoundCount >= 100) {
      TreasureHuntAction?.achievementHandler({ type: 'lunchFairy', level: 2 })
    }
  }, [THUserStore?.achievement?.lunchFairyFoundCount])

  useEffect(() => {
    if (THUserStore?.friends?.length >= 1) {
      TreasureHuntAction?.achievementHandler({ type: 'friend', level: 0 })
    }
    if (THUserStore?.friends?.length >= 3) {
      TreasureHuntAction?.achievementHandler({ type: 'friend', level: 1 })
    }
    if (THUserStore?.friends?.length >= 5) {
      TreasureHuntAction?.achievementHandler({ type: 'friend', level: 2 })
    }
  }, [THUserStore?.friends?.length])

  useEffect(() => {
    if (THUserStore?.achievement?.specialAnimalFoundCount >= 1) {
      TreasureHuntAction?.achievementHandler({
        type: 'specialAnimal',
        level: 0,
      })
    }
    if (THUserStore?.achievement?.specialAnimalFoundCount >= 3) {
      TreasureHuntAction?.achievementHandler({
        type: 'specialAnimal',
        level: 1,
      })
    }
    if (THUserStore?.achievement?.specialAnimalFoundCount >= 5) {
      TreasureHuntAction?.achievementHandler({
        type: 'specialAnimal',
        level: 2,
      })
    }
  }, [THUserStore?.achievement?.specialAnimalFoundCount])

  useEffect(() => {
    if (THUserStore?.achievement?.scartchPurchaseCount >= 100) {
      TreasureHuntAction?.achievementHandler({
        type: 'scratch',
        level: 0,
      })
    }
    if (THUserStore?.achievement?.scartchPurchaseCount >= 1000) {
      TreasureHuntAction?.achievementHandler({
        type: 'scratch',
        level: 1,
      })
    }
    if (THUserStore?.achievement?.scartchPurchaseCount >= 5000) {
      TreasureHuntAction?.achievementHandler({
        type: 'scratch',
        level: 2,
      })
    }
  }, [THUserStore?.achievement?.scartchPurchaseCount])

  useEffect(() => {
    if (THUserStore?.achievement?.searchCumulativeCount >= 100) {
      TreasureHuntAction?.achievementHandler({
        type: 'searchCumulative',
        level: 0,
      })
    }
    if (THUserStore?.achievement?.searchCumulativeCount >= 1000) {
      TreasureHuntAction?.achievementHandler({
        type: 'searchCumulative',
        level: 1,
      })
    }
    if (THUserStore?.achievement?.searchCumulativeCount >= 5000) {
      TreasureHuntAction?.achievementHandler({
        type: 'searchCumulative',
        level: 2,
      })
    }
  }, [THUserStore?.achievement?.searchCumulativeCount])

  return <></>
})

export default TreasureHuntAchievementController
