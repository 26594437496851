/* eslint-disable no-nested-ternary */
import React from 'react'
import { FiChevronRight } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'

import AltoonUserStore from '../../stores/AltoonUserStore'
import ToastStore from '../../stores/ToastStore'
import UserStore from '../../stores/UserStore'
import AB from '../../utils/ab'
import backendApis from '../../utils/backendApis'
import resize from '../../utils/resize'
import AlwayzImage from '../atoms/AlwayzImage'
import AltoonTagComponent from './AltoonTagComponent'

const MainToonComponent = ({
  recentlyViewedToons = [],
  toon = {},
  index = {},
  type = 'main',
  isToday = false,
}) => {
  const navigate = useNavigate()
  const onClickHandler = async () => {
    if (toon?.isAdultOnly) {
      if (AltoonUserStore.userData?.isAdult === false) {
        alert('성인 작품입니다.')
        return
      }
      if (AltoonUserStore.userData?.isAdult === true) {
        alert('기존 인증 내역으로 성인 인증이 완료되었습니다.')
        // ToastStore.toastOn({
        //   type: 'checkemoji',
        //   message: `기존 인증 내역으로 성인 인증이 완료되었습니다`,
        //   duration: 1500,
        //   emoji: '✔️',
        // })
        navigate(`/altoon-detail?toonId=${toon?._id}`)
      }
      if (!AltoonUserStore.userData?.isAdult) {
        const authInfo = await backendApis.loadAuthInfo()
        if (authInfo?.status === 200) {
          const userData = await backendApis.loadUserData()
          AltoonUserStore.set('userData.isAdult', userData?.data?.isAdult)
          if (userData?.data?.isAdult === false) {
            alert('성인 작품입니다.')
            return
          }
          if (userData?.data?.isAdult === true) {
            alert('기존 인증 내역으로 성인 인증이 완료되었습니다.')
            navigate(`/altoon-detail?toonId=${toon?._id}`)
          }
        } else if (authInfo?.status === 201) {
          alert('성인 인증이 필요한 작품입니다.')
          window.location.href = `#navigate.${JSON.stringify({
            screen: 'SelfAuthenticationScreen',
            prop: {
              onSuccess: async () => {
                navigate(-1)
              },
              benefitType: 'toon',
              defaultSuccessHandler: true,
            },
          })}`
        }
      }
      return
    }
    if (type === 'onboarding') {
      const nextChapter = await backendApis.loadChapterByEpisode(toon?._id, 0)
      if (nextChapter?.status === 200) {
        navigate(`/altoon-reader`, {
          state: {
            chapterInfo: nextChapter?.data,
            toonInfo: toon,
            episode: 1,
            rentPeriod: 3,
          },
          replace: true,
        })
      } else if (nextChapter?.status === 204) {
        ToastStore.networkError()
        backendApis.logToonError(
          'mainToonComponent',
          'loadChapterByEpisode',
          nextChapter?.status,
        )
      }
    } else {
      navigate(`/altoon-detail?toonId=${toon?._id}`)
    }
  }

  if (type === 'horizontal') {
    return (
      <button
        type='button'
        className='mr-1 relative'
        key={toon?._id || index}
        onClick={() => {
          onClickHandler()
        }}
      >
        <AlwayzImage
          src={resize(toon?.thumbnail, 236, 236, 'fill')}
          alt={toon?.title}
          lazy
          className='w-[21vw] h-[28vw] rounded-lg border border-[#F5F5F5] min-w-[21vw] min-h-[28vw]'
        />
        {toon?.isAdultOnly && (
          <img
            src='/images/toon/isAdultIcon.png'
            alt='ticket'
            className='absolute top-[1vw] right-[1vw] w-[3.6vw] h-[3.6vw]'
          />
        )}
        <div className='py-1'>
          <h3 className='font-bold text-start text-[2.8vw] mb-1'>
            {toon?.title?.length > 8
              ? `${toon?.title.slice(0, 8)}...`
              : `${toon?.title}`}
          </h3>
        </div>
      </button>
    )
  }

  if (type === 'trending') {
    return (
      <>
        <button
          type='button'
          className='px-[6vw] relative'
          key={toon?._id || index}
          onClick={() => {
            onClickHandler()
          }}
        >
          <AlwayzImage
            lazy
            src={resize(toon?.thumbnail, 254, 340, 'fill')}
            alt={toon?.title}
            className='w-[30vw] h-[40vw] rounded-lg border border-[#F5F5F5] min-w-[30vw] min-h-[40vw] z-10'
          />
          <div
            className={`${
              index === 9 ? 'text-[18vw] -left-[6vw]' : 'text-[20vw] left-0'
            }  justify-start items-end flexRow font-bold text-gray-600 opacity-60 absolute bottom-0 w-[21vw] z-0`}
          >
            {index + 1}
          </div>
          {toon?.isAdultOnly && (
            <img
              src='/images/toon/isAdultIcon.png'
              alt='ticket'
              className='absolute top-[1vw] right-[7vw] w-[3.6vw] h-[3.6vw]'
            />
          )}
          <div className='py-1'>
            <h3 className='font-bold text-center text-[3.4vw] mb-1'>
              {toon?.title?.length > 8
                ? `${toon?.title.slice(0, 8)}...`
                : `${toon?.title}`}
            </h3>
          </div>
        </button>
      </>
    )
  }

  if (type === 'eventPage') {
    return (
      <button
        type='button'
        key={toon?._id || index}
        onClick={async () => {
          onClickHandler()
        }}
        className='flex flex-row w-full h-full'
      >
        <div className='relative w-[44vw]'>
          <img
            src={toon?.thumbnail}
            alt={toon?.title}
            className='w-[40vw] h-[40vw] rounded-lg border border-[#F5F5F5]'
          />
          {toon?.isAdultOnly && (
            <img
              src='/images/toon/isAdultIcon.png'
              alt='ticket'
              className='absolute top-[1vw] right-[1vw] w-[5vw] h-[5vw]'
            />
          )}
        </div>
        <div className='w-full h-full ml-2 mb-1 pt-2 text-start text-[4vw]'>
          <div className='h-full flex flex-col justify-between'>
            <div>
              <h3 className='font-bold'>{toon?.title}</h3>
              <div className='pt-1 pb-2 flex flex-row text-[2.8vw] justify-start text-start'>
                <p>{toon?.author}</p>
                <p className='flex flex-row items-center justify-end pl-2'>
                  <img
                    alt=''
                    style={{
                      marginRight: 2,
                      width: '3vw',
                      height: '3vw',
                    }}
                    src='/images/toon/star.png'
                  />
                  {Math.round((toon?.ratingScore / toon?.ratingCount) * 100) /
                    100 || 0}
                </p>
              </div>
              <div className='text-[2.8vw] justify-start text-gray-600 text-start'>
                {toon?.isAdultOnly
                  ? '본인인증이 필요한 작품입니다.'
                  : toon?.description?.length > 84
                  ? `${toon?.description?.slice(0, 84)}..`
                  : toon?.description}
              </div>
            </div>
            {type !== 'onboarding' && (
              <div className='flex flex-row mt-2 text-[2.8vw]'>
                {toon?.tagList?.hot === true && (
                  <AltoonTagComponent key={Math.random()} tag='hot' />
                )}

                {toon?.tagList?.complete === true && (
                  <AltoonTagComponent key={Math.random()} tag='complete' />
                )}

                {toon?.tagList?.new && (
                  <AltoonTagComponent key={Math.random()} tag='new' />
                )}
                {toon?.tagList?.mainGenre?.slice(0, 2)?.map((tag) => {
                  return <AltoonTagComponent key={Math.random()} tag={tag} />
                })}
              </div>
            )}
          </div>
        </div>
      </button>
    )
  }

  return (
    <button
      type='button'
      key={toon?._id || index}
      onClick={async () => {
        onClickHandler()
      }}
      className='flex flex-row mb-4 w-full h-[40vw]'
    >
      <div className='relative w-[44vw]'>
        <AlwayzImage
          src={resize(toon?.thumbnail, 248, 340, 'fill')}
          alt={toon?.title}
          className='w-[29vw] h-[40vw] rounded-lg border border-[#F5F5F5]'
          lazy
        />
        {isToday && (
          <img
            src='/images/toon/upIcon.png'
            alt='ticket'
            className='absolute top-[1vw] left-[1vw] w-[5vw] h-[3vw]'
          />
        )}
        {toon?.isAdultOnly && (
          <img
            src='/images/toon/isAdultIcon.png'
            alt='ticket'
            className='absolute top-[1vw] right-[1vw] w-[5vw] h-[5vw]'
          />
        )}
      </div>
      <div className='w-full h-full ml-2 mb-1 pt-2 text-start text-[4vw]'>
        <div className='h-full flex flex-col justify-between'>
          <div>
            <h3 className='font-bold'>{toon?.title}</h3>
            <div className='pt-1 pb-2 flex flex-row text-[2.8vw] justify-start text-start'>
              <p>{toon?.author}</p>
              {type !== 'onboarding' && (
                <p className='flex flex-row items-center justify-end pl-2'>
                  <img
                    alt=''
                    style={{
                      marginRight: 2,
                      width: '3vw',
                      height: '3vw',
                    }}
                    src='/images/toon/star.png'
                  />
                  {Math.round((toon?.ratingScore / toon?.ratingCount) * 100) /
                    100 || 0}
                </p>
              )}
            </div>
            <div className='text-[2.8vw] justify-start text-gray-600 text-start'>
              {toon?.isAdultOnly
                ? '본인인증이 필요한 작품입니다.'
                : toon?.description?.length > 84
                ? `${toon?.description?.slice(0, 84)}..`
                : toon?.description}
            </div>
          </div>
          {type !== 'onboarding' && (
            <div className='flex flex-row mt-2 text-[2.8vw]'>
              {toon?.tagList?.hot === true && (
                <AltoonTagComponent key={Math.random()} tag='hot' />
              )}

              {toon?.tagList?.complete === true && (
                <AltoonTagComponent key={Math.random()} tag='complete' />
              )}

              {toon?.tagList?.new && (
                <AltoonTagComponent key={Math.random()} tag='new' />
              )}
              {toon?.tagList?.mainGenre?.slice(0, 2)?.map((tag) => {
                return <AltoonTagComponent key={Math.random()} tag={tag} />
              })}
            </div>
          )}
          {type === 'onboarding' && (
            <div className='flex flex-row items-center justify-center py-1 text-[3vw] bg-red-500 text-white font-semibold rounded'>
              보러가기
              <FiChevronRight className='inline-block ml-1' />
            </div>
          )}
        </div>
      </div>
    </button>
  )
}
export default MainToonComponent
