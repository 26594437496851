const moment = require('moment')

const members = {
  '643df625a7a3e326096aece6_selectGifticon0904': 'b',
  '646c2e9a96e55318d5a0b0ae_selectGifticon0904': 'b',
}
const map = 'abcdefghij'
const AB = (id = '', key = 'test0000', g = [50]) => {
  if (!id) return 'a'
  if (`${id}_${key}` in members) {
    return members[`${id}_${key}`]
  }
  const a = parseInt(id.toString().slice(-8), 36)
  const b = parseInt(key.slice(-8), 36)
  const code = (((a / 2147483647) * b) % 2147483647) % 100
  let r = ''
  g.forEach((each, i) => {
    if (code < each && !r) r = map[i]
  })

  if (!r) r = map[g.length]

  return r
}

const noise = (min = 5, max = 10) => {
  const flag = Math.random()
  let noise = 1
  if (flag > 0.5) {
    noise = -1
  }
  return (Math.random() * (max - min) + min) * noise
}

const random = (min, max) => Math.floor(Math.random() * (max - min) + min)

const sleep = (ms) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms)
  })

const checkAvailabilty = ({ lastClearedAt }) => {
  const nextAvailableTime = moment(lastClearedAt)
    .add(17, 'hours')
    .set({ hour: 7, minute: 0, second: 0, millisecond: 0 })

  const now = moment()
  if (now.isAfter(nextAvailableTime)) {
    return { available: true }
  }

  return { available: false }
}

const NaNPrevent = (v) => {
  return Number?.isNaN(v) ? 0 : Number(v)
}

const coolTimeCheck = (lastClearedAt, coolTime) => {
  if (!coolTime) {
    const lastClearedTime = moment(lastClearedAt ?? '2023-01-01').format(
      'YYYY-MM-DD',
    )
    const now = moment().format('YYYY-MM-DD')
    return lastClearedTime !== now
  }

  const lastClearedTime = moment(lastClearedAt ?? '2023-01-01').add(
    coolTime,
    'minutes',
  )

  const now = moment()
  return now.isSameOrAfter(lastClearedTime)
}

const isNewUser = (createdAt) => {
  const now = moment()
  const created = moment(createdAt ?? '2099-01-01').add(1, 'days')
  return now.isSameOrBefore(created)
}

const isEffective = (expiryTime) => {
  const now = moment()
  const expiry = moment(expiryTime ?? '2023-01-01')
  return now.isBefore(expiry)
}

const pickRandomOneArray = (arr) => {
  const randomIndex = random(0, arr.length)
  return arr[randomIndex]
}

const shortenText = (text, length = 5) => {
  if (text.length > length) {
    return `${text.slice(0, length)}..`
  }
  return text
}

const translateVariable = (variable) => {
  if (variable === 'point') {
    return '캐시'
  }
  if (variable === 'energy') {
    return '번개'
  }
  if (variable === 'gift') {
    return '선물상자'
  }
  if (variable === 'starboxTierOne') {
    return '클로버'
  }
  if (variable === 'starboxTierTwo') {
    return '황금클로버'
  }
  if (variable === 'ticket') {
    return '일반 도전권'
  }
  // if (variable === 'fixedTicket') {
  //   return '100% 도전권'
  // }
  if (variable === 'arcadeTicket') {
    return '아케이드 티켓'
  }
}
const translateUnitVariable = (variable) => {
  if (variable === 'point') {
    return '원'
  }
  if (variable === 'energy') {
    return '개'
  }
  if (variable === 'gift') {
    return '원'
  }
  if (variable === 'starboxTierOne') {
    return '개'
  }
  if (variable === 'starboxTierTwo') {
    return '개'
  }
  if (
    variable === 'ticket' ||
    // variable === 'fixedTicket' ||
    variable === 'arcadeTicket'
  ) {
    return '개'
  }
  return '개'
}

function codepushVersionCheck(currentVersion, targetVersion) {
  // if (!currentVersion || !targetVersion) return false

  // 각 버전을 점(.)으로 분할하여 숫자 배열로 변환합니다.
  const currentParts = currentVersion?.split('.')?.map(Number)
  const targetParts = targetVersion?.split('.')?.map(Number)

  // 세 개의 부분(주, 부, 패치) 각각에 대해 비교합니다.
  for (let i = 0; i < 3; i += 1) {
    if (currentParts?.[i] > targetParts?.[i]) {
      return true
    }
    if (currentParts?.[i] < targetParts?.[i]) {
      return false
    }
  }

  // 모든 부분이 동일한 경우 currentVersion은 targetVersion과 동일하므로 true를 반환합니다.
  return true
}

const utility = {
  noise,
  random,
  sleep,
  checkAvailabilty,
  NaNPrevent,
  coolTimeCheck,
  isNewUser,
  isEffective,
  pickRandomOneArray,
  shortenText,
  translateVariable,
  translateUnitVariable,
  AB,
  codepushVersionCheck,
}

module.exports = utility
