import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import { useSearchParam } from 'react-use'

import ToonModal from '../components/templates/ToonModal'
import BannerSwiperComponent from '../components/toon/bannerSwiperComponent'
import MainToonComponent from '../components/toon/mainToonComponent'
import { ExclamationMarkIcon } from '../images/dailyCheckIn'
import AltoonUserStore from '../stores/AltoonUserStore'
import AuthStore from '../stores/AuthStore'
import UserStore from '../stores/UserStore'
import THModalStore from '../stores/treasureHunt/THModalStore'
import AB from '../utils/ab'
import backendApis from '../utils/backendApis'

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

const AltoonMainPage = observer(() => {
  const toonListRef = useRef(null)
  const scrollContainerRef = useRef(null)

  const navigate = useNavigate()
  const token = useSearchParam('token')
  const code = useSearchParam('code')
  const alfarmMissionToonId = useSearchParam('missionToonId')

  let isCancelled = false

  const [isLoading, setIsLoading] = useState(false)
  const [toons, setToons] = useState([])
  const [bannerData, setBannerData] = useState()
  const [trendingToons, setTrendingToons] = useState([])
  const [recentlyViewedToons, setRecentlyViewedToons] = useState([])
  const [selectedTab, setSelectedTab] = useState(
    window.localStorage.getItem('toon_default_tab') || 'daily',
  )
  const [todayWeekday, setTodayWeekday] = useState(
    sessionStorage.getItem('toon_default_weekday') || moment().format('ddd'),
  )

  useEffect(() => {
    const fetchToonData = async () => {
      // 유저정보 가져오기
      const userData = await backendApis.loadUserData()
      if (userData?.status === 200) {
        AltoonUserStore.set('userData', userData?.data)
        if (userData.data?.dailyMission?.appPushInfo) {
          AltoonUserStore.set(
            'dailyMission.appPushInfo',
            userData.data.dailyMission.appPushInfo,
          )
        }
        if (userData.data?.dailyMission?.firstPurchase) {
          AltoonUserStore.set(
            'dailyMission.firstPurchase',
            userData.data.dailyMission.firstPurchase,
          )
        }
        if (code === 'alfarmMission') {
          // 올팜 물미션으로 온 친구들
          await sleep(500)
          navigate(`/altoon-farm-landing?token=${token}`)
        } else if (code === 'alfarmFertMission') {
          // 올팜 비료미션으로 온 친구들
          await sleep(500)
          navigate(`/altoon-detail?toonId=${alfarmMissionToonId}`)
        } else if (userData?.isNewUser === true) {
          navigate(`/altoon-farm-landing?token=${token}&fromFarm=false`)
          // } else if (userData?.data?.testingGroupInfo?.toon0217 === true) {
          //   navigate(`/altoon-main-testgroup?token=${token}`)
        }
        const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

        const showModalSequentially = async (initModalList) => {
          for (const init of initModalList) {
            await delay(800)
            if (isCancelled) break
            THModalStore.setType(init)
          }
          if (!isCancelled) {
            await backendApis.setToonInitModal({ initModalList: [] })
          }
        }

        if (userData?.data?.initModalList) {
          showModalSequentially(userData.data.initModalList)
        }
      }

      // 배너 정보 가져오기
      const bannerData = await backendApis.loadBannerData()
      if (bannerData?.status === 200) {
        if (userData?.data?.dailyMission?.firstPurchase) {
          setBannerData(
            bannerData?.data?.filter(
              (banner) =>
                banner?.itemId?.toString() !== '65e0160e3401440ed9cecde3',
            ),
          )
        } else {
          setBannerData(bannerData?.data)
        }
      }

      // 작품 정보 가져오기
      const toonResult = await backendApis.loadAllToons()
      if (toonResult?.status === 200) {
        // deletedAt이 없는 모든 작품 가져와서 isAdmin인 작품을 거름
        setToons(toonResult.data.filter((toon) => !toon.isAdmin))
        if (AltoonUserStore.userData.isAdmin === true) {
          // 어드민 계정이면 맨 뒤에 어드민 작품을 붙여줌
          setToons([
            ...toonResult.data.filter((toon) => !toon.isAdmin),
            ...toonResult.data.filter((toon) => toon.isAdmin),
          ])
        }

        // 인기순 데이터 가져오기
        const trendingToonLog = await backendApis.loadTrendingToons()

        if (trendingToonLog?.status === 200) {
          const toonMap = new Map(
            toonResult.data.map((toon) => [toon._id, toon]),
          )

          const trendingToonList = trendingToonLog.data.map((trending) => {
            const toonDetails = toonMap.get(trending.toonId)

            return {
              ...trending, // 트렌딩 만화의 기존 데이터
              toonDetails, // 매핑된 만화의 상세 정보
            }
          })

          setTrendingToons(trendingToonList)
        }

        await backendApis.logToonAction('AltoonMainPage', 'enteredScreen', '')
      }

      // 최근 조회 데이터 가져오기
      const recentlyViewedDataResult =
        await backendApis.loadRecentlyViewedData()
      const recentlyViewedToonsData =
        await backendApis.loadRecentlyViewedToons()
      if (
        recentlyViewedDataResult?.status === 200 &&
        recentlyViewedToonsData?.status === 200
      ) {
        const mergedData = recentlyViewedToonsData.data.map((toon) => {
          const matchedData = recentlyViewedDataResult.data.find(
            (data) => data.toonId === toon._id,
          )

          return {
            ...toon,
            lastViewedAt: matchedData?.lastViewedAt,
          }
        })

        const sortedData = mergedData.sort((a, b) => {
          const aTime = new Date(a.lastViewedAt).getTime()
          const bTime = new Date(b.lastViewedAt).getTime()

          return bTime - aTime
        })

        setRecentlyViewedToons(sortedData)
      }

      const savedPosition = sessionStorage.getItem('mainPage_scrollPosition')
      if (savedPosition) {
        setTimeout(() => {
          // scrollContainerRef.current가 존재하는지 확인
          if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTop = parseInt(savedPosition, 10)
          }
        }, 100)
      }
    }
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'allowiPhoneGesture',
        boolean: true,
      }),
    )
    fetchToonData()

    return () => {
      isCancelled = true // 컴포넌트 언마운트 또는 useEffect 재실행 시 현재 진행 중인 작업 취소
    }
  }, [])

  useEffect(() => {
    setIsLoading(true)
    AuthStore.setToken(token)
    setIsLoading(false)
  }, [])

  useEffect(() => {
    const handleScroll = (e) => {
      // 스크롤 위치 기억하는 용도
      sessionStorage.setItem('mainPage_scrollPosition', e.target.scrollTop)
    }

    const scrollContainer = scrollContainerRef.current
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  const HeaderComponent = () => {
    return (
      <section className='fixed jua z-10 py-2 flex flex-row items-center justify-between overflow-hidden'>
        <button
          aria-label='goBack'
          type='button'
          className='mx-2 p-2 whitespace-nowrap bg-[#a3a3a32c] rounded-full shadow-sm'
          onClick={async () => {
            window.location.href = '#goBack'
          }}
        >
          <FiChevronLeft className='w-8 h-8' />
        </button>
      </section>
    )
  }

  const RecentlyViewedHorizontalList = () => {
    if (recentlyViewedToons?.length > 0) {
      return (
        <div className='pl-4 py-2'>
          <button
            type='button'
            className='w-full flex flex-row justify-between items-center text-[4.2vw] pb-2'
            onClick={() => {
              navigate(`/altoon-mypage?token=${token}`)
            }}
          >
            <div className='flex flex-row items-center'>
              <img
                src='/images/toon/recentIcon.png'
                alt=''
                className='w-6 h-6 mr-1'
              />
              {UserStore?.userInfo?.userName &&
              UserStore?.userInfo?.userName?.length > 8
                ? `${UserStore?.userInfo?.userName?.slice(0, 8)}..`
                : UserStore?.userInfo?.userName}
              {!UserStore?.userInfo?.userName && '고객'}
              님이 <div className='font-semibold ml-1'>최근</div>에 감상한 작품
            </div>
            <FiChevronRight className='w-5 h-5 mr-2' />
          </button>

          <div className='flex items-center overflow-x-scroll whitespace-nowrap'>
            {recentlyViewedToons.slice(0, 6).map((toon, index) => (
              <MainToonComponent
                key={toon?._id}
                toon={toon}
                index={index}
                type='horizontal'
              />
            ))}
            <button
              type='button'
              className='mx-4 text-[3vw]'
              onClick={() => {
                navigate(`/altoon-mypage?token=${token}`)
              }}
            >
              <div className='w-[10vw] h-[10vw] min-w-[10vw] min-h-[10vw] rounded-full border border-[#F5F5F5] flexCol items-center mb-2'>
                <FiChevronRight className='w-[5vw] h-[5vw]' />
              </div>
              <div>전체보기</div>
            </button>
          </div>
        </div>
      )
    }
  }

  const TrendingToonList = () => {
    if (trendingToons?.length > 0) {
      return (
        <div className='pl-4 py-2'>
          <div className='w-full flex flex-row justify-between items-center text-[4.2vw] font-semibold pb-2'>
            오늘 인기 TOP 10
          </div>

          <div className='flex items-center overflow-x-scroll whitespace-nowrap'>
            {trendingToons.slice(0, 10).map((toon, index) => (
              <MainToonComponent
                key={toon?.toonId}
                toon={toon?.toonDetails}
                index={index}
                type='trending'
              />
            ))}
          </div>
        </div>
      )
    }
  }

  const ToonList = forwardRef(() => {
    const [borderStyle, setBorderStyle] = useState({})
    const recommendRef = useRef(null)
    const dailyRef = useRef(null)

    useEffect(() => {
      let activeRef
      switch (selectedTab) {
        case 'recommend':
          activeRef = recommendRef
          break
        case 'daily':
          activeRef = dailyRef
          break
        default:
          break
      }

      if (activeRef && activeRef.current) {
        const rect = activeRef.current.getBoundingClientRect()
        setBorderStyle({
          width: rect.width,
          left: rect.left,
        })
      }
    }, [selectedTab])

    const getButtonStyle = (tab) => {
      if (tab === selectedTab) {
        return 'px-4 font-bold text-[#FF5951] relative' // 선택된 장르일 때의 스타일
      }
      return 'px-4 text-gray-500 relative' // 기본 스타일
    }

    return (
      <div className='flex flex-col items-center'>
        <div className='w-full flex flex-row justify-around px-4 py-2 text-[4vw] border-b border-[#F3F3F3] relative overflow-y-hidden overflow-scroll whitespace-nowrap'>
          <div
            style={borderStyle}
            className='absolute -bottom-[0.2vh] h-[0.6vh] bg-[#FF5951] transition-all duration-300'
          />
          <button
            ref={dailyRef}
            type='button'
            className={getButtonStyle('daily')}
            onClick={() => {
              window.localStorage.setItem('toon_default_tab', 'daily')
              setSelectedTab('daily')
            }}
          >
            요일
          </button>
          <button
            ref={recommendRef}
            type='button'
            className={getButtonStyle('recommend')}
            onClick={() => {
              window.localStorage.setItem('toon_default_tab', 'recommend')
              setSelectedTab('recommend')
            }}
          >
            <img
              alt='newBaloon'
              src='/images/toon/newBalloon.png'
              className={
                selectedTab === 'recommend'
                  ? 'w-[8vw] h-[8vw] mr-1 absolute -right-[6vw] -top-[2vw]'
                  : 'w-[8vw] h-[8vw] mr-1 absolute -right-[6vw] -top-[2vw] animate-wiggle'
              }
            />
            완결작
          </button>
        </div>
      </div>
    )
  })

  const ToonListByGenre = forwardRef((props, ref) => {
    const [selectedGenre, setSelectedGenre] = useState('all')
    const [borderStyle, setBorderStyle] = useState({})

    const allRef = useRef(null)
    const romanceRef = useRef(null)
    const comedyRef = useRef(null)
    const instatoonRef = useRef(null)
    const actionRef = useRef(null)

    useEffect(() => {
      let activeRef
      switch (selectedGenre) {
        case 'all':
          activeRef = allRef
          break
        case 'romance':
          activeRef = romanceRef
          break
        case 'comedy/daily':
          activeRef = comedyRef
          break
        case 'instatoon':
          activeRef = instatoonRef
          break
        case 'drama':
          activeRef = actionRef
          break
        default:
          break
      }

      if (activeRef && activeRef.current) {
        const rect = activeRef.current.getBoundingClientRect()
        setBorderStyle({
          width: rect.width,
          left: rect.left,
        })
      }
    }, [selectedGenre])

    const genreFilters = {
      all: () => toons.filter((toon) => !toon.weekday),
      'comedy/daily': () =>
        toons.filter(
          (toon) => toon.genre === 'comedy' || toon.genre === 'daily',
        ),
      defaultFilter: () => toons.filter((toon) => toon.genre === selectedGenre),
    }

    const filterFunction =
      genreFilters[selectedGenre] || genreFilters.defaultFilter
    const filteredToons = filterFunction()

    const getButtonStyle = (genre) => {
      if (genre === selectedGenre) {
        return 'px-2 font-bold' // 선택된 장르일 때의 스타일
      }
      return 'px-2 text-gray-500' // 기본 스타일
    }

    return (
      <div className='flex flex-col items-center mb-[12vh]'>
        {/* <div className='w-full flex flex-row justify-between px-2 py-2 mb-4 text-[3.2vw] border-b border-[#F3F3F3] relative overflow-y-hidden overflow-scroll whitespace-nowrap'>
          <div
            style={borderStyle}
            className='absolute -bottom-[0.2vh] h-[0.6vh] bg-[#FF5951] transition-all duration-300'
          />

          <button
            ref={allRef}
            type='button'
            className={getButtonStyle('all')}
            onClick={() => setSelectedGenre('all')}
          >
            전체
          </button>
          <button
            ref={romanceRef}
            type='button'
            className={getButtonStyle('romance')}
            onClick={() => setSelectedGenre('romance')}
          >
            로맨스
          </button>
          <button
            ref={comedyRef}
            type='button'
            className={getButtonStyle('comedy/daily')}
            onClick={() => setSelectedGenre('comedy/daily')}
          >
            일상/코미디
          </button>
          <button
            ref={instatoonRef}
            type='button'
            className={getButtonStyle('instatoon')}
            onClick={() => setSelectedGenre('instatoon')}
          >
            인스타툰
          </button>
          <button
            ref={actionRef}
            type='button'
            className={getButtonStyle('drama')}
            onClick={() => setSelectedGenre('drama')}
          >
            드라마
          </button>
        </div> */}

        <div ref={ref} className='px-4 pt-4'>
          {filteredToons?.map((toon, index) => (
            <MainToonComponent
              recentlyViewedToons={recentlyViewedToons}
              key={toon?._id}
              toon={toon}
              index={index}
            />
          ))}
        </div>
      </div>
    )
  })

  const ToonListByWeekday = forwardRef((props, ref) => {
    const getButtonStyle = (weekday) => {
      if (weekday === todayWeekday) {
        return 'px-[3.6vw] py-[0.4vh] font-bold text-white bg-[#FF5951] rounded-full' // 선택된 장르일 때의 스타일
      }
      return 'px-[3.6vw] py-[0.4vh] rounded' // 기본 스타일
    }

    const genreFilters = {
      Mon: () => toons.filter((toon) => toon?.weekday?.includes('Mon')),
      Tue: () => toons.filter((toon) => toon?.weekday?.includes('Tue')),
      Wed: () => toons.filter((toon) => toon?.weekday?.includes('Wed')),
      Thu: () => toons.filter((toon) => toon?.weekday?.includes('Thu')),
      Fri: () => toons.filter((toon) => toon?.weekday?.includes('Fri')),
      Sat: () => toons.filter((toon) => toon?.weekday?.includes('Sat')),
      Sun: () => toons.filter((toon) => toon?.weekday?.includes('Sun')),
    }

    const filterFunction =
      genreFilters[todayWeekday] || genreFilters.defaultFilter
    const filteredToons = filterFunction()
    const isToday = todayWeekday === moment().format('ddd')

    return (
      <div className='flex flex-col items-center mb-[12vh]'>
        <div className='w-full flex flex-row justify-between px-2 py-2 mb-4 text-[3.6vw] border-b border-[#F3F3F3] relative overflow-y-hidden overflow-scroll whitespace-nowrap'>
          <button
            type='button'
            className={getButtonStyle('Mon')}
            onClick={() => {
              sessionStorage.setItem('toon_default_weekday', 'Mon')
              setTodayWeekday('Mon')
            }}
          >
            월
          </button>
          <button
            type='button'
            className={getButtonStyle('Tue')}
            onClick={() => {
              sessionStorage.setItem('toon_default_weekday', 'Tue')
              setTodayWeekday('Tue')
            }}
          >
            화
          </button>
          <button
            type='button'
            className={getButtonStyle('Wed')}
            onClick={() => {
              sessionStorage.setItem('toon_default_weekday', 'Wed')

              setTodayWeekday('Wed')
            }}
          >
            수
          </button>
          <button
            type='button'
            className={getButtonStyle('Thu')}
            onClick={() => {
              sessionStorage.setItem('toon_default_weekday', 'Thu')
              setTodayWeekday('Thu')
            }}
          >
            목
          </button>
          <button
            type='button'
            className={getButtonStyle('Fri')}
            onClick={() => {
              sessionStorage.setItem('toon_default_weekday', 'Fri')
              setTodayWeekday('Fri')
            }}
          >
            금
          </button>
          <button
            type='button'
            className={getButtonStyle('Sat')}
            onClick={() => {
              sessionStorage.setItem('toon_default_weekday', 'Sat')
              setTodayWeekday('Sat')
            }}
          >
            토
          </button>
          <button
            type='button'
            className={getButtonStyle('Sun')}
            onClick={() => {
              sessionStorage.setItem('toon_default_weekday', 'Sun')
              setTodayWeekday('Sun')
            }}
          >
            일
          </button>
        </div>

        <div ref={ref} className='px-4'>
          {filteredToons?.map((toon, index) => (
            <MainToonComponent
              recentlyViewedToons={recentlyViewedToons}
              key={toon?._id}
              toon={toon}
              index={index}
              isToday={isToday}
            />
          ))}
        </div>
      </div>
    )
  })

  const BottomSection = () => {
    return (
      <div className='fixed bottom-0 w-full h-[9vh] flex flex-row justify-between items-center bg-white border-t border-[#F5F5F5] text-sm'>
        <button
          type='button'
          className='w-full flex flex-col items-center text-center'
          onClick={() => {
            navigate(`/altoon-main?token=${token}`)
          }}
        >
          <img
            src='/images/toon/ic_gnb_home_D_active.png'
            alt=''
            className='w-6 h-6 mx-auto mb-1'
          />
          홈
        </button>
        <button
          type='button'
          className='w-full text-center'
          onClick={() => {
            navigate(`/altoon-mypage?token=${token}`)
          }}
        >
          <img
            src='/images/toon/ic_gnb_box_B.png'
            alt=''
            className='w-6 h-6 mx-auto mb-1'
          />
          보관함
        </button>
        <button
          type='button'
          className='w-full text-center'
          onClick={() => {
            navigate(`/altoon-ticket?token=${token}`)
          }}
        >
          <img
            src='/images/toon/ic_ticket_line_A.png'
            alt=''
            className='w-6 h-6 mx-auto mb-1 relative'
          />
          <ExclamationMarkIcon className='absolute top-0 right-[8vw] w-6 h-6 mx-auto mb-1' />
          내 코인
        </button>
      </div>
    )
  }

  const LoadingIndicator = () => {
    return (
      <div>
        <div className='fixed inset-0 z-10 w-full h-full bg-gray-800 opacity-70' />
        <div style={{ left: '40%', top: '40%' }} className='fixed z-20'>
          <ClipLoader
            color='#ff3e3e'
            loading={isLoading}
            size={80}
            aria-label='Loading Spinner'
            data-testid='loader'
          />
        </div>
      </div>
    )
  }

  return (
    <div
      className='w-full overflow-y-scroll'
      style={{ height: '100vh' }}
      ref={scrollContainerRef}
    >
      <HeaderComponent />
      {bannerData?.length > 0 && (
        <BannerSwiperComponent bannerData={bannerData} />
      )}
      <RecentlyViewedHorizontalList />
      <TrendingToonList />
      <ToonList ref={toonListRef} />
      {selectedTab === 'recommend' && <ToonListByGenre ref={toonListRef} />}
      {selectedTab === 'daily' && <ToonListByWeekday ref={toonListRef} />}
      <BottomSection />
      {isLoading && <LoadingIndicator />}
    </div>
  )
})

export default AltoonMainPage
