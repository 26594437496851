import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { FiChevronLeft } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import { useSearchParam } from 'react-use'

import LoadingIndicator from '../components/atoms/loadingIndicator'
import MainToonComponent from '../components/toon/mainToonComponent'
import THImageText from '../components/treasureHunt/THImageText'
import AuthStore from '../stores/AuthStore'
import ToastStore from '../stores/ToastStore'
import backendApis from '../utils/backendApis'

/**
 * 올툰 이벤트 페이지 (id기반 이벤트 정보 조회)
 */
const AltoonEventPage = observer(() => {
  const eventId = useSearchParam('eventId')
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [eventInfo, setEventInfo] = useState()
  const [toonsInfo, setToonsInfo] = useState()

  useEffect(() => {
    const fetchData = async () => {
      const eventInfo = await backendApis.loadToonEventInfo(eventId)
      if (eventInfo?.status === 200) {
        setEventInfo(eventInfo?.data)
        const toonInfo = await backendApis.loadToonInfoByIds(
          eventInfo?.data?.toonIdList,
        )
        setToonsInfo(toonInfo?.data)
      }
    }
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'allowiPhoneGesture',
        boolean: false,
      }),
    )

    setIsLoading(true)
    fetchData()
    backendApis.logToonAction('AltoonEventPage', 'enteredScreen', '')
    setIsLoading(false)
  }, [])

  const HeaderComponent = () => {
    return (
      <section className='w-full h-16 bg-white z-10 py-2 flex flex-row items-center justify-between overflow-hidden font-bold'>
        <button
          type='button'
          aria-label='back'
          className='px-3 py-2 whitespace-nowrap'
          onClick={async () => {
            navigate(-1)
          }}
        >
          <FiChevronLeft className='w-8 h-8' />
        </button>
        <div className='text-[4.2vw] py-2 whitespace-nowrap'>
          {eventInfo?.eventTitle?.length > 20
            ? `${eventInfo?.eventTitle?.slice(0, 20)}..`
            : eventInfo?.eventTitle}
        </div>
        <div className='w-14' />
      </section>
    )
  }

  const MainImageSection = () => {
    return (
      <div>
        <img
          src={eventInfo?.eventImageUri}
          alt=''
          className='w-full h-full object-cover'
        />
      </div>
    )
  }

  const CTAButtonOne = () => {
    return (
      <div className='w-full py-[4vw] flexRow justify-center items-center text-[4vw]'>
        <button
          type='button'
          style={{
            borderColor: `#${eventInfo?.ctaOneInfo?.ctaBorderColor}`,
            color: `#${eventInfo?.ctaOneInfo?.ctaTextColor}`,
            backgroundColor: `#${eventInfo?.ctaOneInfo?.ctaColor}`,
          }}
          className={`py-4 w-[70vw] rounded-lg font-bold text-[5vw] text-center ${
            eventInfo?.ctaOneInfo?.ctaBorderColor ? 'border-2' : ''
          }`}
          onClick={async () => {
            if (eventInfo?.setStorageInfo) {
              window.localStorage.setItem(
                'toon_default_tab',
                eventInfo?.setStorageInfo,
              )
            }
            if (eventInfo?.ctaOneInfo?.ctaType === 'setAlarm') {
              const alarmSet = await backendApis.setToonAlarm(
                `setAlarm_${eventInfo?.description}`,
                eventInfo?._id,
              )
              if (alarmSet?.status === 200) {
                ToastStore.toastOn({
                  type: 'emoji',
                  emoji: '🔔',
                  message: '알림이 설정되었어요!',
                  duration: 2000,
                })
              } else {
                ToastStore.toastOn({
                  type: 'error',
                  message:
                    '알 수 없는 오류가 발생했어요.\n 잠시후 다시 시도해주세요',
                  duration: 2000,
                })
              }
            } else {
              navigate(
                `/${eventInfo?.ctaOneInfo?.ctaPath}?token=${AuthStore?.token}&${eventInfo?.ctaOneInfo?.ctaProp}`,
              )
            }
          }}
        >
          {eventInfo?.ctaOneInfo?.ctaText || '바로가기'}
        </button>
      </div>
    )
  }

  const CTAButtonTwo = () => {
    return (
      <div className='w-full pt-[4vw] pb-[10vw] flexRow justify-center items-center text-[4vw]'>
        <button
          type='button'
          style={{
            borderColor: `#${eventInfo?.ctaTwoInfo?.ctaBorderColor}`,
            color: `#${eventInfo?.ctaTwoInfo?.ctaTextColor}`,
            backgroundColor: `#${eventInfo?.ctaTwoInfo?.ctaColor}`,
          }}
          className={`py-4 w-[70vw] rounded-lg font-bold text-[5vw] text-center ${
            eventInfo?.ctaTwoInfo?.ctaBorderColor ? 'border-2' : ''
          }`}
          onClick={async () => {
            if (eventInfo?.setStorageInfo) {
              window.localStorage.setItem(
                'toon_default_tab',
                eventInfo?.setStorageInfo,
              )
            }
            if (eventInfo?.ctaTwoInfo?.ctaType === 'setAlarm') {
              const alarmSet = await backendApis.setToonAlarm(
                `setAlarm_${eventInfo?.description}`,
                eventInfo?._id,
              )
              if (alarmSet?.status === 200) {
                ToastStore.toastOn({
                  type: 'emoji',
                  emoji: '🔔',
                  message: '알림이 설정되었어요!',
                  duration: 2000,
                })
              } else {
                ToastStore.toastOn({
                  type: 'error',
                  message:
                    '알 수 없는 오류가 발생했어요.\n 잠시후 다시 시도해주세요',
                  duration: 2000,
                })
              }
            } else {
              navigate(
                `/${eventInfo?.ctaTwoInfo?.ctaPath}?token=${AuthStore?.token}&${eventInfo?.ctaTwoInfo?.ctaProp}`,
              )
            }
          }}
        >
          {eventInfo?.ctaTwoInfo?.ctaText || '바로가기'}
        </button>
      </div>
    )
  }

  const ToonList = () => {
    return (
      <div className='pb-4'>
        <div className='px-4 pt-6 pb-4 font-bold text-[4.8vw] text-white'>
          {eventInfo?.toonListTitle || '이벤트 대상 작품목록'}
        </div>
        {toonsInfo?.map((toon, index) => (
          <div key={toon?._id} className='mx-4 mb-4 rounded-lg border bg-white'>
            <MainToonComponent toon={toon} index={index} type='eventPage' />
          </div>
        ))}
      </div>
    )
  }

  const NoticeSection = () => {
    return (
      <div className='w-full px-6 pt-4 pb-20 bg-gray-200'>
        <div className='text-[3.6vw] font-semibold'>유의사항</div>
        <THImageText
          fontSize={2.8}
          style={{
            color: '#8E8D9B',
          }}
          text={`${eventInfo?.notice}`}
        />
      </div>
    )
  }

  return (
    <div
      style={{
        backgroundColor: `#${eventInfo?.backgroundColor}`,
      }}
    >
      {eventInfo && (
        <>
          <HeaderComponent />
          <MainImageSection />
          {eventInfo?.ctaOneInfo?.ctaText && <CTAButtonOne />}
          {eventInfo?.toonIdList?.length > 0 && <ToonList />}
          {eventInfo?.ctaTwoInfo?.ctaText && <CTAButtonTwo />}
          {eventInfo?.notice && <NoticeSection />}
        </>
      )}
      {isLoading && <LoadingIndicator isLoading={isLoading} />}
    </div>
  )
})

export default AltoonEventPage
