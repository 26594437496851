import { ThemeProvider } from '@material-tailwind/react'
import React from 'react'
import { Provider as AlertProvider, positions } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import AlertMUITemplate from 'react-alert-template-mui'
import { render } from 'react-dom'
import { createRoot } from 'react-dom/client'

import App from './App'
import './index.css'
import reportWebVitals from './reportWebVitals'

const container = document.getElementById('root')
const root = createRoot(container)

// optional configuration
const options = {
  // you can also just use 'bottom center'
  position: positions.MIDDLE,
  // // you can also just use 'scale'
  // transition: transitions.SCALE,
}

const Root = () => (
  <AlertProvider template={AlertMUITemplate} {...options}>
    <App />
  </AlertProvider>
)
// render(<Root />, container)
root.render(<Root />)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
