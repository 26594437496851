import { observer } from 'mobx-react-lite'
import React from 'react'

import MatchItem from './MatchItem'

const MatchListItems = observer(({ matchList }) => {
  return (
    <div className='relative w-full h-full'>
      {matchList?.map((item, index) => (
        <MatchItem key={Math.random()} item={item} index={index} />
      ))}
    </div>
  )
})

export default MatchListItems
