import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'

import UserStore from '../../stores/UserStore'
import ABStore from '../../stores/treasureHunt/ABStore'
import THBottomSheetStore from '../../stores/treasureHunt/THBottomSheetStore'
import THModalStore from '../../stores/treasureHunt/THModalStore'
// import commaNumber from 'comma-number'
import AnimatedNumber from '../atoms/AnimatedNumber'
import ToonCommentSection from '../toon/toonCommentSection'
import MVNOPreLaunch from './MVNOPreLaunch'
import THAchievement from './THAchievement'
import THBackpack from './THBackpack'
import THBambooInvite from './THBambooInvite'
import THCloset from './THCloset'
import THCollection from './THCollection'
import THLuck from './THLuck'
import THMission from './THMission'
import THPet from './THPet'
import THUploadChallenge from './THUploadChallenge'
import THUploadChallengeVer2 from './THUploadChallengeVer2'
import WatermelonArcadeBackpack from './WatermelonArcadeBackpack'
import WatermelonBackpack from './WatermelonBackpack'
import WatermelonBounty from './WatermelonBounty'
import WatermelonContacts from './WatermelonContacts'
import WatermelonRanking from './WatermelonRanking'
import Tutorial from './molecules/Tutorial'

const THBottomSheets = observer(() => {
  const [content, setContent] = useState(null)
  const [title, setTitle] = useState('')
  const [wallet, setWallet] = useState(UserStore?.wallet)
  const [jewel, setJewel] = useState(UserStore?.jewel)

  const onDismiss = () => {
    setTimeout(() => {
      THBottomSheetStore.close()
    }, 50)
  }

  const RightTopInfo = observer(() => {
    if (THBottomSheetStore?.type === 'bambooInvite') {
      return (
        <div
          className='default flex-row justify-center items-center absolute right-0 mr-4'
          onClick={() => {
            THModalStore.openBambooInviteModal()
          }}
        >
          <img
            className='w-[4vw] h-[4vw] mr-1'
            src='/images/treasureHunt/informationBlack.png'
            alt='jewel'
          />
        </div>
      )
    }
    if (THBottomSheetStore?.type === 'luck') {
      return (
        <div
          className='default flex-row justify-center items-center absolute right-0 mr-4'
          onClick={() => {
            THModalStore.openLuckModal()
          }}
        >
          <img
            className='w-[4vw] h-[4vw] mr-1'
            src='/images/treasureHunt/informationBlack.png'
            alt='jewel'
          />
        </div>
      )
    }
    if (THBottomSheetStore?.type === 'toonComment') {
      return (
        <button
          type='button'
          className='absolute right-0 mr-4 text-gray-500 text-[4vw]'
          onClick={onDismiss}
        >
          닫기
        </button>
      )
    }

    return null
  })

  const LeftTopInfo = observer(() => {
    if (
      THBottomSheetStore?.type === 'collection' ||
      THBottomSheetStore?.type === 'pet' ||
      THBottomSheetStore?.type === 'achievement' ||
      THBottomSheetStore?.type === 'closet'
    ) {
      return (
        <div
          className='default flex-row justify-center items-center absolute left-0 ml-4'
          onClick={() => {
            THBottomSheetStore.setType({
              type: 'backpack',
              config: {},
            })
          }}
        >
          <img
            className='w-[8vw] h-[8vw]'
            src='../images/treasureHunt/backpack/backpack.png'
            alt='collection'
          />
        </div>
      )
    }
    if (THBottomSheetStore?.type === 'booster') {
      return (
        <div className='default flex-row justify-center items-center absolute left-0 ml-4'>
          <img
            className='w-[6vw] h-[6vw] mr-1'
            src='/images/etc/heart.png'
            alt='heart'
          />
          <div className='text-[4vw] font-bold text-gray-800'>
            <AnimatedNumber targetNumber={UserStore?.heart} interval={10} />
          </div>
        </div>
      )
    }
    return null
  })

  useEffect(() => {
    switch (THBottomSheetStore?.type) {
      case 'mvnoPreLaunch':
        setContent(<MVNOPreLaunch />)
        setTitle('사전신청')
        break
      case 'watermelonBounty':
        setContent(<WatermelonBounty />)
        setTitle('현상금 미션')
        break
      case 'watermelonContacts':
        setContent(<WatermelonContacts />)
        setTitle('연락처 초대')
        break
      case 'watermelonRanking':
        setContent(<WatermelonRanking />)
        setTitle('랭킹')
        break
      case 'watermelonTicket':
        setContent(<WatermelonBackpack />)
        setTitle('도전권 미션')
        break
      case 'watermelonArcadeTicket':
        setContent(<WatermelonArcadeBackpack />)
        setTitle('티켓 미션')
        break

      case 'uploadChallenge':
        if (ABStore?.uploadChallenge === 'b') {
          setContent(<THUploadChallengeVer2 />)

          setTitle('삼행시 챌린지')
        } else {
          setContent(<THUploadChallenge />)

          setTitle('게시글 챌린지')
        }

        break
      case 'closet':
        setContent(<THCloset />)
        setTitle('꾸미기')
        break
      case 'achievement':
        setContent(<THAchievement />)
        setTitle('업적')
        break
      case 'backpack':
        setContent(<THBackpack />)
        setTitle('가방')
        break
      case 'luck':
        setContent(<THLuck />)
        setTitle('행운')
        break
      case 'bambooInvite':
        setContent(<THBambooInvite />)
        setTitle('친구초대')
        break
      case 'collection':
        setContent(<THCollection />)
        setTitle('캐릭터 도감')
        break
      case 'pet':
        setContent(<THPet />)
        setTitle('야옹이')
        break
      case 'tutorial':
        setContent(<Tutorial />)
        break
      case 'mission':
        setContent(<THMission />)
        setTitle('미션')
        break
      case 'toonComment':
        setContent(
          <ToonCommentSection
            toonInfo={THBottomSheetStore?.config.toonInfo}
            chapterInfo={THBottomSheetStore?.config.chapterInfo}
          />,
        )
        setTitle(THBottomSheetStore?.config.title)
        break
      default:
        setContent(null)
        break
    }
  }, [THBottomSheetStore?.type])

  useEffect(() => {
    setWallet(UserStore?.wallet)
  }, [UserStore?.wallet])

  useEffect(() => {
    setJewel(UserStore?.jewel)
  }, [UserStore?.jewel])

  return (
    <BottomSheet
      className='w-full bg-white border-none outline-none bottom-sheet relative z-[28]'
      onDismiss={onDismiss}
      blocking={THModalStore.type === 'none'}
      scrollLocking={false}
      header={
        THBottomSheetStore?.titleOff ? (
          false
        ) : (
          <h1 className='flex items-center justify-center font-bold text-[#313138] mb-1 mt-1 text-[5vw]'>
            <LeftTopInfo />
            {title}
            <RightTopInfo />
          </h1>
        )
      }
      open={THBottomSheetStore?.type !== 'none'}
      snapPoints={({ maxHeight }) => [maxHeight, maxHeight * 0.8]}
    >
      {content}
    </BottomSheet>
  )
})

export default THBottomSheets
