import commaNumber from 'comma-number'
import React from 'react'

import { CouponIcon, CouponPackBanner } from '../images/dailyCheckIn'
import backendApis from '../utils/backendApis'

const CouponHorizontalList = ({ couponPackData, setOpenCouponModal }) => {
  return (
    <div className='flex flex-col py-5 mx-4'>
      <div className='items-end justify-between pb-3 mx-1 text-xl font-bold'>
        행운의 쿠폰팩 뽑기
      </div>
      <div className='flex flex-row items-center justify-start overflow-x-scroll'>
        {couponPackData.length > 0 &&
          couponPackData[0]?.couponInfo?.map((coupon) => {
            return (
              <button
                type='button'
                key={coupon.couponUniqueNumber + Math.random()}
                className='relative flex flex-col items-center justify-center w-32 h-28 p-4 mx-2 rounded-lg drop-shadow outline-0'
                onClick={() => {
                  setOpenCouponModal(true)
                }}
              >
                <div className='w-28 h-24'>
                  <div className='font-bold text-2xl'>
                    {commaNumber(coupon.value)}
                    {coupon.couponType === 'won' ? '원' : ''}
                  </div>
                  <div className='text-gray-500 text-xs'>
                    {coupon.condition > 0
                      ? `${commaNumber(coupon.condition)}원 이상 구매`
                      : '조건 없음'}
                  </div>
                </div>
                <CouponIcon className='absolute w-full h-full left-0 top-0 -z-10' />
              </button>
            )
          })}
        {(!couponPackData || couponPackData.length === 0) && (
          <button
            type='button'
            onClick={async () => {
              await backendApis.recordEventLog(
                'checkInCouponPack',
                'pressCouponPackBanner',
              )
              setOpenCouponModal(true)
            }}
          >
            <CouponPackBanner className='' />
          </button>
        )}
      </div>
    </div>
  )
}

export default CouponHorizontalList
