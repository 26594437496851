import React from 'react'

import {
  CheckInPigIcon,
  DailyCheckInAlarm,
  DailyCheckInAlfarm,
  DailyCheckInCart,
  DailyCheckInClock,
  DailyCheckInFriends,
  DailyCheckInQuiz,
  DailyCheckInShoppingBag,
  HappinessMudOnly,
  HappinessToyOnly,
  Heart,
  PigBoostMissionFeed,
  PigBoostMissionPurchase,
  PigBoostMissionStamp,
  PigFoodColored,
  PigHappinessMissionPet,
  PigHappinessMissionShop,
  PigHappinessMissionTimer,
  PigMissionAlarm,
  PigMissionCheckIn,
  PigMissionConsecutiveCheckIn,
  PigMissionPlay,
  PigMissionShop,
  PigMissionShorts,
  PigMissionTimer,
  PigMissionWakeUp,
  SuperPig,
} from '../images/dailyCheckIn'
import useDebounce from './useDebounce'

const DailyCheckInScreenBottomSheetItem = ({
  leftImage = '',
  title,
  subTitle,
  reward,
  rewardType = 'food',
  subReward,
  subRewardType,
  content,
  timer,
  headText,
  footerText,
  impressTextInContent = '',
  moveTo,
  disabled = false,
  stampComponent,
  button = '',
}) => {
  const debounce = useDebounce()
  const ImageMapper = ({ leftImage = '' }) => {
    if (leftImage === 'DailyCheckInAlfarm') {
      return <DailyCheckInAlfarm className='w-12' />
    }
    if (leftImage === 'DailyCheckInAlarm') {
      return <DailyCheckInAlarm className='w-12' />
    }
    if (leftImage === 'DailyCheckInClock') {
      return <DailyCheckInClock className='w-12' />
    }
    if (leftImage === 'DailyCheckInFriends') {
      return <DailyCheckInFriends className='w-12' />
    }
    if (leftImage === 'DailyCheckInQuiz') {
      return <DailyCheckInQuiz className='w-12' />
    }
    if (leftImage === 'DailyCheckInBrowsingMission') {
      return <DailyCheckInShoppingBag className='w-12' />
    }
    if (leftImage === 'DailyCheckInCashbackMission') {
      return <DailyCheckInCart className='w-12' />
    }
    if (leftImage === 'PigMissionTimer') {
      return <PigMissionTimer className='w-12' />
    }
    if (leftImage === 'PigMissionAlarm') {
      return <PigMissionAlarm className='w-12' />
    }
    if (leftImage === 'PigMissionShop') {
      return <PigMissionShop className='w-12' />
    }
    if (leftImage === 'PigMissionShorts') {
      return <PigMissionShorts className='w-12' />
    }
    if (leftImage === 'PigMissionCheckIn') {
      return <PigMissionCheckIn className='w-12' />
    }
    if (leftImage === 'PigMissionConsecutiveCheckIn') {
      return <PigMissionConsecutiveCheckIn className='w-12' />
    }
    if (leftImage === 'PigMissionPlay') {
      return <PigMissionPlay className='w-12' />
    }
    if (leftImage === 'PigMissionWakeUp') {
      return <PigMissionWakeUp className='w-12' />
    }
    if (leftImage === 'PigHappinessMissionPet') {
      return <PigHappinessMissionPet className='w-12' />
    }
    if (leftImage === 'PigHappinessMissionShop') {
      return <PigHappinessMissionShop className='w-12' />
    }
    if (leftImage === 'PigHappinessMissionTimer') {
      return <PigHappinessMissionTimer className='w-12' />
    }
    if (leftImage === 'PigBoostMissionStamp') {
      return <PigBoostMissionStamp className='w-12' />
    }
    if (leftImage === 'PigBoostMissionFeed') {
      return <PigBoostMissionFeed className='w-12' />
    }
    if (leftImage === 'PigBoostMissionPurchase') {
      return <PigBoostMissionPurchase className='w-12' />
    }
    if (leftImage === 'CheckInPigIcon') {
      return <CheckInPigIcon className='w-12' />
    }
    if (leftImage === 'DailyCheckInRewardedAdMission') {
      return <DailyCheckInQuiz className='w-12' />
    }
  }

  return (
    <div className='flex flex-row items-center flex-1 py-4 jua'>
      <div className='flex items-center flex-1'>
        <ImageMapper leftImage={leftImage} />
        <div className='flex flex-col items-start justify-center flex-1 pl-4'>
          {headText && <div className='text-sm text-red-500'>{headText}</div>}
          <div className='flex flex-row items-center justify-center'>
            <div className='flex flex-row items-center text-base'>
              {title}
              <div className='flex pl-1 text-xs'>{subTitle}</div>
            </div>
            {reward && (
              <div
                className='flex flex-row items-center text-xs rounded-lg'
                style={{ color: '#DD524B' }}
              >
                {rewardType === 'food' && (
                  <PigFoodColored className='w-5 mx-1' />
                )}
                {rewardType === 'happiness' && (
                  <Heart className='w-4 mx-1 mt-1' />
                )}
                {rewardType === 'toy' && <HappinessToyOnly className='w-8' />}
                {rewardType === 'mud' && <HappinessMudOnly className='w-8' />}
                {rewardType === 'boostDondon' && (
                  <SuperPig className='w-6 mr-1' />
                )}

                {reward}
              </div>
            )}
            {subReward && (
              <div
                className='flex flex-row items-center justify-center ml-2 text-xs'
                style={{ color: '#DD524B' }}
              >
                <div className='mt-1 mr-1'>{subRewardType}</div>
                {subReward}
              </div>
            )}
          </div>
          <div className='text-xs' style={{ color: '#796950' }}>
            {impressTextInContent}
            {content}
          </div>
          {footerText && (
            <div className='text-xs' style={{ color: '#DD524B' }}>
              {footerText}
            </div>
          )}
          {stampComponent && (
            <div className='text-xs' style={{ color: '#DD524B' }}>
              {stampComponent}
            </div>
          )}
        </div>
      </div>
      <div className='flex flex-col items-center justify-center'>
        {timer && <div className='text-xs'>{timer} 후 가능</div>}

        {button || (
          <button
            type='button'
            className={`w-20 h-10 px-2 rounded-lg justify-center text-white text-base
              ${
                disabled
                  ? 'bg-gray-300'
                  : 'bg-red-500 active:brightness-90 duration-200 active:scale-95 '
              }
                `}
            onClick={() => {
              debounce(moveTo, 500)
            }}
            disabled={disabled}
          >
            {disabled ? '미션완료' : '보상받기'}
            {button}
          </button>
        )}
      </div>
    </div>
  )
}

export default DailyCheckInScreenBottomSheetItem
