import ImageAtom from '../../../atoms/imageAtom'

const ItemCard = ({ item }) => {
  if (!item?.mainImageUri) {
    return
  }
  return (
    <>
      <div className='relative flexCol w-[80vw] h-[66vw] mx-[2vw]'>
        <ImageAtom
          src={item.mainImageUri}
          className='rounded-lg w-[60%] h-auto shadow-xl'
        />
      </div>
    </>
  )
}
export default ItemCard
