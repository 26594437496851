import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import altoonCMSBackendApis from '../../utils/altoonCMSBackendApis'

const ToonAdminScreen = observer(() => {
  const [filterCurrentToonData, setFilterCurrentToonData] = useState(false)
  const [tempData, setTempData] = useState([])
  const [promotionToonData, setPromotionToonData] = useState()
  const [chapterData, setChapterData] = useState([])
  const [toonId, setToonId] = useState()
  const selectedGenres = 'hot'

  const loadPromotionToons = async () => {
    const promotionData = await altoonCMSBackendApis.loadRecommendingToons({
      selectedGenres,
    })
    if (promotionData?.status === 200) {
      setPromotionToonData(promotionData?.data)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const result = await altoonCMSBackendApis.getToonData()
      if (result?.status === 200) {
        if (filterCurrentToonData) {
          setTempData(
            result?.data.filter(
              (item) => item.isDisplayed === true && item.isAdmin !== true,
            ),
          )
        } else {
          setTempData(result?.data)
        }
      }
    }

    fetchData()
    loadPromotionToons()
  }, [filterCurrentToonData])

  const RegisteredToonList = () => {
    return (
      <div className='p-4 h-[60vh] overflow-y-scroll bg-white rounded-xl'>
        <div className='py-4 font-bold text-[2.6vh]'>연재중 만화</div>
        <div className='pb-10 flexRow justify-start items-center font-bold '>
          <div className='pr-2'>제공중인 작품들만 보기</div>
          <input
            type='checkbox'
            checked={filterCurrentToonData}
            onChange={(e) => {
              setFilterCurrentToonData(e.target.checked)
            }}
          />
        </div>
        {tempData?.length > 0 && promotionToonData?.length > 0 && (
          <table>
            <thead className='whitespace-nowrap text-[1.4vh] border-b'>
              <tr>
                <th>아이디</th>
                <th>작품제목</th>
                <th>작가</th>
                <th>썸네일</th>
                <th>평균평점</th>
                <th>연재 시작일</th>
                <th>노출 여부</th>
                <th>노출순서</th>
                <th>설명</th>
                <th>공지</th>
                <th>CP사</th>
                <th>에피소드 가격</th>
                <th className='text-[1vh]'>프로모션 여부</th>
                <th className='text-[1vh]'>프로모션하기</th>
              </tr>
              <tr className='text-[1vh]'>
                <th>id</th>
                <th>title</th>
                <th>author</th>
                <th>thumbnail</th>
                <th>rating</th>
                <th>createdAt</th>
                <th>isDisplayed</th>
                <th>priority</th>
                <th>description</th>
                <th>notice</th>
                <th>providerInfo</th>
                <th>toonPrice</th>
              </tr>
            </thead>
            <tbody style={{ fontSize: 12, textAlign: 'center' }}>
              {tempData.map((item) => (
                <tr className='border' key={item._id}>
                  <td style={{ fontSize: 8 }}>{item._id}</td>
                  <td className='whitespace-nowrap'>{item.title}</td>
                  <td className='whitespace-nowrap'>{item.author}</td>
                  <td>
                    <img
                      src={item.thumbnail}
                      alt='itemImage'
                      style={{ width: 50, height: 50 }}
                    />
                  </td>
                  <td>{item.rating}</td>
                  <td className='whitespace-nowrap'>
                    {moment(item.createdAt).format('YYYY-MM-DD HH:mm')}
                  </td>
                  <td>
                    {item.isDisplayed === true ? 'Y' : 'N'}
                    {item.isAdmin === true ? '(admin)' : ''}
                  </td>
                  <td>{item.priority}</td>
                  <td className='p-2'>
                    {`${item.description.slice(0, 20)}...`}
                  </td>
                  <td className='p-2'>{item.notice ? item.notice : '-'}</td>
                  {item?.providerInfo && (
                    <td>
                      <table className='border self-center'>
                        <thead>
                          <tr>
                            <th className='whitespace-nowrap text-[0.4vw]'>
                              id
                            </th>
                            <th className='whitespace-nowrap'>이름</th>
                            <th className='whitespace-nowrap'>배분율</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className='whitespace-nowrap text-[0.4vw]'>
                              {item.providerInfo.providerId.slice(0, 4)}...
                            </td>
                            <td className='whitespace-nowrap'>
                              {item.providerInfo.providerDisplayName}
                            </td>
                            <td className='whitespace-nowrap'>
                              {item.providerInfo.settlementFeeRate}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  )}
                  {!item?.providerInfo && <td>-</td>}
                  <td>{item.toonPrice}C</td>
                  <td>
                    {promotionToonData?.some(
                      (e) => e._id?.toString() === item._id?.toString(),
                    )
                      ? 'O'
                      : '-'}
                  </td>
                  <td>
                    <button
                      type='button'
                      className='border rounded whitespace-nowrap px-2 py-1 bg-gray-500 text-white'
                      onClick={async () => {
                        if (
                          promotionToonData?.some(
                            (e) => e._id?.toString() === item._id?.toString(),
                          )
                        ) {
                          const result =
                            await altoonCMSBackendApis.dePromoteToon({
                              toonId: item._id,
                            })
                          if (result?.status === 200) {
                            alert('취소되었습니다')
                          } else {
                            alert(
                              '오류가 발생했습니다. 담당자에게 문의해주세요.',
                            )
                          }
                          loadPromotionToons()
                        } else {
                          const result = await altoonCMSBackendApis.promoteToon(
                            {
                              toonId: item._id,
                            },
                          )
                          if (result?.status === 200) {
                            alert('프로모션 등록되었습니다')
                          } else {
                            alert(
                              '오류가 발생했습니다. 담당자에게 문의해주세요.',
                            )
                          }
                          loadPromotionToons()
                        }
                      }}
                    >
                      {promotionToonData?.some(
                        (e) => e._id?.toString() === item._id?.toString(),
                      )
                        ? '취소하기'
                        : '프로모션하기'}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    )
  }

  const RegisteredChapterList = () => {
    return (
      <div
        className={`w-full ${
          chapterData?.length > 0 ? 'h-[40vh]' : ''
        } overflow-y-scroll bg-white`}
      >
        {chapterData?.length > 0 && (
          <table>
            <thead className='whitespace-nowrap text-[1.4vh] border-b'>
              <tr>
                <th>아이디</th>
                <th>episode</th>
                <th>chapterTitle</th>
                <th>detailThumbnail</th>
                <th>평균평점</th>
                <th>publishedAt</th>
                <th>isFree</th>
                <th>isDisplayed</th>
                <th>contents(수정X)</th>
                <th>authorComment</th>
                <th>deletedAt</th>
              </tr>
            </thead>
            <tbody style={{ fontSize: 12, textAlign: 'center' }}>
              {chapterData.map((item) => (
                <tr key={item._id}>
                  <td style={{ fontSize: 8 }}>{item._id}</td>
                  <td>{item.episode}</td>
                  <td>{item.chapterTitle}</td>
                  <td>
                    <img
                      src={item.detailThumbnail}
                      alt='itemImage'
                      style={{ width: 50, height: 50 }}
                    />
                  </td>
                  <td>
                    {Math.floor(
                      (item.chapterRatingScore / item.chapterRatingCount) * 100,
                    ) / 100 || '-'}
                  </td>
                  <td>{moment(item.publishedAt).format('YYYY-MM-DD HH:mm')}</td>
                  <td>{item.isFree === true ? '무료' : '유료'}</td>
                  <td>{item.isDisplayed === true ? 'Y' : 'N'}</td>
                  <td>
                    {item.contents.slice(0, 1)} 및 {item.contents.length}개
                  </td>
                  <td>{item.authorComment}</td>
                  {item.deletedAt && (
                    <td>{moment(item.deletedAt).format('YYYY-MM-DD HH:mm')}</td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {!chapterData?.length > 0 && (
          <div className='flexRow justify-center items-center'>
            데이터가 없습니다.
          </div>
        )}
      </div>
    )
  }

  const ToonSearchSection = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          paddingBottom: 20,
        }}
      >
        작품아이디
        <div style={{ marginLeft: 12, marginRight: 12 }}>
          <input
            type='text'
            value={toonId}
            onChange={(e) => {
              console.log(`text: ${e.target.value}`)
              setToonId(e.target.value)
            }}
          />
        </div>
        <button
          type='button'
          className='px-4 py-2 bg-gray-500 text-white rounded-xl'
          onClick={async () => {
            const result = await altoonCMSBackendApis.getChapterDataByToonId({
              toonId,
            })
            if (result?.status === 200) {
              console.log('result: ', result?.data)
              setChapterData(result?.data)
            }
          }}
        >
          회차 조회하기
        </button>
      </div>
    )
  }

  const UpdateToonInfo = () => {
    const [toonId, setToonId] = useState('')
    const [field, setField] = useState('')
    const [value, setValue] = useState('')
    const [isDate, setIsDate] = useState(false)

    return (
      <div className='p-4 bg-white rounded-xl'>
        <div className='py-4 font-bold text-[2.6vh]'>작품 정보 수정</div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            paddingBottom: 12,
          }}
        >
          작품 아이디
          <div style={{ marginBottom: 8 }}>
            <input
              type='text'
              value={toonId}
              onChange={(e) => {
                console.log(`text: ${e.target.value}`)
                setToonId(e.target.value)
              }}
            />
          </div>
          바꿀 field
          <div style={{ marginBottom: 8 }}>
            <input
              type='text'
              value={field}
              onChange={(e) => {
                console.log(`text: ${e.target.value}`)
                setField(e.target.value)
              }}
            />
          </div>
          value
          <div style={{ marginBottom: 4 }}>
            <input
              type='text'
              value={value}
              onChange={(e) => {
                console.log(`text: ${e.target.value}`)
                setValue(e.target.value)
              }}
            />
          </div>
        </div>
        <div style={{ marginBottom: 8 }}>
          <div>
            value가 날짜인가요? (날짜인 경우 value 형식은 "2024-01-01
            00:00:00"의 형식으로 넣어주세요)
          </div>
          <input
            type='checkbox'
            checked={isDate}
            onChange={(e) => {
              console.log(`text: ${e.target.checked}`)
              setIsDate(e.target.checked)
            }}
          />
        </div>
        <button
          type='button'
          className='mt-10 px-4 py-2 bg-gray-500 text-white rounded-xl'
          onClick={async () => {
            const result = await altoonCMSBackendApis.updateToonInfo({
              toonId,
              field,
              value,
              isDate,
            })
            if (result?.status === 200) {
              alert('업데이트 완료')
            }
          }}
        >
          작품 정보 업데이트
        </button>
      </div>
    )
  }

  const UpdateChapterInfo = () => {
    const [chapterId, setChapterId] = useState('')
    const [field, setField] = useState('')
    const [value, setValue] = useState('')
    const [isDate, setIsDate] = useState(false)

    return (
      <div className='p-4 bg-white rounded-xl'>
        <div className='py-4 font-bold text-[2.6vh]'>회차 정보 수정</div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            paddingBottom: 12,
          }}
        >
          회차아이디
          <div style={{ marginBottom: 8 }}>
            <input
              type='text'
              value={chapterId}
              onChange={(e) => {
                console.log(`text: ${e.target.value}`)
                setChapterId(e.target.value)
              }}
            />
          </div>
          바꿀 field
          <div style={{ marginBottom: 8 }}>
            <input
              type='text'
              value={field}
              onChange={(e) => {
                console.log(`text: ${e.target.value}`)
                setField(e.target.value)
              }}
            />
          </div>
          value
          <div style={{ marginBottom: 4 }}>
            <input
              type='text'
              value={value}
              onChange={(e) => {
                console.log(`text: ${e.target.value}`)
                setValue(e.target.value)
              }}
            />
          </div>
        </div>
        <div style={{ marginBottom: 8 }}>
          <div>
            value가 날짜인가요? (날짜인 경우 value 형식은 "2024-01-01
            00:00:00"의 형식으로 넣어주세요)
          </div>
          <input
            type='checkbox'
            checked={isDate}
            onChange={(e) => {
              console.log(`text: ${e.target.checked}`)
              setIsDate(e.target.checked)
            }}
          />
        </div>
        <button
          type='button'
          className='mt-10 px-4 py-2 bg-gray-500 text-white rounded-xl'
          onClick={async () => {
            const result = await altoonCMSBackendApis.updateChapterInfo({
              chapterId,
              field,
              value,
              isDate,
            })
            if (result?.status === 200) {
              alert('업데이트 완료')
            }
          }}
        >
          회차 정보 업데이트
        </button>
      </div>
    )
  }

  const ToonRegisterSection = () => {
    const [title, setTitle] = useState('')
    const [author, setAuthor] = useState('')
    const [thumbnail, setThumbnail] = useState('')
    const [verticalThumbnail, setVerticalThumbnail] = useState(false)
    const [description, setDescription] = useState('')
    const [toonType, setToonType] = useState('')
    const [genre, setGenre] = useState('')
    const [providerId, setProviderId] = useState('')
    const [settlementFeeRate, setSettlementFeeRate] = useState('')
    const [createdAt, setCreatedAt] = useState('')

    return (
      <div className='p-4 bg-white rounded-xl'>
        <div className='py-4 font-bold text-[2.6vh]'>작품 등록</div>
        <div className='grid-cols-2 grid'>
          <div>
            작품명
            <div>
              <input
                type='text'
                value={title}
                onChange={(e) => {
                  console.log(`text: ${e.target.value}`)
                  setTitle(e.target.value)
                }}
              />
            </div>
          </div>
          <div style={{ paddingTop: 12 }}>
            <div>
              작가명
              <div>
                <input
                  type='text'
                  value={author}
                  onChange={(e) => {
                    console.log(`text: ${e.target.value}`)
                    setAuthor(e.target.value)
                  }}
                />
              </div>
            </div>
          </div>
          <div style={{ paddingTop: 12 }}>
            <div>
              썸네일 url
              <div>
                <input
                  type='text'
                  value={thumbnail}
                  onChange={(e) => {
                    console.log(`text: ${e.target.value}`)
                    setThumbnail(e.target.value)
                  }}
                />
              </div>
            </div>
          </div>
          <div style={{ paddingTop: 12 }}>
            세로형 썸네일 여부
            <input
              type='checkbox'
              checked={verticalThumbnail}
              onChange={(e) => {
                console.log(`text: ${e.target.checked}`)
                setVerticalThumbnail(e.target.checked)
              }}
            />
          </div>
          <div style={{ paddingTop: 12 }}>
            <div>
              작품설명
              <div>
                <textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  style={{ width: 400, height: 100 }}
                />
              </div>
            </div>
          </div>
          <div style={{ paddingTop: 12 }}>
            <div>
              작품형태(ex: VerticalScroll // HorizontalScroll)
              <div>
                <input
                  type='text'
                  value={toonType}
                  onChange={(e) => {
                    console.log(`text: ${e.target.value}`)
                    setToonType(e.target.value)
                  }}
                />
              </div>
            </div>
          </div>
          <div style={{ paddingTop: 12 }}>
            <div>
              장르(ex: romance, instatoon, drama, daily, comedy, action,
              thriller)
              <div>
                <input
                  type='text'
                  value={genre}
                  onChange={(e) => {
                    console.log(`text: ${e.target.value}`)
                    setGenre(e.target.value)
                  }}
                />
              </div>
            </div>
          </div>
          <div style={{ paddingTop: 12 }}>
            CP사 아이디
            <input
              type='text'
              value={providerId}
              onChange={(e) => {
                console.log(`text: ${e.target.value}`)
                setProviderId(e.target.value)
              }}
            />
          </div>
          <div style={{ paddingTop: 12 }}>
            분배율(0~1 사이)
            <input
              type='text'
              value={settlementFeeRate}
              onChange={(e) => {
                console.log(`text: ${e.target.value}`)
                setSettlementFeeRate(e.target.value)
              }}
            />
          </div>
          <div style={{ paddingTop: 12 }}>
            <div>
              시작 날짜:
              <div>
                <input
                  type='datetime-local'
                  value={createdAt}
                  onChange={(e) => {
                    console.log(`text: ${e.target.value}`)
                    setCreatedAt(e.target.value)
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div style={{ paddingTop: 12 }}>
          <button
            type='button'
            className='mt-10 px-4 py-2 bg-gray-500 text-white rounded-xl'
            onClick={async () => {
              if (
                !title ||
                !author ||
                !createdAt ||
                !thumbnail ||
                !description ||
                !toonType ||
                !genre ||
                !providerId ||
                !settlementFeeRate
              ) {
                return
              }

              const result = await altoonCMSBackendApis.registerToon({
                title,
                author,
                createdAt: new Date(createdAt),
                thumbnail,
                description,
                toonType,
                genre,
                providerId,
                settlementFeeRate,
                verticalThumbnail,
              })
              if (result?.status === 200) {
                alert('등록되었습니다.')
                setTitle('')
                setAuthor('')
                setCreatedAt('')
                setThumbnail('')
                setDescription('')
                setToonType('')
                setGenre('')
                setProviderId('')
                setSettlementFeeRate('')
                setVerticalThumbnail(false)
              } else if (result?.status === 204) {
                alert(result?.data)
              } else {
                alert(
                  '오류가 발생하였습니다. 오기입 위험이 있으니 DB를 확인해주세요.',
                )
              }
            }}
          >
            작품 등록하기
          </button>
        </div>
      </div>
    )
  }

  const ChapterRegisterSection = () => {
    const [toonId, setToonId] = useState('')
    const [chapterTitle, setChapterTitle] = useState('')
    const [chapterEpisode, setChapterEpisode] = useState('')
    const [isFree, setIsFree] = useState(false)
    const [detailThumbnail, setDetailThumbnail] = useState('')
    const [authorComment, setAuthorComment] = useState('')
    const [publishedAt, setPublishedAt] = useState('')
    const [contents, setContents] = useState('')

    return (
      <div className='p-4 bg-white rounded-xl'>
        <div className='py-4 font-bold text-[2.6vh]'>연재 화 추가하기</div>
        <div className='grid-cols-2 grid'>
          <div style={{ paddingTop: 12 }}>
            작품 아이디
            <div>
              <input
                type='text'
                value={toonId}
                onChange={(e) => {
                  setToonId(e.target.value)
                }}
              />
            </div>
          </div>

          {/* <div className='flex flex-col'> */}
          <div style={{ paddingTop: 12 }}>
            <div> 연재화 제목</div>
            <input
              type='text'
              value={chapterTitle}
              onChange={(e) => {
                console.log(`text: ${e.target.value}`)
                setChapterTitle(e.target.value)
              }}
            />
          </div>
          <div style={{ paddingTop: 12 }}>
            <div> 연재회차 (episode)</div>
            <input
              type='text'
              value={chapterEpisode}
              onChange={(e) => {
                console.log(`text: ${e.target.value}`)
                setChapterEpisode(e.target.value)
              }}
            />
          </div>
          <div style={{ paddingTop: 12 }}>
            무료여부
            <input
              type='checkbox'
              checked={isFree}
              onChange={(e) => {
                console.log(`text: ${e.target.checked}`)
                setIsFree(e.target.checked)
              }}
            />
          </div>
          <div style={{ paddingTop: 12 }}>
            <div>연재화 썸네일 url</div>
            <input
              type='text'
              value={detailThumbnail}
              onChange={(e) => {
                console.log(`text: ${e.target.value}`)
                setDetailThumbnail(e.target.value)
              }}
            />
          </div>
          <div style={{ paddingTop: 12 }}>
            <div> 작가의말:</div>
            <textarea
              value={authorComment}
              onChange={(e) => setAuthorComment(e.target.value)}
              style={{ width: 400, height: 100 }}
            />
          </div>
          <div style={{ paddingTop: 20 }}>
            <div>
              공개일시:
              <div>
                <input
                  type='datetime-local'
                  value={publishedAt}
                  onChange={(e) => {
                    console.log(`text: ${e.target.value}`)
                    setPublishedAt(e.target.value)
                  }}
                />
              </div>
            </div>
          </div>
          <div style={{ paddingTop: 20 }}>
            <div>
              내용 이미지 url(ex: ["url1", "url2"])
              <div>
                <textarea
                  value={contents}
                  onChange={(e) => setContents(e.target.value)}
                  style={{ width: 400, height: 100 }}
                />
              </div>
            </div>
          </div>
        </div>

        <div style={{ paddingTop: 20 }}>
          <button
            type='button'
            className='mt-10 px-4 py-2 bg-gray-500 text-white rounded-xl'
            onClick={async () => {
              if (
                !toonId ||
                !chapterTitle ||
                !detailThumbnail ||
                !authorComment ||
                !publishedAt ||
                !contents
              ) {
                return
              }

              const result = await altoonCMSBackendApis.registerToonChapter({
                toonId,
                chapterTitle,
                detailThumbnail,
                publishedAt: new Date(publishedAt),
                contents,
                isFree,
                authorComment,
                chapterEpisode,
              })
              if (result?.status === 200) {
                alert('등록되었습니다.')
                // setToonId('')
                // setChapterTitle('')
                // setDetailThumbnail('')
                // setPublishedAt('')
                // setContents('')
                // setIsFree(false)
                // setAuthorComment('')
              } else {
                alert(
                  '오류가 발생하였습니다. 오기입 위험이 있으니 DB를 확인해주세요.',
                )
              }
            }}
          >
            연재화 등록하기
          </button>
        </div>
      </div>
    )
  }

  const TicketIssueSection = () => {
    const [userId, setUserId] = useState('')
    const [coinCount, setCoinCount] = useState('')

    return (
      <div className='p-4 bg-white rounded-xl'>
        <div className='py-4 font-bold text-[2.6vh]'>코인 지급</div>

        <div>
          userId
          <div>
            <input
              type='text'
              value={userId}
              onChange={(e) => {
                console.log(`text: ${e.target.value}`)
                setUserId(e.target.value)
              }}
            />
          </div>
        </div>
        <div style={{ paddingTop: 12 }}>
          <div>
            coinCount
            <div>
              <input
                type='text'
                value={coinCount}
                onChange={(e) => {
                  console.log(`text: ${e.target.value}`)
                  setCoinCount(e.target.value)
                }}
              />
            </div>
          </div>
        </div>
        <div style={{ paddingTop: 20 }}>
          <button
            type='button'
            className='mt-10 px-4 py-2 bg-gray-500 text-white rounded-xl'
            onClick={async () => {
              if (!userId || !coinCount) {
                return
              }

              const result = await altoonCMSBackendApis.addToonCoin({
                userId,
                coinCount,
              })
              if (result?.status === 200) {
                alert('코인이 지급되었습니다.')
                setUserId('')
                setCoinCount('')
              } else {
                alert(
                  '오류가 발생하였습니다. 오기입 위험이 있으니 DB를 확인해주세요.',
                )
              }
            }}
          >
            코인 지급하기
          </button>
        </div>
      </div>
    )
  }

  return (
    <div className='w-full my-10 min-w-7xl max-w-10xl select-text'>
      <div className='h-4' />
      <RegisteredToonList />

      <div className='h-4' />
      <div className='p-4 bg-white py-4 flexCol items-start justify-center rounded-xl'>
        <div className='py-4 font-bold text-[2.6vh]'>회차 목록</div>
        <ToonSearchSection />
        <RegisteredChapterList />
      </div>

      <div className='h-4' />
      <UpdateToonInfo />

      <div className='h-4' />
      <UpdateChapterInfo />

      <div className='h-4' />
      <ToonRegisterSection />

      <div className='h-4' />
      <ChapterRegisterSection />

      <div className='h-4' />
      <TicketIssueSection />
    </div>
  )
})

export default ToonAdminScreen
