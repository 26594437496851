import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'

import AuthStore from '../../../stores/AuthStore'
import backendApis from '../../../utils/backendApis'
import SpeedGifticonItemComponent from '../item/speedGifticonItemComponent'

const speedGifticonItemSection = () => {
  const [gifticonInfo, setGifticonInfo] = useState([])

  const fetchItems = async () => {
    const itemsInfoList = await backendApis.getSpeedGifticonItems()
    setGifticonInfo(itemsInfoList?.data)
  }

  useEffect(() => {
    if (AuthStore.token) {
      fetchItems()
    }
  }, [AuthStore.token])

  if (gifticonInfo?.length === 0 || !gifticonInfo) return

  return (
    <div className='bg-white'>
      {gifticonInfo?.length > 0 && (
        <div className='px-4 py-2 w-full'>
          <div className='pl-2 pb-4 flex justify-start font-bold text-[4vw] text-[#9898A3] text-start'>
            매일 선착순으로 열리는 초특가 기프티콘
          </div>

          {gifticonInfo?.map((item) => (
            <SpeedGifticonItemComponent key={item?._id} itemInfo={item} />
          ))}
        </div>
      )}
    </div>
  )
}
export default observer(speedGifticonItemSection)
