import commaNumber from 'comma-number'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import Iframe from 'react-iframe'
import { useInterval, useSearchParam } from 'react-use'

import TreasureHuntAction from '../../actions/TreasureHuntAction'
import WatermelonAction from '../../actions/WatermelonAction'
import TreasureHuntButton from '../../components/treasureHunt/TreasureHuntButton'
import TreasureHuntTimer from '../../components/treasureHunt/TreasureHuntTimer'
import HeaderComponent from '../../components/treasureHunt/molecules/HeaderComponent'
import AuthStore from '../../stores/AuthStore'
import RouteStore from '../../stores/RouteStore'
import ToastStore from '../../stores/ToastStore'
import WatermelonUserStore from '../../stores/WatermelonUserStore'
import ABStore from '../../stores/treasureHunt/ABStore'
import THBottomSheetStore from '../../stores/treasureHunt/THBottomSheetStore'
import THModalStore from '../../stores/treasureHunt/THModalStore'
import THUserStore from '../../stores/treasureHunt/THUserStore'
import backendApis from '../../utils/backendApis'
import { codepushVersionCheck } from '../../utils/utility'

let visibilityChange
if (typeof document.hidden !== 'undefined') {
  // Opera 12.10 and Firefox 18 and later support
  visibilityChange = 'visibilitychange'
} else if (typeof document.msHidden !== 'undefined') {
  visibilityChange = 'msvisibilitychange'
} else if (typeof document.webkitHidden !== 'undefined') {
  visibilityChange = 'webkitvisibilitychange'
}

const modeForGame = {
  type: 'teamgame',
  backgroundImage: `url(/watermelonAssets/background/teamgame.png)`,
  backgroundColor: '#FFE0A5',
}

const typeList = ['teamgame']
const modeList = ['teamgame']
const typeInfo = {
  teamgame: {
    backgroundColor: '#FFE0A5',
    fixedAvailble: false,
    map: 'map7',
  },
}

const WatermelonTeamGame = observer(() => {
  const [status, setStatus] = useState('lobby')
  const token = useSearchParam('token')
  const [buttonText, setButtonText] = useState('도전시작')
  const [readyButtonText, setReadyButtonText] = useState('준비완료')
  const [mode, setMode] = useState({
    type: 'teamgame',
    backgroundImage: `url(/watermelonAssets/background/teamgame.png)`,
    backgroundColor: '#FFE0A5',
    map: 'map7',
  })
  const [canStartGame, setCanStartGame] = useState(false)
  const [modeIndex, setModeIndex] = useState(0)
  const [typeIndex, setTypeIndex] = useState(0)
  const [members, setMembers] = useState([])
  const [isLeader, setIsLeader] = useState(false)

  const childRef = useRef()

  useEffect(() => {
    if (WatermelonUserStore?.gameMode === 'timer10') {
      WatermelonUserStore.set('isTimerGame', true)
    } else {
      WatermelonUserStore.set('isTimerGame', false)
    }
  }, [WatermelonUserStore?.gameMode])

  const onPressArrow = ({ type, dir }) => {
    if (type === 'type') {
      const i = (typeIndex + dir + typeList.length) % typeList.length
      setTypeIndex(i)
      WatermelonUserStore.set('gameType', typeList?.[i])
    } else if (type === 'mode') {
      const i = (modeIndex + dir + modeList.length) % modeList.length
      setModeIndex(i)
      WatermelonUserStore.set('gameMode', modeList?.[i])
    }
  }
  useEffect(() => {
    onPressArrow({ type: 'type', dir: 0 })
  }, [])

  useInterval(
    () => {
      WatermelonAction.fetchTeamgame()
    },
    status === 'playing' ? null : 5000,
  )

  useEffect(() => {
    setMembers(WatermelonUserStore?.userData?.teamgame?.members ?? [])
  }, [WatermelonUserStore?.userData?.teamgame?.members])

  useEffect(() => {
    if (
      WatermelonUserStore?.userData?.teamgame?.leaderId?.toString() ===
      WatermelonUserStore?.userData?.userId?.toString()
    ) {
      setIsLeader(true)
    } else {
      setIsLeader(false)
    }
  }, [WatermelonUserStore?.userData?.teamgame])

  useEffect(() => {
    if (
      WatermelonUserStore?.userData?.teamgame?.members?.filter(
        (member) => member.isReady,
      )?.length >= 2
    ) {
      setButtonText('도전시작')
    } else if (
      moment().isBefore(
        moment(
          WatermelonUserStore?.userData?.teamgame?.timerEndAt ?? '2023-01-01',
        ),
      )
    ) {
      setButtonText('도전 진행중')
    } else {
      setButtonText('팀원 모두가 준비해야해요 ')
    }
    if (
      moment().isAfter(
        moment('2023-12-03').startOf('day').add(11, 'hours').add(30, 'minutes'),
      )
    ) {
      setButtonText('이벤트가 종료되었어요')
      setReadyButtonText('이벤트가 종료되었어요')
    }
  }, [
    WatermelonUserStore?.userData?.teamgame?.members,
    WatermelonUserStore?.userData?.teamgame?.timerEndAt,
  ])

  useEffect(() => {
    setMode({
      type: WatermelonUserStore?.gameType,
      backgroundImage: `url(/watermelonAssets/background/${WatermelonUserStore?.gameType}.png)`,
      backgroundColor:
        typeInfo?.[WatermelonUserStore?.gameType]?.backgroundColor,
      map: typeInfo?.[WatermelonUserStore?.gameType]?.map,
    })
    modeForGame.type = WatermelonUserStore?.gameType
    modeForGame.backgroundColor =
      typeInfo?.[WatermelonUserStore?.gameType]?.backgroundColor
    WatermelonUserStore.set(
      'backgroundColor',
      typeInfo?.[WatermelonUserStore?.gameType]?.backgroundColor,
    )
    // if (typeInfo?.[WatermelonUserStore?.gameType]?.fixedAvailble) {
    //   setCanStartGame(WatermelonUserStore?.userData?.ticket > 0)
    // } else {
    setCanStartGame(WatermelonUserStore?.userData?.ticket > 0)
    // }
  }, [WatermelonUserStore?.gameType, WatermelonUserStore?.userData?.ticket])

  useEffect(() => {
    if (process.env.NODE_ENV === 'development' && token) {
      WatermelonAction.initGame({ data: { token, codepushVersion: '4.3.31' } })
    }
  }, [token])

  useEffect(() => {
    document.addEventListener(
      visibilityChange,
      (visibility) => {
        if (document.visibilityState === 'visible') {
          setTimeout(() => {
            if (!WatermelonUserStore.isPlaying) {
              fetchData()
            }
          }, 500)
        }
      },
      false,
    )
  }, [])

  useEffect(() => {
    if (status === 'playing') {
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({
          type: 'allowiPhoneGesture',
          boolean: false,
        }),
      )
    } else {
      window.ReactNativeWebView?.postMessage(
        JSON.stringify({
          type: 'allowiPhoneGesture',
          boolean: true,
        }),
      )
    }
  }, [status])

  useEffect(() => {
    if (
      !Number.isNaN(WatermelonUserStore?.userData?.highscore) &&
      WatermelonUserStore?.userData?.highscore >
        (WatermelonUserStore?.userData?.allTimeHighscore ?? 0)
    ) {
      backendApis.setAllTimeHighscore({
        allTimeHighscore: Number(WatermelonUserStore?.userData?.highscore),
      })
      WatermelonUserStore.set(
        'userData.allTimeHighscore',
        WatermelonUserStore?.userData?.highscore,
      )
    }
  }, [WatermelonUserStore?.userData?.highscore])

  useEffect(() => {
    if (!WatermelonUserStore?.isLoading) {
      const handleMessage = async (e) => {
        // e.data가 전달받은 메시지
        if (e.data?.status === 'gameOver') {
          WatermelonUserStore.set('isFixedGame', false)
          WatermelonUserStore.set('hideCloseButton', false)
          WatermelonUserStore.set('isPlaying', false)
          setStatus('gameOver')
          const score = Number(e?.data?.score)
          if (WatermelonUserStore?.userData?.highscore < score) {
            WatermelonUserStore.set('userData.highscore', score)
            backendApis.setHighscore({
              highscore: Number(score),
            })
          }

          if (WatermelonUserStore?.userData?.allTimeHighscore < score) {
            WatermelonUserStore.set('userData.allTimeHighscore', score)
            backendApis.setAllTimeHighscore({
              allTimeHighscore: Number(score),
            })
          }
          let type = '치킨'
          if (WatermelonUserStore.gameType === 'teamgame') {
            type = '치킨'
          }

          if (e.data?.reachedFinal) {
            WatermelonUserStore.set('userData.isBonusNewbie', false)
            THModalStore.setType({
              type: 'reward',
              config: {
                rewardName: `${type} 도달 성공!`,
                src: `../watermelonAssets/smile.png`,
                description: `내 점수: ${commaNumber(
                  score,
                )}점\n\n팀원들의 결과를 기다려주세요!`,
                forceOpen: true,
                text: '확인',
              },
            })
          } else {
            THModalStore.setType({
              type: 'reward',
              config: {
                rewardName: `${type} 도달 실패`,
                src: `../watermelonAssets/sad.png`,
                description: `내 점수: ${commaNumber(
                  score,
                )}점\n\n너무 아까웠어요..\n다시 도전해보세요!`,
                forceOpen: true,
              },
            })
          }

          if (WatermelonUserStore?.userData?.ticket < 1) {
            THModalStore.setType({
              type: 'reward',
              config: {
                rewardName: `공유하기`,
                src: `../watermelonAssets/smile.png`,
                description:
                  '친구에게 양파게임을 알려주고\n도전권을 받아보세요!',
                text: '공유하기',
                onClick: () => {
                  backendApis.commonInsert({
                    data: {
                      type: 'clickShareButton',
                      gameType: WatermelonUserStore?.gameType,
                    },
                    collection: 'watermelon_clickLog',
                  })
                  const imageUrl =
                    'https://assets.ilevit.com/e9741bf3-54c4-4fef-aa5c-3c80292086e1.png'
                  const title = '[양파게임] 무료 양파 1Kg에 도전하세요!'
                  const description =
                    '작물을 합쳐서 양파를 만들어내면 양파 1Kg가 무료!'
                  const templateId = 100565

                  WatermelonAction.kakaoLinkShare({
                    shareType: 'Watermelon_LinkShare',
                    title,
                    description,
                    imageUrl,
                    payload: {},
                    templateId,
                  })
                },
              },
            })
          }
        } else if (e.data?.status === 'reachedFinal') {
          const result = await backendApis.checkTeamgameClear()
          let type = '치킨'
          if (WatermelonUserStore.gameType === 'teamgame') {
            type = '치킨'
          }
          if (result?.status === 200) {
            ToastStore.toastOn({
              type: 'emoji',
              message: `${type} 만들기 성공! 🔥`,
              duration: 2000,
              emoji: '🎉',
            })
          } else if (result?.status === 202) {
            THModalStore.setType({
              type: 'reward',
              config: {
                rewardName: `도전 실패`,
                src: `../watermelonAssets/sad.png`,
                description: `도전시간이 지나버렸어요..\n다시 도전해보세요!`,
                forceOpen: true,
                text: '확인',
              },
            })
          } else if (result?.status === 203) {
            THModalStore.setType({
              type: 'reward',
              config: {
                rewardName: `도전 실패`,
                src: `../watermelonAssets/sad.png`,
                description: `팀게임이 끝났어요..\n다시 도전해보세요!`,
                forceOpen: true,
                text: '확인',
              },
            })
          } else {
            ToastStore.networkError()
          }
          childRef.current = document.getElementById('child') // iframe
          if (childRef.current) {
            childRef.current.contentWindow.postMessage(
              {
                msg: 'gameOver',
              },
              '*',
            )
          }
        } else if (e.data?.status === 'gameInitSuccess') {
          childRef.current = document.getElementById('child') // iframe
          if (childRef.current) {
            childRef.current.contentWindow.postMessage(
              {
                msg: 'startClicked',
                mode: modeForGame.type,
                gameMode: WatermelonUserStore?.gameMode,
                backgroundColor: modeForGame.backgroundColor,
                isFirstGame: modeForGame.isFirstGame,
                isFixedGame: WatermelonUserStore?.isFixedGame,
                isBonuseNewbie: WatermelonUserStore?.userData?.isBonusNewbie,
                isTeamGame: WatermelonUserStore?.isTeamGame,
              },
              '*',
            )
          }
        } else if (e.data?.status === 'hideCloseButton') {
          WatermelonUserStore.set('hideCloseButton', true)
        }
      }
      window.addEventListener('message', handleMessage)

      return () => {
        window.removeEventListener('message', handleMessage)
      }
    }
  }, [WatermelonUserStore?.isLoading])

  const fetchData = async () => {
    const result = await backendApis.getWatermelonGameData({ routeParams: {} })
    if (result?.status === 200) {
      if (result?.data?.initModalList) {
        for (const init of result?.data?.initModalList) {
          THModalStore.setType({
            type: init?.type,
            config: { ...init?.config, forceOpen: true },
          })
        }
        backendApis.commonSet({
          set: {
            initModalList: [],
          },
          collection: 'watermelon_user',
        })
      }
      WatermelonUserStore.set('userData', result?.data)
    } else {
      ToastStore.networkError()
    }
  }
  const startGame = async (params) => {
    WatermelonUserStore.set('isNewbie', false)
    WatermelonUserStore.set('isTeamGame', true)
    WatermelonUserStore.set('gameMode', 'teamgame')
    const result = await backendApis.watermelonStartGame({
      type: modeForGame.type,
      gameMode: WatermelonUserStore?.gameMode,
    })
    if (result?.status === 200) {
      setStatus('playing')
      WatermelonUserStore.set('isPlaying', true)
      WatermelonUserStore.increase('userData.ticket', -1)
    } else if (result?.status === 202) {
      ToastStore.networkError(202)
    } else if (result?.status === 201) {
      ToastStore.toastOn({
        type: 'error',
        message: '도전권이 부족해요!',
        duration: 3000,
      })
    } else {
      ToastStore.networkError()
    }
  }

  const InviteButton = observer(({ userData = null }) => {
    if (!userData) {
      return (
        <div
          className='flex p-2 px-4 bg-[#FFEFD1] rounded-2xl relative justify-center items-center flex-col'
          onClick={() => {
            // 초대 링크 쉐어
            if (isLeader || !WatermelonUserStore?.userData?.teamgame) {
              TreasureHuntAction.linkShare({
                shareType: 'Watermelon_TeamGame_Invite',
                title: '[양파게임] 3인 치킨 팀에 초대해요!',
                description:
                  '팀원 모두가 치킨을 만들어내면 전원 교촌치킨 1마리씩 드려요!',
                imageUrl:
                  'https://assets.ilevit.com/0119dc75-47a0-4fe3-892f-4a158d7f0114.png',
                payload: {},
              })
            }
          }}
        >
          <img
            className='w-[13vw] h-[13vw]'
            alt=''
            src='../watermelonAssets/plus.png'
          />

          <div className='flex flex-row w-full justify-center items-center px-2 text-[4.5vw] text-[#313138] font-bold text-center'>
            팀원
          </div>
        </div>
      )
    }

    return (
      <div className='flex p-2 px-4 bg-[#FFEFD1] rounded-2xl relative justify-center items-center flex-col'>
        <img
          className='w-[13vw] h-[13vw] rounded-full'
          alt='profile'
          src={userData?.userImageUri ?? WatermelonUserStore?.DEFAULT_IMAGE}
          onError={(e) => {
            e.target.src = WatermelonUserStore?.DEFAULT_IMAGE
          }}
        />
        {WatermelonUserStore?.userData?.teamgame?.leaderId?.toString() ===
          userData?.userId?.toString() &&
          moment().isAfter(
            moment(
              WatermelonUserStore.userData?.teamgame?.timerEndAt ??
                '2023-01-01',
            ),
          ) && (
            <div className='text-[3vw] mt-2 font-bold text-[#E7807B]'>팀장</div>
          )}
        {WatermelonUserStore?.userData?.teamgame?.leaderId?.toString() !==
          userData?.userId?.toString() &&
          moment().isAfter(
            moment(
              WatermelonUserStore.userData?.teamgame?.timerEndAt ??
                '2023-01-01',
            ),
          ) && (
            <div className='text-[3vw] mt-2 font-bold text-[#E7807B]'>
              {userData?.isReady ? '준비 완료' : '준비 안함'}
            </div>
          )}
        {moment().isBefore(
          moment(
            WatermelonUserStore.userData?.teamgame?.timerEndAt ?? '2023-01-01',
          ),
        ) && (
          <div className='text-[3vw] mt-2 font-bold text-[#E7807B]'>
            {userData?.isSuccess ? '성공!' : '진행중'}
          </div>
        )}
        {userData?.isSuccess && (
          <div className='text-[3vw] mt-2 font-bold text-[#fff] absolute -top-4 bg-[#E7807B] rounded-full px-2 py-1'>
            치킨 성공!
          </div>
        )}
        <div className='flex flex-row w-full justify-center items-center px-2 text-[3vw] text-[#313138] font-bold text-center'>
          {userData?.userName ?? '친구'}
        </div>
      </div>
    )
  })

  if (WatermelonUserStore?.isLoading) {
    return (
      <div className='loadingPage flex flex-col jua'>
        <div className='slowRotateCenter pigNose'>🧅</div>
        <div>로딩 중...</div>
      </div>
    )
  }

  return (
    <div
      id='watermelon'
      className='fixed'
      style={{
        width: '100vw',
        height: '100vh',
        backgroundImage: mode.backgroundImage,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        fontFamily: 'maplestory',
        backgroundColor: mode.backgroundColor,
      }}
    >
      {(status === 'lobby' || status === 'gameOver') && (
        <>
          <HeaderComponent
            title=''
            hideButtons
            backButtonOnClick={() => {
              window.location.href = `/watermelon?token=${AuthStore?.token}`
            }}
            watermelonTicketOn
            watermelonTicket={WatermelonUserStore?.userData?.ticket}
          />
          <TreasureHuntButton
            style={{
              position: 'absolute',
              // width: '20vw',
              padding: '1vw 2vw',
              top: '14vw',
              right: '4vw',
              fontSize: '3vw',
              backgroundColor: 'white',
              color: '#FF5951',
            }}
            text='규칙 설명'
            clickable
            onClick={() => {
              THModalStore.setType({
                type: 'watermelonRule',
                config: {
                  forceOpen: true,
                },
              })
            }}
          />
        </>
      )}
      {status !== 'playing' && (
        <div className='absolute p-4 w-full bottom-0 flex flex-col space-y-4 '>
          <div className='w-full flex flex-row justify-center items-center space-x-8 z-[100] bg-white rounded-xl border-2 py-4 border-[#B52E00]'>
            <InviteButton userData={members?.[0]} />
            <InviteButton userData={members?.[1]} />
            <InviteButton userData={members?.[2]} />
          </div>
          <div className='px-4 text-left text-[#313138] text-[3.5vw]'>
            {moment().isBefore(
              moment(
                WatermelonUserStore?.userData?.teamgame?.timerEndAt ??
                  '2023-01-01',
              ),
            ) ? (
              <div className='flex flex-row text-[#313138] font-bold w-full justify-center items-center'>
                제한시간:
                <TreasureHuntTimer
                  style={{
                    marginLeft: '8px',
                    color: '#313138',
                  }}
                  format='number'
                  expiryTimestamp={moment(
                    WatermelonUserStore?.userData?.teamgame?.timerEndAt,
                  )?.toDate()}
                  onExpire={() => {
                    // 팀게임 종료 처리
                    WatermelonAction.endTeamGame()
                  }}
                />
              </div>
            ) : (
              <div className='flex flex-col px-[12vw]'>
                <div className='font-bold text-center mb-2'>참여 방법</div>
                <div className='flex flex-row'>
                  <div className='w-[4vw] whitespace-nowrap'>1.</div>
                  <div className='flex-1'>팀원 모두 준비 완료하기</div>
                </div>
                <div className='flex flex-row'>
                  <div className='w-[4vw] whitespace-nowrap'>2.</div>
                  <div className='flex-1'>
                    도전시작 후 20분안에 모두 치킨 만들기
                  </div>
                </div>
              </div>
            )}
          </div>

          {WatermelonUserStore.userData?.teamgame &&
            moment().isAfter(
              moment(
                WatermelonUserStore.userData?.teamgame?.timerEndAt ??
                  '2023-01-01',
              ),
            ) && (
              <>
                {isLeader ? (
                  <TreasureHuntButton
                    style={{
                      width: '100%',
                      padding: '4vw 4vw',
                    }}
                    text={buttonText}
                    clickable={
                      WatermelonUserStore?.userData?.teamgame?.members?.filter(
                        (m) => m.isReady,
                      )?.length >= 2 &&
                      moment().isAfter(
                        moment(
                          WatermelonUserStore?.userData?.teamgame?.timerEndAt ??
                            '2023-01-01',
                        ),
                      ) &&
                      moment().isBefore(
                        moment('2023-12-03')
                          .startOf('day')
                          .add(11, 'hours')
                          .add(30, 'minutes'),
                      )
                    }
                    onClick={async () => {
                      WatermelonAction.startTeamGame()
                    }}
                  />
                ) : (
                  <TreasureHuntButton
                    style={{
                      width: '100%',
                      padding: '4vw 4vw',
                    }}
                    text={readyButtonText}
                    clickable={
                      !WatermelonUserStore?.userData?.teamgameUser?.isReady &&
                      moment().isBefore(
                        moment('2023-12-03')
                          .startOf('day')
                          .add(11, 'hours')
                          .add(30, 'minutes'),
                      )
                    }
                    onClick={async () => {
                      const result = await backendApis.commonSet({
                        set: {
                          isReady: true,
                        },
                        collection: 'watermelon_teamgame_user',
                      })
                      if (result?.status === 200) {
                        WatermelonUserStore.set(
                          'userData.teamgameUser.isReady',
                          true,
                        )
                      } else {
                        ToastStore.networkError()
                      }
                    }}
                  />
                )}
              </>
            )}

          <TreasureHuntButton
            style={{
              width: '100%',
              padding: '4vw 4vw',
            }}
            text='게임시작'
            clickable={moment().isBefore(
              moment(
                WatermelonUserStore?.userData?.teamgame?.timerEndAt ??
                  '2023-01-01',
              ),
            )}
            onClick={async () => {
              //  start team game
              startGame()
            }}
          />
          {moment().isBefore(
            moment(
              WatermelonUserStore?.userData?.teamgame?.timerEndAt ??
                '2023-01-01',
            ),
          ) && (
            <TreasureHuntButton
              style={{
                width: '100%',
                padding: '4vw 4vw',
              }}
              text='공유하고 도전권 받기'
              clickable={WatermelonUserStore?.showKakaoShare}
              onClick={() => {
                backendApis.commonInsert({
                  data: {
                    type: 'clickShareButton',
                    gameType: WatermelonUserStore?.gameType,
                  },
                  collection: 'watermelon_clickLog',
                })
                let imageUrl =
                  'https://assets.ilevit.com/e9741bf3-54c4-4fef-aa5c-3c80292086e1.png'
                let title = '[양파게임] 무료 양파 1Kg에 도전하세요!'
                let description =
                  '작물을 합쳐서 양파를 만들어내면 양파 1Kg가 무료!'
                let templateId = 100565
                if (WatermelonUserStore?.gameType === 'iphone') {
                  imageUrl =
                    'https://assets.ilevit.com/dd3f1c71-d82a-4d8b-84d6-1466b8a03870.png'
                  title = '[양파게임] 화제의 양파게임 아이폰 이벤트 중!'
                  description = '아이폰 도달시 아이폰 15 Pro Max를 드려요!'
                  templateId = 100888
                }
                WatermelonAction.kakaoLinkShare({
                  shareType: 'Watermelon_LinkShare',
                  title,
                  description,
                  imageUrl,
                  payload: {},
                  templateId,
                })
              }}
            />
          )}
          <TreasureHuntButton
            style={{
              width: '100%',
              padding: '4vw 4vw',
            }}
            text='팀 탈퇴하기'
            clickable={
              WatermelonUserStore?.userData?.teamgame &&
              moment().isAfter(
                moment(
                  WatermelonUserStore?.userData?.teamgame?.timerEndAt ??
                    '2023-01-01',
                ),
              )
            }
            onClick={() => {
              // 팀탈퇴
              if (isLeader) {
                THModalStore?.setType({
                  type: 'confirm',
                  config: {
                    forceOpen: true,
                    title: '3인팀 탈퇴',
                    src: '../watermelonAssets/sad.png',
                    description: `팀장이 팀을 탈퇴하면 팀이 사라져요\n정말 팀을 탈퇴하시겠어요?`,
                    buttonText: '탈퇴하기',
                    onConfirm: async () => {
                      // setStatus('lobby')
                      WatermelonAction.exitTeamGame({ isLeader })
                    },
                    clickable: true,
                  },
                })
              } else {
                THModalStore?.setType({
                  type: 'confirm',
                  config: {
                    forceOpen: true,
                    title: '3인팀 탈퇴',
                    src: '../watermelonAssets/sad.png',
                    description: `정말 팀을 탈퇴하시겠어요?`,
                    buttonText: '탈퇴하기',
                    onConfirm: async () => {
                      // setStatus('lobby')
                      WatermelonAction.exitTeamGame({ isLeader })
                    },
                    clickable: true,
                  },
                })
              }
            }}
          />
        </div>
      )}

      {status === 'playing' && (
        <div className='relative'>
          <div className='absolute top-[24vw] left-[4vw] z-[100] pointer-events-none font-bold whitespace-nowrap flex flex-row justify-center items-center text-center text-[#313138]'>
            모드: 3인팀치킨
          </div>
          <div className='absolute top-[24vw] right-[4vw] z-[100] pointer-events-none font-bold whitespace-nowrap flex flex-row justify-center items-center text-center text-[#313138]'>
            제한시간:
            <TreasureHuntTimer
              style={{
                marginLeft: '8px',
                color: '#313138',
              }}
              format='number'
              expiryTimestamp={moment(
                WatermelonUserStore?.userData?.teamgame?.timerEndAt,
              )?.toDate()}
              onExpire={() => {
                // 게임 종료 처리
                childRef.current = document.getElementById('child') // iframe
                if (childRef.current) {
                  childRef.current.contentWindow.postMessage(
                    {
                      msg: 'gameOver',
                    },
                    '*',
                  )
                }
              }}
            />
          </div>

          <div className='absolute z-[10] top-[155vw] px-2 pointer-events-none'>
            <img
              className='w-full'
              src={`../watermelonAssets/${mode.map}.png`}
              alt='map'
            />
          </div>

          {!WatermelonUserStore?.hideCloseButton && (
            <div
              className='absolute right-4 top-4 z-[10]'
              onClick={() => {
                THModalStore?.setType({
                  type: 'confirm',
                  config: {
                    forceOpen: true,
                    title: '게임 종료',
                    src: '../watermelonAssets/smile.png',
                    description: `진행상황이 사라져요\n정말 그만두시겠어요?`,
                    buttonText: '그만하기',
                    onConfirm: async () => {
                      // setStatus('lobby')
                      childRef.current = document.getElementById('child') // iframe
                      if (childRef.current) {
                        childRef.current.contentWindow.postMessage(
                          {
                            msg: 'gameOver',
                          },
                          '*',
                        )
                      }
                    },
                    clickable: true,
                  },
                })
              }}
            >
              <img
                className='w-8 h-8'
                src='../watermelonAssets/close.png'
                alt='map'
                style={
                  {
                    // bottom: `${groundPosition}px`,
                  }
                }
              />
            </div>
          )}
          <Iframe
            styles={{ border: 0, margin: 0, overflow: 'hidden' }}
            frameBorder={0}
            url='/Watermelon.html'
            width='100%'
            height='100%'
            id='child'
            display='flex'
            position='fixed'
            allowFullScreen
          />
        </div>
      )}
      <div className='z-[-10] absolute -bottom-4 w-full h-[30vw] bg-white' />
    </div>
  )
})

export default WatermelonTeamGame
