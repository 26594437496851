import commaNumber from 'comma-number'
import moment from 'moment'
import React, { useState } from 'react'
import { toast } from 'react-hot-toast'

import { CouponEnvelopeRed, CouponEnvelopeYellow } from '../images/dailyCheckIn'
import UserStore from '../stores/UserStore'
import backendApis from '../utils/backendApis'
import ConfirmAlert from './atoms/confirmAlert'
import useDebounce from './useDebounce'

const CheckInCouponBottomSheetItem = ({
  key,
  value,
  couponType = 'won',
  condition,
  conditionType,
  receivedCoupon,
  lastReceivedAt,
  checkInAlarmConsent,
  onButtonClick,
}) => {
  const [openNoticeModal, setOpenNoticeModal] = useState(false)
  const debounce = useDebounce()
  const triggerAlertToast = () =>
    toast(
      `${
        receivedCoupon === true
          ? '이미 받은 쿠폰이에요. \n 다른 쿠폰을 받아보세요!'
          : '쿠폰은 하루에 한번만 받을 수 있어요. \n 내일 다시 받아보세요!'
      }`,
      {
        id: 'coupon alert',
        duration: 5000,
        position: 'top-center',
        style: {
          paddingLeft: 8,
          paddingTop: 20,
          paddingBottom: 20,
          fontWeight: 'bold',
        },
        icon: '📨',
      },
    )
  const triggerAlarmConfirmToast = () =>
    toast(
      `${'내일 출석체크를 하실 수 있도록 알려드릴게요. \n 알림설정을 확인해주세요!'}`,
      {
        id: 'alarm alert',
        duration: 5000,
        position: 'top-center',
        style: {
          paddingLeft: 8,
          paddingTop: 20,
          paddingBottom: 20,
          fontWeight: 'bold',
        },
        icon: '🛎️',
      },
    )

  return (
    <div className='pt-4 mx-1 my-1 relative flex flex-col items-center w-1/3 h-28'>
      <div className='text-xl font-bold'>
        {commaNumber(value)}
        {couponType === 'won' ? '원' : '%'}
      </div>
      <div className='text-xs text-gray-500'>
        {condition > 0 ? commaNumber(condition) : '조건 없음'}
        {conditionType === 'minimumPrice' ? '원 이상 구매' : ''}
      </div>
      <button
        type='button'
        className='w-full h-full mt-4 rounded-lg justify-center text-white text-sm font-semibold'
        onClick={async () => {
          if (receivedCoupon) {
            triggerAlertToast()
            return
          }
          if (
            !!lastReceivedAt &&
            moment(lastReceivedAt).isSame(moment(), 'day')
          ) {
            if (checkInAlarmConsent === false) {
              setOpenNoticeModal(true)
            } else {
              triggerAlertToast()
            }
            return
          }
          debounce(onButtonClick, 500)
        }}
      >
        {receivedCoupon === true ? '받기 완료' : '쿠폰 받기'}
      </button>
      {receivedCoupon === false && (
        <CouponEnvelopeYellow className='absolute left-0 top-0 -z-10 w-full h-full' />
      )}
      {receivedCoupon === true && (
        <CouponEnvelopeRed className='absolute left-0 top-0 -z-10 w-full h-full' />
      )}
      {openNoticeModal && (
        <ConfirmAlert
          modalTitle='안내'
          modalContent={`쿠폰은 하루에 한번만 받을 수 있어요. \n 내일 알려드릴까요?`}
          buttonText='네, 알려주세요'
          buttonLink={async () => {
            await backendApis.recordEventLog(
              'checkInCouponPack',
              'pressAlarmConfirmButton',
            )
            await UserStore.setCheckInNotificationSettings(true, 10)
            setOpenNoticeModal(false)
            triggerAlarmConfirmToast()
          }}
          showModal
          setShowModal={setOpenNoticeModal}
        />
      )}
    </div>
  )
}
export default CheckInCouponBottomSheetItem
