import ApplePangAction from '../actions/ApplePangAction'
import TreasureHuntAction from '../actions/TreasureHuntAction'
import WatermelonAction from '../actions/WatermelonAction'
import AuthStore from '../stores/AuthStore'
import GroupPurchaseStore from '../stores/GroupPurchaseStore'
import ItemImpressionStore from '../stores/ItemImpressionStore'
import RouteStore from '../stores/RouteStore'
import ToastStore from '../stores/ToastStore'
import UserStore from '../stores/UserStore'
import ABStore from '../stores/treasureHunt/ABStore'
import THBottomSheetStore from '../stores/treasureHunt/THBottomSheetStore'
import THModalStore from '../stores/treasureHunt/THModalStore'
import THUserStore from '../stores/treasureHunt/THUserStore'
import backHandler from './backHandler'
import useCheckCodePushVersion from './useConvertCodePushVersion'
import { sendMessage } from './utils'

const nativeMessageListener = async (event) => {
  const codePushVersionAvailable = useCheckCodePushVersion()

  if (event?.data) {
    try {
      const message =
        typeof event?.data === 'string' ? JSON.parse(event?.data) : event?.data

      if (message?.type === 'userInitialSetting') {
        // setCodepushVersion(message?.data?.codepushVersion)
        // setAppVersion(message?.data?.appVersion)
        UserStore.setUserInfo({
          _id: message?.data?._id,
          userName: message?.data?.userName,
          codepushVersion: message?.data?.codepushVersion,
          appVersion: message?.data?.appVersion,
          needsLogin: message?.data?.needsLogin,
          userImageUri: message?.data?.userImageUri,
        })
        AuthStore.setCodePushVersion(message?.data?.codepushVersion)
        AuthStore.setToken(message?.data?.token)
      } else if (message?.type === 'THAuthorizeResult') {
        if (message?.data?.gpsData?.errorCode >= 0) {
          THModalStore.setType({
            type: 'reward',
            config: {
              rewardName: '위치 권한을 허용해주세요',
              description: `보물찾기 이용을 위해선 위치 권한을 허용해야해요!`,
              src: `../images/treasureHunt/emoji/face/70.png`,
              onClick: () => {
                TreasureHuntAction.postMessage({ type: 'navigateToSettings' })
              },
            },
          })
          return
        }
        TreasureHuntAction.postMessage({ type: 'ready' })
        RouteStore.navigate(`/treasureHunt?token=${AuthStore?.token}`)
      } else if (message?.type === 'THAUthorizationRequest') {
        RouteStore.navigate('/treasureHunt/authorizationRequest')
        TreasureHuntAction.initTreasureHunt(AuthStore?.token, {
          routeParams: message?.data?.routeParams,
          codepushVersion: message?.data?.codepushVersion,
        })
        TreasureHuntAction.setTutorialStep(5)
        TreasureHuntAction.setOldTutorialOn(true)
        THUserStore?.set('tutorialStep', 5)
        THUserStore?.set('oldTutorialOn', true)
        THBottomSheetStore.close()
      } else if (message?.type === 'THuserInitialSetting') {
        ABStore.initialize({ userId: message?.data?._id })
        AuthStore.setCodePushVersion(message?.data?.codepushVersion)
        AuthStore.setToken(message?.data?.token)

        if (window.location.pathname.includes('/watermelon')) {
          WatermelonAction.initGame({ data: message?.data })
          return
        }

        THUserStore.setMultiple({
          _id: message?.data?._id?.toString(),
          userId: message?.data?._id?.toString(),
          userName: message?.data?.userName,
          codepushVersion: message?.data?.codepushVersion,
          appVersion: message?.data?.appVersion,
          needsLogin: message?.data?.needsLogin,
          userImageUri: message?.data?.userImageUri,
        })

        // const carousel = window.localStorage.getItem('THcarouselAt')
        // if (!carousel) {
        //   window.localStorage.setItem('THcarouselAt', new Date().getTime())
        //   RouteStore.navigate('/treasureHuntCarousel')
        //   return
        // }

        TreasureHuntAction.initTreasureHunt(message?.data?.token, {
          routeParams: message?.data?.routeParams,
          codepushVersion: message?.data?.codepushVersion,
        })
      } else if (message?.type === 'contactPermissionResult') {
        if (window.location.href.includes('applePang')) {
          ApplePangAction.getRankingLocal({
            contacts: message?.data?.processedContacts,
          })
        } else {
          WatermelonAction.getRankingLocal({
            contacts: message?.data?.processedContacts,
          })
        }
      } else if (message?.type === 'treadureHuntLaunchGPSUpdate') {
        TreasureHuntAction.launchGPSUpdate({ gpsData: message?.data?.gpsData })
      } else if (message?.type === 'imageUploadDone') {
        THUserStore.set('selectedImage', message?.data?.s3Uri)
        THUserStore?.set('isUploadingImage', false)
      } else if (message?.type === 'imageUploadStart') {
        THUserStore?.set('isUploadingImage', true)
      } else if (message?.type === 'imageUploadFailed') {
        THUserStore?.set('isUploadingImage', false)
      } else if (message?.type === 'treasureHuntGPSData') {
        if (message?.data?.latitude && message?.data?.longitude) {
          TreasureHuntAction.processFindTreasure({
            latitude: message?.data?.latitude,
            longitude: message?.data?.longitude,
          })
        } else if (
          message?.data?.gpsData?.latitude &&
          message?.data?.gpsData?.longitude
        ) {
          TreasureHuntAction.processFindTreasure({
            latitude: message?.data?.gpsData?.latitude,
            longitude: message?.data?.gpsData?.longitude,
          })
        } else if (message?.data?.errorCode >= 0) {
          // modal pop
          THUserStore.set('isFindingTreasure', false)
          THUserStore.setMultiple({
            gpsError: message?.data?.errorCode,
            errorMessage: message?.data?.errorMessage,
          })
          if (message?.data?.errorCode === 0) {
            THUserStore.set('tutorialStep', 4)
            this.postMessage({ type: 'navigateToSettings' })
          } else {
            ToastStore.GPSError()
          }
        } else if (message?.data?.gpsData?.errorCode >= 0) {
          // modal pop
          THUserStore.set('isFindingTreasure', false)
          THUserStore.setMultiple({
            gpsError: message?.data?.gpsData?.errorCode,
            errorMessage: message?.data?.gpsData?.errorMessage,
          })
          if (message?.data?.gpsData?.errorCode === 0) {
            THUserStore.set('tutorialStep', 4)
            this.postMessage({ type: 'navigateToSettings' })
          } else {
            ToastStore.GPSError()
          }
        }
      } else if (message?.type === 'gpsDataSetting') {
        if (message?.data?.latitude && message?.data?.longitude) {
          UserStore.setUserInfo({
            ...UserStore?.userInfo,
            longitude: message?.data?.longitude,
            latitude: message?.data?.latitude,
          })
        } else if (message?.data?.errorCode >= 0) {
          UserStore.setUserInfo({
            ...UserStore?.userInfo,
            gpsError: message?.data?.errorCode,
            errorMessage: message?.data?.errorMessage,
          })
        }
      } else if (message?.type === 'shakeDetected') {
        if (message?.data?.shakeDetected) {
          UserStore.setUserInfo({
            ...UserStore?.userInfo,
            shakeDetected: message?.data?.shakeDetected,
          })
        }
      } else if (message?.type === 'createdGroupPurchase') {
        GroupPurchaseStore.initializeGroupPurchaseStore({
          ...message?.data,
        })

        GroupPurchaseStore.setIsFromGroupPurchaseCreate(true)
      } else if (message?.type === 'impressionReset') {
        const impressionId = message?.data?.impressionId
        const previousIsFrom = message?.data?.previousIsFrom
        console.debug(`impressionReset ${impressionId}, ${previousIsFrom}`)

        if (impressionId) {
          ItemImpressionStore.setImpressionId(impressionId)
        } else {
          console.warn(`impressionReset impressionId is null`)
          ItemImpressionStore.resetImpressionId()
        }

        // will use existing previousIsFrom
        // if (previousIsFrom) {
        //   ItemImpressionStore.setPreviousIsFrom(previousIsFrom)
        // }
      } else if (message?.type === 'hardwareBackPress') {
        if (
          codePushVersionAvailable(
            UserStore?.userInfo?.codepushVersion,
            '2.5.49',
          )
        ) {
          backHandler()
        }
      } else if (message?.type === 'backPressTH') {
        if (THModalStore?.type !== 'none') {
          THModalStore.close()
        } else if (THBottomSheetStore?.type !== 'none') {
          THBottomSheetStore.close()
        } else if (window.location.pathname.includes('/treasureHunt/')) {
          RouteStore.navigate(`/treasureHunt?token=${AuthStore?.token}`)
          TreasureHuntAction.initTreasureHunt(AuthStore?.token, {
            routeParams: {},
            codepushVersion: AuthStore?.codepushVersion,
          })
        } else if (window.location.pathname.includes('/treasureHunt')) {
          TreasureHuntAction.postMessage({ type: 'close' })
        }
      } else if (message?.type === 'exit') {
        ItemImpressionStore.uploadAndClearImpressions()
      } else if (message?.type === 'appLovinRewardAdReady') {
        UserStore.setIsRewardAdReady(message?.isRewardedAdReady)
      } else if (message?.type === 'notifyAdReward') {
        UserStore.setIsWatchingAd(true)
      }
    } catch (err) {
      console.log(`nativeMessageListener ${err}, ${event?.data}`)
    }
  }
}

const nativeMessageSender = sendMessage

export { nativeMessageListener, nativeMessageSender }
