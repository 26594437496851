import commaNumber from 'comma-number'
import { observer } from 'mobx-react-lite'
import React from 'react'

import TreasureHuntAction from '../../../actions/TreasureHuntAction'
import THUserStore from '../../../stores/treasureHunt/THUserStore'

const CashShopButton = observer(({ className }) => {
  return (
    <div
      className={`relative pointer-events-auto flex justify-center items-center rounded-full ${className}`}
      onClick={() => {
        const payload = {
          enteringComponent: 'TreasureHunt_PurchaseScreen',
          text: '보물찾기',
          source:
            'https://assets.ilevit.com/14161f15-6e34-4435-8f4d-f771603011f6.png',
          rewardText: `포인트를 사용해서 무료로 구매해보세요!`,
          myPoint: `${commaNumber(THUserStore?.point)}원` ?? '로딩 중',
          point: THUserStore?.point,
        }
        TreasureHuntAction.navigateRN({
          screen: 'TreasureHuntPurchaseScreen',
          payload,
        })
      }}
    >
      <img
        className='w-[16vw] absolute absolute-center'
        src='../images/treasureHunt/bottomButtons/cashShop.png'
        alt='cashShop'
      />
    </div>
  )
})

export default CashShopButton
