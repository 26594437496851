import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Autoplay, Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import backendApis from '../../utils/backendApis'
import resize from '../../utils/resize'

const BannerSwiperComponent = ({ bannerData }) => {
  const [swiperIndex, setSwiperIndex] = useState(0)
  const navigate = useNavigate()
  const handleBannerClick = (data) => {
    backendApis.logToonAction('AltoonMainBanner', 'clickedBanner', {
      toonInfo: data?.state?.toonInfo?._id,
      title: data?.state?.toonInfo?.title,
    })
    navigate(data?.pathname, { state: data?.state })
  }

  return (
    <Swiper
      spaceBetween={10}
      slidesPerView={1}
      initialSlide={swiperIndex}
      onSlideChange={(swiper) => {
        setSwiperIndex(swiper.activeIndex)
      }}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      pagination={{
        type: 'bullets',
        clickable: true,
        dynamicBullets: true,
      }}
      navigation
      modules={[Pagination, Navigation, Autoplay]}
    >
      {bannerData?.map((banner, index) => (
        <SwiperSlide key={index || Math.random()}>
          <button
            type='button'
            onClick={() => {
              handleBannerClick(banner?.navigateTo)
            }}
          >
            <img
              key={index || Math.random()}
              className='w-[100vw] h-full'
              src={resize(banner?.imageUri, 1230, 825)}
              alt='banner'
            />
          </button>
        </SwiperSlide>
      ))}
    </Swiper>
  )
}
export default BannerSwiperComponent
