import additionalAreaIcon from './additionalAreaIcon.png'
import alarmNudge from './alarmNudge.png'
import bellRedPlus from './bellRedPlus.png'
import blackP from './blackP.png'
import bonusPigFoodIcon from './bonusPigFoodIcon.png'
import bubblePop from './bubblePop.png'
import cashback from './cashback.png'
import check from './check.png'
import checkInEnvelope from './checkInEnvelope.png'
import checkInEnvelopeOpened from './checkInEnvelopeOpened.png'
import checkInPig from './checkInPig.png'
import checkInPigBoostMissionButton from './checkInPigBoostMissionButton.png'
import checkInPigIcon from './checkInPigIcon.png'
import checkInPigMain from './checkInPigMain.png'
import checkInPigMissionButton from './checkInPigMissionButton.png'
import close from './close.png'
import closeRounded from './closeRounded.png'
import closeThin from './closeThin.png'
import consecutiveBonus from './consecutiveBonus.png'
import consecutiveBonusIcon from './consecutiveBonusIcon.png'
import coupon from './coupon.png'
import couponEnvelopeGray from './couponEnvelopeGray.png'
import couponEnvelopeRed from './couponEnvelopeRed.png'
import couponEnvelopeYellow from './couponEnvelopeYellow.png'
import couponIcon from './couponIcon.png'
import couponPackBanner from './couponPackBanner.png'
import couponPackNudge from './couponPackNudge.png'
import dailyCheckInAlarm from './dailyCheckInAlarm.png'
import dailyCheckInAlfarm from './dailyCheckInAlfarm.png'
import dailyCheckInCart from './dailyCheckInCart.png'
import dailyCheckInClock from './dailyCheckInClock.png'
import dailyCheckInEnvelope from './dailyCheckInEnvelope.png'
import dailyCheckInFiveGuests from './dailyCheckInFiveGuests.png'
import dailyCheckInFriends from './dailyCheckInFriends.png'
import dailyCheckInQuiz from './dailyCheckInQuiz.png'
import dailyCheckInQuizHint from './dailyCheckInQuizHint.png'
import dailyCheckInQuizLogo from './dailyCheckInQuizLogo.png'
import dailyCheckInShoppingBag from './dailyCheckInShoppingBag.png'
import dailyCheckInSpecialDeal from './dailyCheckInSpecialDeal.png'
import dailyRouletteButton from './dailyRouletteButton.png'
import defaultProfileImage from './defaultProfileImage.jpeg'
import eatingPig from './eatingPig.gif'
import eatingPigPink from './eatingPigPink.gif'
import eatingSuperPig from './eatingSuperPig.gif'
import eatingSuperduperPig from './eatingSuperduperPig.gif'
import emptyProfileImage from './emptyProfileImage.png'
import exclamationMarkIcon from './exclamationMarkIcon.png'
import fireIcon from './fireIcon.png'
import friendIcon from './friendIcon.png'
import gainedPointCard from './gainedPointCard.png'
import giftBoxClosed from './giftbox_closed.png'
import giftBoxOpened from './giftbox_opened.png'
import gifticonRecoverFailed from './gifticonRecoverFailed.png'
import gifticonRecovering from './gifticonRecovering.gif'
import gifticonUpgradeExplain from './gifticonUpgradeExplain.png'
import gifticonUpgradeFailed from './gifticonUpgradeFailed.png'
import gifticonUpgrading from './gifticonUpgrading.gif'
import gps from './gps.png'
import grayCalendar from './grayCalendar.png'
import grayPointCard from './grayPointCard.png'
import greenPlus from './greenPlus.png'
import happinessMission from './happinessMission.png'
import happinessMud from './happinessMud.png'
import happinessMudOnly from './happinessMudOnly.png'
import happinessToy from './happinessToy.png'
import happinessToyOnly from './happinessToyOnly.png'
import heart from './heart.png'
import heartFaded from './heartFaded.png'
import invitationStamp from './invitationStamp.png'
import lockedGifticonList from './lockedGifticonList.png'
import luckyScratchButton from './luckyScratchButton.png'
import megaphone from './megaphone.png'
import modalPig from './modalPig.png'
import modalPigOnly from './modalPigOnly.png'
import modalPinkPig from './modalPinkPig.png'
import moneyTree from './moneyTree.png'
import moneyTreeBackground from './moneyTreeBackground.png'
import moneyTreeBackgroundPing from './moneyTreeBackgroundPing.png'
import moneyTreeBody from './moneyTreeBody.png'
import moneyTreeBottomIcon from './moneyTreeBottomIcon.png'
import moneyTreeCompleteIllustration from './moneyTreeCompleteIllustration.png'
import moneyTreeIcon from './moneyTreeIcon.png'
import moneyTreeIllustration from './moneyTreeIllustration.png'
import moneyTreeReward from './moneyTreeReward.png'
import moneyTreeRewardBubble from './moneyTreeRewardBubble.png'
import moneyTreeRewardPopped from './moneyTreeRewardPopped.png'
import moneyTreeTwinkle from './moneyTreeTwinkle.png'
import mysterySuperduperPig from './mysterySuperduperPig.png'
import nearMatchBonus from './nearMatchBonus.png'
import nearMatchBonusNudge from './nearMatchBonusNudge.png'
import newBalloon from './newBalloon.png'
import newTeammateBadge from './newTeammateBadge.png'
import newTeammateNudge2 from './newTeammateNudge2.png'
import newTeammateNudge from './newTeammateNudge.png'
import orangeMe from './orangeMe.png'
import orangePlus from './orangePlus.png'
import pigBackground from './pigBackground.png'
import pigBoostMissionFeed from './pigBoostMissionFeed.png'
import pigBoostMissionPurchase from './pigBoostMissionPurchase.png'
import pigBoostMissionStamp from './pigBoostMissionStamp.png'
import pigFeedButton from './pigFeedButton.png'
import pigFood from './pigFood.png'
import pigFoodColored from './pigFoodColored.png'
import pigFoodEarn from './pigFoodEarn.png'
import pigFoodLarge from './pigFoodLarge.png'
import pigFoodLargeColor from './pigFoodLargeColor.png'
import pigFoodLargeWaterMark from './pigFoodLargeWaterMark.png'
import pigHappinessMissionPet from './pigHappinessMissionPet.png'
import pigHappinessMissionShop from './pigHappinessMissionShop.png'
import pigHappinessMissionTimer from './pigHappinessMissionTimer.png'
import pigHeartBordered from './pigHeartBordered.png'
import pigHeartHundred from './pigHeartHundred.png'
import pigMissionAlarm from './pigMissionAlarm.png'
import pigMissionCheckIn from './pigMissionCheckIn.png'
import pigMissionConsecutiveCheckIn from './pigMissionConsecutiveCheckIn.png'
import pigMissionPlay from './pigMissionPlay.png'
import pigMissionShop from './pigMissionShop.png'
import pigMissionShorts from './pigMissionShorts.png'
import pigMissionTimer from './pigMissionTimer.png'
import pigMissionWakeUp from './pigMissionWakeUp.png'
import pigTitle from './pigTitle.png'
import pigTitleInfo from './pigTitleInfo.png'
import pinkGift from './pinkGift.png'
import pinkPig from './pinkPig.png'
import plusIcon from './plusIcon.png'
import plusSmall from './plusSmall.png'
import pointUpgrade from './pointUpgrade.png'
import poof from './poof.gif'
import questionMarkIcon from './questionMarkIcon.png'
import raffleBackground from './raffleBackground.png'
import raffleBall from './raffleBall.png'
import raffleBalls from './raffleBalls.png'
import raffleBanner from './raffleBanner_0529.png'
import raffleBubble from './raffleBubble.png'
import raffleExplanation from './raffleExplanation.png'
import raffleMachine from './raffleMachine.png'
import redBox from './redBox.png'
import redP from './redP.png'
import redPlus from './redPlus.png'
import redPoint from './redPoint.png'
import redRoundCheckIcon from './redRoundCheckIcon.png'
import rouletteTicket from './rouletteTicket.png'
import shakeIcon from './shakeIcon.png'
import shakeIconGreen from './shakeIconGreen.png'
import stamp from './stamp.png'
import superPig from './superPig.png'
import superduperPig from './superduperPig.png'
import teamNudge from './teamNudge.png'
import telepathicIcon from './telepathicIcon.png'
import tripleShakeIcon from './tripleShakeIcon.png'
import tutorialTouch from './tutorialTouch.png'
import up from './up.png'
import upperArrowIcon from './upperArrowIcon.png'
import yellowMoney from './yellowMoney.png'
import yellowPoint from './yellowPoint.png'

const CheckInEnvelope = ({ className = 'w-48 h-48', children }) => (
  <div>
    <img className={className} alt='CheckInEnvelope' src={checkInEnvelope} />
    <div>{children}</div>
  </div>
)

const CheckInEnvelopeOpened = ({ className = 'w-48 h-48', children }) => (
  <div>
    <img
      className={className}
      alt='checkInEnvelopeOpened'
      src={checkInEnvelopeOpened}
    />
    <div>{children}</div>
  </div>
)

const Cashback = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='Cashback' src={cashback} />
    <div>{children}</div>
  </div>
)

const Close = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='Close' src={close} />
    <div>{children}</div>
  </div>
)

const CloseRounded = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='CloseRounded' src={closeRounded} />
    <div>{children}</div>
  </div>
)

const Check = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='Check' src={check} />
    <div>{children}</div>
  </div>
)

const Poof = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='Poof' src={poof} />
    <div>{children}</div>
  </div>
)

const ConsecutiveBonus = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='ConsecutiveBonus' src={consecutiveBonus} />
    <div>{children}</div>
  </div>
)

const DailyCheckInEnvelope = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img
      className={className}
      alt='DailyCheckInEnvelope'
      src={dailyCheckInEnvelope}
    />
    <div>{children}</div>
  </div>
)

const DailyCheckInQuizHint = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img
      className={className}
      alt='DailyCheckInQuizHint'
      src={dailyCheckInQuizHint}
    />
    <div>{children}</div>
  </div>
)

const DailyCheckInQuizLogo = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img
      className={className}
      alt='DailyCheckInQuizLogo'
      src={dailyCheckInQuizLogo}
    />
    <div>{children}</div>
  </div>
)

const DailyCheckInAlfarm = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img
      className={className}
      alt='DailyCheckInAlfarm'
      src={dailyCheckInAlfarm}
    />
    <div>{children}</div>
  </div>
)

const DailyCheckInFriends = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img
      className={className}
      alt='DailyCheckInFriends'
      src={dailyCheckInFriends}
    />
    <div>{children}</div>
  </div>
)

const DailyCheckInQuiz = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='DailyCheckInQuiz' src={dailyCheckInQuiz} />
    <div>{children}</div>
  </div>
)

const DailyCheckInCart = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='DailyCheckInCart' src={dailyCheckInCart} />
    <div>{children}</div>
  </div>
)

const DailyCheckInClock = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img
      className={className}
      alt='DailyCheckInClock'
      src={dailyCheckInClock}
    />
    <div>{children}</div>
  </div>
)

const DailyCheckInShoppingBag = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img
      className={className}
      alt='DailyCheckInShoppingBag'
      src={dailyCheckInShoppingBag}
    />
    <div>{children}</div>
  </div>
)

const DailyCheckInSpecialDeal = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img
      className={className}
      alt='DailyCheckInSpecialDeal'
      src={dailyCheckInSpecialDeal}
    />
    <div>{children}</div>
  </div>
)

const Stamp = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='Stamp' src={stamp} />
    <div>{children}</div>
  </div>
)

const ExclamationMarkIcon = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img
      className={className}
      alt='ExclamationMarkIcon'
      src={exclamationMarkIcon}
    />
    <div>{children}</div>
  </div>
)

const TutorialTouch = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='TutorialTouch' src={tutorialTouch} />
    <div>{children}</div>
  </div>
)

const PlusSmall = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='PlusSmall' src={plusSmall} />
    <div>{children}</div>
  </div>
)

const DailyCheckInFiveGuests = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img
      className={className}
      alt='DailyCheckInFiveGuests'
      src={dailyCheckInFiveGuests}
    />
    <div>{children}</div>
  </div>
)

const NewBalloon = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='NewBalloon' src={newBalloon} />
    <div>{children}</div>
  </div>
)

const InvitationStamp = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='InvitationStamp' src={invitationStamp} />
    <div>{children}</div>
  </div>
)

const FriendIcon = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='FriendIcon' src={friendIcon} />
    <div>{children}</div>
  </div>
)

const CheckInPigMain = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='CheckInPigMain' src={checkInPigMain} />
    <div>{children}</div>
  </div>
)

const BonusPigFoodIcon = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='BonusPigFoodIcon' src={bonusPigFoodIcon} />
    <div>{children}</div>
  </div>
)

const CheckInPigMissionButton = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img
      className={className}
      alt='CheckInPigMissionButton'
      src={checkInPigMissionButton}
    />
    <div>{children}</div>
  </div>
)
const PigFood = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='PigFood' src={pigFood} />
    <div>{children}</div>
  </div>
)

const EatingPig = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='EatingPig' src={eatingPig} />
    <div>{children}</div>
  </div>
)

const EatingPigPink = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='EatingPigPink' src={eatingPigPink} />
    <div>{children}</div>
  </div>
)

const PinkPig = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='PinkPig' src={pinkPig} />
    <div>{children}</div>
  </div>
)

const EatingSuperPig = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='EatingSuperPig' src={eatingSuperPig} />
    <div>{children}</div>
  </div>
)

const SuperPig = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='SuperPig' src={superPig} />
    <div>{children}</div>
  </div>
)

const EatingSuperduperPig = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img
      className={className}
      alt='EatingSuperduperPig'
      src={eatingSuperduperPig}
    />
    <div>{children}</div>
  </div>
)

const SuperduperPig = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='SuperduperPig' src={superduperPig} />
    <div>{children}</div>
  </div>
)

const MysterySuperduperPig = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img
      className={className}
      alt='MysterySuperduperPig'
      src={mysterySuperduperPig}
    />
    <div>{children}</div>
  </div>
)

const ModalPinkPig = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='ModalPinkPig' src={modalPinkPig} />
    <div>{children}</div>
  </div>
)

const ModalPig = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='ModalPig' src={modalPig} />
    <div>{children}</div>
  </div>
)

const ModalPigOnly = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='ModalPigOnly' src={modalPigOnly} />
    <div>{children}</div>
  </div>
)

const PigBackground = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img
      className={className}
      style={{ height: '100vh', width: '100%' }}
      alt='PigBackground'
      src={pigBackground}
    />
    <div>{children}</div>
  </div>
)

const PigFoodEarn = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='PigFoodEarn' src={pigFoodEarn} />
    <div>{children}</div>
  </div>
)

const PigTitle = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='PigTitle' src={pigTitle} />
    <div>{children}</div>
  </div>
)

const PigTitleInfo = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='PigTitleInfo' src={pigTitleInfo} />
    <div>{children}</div>
  </div>
)

const ConsecutiveBonusIcon = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img
      className={className}
      alt='ConsecutiveBonusIcon'
      src={consecutiveBonusIcon}
    />
    <div>{children}</div>
  </div>
)

const PigFoodLargeWaterMark = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img
      className={className}
      alt='PigFoodLargeWaterMark'
      src={pigFoodLargeWaterMark}
    />
    <div>{children}</div>
  </div>
)

const PigFoodLarge = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='PigFoodLarge' src={pigFoodLarge} />
    <div>{children}</div>
  </div>
)

const PigFoodLargeColor = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img
      className={className}
      alt='PigFoodLargeColor'
      src={pigFoodLargeColor}
    />
    <div>{children}</div>
  </div>
)

const PigFoodColored = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='PigFoodColored' src={pigFoodColored} />
    <div>{children}</div>
  </div>
)

const PigMissionTimer = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='PigMissionTimer' src={pigMissionTimer} />
    <div>{children}</div>
  </div>
)

const PigMissionAlarm = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='PigMissionAlarm' src={pigMissionAlarm} />
    <div>{children}</div>
  </div>
)

const PigMissionShop = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='PigMissionShop' src={pigMissionShop} />
    <div>{children}</div>
  </div>
)

const PigMissionShorts = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='PigMissionShorts' src={pigMissionShorts} />
    <div>{children}</div>
  </div>
)

const PigMissionCheckIn = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img
      className={className}
      alt='PigMissionCheckIn'
      src={pigMissionCheckIn}
    />
    <div>{children}</div>
  </div>
)

const PigMissionConsecutiveCheckIn = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img
      className={className}
      alt='PigMissionConsecutiveCheckIn'
      src={pigMissionConsecutiveCheckIn}
    />
    <div>{children}</div>
  </div>
)

const PigMissionPlay = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='PigMissionPlay' src={pigMissionPlay} />
    <div>{children}</div>
  </div>
)

const PigMissionWakeUp = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='PigMissionWakeUp' src={pigMissionWakeUp} />
    <div>{children}</div>
  </div>
)

const Coupon = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='Coupon' src={coupon} />
    <div>{children}</div>
  </div>
)

const LuckyScratchButton = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img
      className={className}
      alt='LuckyScratchButton'
      src={luckyScratchButton}
    />
    <div>{children}</div>
  </div>
)

const DailyRouletteButton = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img
      className={className}
      alt='DailyRouletteButton'
      src={dailyRouletteButton}
    />
    <div>{children}</div>
  </div>
)

const RouletteTicket = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='RouletteTicket' src={rouletteTicket} />
    <div>{children}</div>
  </div>
)

const Heart = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='Heart' src={heart} />
    <div>{children}</div>
  </div>
)

const HeartFaded = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='HeartFaded' src={heartFaded} />
    <div>{children}</div>
  </div>
)

const PigHeartBordered = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='PigHeartBordered' src={pigHeartBordered} />
    <div>{children}</div>
  </div>
)

const PigHeartHundred = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='PigHeartHundred' src={pigHeartHundred} />
    <div>{children}</div>
  </div>
)

const HappinessMission = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='HappinessMission' src={happinessMission} />
    <div>{children}</div>
  </div>
)

const HappinessMudOnly = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='HappinessMudOnly' src={happinessMudOnly} />
    <div>{children}</div>
  </div>
)

const HappinessToyOnly = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='HappinessToyOnly' src={happinessToyOnly} />
    <div>{children}</div>
  </div>
)

const HappinessMud = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='HappinessMud' src={happinessMud} />
    <div>{children}</div>
  </div>
)

const HappinessToy = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='HappinessToy' src={happinessToy} />
    <div>{children}</div>
  </div>
)

const PigHappinessMissionPet = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img
      className={className}
      alt='PigHappinessMissionPet'
      src={pigHappinessMissionPet}
    />
    <div>{children}</div>
  </div>
)

const PigHappinessMissionShop = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img
      className={className}
      alt='PigHappinessMissionShop'
      src={pigHappinessMissionShop}
    />
    <div>{children}</div>
  </div>
)

const PigHappinessMissionTimer = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img
      className={className}
      alt='PigHappinessMissionTimer'
      src={pigHappinessMissionTimer}
    />
    <div>{children}</div>
  </div>
)

const PigBoostMissionStamp = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img
      className={className}
      alt='PigBoostMissionStamp'
      src={pigBoostMissionStamp}
    />
    <div>{children}</div>
  </div>
)

const PigBoostMissionFeed = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img
      className={className}
      alt='PigBoostMissionFeed'
      src={pigBoostMissionFeed}
    />
    <div>{children}</div>
  </div>
)

const PigBoostMissionPurchase = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img
      className={className}
      alt='PigBoostMissionPurchase'
      src={pigBoostMissionPurchase}
    />
    <div>{children}</div>
  </div>
)

const CheckInPigBoostMissionButton = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img
      className={className}
      alt='CheckInPigBoostMissionButton'
      src={checkInPigBoostMissionButton}
    />
    <div>{children}</div>
  </div>
)

const AdditionalAreaIcon = ({ className = 'w-6 h-6', children, style }) => (
  <div>
    <img
      className={className}
      alt='AdditionalAreaIcon'
      src={additionalAreaIcon}
      style={style}
    />
    <div>{children}</div>
  </div>
)

const BellRedPlus = ({ className = 'w-6 h-6', children, style }) => (
  <div>
    <img
      className={className}
      alt='BellRedPlus'
      src={bellRedPlus}
      style={style}
    />
    <div>{children}</div>
  </div>
)

const PointUpgrade = ({ className = 'w-6 h-6', children, style }) => (
  <div>
    <img
      className={className}
      alt='PointUpgrade'
      src={pointUpgrade}
      style={style}
    />
    <div>{children}</div>
  </div>
)

const CheckInPigIcon = ({ className = 'w-6 h-6', children, style }) => (
  <div>
    <img
      className={className}
      alt='CheckInPigIcon'
      src={checkInPigIcon}
      style={style}
    />
    <div>{children}</div>
  </div>
)

const DailyCheckInAlarm = ({ className = 'w-6 h-6', children, style }) => (
  <div>
    <img
      className={className}
      alt='DailyCheckInAlarm'
      src={dailyCheckInAlarm}
      style={style}
    />
    <div>{children}</div>
  </div>
)

const Megaphone = ({ className = 'w-6 h-6', children, style }) => (
  <div>
    <img className={className} alt='Megaphone' src={megaphone} style={style} />
    <div>{children}</div>
  </div>
)

const CheckInPig = ({ className = 'w-6 h-6', children, style }) => (
  <div>
    <img
      className={className}
      alt='CheckInPig'
      src={checkInPig}
      style={style}
    />
    <div>{children}</div>
  </div>
)

const PigFeedButton = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='PigFeedButton' src={pigFeedButton} />
    <div>{children}</div>
  </div>
)

const QuestionMarkIcon = ({ className = 'w-6 h-6', children, style = {} }) => (
  <div style={style}>
    <img className={className} alt='QuestionMarkIcon' src={questionMarkIcon} />
    <div>{children}</div>
  </div>
)

const YellowMoney = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='YellowMoney' src={yellowMoney} />
    <div>{children}</div>
  </div>
)

const YellowPoint = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='YellowPoint' src={yellowPoint} />
    <div>{children}</div>
  </div>
)

const Up = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='Up' src={up} />
    <div>{children}</div>
  </div>
)

const RedPlus = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='RedPlus' src={redPlus} />
    <div>{children}</div>
  </div>
)

const RedPoint = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='RedPoint' src={redPoint} />
    <div>{children}</div>
  </div>
)

const RedP = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='RedP' src={redP} />
    <div>{children}</div>
  </div>
)

const RedBox = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='RedBox' src={redBox} />
    <div>{children}</div>
  </div>
)

const OrangePlus = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='OrangePlus' src={orangePlus} />
    <div>{children}</div>
  </div>
)

const OrangeMe = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='OrangeMe' src={orangeMe} />
    <div>{children}</div>
  </div>
)

const GrayPointCard = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='GrayPointCard' src={grayPointCard} />
    <div>{children}</div>
  </div>
)

const GrayCalendar = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='GrayCalendar' src={grayCalendar} />
    <div>{children}</div>
  </div>
)

const GainedPointCard = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='GainedPointCard' src={gainedPointCard} />
    <div>{children}</div>
  </div>
)

const EmptyProfileImage = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img
      className={className}
      alt='EmptyProfileImage'
      src={emptyProfileImage}
    />
    <div>{children}</div>
  </div>
)

const BlackP = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='BlackP' src={blackP} />
    <div>{children}</div>
  </div>
)

const TeamNudge = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='TeamNudge' src={teamNudge} />
    <div>{children}</div>
  </div>
)

const NewTeammateNudge = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='NewTeammateNudge' src={newTeammateNudge} />
    <div>{children}</div>
  </div>
)

const NewTeammateNudge2 = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img
      className={className}
      alt='NewTeammateNudge2'
      src={newTeammateNudge2}
    />
    <div>{children}</div>
  </div>
)

const AlarmNudge = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='AlarmNudge' src={alarmNudge} />
    <div>{children}</div>
  </div>
)

const FireIcon = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='FireIcon' src={fireIcon} />
    <div>{children}</div>
  </div>
)

const NewTeammateBadge = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='NewTeammateBadge' src={newTeammateBadge} />
    <div>{children}</div>
  </div>
)

const MoneyTreeBackground = ({
  className = 'w-6 h-6',
  children,
  style = {},
}) => (
  <div style={style}>
    <img
      className={className}
      alt='MoneyTreeBackground'
      src={moneyTreeBackground}
    />
    <div>{children}</div>
  </div>
)

const MoneyTreeBackgroundPing = ({
  className = 'w-6 h-6',
  children,
  style = {},
}) => (
  <div style={style}>
    <img
      className={className}
      alt='MoneyTreeBackgroundPing'
      src={moneyTreeBackgroundPing}
    />
    <div>{children}</div>
  </div>
)

const MoneyTree = ({ className = 'w-6 h-6', children, style = {} }) => (
  <div style={style}>
    <img className={className} alt='MoneyTree' src={moneyTree} />
    <div>{children}</div>
  </div>
)

const MoneyTreeBody = ({ className = 'w-6 h-6', children, style = {} }) => (
  <div style={style}>
    <img className={className} alt='MoneyTreeBody' src={moneyTreeBody} />
    <div>{children}</div>
  </div>
)

const MoneyTreeReward = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='MoneyTreeReward' src={moneyTreeReward} />
    <div>{children}</div>
  </div>
)

const MoneyTreeRewardPopped = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img
      className={className}
      alt='MoneyTreeRewardPopped'
      src={moneyTreeRewardPopped}
    />
    <div>{children}</div>
  </div>
)

const MoneyTreeRewardBubble = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img
      className={className}
      alt='MoneyTreeRewardBubble'
      src={moneyTreeRewardBubble}
    />
    <div>{children}</div>
  </div>
)

const MoneyTreeTwinkle = ({ className = 'w-6 h-6', children, style = {} }) => (
  <div style={style}>
    <img className={className} alt='MoneyTreeTwinkle' src={moneyTreeTwinkle} />
    <div>{children}</div>
  </div>
)

const MoneyTreeIllustration = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img
      className={className}
      alt='MoneyTreeIllustration'
      src={moneyTreeIllustration}
    />
    <div>{children}</div>
  </div>
)

const MoneyTreeCompleteIllustration = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img
      className={className}
      alt='MoneyTreeCompleteIllustration'
      src={moneyTreeCompleteIllustration}
    />
    <div>{children}</div>
  </div>
)

const NearMatchBonus = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='NearMatchBonus' src={nearMatchBonus} />
    <div>{children}</div>
  </div>
)

const NearMatchBonusNudge = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img
      className={className}
      alt='NearMatchBonusNudge'
      src={nearMatchBonusNudge}
    />
    <div>{children}</div>
  </div>
)

const BubblePop = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='BubblePop' src={bubblePop} />
    <div>{children}</div>
  </div>
)

const Gps = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='Gps' src={gps} />
    <div>{children}</div>
  </div>
)

const ShakeIcon = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='ShakeIcon' src={shakeIcon} />
    <div>{children}</div>
  </div>
)

const ShakeIconGreen = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='ShakeIconGreen' src={shakeIconGreen} />
    <div>{children}</div>
  </div>
)

const MoneyTreeIcon = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='MoneyTreeIcon' src={moneyTreeIcon} />
    <div>{children}</div>
  </div>
)

const MoneyTreeBottomIcon = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img
      className={className}
      alt='MoneyTreeBottomIcon'
      src={moneyTreeBottomIcon}
    />
    <div>{children}</div>
  </div>
)

const GreenPlus = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='GreenPlus' src={greenPlus} />
    <div>{children}</div>
  </div>
)

const CloseThin = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='CloseThin' src={closeThin} />
    <div>{children}</div>
  </div>
)

const TelepathicIcon = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='TelepathicIcon' src={telepathicIcon} />
    <div>{children}</div>
  </div>
)

const TripleShakeIcon = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='TripleShakeIcon' src={tripleShakeIcon} />
    <div>{children}</div>
  </div>
)

const DefaultProfileImage = ({
  className = 'w-6 h-6',
  children,
  style = {},
}) => (
  <div style={style}>
    <img
      className={className}
      alt='DefaultProfileImage'
      src={defaultProfileImage}
    />
    <div>{children}</div>
  </div>
)

const CouponPackBanner = ({ className = 'w-6 h-6', children, style = {} }) => (
  <div style={style}>
    <img className={className} alt='CouponPackBanner' src={couponPackBanner} />
    <div>{children}</div>
  </div>
)

const CouponPackNudge = ({ className = 'w-6 h-6', children, style = {} }) => (
  <div style={style}>
    <img className={className} alt='CouponPackNudge' src={couponPackNudge} />
    <div>{children}</div>
  </div>
)

const CouponIcon = ({ className = 'w-6 h-6', children, style = {} }) => (
  <div style={style}>
    <img className={className} alt='CouponIcon' src={couponIcon} />
    <div>{children}</div>
  </div>
)

const CouponEnvelopeRed = ({ className = 'w-6 h-6', children, style = {} }) => (
  <div style={style}>
    <img
      className={className}
      alt='CouponEnvelopeRed'
      src={couponEnvelopeRed}
    />
    <div>{children}</div>
  </div>
)

const CouponEnvelopeYellow = ({
  className = 'w-6 h-6',
  children,
  style = {},
}) => (
  <div style={style}>
    <img
      className={className}
      alt='CouponEnvelopeYellow'
      src={couponEnvelopeYellow}
    />
    <div>{children}</div>
  </div>
)

const CouponEnvelopeGray = ({
  className = 'w-6 h-6',
  children,
  style = {},
}) => (
  <div style={style}>
    <img
      className={className}
      alt='CouponEnvelopeGray'
      src={couponEnvelopeGray}
    />
    <div>{children}</div>
  </div>
)

const PinkGift = ({ className = 'w-6 h-6', children, style = {} }) => (
  <div style={style}>
    <img className={className} alt='PinkGift' src={pinkGift} />
    <div>{children}</div>
  </div>
)

const GiftBoxClosed = ({ className = 'w-6 h-6', children, style = {} }) => (
  <div style={style}>
    <img className={className} alt='giftBoxClosed' src={giftBoxClosed} />
    <div>{children}</div>
  </div>
)

const GiftBoxOpened = ({ className = 'w-6 h-6', children, style = {} }) => (
  <div style={style}>
    <img className={className} alt='giftBoxOpened' src={giftBoxOpened} />
    <div>{children}</div>
  </div>
)

const RaffleBubble = ({ className = 'w-6 h-6', children, style = {} }) => (
  <div style={style}>
    <img className={className} alt='RaffleBubble' src={raffleBubble} />
    <div>{children}</div>
  </div>
)

const RaffleMachine = ({ className = 'w-6 h-6', children, style = {} }) => (
  <div style={style}>
    <img className={className} alt='RaffleMachine' src={raffleMachine} />
    <div>{children}</div>
  </div>
)

const RaffleBalls = ({ className = 'w-6 h-6', children, style = {} }) => (
  <div style={style}>
    <img className={className} alt='RaffleBalls' src={raffleBalls} />
    <div>{children}</div>
  </div>
)

const RaffleBackground = ({ className = 'w-6 h-6', children, style = {} }) => (
  <div style={style}>
    <img className={className} alt='RaffleBackground' src={raffleBackground} />
    <div>{children}</div>
  </div>
)

const RaffleExplanation = ({ className = 'w-6 h-6', children, style = {} }) => (
  <div style={style}>
    <img
      className={className}
      alt='RaffleExplanation'
      src={raffleExplanation}
    />
    <div>{children}</div>
  </div>
)

const RaffleBall = ({ className = 'w-6 h-6', children, style = {} }) => (
  <div style={style}>
    <img className={className} alt='RaffleBall' src={raffleBall} />
    <div>{children}</div>
  </div>
)

const RaffleBanner = ({ className = 'w-6 h-6', children, style = {} }) => (
  <div style={style}>
    <img className={className} alt='RaffleBanner' src={raffleBanner} />
    <div>{children}</div>
  </div>
)

const LockedGifticonList = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img
      className={className}
      alt='LockedGifticonList'
      src={lockedGifticonList}
    />
    <div>{children}</div>
  </div>
)

const PlusIcon = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='PlusIcon' src={plusIcon} />
    <div>{children}</div>
  </div>
)

const GifticonRecovering = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img
      className={className}
      alt='GifticonRecovering'
      src={gifticonRecovering}
    />
    <div>{children}</div>
  </div>
)

const GifticonUpgrading = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img
      className={className}
      alt='GifticonUpgrading'
      src={gifticonUpgrading}
    />
    <div>{children}</div>
  </div>
)

const GifticonUpgradeExplain = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img
      className={className}
      alt='GifticonUpgradeExplain'
      src={gifticonUpgradeExplain}
    />
    <div>{children}</div>
  </div>
)

const GifticonRecoverFailed = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img
      className={className}
      alt='GifticonRecoverFailed'
      src={gifticonRecoverFailed}
    />
    <div>{children}</div>
  </div>
)

const GifticonUpgradeFailed = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img
      className={className}
      alt='GifticonUpgradeFailed'
      src={gifticonUpgradeFailed}
    />
    <div>{children}</div>
  </div>
)

const RedRoundCheckIcon = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img
      className={className}
      alt='RedRoundCheckIcon'
      src={redRoundCheckIcon}
    />
    <div>{children}</div>
  </div>
)

const UpperArrowIcon = ({ className = 'w-6 h-6', children }) => (
  <div>
    <img className={className} alt='UpperArrowIcon' src={upperArrowIcon} />
    <div>{children}</div>
  </div>
)

export {
  CheckInEnvelope,
  CheckInEnvelopeOpened,
  Cashback,
  Close,
  CloseRounded,
  Check,
  Poof,
  ConsecutiveBonus,
  Stamp,
  ExclamationMarkIcon,
  DailyCheckInEnvelope,
  DailyCheckInQuizHint,
  DailyCheckInQuizLogo,
  DailyCheckInAlfarm,
  DailyCheckInFriends,
  DailyCheckInQuiz,
  DailyCheckInCart,
  DailyCheckInClock,
  DailyCheckInShoppingBag,
  DailyCheckInSpecialDeal,
  TutorialTouch,
  PlusSmall,
  DailyCheckInFiveGuests,
  NewBalloon,
  InvitationStamp,
  FriendIcon,
  CheckInPigMain,
  BonusPigFoodIcon,
  CheckInPigMissionButton,
  PigFood,
  EatingPig,
  EatingPigPink,
  PinkPig,
  EatingSuperPig,
  SuperPig,
  EatingSuperduperPig,
  SuperduperPig,
  MysterySuperduperPig,
  ModalPinkPig,
  ModalPig,
  ModalPigOnly,
  PigBackground,
  PigFoodEarn,
  PigTitle,
  PigTitleInfo,
  ConsecutiveBonusIcon,
  PigFoodLargeWaterMark,
  PigFoodLarge,
  PigFoodLargeColor,
  PigFoodColored,
  PigMissionTimer,
  PigMissionAlarm,
  PigMissionShop,
  PigMissionShorts,
  PigMissionCheckIn,
  PigMissionConsecutiveCheckIn,
  PigMissionPlay,
  PigMissionWakeUp,
  Coupon,
  LuckyScratchButton,
  DailyRouletteButton,
  RouletteTicket,
  Heart,
  HeartFaded,
  PigHeartBordered,
  PigHeartHundred,
  HappinessMission,
  HappinessMud,
  HappinessToy,
  HappinessMudOnly,
  HappinessToyOnly,
  PigHappinessMissionPet,
  PigHappinessMissionShop,
  PigHappinessMissionTimer,
  PigBoostMissionStamp,
  PigBoostMissionFeed,
  PigBoostMissionPurchase,
  CheckInPigBoostMissionButton,
  AdditionalAreaIcon,
  BellRedPlus,
  PointUpgrade,
  CheckInPigIcon,
  DailyCheckInAlarm,
  Megaphone,
  CheckInPig,
  PigFeedButton,
  QuestionMarkIcon,
  YellowPoint,
  YellowMoney,
  RedPlus,
  RedPoint,
  RedP,
  RedBox,
  OrangePlus,
  OrangeMe,
  GrayPointCard,
  GrayCalendar,
  GainedPointCard,
  EmptyProfileImage,
  BlackP,
  TeamNudge,
  NewTeammateNudge,
  NewTeammateNudge2,
  AlarmNudge,
  FireIcon,
  NewTeammateBadge,
  MoneyTreeBackground,
  MoneyTreeBackgroundPing,
  MoneyTree,
  MoneyTreeBody,
  MoneyTreeReward,
  MoneyTreeRewardPopped,
  MoneyTreeRewardBubble,
  MoneyTreeTwinkle,
  MoneyTreeIllustration,
  MoneyTreeCompleteIllustration,
  NearMatchBonus,
  NearMatchBonusNudge,
  BubblePop,
  Gps,
  ShakeIcon,
  ShakeIconGreen,
  MoneyTreeIcon,
  MoneyTreeBottomIcon,
  DefaultProfileImage,
  GreenPlus,
  CloseThin,
  TelepathicIcon,
  TripleShakeIcon,
  Up,
  CouponPackBanner,
  CouponPackNudge,
  CouponIcon,
  CouponEnvelopeRed,
  CouponEnvelopeYellow,
  CouponEnvelopeGray,
  PinkGift,
  GiftBoxClosed,
  GiftBoxOpened,
  RaffleBubble,
  RaffleMachine,
  RaffleBalls,
  RaffleBackground,
  RaffleExplanation,
  RaffleBall,
  RaffleBanner,
  LockedGifticonList,
  PlusIcon,
  UpperArrowIcon,
  GifticonRecovering,
  GifticonUpgrading,
  GifticonUpgradeExplain,
  GifticonRecoverFailed,
  GifticonUpgradeFailed,
  RedRoundCheckIcon,
}
