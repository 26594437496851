import React, { useEffect, useRef, useState } from 'react'
import { useInterval } from 'react-use'

import { shortenText } from '../../utils/utility'

window.onload = function () {
  const movementDistance = (window.innerWidth * 12) / 2 + 500
  document.documentElement.style.setProperty(
    '--movement-distance',
    `${movementDistance}px`,
  )
  const init =
    Math.floor((Math.random() * (window.innerWidth * 12)) / 2) +
    Math.random(800)
  document.documentElement.style.setProperty(
    '--initial-movement-distance',
    `${init}px`,
  )
}

const MovingComponentsScreen = ({ userList }) => {
  const [offset, setOffset] = useState(0)
  const [visible, setVisible] = useState(false)
  const [containerWidth, setContainerWidth] = useState(0)
  const offsetRef = useRef(offset) // create a ref for offset

  useInterval(() => {
    setOffset((prevOffset) => {
      const newOffset = prevOffset - 4
      offsetRef.current = newOffset // update offset ref
      return newOffset
    })
  }, 50)

  useEffect(() => {
    const containerWidth = document.getElementById('container').clientWidth
    setContainerWidth(containerWidth)
    const initialOffset = Math.floor(Math.random() * 300 + containerWidth / 2)
    setOffset(initialOffset)
    offsetRef.current = initialOffset // update offset ref
    setVisible(true)
  }, [])

  useEffect(() => {
    if (offsetRef.current < (containerWidth / 2) * -1) {
      // use offset ref
      setVisible(false)
      const newOffset = containerWidth / 2 + 500
      setOffset(newOffset)
      offsetRef.current = newOffset // update offset ref
      setTimeout(() => {
        setVisible(true)
      }, 100)
    }
  }, [offset, containerWidth])

  return (
    <div style={styles.screen}>
      <div style={styles.componentsContainer} id='container'>
        {userList.map((item, index) => (
          <div
            key={index}
            className='slide-left'
            style={{
              ...styles.component,
              display: visible ? 'flex' : 'none',
            }}
          >
            <img
              className='w-[5vw] h-[5vw] rounded-full mr-2'
              alt='profile'
              src={
                item?.userImageUri ??
                'https://alwayz-assets.s3.ap-northeast-2.amazonaws.com/d9a2da73-105d-4c7d-bf80-9c3191a977f7.png'
              }
            />
            <div className='text-[3vw] font-bold'>
              {shortenText(item?.userName, 6)}님이 오늘{' '}
              {shortenText(item?.itemTitle?.split(' ')?.[1]?.toString(), 10)}{' '}
              상품을 무료 획득하셨어요!
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

const styles = {
  screen: {
    // width: "100%",
    // height: "100vh",
    // backgroundColor: "#f2f2f2",
    overflow: 'hidden',
    marginTop: 2,
    marginBottom: 2,
  },
  componentsContainer: {
    display: 'flex',
    position: 'relative',
    width: '1400vw',
    justifyContent: 'space-around',
    alignItems: 'center',
    height: '8vw',
    borderRadius: 99,
    // height: "100%",
  },
  component: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    // height: '10vw',
    backgroundColor: '#000000aa',
    fontSize: '5vw',
    color: 'white',
    borderRadius: 99,
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)',
    transition: 'transform 0.3s ease',
    padding: 4,
    paddingRight: 16,
  },
}

export default MovingComponentsScreen
