import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import AlwayzImage from '../components/atoms/AlwayzImage'
import ImageAtom from '../components/atoms/imageAtom'
import LoadingIndicator from '../components/atoms/loadingIndicator'
import AltoonHorizontalReaderPage from '../components/toon/AltoonHorizontalReaderPage'
import AuthorCommentSection from '../components/toon/AuthorCommentSection'
import BottomSection from '../components/toon/BottomSection'
import ChapterHandler from '../components/toon/ChapterHandler'
import GuideTutorial from '../components/toon/GuideTutorial'
import HeaderComponent from '../components/toon/HeaderComponent'
import ItemConnectingSection from '../components/toon/ItemConnectionSection'
import RatingComponent from '../components/toon/RatingComponent'
import ToonCommentSection from '../components/toon/toonCommentSection'
import useChapterData from '../hooks/altoon/useChapterData'
import backendApis from '../utils/backendApis'
import resize from '../utils/resize'

const AltoonReaderPage = () => {
  const scrollContainerRef = useRef(null)
  const location = useLocation()

  const [guideOn, setGuideOn] = useState(true)
  const [guideTutorialOn, setGuideTutorialOn] = useState(false)

  const { chapterInfo, toonInfo, episode } = location.state || {}
  const chapterId = chapterInfo?._id
  const { chapter, previousChapter, nextChapter, viewLog, isLoading, error } =
    useChapterData(chapterId, toonInfo, episode)
  const rentPeriod = location?.state?.rentPeriod || 3
  let loggedMiddle = false
  let loggedBottom = false

  const toonId = toonInfo?._id
  const toonType = toonInfo?.toonType

  useEffect(() => {
    const logReadingProgress = async () => {
      // 독서 진행 상태를 로깅하는 API 호출
      try {
        await backendApis.logChapterViewed(toonId, chapterInfo?._id, rentPeriod)

        await backendApis.logToonAction('AltoonReaderPage', 'enteredScreen', {
          toonId,
          chapterId,
        })
      } catch (error) {
        console.error('Failed to log reading progress:', error)
      }
    }

    // 사용자가 새로운 챕터를 시작할 때마다 독서 진행 상태를 로깅
    logReadingProgress()
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = 0
    }
  }, [chapterInfo, toonInfo, episode])

  useEffect(() => {
    if (toonInfo?.toonType === 'HorizontalScroll_reverse' && episode === 1) {
      setGuideTutorialOn(true)
    }
  }, [])

  // useEffect(() => {
  //   if (toonType === 'VerticalScroll') {
  //     const timer = setTimeout(() => {
  //       setGuideOn(false)
  //     }, 3000)
  //     // 3000ms = 3s

  //     return () => {
  //       clearTimeout(timer)
  //     }
  //   }
  // }, [guideOn, chapterInfo, toonInfo, episode])

  useEffect(() => {
    // 이전에 보던 스크롤 위치가 있다면 해당 위치로 이동
    const savedPosition = sessionStorage.getItem(
      `reader_scrollPosition_${chapterId}`,
    )
    if (savedPosition) {
      setTimeout(() => {
        scrollContainerRef.current.scrollTop = parseInt(savedPosition, 10)
      }, 100)
    }
  }, [])

  useEffect(() => {
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'allowiPhoneGesture',
        boolean: false,
      }),
    )
    // 캡처 & 녹화 방지
    const handleScreenGuard = (type) => {
      if (type === 'disable') {
        window.ReactNativeWebView?.postMessage(
          JSON.stringify({
            type: 'disableScreenCapture',
            boolean: false,
          }),
        )
      } else {
        window.ReactNativeWebView?.postMessage(
          JSON.stringify({
            type: 'enableScreenCapture',
            boolean: false,
          }),
        )
      }
    }
    window.addEventListener('screenGuard', handleScreenGuard('disable'))

    return () => {
      window.addEventListener('screenGuard', handleScreenGuard('enable'))
    }
  }, [])

  useEffect(() => {
    const handleScroll = (e) => {
      // 스크롤 위치 기억하는 용도
      sessionStorage.setItem(
        `reader_scrollPosition_${chapterId}`,
        e.target.scrollTop,
      )
      if (e.target.scrollTop !== 0 && guideOn) {
        setGuideOn(false)
      }

      // 중간, 최하단 도달 시 로깅하는 용도
      const middle =
        e.target.scrollHeight - e.target.scrollTop <=
        Math.floor(e.target.scrollHeight / 2)
      const bottom =
        e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 10
      if (middle && !loggedMiddle) {
        loggedMiddle = true
        backendApis.logToonAction('AltoonReaderPage', 'reachedMiddle', {
          toonId,
          chapterId,
        })
      }
      if (bottom && !loggedBottom) {
        loggedBottom = true
        // Action to perform at the end of the scroll
        backendApis.logToonAction('AltoonReaderPage', 'reachedEnd', {
          toonId,
          chapterId,
        })
        backendApis.logToonActionForAlfarmMission(toonId, chapterId)
      }
    }

    const scrollContainer = scrollContainerRef.current
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handleScroll)
      }
    }
  }, [chapterInfo])

  const handleScreenTap = () => setGuideOn(!guideOn)

  const ScrollButton = () => {
    return (
      <div
        type='button'
        className={`w-[10vw] fixed bottom-[10vh] right-4 ${
          guideOn
            ? 'opacity-100 animate-slideUp'
            : 'opacity-0 animate-slideDownFade'
        }`}
      >
        <button
          type='button'
          className='rounded-full bg-white shadow-md'
          onClick={() => {
            if (scrollContainerRef.current) {
              scrollContainerRef.current.scrollTop = 0
            }
          }}
        >
          <img src='/images/toon/toTopIcon.png' alt='up' className='w-full' />
        </button>
        <button
          type='button'
          className='rounded-full bg-white shadow-md'
          onClick={() => {
            if (scrollContainerRef.current) {
              scrollContainerRef.current.scrollTop =
                scrollContainerRef.current.scrollHeight
            }
          }}
        >
          <img
            src='/images/toon/toBottomIcon.png'
            alt='up'
            className='w-full'
          />
        </button>
      </div>
    )
  }

  return (
    <div
      ref={scrollContainerRef}
      className={`w-full h-[100vh] overflow-x-hidden ${
        toonType?.includes('HorizontalScroll')
          ? 'overflow-y-hidden'
          : 'overflow-y-scroll'
      } `}
    >
      <HeaderComponent
        toonInfo={toonInfo}
        chapterInfo={chapterInfo}
        guideOn={guideOn}
      />

      {toonType === 'VerticalScroll' && (
        <button
          type='button'
          className='w-full flex flex-col'
          onClick={handleScreenTap}
        >
          {chapter?.contents?.map((content, index) => (
            <AlwayzImage
              src={content}
              key={index || content}
              alt={chapterInfo?.title}
              placeholder={
                <div
                  style={{
                    background: '#d8d8d8',
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                    minHeight: '100vh',
                  }}
                />
              }
              lazy
            />
          ))}
        </button>
      )}

      {toonType?.includes('HorizontalScroll') &&
        chapter?.contents?.length > 0 && (
          <AltoonHorizontalReaderPage
            currentChapter={chapter?.episode}
            previousChapter={previousChapter}
            nextChapter={nextChapter}
            toonInfo={toonInfo}
            chapterInfo={chapterInfo}
            guideOn={guideOn}
            setGuideOn={setGuideOn}
            contents={chapter?.contents}
            handleScreenTap={handleScreenTap}
            viewLog={viewLog}
            direction={toonType === 'HorizontalScroll' ? 'ltr' : 'rtl'}
          />
        )}

      {toonType === 'VerticalScroll' && (
        <>
          {/* 다음화 보기 버튼, 마지막화일땐 숨기기 */}
          {nextChapter && (
            <ChapterHandler
              nextChapter={nextChapter}
              toonInfo={toonInfo}
              rentPeriod={rentPeriod}
              guideOn={guideOn}
              viewLog={viewLog}
            />
          )}
          <RatingComponent
            toonId={toonId}
            toonInfo={toonInfo}
            chapterInfo={chapterInfo}
          />
          <AuthorCommentSection
            author={toonInfo?.author}
            comment={chapterInfo?.authorComment}
            isVerticalToon={toonType === 'VerticalScroll'}
          />
          <ToonCommentSection toonInfo={toonInfo} chapterInfo={chapterInfo} />
        </>
      )}

      {toonInfo?.connectingItemId && toonType === 'VerticalScroll' && (
        <ItemConnectingSection connectingItemId={toonInfo?.connectingItemId} />
      )}

      {guideTutorialOn && (
        <GuideTutorial setGuideTutorialOn={setGuideTutorialOn} />
      )}

      {guideOn && toonType === 'VerticalScroll' && (
        <>
          <ScrollButton />
          <BottomSection
            currentChapter={chapter?.episode}
            previousChapter={previousChapter}
            nextChapter={nextChapter}
            toonInfo={toonInfo}
            chapterInfo={chapterInfo}
            guideOn={guideOn}
            style={{ display: guideOn ? 'flex' : 'none' }}
            viewLog={viewLog}
            rentPeriod={rentPeriod}
          />
        </>
      )}
      {isLoading && (
        <>
          <LoadingIndicator isLoading={isLoading} />
          <div className='loadingPage flex flex-col jua'>
            <div className='toonLoading'>📖</div>
            <div>로딩 중...</div>
          </div>
        </>
      )}
    </div>
  )
}

export default AltoonReaderPage
