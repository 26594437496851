import commaNumber from 'comma-number'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import AnimatedNumbers from 'react-animated-numbers'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import { useSearchParam } from 'react-use'

import ConfirmAlert from '../../components/atoms/confirmAlert'
import LoadingIndicator from '../../components/atoms/loadingIndicator'
import GifticonDrawSection from '../../components/deal/section/gifticonDrawSection'
import GifticonItemSection from '../../components/deal/section/gifticonItemSection'
import SpeedGifticonItemSection from '../../components/deal/section/speedGifticonItemSection'
import { Coupon, YellowMoney } from '../../images/dailyCheckIn'
import AuthStore from '../../stores/AuthStore'
import UserStore from '../../stores/UserStore'
import AB from '../../utils/ab'
import backendApis from '../../utils/backendApis'

let buttonPressed = false

const CheckInExchangeStorePage = observer(() => {
  const token = useSearchParam('token')
  const navigate = useNavigate()

  const [animatePoints, setAnimatePoints] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [openLoginModal, setOpenLoginModal] = useState(false)
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [openNoticeModal, setOpenNoticeModal] = useState(false)
  const [accumulatedPoints, setAccumulatedPoints] = useState(0)
  const [noticeModalInfo, setNoticeModalInfo] = useState({
    title: '',
    content: '',
    onClick: () => {},
  })
  const [selectedRewardInfo, setSelectedRewardInfo] = useState({
    rewardType: '',
    rewardAmount: '',
  })
  const [userData, setUserData] = useState(null)
  const costAB = AB(UserStore?.userInfo._id, 'gifti1221', [2])

  useEffect(() => {
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 300)
    if (!AuthStore.token) {
      setOpenLoginModal(true)
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      const result = await backendApis.getCheckInPoint(AuthStore.token)
      if (result?.status === 200) {
        setAccumulatedPoints(result?.data[0]?.totalCheckInPoints)
        setUserData(result.data[0])
      }
      const distinguish = await UserStore.distinguishCherrypicker()
      if (distinguish !== 'done') {
        setNoticeModalInfo({
          title: '안내',
          content:
            '인터넷 연결이 불안정하여 데이터 로드에 실패했어요. 다음에 다시 시도해주세요.',
          onlyOneButton: true,
          onClick: () => {
            setOpenNoticeModal(false)
            setNoticeModalInfo({})
          },
        })
        setOpenNoticeModal(true)
      }
      if (isLoading) {
        setIsLoading(false)
      }
    }
    fetchData()
  }, [AuthStore.token])

  useEffect(() => {
    setTimeout(() => {
      setAnimatePoints(true)
      setAccumulatedPoints(UserStore?.accumulatedPoints)
      setTimeout(() => {
        setAnimatePoints(false)
      }, 3000)
    }, 200)
  }, [UserStore?.accumulatedPoints])

  const HeaderComponent = () => {
    return (
      <section className='relative z-0 flex flex-row items-center justify-between py-2 overflow-hidden'>
        <button
          type='button'
          className='px-3 py-2 whitespace-nowrap'
          onClick={() => {
            navigate(`/daily-check-in?token=${AuthStore.token}`)
          }}
        >
          <FiChevronLeft className='w-8 h-8 stroke-gray-600' />
        </button>
        <div
          style={{ fontSize: '5vw' }}
          className={
            selectedRewardInfo?.rewardAmount === '500'
              ? 'p-3 text-lg font-bold text-center text-gray-600'
              : 'p-3 text-lg font-bold text-center text-gray-800'
          }
        >
          올포인트 교환소
        </div>
        <div className='w-10' />
      </section>
    )
  }

  const PointStatusComponent = () => {
    return (
      <div style={{ fontSize: '4.5vw' }} className='flex flex-row w-full'>
        <div className='flex flex-col w-full mx-6'>
          <div className='flex flex-col items-start px-6 py-4 mt-4 mb-6 font-bold bg-white rounded-xl'>
            <div className='flex flex-row justify-between w-full'>
              <div>보유한 올포인트</div>
              <button
                type='button'
                className='flex flex-row items-center justify-end text-xs text-gray-500'
                onClick={() => {
                  navigate(`/check-in-exchange-history`)
                  // window.location.href = `#navigate.${JSON.stringify({
                  //   screen: 'DailyCheckInCashBackHistoryScreen',
                  // })}`
                }}
              >
                교환내역 <FiChevronRight className='w-4 h-4 stroke-gray-400' />
              </button>
            </div>
            <div
              style={{ fontSize: '7.6vw' }}
              className='flex flex-row items-center pt-1'
            >
              <YellowMoney className='w-8 mr-2' />
              {animatePoints ? (
                <AnimatedNumbers
                  includeComma
                  animateToNumber={accumulatedPoints || 0}
                  fontStyle={{ fontSize: '7.6vw' }}
                  configs={[{ mass: 1, tension: 320, friction: 70 }]}
                />
              ) : (
                commaNumber(accumulatedPoints || 0)
              )}
              <div className='ml-1'>P</div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const CouponListComponent = () => {
    const CouponComponent = ({
      title,
      rewardType,
      rewardAmount,
      costPoint,
      onClickExchange,
      disabled,
    }) => {
      return (
        <div
          style={{ fontSize: '4vw' }}
          className='flex flex-row items-center justify-between w-full px-1 py-2 my-2 border-b border-gray-1 rounded-xl'
        >
          <div className='flex flex-row w-5/6'>
            {rewardType === 'coupon' && (
              <div className='w-20 mr-2'>
                <Coupon className='p-2' />
              </div>
            )}
            <div className='flex flex-col justify-center'>
              <div className='font-bold' style={{ fontSize: '5vw' }}>
                {title}
              </div>
              <div>사용 포인트: {commaNumber(costPoint)}P</div>
            </div>
          </div>
          <button
            type='button'
            style={{ fontSize: '3vw' }}
            className={
              // eslint-disable-next-line no-nested-ternary
              disabled
                ? 'w-[24vw] py-[3vw] rounded-lg bg-gray-300 text-gray-500 font-bold'
                : selectedRewardInfo?.rewardAmount === '500'
                ? 'w-[24vw] py-[3vw] rounded-lg bg-red-500 text-white font-bold border border-gray-300'
                : 'w-[24vw] py-[3vw] rounded-lg bg-red-500 text-white font-bold'
            }
            onClick={onClickExchange}
            disabled={disabled}
          >
            {disabled ? '교환불가' : '교환하기'}
          </button>
        </div>
      )
    }

    return (
      <div className='px-6 pb-6 bg-white rounded-t-2xl'>
        <div
          className='flex flex-row items-center pt-6 font-bold'
          style={{ fontSize: '5vw' }}
        >
          올웨이즈 쿠폰
          <div style={{ fontSize: '3.5vw', marginLeft: 2 }}>(1일 1회)</div>
        </div>
        <CouponComponent
          title='500원 쿠폰'
          rewardType='coupon'
          rewardAmount='500'
          costPoint='500'
          onClickExchange={() => {
            setSelectedRewardInfo({
              rewardType: 'coupon',
              rewardAmount: '500',
            })
            setOpenConfirmModal(true)
          }}
          disabled={UserStore?.accumulatedPoints < parseInt('500', 10)}
        />
        <CouponComponent
          title='1,000원 쿠폰'
          rewardType='coupon'
          rewardAmount='1000'
          costPoint='1000'
          onClickExchange={() => {
            setSelectedRewardInfo({
              rewardType: 'coupon',
              rewardAmount: '1000',
            })
            setOpenConfirmModal(true)
          }}
          disabled={UserStore?.accumulatedPoints < parseInt('1000', 10)}
        />
        <CouponComponent
          title='3,000원 쿠폰'
          rewardType='coupon'
          rewardAmount='3000'
          costPoint='3000'
          onClickExchange={() => {
            setSelectedRewardInfo({
              rewardType: 'coupon',
              rewardAmount: '3000',
            })
            setOpenConfirmModal(true)
          }}
          disabled={UserStore?.accumulatedPoints < parseInt('3000', 10)}
        />
      </div>
    )
  }

  const NoticeComponent = () => {
    return (
      <div
        style={{
          backgroundColor: '#F5F5F5',
          color: '#999999',
          fontSize: 12,
          paddingTop: 20,
          paddingLeft: 20,
          paddingRight: 20,
          paddingBottom: 20,
        }}
      >
        <div
          style={{
            fontSize: 14,
            fontWeight: 'bold',
          }}
        >
          포인트 교환소 유의사항
        </div>
        <div
          style={{
            fontWeight: 'normal',
            color: '#999999',
            paddingBottom: 8,
          }}
        >
          • 출석체크와 미션 등을 통해 모은 포인트는 올웨이즈 쿠폰이나 이벤트
          상품으로 교환할 수 있으며, 별도 명시되지 않은 경우 포인트의 유효기간은
          적립일로부터 6개월이에요 <br /> • 포인트의 유효기간은 이벤트에 따라
          변경될 수 있어요 <br /> • 올웨이즈 포인트는 사전에 고지 없이 변경 및
          종료될 수 있으며, 기술적 결함으로 인해 잘못 지급된 포인트는 회수될 수
          있어요 <br /> • 올웨이즈 쿠폰 교환은 하루에 1번만 가능하며,
          교환일로부터 14일 동안 유효해요 <br />• 문의는 내 정보 {'>'} 고객 문의{' '}
          {'>'} 1:1 카카오톡에서 하실 수 있어요
        </div>
        <button
          style={{
            fontSize: 14,
            fontWeight: 'bold',
            marginTop: 4,
          }}
          type='button'
          onClick={() => {
            navigate(`/raffle?token=${token}&isFrom=web`)
          }}
        >
          올웨이즈 기프티콘 유의사항
        </button>
        <div
          style={{
            fontWeight: 'normal',
            color: '#999999',
            paddingBottom: 8,
          }}
        >
          • 올웨이즈 기프티콘은 1인 1계정으로, 최초 1회 실명인증 후 교환
          가능해요
          <br />
          • 올웨이즈 기프티콘은 모바일 상품권을 올포인트로 결제할 수 있는
          서비스로, 하루 1회 교환이 가능해요 <br />
          • 모바일 상품권은 교환을 신청한 일로부터 영업일 기준 2~3일 내
          지급되며, 당사 사정으로 지연될 수 있어요
          <br />
          • VIP 기프티콘 교환의 경우 올웨이즈에서 기간 내 상품 구매 이력이 있는
          분만 참여 가능한 이벤트로, 배송 완료된 건에 상품에 한하여 정상 구매
          건으로 인정 돼요 <br />
          • 선착순 기프티콘 이벤트는 이벤트성 기능으로 일부 고객님께만 보이는
          기능이에요 <br />
          • 선착순 기프티콘 이벤트는 수량이 마감되거나 자정을 지나는 경우 교환이
          불가해요 <br />
          • 기프티콘 뽑기 이벤트는 이벤트성 기능으로 일부 고객님께만 보이는
          기능이에요 <br />
          • 뽑은 기프티콘의 경우, 명시된 기간 내로 교환 신청을 하지 않으면
          만료되어 받을 수 없게 되므로, 참고하여 기간 내에 교환 신청을 완료해
          주세요 <br />• 뽑은 기프티콘 교환 신청 내역은 &apos;올포인트 교환소
          &gt; 보유한 올포인트 &gt; 교환내역 &gt; 기프티콘 교환&apos; 에서
          확인이 가능해요 <br />
          • 모바일 상품권은 상품권에 명시된 유효기간 내에만 사용할 수 있고,
          어떠한 사유로도 기간 연장이나 환불이 불가해요
          <br />• 상품권은 카카오톡으로 지급되어요. 카카오톡 계정이 없거나
          &apos;센드비&apos; 채널이 차단되어있는 경우 MMS로 발송되며 이 경우
          스팸처리되어 스팸문자함에 수신되었을 수 있으니 확인 부탁드려요
          <br />
          • 카카오톡 미사용 및 &apos;센드비&apos; 채널 차단으로 인해 MMS로 발송
          될 경우 MMS 수신이 불가능한 번호(일부 알뜰폰, 해외번호 등)로
          가입하셨다면 이벤트 참여가 어려워요
          <br />• 상품권이 기간 내 지급되지 않는 경우 별도 안내될 예정이에요
          <br />• 상품권은 올웨이즈에 가입하신 휴대폰 번호로 발송되어요
          <br />• 모바일 상품권의 종류나 교환금액은 사전 고지 없이 변경될 수
          있어요
          <br />• 올웨이즈 기프티콘 교환은 이벤트성 기능으로 일부 고객님께만
          보이는 기능이에요
        </div>
      </div>
    )
  }

  return (
    <div
      style={{
        overflow: 'hidden',
        width: '100%',
        height: '100%',
        backgroundColor: '#F4DBC9',
      }}
    >
      <HeaderComponent />
      <PointStatusComponent />
      <CouponListComponent />

      <div
        className='flex flex-row items-center px-6 pt-2 font-bold bg-white'
        style={{ fontSize: '5vw' }}
      >
        올웨이즈 기프티콘
        <div style={{ fontSize: '3.5vw', marginLeft: 2 }}>(1일 1회)</div>
      </div>

      {costAB === 'b' ? (
        <>
          <SpeedGifticonItemSection />
          <GifticonDrawSection />
          <GifticonItemSection />
        </>
      ) : (
        <GifticonItemSection />
      )}

      <NoticeComponent />
      {openConfirmModal && (
        <ConfirmAlert
          modalTitle='포인트 교환'
          modalContent={`${commaNumber(
            selectedRewardInfo?.rewardAmount,
          )}포인트를 사용하여 ${
            selectedRewardInfo?.rewardType === 'coupon' ? '쿠폰으로' : ''
          } 교환하시겠어요?`}
          buttonText='확인'
          buttonLink={async () => {
            if (buttonPressed) return
            buttonPressed = true
            setIsLoading(true)
            setOpenConfirmModal(false)
            const result = await backendApis.recordReceivedCoupon(
              'ey-daily-check-in-220410-v1',
              selectedRewardInfo,
            )
            if (result?.status === 200) {
              UserStore.setAccumulatedPoints(
                UserStore?.accumulatedPoints -
                  parseInt(selectedRewardInfo?.rewardAmount, 10),
              )
              setNoticeModalInfo({
                title: '교환 성공!',
                content:
                  '성공적으로 교환되었습니다. 포인트를 더 모으고 저렴하게 구매하세요!',
                buttonText: '쿠폰함가기',
                onClick: () => {
                  setOpenNoticeModal(false)
                  window.location.href = `#navigate.${JSON.stringify({
                    screen: 'CouponListScreen',
                    params: {
                      couponId: 'new',
                    },
                  })}`
                  setNoticeModalInfo({})
                },
              })
            } else if (result?.status === 201) {
              setNoticeModalInfo({
                title: '안내',
                content:
                  '오늘 이미 기프티콘을 교환하셨어요. \n 기프티콘은 하루에 한번만 교환할 수 있어요',
                onlyOneButton: true,
                onClick: () => {
                  setOpenNoticeModal(false)
                  setNoticeModalInfo({})
                },
              })
            } else {
              setNoticeModalInfo({
                title: '안내',
                content:
                  '인터넷 연결이 불안정하여 기프티콘 발급에 실패했어요. 다음에 다시 시도해 주세요',
                onlyOneButton: true,
                onClick: () => {
                  setOpenNoticeModal(false)
                  setNoticeModalInfo({})
                },
              })
            }
            setOpenNoticeModal(true)

            setSelectedRewardInfo({})
            buttonPressed = false
            setIsLoading(false)
          }}
          showModal
          setShowModal={setOpenConfirmModal}
        />
      )}
      {openLoginModal && (
        <ConfirmAlert
          modalTitle='로그인을 해주세요'
          modalContent='로그인을 해주셔야 출석체크를 사용할 수 있어요.'
          buttonText='확인'
          buttonLink={() => {
            setOpenLoginModal(false)
            window.location.href = `#navigate.${JSON.stringify({
              screen: 'MainBMyInfoScreen',
            })}`
          }}
          showModal
          setShowModal={setOpenLoginModal}
          onlyOneButton
        />
      )}
      <LoadingIndicator isLoading={isLoading} />
      {openNoticeModal && (
        <ConfirmAlert
          modalTitle={noticeModalInfo.title}
          modalContent={noticeModalInfo.content}
          buttonText={noticeModalInfo.buttonText || '확인'}
          buttonLink={noticeModalInfo.onClick}
          showModal
          onlyOneButton={noticeModalInfo.onlyOneButton}
          setShowModal={setOpenNoticeModal}
        />
      )}
    </div>
  )
})

export default CheckInExchangeStorePage
