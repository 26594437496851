import React from 'react'

import { Up } from '../../images/dailyCheckIn'

const ScrolltoTopButton = () => {
  return (
    <button
      type='button'
      className='fixed items-center justify-center bg-white rounded-full shadow drop-shadow-lg shadow-gray-500 h-11 w-11 bottom-6 right-4 opacity-70 active:opacity-100'
      onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
    >
      <Up className='w-5 h-5 mx-auto' />
    </button>
  )
}

export default ScrolltoTopButton
