import commaNumber from 'comma-number'
import React, { useEffect, useState } from 'react'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import { useSearchParam } from 'react-use'

import LoadingIndicator from '../components/atoms/loadingIndicator'
import THImageText from '../components/treasureHunt/THImageText'
import AltoonUserStore from '../stores/AltoonUserStore'
import AuthStore from '../stores/AuthStore'
import ToastStore from '../stores/ToastStore'
import backendApis from '../utils/backendApis'

let visibilityChange
if (typeof document.hidden !== 'undefined') {
  visibilityChange = 'visibilitychange'
} else if (typeof document.msHidden !== 'undefined') {
  visibilityChange = 'msvisibilitychange'
} else if (typeof document.webkitHidden !== 'undefined') {
  visibilityChange = 'webkitvisibilitychange'
}

/**
 * 올툰 열람권 OrderMain 페이지
 */
const AltoonTicketPurchasePage = () => {
  const itemId = useSearchParam('itemId')
  const navigate = useNavigate()
  const [itemInfo, setItemInfo] = useState()
  const [userInfo, setUserInfo] = useState()
  const [cardsInfo, setCardsInfo] = useState()
  const [userRepresentativeCardInfo, setUserRepresentativeCardInfo] = useState()
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showTermsOfUse, setShowTermsOfUse] = useState(false)
  const [modalContent, setModalContent] = useState('')

  const fetchData = async () => {
    const itemInfo = await backendApis.loadTicketItemInfo(itemId)
    setItemInfo(itemInfo?.data)

    const userData = await backendApis.userInfoByUserId()
    setUserInfo(userData?.data)
    setCardsInfo(userData?.data?.cardsInfo)
    setUserRepresentativeCardInfo({
      paymentMethodId: userData?.data?.representativeCardInfo?.paymentMethodId,
      bid: userData?.data?.representativeCardInfo?.bid,
      cardCode: userData?.data?.representativeCardInfo?.cardCode,
      cardFirstNumbers:
        userData?.data?.representativeCardInfo?.cardFirstNumbers,
      cardLastNumbers: userData?.data?.representativeCardInfo?.cardLastNumbers,
      cardName: userData?.data?.representativeCardInfo?.cardName,
      pgCompany:
        userData?.data?.representativeCardInfo?.pgCompany || 'nice-pay',
    })
  }

  useEffect(() => {
    document.addEventListener(
      visibilityChange,
      (visibility) => {
        if (document.visibilityState === 'visible') {
          fetchData()
        }
      },
      false,
    )
  }, [])

  useEffect(() => {
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'allowiPhoneGesture',
        boolean: false,
      }),
    )
    setIsLoading(true)
    fetchData()
    setIsLoading(false)
  }, [])

  /**
   * 결제된 orderID로 주문처리가 잘 되었는지 확인
   * 1. 열람권 구매시 열람권 수 증가
   * 2. 열람권 구매시 데일리 미션 업데이트
   */
  const handlePaymentSuccess = async (itemId, orderId) => {
    const result = await backendApis.rewardToonPurchase(itemId, orderId)
    if (result?.status === 200) {
      if (result?.data?.type === 'ticket') {
        if (AltoonUserStore?.userData?.ticket) {
          AltoonUserStore.increase(
            'userData.ticket',
            result?.data?.rewardAmount,
          )
        } else {
          AltoonUserStore.set('userData.ticket', result?.data?.rewardAmount)
        }
        AltoonUserStore.set('dailyMission', {
          ...AltoonUserStore.dailyMission,
          firstPurchase: {
            ...AltoonUserStore.dailyMission?.firstPurchase,
            rewardedAt: new Date(),
            status: 'finish',
          },
        })
      }
      if (result?.data?.type === 'toonCoin') {
        if (AltoonUserStore?.userData?.toonCoin) {
          AltoonUserStore.increase(
            'userData.toonCoin',
            result?.data?.rewardAmount,
          )
          AltoonUserStore.increase(
            'userData.paidCoin',
            result?.data?.rewardAmount,
          )
        } else {
          AltoonUserStore.set('userData.toonCoin', result?.data?.rewardAmount)
          AltoonUserStore.set('userData.paidCoin', result?.data?.rewardAmount)
        }
      }
    } else {
      ToastStore.networkError()
    }
  }

  const HeaderComponent = () => {
    return (
      <section className='w-full h-16 bg-white z-10 py-2 flex flex-row items-center justify-between overflow-hidden'>
        <button
          type='button'
          aria-label='back'
          className='px-3 py-2 whitespace-nowrap'
          onClick={async () => {
            navigate(-1)
          }}
        >
          <FiChevronLeft className='w-7 h-7' />
        </button>
        <div>주문/결제</div>
        <div className='w-12' />
      </section>
    )
  }

  const PurchaseItemInfoSection = ({ itemInfo }) => {
    return (
      <div className='px-4'>
        <div className='py-2 text-[4.6vw] font-bold'>주문 상품</div>
        <div className='flex flex-row pb-2 border-b border-[#f5f5f5]'>
          <img
            className='w-[20vw] h-[20vw] mr-2 mb-4'
            alt='main'
            src={`${itemInfo?.mainImageUri}`}
          />
          <div className='w-[70vw]'>
            <div className='pb-2 text-[4vw] border-b border-gray-200'>
              {itemInfo?.itemTitle || '상품명'}
            </div>
            <div className='w-full h-10 my-2 flex items-center justify-center bg-gray-300 text-gray-600 text-[3.8vw] rounded'>
              쿠폰 선택하기
            </div>

            <div className='flex flex-row text-gray-400 text-[3.6vw]'>
              <div className='w-1/2 flex flex-col items-start'>
                <div>총 상품 가격</div>
                <div>배송비</div>
                <div className='text-black'>상품 결제 금액</div>
              </div>
              <div className='w-1/2 flex flex-col items-end font-bold'>
                <div>{commaNumber(itemInfo?.teamPurchasePrice) || 0}원</div>
                <div>0원</div>
                <div className='text-black'>
                  {commaNumber(itemInfo?.teamPurchasePrice) || 0}원
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='py-2 flex flex-row justify-between text-[3.6vw]'>
          <div>주문 수량</div>
          <div className='px-4'>1</div>
        </div>
      </div>
    )
  }

  const MyCardSection = ({
    userInfo,
    cardsInfo,
    userRepresentativeCardInfo,
  }) => {
    return (
      <div className='px-4 pb-2'>
        <div className='py-2 flex flex-row justify-between text-[4.6vw]'>
          <div className='font-bold'>결제방법</div>
          <button
            type='button'
            className='flex items-center text-red-500 text-[4vw]'
            onClick={() => {
              window.location.href = `#navigate.${JSON.stringify({
                screen: 'Payment',
                prop: {
                  origin: 'webpay',
                },
              })}`
            }}
          >
            결제수단 관리 <FiChevronRight className='w-5 h-5' />
          </button>
        </div>
        <div className='mt-2 mb-3 flex flex-row justify-start items-center font-bold'>
          <div className='p-[0.6vw] bg-red-500 rounded-sm'>
            <img className='w-4 h-4' alt='main' src='/images/CheckSmall.png' />
          </div>
          <img
            className='w-6 h-6 ml-3 mr-[1.5vw]'
            alt='logo'
            src='/images/LogoApp.png'
          />
          올페이
        </div>
        {!cardsInfo?.length > 0 && (
          <button
            type='button'
            className='flex flex-col w-[80vw] mx-[10vw] py-6 mb-8 justify-center items-center bg-[#FEE9ED] rounded'
          >
            <img
              alt='pay'
              src='../images/AlwayzPay.png'
              style={{ width: 160, height: (160 * 56) / 120 }}
            />
            <div className='text-[5vw] pt-2 font-semibold'>
              한번의 카드 등록으로
            </div>
            <div className='text-[5vw] pt-2 font-semibold'>
              3초만에 결제하는 올페이
            </div>
            <div style={{ height: 16 }} />
            <button
              type='button'
              className='w-4/5 px-4 py-2 rounded bg-white text-red-500 font-bold border border-red-500'
              style={{
                height: 48,
                fontSize: 16,
                fontWeight: 'bold',
              }}
              onClick={() => {
                window.location.href = `#navigate.${JSON.stringify({
                  screen: 'Payment',
                  prop: {
                    origin: 'webpay',
                  },
                })}`
              }}
            >
              신용/체크카드 등록하기
            </button>
          </button>
        )}

        {cardsInfo?.length > 0 &&
          userInfo?.cardsInfo?.map((item) => (
            <div
              key={item.paymentMethodId}
              className={`py-3 mb-2 text-center text-[3.8vw] rounded border ${
                item?.paymentMethodId?.toString() ===
                userRepresentativeCardInfo?.paymentMethodId?.toString()
                  ? 'text-red-500 border-red-500'
                  : 'border-gray-300'
              }`}
            >
              <button
                type='button'
                onClick={() => {
                  setUserRepresentativeCardInfo({
                    paymentMethodId: item?.paymentMethodId,
                    bid: item?.bid,
                    cardCode: item?.cardCode,
                    cardFirstNumbers: item?.cardFirstNumbers,
                    cardLastNumbers: item?.cardLastNumbers,
                    cardName: item?.cardName,
                    pgCompany: item?.pgCompany || 'nice-pay',
                  })
                }}
              >
                {item.cardName}{' '}
                {`${item.cardFirstNumbers} **** **** ${item.cardLastNumbers}`}
              </button>
            </div>
          ))}
      </div>
    )
  }

  const TermsOfUse = () => {
    return (
      <div className='px-4 py-2 text-[3.6vw]'>
        <button
          type='button'
          className='w-full flexRow justify-between'
          onClick={() => {
            setShowTermsOfUse(!showTermsOfUse)
          }}
        >
          <div> 올툰 서비스 이용약관</div>
          <div className='text-gray-400'>
            {showTermsOfUse ? '닫기' : '상세보기'}
          </div>
        </button>
        {showTermsOfUse && (
          <div>
            <THImageText
              fontSize={3}
              style={{
                color: '#8E8D9B',
              }}
              text={`
            제1조 (목적, 적용범위 등)
            ① 이 약관은 주식회사 레브잇(이하 “레브잇”)의 웹툰 서비스 “올툰”에서 콘텐츠를 이용하기 위해 사용되는 전용상품권과 관련하여 레브잇과 이용자(이하 "이용자") 사이의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.\n② 이 약관에서 정하지 않은 부분은 올웨이즈 서비스 이용약관(이하 "올웨이즈 이용약관")에서 정한 바에 따릅니다.
            
            제2조 (정의)
            ① 이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
            - 1. "전용상품권"은 “올툰” 서비스에서 콘텐츠를 구매 또는 대여하기 위해 사용할 수 있는 레브잇이 발행한 전자적 증표 또는 그 증표에 관한 정보로서, 전자적 지급수단을 말하며 명칭은 레브잇의 결정에 따라 변경될 수 있습니다. 레브잇은 콘텐츠의 종류별로 별도의 전용상품권을 발행할 수 있으며 개별 전용상품권에 대한 정책에 따라 이를 운영합니다. 
            - 2. "작품별이용권"이라 함은 “올툰” 서비스에서 특정한 종류의 콘텐츠의 구매 또는 대여를 위해 사용할 수 있는 레브잇이 발행한 전자적 증표 또는 그 증표에 관한 정보로서, 대여모델에 사용되는 대여권과 구매모델에 사용되는 소장권으로 구분됩니다. 
            - 3. "전용상품권 등"이라 함은 전용상품권과 작품별이용권을 말합니다.
           
            제3조 (전용상품권의 구매 등)
            ① 레브잇은 이용자의 전용상품권 구매 전에 전용상품권에 대한 거래 조건을 정확하게 이해하고 실수나 착오 없이 거래할 수 있도록 다음 각 호의 사항들을 적절한 방법으로 이 약관 및 서비스 내에서 고지합니다.
              - 1. 판매자의 상호, 대표자 성명, 주소, 전화번호 등                    
              - 2. 전용상품권의 확인 및 선택, 구매 방법의 선택 및 결제정보의 입력, 구매 신청에 관한 확인, 종류 및 가격, 이용방법, 이용시기, 이용조건 등 구체적 내용                    
              - 3. 청약철회 및 계약해제의 기한・행사방법 및 효과, 환불 등에 관한 사항                    
              - 4. 소비자의 피해보상, 불만 처리 및 분쟁 처리에 관한 사항                    
            ② 레브잇은 이용자의 전용상품권 구매 신청이 다음 각 호에 해당하는 경우에는 승낙하지 않거나 승낙을 유보하거나, 추후 승낙을 취소할 수 있습니다. 
              - 1. 실명이 아니거나 타인의 명의를 이용한 경우 
              - 2. 허위의 정보를 기재하거나, 필수  사항을 기재하지 않은 경우 
              - 3. 서비스 관련 설비의 여유가 없거나, 기술상 또는 업무상 문제가 있는 경우 
              - 4. 동일 아이피의 대량 결제, 유사 아이디에서의 연속적인 결제, 위험 아이피에서의 결제 등 도용에 의한 전용상품권의 구매로 판단되는 경우 
              - 5. 만 19세 미만의 미성년자가 법정대리인의 동의 없이 신청한 경우 
              - 6. 결제에 대한 지급이 실제로  이루어지지 않았거나 않을 가능성이 있는 경우 
              - 7. 기타 이용자의  전용상품권 구매 신청이 레브잇과 제3자의 이익을 훼손할 위험이 있다고 판단되는 경우
            ③ 전용상품권의 구매 계약은 레브잇이 구매 완료를 신청절차 상에서 표시 또는 이용자에게 고지한 시점에 성립되며, 이때 레브잇은 제1항 각 호의 내용을 포함하여 계약체결의 내용을 즉시 이용자에게 교부합니다. 
            ④ 이용자는 이 약관 동의 및 전용상품권 구매에 따른 결제 후 서비스에서 전용상품권을 이용할 수 있습니다. 단, 구매를 전제하지 않는 무상 전용상품권 및 무상 작품별이용권의 경우에는 이 약관에 동의 후 이용할 수 있습니다. 
            ⑤ 레브잇은 이용자에게 사전에 공지하고 전용상품권 등의 종류와 가격, 지급수량 등 그 구체적인 내용을 변경하거나 특정한 전용상품권 및 작품별이용권을 폐지할 수 있습니다. 단, 그와 같은 변경 및 폐지가 이용자에게 불리한 경우에는 공지 외 “올툰” 서비스 내 전자우편 등의 전자적 수단을 통해 개별적으로 통지하여야 합니다. 
            ⑥ 레브잇이 전용상품권 등의 종류 및 내용 등을 변경 혹은 폐지함에 따라 이용자가 자신의 귀책 없이 기존에 구매한 전용상품권 등을 구매할 당시에 정한 조건 등에 따라 이용하지 못하게 될 경우, 레브잇은 그와 같이 이용하지 못한 부분에 대해서 회원에게 적절한 보상을 합니다. 
           
            제4조 (청약철회 등)
            ① 이용자는 전자상거래 등에서의 소비자 보호에 관한 법률 등 관련 법령에서 정한 바에 따라 유상으로 구매한 전용상품권에 대하여 계약체결에 대한 내용을 교부 받은 시점 혹은 구매한 전용상품권을 제공받은 시점으로부터 7일 이내에 청약의 철회 및 계약의 해제(이하 "청약철회등")를 할 수 있습니다. 단, 이용자가 구매한 전용상품권을 이미 사용한 경우에는 사용한 부분에 대해서는 청약철회등이 불가능하며, 이러한 불가능 사유에 대해서는 전용상품권 구매 시 레브잇이 이용자에게 명확하게 고지합니다. 
            ② 전항에도 불구하고 전용상품권의 내용이 표시・광고의 내용과 다르거나 계약내용과 다르게 이행된 경우에는 이용자는 그 전용상품권을 공급받은 날부터 3개월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에 청약철회등을 할 수 있습니다. 
            ③ 제1항 또는 제2항에 따라서 이용자가 청약철회등을 한 경우 전자상거래 등에서의 소비자 보호에 관한 법률에 따라 이용자가 구매한 전용상품권은 반환되며, 레브잇은 동법에 따라 환급 절차를 진행합니다. 
            ④ 그 밖에 본 조에 따른 청약철회등에 대한 사항은 전자상거래 등에서의 소비자 보호에 관한 법률에 따라 규율 및 처리됩니다.
           
            제5조 (환불정책)
            ① 레브잇은 이용자가 구매한 전용상품권의 미사용분에 대해 환불을 요구할 경우 환불수수료에 해당하는 금액을 공제하고 환불할 수 있습니다. 단, 레브잇의 귀책으로 인한 경우에는 환불수수료를 공제하지 않습니다. 이용자가 전용상품권의 구매 시 함께 지급된 추가 무상전용상품권을 사용한 경우에는 유상 전용상품권의 미사용분에서 해당 분량을 제외하고 환불됩니다. 
            ② 무상 전용상품권은 전항의 환불 대상에서 제외됩니다. 
            ③ 레브잇은 이용자에 대하여 환불을 신청한 날부터 3영업일 이내에 그 결제와 동일한 방법으로 이를 환불합니다. 
            ④ 이용자가 구매한 작품별이용권의 미사용분에 대해 환불을 요구할 경우 환불을 신청한 날부터 3영업일 이내에 그 결제와 동일한 방법으로 이를 환불합니다. 단, 작품별이용권의 구매에 사용한 전용상품권 중 유효기간이 만료된 무상 전용상품권이 있는 경우 이를 제외하고 환불합니다. 
            ⑤ 무상 작품별이용권은 전항의 환불 대상에서 제외됩니다. 
            ⑥ 무상 이용권과 유상 이용권을 함께 보유한 경우, 유상 이용권부터 차감됩니다.
            
            제6조 (유효기간 등)
            ① 유상 전용상품권과 무상 전용상품권의 기본 유효기간은 충전일로부터 1개월입니다. 전용상품권 획득 시 별도의 조건 혹은 유효기간이 명시될 수 있으며, 별도의 안내가 없는 경우 기본 유효기간과 같은 것으로 간주합니다. 
            ② 유상 작품별이용권과 무상 작품별이용권의 기본 유효기간은 충전일로부터 1개월입니다. 작품별이용권 획득 시 별도의 조건 혹은 유효기간이 명시될 수 있으며, 별도의 안내가 없는 경우 기본 유효기간과 같은 것으로 간주합니다.
            
            제7조 (기타)
            ① 레브잇은 소비자의 피해보상, 불만 처리 및 분쟁 처리에 관한 사항, 환불에 대한 사항 등 소비자의 문제 제기에 대해 해결하기 위해서 고객센터를 운영합니다. 
            
            [부칙]\n 이 약관은 2023년 12월 1일부터 적용됩니다.`}
            />
          </div>
        )}
      </div>
    )
  }

  const BottomOrderBar = () => {
    return (
      <div className='fixed bottom-0 px-4 py-2 w-full h-[18vw] flex flex-row justify-center bg-white border-t border-gray-200'>
        <button
          type='button'
          className={`py-2 flex flex-col items-center justify-center w-[90vw] ${
            isLoading || !userRepresentativeCardInfo?.paymentMethodId
              ? 'bg-gray-500'
              : 'bg-red-500'
          } rounded text-white text-center text-[4.4vw] font-bold`}
          disabled={isLoading}
          onClick={async () => {
            if (isLoading) {
              return
            }
            setIsLoading(true)
            if (!userRepresentativeCardInfo?.paymentMethodId) {
              setModalContent(
                `선택된 결제수단이 없어요.\n결제하실 카드를 선택해주세요.`,
              )
              setShowModal(true)
              setIsLoading(false)
              return
            }
            if (userInfo?.defaultPaymentMethod) {
              const orderInfo = await backendApis.logToonTicketOrder(
                itemInfo?.teamPurchasePrice,
                itemInfo,
                userRepresentativeCardInfo,
              )
              if (!orderInfo?.data?._id) {
                setModalContent(
                  `결제 과정에서 문제가 생겼어요.\n잠시 후 다시 시도해주세요.`,
                )
                setShowModal(true)
              } else if (
                userInfo?.defaultPaymentMethod?.paymentMethodType === 'nice-pay'
              ) {
                if (userRepresentativeCardInfo?.bid) {
                  console.log(`nicepay billing pay`)
                  const result = await backendApis.requestWebBillingPay(
                    userInfo?.defaultPaymentMethod?.paymentMethodType,
                    'toon',
                    orderInfo?.data,
                    userRepresentativeCardInfo?.paymentMethodId,
                    userRepresentativeCardInfo?.bid,
                    new Date(),
                  )
                  console.log(`requestWebBillingPay result`, result)

                  if (result.data?.data?.ResultCode === '3001') {
                    /**
                     * 결제 성공
                     */
                    handlePaymentSuccess(
                      itemInfo?._id,
                      result?.data?.alwayzOrderId,
                    )
                    navigate(
                      `/altoon-ticket?token=${AuthStore?.token}&purchased=true`,
                    )
                  } else {
                    backendApis.registerWebPaymentErrorLog({
                      errorType: 'nicepay billing pay',
                      errorCause: 'server',
                      errorResult: result,
                    })

                    if (
                      result.data?.data?.ResultCode !== '3001' &&
                      result.data?.data?.ResultCode !== 'A225' &&
                      typeof result?.data?.msg === 'string'
                    ) {
                      setModalContent(
                        `결제 과정에서 문제가 생겼어요.\n잠시 후 다시 시도해주세요.`,
                      )
                      setShowModal(true)

                      // 카드결제 오류 로깅 및 알러트
                    } else if (
                      result?.status !== 200 ||
                      (result.data?.data?.ResultCode !== '3001' &&
                        result.data?.data?.ResultCode !== 'A225')
                    ) {
                      // 결제 불가 케이스 분류 시작
                      if (
                        result.data?.data?.ResultCode === '1146' ||
                        result.data?.data?.ResultCode === '8323'
                      ) {
                        // 잔액부족.
                        setModalContent(
                          `잔액이 부족해요.\n확인 후 다시 시도해주세요.`,
                        )
                      } else if (
                        result.data?.data?.ResultCode === '1082' ||
                        result.data?.data?.ResultCode === '9305' ||
                        result.data?.data?.ResultCode === '1091' ||
                        result.data?.data?.ResultCode === '8521'
                      ) {
                        // 한도초과.
                        setModalContent(
                          `사용 한도가 초과되었어요.\n확인 후 다시 시도해주세요.`,
                        )
                      } else if (
                        result.data?.data?.ResultCode === '1141' ||
                        result.data?.data?.ResultCode === '8324'
                      ) {
                        // 거래정지.
                        setModalContent(
                          `거래가 정지된 카드에요.\n확인 후 다시 시도해주세요.`,
                        )
                      } else if (result.data?.data?.ResultCode === '1140') {
                        // 도난/분실 카드
                        setModalContent(
                          `도난/분실 신고가 된 카드에요.\n확인 후 다시 시도해주세요.`,
                        )
                      } else if (result.data?.data?.ResultCode === '1037') {
                        // 교체발급된 카드 존재
                        setModalContent(
                          `교체 발급된 카드가 있어요.\n새로 발급된 카드로 다시 시도해주세요.`,
                        )
                      } else if (result.data?.data?.ResultCode === '3021') {
                        // 유효기간 만료
                        setModalContent(
                          `유효기간이 만료된 카드에요.\n확인 후 다시 시도해주세요.`,
                        )
                      } else if (
                        result.data?.data?.ResultCode === '1186' ||
                        result.data?.data?.ResultCode === '1189'
                      ) {
                        // 카드사 시스템 점검시간
                        setModalContent(
                          `카드사 시스템 점검시간이에요.\n잠시 후 다시 시도해주세요.`,
                        )
                      } else if (result.data?.data?.ResultCode === '1145') {
                        // 해지된 카드
                        setModalContent(
                          `해지된 카드에요.\n확인 후 다시 시도해주세요.`,
                        )
                      } else {
                        setModalContent(
                          `기존 카드 정보가 유효하지 않아요.\n결제 수단을 새로 등록해주세요.`,
                        )
                      }
                      setModalContent(
                        `결제 과정에서 문제가 생겼어요.\n잠시 후 다시 시도해주세요.`,
                      )
                      setShowModal(true)
                    }
                  }
                }
              } else if (
                userInfo?.defaultPaymentMethod?.paymentMethodType === 'toss-pay'
              ) {
                if (userRepresentativeCardInfo?.bid) {
                  const result = await backendApis.requestWebBillingPay(
                    userInfo?.defaultPaymentMethod?.paymentMethodType,
                    'toon',
                    orderInfo?.data,
                    userRepresentativeCardInfo?.paymentMethodId,
                    userRepresentativeCardInfo?.bid,
                    new Date(),
                  )

                  if (
                    result?.status !== 200 ||
                    result.data?.data?.status !== 'DONE'
                  ) {
                    backendApis.registerWebPaymentErrorLog({
                      errorType: 'tosspay billing pay',
                      errorCause: 'server',
                      errorResult: result,
                    })
                    setModalContent(
                      `기존 카드 정보가 유효하지 않아요.\n결제 수단을 새로 등록해주세요.`,
                    )
                    setShowModal(true)
                  } else if (result.data?.data?.status === 'DONE') {
                    /**
                     * 결제 성공
                     */
                    handlePaymentSuccess(
                      itemInfo?._id,
                      result?.data?.alwayzOrderId,
                    )
                    navigate(
                      `/altoon-ticket?token=${AuthStore?.token}&purchased=true`,
                    )
                  }
                } else {
                  setModalContent(
                    `결제 과정에서 문제가 생겼어요.\n잠시 후 다시 시도해주세요.`,
                  )
                  setShowModal(true)
                }
              } else if (
                userInfo?.defaultPaymentMethod?.paymentMethodType === 'payple'
              ) {
                if (userRepresentativeCardInfo?.bid) {
                  const result = await backendApis.requestWebBillingPay(
                    userInfo?.defaultPaymentMethod?.paymentMethodType,
                    'toon',
                    orderInfo?.data,
                    userRepresentativeCardInfo?.paymentMethodId,
                    userRepresentativeCardInfo?.bid,
                    new Date(),
                  )

                  if (
                    result?.status !== 200 ||
                    result.data.PCD_PAY_RST !== 'success'
                  ) {
                    backendApis.registerWebPaymentErrorLog({
                      errorType: 'payple billing pay',
                      errorCause: 'server',
                      errorResult: result,
                    })
                    setModalContent(
                      `기존 카드 정보가 유효하지 않아요.\n결제 수단을 새로 등록해주세요.`,
                    )
                    setShowModal(true)
                  } else {
                    /**
                     * 결제 성공
                     */
                    handlePaymentSuccess(
                      itemInfo?._id,
                      result?.data?.alwayzOrderId,
                    )
                    navigate(
                      `/altoon-ticket?token=${AuthStore?.token}&purchased=true`,
                    )
                  }
                } else {
                  setModalContent(
                    `결제 과정에서 문제가 생겼어요.\n잠시 후 다시 시도해주세요.`,
                  )
                  setShowModal(true)
                }
              }
            } else {
              setModalContent(
                `결제 과정에서 문제가 생겼어요.\n잠시 후 다시 시도해주세요.`,
              )
              setShowModal(true)
            }
            setIsLoading(false)
          }}
        >
          <div>{commaNumber(itemInfo?.teamPurchasePrice) || 0}원 결제하기</div>
        </button>
      </div>
    )
  }

  const AlertModal = ({ setShowModal, modalContent }) => {
    return (
      <>
        <div className='bg-[#43434350] w-full h-full fixed top-0' />
        <div className='absolute w-[80vw] h-[30vh] top-[30vh] left-[10vw] rounded-lg text-center font-bold bg-white'>
          <div className='relative px-2 whitespace-normal top-[7vh] text-[5vw]'>
            <THImageText fontSize='4vw' text={modalContent} />
          </div>
          <button
            type='button'
            className='w-full py-3 text-white bg-red-500 absolute rounded-b-lg bottom-0 left-0'
            onClick={() => {
              setShowModal(false)
            }}
          >
            확인
          </button>
        </div>
      </>
    )
  }

  return (
    <div className={`${showModal ? 'pb-20 fixed' : 'pb-20'}`}>
      <HeaderComponent />
      {itemInfo && <PurchaseItemInfoSection itemInfo={itemInfo} />}
      <div className='w-full my-1 h-2 bg-gray-100' />
      {userInfo && (
        <MyCardSection
          userInfo={userInfo}
          cardsInfo={cardsInfo}
          userRepresentativeCardInfo={userRepresentativeCardInfo}
        />
      )}

      <div className='w-full my-1 h-2 bg-gray-100' />
      <TermsOfUse />
      <div className='w-full my-1 h-2 bg-gray-100' />
      <div className='py-4 text-center text-[3.6vw] font-bold'>
        주문 내용을 확인하였으며, 이용약관에 동의합니다.
      </div>
      <BottomOrderBar />
      {showModal && (
        <AlertModal setShowModal={setShowModal} modalContent={modalContent} />
      )}
      {isLoading && <LoadingIndicator isLoading={isLoading} />}
    </div>
  )
}

export default AltoonTicketPurchasePage
