import commaNumber from 'comma-number'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import AnimatedNumbers from 'react-animated-numbers'
import { FiChevronRight } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import { BottomSheet } from 'react-spring-bottom-sheet'
import { useSearchParam } from 'react-use'

import DailyCheckInAction from '../../actions/DailyCheckInAction'
import BlackTextTimer from '../../components/BlackTextTimer'
import CouponHorizontalList from '../../components/CouponHorizontalList'
import CouponPackBottomSheet from '../../components/CouponPackBottomSheet'
import DailyCheckInScreenBottomSheetItem from '../../components/DailyCheckInScreenBottomSheetItem'
import ScrolltoTopButton from '../../components/atoms/ScrollToTopButton'
import Button from '../../components/atoms/button'
import ConfirmAlert from '../../components/atoms/confirmAlert'
import LoadingIndicator from '../../components/atoms/loadingIndicator'
import HeaderComponent from '../../components/dailyCheckIn/HeaderComponent'
import SubMenuButtonGroup from '../../components/dailyCheckIn/SubMenuButtonGroup'
import DailyCheckInItemSection from '../../components/deal/section/dailyCheckInItemSection'
import { AlarmNudge, Close, PointUpgrade } from '../../images/dailyCheckIn'
import DailyCheckInModalHandler from '../../modals/dailyCheckIn/DailyCheckInModalHandler'
import AuthStore from '../../stores/AuthStore'
import UserStore from '../../stores/UserStore'
import THModalStore from '../../stores/treasureHunt/THModalStore'
import AB from '../../utils/ab'
import backendApis from '../../utils/backendApis'
import useCheckCodePushVersion from '../../utils/useConvertCodePushVersion'

const yellowMoney = require('../../images/dailyCheckIn/yellowMoney.png')

let errorCode = 0
let buttonPressed = false
let hidden
let visibilityChange
if (typeof document.hidden !== 'undefined') {
  // Opera 12.10 and Firefox 18 and later support
  hidden = 'hidden'
  visibilityChange = 'visibilitychange'
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden'
  visibilityChange = 'msvisibilitychange'
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden'
  visibilityChange = 'webkitvisibilitychange'
}

const type = 'ey-daily-check-in-220410-v1'
const lastActiveDate = Date.now()
const sleep = (ms) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms)
  })
const periodicMissionCoolTimeTextHandler = (
  leftCoolTime,
  timeMissionCount,
  availableAtNextDay,
  text = false,
) => {
  if (!text) {
    if (timeMissionCount >= 6 || availableAtNextDay || leftCoolTime <= 0)
      return ''
    if (leftCoolTime % 60 === 0) {
      return `${Math.floor(leftCoolTime / 60)}시간`
    }
    if (Math.floor(leftCoolTime / 60) === 0) {
      return `${leftCoolTime % 60}분`
    }
    return `${Math.floor(leftCoolTime / 60)}시간 ${leftCoolTime % 60}분`
  }
  if (text) {
    if (timeMissionCount < 6 && leftCoolTime <= 0)
      return '지금 포인트 받아가세요'
    if (timeMissionCount >= 6 || availableAtNextDay) return '내일 또 봐요 🙋🏻‍♀️'
    return ' 후에 가능해요'
  }
}

const DailyCheckInPage = observer(() => {
  const navigate = useNavigate()
  const token = useSearchParam('token')
  const alwayzInvitorId = useSearchParam('alwayzInvitorId')
  const checkInInvitorName = useSearchParam('checkInInvitorName')
  const code = useSearchParam('code')
  const codePushVersionAvailable = useCheckCodePushVersion()

  const [userInfo, setUserInfo] = useState('')
  const [animateButton, setAnimateButton] = useState(true)
  const [animatePoints, setAnimatePoints] = useState(false)
  const [checkInAlarmConsent, setCheckInAlarmConsent] = useState(false)
  const [checkInAlarmTime, setCheckInAlarmTime] = useState(false)
  const [showCheckInDeal, setShowCheckInDeal] = useState(false)
  const [checkInDealLastSeenAt, setCheckInDealLastSeenAt] = useState(new Date())
  const [today, setToday] = useState(new Date())
  const [newUserBonus, setNewUserBonus] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const [openCouponModal, setOpenCouponModal] = useState(false)
  const [openLoginModal, setOpenLoginModal] = useState(false)
  const [openErrorModal, setOpenErrorModal] = useState(false)
  const [modalConfig, setModalConfig] = useState({ visible: false })
  const [missionConfig, setMissionConfig] = useState({
    intialMission: { isShow: false, isDisable: true },
    alfarmEvent: { isShow: false, isDisable: true },
    timeMission: { isShow: true, isDisable: true },
    viewMission: { isShow: true, isDisable: true },
    inviteMission: { isShow: true, isDisable: true },
    newInviteMission: { isShow: true, isDisable: true },
    quizMisson: { isShow: false, isDisable: true },
    alarmSetMission: { isShow: false, isDisable: true },
    rewardedAdMission: { isShow: false, isDisable: true },
  })
  const [weeklyStampData, setWeeklyStampData] = useState({
    checkIn7DaysList: [{}, {}, {}, {}, {}, {}, {}],
  })
  const [dailyCheckInRewardHistory, setDailyCheckInRewardHistory] = useState([
    {},
    {},
    {},
    {},
    {},
    {},
  ])
  const [accumulatedPoints, setAccumulatedPoints] = useState(0)
  const [openTeamStatusModal, setOpenTeamStatusModal] = useState(false)
  const [couponPackData, setCouponPackData] = useState([])
  const [consecutiveCheckInData, setConsecutiveCheckInData] = useState([])
  const couponPackAB = AB(UserStore?.userInfo?._id, 'pack0824', [50])

  const loadCouponPackData = async () => {
    const couponPackData = await backendApis.couponPackData()
    if (couponPackData?.status === 200 && couponPackData?.data?.length > 0) {
      setCouponPackData(couponPackData?.data)
    }
  }

  const loadAllDailyCheckInData = async () => {
    const userData = await backendApis.getUserBasicInfoWithAuthentication(token)

    if (userData?.status === 200) {
      setUserInfo(userData?.data)
    }
    await loadCouponPackData()

    const totalResult = await backendApis.logDailyCheckInScreen(type)
    if (totalResult?.status !== 200) {
      errorCode = 9
      setOpenErrorModal(true)
    } else {
      const [
        result,
        date,
        todayRewards,
        dailyCheckInLevelData,
        checkInGuestInfo,
        todayCheckInNewInvitation,
        checkInAlarmConsent,
        checkInPurchaseReward,
      ] = await Promise.all([
        backendApis.getCheckInPoint(),
        backendApis.getCurrentDate(),
        backendApis.getTodayRewards(),
        backendApis.getDailyCheckInLevelData(),
        backendApis.getCheckInGuestInfo(),
        backendApis.getTodayCheckInNewInvitationHistory(),
        backendApis.getUserCheckInPushNotificationSetting(),
        backendApis.purchaseReward(),
      ])
      if (
        result?.status !== 200 ||
        date?.status !== 200 ||
        todayRewards?.status !== 200 ||
        dailyCheckInLevelData?.status !== 200 ||
        checkInGuestInfo?.status !== 200 ||
        todayCheckInNewInvitation?.status !== 200 ||
        checkInAlarmConsent?.status !== 200
      ) {
        if (todayRewards?.status !== 200) {
          errorCode = 1
        }
        if (dailyCheckInLevelData?.status !== 200) {
          errorCode = 2
        }
        if (result?.status !== 200) {
          errorCode = 3
        }
        if (date?.status !== 200) {
          errorCode = 4
        }
        if (checkInGuestInfo?.status !== 200) {
          errorCode = 5
        }
        if (todayCheckInNewInvitation?.status !== 200) {
          errorCode = 6
        }
        if (checkInAlarmConsent?.status !== 200) {
          errorCode = 7
        }
        setOpenErrorModal(true)
        return
      }
      if (
        checkInPurchaseReward?.status === 200 &&
        checkInPurchaseReward?.data?.length > 0
      ) {
        setModalConfig({
          ...modalConfig,
          visible: true,
          modalType: 'DailyCheckInPurchaseReward',
          rewardData: checkInPurchaseReward?.data,
          goBackToMain: async () => {
            setModalConfig({ ...modalConfig, visible: false })
            const result = await backendApis.receiveCheckInPurchaseReward(
              checkInPurchaseReward?.data,
            )
            if (result.status === 200) {
              if (result.data?.totalRewardPoint > 0) {
                setModalConfig({
                  ...modalConfig,
                  visible: true,
                  modalType: 'DailyCheckInScreenRewardReceiveModal',
                  rewardAmountForDisplay: result?.data?.totalRewardPoint,
                  isConsecutiveBonusAdded: false,
                  setModalConfig,
                })
              } else if (result.data?.totalRewardFood > 0) {
                setModalConfig({
                  ...modalConfig,
                  visible: true,
                  modalType: 'DailyCheckInFoodReceiveModal',
                  rewardAmount: result?.data?.totalRewardFood,
                })
              }
              loadAllDailyCheckInData()
            }
          },
        })
      }

      if (
        result?.data[0]?.dailyCheckInMonthAt?.length === 1 &&
        result?.data[0]?.dailyCheckInMonthAt[0]?.date ===
          moment().subtract(1, 'days').format('YYYY-MM-DD')
      ) {
        setNewUserBonus(true)
      }

      if (result?.data[0]?.consecutiveCheckInInfo) {
        const consecutiveDays =
          result?.data[0]?.consecutiveCheckInInfo?.consecutiveCheckInLog?.length
        const lastCheckInDay =
          result?.data[0]?.consecutiveCheckInInfo?.consecutiveCheckInLog[
            consecutiveDays - 1
          ]
        const streakStopped =
          !moment(lastCheckInDay).isSame(moment().subtract(1, 'days'), 'day') &&
          !moment(lastCheckInDay).isSame(moment(), 'day')

        if (consecutiveDays > 0) {
          if (consecutiveDays > 6 || streakStopped) {
            await backendApis.resetConsecutiveCheckInLog(streakStopped)
          } else {
            setConsecutiveCheckInData(result?.data[0]?.consecutiveCheckInInfo)
          }
        }
      } else {
        setConsecutiveCheckInData({
          consecutiveCheckInLog: [],
          consecutiveCheckInCount: 0,
        })
      }

      if (result?.data[0]?.checkInDealLastSeenAt) {
        const now = moment()
        const lastSeen = moment(result?.data[0]?.checkInDealLastSeenAt)
        const tomorrow = moment(lastSeen).add(1, 'day')
        if (now.diff(tomorrow, 'hours') < 0) {
          setCheckInDealLastSeenAt(new Date(tomorrow))
          setShowCheckInDeal(true)
        }
      }

      if (checkInAlarmConsent?.status === 200) {
        const result = await UserStore.loadCheckInNotificationSettings()
        if (result) {
          setCheckInAlarmConsent(
            result?.data?.pushNotificationSetting.receivingCheckInNotification,
          )
          setCheckInAlarmTime(
            result.data?.pushNotificationSetting
              ?.receivingCheckInNotificationTime,
          )
        }
      }

      setToday(new Date(date?.data))
      if (result?.status === 200 && result.data?.length > 0) {
        const latestDailyCheckInData = result?.data[0]

        if (latestDailyCheckInData?.loggedAt?.length <= 1) {
          setModalConfig({
            ...modalConfig,
            visible: true,
            modalType: 'DailyCheckInTutorial',
          })
        }

        if (
          // 포인트 소멸예정되어 있는데 아직 안 알려줬으면 알려주기
          !latestDailyCheckInData?.expireNotifiedAt ||
          moment(latestDailyCheckInData?.expireNotifiedAt).isBefore(
            moment().startOf('month'),
          )
        ) {
          const expireHistoryData = await backendApis.getExpiringPoint()
          const expireDuePoints = expireHistoryData?.data?.filter(
            (data) =>
              data.month === parseInt(moment().format('MM'), 10) &&
              data.year === parseInt(moment().format('YYYY'), 10) &&
              data.expiredPoints > 0,
          )
          if (
            expireHistoryData?.data?.length > 0 &&
            expireDuePoints?.length > 0
          ) {
            setModalConfig({
              ...modalConfig,
              visible: true,
              modalType: 'ExpirePointModal',
              expireDuePoints,
            })
            backendApis.logExpirePointNotification()
          }
        }
        UserStore.setAccumulatedPoints(
          latestDailyCheckInData?.totalCheckInPoints || 0,
        )
        const checkIn7DaysList = []
        const pastWeekDateString = [...Array(7).keys()]
          .map((days) =>
            moment(new Date(new Date(date?.data) - 86400000 * days)).format(
              'YYYY-MM-DD',
            ),
          )
          .reverse()

        for (const dateString of pastWeekDateString) {
          checkIn7DaysList.push({
            date: dateString,
            didOpen: !!latestDailyCheckInData?.dailyCheckInMonthAt?.find(
              (el) => {
                return el?.date === dateString && el?.didOpen === true
              },
            ),
          })
        }

        setWeeklyStampData({ checkIn7DaysList, today: new Date(date?.data) })

        if (todayRewards?.result?.dailyCheckInRewardHistory?.length > 0) {
          dailyCheckInRewardHistory?.splice(
            0,
            todayRewards?.result?.dailyCheckInRewardHistory?.length + 1,
            ...todayRewards?.result?.dailyCheckInRewardHistory,
            { isShaking: true },
          )
        } else {
          dailyCheckInRewardHistory?.splice(0, 1, {
            isShaking: true,
            rewardType: 'initial-check-in',
            didOpen: false,
            rewardAmount: 0,
          })
        }

        setDailyCheckInRewardHistory(dailyCheckInRewardHistory)

        const isTutorial =
          latestDailyCheckInData?.solvedQuizList === undefined ||
          latestDailyCheckInData?.solvedQuizList.length < 2
        let solvedQuizList = latestDailyCheckInData?.solvedQuizList
        if (!solvedQuizList) solvedQuizList = []
        // const newQuizData = await backendApis.getDailyCheckInQuizData(
        //   isTutorial,
        //   solvedQuizList,
        // )

        let lastBonusAt = moment(today).subtract(1, 'days')
        if (latestDailyCheckInData?.periodicBonusMissionLastClickedAt) {
          lastBonusAt = latestDailyCheckInData.periodicBonusMissionLastClickedAt
        }
        const lastBonusDate = moment(lastBonusAt).startOf('day')
        const fatestAvailableTime = moment(lastBonusAt).add(
          dailyCheckInLevelData?.result?.periodicBonusMissionCoolTime,
          'hours',
        )

        let leftCoolTime = 0
        if (
          moment.duration(fatestAvailableTime.diff(lastBonusDate)).asDays() >= 1
        ) {
          leftCoolTime = Number(
            lastBonusDate.add(1, 'day').diff(moment(today), 'minutes'),
          )
        } else {
          leftCoolTime = Number(
            fatestAvailableTime.diff(moment(today), 'minutes'),
          )
        }

        let tmpAlfarmEventObject = {}

        if (
          moment(new Date(latestDailyCheckInData?.checkInLog?.[0]?.checkedAt))
            .add(2, 'days')
            .format('YYYY-MM-DD') > moment(today).format('YYYY-MM-DD')
        ) {
          const alfarmLogs = await backendApis.getAlfarmTwoDaysLogs()
          if (alfarmLogs?.status === 200) {
            if (latestDailyCheckInData?.checkInLog) {
              const firstCheckInDate = moment(
                new Date(latestDailyCheckInData?.checkInLog?.[0]?.checkedAt),
              )
              const diffDays = moment(today).diff(firstCheckInDate, 'days')
              if (diffDays === 0) {
                tmpAlfarmEventObject = {
                  ...tmpAlfarmEventObject,
                  isShow: true,
                }
              } else if (diffDays === 1) {
                if (
                  alfarmLogs?.data?.find(
                    (item) =>
                      item.createdDate ===
                      moment(today).subtract(1, 'd').format('YYYY-MM-DD'),
                  )
                ) {
                  tmpAlfarmEventObject = {
                    ...tmpAlfarmEventObject,
                    isShow: true,
                  }
                }
              }
            } else {
              tmpAlfarmEventObject = {
                ...tmpAlfarmEventObject,
                isShow: true,
              }
            }

            if (!alfarmLogs.data) {
              tmpAlfarmEventObject = {
                ...tmpAlfarmEventObject,
                alfarmPlayCount: 0,
              }
            } else if (latestDailyCheckInData?.checkInLog) {
              const tempAlfarmLogs = alfarmLogs?.data?.filter(
                (item) =>
                  item.createdDate >=
                  moment(
                    new Date(latestDailyCheckInData?.checkInLog[0].checkedAt),
                  ).format('YYYY-MM-DD'),
              )
              tmpAlfarmEventObject = {
                ...tmpAlfarmEventObject,
                alfarmPlayCount: tempAlfarmLogs.length,
              }
              if (
                alfarmLogs.data.find(
                  (item) => item.createdDate === moment().format('YYYY-MM-DD'),
                )
              ) {
                tmpAlfarmEventObject = {
                  ...tmpAlfarmEventObject,
                  isPlayAlfarmToday: true,
                }
              }
              if (tempAlfarmLogs?.length >= 2) {
                tmpAlfarmEventObject = {
                  ...tmpAlfarmEventObject,
                  isFirstImpressionDisable: false,
                }
              }
            } else {
              tmpAlfarmEventObject = {
                ...tmpAlfarmEventObject,
                alfarmPlayCount: 0,
              }
            }
          }
        }

        const tmpTodayRewards = todayRewards?.result?.dailyCheckInRewardHistory
        const tmpMissionConfig = {
          ...missionConfig,
          alfarmEvent: {
            ...missionConfig.alfarmEvent,
            ...tmpAlfarmEventObject,
            isDisable: tmpTodayRewards?.find(
              (e) => e.rewardType === 'alfarm-first-user-event',
            ),
          },
          alarmSetMission: {
            ...missionConfig?.alarmSetMission,
            isShow: !(
              latestDailyCheckInData?.alarmMissionCompleted ||
              latestDailyCheckInData?.pushNotificationSetting
                ?.receivingCheckInNotification
            ),
            isDisable: latestDailyCheckInData?.alarmMissionCompleted,
          },
          timeMission: {
            ...missionConfig?.timeMission,
            isDisable:
              (tmpTodayRewards?.filter((x) => {
                return (
                  x?.rewardType === 'periodic-bonus-mission' &&
                  x?.rewardAmount > 0
                )
              })?.length >= 6 ||
                leftCoolTime > 0) &&
              latestDailyCheckInData?.periodicBonusMissionLastClickedAt,
            todayCount: tmpTodayRewards?.filter((x) => {
              return (
                x?.rewardType === 'periodic-bonus-mission' &&
                x?.rewardAmount > 0
              )
            })?.length,
            leftCoolTime,
            availableAtNextDay:
              moment(today).format('YYYY-MM-DD') !==
              fatestAvailableTime.format('YYYY-MM-DD'),
          },
          // viewMission: {
          //   ...missionConfig?.viewMission,
          //   isDisable: !!todayRewards?.result?.dailyCheckInRewardHistory?.find(
          //     (x) => {
          //       return (
          //         x?.rewardType === 'time-attack-mission' && x?.rewardAmount > 0
          //       )
          //     },
          //   ),
          // },
          newInviteMission: {
            ...missionConfig?.newInviteMission,
            todayTotalNewInviteNum:
              (tmpTodayRewards || [])?.filter((x) => {
                return (
                  x?.rewardType === 'invite-friend-new' && x?.rewardAmount > 0
                )
              })?.length || 0,
            isDisable:
              tmpTodayRewards?.filter((x) => {
                return (
                  x?.rewardType === 'invite-friend-new' && x?.rewardAmount > 0
                )
              })?.length >= 6,
          },
          // quizMisson: {
          //   ...missionConfig?.quizMisson,
          //   isShow: !!newQuizData?.result,
          //   isDisable:
          //     !newQuizData?.result ||
          //     todayRewards?.result?.dailyCheckInRewardHistory?.filter((x) => {
          //       return x?.rewardType === 'quiz' && x?.rewardAmount > 0
          //     })?.length > 0,
          //   quizData: newQuizData?.result,
          // },
          browsingMisson: {
            ...missionConfig?.browsingMisson,
            isDisable: tmpTodayRewards?.find(
              (e) =>
                e?.rewardType === 'time-attack-mission-pointBrowsingMission' &&
                e?.rewardAmount > 0,
            ),
          },
          cashbackMission: {
            ...missionConfig?.cashbackMission,
            isDisable: tmpTodayRewards?.find(
              (e) =>
                e?.rewardType === 'cashback-mission' && e?.rewardAmount > -1,
            ),
          },
          rewardedAdMission: {
            ...missionConfig?.rewardedAdMission,
            isDisable:
              tmpTodayRewards?.filter(
                (e) => e?.rewardType === 'rewardedAD-mission',
              )?.length >= 1,
          },
        }
        setMissionConfig(tmpMissionConfig)

        if (
          todayCheckInNewInvitation?.data?.guestInfoList?.slice(-1)[0]
            ?.didOpen === false &&
          (todayRewards?.result?.dailyCheckInRewardHistory || [])?.filter(
            (x) => {
              return x?.rewardType === 'initial-check-in'
            },
          )?.length > 0
        ) {
          setModalConfig({
            ...modalConfig,
            visible: true,
            modalType: 'DailyCheckInInviteFriendsNewModal',
            handleCheckInButton,
            missionConfig: tmpMissionConfig,
            setModalConfig,
            todayTotalNewInviteNum:
              (todayRewards?.result?.dailyCheckInRewardHistory || [])?.filter(
                (x) => {
                  return x?.rewardType === 'invite-friend-new'
                },
              )?.length || 0,
            weeklyStampData: { checkIn7DaysList, today: new Date(date?.data) },
          })
        }

        // 초대로 들어온 고객 로직
        if (alwayzInvitorId) {
          const isNewUserCheckIn = await backendApis.getIsCheckInInviteNewUser()
          if (UserStore.userInfo.needsLogin) {
            setOpenLoginModal(true)
          } else if (
            !UserStore.userInfo.needsLogin &&
            alwayzInvitorId &&
            isNewUserCheckIn?.data === true
          ) {
            await backendApis.logNewUserBenefitLog(code, alwayzInvitorId)
            // 신규 유저의 경우
            if (code === 'CheckInPigRoulette') {
              if (alwayzInvitorId !== UserStore?.userInfo?._id) {
                const missionData = await backendApis.pigMissionData()

                if (
                  missionData?.data?.participatedPigRouletteInvite ||
                  missionData?.data?.participatedPigRouletteInvite === undefined
                ) {
                  const ticketRewardResult =
                    await backendApis.giveRouletteTicketWithUserId(
                      alwayzInvitorId,
                      'CheckInPig',
                      'dailyRoulette',
                      'checkInPigRouletteInvite',
                      15,
                    )
                  if (
                    ticketRewardResult?.status === 200 &&
                    ticketRewardResult?.data !==
                      'invitors mission already cleared'
                  ) {
                    // 지급 & 로깅 완료

                    await backendApis.pigData()
                    await backendApis.logRewardTime(
                      'participatedPigRouletteInvite',
                    )
                    setModalConfig({
                      ...modalConfig,
                      visible: true,
                      modalType: 'CheckInPigRouletteInvite',
                      setShowTutorialModal: () => {
                        if (
                          (
                            todayRewards?.result?.dailyCheckInRewardHistory ||
                            []
                          )?.filter((x) => {
                            return x?.rewardType === 'initial-check-in'
                          })?.length === 0
                        ) {
                          setModalConfig({
                            ...modalConfig,
                            visible: true,
                            modalType: 'DailyCheckInTutorial',
                          })
                        }
                      },
                      checkInInvitorName,
                      alreadyParticipated: false,
                      invitorMissionAlreadyCleared:
                        ticketRewardResult?.data ===
                        'invitors mission already cleared',
                    })
                  } else {
                    // 이미 Invitor를 다른 사람이 도와줌

                    setModalConfig({
                      ...modalConfig,
                      visible: true,
                      modalType: 'CheckInPigRouletteInvite',
                      setShowTutorialModal: () => {
                        if (
                          (
                            todayRewards?.result?.dailyCheckInRewardHistory ||
                            []
                          )?.filter((x) => {
                            return x?.rewardType === 'initial-check-in'
                          })?.length === 0
                        ) {
                          setModalConfig({
                            ...modalConfig,
                            visible: true,
                            modalType: 'DailyCheckInTutorial',
                          })
                        }
                      },
                      checkInInvitorName,
                      alreadyParticipated: false,
                      invitorMissionAlreadyCleared:
                        ticketRewardResult?.data ===
                        'invitors mission already cleared',
                    })
                  }
                } else {
                  // 이미 참여해줬음

                  setModalConfig({
                    ...modalConfig,
                    visible: true,
                    modalType: 'CheckInPigRouletteInvite',
                    setShowTutorialModal: () => {
                      if (
                        (
                          todayRewards?.result?.dailyCheckInRewardHistory || []
                        )?.filter((x) => {
                          return x?.rewardType === 'initial-check-in'
                        })?.length === 0
                      ) {
                        setModalConfig({
                          ...modalConfig,
                          visible: true,
                          modalType: 'DailyCheckInTutorial',
                        })
                      }
                    },
                    checkInInvitorName,
                    alreadyParticipated: true,
                  })
                }
              }
            }
          } else if (
            !UserStore.userInfo.needsLogin &&
            alwayzInvitorId &&
            (!isNewUserCheckIn?.data ||
              isNewUserCheckIn?.data === 'false' ||
              isNewUserCheckIn?.data === 'leftUser')
          ) {
            // 신규 유저가 아닌 경우
            if (code === 'CheckInInviteFriends' || !code) {
              // setModalConfig({
              //   ...modalConfig,
              //   visible: true,
              //   modalType: 'DailyCheckInInviteNudging',
              //   setShowInviteNudgingModal: () => {
              //     setModalConfig({ ...modalConfig, visible: false })
              //   },
              //   goBackToMain: () => {
              //     setModalConfig({ ...modalConfig, visible: false })
              //     window.location.href = `#navigate.${JSON.stringify({
              //       screen: 'MainALargeCategoryTabNavigator',
              //     })}`
              //   },
              //   setOpenInviteFriendsNewModal: (bool) => {
              //     setModalConfig({
              //       ...modalConfig,
              //       visible: bool,
              //       modalType: 'DailyCheckInInviteFriendsNewModal',
              //       handleCheckInButton,
              //       setModalConfig,
              //       missionConfig: tmpMissionConfig,
              //       todayTotalNewInviteNum:
              //         tmpMissionConfig?.newInviteMission
              //           ?.todayTotalNewInviteNum || 0,
              //       weeklyStampData,
              //     })
              //   },
              //   setShowTutorialModal: () => {
              //     if (
              //       (
              //         todayRewards?.result?.dailyCheckInRewardHistory || []
              //       )?.filter((x) => {
              //         return x?.rewardType === 'initial-check-in'
              //       })?.length === 0
              //     ) {
              //       setModalConfig({
              //         ...modalConfig,
              //         visible: true,
              //         modalType: 'DailyCheckInTutorial',
              //       })
              //     }
              //   },
              //   checkInInvitorName: checkInInvitorName,
              //   checkInInvitorId: alwayzInvitorId,
              // })
            } else if (code === 'CheckInPigRoulette') {
              if (alwayzInvitorId !== UserStore?.userInfo?._id) {
                const missionData = await backendApis.pigMissionData()

                if (
                  missionData?.data?.participatedPigRouletteInvite ||
                  missionData?.data?.participatedPigRouletteInvite === undefined
                ) {
                  const ticketRewardResult =
                    await backendApis.giveRouletteTicketWithUserId(
                      alwayzInvitorId,
                      'CheckInPig',
                      'dailyRoulette',
                      'checkInPigRouletteInvite',
                      1,
                    )
                  if (
                    ticketRewardResult?.status === 200 &&
                    ticketRewardResult?.data !==
                      'invitors mission already cleared'
                  ) {
                    // 지급 & 로깅 완료
                    await backendApis.pigData()
                    await backendApis.logRewardTime(
                      'participatedPigRouletteInvite',
                    )
                    setModalConfig({
                      ...modalConfig,
                      visible: true,
                      modalType: 'CheckInPigRouletteInvite',
                      setShowTutorialModal: () => {
                        if (
                          (
                            todayRewards?.result?.dailyCheckInRewardHistory ||
                            []
                          )?.filter((x) => {
                            return x?.rewardType === 'initial-check-in'
                          })?.length === 0
                        ) {
                          setModalConfig({
                            ...modalConfig,
                            visible: true,
                            modalType: 'DailyCheckInTutorial',
                          })
                        }
                      },
                      checkInInvitorName,
                      alreadyParticipated: false,
                      invitorMissionAlreadyCleared:
                        ticketRewardResult?.data ===
                        'invitors mission already cleared',
                    })
                  } else {
                    // 이미 Invitor를 다른 사람이 도와줌

                    setModalConfig({
                      ...modalConfig,
                      visible: true,
                      modalType: 'CheckInPigRouletteInvite',
                      setShowTutorialModal: () => {
                        if (
                          (
                            todayRewards?.result?.dailyCheckInRewardHistory ||
                            []
                          )?.filter((x) => {
                            return x?.rewardType === 'initial-check-in'
                          })?.length === 0
                        ) {
                          setModalConfig({
                            ...modalConfig,
                            visible: true,
                            modalType: 'DailyCheckInTutorial',
                          })
                        }
                      },
                      checkInInvitorName,
                      alreadyParticipated: false,
                      invitorMissionAlreadyCleared:
                        ticketRewardResult?.data ===
                        'invitors mission already cleared',
                    })
                  }
                } else {
                  // 이미 참여해줬음

                  setModalConfig({
                    ...modalConfig,
                    visible: true,
                    modalType: 'CheckInPigRouletteInvite',
                    setShowTutorialModal: () => {
                      if (
                        (
                          todayRewards?.result?.dailyCheckInRewardHistory || []
                        )?.filter((x) => {
                          return x?.rewardType === 'initial-check-in'
                        })?.length === 0
                      ) {
                        setModalConfig({
                          ...modalConfig,
                          visible: true,
                          modalType: 'DailyCheckInTutorial',
                        })
                      }
                    },
                    checkInInvitorName,
                    alreadyParticipated: true,
                  })
                }
              }
            }
          }
        }
      }
    }
  }

  const MainComponentGroup = () => {
    const rewardHistory = dailyCheckInRewardHistory || []

    return (
      <>
        {checkInDealLastSeenAt && showCheckInDeal && (
          <button
            type='button'
            style={{
              position: 'absolute',
              borderWidth: 0,
              padding: 10,
              marginTop: 48,
              right: 12,
              top: 32,
            }}
            onClick={() => {
              window.location.href = `#navigate.${JSON.stringify({
                screen: 'DailyCheckInPointDeal',
              })}`
            }}
          >
            <div
              style={{
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <PointUpgrade className='w-14' />
              <BlackTextTimer
                fontSize={10}
                interval={1000}
                color='black'
                onTimeEnd={() => {
                  setShowCheckInDeal(false)
                }}
                timeStamp={new Date(checkInDealLastSeenAt)}
                timerMinutes={0}
              />
            </div>
          </button>
        )}
        {!checkInAlarmConsent && moment().day() === 0 && (
          <button
            type='button'
            style={{
              position: 'absolute',
              borderWidth: 0,
              padding: 10,
              marginTop: 36,
              right: 2,
              top: 0,
            }}
            onClick={async () => {
              await UserStore.setCheckInNotificationSettings(true, 10)
              window.location.href = `#navigate.${JSON.stringify({
                screen: 'CheckInPushSettingSuccessScreen',
              })}`
            }}
          >
            <div
              style={{
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <AlarmNudge className='w-44' />
            </div>
          </button>
        )}
        <div className='flex flex-col items-start justify-center p-4 mx-4 mt-2 mb-4 bg-white rounded-lg'>
          <button
            type='button'
            onClick={() => {
              navigate(`/expire-history?token=${token}&isFrom=web`)
            }}
            className='flex flex-row items-center justify-between w-full pl-2 text-base font-bold'
          >
            {userInfo ? `${userInfo.userName}님의 올포인트` : `내 올포인트`}
            <div className='flex flex-row items-center text-xs font-normal text-[#716F71]'>
              소멸내역
              <FiChevronRight className='inline-block w-4 h-4' />
            </div>
          </button>
          <div className='flex flex-row items-end py-1 pl-2 text-5xl font-bold h-14'>
            {animatePoints ? (
              <AnimatedNumbers
                includeComma
                animateToNumber={accumulatedPoints || 0}
                fontStyle={{ fontSize: 48 }}
                configs={[{ mass: 1, tension: 320, friction: 70 }]}
              />
            ) : (
              commaNumber(accumulatedPoints || 0)
            )}
            <div className='ml-2 text-2xl'>원</div>
          </div>
          <Button
            appearance=' '
            className={`drop-shadow relative w-full mt-2 bg-red-500 h-14 outline-0 rounded-lg active:brightness-90 active:scale-95 duration-200 ${
              animateButton ? ' animate-zoomOut' : ''
            }`}
            onClick={() => {
              if (buttonPressed) return
              buttonPressed = true

              if (
                !rewardHistory?.find(
                  (el) => el?.rewardType === 'initial-check-in',
                ) ||
                rewardHistory?.find(
                  (el) => el?.rewardType === 'initial-check-in',
                )?.didOpen === false
              ) {
                handleCheckInButton(
                  false,
                  'initial-check-in',
                  weeklyStampData,
                  missionConfig,
                )
              } else {
                setAnimateButton(false)
                setAnimatePoints(false)
                setOpen(true)
              }
              buttonPressed = false
            }}
          >
            {Object.values(missionConfig)?.filter(
              (el) => el?.isDisable === false && el?.isShow === true,
            )?.length > 0 && (
              <div
                style={{
                  borderWidth: 2,
                  borderRadius: 99,
                  height: 30,
                  width: 30,
                  position: 'absolute',
                  left: '93%',
                  top: '-15%',
                  backgroundColor: '#E93E47',
                  borderColor: 'white',
                }}
                className='flex items-center justify-center font-bold text-white drop-shadow'
              >
                {
                  Object.values(missionConfig)?.filter(
                    (el) => el?.isDisable === false && el?.isShow === true,
                  )?.length
                }
              </div>
            )}
            <span className='text-xl font-semibold text-white-600'>
              {!rewardHistory?.find(
                (el) => el?.rewardType === 'initial-check-in',
              ) ||
              rewardHistory?.find((el) => el?.rewardType === 'initial-check-in')
                ?.didOpen === false
                ? '출석하고 포인트 받기'
                : '미션하고 포인트 받기'}
            </span>
          </Button>
        </div>
      </>
    )
  }

  const handleCheckInButton = async (
    isPeriodic = false,
    rewardType,
    weeklyStampData,
    missionConfig,
    isNewUserInvitation = false,
  ) => {
    setIsLoading(true)
    let consecutiveBonus
    if (isPeriodic) {
      consecutiveBonus = 1
    } else if (newUserBonus) {
      consecutiveBonus = 5
    } else {
      if (
        [2, 4, 6].includes(
          consecutiveCheckInData?.consecutiveCheckInLog?.length,
        )
      ) {
        consecutiveBonus =
          consecutiveCheckInData?.consecutiveCheckInLog?.length + 1
      } else {
        consecutiveBonus = 1
      }
      backendApis.logConsecutiveCheckIn()
      window.location.href = '#vibrate.'
    }

    const processOpenEnvelopeResult = await backendApis.processOpenEnvelopeV2(
      type,
      consecutiveBonus,
      rewardType,
      'a',
      alwayzInvitorId,
      isNewUserInvitation,
      true,
    )

    if (processOpenEnvelopeResult?.status === 200) {
      dailyCheckInRewardHistory?.splice(
        0,
        processOpenEnvelopeResult?.data?.dailyCheckInRewardHistory
          ?.dailyCheckInRewardHistory?.length + 1,
        ...processOpenEnvelopeResult?.data?.dailyCheckInRewardHistory
          ?.dailyCheckInRewardHistory,
        { isShaking: true },
      )
      setDailyCheckInRewardHistory(dailyCheckInRewardHistory)

      const checkIn7DaysList = []
      const pastWeekDateString = [...Array(7).keys()]
        .map((days) =>
          moment(new Date(new Date(today) - 86400000 * days)).format(
            'YYYY-MM-DD',
          ),
        )
        .reverse()

      for (const dateString of pastWeekDateString) {
        checkIn7DaysList.push({
          date: dateString,
          didOpen:
            !!processOpenEnvelopeResult?.data?.checkInData?.dailyCheckInMonthAt?.find(
              (el) => {
                return el?.date === dateString && el?.didOpen === true
              },
            ),
        })
      }
      setWeeklyStampData({ checkIn7DaysList, today: new Date(today) })

      UserStore?.setAccumulatedPoints(
        processOpenEnvelopeResult?.data?.checkInData?.totalCheckInPoints,
      )
      if (processOpenEnvelopeResult?.data?.alertOpen) {
        setOpenErrorModal(true)
        buttonPressed = false
      } else {
        setModalConfig({
          ...modalConfig,
          visible: true,
          modalType: 'DailyCheckInScreenRewardReceiveModal',
          rewardAmountForDisplay: processOpenEnvelopeResult?.data?.totalReward,
          isConsecutiveBonusAdded:
            processOpenEnvelopeResult?.data?.consecutiveBonusReward > 0,
          consecutiveBonus,
          isFirstTime: processOpenEnvelopeResult?.data?.isFirstTime,
          rewardType,
          openRBSheet: async () => {
            await sleep(500)
            setOpen(true)
          },
          setModalConfig,
        })
        if (rewardType === 'alarm-set-mission') {
          setMissionConfig({
            ...missionConfig,
            alarmSetMission: {
              ...missionConfig?.alarmSetMission,
              isDisable: true,
            },
          })
        } else if (rewardType === 'periodic-bonus-mission') {
          setMissionConfig({
            ...missionConfig,
            timeMission: {
              ...missionConfig?.timeMission,
              isDisable: true,
              leftCoolTime: 240,
              todayCount: (missionConfig?.timeMission?.todayCount || 0) + 1,
              availableAtNextDay:
                moment(today).format('YYYY-MM-DD') !==
                moment(today).add(4, 'hours').format('YYYY-MM-DD'),
            },
          })
        } else if (rewardType === 'invite-friend-new') {
          if (
            (missionConfig?.intialMission?.todayTotalNewInviteNum || 0) >= 4
          ) {
            setMissionConfig({
              ...missionConfig,
              newInviteMission: {
                ...missionConfig?.newInviteMission,
                todayTotalNewInviteNum: 5,
              },
            })
          } else {
            setMissionConfig({
              ...missionConfig,
              newInviteMission: {
                ...missionConfig?.newInviteMission,
                todayTotalNewInviteNum:
                  (missionConfig?.newInviteMission?.todayTotalNewInviteNum ||
                    0) + 1,
              },
            })
          }
        }
      }
      UserStore?.loadDailyCheckInQuickLinkNotificationCount()
    } else {
      setOpenErrorModal(true)
    }
    setIsLoading(false)
  }

  const handleRewardedAdMission = async () => {
    // 1.2초 대기(앱러빈 시청완료 api 오는데 살짝 시간이 걸릴수 있어서)
    await sleep(1200)
    const result = await backendApis.getTodayRewards()
    const rewardedADmission = result?.result?.dailyCheckInRewardHistory?.filter(
      (e) => e?.rewardType === 'rewardedAD-mission',
    )
    const rewardAdPoint =
      rewardedADmission?.length > 0
        ? rewardedADmission[rewardedADmission.length - 1]?.rewardAmount
        : undefined

    if (result?.status === 200 && rewardAdPoint > 0) {
      THModalStore.setType({
        type: 'reward',
        config: {
          rewardName: '광고 시청 완료',
          description: `${rewardAdPoint} 포인트를 받았어요!`,
          src: yellowMoney,
          text: '확인',
          forceOpen: true,
          onClick: () => {
            UserStore.setAccumulatedPoints(
              UserStore.accumulatedPoints + rewardAdPoint,
            )
          },
        },
      })
      UserStore.setIsWatchingAd(false)
    }
  }

  useEffect(() => {
    if (UserStore.isWatchingAd === true) {
      handleRewardedAdMission()
    }
  }, [UserStore.isWatchingAd])

  useEffect(() => {
    setIsLoading(true)
    AuthStore.setToken(token)
    loadAllDailyCheckInData()
    setIsLoading(false)
  }, [])

  useEffect(() => {
    document.addEventListener(
      visibilityChange,
      (visibility) => {
        const now = Date.now()
        if (now - lastActiveDate < 1000 * 5) return
        if (document.visibilityState === 'visible') {
          setIsLoading(true)
          setTimeout(() => {
            loadAllDailyCheckInData()
            setIsLoading(false)
          }, 500)
        }
      },
      false,
    )
  }, [token])

  useEffect(() => {
    const fetchData = async () => {
      const result = await backendApis.getCheckInPoint(token)
      if (result?.status === 200) {
        setAccumulatedPoints(result?.data[0]?.totalCheckInPoints)
      }
      const distinguish = await UserStore.distinguishCherrypicker()
      if (distinguish !== 'done') {
        errorCode = 10
        setOpenErrorModal(true)
      }
    }
    if (AuthStore.token) {
      fetchData()
    }
  }, [AuthStore.token])

  useEffect(() => {
    setTimeout(() => {
      setAnimatePoints(true)
      setAccumulatedPoints(UserStore?.accumulatedPoints)
      setTimeout(() => {
        setAnimatePoints(false)
      }, 3000)
    }, 200)
  }, [UserStore?.accumulatedPoints])

  return (
    <div
      className={`bg-[#F9D7D6] w-full h-full overflow-hidden ${
        modalConfig?.visible ? 'fixed' : ''
      }`}
    >
      <HeaderComponent
        checkInAlarmConsent={checkInAlarmConsent}
        checkInAlarmTime={checkInAlarmTime}
      />
      <MainComponentGroup />
      <SubMenuButtonGroup checkInAlarmConsent={checkInAlarmConsent} />
      <div className='w-full mt-4 bg-white rounded-t-xl'>
        {couponPackAB === 'b' && (
          <CouponHorizontalList
            couponPackData={couponPackData}
            setOpenCouponModal={() => setOpenCouponModal(true)}
          />
        )}
      </div>
      <DailyCheckInItemSection />

      <LoadingIndicator isLoading={isLoading} />
      <ScrolltoTopButton />

      {/* bottomsheet */}
      <BottomSheet open={open} onDismiss={() => setOpen(false)}>
        <div className='flex items-center justify-between h-16 px-6'>
          <div className='w-8' />
          <div className='text-xl font-bold'>올포인트 미션</div>
          <button
            type='button'
            className='flex items-center justify-center w-8 h-8 p-1 bg-gray-900 rounded-full opacity-20 outline-0'
            onClick={() => {
              setOpen(false)
              setOpenCouponModal(false)
            }}
          >
            <Close />
          </button>
        </div>

        {/* 미션 목록 시작 */}
        <div className='px-6 pb-10'>
          {missionConfig?.alfarmEvent?.isShow && (
            <DailyCheckInScreenBottomSheetItem
              leftImage='DailyCheckInAlfarm'
              title='올팜 2일 연속 물주기'
              headText='신규 고객 한정 이벤트'
              content={
                (missionConfig?.alfarmEvent?.alfarmPlayCount === 0 ||
                  missionConfig?.alfarmEvent?.alfarmPlayCount === 2 ||
                  (missionConfig?.alfarmEvent?.alfarmPlayCount === 1 &&
                    !missionConfig?.alfarmEvent?.isPlayAlfarmToday)) &&
                `물주고 포인트 받기 (${missionConfig?.alfarmEvent?.alfarmPlayCount}/2)`
              }
              impressTextInContent={
                missionConfig?.alfarmEvent?.alfarmPlayCount === 1 &&
                missionConfig?.alfarmEvent?.isPlayAlfarmToday &&
                '내일 미션하면 포인트 획득'
              }
              gaugeCount={missionConfig?.alfarmEvent?.alfarmPlayCount}
              moveTo={async () => {
                if (buttonPressed) return
                buttonPressed = true
                setOpen(false)
                setMissionConfig({
                  ...missionConfig,
                  alfarmEvent: {
                    ...missionConfig?.alfarmEvent,
                    isFirstImpressionDisable: true,
                  },
                })
                const result = await backendApis.setAlfarmEventReward()
                if (result?.status === 200) {
                  setMissionConfig({
                    ...missionConfig,
                    alfarmEvent: {
                      ...missionConfig?.alfarmEvent,
                      isDisable: true,
                    },
                  })
                  dailyCheckInRewardHistory?.splice(0, 0, {
                    didOpen: true,
                  })
                  dailyCheckInRewardHistory?.splice(
                    dailyCheckInRewardHistory?.length - 1,
                    1,
                  )
                  setDailyCheckInRewardHistory(dailyCheckInRewardHistory)

                  setModalConfig({
                    ...modalConfig,
                    visible: true,
                    modalType: 'DailyCheckInScreenRewardReceiveModal',
                    rewardAmountForDisplay: result?.data?.point,
                    isConsecutiveBonusAdded: false,
                    setModalConfig,
                  })
                  UserStore?.setAccumulatedPoints(
                    UserStore?.accumulatedPoints + result?.data?.point,
                  )
                }
                await sleep(500)

                buttonPressed = false
              }}
              disabled={
                missionConfig?.alfarmEvent?.isDisable ||
                missionConfig?.alfarmEvent?.isFirstImpressionDisable
              }
              button={
                missionConfig?.alfarmEvent?.alfarmPlayCount < 2 && (
                  <button
                    type='button'
                    className={
                      missionConfig?.alfarmEvent?.alfarmPlayCount === 1 &&
                      missionConfig?.alfarmEvent?.isPlayAlfarmToday
                        ? 'w-20 h-10 bg-gray-300 px-2 rounded-lg justify-center text-white text-base'
                        : 'w-20 h-10 bg-red-500 px-2 rounded-lg justify-center text-white text-base'
                    }
                    onClick={async () => {
                      setOpen(false)
                      const result = await backendApis.getAlFarmUserData(token)

                      if (result?.status !== 200) return

                      if (result?.data?._id) {
                        window.location.href = `#navigate.${JSON.stringify({
                          screen: 'AlfarmMain',
                          prop: {
                            isAlreadyExist: true,
                            itemType: `${result?.data?.itemType}`,
                          },
                        })}`
                      } else {
                        window.location.href = `#navigate.${JSON.stringify({
                          screen: 'AlfarmMain',
                          prop: {
                            isAlreadyExist: false,
                          },
                        })}`
                      }
                    }}
                    disabled={missionConfig?.alfarmEvent?.isDisable}
                  >
                    {missionConfig?.alfarmEvent?.alfarmPlayCount === 1 &&
                    missionConfig?.alfarmEvent?.isPlayAlfarmToday
                      ? '오늘 완료'
                      : '플레이'}
                  </button>
                )
              }
            />
          )}
          {missionConfig?.alarmSetMission?.isShow && (
            <DailyCheckInScreenBottomSheetItem
              leftImage='DailyCheckInAlarm'
              title='출석체크 알림받기'
              content='알림 켜고 포인트 받기'
              moveTo={async () => {
                if (buttonPressed) return
                buttonPressed = true

                setOpen(false)
                backendApis.logAlarmMission()
                await UserStore.setCheckInNotificationSettings(true, 10)
                await sleep(500)
                handleCheckInButton(
                  false,
                  'alarm-set-mission',
                  weeklyStampData,
                  missionConfig,
                )
                buttonPressed = false
              }}
              disabled={!!missionConfig?.alarmSetMission?.isDisable}
            />
          )}
          <DailyCheckInScreenBottomSheetItem
            leftImage='CheckInPigIcon'
            title='돈돈이 밥주기'
            content='밥주고 포인트 받기'
            moveTo={() => {}}
            button={
              <button
                type='button'
                className='w-20 h-10 px-2 text-white duration-200 bg-red-500 rounded-lg active:brightness-90 active:scale-95'
                onClick={async () => {
                  if (buttonPressed) return
                  buttonPressed = true
                  setOpen(false)
                  navigate(`/check-in-pig?token=${token}&isFrom=web`)

                  backendApis.recordEventLog(
                    'CheckIn_PigMissionButton',
                    `clicked_CheckIn_PigMissionButton`,
                  )
                  buttonPressed = false
                }}
              >
                보상받기
              </button>
            }
          />
          <DailyCheckInScreenBottomSheetItem
            leftImage='DailyCheckInClock'
            title={
              (missionConfig?.timeMission?.todayCount || 0) < 6
                ? '시간 출석체크'
                : '가능 횟수를 소진했어요'
            }
            impressTextInContent={periodicMissionCoolTimeTextHandler(
              missionConfig?.timeMission?.leftCoolTime,
              missionConfig?.timeMission?.todayCount || 0,
              missionConfig?.timeMission?.availableAtNextDay,
              false,
            )}
            content={periodicMissionCoolTimeTextHandler(
              missionConfig?.timeMission?.leftCoolTime,
              missionConfig?.timeMission?.todayCount || 0,
              missionConfig?.timeMission?.availableAtNextDay,
              true,
            )}
            moveTo={() => {
              setOpen(false)
            }}
            button={
              <button
                type='button'
                className={`w-20 h-10 px-2 text-white duration-200 rounded-lg active:brightness-90 active:scale-95 ${
                  missionConfig?.timeMission?.isDisable
                    ? 'bg-yellow-700'
                    : 'bg-red-500'
                }`}
                onClick={async () => {
                  if (buttonPressed) return
                  buttonPressed = true

                  setOpen(false)
                  await sleep(500)

                  if (missionConfig?.timeMission?.isDisable) {
                    window.location.href = `#navigate.${JSON.stringify({
                      screen: 'DailyCheckInCompatibleBrowsingMission',
                      prop: {
                        missionType: 'periodic-bonus-mission',
                        missionName: '시간 출석하기',
                        rewardType: 'point',
                        rewardAmount: 10,
                        defaultLeftTime: 30,
                        defaultCategory: 'total',
                        showEnvelopeList: true,
                        showBrandDeal: true,
                        bannerUri: '',
                      },
                    })}`
                  } else {
                    handleCheckInButton(
                      true,
                      'periodic-bonus-mission',
                      weeklyStampData,
                      missionConfig,
                    )
                  }

                  buttonPressed = false
                }}
              >
                {missionConfig?.timeMission?.isDisable ? '추가 미션' : '받기'}
              </button>
            }
          />
          <DailyCheckInScreenBottomSheetItem
            leftImage='DailyCheckInBrowsingMission'
            title='추천상품 구경하기'
            content='40초 구경하고 포인트 받기'
            disabled={missionConfig?.browsingMisson?.isDisable}
            moveTo={() => {
              setOpen(false)
            }}
            button={
              !missionConfig?.browsingMisson?.isDisable && (
                <button
                  type='button'
                  className={`w-20 h-10 px-2 text-white duration-200 rounded-lg active:brightness-90 active:scale-95 ${
                    missionConfig?.browsingMisson?.isDisable
                      ? 'bg-gray-300'
                      : 'bg-red-500'
                  }`}
                  onClick={() => {
                    setOpen(false)

                    window.location.href = `#navigate.${JSON.stringify({
                      screen: 'DailyCheckInCompatibleBrowsingMission',
                      prop: {
                        missionType: 'pointBrowsingMission',
                        missionName: '추천상품 구경하기',
                        rewardType: 'point',
                        rewardTypeKor: '',
                        unit: '',
                        rewardAmount: 10,
                        defaultLeftTime: 40,
                        defaultCategory: 'total',
                        showEnvelopeList: false,
                        showBrandDeal: true,
                        bannerUri:
                          'https://assets.ilevit.com/cadd4261-be86-4266-b242-705dad1e5ebb.png',
                      },
                    })}`
                  }}
                  disabled={missionConfig?.browsingMisson?.isDisable}
                >
                  구경하기
                </button>
              )
            }
          />
          <DailyCheckInScreenBottomSheetItem
            leftImage='DailyCheckInCashbackMission'
            headText='하루 1번만 가능'
            title='상품 1개 구매하기'
            content='캐시백 상품 사고 포인트 받기'
            disabled={missionConfig?.cashbackMission?.isDisable}
            moveTo={() => {
              setOpen(false)
            }}
            button={
              !missionConfig?.cashbackMission?.isDisable && (
                <button
                  type='button'
                  className={`w-20 h-10 px-2 text-white duration-200 rounded-lg active:brightness-90 active:scale-95 ${
                    missionConfig?.cashbackMission?.isDisable
                      ? 'bg-gray-300'
                      : 'bg-red-500'
                  }`}
                  onClick={() => {
                    setOpen(false)

                    window.location.href = `#navigate.${JSON.stringify({
                      screen: 'DailyCheckInCashBackDealScreen',
                    })}`
                  }}
                  disabled={missionConfig?.cashbackMission?.isDisable}
                >
                  구매하기
                </button>
              )
            }
          />
          {(UserStore?.abInfo?.checkInRewardedAd0527 === 'b' ||
            UserStore?.userInfo?._id === '626aa6a6c4d18f4110ecd6cb') &&
            codePushVersionAvailable(
              UserStore?.userInfo?.codepushVersion,
              '5.0.1',
            ) && (
              <DailyCheckInScreenBottomSheetItem
                leftImage='DailyCheckInRewardedAdMission'
                headText='하루 1번만 가능'
                title='광고 시청하기'
                content='광고 보고 포인트 받기'
                disabled={missionConfig?.rewardedAdMission?.isDisable}
                moveTo={() => setOpen(false)}
                button={
                  !missionConfig?.rewardedAdMission?.isDisable && (
                    <button
                      type='button'
                      className={`w-20 h-10 px-2 text-white duration-200 rounded-lg active:brightness-90 active:scale-95 ${
                        UserStore?.isRewardAdReady
                          ? 'bg-red-500'
                          : 'bg-gray-300'
                      }`}
                      disabled={
                        missionConfig?.rewardedAdMission?.isDisable &&
                        UserStore?.isRewardAdReady
                      }
                      onClick={async () => {
                        setOpen(false)

                        if (UserStore.isRewardAdReady === true) {
                          THModalStore?.setType({
                            type: 'confirm',
                            config: {
                              forceOpen: true,
                              title: '광고 보고 포인트 받기',
                              src: null,
                              description: `광고를 보고 상단에 나타나는\nX버튼을 눌러주세요\n소리가 나올 수 있어요`,
                              buttonText: '광고보기',
                              onConfirm: () => {
                                THModalStore.close()
                                DailyCheckInAction.showRewardedAd()
                              },
                              clickable: true,
                            },
                          })
                        }
                      }}
                    >
                      {UserStore?.isRewardAdReady ? '광고보기' : '광고준비중'}
                    </button>
                  )
                }
              />
            )}
        </div>
      </BottomSheet>
      <CouponPackBottomSheet
        accumulatedPoints={accumulatedPoints}
        couponPackData={couponPackData}
        loadCouponPackData={() => loadCouponPackData()}
        checkInAlarmConsent={checkInAlarmConsent}
        openCouponModal={openCouponModal}
        onDismiss={() => {
          setOpen(false)
          setOpenCouponModal(false)
        }}
        setAccumulatedPoints={setAccumulatedPoints}
        setIsLoadingTrue={() => setIsLoading(true)}
        setIsLoadingFalse={() => setIsLoading(false)}
      />

      {/* modal */}
      {modalConfig?.visible && (
        <DailyCheckInModalHandler
          setModalConfig={setModalConfig}
          {...modalConfig}
        />
      )}
      {openLoginModal && (
        <ConfirmAlert
          modalTitle='로그인을 해주세요'
          modalContent='로그인을 해주셔야 출석체크를 사용할 수 있어요.'
          buttonText='확인'
          buttonLink={() => {
            setOpenLoginModal(false)
            window.location.href = `#navigate.${JSON.stringify({
              screen: 'MainBMyInfoScreen',
            })}`
          }}
          showModal
          setShowModal={setOpenLoginModal}
          onlyOneButton
        />
      )}
      {openTeamStatusModal && (
        <ConfirmAlert
          modalTitle='팀 참여 실패'
          modalContent='이미 팀에 참여되어 있거나, 3명이 모두 모인 팀이에요'
          buttonText='확인'
          buttonLink={() => {
            setOpenTeamStatusModal(false)
          }}
          showModal
          setShowModal={setOpenTeamStatusModal}
          onlyOneButton
        />
      )}
      {openErrorModal && (
        <ConfirmAlert
          modalTitle='연결이 불안정해요'
          modalContent={`인터넷 연결이 불안정하여 출석체크에 실패했어요. 잠시 후 다시 시도해주세요. ${
            errorCode > 0 ? `(코드: ${errorCode})` : ''
          }`}
          buttonText='확인'
          buttonLink={() => {
            window.location.href = `#navigate.${JSON.stringify({
              screen: 'MainALargeCategoryTabNavigator',
            })}`
            setOpenErrorModal(false)
          }}
          showModal
          setShowModal={setOpenErrorModal}
          onlyOneButton
        />
      )}
      <link
        rel='stylesheet'
        href='https://unpkg.com/react-spring-bottom-sheet/dist/style.css'
        crossOrigin='anonymous'
      />
    </div>
  )
})

export default DailyCheckInPage
