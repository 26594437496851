import commaNumber from 'comma-number'

const { default: ImageAtom } = require('../../../atoms/imageAtom')

const DrawItemCard = ({ item }) => {
  return (
    <>
      <div className='relative flexCol h-[66vw] w-[36vw] mx-[2vw] mt-[2vw]'>
        <ImageAtom
          src='https://assets.ilevit.com/570861e1-25ea-4288-a6f4-17a3a09a2579.png'
          className='absoluteCenter z-1 w-full h-auto'
        />
        <div className='flexCol h-full z-10 w-[90%] absolute top-[-4vw]'>
          <ImageAtom
            src={item.mainImageUri}
            className='rounded-lg w-[68%] h-auto'
          />
          <div className='flex flex-col text-left w-[88%] pt-[2vw]'>
            <div className='w-full overflow-hidden leading-tight line-clamp-2 text-ellipsis text-start text-[3vw] font-bold '>
              {item?.itemTitle}
            </div>
            <div className='text-[2vw] mt-[1vw]'>
              정가: {commaNumber(item?.individualPurchasePrice)}원
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DrawItemCard
