import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'

import { RouletteTicket } from '../../images/dailyCheckIn'
// eslint-disable-next-line import/no-cycle
import DailyCheckInModalHandler from '../../modals/dailyCheckIn/DailyCheckInModalHandler'
import UserStore from '../../stores/UserStore'
import backendApis from '../../utils/backendApis'

let buttonPressed = false

const Roulette = ({ prizes, rouletteTitle, token }) => {
  const DEGREE_MAP = [
    5370, // 에어랩
    5310, // 고급비료
    5250, // 쿠폰
    5190, // 미니백
    5130, // 물
    5070, // 바로수확
  ]
  const rewardImages = [
    prizes[0].image,
    prizes[1].image,
    prizes[2].image,
    prizes[3].image,
    prizes[4].image,
    prizes[5].image,
  ]
  const [rouletteRewardNumber, setRouletteRewardNumber] = useState(0)
  const [isRolling, setIsRolling] = useState(false)
  const [modalConfig, setModalConfig] = useState(false)
  const [rewardModal, setRewardModal] = useState(false)
  const [infoModal, setInfoModal] = useState(false)
  const [ticketCount, setTicketCount] = useState(0)
  const [missionConfig, setMissionConfig] = useState({
    dailyTicket: { isShow: true, isDisable: true },
    dailyTicketPurchase: { isShow: true, isDisable: true },
  })

  const triggerTicketToast = (ticket) =>
    toast(`뽑기권 ${ticket}장을 획득했어요!`, {
      id: 'ticekt given',
      duration: 2000,
      position: 'top-center',
      style: {
        paddingLeft: 8,
        paddingTop: 20,
        paddingBottom: 20,
        fontWeight: 'bold',
      },
      icon: '🎉',
    })

  const startRoulette = async () => {
    setIsRolling(true)
    const result = await backendApis.startRoulette(
      'CheckInPig',
      rouletteTitle,
      prizes,
    )
    if (result.status === 200) {
      setRouletteRewardNumber(result?.data)
      await backendApis.giveRouletteReward(
        'CheckInPig',
        rouletteTitle,
        prizes[result?.data],
      )

      setTimeout(async () => {
        setRewardModal(true)
        if (prizes[result?.data].rewardType === 'fail') {
          setTicketCount(ticketCount)
        }
        setIsRolling(false)
      }, 7000)
    } else {
      return 'no internet'
    }
  }

  const PrizeComponent = () => {
    return (
      <div>
        <img
          src={rewardImages[0]}
          alt=''
          style={{
            position: 'absolute', // Add position absolute to the reward images
            top: prizes[0].imageTop, // Adjust the position of the reward images
            right: prizes[0].imageRight,
            transform: `translate(-50%, -50%) rotate(${
              DEGREE_MAP[0] - 300
            }deg)`, // Adjust the rotation of the reward images
            width: prizes[0].imageWidth, // Adjust the size of the reward images
            height: prizes[0].imageHeight,
          }}
        />
        <div
          style={{
            position: 'absolute',
            top: prizes[0].captionTop,
            right: prizes[0].captionRight,
            transform: `translate(-50%, -50%) rotate(${
              DEGREE_MAP[0] - 300
            }deg)`,
            width: '24%',
            height: '24%',
            textAlign: 'center',
            fontSize: 10,
          }}
        >
          {prizes[0].caption}
        </div>
        <img
          src={rewardImages[1]}
          alt=''
          style={{
            position: 'absolute',
            top: prizes[1].imageTop,
            right: prizes[1].imageRight,
            transform: `translate(-50%, -50%) rotate(${
              DEGREE_MAP[1] - 180
            }deg)`,
            width: prizes[1].imageWidth,
            height: prizes[1].imageHeight,
          }}
        />
        <div
          style={{
            position: 'absolute',
            top: prizes[1].captionTop,
            right: prizes[1].captionRight,
            transform: `translate(-50%, -50%) rotate(${
              DEGREE_MAP[1] - 180
            }deg)`,
            width: '24%',
            height: '24%',
            textAlign: 'center',
            fontSize: 10,
          }}
        >
          {prizes[1].caption}
        </div>
        <img
          src={rewardImages[2]}
          alt=''
          style={{
            position: 'absolute',
            top: prizes[2].imageTop,
            right: prizes[2].imageRight,
            transform: `translate(-50%, -50%) rotate(${DEGREE_MAP[2] - 60}deg)`,
            width: prizes[2].imageWidth,
            height: prizes[2].imageHeight,
          }}
        />
        <div
          style={{
            position: 'absolute',
            top: prizes[2].captionTop,
            right: prizes[2].captionRight,
            transform: `translate(-50%, -50%) rotate(${DEGREE_MAP[2] - 60}deg)`,
            width: '24%',
            height: '24%',
            textAlign: 'center',
            fontSize: 10,
          }}
        >
          {prizes[2].caption}
        </div>
        <img
          src={rewardImages[3]}
          alt=''
          style={{
            position: 'absolute',
            top: prizes[3].imageTop,
            right: prizes[3].imageRight,
            transform: `translate(-50%, -50%) rotate(${DEGREE_MAP[3] + 60}deg)`,
            width: prizes[3].imageWidth,
            height: prizes[3].imageHeight,
          }}
        />
        <div
          style={{
            position: 'absolute',
            top: prizes[3].captionTop,
            right: prizes[3].captionRight,
            transform: `translate(-50%, -50%) rotate(${DEGREE_MAP[3] + 60}deg)`,
            width: '24%',
            height: '24%',
            textAlign: 'center',
            fontSize: 10,
          }}
        >
          {prizes[3].caption}
        </div>
        <img
          src={rewardImages[4]}
          alt=''
          style={{
            position: 'absolute',
            top: prizes[4].imageTop,
            right: prizes[4].imageRight,
            transform: `translate(-50%, -50%) rotate(${
              DEGREE_MAP[4] + 180
            }deg)`,
            width: prizes[4].imageWidth,
            height: prizes[4].imageHeight,
          }}
        />
        <div
          style={{
            position: 'absolute',
            top: prizes[4].captionTop,
            right: prizes[4].captionRight,
            transform: `translate(-50%, -50%) rotate(${
              DEGREE_MAP[4] + 180
            }deg)`,
            width: '24%',
            height: '24%',
            textAlign: 'center',
            fontSize: 10,
          }}
        >
          {prizes[4].caption}
        </div>
        <img
          src={rewardImages[5]}
          alt=''
          style={{
            position: 'absolute',
            top: prizes[5].imageTop,
            right: prizes[5].imageRight,
            transform: `translate(-50%, -50%) rotate(${DEGREE_MAP[5] - 60}deg)`,
            width: prizes[5].imageWidth,
            height: prizes[5].imageHeight,
          }}
        />
        <div
          style={{
            position: 'absolute',
            top: prizes[5].captionTop,
            right: prizes[5].captionRight,
            transform: `translate(-50%, -50%) rotate(${DEGREE_MAP[5] - 60}deg)`,
            width: '24%',
            height: '24%',
            textAlign: 'center',
            fontSize: 10,
          }}
        >
          {prizes[5].caption}
        </div>
      </div>
    )
  }

  const Pointer = () => {
    return (
      <div
        style={{
          top: '-5vw',
          position: 'absolute',
          width: '16vw',
          left: '42%',
        }}
        className={isRolling ? 'animate-shake' : ''}
      >
        <img src='/roulette/pointer.png' alt='' />
      </div>
    )
  }

  const CenterPlayButton = () => {
    return (
      <button
        type='button'
        style={{
          top: '26vw',
          position: 'absolute',
          width: '28vw',
          left: '36%',
          // borderWidth: 3,
        }}
        onPointerDown={() => {
          if (isRolling) {
            return
          }
          console.log('clicked button')
          if (ticketCount < 1) {
            console.log('no tickets')
            setInfoModal(true)
            setTimeout(() => {
              setInfoModal(false)
            }, 3000)
            return
          }
          if (!isRolling) {
            setTicketCount(ticketCount - 1)
            startRoulette()
          }
        }}
      >
        <img src='/roulette/button.png' alt='' />
      </button>
    )
  }

  const MissionComponent = ({
    title,
    subTitle,
    ticketCount,
    onPress,
    disabled,
  }) => {
    return (
      <div className='flex flex-row items-center justify-between w-full px-3 py-2 mt-2 text-base bg-white rounded-xl'>
        <div
          className='flex flex-col items-start'
          style={{ width: '50%', color: '#6E2323' }}
        >
          <div>{title}</div>
          {subTitle && <div className='text-xs text-black'>{subTitle}</div>}
        </div>
        <div
          style={{ width: '20%' }}
          className='flex flex-row items-center w-10 mr-2 text-sm'
        >
          <RouletteTicket className='w-6 h-full mr-1' />
          <div className='font-semibold text-red-500'>+{ticketCount}</div>
        </div>
        <button
          type='button'
          className={
            disabled
              ? 'px-2 py-2 rounded-xl bg-gray-500 text-sm text-white'
              : 'px-2 py-2 rounded-xl bg-red-500 text-sm text-white'
          }
          style={{ width: '20%' }}
          aria-hidden='true'
          onPointerDown={onPress}
          disabled={disabled}
        >
          {disabled ? '완료' : '받기'}
        </button>
      </div>
    )
  }

  const GainTicketComponent = () => {
    return (
      <div className='flex flex-col items-center px-4 pt-4 pb-80 z-3'>
        <div className='flex flex-row items-center justify-between w-full px-4 mb-2'>
          <div className='text-xl'>뽑기권 받기</div>
          <div
            className='flex flex-row items-center justify-center w-20 py-1 text-base bg-white rounded-3xl'
            aria-hidden='true'
            onPointerDown={async () => {
              setModalConfig({
                ...modalConfig,
                visible: true,
                modalType: 'CheckInPigModal',
                contents: 'RouletteNotice',
                setModalConfig,
              })
            }}
          >
            <RouletteTicket className='w-6 h-full mr-2' />x {ticketCount}
          </div>
        </div>

        <MissionComponent
          title='오늘의 뽑기권 받기'
          ticketCount='1~3'
          onPress={async () => {
            if (buttonPressed || missionConfig?.dailyTicket?.isDisable) return
            buttonPressed = true
            let rewardTicket = 1
            const randomNumber = Math.random()
            if (randomNumber < 0.5) {
              rewardTicket = 1
            } else if (randomNumber < 0.8) {
              rewardTicket = 2
            } else if (randomNumber < 1) {
              rewardTicket = 3
            }

            triggerTicketToast(rewardTicket)
            setMissionConfig({
              ...missionConfig,
              dailyTicket: {
                ...missionConfig?.dailyTicket,
                isDisable: true,
              },
            })
            await backendApis.giveRouletteTicket(
              'CheckInPig',
              'dailyRoulette',
              'dailyTicket',
              rewardTicket,
            )
            setTicketCount(ticketCount + rewardTicket)

            buttonPressed = false
          }}
          disabled={missionConfig?.dailyTicket?.isDisable}
        />

        <MissionComponent
          title='상품 구매하기'
          ticketCount={10}
          onPress={() => {
            if (missionConfig?.dailyTicketPurchase?.isDisable) return

            window.location.href = `#navigate.${JSON.stringify({
              screen: 'DailyCheckInCompatiblePurchaseMission',
              prop: {
                missionType: 'DailyRoulette',
                missionName: '매일매일 행운룰렛',
                defaultCategory: 'total',
                bannerUri:
                  'https://assets.ilevit.com/d3880344-d86c-4bfb-967d-302b5322d3b9.png',
              },
            })}`
          }}
          disabled={missionConfig?.dailyTicketPurchase?.isDisable}
        />

        <MissionComponent
          title='오늘의 친구 초대하기'
          subTitle='신규 회원이면 뽑기권 15개!'
          ticketCount='1~15'
          onPress={() => {
            if (missionConfig?.checkInPigRouletteInvite?.isDisable) return

            window.location.href = `#openBottomShareSheet.${JSON.stringify({
              code: 'CheckInPigRoulette',
              shareParams: UserStore?.userInfo?._id,
            })}`
          }}
          disabled={missionConfig?.checkInPigRouletteInvite?.isDisable}
        />
      </div>
    )
  }

  // const RouletteStartButtonComponent = () => {
  //   return (
  //     <div
  //       className='w-full py-4 mt-4 text-lg text-center text-white bg-red-500 z-2 rounded-xl'
  //       aria-hidden='true'
  //       onPointerDown={async () => {
  //         if (isRolling) {
  //           return
  //         }
  //         if (ticketCount < 1) {
  //           console.log('no tickets')
  //           setInfoModal(true)
  //           setTimeout(() => {
  //             setInfoModal(false)
  //           }, 3000)
  //           return
  //         }
  //         if (!isRolling) {
  //           setTicketCount(ticketCount - 1)
  //           startRoulette()
  //         }
  //       }}
  //     >
  //       룰렛 돌리기{isRolling}
  //     </div>
  //   )
  // }

  useEffect(() => {
    const fetchData = async () => {
      const result = await backendApis.fetchRouletteTicket()
      const missionData = await backendApis.pigMissionData()

      if (result?.status === 200 && 'rouletteTicket' in result?.data[0]) {
        setTicketCount(result?.data[0]?.rouletteTicket[rouletteTitle])
      } else if (result?.status === 200) {
        setTicketCount(0)
        await backendApis.giveRouletteTicket(
          'CheckInPig',
          'dailyRoulette',
          'dailyTicket',
          0,
        )
      }
      if (missionData?.status === 200) {
        const tmpMissionConfig = {
          ...missionConfig,
          dailyTicket: {
            ...missionConfig?.dailyTicket,
            isDisable: !missionData?.data?.dailyTicket,
          },
          dailyTicketPurchase: {
            ...missionConfig?.dailyTicketPurchase,
            isDisable: !missionData.data.dailyTicketPurchase,
          },
          checkInPigRouletteInvite: {
            ...missionConfig?.checkInPigRouletteInvite,
            isDisable: !missionData.data.checkInPigRouletteInvite,
          },
        }
        setMissionConfig(tmpMissionConfig)
      }
    }
    fetchData()
  }, [rouletteTitle, token])

  return (
    <div
      style={{
        width: '100%',
        position: 'relative',
      }}
      className='flex flex-col items-center justify-center'
    >
      <div
        style={{
          marginTop: '0vw',
          width: '80vw',
          height: '80vw',
          transform: isRolling
            ? `translate(0%, 0%) rotate(${DEGREE_MAP[rouletteRewardNumber]}deg`
            : `translate(0%, 0%)`,
          transition: isRolling ? 'all 7s cubic-bezier(1, 0.12, 0, 1) 0s' : '',
        }}
      >
        <img src='/roulette/roulette.png' alt='' />
        <PrizeComponent />
      </div>
      <Pointer />
      <CenterPlayButton />
      {rouletteTitle === 'dailyRoulette' && (
        <div
          style={{
            width: '100%',
            color: 'black',
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
          }}
          className='mt-6 rounded-t-xl'
        >
          <GainTicketComponent />
          {/* <RouletteStartButtonComponent /> */}
        </div>
      )}

      {rewardModal && (
        // 보상받기 로직 여기에 추가
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: '20%',
            top: '26%',
            zIndex: 40,
            width: '60%',
            height: '36%',
            backgroundColor: 'white',
            borderRadius: 8,
          }}
        >
          <div className='flex flex-col items-center justify-center w-full h-full text-base'>
            <img
              style={{
                width: '40%',
                left: '30%',
              }}
              alt=''
              src={prizes[rouletteRewardNumber].image}
            />
            {prizes[rouletteRewardNumber].caption === '꽝! 😔'
              ? `아쉽지만 꽝이에요 ㅠㅠ`
              : `${prizes[rouletteRewardNumber].caption} 획득!`}
            {prizes[rouletteRewardNumber].caption === '꽝! 😔' && (
              <div>대신 뽑기권을 드려요!</div>
            )}
            <button
              type='button'
              className='w-3/5 py-2 mt-8 text-base text-center text-white bg-red-500 rounded-xl'
              onPointerDown={() => {
                setRewardModal(false)
              }}
            >
              확인
            </button>
          </div>
        </div>
      )}
      {infoModal && (
        <div
          style={{
            flex: 1,
            position: 'fixed',
            left: '10%',
            top: '30%',
            zIndex: 40,
            width: '80%',
            height: '30vw',
            background: 'rgba(0,0,0,0.8)',
            borderRadius: 20,
            fontSize: '4vw',
            color: 'white',
            textAlign: 'center',
            paddingTop: '2vw',
          }}
        >
          뽑기권이 부족해요
          <div style={{ height: '1vw' }} />
          미션을 수행해서
          <div style={{ height: '1vw' }} />
          뽑기권을 얻을 수 있어요
        </div>
      )}
      {modalConfig?.visible && (
        <DailyCheckInModalHandler
          setModalConfig={setModalConfig}
          {...modalConfig}
        />
      )}
    </div>
  )
}

export default Roulette
