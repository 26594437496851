import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'

import THUserStore from '../../../stores/treasureHunt/THUserStore'
import { codepushVersionCheck } from '../../../utils/utility'
import TreasureHuntTimer from '../TreasureHuntTimer'

const EventIcon = observer(({ event }) => {
  const [hide, setHide] = useState(false)

  if (
    !codepushVersionCheck(
      THUserStore?.codepushVersion,
      event?.codepushVersion ?? '3.0.0',
    ) ||
    !THUserStore?.tutorialEndAt ||
    hide
  ) {
    return null
  }
  return (
    <div
      className='flex-shrink-0 relative'
      onClick={() => {
        event?.onClick?.()
      }}
    >
      <img
        className='w-[18vw] h-[18vw]'
        src={`../images/treasureHunt/eventButtons/${event?.src}.png`}
        alt='event'
      />
      {event?.tag && (
        <div className='absolute left-1/2 -translate-x-1/2 whitespace-nowrap rounded-full -top-[3vw] py-[2px] px-[8px] bg-[#FF5951] text-white font-bold z-[11] text-[3vw] text-center'>
          {event?.tagType === 'timer' ? (
            <>
              <TreasureHuntTimer
                style={{
                  color: '#FFFFFF',
                }}
                format='number'
                expiryTimestamp={event?.expiryTimestamp}
                onExpire={() => {
                  setHide(true)
                }}
              />
            </>
          ) : (
            event?.tag
          )}
        </div>
      )}
    </div>
  )
})

export default EventIcon
