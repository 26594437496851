import commaNumber from 'comma-number'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { Toaster, toast } from 'react-hot-toast'
import { FiChevronLeft } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import { BottomSheet } from 'react-spring-bottom-sheet'
import { useSearchParam } from 'react-use'

import BlackTextTimer from '../../components/BlackTextTimer'
import DailyCheckInScreenBottomSheetItem from '../../components/DailyCheckInScreenBottomSheetItem'
import Button from '../../components/atoms/button'
import LoadingIndicator from '../../components/atoms/loadingIndicator'
import SpeechBubble from '../../components/atoms/speechBubble'
import GaugeBar from '../../components/gaugeBar'
import GaugeBarPercentage from '../../components/gaugeBarPercentage'
import {
  AdditionalAreaIcon,
  BonusPigFoodIcon,
  Check,
  CheckInPigBoostMissionButton,
  CheckInPigMain,
  CheckInPigMissionButton,
  Close,
  CloseRounded,
  ConsecutiveBonusIcon,
  DailyRouletteButton,
  EatingPig,
  EatingPigPink,
  EatingSuperPig,
  EatingSuperduperPig,
  ExclamationMarkIcon,
  HappinessMission,
  HappinessMud,
  HappinessToy,
  Heart,
  HeartFaded,
  LuckyScratchButton,
  ModalPigOnly,
  ModalPinkPig,
  MysterySuperduperPig,
  PigBackground,
  PigFeedButton,
  PigFood,
  PigFoodColored,
  PigFoodEarn,
  PigHeartBordered,
  PigTitle,
  PigTitleInfo,
  PinkPig,
  Poof,
  SuperPig,
  SuperduperPig,
} from '../../images/dailyCheckIn'
import DailyCheckInModalHandler from '../../modals/dailyCheckIn/DailyCheckInModalHandler'
import AuthStore from '../../stores/AuthStore'
import ToastStore from '../../stores/ToastStore'
import backendApis from '../../utils/backendApis'
import useCheckCodePushVersion from '../../utils/useConvertCodePushVersion'

let buttonPressed = false
let hidden
let visibilityChange

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

if (typeof document.hidden !== 'undefined') {
  // Opera 12.10 and Firefox 18 and later support
  hidden = 'hidden'
  visibilityChange = 'visibilitychange'
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden'
  visibilityChange = 'msvisibilitychange'
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden'
  visibilityChange = 'webkitvisibilitychange'
}

const lastActiveDate = Date.now()

const CheckInPigPage = observer(() => {
  function onDismiss() {
    setOpenFoodBottomSheet(false)
    setOpenHappinessBottomSheet(false)
    setOpenBoostedPigBottomSheet(false)
  }
  const triggerFoodToast = (food) =>
    toast(`밥 ${food}g을 획득했어요!`, {
      id: 'food rewarded',
      duration: 2000,
      position: 'top-center',
      style: {
        paddingLeft: 8,
        paddingTop: 20,
        paddingBottom: 20,
        fontWeight: 'bold',
      },
      icon: '🍚',
    })
  const triggerNoFoodToast = () =>
    toast(`밥이 모자라요. \n미션을 수행하고 밥을 획득하세요!`, {
      id: 'no food',
      duration: 2000,
      position: 'top-center',
      style: {
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
        paddingLeft: 8,
        paddingTop: 20,
        paddingBottom: 20,
        fontWeight: 'bold',
      },
      icon: '😔',
    })
  const triggerHappinessToast = (happiness) =>
    toast(`돈돈이 행복도가 ${happiness} 올랐어요!`, {
      id: 'happiness rewarded',
      duration: 2000,
      position: 'top-center',
      style: {
        paddingLeft: 8,
        paddingTop: 20,
        paddingBottom: 20,
        fontWeight: 'bold',
      },
      icon: '💓',
    })
  const triggerNoItemToast = (itemType) =>
    toast(`${itemType} 부족해요. \n미션을 수행해보세요!`, {
      id: 'no item',
      duration: 2000,
      position: 'top-center',
      style: {
        paddingLeft: 8,
        paddingTop: 20,
        paddingBottom: 20,
        fontWeight: 'bold',
      },
      icon: '😔',
    })
  const triggerPetToast = () =>
    toast(`돈돈이를 눌러 쓰다듬어주세요!`, {
      id: 'petting guide',
      duration: 2500,
      position: 'top-center',
      style: {
        marginTop: 120,
        paddingLeft: 8,
        paddingTop: 20,
        paddingBottom: 20,
        fontWeight: 'bold',
      },
      icon: '👇',
    })
  const triggerBoostToast = () =>
    toast(`슈퍼돈돈이를 받았어요!`, {
      id: 'boost rewarded',
      duration: 2500,
      position: 'top-center',
      style: {
        marginTop: 120,
        paddingLeft: 8,
        paddingTop: 20,
        paddingBottom: 20,
        fontWeight: 'bold',
      },
      icon: '🐷',
    })
  const triggerBoostStartToast = () =>
    toast(`슈퍼돈돈이 진화!`, {
      id: 'boost rewarded',
      duration: 2500,
      position: 'top-center',
      style: {
        marginTop: 80,
        paddingLeft: 8,
        paddingTop: 20,
        paddingBottom: 20,
        fontWeight: 'bold',
      },
      icon: '🔥',
    })
  // const scrollDown = () => {
  //   window.scrollTo({
  //     top: document.documentElement.scrollHeight - window.innerHeight,
  //     behavior: 'smooth',
  //   })
  // }
  // const scrollUp = () => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: 'smooth',
  //   })
  // }
  const navigate = useNavigate()
  const token = useSearchParam('token')
  const rewardType = useSearchParam('rewardType')
  const rewardAmount = useSearchParam('rewardAmount')
  const isFrom = useSearchParam('isFrom')

  const balloonRemoveTimer = useRef()
  const codePushVersionAvailable = useCheckCodePushVersion()

  const [isLoading, setIsLoading] = useState(false)
  const [animatingOn, setAnimatingOn] = useState(false)
  const [shake, setShake] = useState(false)
  const [transform, setTransform] = useState(false)
  const [openFoodBottomSheet, setOpenFoodBottomSheet] = useState(false)
  const [openHappinessBottomSheet, setOpenHappinessBottomSheet] =
    useState(false)
  const [openBoostedPigBottomSheet, setOpenBoostedPigBottomSheet] =
    useState(false)
  const [modalConfig, setModalConfig] = useState({ visible: false })
  const [missionConfig, setMissionConfig] = useState({
    checkInMission: { isShow: true, isDisable: true },
    wakeUpMission: { isShow: true, isDisable: true },
    playMission: { isShow: true, isDisable: true },
    timeMission: { isShow: true, isDisable: true },
    browsingMission: { isShow: true, isDisable: true },
    purchaseMission: { isShow: true, isDisable: true },
    onboardingMission: { isShow: false, isDisable: true },
    luckyScratch: { isShow: true, isDisable: true, isCounted: false },
    petOnceMission: { isShow: true, isDisable: true, isCounted: false },
    toyStorePurchaseMission: {
      isShow: true,
      isDisable: true,
      isCounted: false,
    },
    mudBrowsingMission: { isShow: true, isDisable: true, isCounted: false },
    boostTimeCheckIn: { isShow: true, isDisable: true, isCounted: false },
    boostWeeklyFeed: { isShow: true, isDisable: true, isCounted: false },
    dailyTicket: { isShow: true, isDisable: true, isCounted: false },
    boostItemPurchase: { isShow: true, isDisable: true, isCounted: false },
  })
  const [currentExpLevel, setCurrentExpLevel] = useState(9)
  const [pigLevelData, setPigLevelData] = useState([])
  const [pigData, setPigData] = useState({
    food: 0,
    bonusFood: 0,
    exp: 0,
    happiness: 0,
    toy: 0,
    mud: 0,
    weeklyFeedingCount: 0,
    boostDondon: 0,
    boostCount: 0,
  })
  const [bonusFoodLevel, setBonusFoodLevel] = useState({
    level: {
      level1: 700,
      level2: 2100,
      level3: 3500,
    },
  })
  const [pigCustomizationData, setPigCustomizationData] = useState()
  const [speechBubbleText, setSpeechBubbleText] = useState()
  const [userCodePushVersion, setUserCodePushVersion] = useState()

  const codePushVersion = async () => {
    const result = await backendApis.getUserCodePushVersion()
    setUserCodePushVersion(result?.codePushVersion)
  }

  const [loadingPage, setLoadingPage] = useState(false)
  const [play, setPlay] = useState(true)
  useEffect(() => {
    const interval = setInterval(() => {
      setPlay(!play)
    }, 600)
    return () => clearInterval(interval)
  }, [play])

  useEffect(() => {
    const fetchInitialData = async () => {
      setLoadingPage(true)
      await sleep(1000)
      if (AuthStore.token) {
        await codePushVersion()
        await fetchLevelData()
        await receiveBonusFood()
        await evaporateHappiness()
        await fetchData()
        await fetchCustomizationData()
      }
      setLoadingPage(false)
    }
    fetchInitialData()
  }, [AuthStore.token])

  useEffect(() => {
    AuthStore.setToken(token)
    if (rewardAmount) {
      if (rewardType === 'food') {
        setOpenFoodBottomSheet(false)
        triggerFoodToast(rewardAmount)
      }
    }
  }, [])

  useEffect(() => {
    document.addEventListener(
      visibilityChange,
      (visibility) => {
        const now = Date.now()
        if (now - lastActiveDate < 1000 * 5) return
        if (document.visibilityState === 'visible') {
          setIsLoading(true)
          setTimeout(async () => {
            await receiveBonusFood()
            fetchLevelData()
            fetchData()
            setIsLoading(false)
          }, 500)
        }
      },
      false,
    )
  }, [token])

  useEffect(() => {
    balloonRemoveTimer.current = setTimeout(() => {
      removeSpeechBubble()
    }, 3000)
  }, [])

  const evaporateHappiness = async () => {
    const result = await backendApis.evaporateHappiness()
    setIsLoading(true)
    if (
      result?.status === 200 &&
      result?.msg?.result !== 'happiness not evaporated'
    ) {
      fetchData()
    }
    setIsLoading(false)
  }

  const fetchLevelData = async () => {
    const levelData = await backendApis.pigLevelData()
    setIsLoading(true)
    if (levelData?.status === 200) {
      const data = levelData?.data[0]
      setPigLevelData(data)
    }
    setIsLoading(false)
  }

  const receiveBonusFood = async () => {
    const pigData = await backendApis.pigData()
    if (pigData?.status === 200 && pigData?.data[0]) {
      const data = pigData?.data[0]
      const missionData = await backendApis.pigMissionData()
      const tmpPigData = []

      if (
        // 전날 쌓인 내일받기 지급로직
        data?.bonusFood > 0 &&
        missionData?.data?.bonusFoodReceivedAt
      ) {
        const retrieveResult = await backendApis.retrieveBonusFood()
        if (retrieveResult?.msg === 'bonusFood retrieved') {
          setModalConfig({
            ...modalConfig,
            visible: true,
            modalType: 'CheckInPigBonusFoodAlert',
            contents: 'RetrieveBonusFood',
            setModalConfig,
            givenBonusFood: data.bonusFood,
            bonusLevel: '',
            triggerFoodToast: async () => {
              triggerFoodToast(data?.bonusFood)
            },
          })
          tmpPigData.push({
            bonusFood: 0,
            food: data.food + data.bonusFood,
            exp: data.exp,
            happiness: data?.happiness,
            toy: data?.toy,
            mud: data?.mud,
            weeklyFeedingCount: data?.weeklyFeedingCount,
            boostDondon: data?.boostDondon,
            boostCount: data?.boostCount,
          })
        } else {
          tmpPigData.push({
            bonusFood: data?.bonusFood,
            food: data?.food,
            exp: data?.exp,
            happiness: data?.happiness,
            toy: data?.toy,
            mud: data?.mud,
            weeklyFeedingCount: data?.weeklyFeedingCount,
            boostDondon: data?.boostDondon,
            boostCount: data?.boostCount,
          })
        }
      } else if (
        data?.bonusFood === 0 &&
        missionData?.data?.bonusFoodReceivedAt
      ) {
        // 내일받기 미션 최신화 해주기
        backendApis.resetBonusFood()
      } else {
        // 기본 데이터 세팅
        tmpPigData.push({
          bonusFood: data?.bonusFood,
          food: data?.food,
          exp: data?.exp,
          happiness: data?.happiness,
          toy: data?.toy,
          mud: data?.mud,
          weeklyFeedingCount: data?.weeklyFeedingCount,
          boostDondon: data?.boostDondon,
          boostCount: data?.boostCount,
        })
      }
      setPigData(tmpPigData)
    }
  }

  const fetchFoodInfo = async () => {
    const rawPigData = await backendApis.pigData()
    if (rawPigData?.status === 200 && rawPigData?.data[0]) {
      const tmpPigData = []
      const data = rawPigData?.data[0]
      tmpPigData.push({
        bonusFood: data?.bonusFood,
        food: data?.food,
        exp: data?.exp,
        happiness: data?.happiness,
        toy: data?.toy,
        mud: data?.mud,
        weeklyFeedingCount: data?.weeklyFeedingCount,
        boostDondon: data?.boostDondon,
        boostCount: data?.boostCount,
      })
      setPigData(tmpPigData)

      return tmpPigData
    }
  }

  const fetchData = async (type) => {
    setIsLoading(true)
    const pigData = await backendApis.pigData()
    if (pigData?.status === 200 && pigData?.data[0]) {
      const missionData = await backendApis.pigMissionData()
      const luckyScratchData = await backendApis.luckyScratchLog()
      const tmpPigData = []
      const data = pigData?.data[0]
      const now = moment()
      const hour = moment(now).hour()

      // setting Difficulty Info
      if (data?.envelopeCount && data?.envelopeCount < 10) {
        setCurrentExpLevel(data?.envelopeCount)
      }

      // setting SpeechBubble Info
      if (type === 'happiness') {
        changeSpeechBubble('happiness')
      } else {
        changeSpeechBubble('hungry')
      }

      if (
        // 신규 온보딩 로직
        data.accumulatedExp === 0 &&
        data.food === 0 &&
        !data.lastFoodGivenAt
      ) {
        setModalConfig({
          ...modalConfig,
          visible: true,
          modalType: 'CheckInPigModal',
          contents: 'Welcome',
          triggerFoodToast: () => {
            const tempPigData = []
            tempPigData.push({
              bonusFood: 0,
              food: 500,
              exp: 0,
              happiness: 100,
              toy: 1,
              mud: 200,
              weeklyFeedingCount: 0,
              boostDondon: 0,
              boostCount: 0,
            })
            setPigData(tempPigData)

            triggerFoodToast(500)
            setModalConfig({
              ...modalConfig,
              visible: true,
              modalType: 'CheckInPigTutorial',
              afterClick: async () => {
                const tempPigData = []
                setAnimatingOn(true)
                setTimeout(() => {
                  setAnimatingOn(false)
                }, 3000)
                tempPigData.push({
                  food: 400,
                  exp: 100,
                  bonusFood: 100,
                  happiness: 101,
                  toy: 1,
                  mud: 200,
                  weeklyFeedingCount: 1,
                  boostDondon: 0,
                  boostCount: 0,
                })
                setPigData(tempPigData)

                await backendApis.feedCheckInPig(1)
              },
            })
          },
        })
      } else {
        // setting Item Info
        tmpPigData.push({
          bonusFood: data?.bonusFood,
          food: data?.food,
          exp: data?.exp,
          happiness: data?.happiness,
          toy: data?.toy,
          mud: data?.mud,
          weeklyFeedingCount: data?.weeklyFeedingCount,
          boostDondon: data?.boostDondon,
          boostCount: data?.boostCount,
        })
      }

      setPigData(tmpPigData)
      await receiveBonusFood()
      setBonusFoodLevel(data?.bonusFoodLevel)

      // setting Mission Info
      if (missionData.status === 200) {
        const tmpMissionConfig = {
          ...missionConfig,

          checkInMission: {
            ...missionConfig?.checkInMission,
            isDisable: !missionData.data.checkInMission,
          },
          bonusFoodReceivedAt: {
            ...missionConfig?.bonusFoodReceivedAt,
            isDisable: !missionData.data.bonusFoodReceivedAt,
          },
          wakeUpMission: {
            ...missionConfig?.wakeUpMission,
            isDisable: !missionData.data.wakeUpMission || hour >= 12,
          },
          playMission: {
            ...missionConfig?.playMission,
            isDisable: !missionData.data.playMission || hour < 12,
          },
          browsingMission: {
            ...missionConfig?.browsingMission,
            isDisable: !missionData.data.browsingMission,
          },
          onboardingMission: {
            ...missionConfig?.onboardingMission,
            isShow:
              missionData.data.onboardingMission.count < 7 &&
              !missionData.data.onboardingMission.failed &&
              (!missionData.data.onboardingMission.retryCount ||
                missionData.data.onboardingMission.retryCount < 5),
            isDisable: !missionData.data.onboardingMission.isAvailable,
            count: missionData.data.onboardingMission.count,
          },
          luckyScratch: {
            ...missionConfig?.luckyScratch,
            isDisable: !missionData.data.luckyScratch,
          },
          purchaseMission: {
            ...missionConfig?.purchaseMission,
            isDisable: !missionData.data.purchaseMission,
          },
          petOnceMission: {
            ...missionConfig?.petOnceMission,
            isDisable: !missionData.data.petOnceMission,
            count: 0,
          },
          toyStorePurchaseMission: {
            ...missionConfig?.toyStorePurchaseMission,
            isDisable: !missionData.data.toyStorePurchaseMission,
          },
          mudBrowsingMission: {
            ...missionConfig?.mudBrowsingMission,
            isDisable: !missionData.data.mudBrowsingMission,
          },
          boostTimeCheckIn: {
            ...missionConfig?.boostTimeCheckIn,
            isDisable:
              !missionData?.data?.boostTimeCheckIn?.isAvailable || false,
            count: missionData?.data?.boostTimeCheckIn?.count || 0,
            lastLoggedAt: missionData?.data?.boostTimeCheckIn?.lastLoggedAt,
          },
          boostWeeklyFeed: {
            ...missionConfig?.boostWeeklyFeed,
            isDisable: !missionData?.data?.boostWeeklyFeed || false,
          },
          boostItemPurchase: {
            ...missionConfig?.boostItemPurchase,
            isDisable: !missionData?.data?.boostItemPurchase || false,
          },
          dailyTicket: {
            ...missionConfig?.dailyTicket,
            isDisable: !missionData?.data?.dailyTicket || false,
          },
        }
        setMissionConfig(tmpMissionConfig)

        // setting LuckyScratch Info
        if (
          luckyScratchData?.status === 200 &&
          luckyScratchData?.data?.length > 0
        ) {
          const purchaseRewardLog =
            await backendApis.getLuckyScratchPurchaseReward()

          const now = moment()
          const rewardedAt = moment(luckyScratchData?.data[0]?.createdAt)
          const term = now.diff(rewardedAt, 'hours')

          if (term < 3 && purchaseRewardLog?.data?.length === 0) {
            setMissionConfig({
              ...tmpMissionConfig,
              luckyScratch: {
                ...tmpMissionConfig?.luckyScratch,
                isDisable: true,
                isAvailable: true,
                scratchReward: luckyScratchData?.data[0]?.rewardAmount,
                rewardedAt: new Date(rewardedAt),
              },
            })
          } else if (term < 3 && purchaseRewardLog?.data?.length !== 0) {
            setMissionConfig({
              ...tmpMissionConfig,
              luckyScratch: {
                ...tmpMissionConfig?.luckyScratch,
                isDisable: true,
                scratchReward: luckyScratchData?.data[0]?.rewardAmount,
                rewardedAt: new Date(rewardedAt),
                isAvailable: false,
              },
            })
          } else {
            setMissionConfig({
              ...tmpMissionConfig,
              luckyScratch: {
                ...tmpMissionConfig?.luckyScratch,
                isDisable: false,
              },
            })
          }
        }
      }

      setIsLoading(false)
    } else {
      setIsLoading(false)
      ToastStore.toastOn({
        type: 'error',
        message: '알 수 없는 오류가 발생했어요.\n 잠시후 다시 시도해주세요',
        duration: 2000,
      })
    }
  }

  const fetchCustomizationData = async () => {
    const customizationData = await backendApis.pigCustomizationData()
    setIsLoading(true)
    if (customizationData.status === 200) {
      setPigCustomizationData(customizationData.data)
    }

    setIsLoading(false)
  }

  const feedPig = async (pigData, pigLevelData, multiple) => {
    if (buttonPressed) return
    buttonPressed = true

    const latestPigData = await fetchFoodInfo()

    // 밥 부족
    if (latestPigData[0]?.food < 100 * multiple) {
      triggerNoFoodToast()
      setOpenFoodBottomSheet(true)
      buttonPressed = false

      return
    }
    const tmpPigData = []
    const data = latestPigData[0]
    const currentHappiness = data?.happiness || 31 // 현재 행복도
    const currentBoost = data?.boostCount || 0

    const levelOneMultiple = pigLevelData?.bonusFood[0]?.actualMultiple || 1
    const levelTwoMultiple = pigLevelData?.bonusFood[1]?.actualMultiple || 0.5
    const levelThreeMultiple = pigLevelData?.bonusFood[2]?.actualMultiple || 0.4

    let actualMultiple = multiple // 보너스 레벨별 경험치&내일획득 난이도 조절
    let happinessMultiple = 1
    let pigSkinMultiple = 1
    let boostDondonMultiple = 1

    // 각종 경험치 boost (색상, 행복도, 슈퍼돈돈이)
    if (pigCustomizationData?.length > 0) {
      pigSkinMultiple = 1.005
    }
    if (currentHappiness >= 100) {
      happinessMultiple = 1.05
    } else if (currentHappiness > 30) {
      happinessMultiple = 1
    } else {
      happinessMultiple = 0.7
    }
    if (currentBoost > 0) {
      boostDondonMultiple = 2
    }
    actualMultiple =
      multiple * happinessMultiple * pigSkinMultiple * boostDondonMultiple

    // 애니메이션
    setShake(true)
    changeSpeechBubble('foodGiven')
    setTimeout(() => {
      setShake(false)
      setAnimatingOn(true)
      setTimeout(() => {
        setAnimatingOn(false)
      }, 1500)
    }, 500)

    // 경험치 100%면 봉투 지급 로직
    if (
      data?.exp + actualMultiple * 100 >=
      pigLevelData?.totalExp[currentExpLevel]
    ) {
      console.log('envelope reward start')
      const processOpenEnvelopeResult = await backendApis.processOpenEnvelopeV2(
        'ey-daily-check-in-220410-v1',
        1,
        'check-in-pig',
        'a',
      )
      if (processOpenEnvelopeResult?.status === 200) {
        console.log('envelope reward success')
        setAnimatingOn(true)
        setTimeout(() => {
          setAnimatingOn(false)
        }, 2000)
        await backendApis.feedCheckInPig(multiple)
        await backendApis.openPigEnvelope()

        setModalConfig({
          ...modalConfig,
          visible: true,
          modalType: 'DailyCheckInScreenRewardReceiveModal',
          rewardAmountForDisplay: processOpenEnvelopeResult?.data?.totalReward,
          setModalConfig,
        })

        fetchData()
      } else {
        ToastStore.toastOn({
          type: 'error',
          message: '알 수 없는 오류가 발생했어요.\n 잠시후 다시 시도해주세요',
          duration: 2000,
        })
      }
      buttonPressed = false
    } else {
      // 내일획득 로직
      // 각 단계별로 multiple이 적용되어 부여되는 내일획득 양이 줄어든다. exp는 5월 6일 이후로 줄어들지 않음
      if (
        data?.bonusFood +
          Math.floor(levelThreeMultiple * actualMultiple * 100) >=
        bonusFoodLevel?.level3 + 700
      ) {
        tmpPigData.push({
          exp: data?.exp + Math.floor(actualMultiple * 100),
          bonusFood:
            data?.bonusFood +
            Math.floor(levelThreeMultiple * actualMultiple * 100),
          food: data?.food - 100 * multiple,
          happiness: data?.happiness + 1 * multiple,
          weeklyFeedingCount: data?.weeklyFeedingCount + 1 * multiple,
          boostDondon: data?.boostDondon,
          boostCount: data?.boostCount > 0 ? data?.boostCount - multiple : 0,
        })
      } else if (
        data?.bonusFood + Math.floor(levelTwoMultiple * actualMultiple * 100) >=
        bonusFoodLevel?.level3
      ) {
        if (
          data?.bonusFood +
            Math.floor(levelTwoMultiple * actualMultiple * 100) <=
          bonusFoodLevel?.level3 + 700
        ) {
          tmpPigData.push({
            food: data?.food - 100 * multiple,
            exp: data?.exp + Math.floor(actualMultiple * 100),
            bonusFood:
              data?.bonusFood +
              Math.floor(levelTwoMultiple * actualMultiple * 100) +
              700,
            happiness: data?.happiness + 1 * multiple,
            weeklyFeedingCount: data?.weeklyFeedingCount + 1 * multiple,
            boostDondon: data?.boostDondon,
            boostCount: data?.boostCount > 0 ? data?.boostCount - multiple : 0,
          })
          setModalConfig({
            ...modalConfig,
            visible: true,
            modalType: 'CheckInPigBonusFoodAlert',
            contents: 'BonusFoodGiven',
            setModalConfig,
            currentBonus: bonusFoodLevel?.level3,
            givenBonusFood: bonusFoodLevel?.level3 + 700,
            bonusLevel: '',
          })
        } else {
          tmpPigData.push({
            food: data?.food - 100 * multiple,
            exp: data?.exp + Math.floor(actualMultiple * 100),
            bonusFood:
              data?.bonusFood +
              Math.floor(levelTwoMultiple * actualMultiple * 100),
            happiness: data?.happiness + 1 * multiple,
            weeklyFeedingCount: data?.weeklyFeedingCount + 1 * multiple,
            boostDondon: data?.boostDondon,
            boostCount: data?.boostCount > 0 ? data?.boostCount - multiple : 0,
          })
        }
      } else if (
        data?.bonusFood + Math.floor(levelOneMultiple * actualMultiple * 100) >=
        bonusFoodLevel?.level2
      ) {
        if (
          data?.bonusFood +
            Math.floor(levelOneMultiple * actualMultiple * 100) <=
          bonusFoodLevel?.level2 + 600
        ) {
          tmpPigData.push({
            food: data?.food - 100 * multiple,
            exp: data?.exp + Math.floor(actualMultiple * 100),
            bonusFood:
              data?.bonusFood +
              Math.floor(levelOneMultiple * actualMultiple * 100) +
              600,
            happiness: data?.happiness + 1 * multiple,
            weeklyFeedingCount: data?.weeklyFeedingCount + 1 * multiple,
            boostDondon: data?.boostDondon,
            boostCount: data?.boostCount > 0 ? data?.boostCount - multiple : 0,
          })
          setModalConfig({
            ...modalConfig,
            visible: true,
            modalType: 'CheckInPigBonusFoodAlert',
            contents: 'BonusFoodGiven',
            setModalConfig,
            currentBonus: bonusFoodLevel?.level2,
            givenBonusFood: bonusFoodLevel?.level2 + 600,
            bonusLevel: bonusFoodLevel?.level3,
          })
        } else {
          tmpPigData.push({
            food: data?.food - 100 * multiple,
            exp: data?.exp + Math.floor(actualMultiple * 100),
            bonusFood:
              data?.bonusFood +
              Math.floor(levelOneMultiple * actualMultiple * 100),
            happiness: data?.happiness + 1 * multiple,
            weeklyFeedingCount: data?.weeklyFeedingCount + 1 * multiple,
            boostDondon: data?.boostDondon,
            boostCount: data?.boostCount > 0 ? data?.boostCount - multiple : 0,
          })
        }
      } else if (
        data?.bonusFood + Math.floor(levelOneMultiple * actualMultiple * 100) >=
        bonusFoodLevel?.level1
      ) {
        if (
          data?.bonusFood +
            Math.floor(levelOneMultiple * actualMultiple * 100) <=
          bonusFoodLevel?.level1 + 700
        ) {
          tmpPigData.push({
            food: data?.food - 100 * multiple,
            exp: data?.exp + Math.floor(actualMultiple * 100),
            bonusFood:
              data?.bonusFood +
              Math.floor(levelOneMultiple * actualMultiple * 100) +
              700,
            happiness: data?.happiness + 1 * multiple,
            weeklyFeedingCount: data?.weeklyFeedingCount + 1 * multiple,
            boostDondon: data?.boostDondon,
            boostCount: data?.boostCount > 0 ? data?.boostCount - multiple : 0,
          })
          setModalConfig({
            ...modalConfig,
            visible: true,
            modalType: 'CheckInPigBonusFoodAlert',
            contents: 'BonusFoodGiven',
            setModalConfig,
            currentBonus: bonusFoodLevel?.level1,
            givenBonusFood: bonusFoodLevel?.level1 + 700,
            bonusLevel: bonusFoodLevel?.level2,
          })
        } else {
          tmpPigData.push({
            food: data?.food - 100 * multiple,
            exp: data?.exp + Math.floor(actualMultiple * 100),
            bonusFood:
              data?.bonusFood +
              Math.floor(levelOneMultiple * actualMultiple * 100),
            happiness: data?.happiness + 1 * multiple,
            weeklyFeedingCount: data?.weeklyFeedingCount + 1 * multiple,
            boostDondon: data?.boostDondon,
            boostCount: data?.boostCount > 0 ? data?.boostCount - multiple : 0,
          })
        }
      } else {
        // 일반적인 밥 주기 로직
        tmpPigData.push({
          food: data?.food - 100 * multiple,
          exp: data?.exp + Math.floor(actualMultiple * 100),
          bonusFood: data?.bonusFood + Math.floor(actualMultiple * 100),
          happiness: data?.happiness + 1 * multiple,
          toy: data?.toy,
          mud: data?.mud,
          weeklyFeedingCount: data?.weeklyFeedingCount,
          boostDondon: data?.boostDondon,
          boostCount: data?.boostCount > 0 ? data?.boostCount - multiple : 0,
        })
      }
      const randomNumber = Math.random()
      const scratchReward = Math.floor(randomNumber * 100 * 50) + 100
      if (
        randomNumber < 0.01 &&
        missionConfig?.luckyScratch?.isDisable === false &&
        missionConfig?.luckyScratch?.isAvailable !== false
      ) {
        await backendApis.gainFood('luckyScratch', scratchReward, 'food')
        setMissionConfig({
          ...missionConfig,
          luckyScratch: {
            ...missionConfig?.luckyScratch,
            isDisable: true,
            scratchReward,
            rewardedAt: new Date(),
            isAvailable: false,
          },
        })
        setModalConfig({
          ...modalConfig,
          visible: true,
          modalType: 'CheckInPigModal',
          contents: 'LuckyScratch',
          scratchReward,
          triggerFoodToast: () => {
            fetchData()
            setOpenFoodBottomSheet(false)
            triggerFoodToast(scratchReward)
          },
        })
      }
      await backendApis.feedCheckInPig(multiple)
      console.log('feeding complete')
      setPigData(tmpPigData)
      buttonPressed = false
    }
  }

  const gainedFood = async (
    missionType,
    rewardAmount,
    rewardType,
    missionConfig,
  ) => {
    setIsLoading(true)

    const result = await backendApis.gainFood(
      missionType,
      rewardAmount,
      rewardType,
    )
    if (result.status === 200) {
      setMissionConfig({
        ...missionConfig,
        [`${missionType}`]: {
          ...missionConfig?.[`${missionType}`],
          isDisable: true,
        },
      })
      if (rewardType === 'food') {
        fetchData()
        setOpenFoodBottomSheet(false)
        triggerFoodToast(rewardAmount)
      }
    }
    setIsLoading(false)
  }
  const gainedHappiness = async (
    missionType,
    rewardAmount,
    rewardType,
    missionConfig,
  ) => {
    setIsLoading(true)

    const result = await backendApis.gainFood(
      missionType,
      rewardAmount,
      rewardType,
    )
    if (result.status === 200) {
      setMissionConfig({
        ...missionConfig,
        [`${missionType}`]: {
          ...missionConfig?.[`${missionType}`],
          isDisable: true,
        },
      })
      fetchData(`${rewardType}`)
      setOpenHappinessBottomSheet(false)
      triggerHappinessToast(rewardAmount)
    }
    setIsLoading(false)
  }

  const usePigItem = async (itemType, itemQuantity) => {
    const result = await backendApis.usePigItem(itemType, itemQuantity)
    if (result?.msg?.result === 'toy successfully used') {
      if (itemType === 'toy') {
        setOpenHappinessBottomSheet(false)
        triggerHappinessToast(100)
      }
      fetchData('happiness')
    } else if (result?.msg?.result === 'boostDondon successfully used') {
      triggerBoostStartToast()
    } else if (result?.msg?.result === 'not enough items') {
      let itemTypeKOR = '장난감이'
      if (itemType === 'toy') {
        itemTypeKOR = '장난감이'
      } else if (itemType === 'mud') {
        itemTypeKOR = '진흙이'
      } else {
        itemTypeKOR = '아이템이'
      }
      triggerNoItemToast(itemTypeKOR)
    }
  }

  const removeSpeechBubble = () => {
    setSpeechBubbleText()
  }

  const changeSpeechBubble = async (eventType) => {
    const randomNumber = Math.random()
    const speechBubble = await backendApis.loadDondonSpeechBubble(eventType)

    if (speechBubble?.status === 200) {
      const speechBubbleIndex = Math.floor(
        speechBubble?.data?.length * randomNumber,
      )
      setSpeechBubbleText(speechBubble.data[speechBubbleIndex].text)
    }

    clearTimeout(balloonRemoveTimer.current)
    balloonRemoveTimer.current = setTimeout(() => {
      removeSpeechBubble()
    }, 1000 * 2)
  }

  const HeaderComponent = () => {
    return (
      <section
        className='fixed flex flex-row items-center w-screen overflow-hidden'
        style={{ zIndex: 1 }}
      >
        <button
          type='button'
          className='flex flex-1 px-3 py-2 whitespace-nowrap'
          style={{ width: '25vw' }}
          onClick={() => {
            if (isFrom === 'web') {
              navigate(`/daily-check-in?token=${token}`)
            } else {
              window.location.href = `#navigate.${JSON.stringify({
                screen: 'NewDailyCheckInScreen',
              })}`
            }
          }}
        >
          <FiChevronLeft className='w-8 h-8 stroke-gray-600' />
        </button>
        <div className='text-center' style={{ width: '50vw' }}>
          <PigTitle className='w-full' />
        </div>

        <button
          type='button'
          className='flex justify-end pr-6 tracking-tight'
          style={{ width: '25vw' }}
          onClick={() => {
            setModalConfig({
              ...modalConfig,
              visible: true,
              modalType: 'CheckInPigModal',
              contents: 'Notice',
              setModalConfig,
            })
          }}
        >
          <PigTitleInfo className='w-6' />
        </button>
      </section>
    )
  }

  const BonusFoodComponent = ({ pigData, pigLevelData }) => {
    let targetAmount = 0
    let rewardAmount = 0

    if (pigData[0]?.bonusFood > bonusFoodLevel?.level3) {
      targetAmount = ''
      rewardAmount = pigData[0]?.bonusFood
    } else if (pigData[0]?.bonusFood > bonusFoodLevel?.level2 + 600) {
      targetAmount = bonusFoodLevel?.level3
      rewardAmount = bonusFoodLevel?.level3 + 700
    } else if (pigData[0]?.bonusFood > bonusFoodLevel?.level1 + 700) {
      targetAmount = bonusFoodLevel?.level2
      rewardAmount = bonusFoodLevel?.level2 + 600
    } else {
      targetAmount = bonusFoodLevel?.level1
      rewardAmount = bonusFoodLevel?.level1 + 700
    }

    return (
      <div
        className='absolute flex jua'
        style={{ left: '2%', top: 100, zIndex: 1 }}
      >
        <Button
          appearance=' '
          className='relative flex flex-col items-center justify-center w-full '
          onClick={async () => {
            if (buttonPressed) return
            buttonPressed = true
            setModalConfig({
              ...modalConfig,
              visible: true,
              modalType: 'CheckInPigModal',
              contents: 'BonusFood',
              setModalConfig,
              pigData: pigData[0],
              pigLevelData: bonusFoodLevel,
            })
            buttonPressed = false
          }}
        >
          <BonusPigFoodIcon className='z-20 w-16' />
          <div
            className='absolute z-20 text-sm text-white drop-shadow'
            style={{
              textShadow:
                '-2px 0 #884910, 0 2px #884910, 2px 0 #884910, 0 -2px #884910',
              top: 0,
            }}
          >
            {commaNumber(pigData[0]?.bonusFood) || 0}g
          </div>
        </Button>
        {targetAmount && (
          <button
            type='button'
            onClick={async () => {
              if (buttonPressed) return
              buttonPressed = true
              setModalConfig({
                ...modalConfig,
                visible: true,
                modalType: 'CheckInPigModal',
                contents: 'BonusFood',
                setModalConfig,
                pigData: pigData[0],
                pigLevelData: bonusFoodLevel,
              })
              buttonPressed = false
            }}
          >
            <AdditionalAreaIcon
              className='absolute z-10'
              style={{
                top: -6,
                left: 40,
                width: 120,
                height: 76,
                maxWidth: 200,
              }}
            />

            <div
              className='absolute w-full drop-shadow text-xs text-[#083C1E] z-20'
              style={{
                textShadow:
                  '-0.8px 0 #ffffff, 0 0.8px #ffffff, 0.8px 0 #ffffff, 0 -0.8px #ffffff',
                top: 14,
                left: 66,
              }}
            >
              {commaNumber(targetAmount) || 0}g 채우면
            </div>

            <div
              className='absolute w-full drop-shadow text-xs text-[#083C1E] z-20'
              style={{
                textShadow:
                  '-0.8px 0 #ffffff, 0 0.8px #ffffff, 0.8px 0 #ffffff, 0 -0.8px #ffffff',
                top: 30,
                left: 66,
              }}
            >
              {commaNumber(rewardAmount) || 0}g 획득
            </div>
          </button>
        )}
      </div>
    )
  }

  const ConsecutiveCheckIn = ({ pigData, pigLevelData }) => {
    return (
      <div
        className='absolute flex items-center justify-center w-20 h-20 jua'
        style={{ right: '4%', bottom: 280, zIndex: 1 }}
      >
        <Button
          appearance='relative'
          className='flex-col items-center justify-center '
          onClick={async () => {
            if (buttonPressed) return
            buttonPressed = true

            if (!missionConfig.onboardingMission.isDisable) {
              setModalConfig({
                ...modalConfig,
                visible: true,
                modalType: 'CheckInPigModal',
                contents: 'ConsecutiveCheckIn',
                setModalConfig,
                triggerFoodToast: (value) => {
                  setMissionConfig({
                    ...missionConfig,
                    onboardingMission: {
                      ...missionConfig?.onboardingMission,
                      isDisable: true,
                    },
                  })

                  const tempPigData = []
                  tempPigData.push({
                    bonusFood: pigData[0]?.bonusFood,
                    food: pigData[0]?.food + value,
                    exp: pigData[0]?.exp,
                    happiness: pigData[0]?.happiness,
                    toy: pigData[0]?.toy,
                    mud: pigData[0]?.mud,
                    weeklyFeedingCount: pigData[0]?.weeklyFeedingCount,
                    boostDondon: pigData[0]?.boostDondon,
                    boostCount: pigData[0]?.boostCount,
                  })
                  setPigData(tempPigData)
                  triggerFoodToast(value)
                },
                count: missionConfig?.onboardingMission?.count || 0,
              })
            } else {
              setModalConfig({
                ...modalConfig,
                visible: true,
                modalType: 'CheckInPigModal',
                contents: 'ConsecutiveCheckInDone',
                setModalConfig,
              })
            }
            buttonPressed = false
          }}
        >
          <ConsecutiveBonusIcon className='w-32' />
          {!missionConfig?.onboardingMission?.isDisable && (
            <ExclamationMarkIcon className='absolute -top-1 right-1 w-7' />
          )}
        </Button>
      </div>
    )
  }

  const LuckyScratch = () => {
    return (
      <div
        className='absolute w-20 h-20 jua'
        style={{ right: '4%', top: 80, zIndex: 1 }}
      >
        <Button
          appearance=' '
          className='flex flex-col items-center justify-start '
          onClick={async () => {
            if (buttonPressed) return
            buttonPressed = true
            setModalConfig({
              ...modalConfig,
              visible: true,
              modalType: 'CheckInPigModal',
              contents: 'LuckyScratchButton',
              scratchReward: missionConfig?.luckyScratch?.scratchReward,
            })
            buttonPressed = false
          }}
        >
          <BlackTextTimer
            timeStamp={new Date(missionConfig?.luckyScratch?.rewardedAt)}
            timerMinutes={60 * 3}
            fontSize={12}
            marginBottom={-4}
            color='#FFF576'
            onTimeEnd={() => {
              fetchData()
            }}
          />
          <LuckyScratchButton className='w-36' />
        </Button>
      </div>
    )
  }
  const PigSkinIndicator = () => {
    return (
      <div
        className='absolute h-16 animate-pulse w-18 jua'
        style={{ right: '24%', top: 100, zIndex: 1 }}
      >
        <Button
          appearance=' '
          className='flex flex-col items-center justify-start '
          onClick={async () => {
            if (buttonPressed) return
            buttonPressed = true
            setModalConfig({
              ...modalConfig,
              visible: true,
              modalType: 'CheckInPigModal',
              contents: 'PinkPig',
              leftTime: moment(pigCustomizationData[0]?.expiredAt).format(
                'M월D일 HH:mm까지',
              ),
            })
            buttonPressed = false
          }}
        >
          <ModalPinkPig className='w-16' />
          <BlackTextTimer
            timeStamp={new Date(pigCustomizationData[0]?.expiredAt)}
            timerMinutes={0}
            fontSize={12}
            marginTop={-8}
            color='#FFE8E8'
            onTimeEnd={() => {
              fetchCustomizationData()
            }}
          />
        </Button>
      </div>
    )
  }

  const DailyRoulette = () => {
    return (
      <div
        className='absolute w-20 h-20'
        style={{ left: '2%', top: 184, zIndex: 1 }}
      >
        <Button
          appearance=''
          className='relative flex flex-col items-center justify-start '
          onClick={async () => {
            if (buttonPressed) return
            buttonPressed = true
            setModalConfig({
              ...modalConfig,
              visible: true,
              modalType: 'CheckInPigDailyRoulette',
              fetchCustomizationData: () => {
                fetchCustomizationData()
                fetchData()
              },
            })
            buttonPressed = false
          }}
        >
          <DailyRouletteButton className='w-36' />
          {!missionConfig?.dailyTicket?.isDisable && (
            <ExclamationMarkIcon className='absolute -top-1 right-1 w-7' />
          )}
        </Button>
      </div>
    )
  }

  const HappinessIndicator = () => {
    return (
      <div
        className='absolute flex items-center justify-center w-16 h-20 jua'
        style={{ left: '7%', bottom: 240, zIndex: 1 }}
      >
        <Button
          appearance=' '
          className='flex flex-col items-center justify-center '
          onClick={async () => {
            if (buttonPressed) return
            buttonPressed = true
            setModalConfig({
              ...modalConfig,
              visible: true,
              modalType: 'CheckInPigModal',
              contents: 'HappinessInfo',
              setModalConfig,
              pigData: pigData[0],
              pigLevelData,
            })
            buttonPressed = false
          }}
        >
          {pigData[0]?.happiness > 30 && <Heart className='w-20' />}
          {pigData[0]?.happiness <= 30 && <HeartFaded className='w-20' />}
          <div
            className='absolute text-lg text-white drop-shadow'
            style={{
              textShadow:
                '-2px 0 #EA2662, 0 2px #EA2662, 2px 0 #EA2662, 0 -2px #EA2662',
              top: '32%',
            }}
          >
            {pigData[0]?.happiness >= 0
              ? commaNumber(pigData[0]?.happiness)
              : 0}
          </div>
        </Button>
      </div>
    )
  }
  const FeedButton = ({ pigData, pigLevelData }) => {
    return (
      <div className='flex flex-col items-center justify-center'>
        {pigData[0]?.food >= 1000 &&
          (pigData[0]?.boostCount === 0 || pigData[0]?.boostCount >= 5) && (
            <MultipleFeedButton
              missionConfig={missionConfig}
              pigData={pigData}
              pigLevelData={pigLevelData}
            />
          )}

        <Button
          disabled={shake || animatingOn}
          appearance=' '
          className=''
          onClick={async () => {
            feedPig(pigData, pigLevelData, 1)
          }}
        >
          <div className='flex flex-col items-center justify-center h-full pt-1'>
            <PigFeedButton className='w-24 h-full ' />
            <div
              className='absolute text-xl text-center text-white drop-shadow'
              style={{
                textShadow:
                  '-2px 0 #255C3C, 0 2px #255C3C, 2px 0 #255C3C, 0 -2px #255C3C',
                bottom: 50,
              }}
            >
              {commaNumber(pigData[0]?.food) || 0}g
            </div>
          </div>
        </Button>
      </div>
    )
  }
  const MultipleFeedButton = ({ pigData, pigLevelData }) => {
    return (
      <div className='relative' style={{}}>
        <Button
          disabled={shake || animatingOn}
          appearance=' '
          className='animate-zoom-slow px-4 py-1 bg-gradient-to-b from-[#F84834] to-[#F5655D] rounded-2xl'
          onClick={async () => {
            feedPig(pigData, pigLevelData, 5)
          }}
        >
          <div className='overflow-hidden text-xs text-white-600 text-clip'>
            5번 밥주기
          </div>
        </Button>
      </div>
    )
  }

  const MissionButton = ({ ...params }) => {
    return (
      <button
        type='button'
        disabled={shake || animatingOn}
        className='relative mr-1'
        onClick={() => {
          if (buttonPressed) return
          buttonPressed = true
          setOpenFoodBottomSheet(true)
          buttonPressed = false
        }}
      >
        {/* {Object.values(params?.missionConfig)?.filter(
            (el) =>
              el?.isDisable === false &&
              el?.isShow === true &&
              el?.isCounted !== false,
          )?.length > 0 && (
            <div
              style={{
                borderWidth: 2,
                borderRadius: 99,
                height: 30,
                width: 30,
                position: 'absolute',
                left: '7%',
                top: '-10%',
                backgroundColor: '#E93E47',
                borderColor: 'white',
              }}
              className={
                'flex items-center justify-center text-white font-bold'
              }
            >
              {
                Object.values(params?.missionConfig)?.filter(
                  (el) =>
                    el?.isDisable === false &&
                    el?.isShow === true &&
                    el?.isCounted !== false,
                )?.length
              }
            </div>
          )} */}

        <CheckInPigMissionButton className='w-20 h-full' />
        {!missionConfig?.checkInMission?.isDisable && (
          <ExclamationMarkIcon className='absolute right-0 -top-2 w-7' />
        )}
      </button>
    )
  }
  const HappinessMissionButton = () => {
    return (
      <button
        type='button'
        disabled={shake || animatingOn}
        className='relative mr-1'
        onClick={async () => {
          if (buttonPressed) return
          buttonPressed = true
          setOpenHappinessBottomSheet(true)
          buttonPressed = false
        }}
      >
        <HappinessMission className='w-20 h-full' />
        {!missionConfig?.petOnceMission?.isDisable && (
          <ExclamationMarkIcon className='absolute right-0 -top-2 w-7' />
        )}
      </button>
    )
  }

  const BoostedPigMissionButton = () => {
    return (
      <button
        type='button'
        disabled={shake || animatingOn}
        className='relative'
        onClick={async () => {
          if (buttonPressed) return
          buttonPressed = true
          setOpenBoostedPigBottomSheet(true)
          buttonPressed = false
        }}
      >
        <CheckInPigBoostMissionButton className='w-20 h-full' />
        {!missionConfig?.boostTimeCheckIn?.isDisable && (
          <ExclamationMarkIcon className='absolute right-0 -top-2 w-7' />
        )}
      </button>
    )
  }

  const MainComponent = ({
    pigData,
    pigLevelData,
    animatingOn,
    speechBubbleText,
  }) => {
    return (
      <div className='z-0 flex items-end' style={{ height: '100vh' }}>
        <PigBackground className='absolute top-0 z-0' />
        <div className='flex items-end w-full'>
          {pigData[0]?.happiness >= 0 && <HappinessIndicator />}
          <div
            className='absolute flex flex-col items-center'
            style={
              pigData[0]?.food > 1000
                ? { left: '18%', bottom: 180 }
                : { left: '18%', bottom: 160 }
            }
          >
            {speechBubbleText && <SpeechBubble contents={speechBubbleText} />}
            {pigData[0]?.boostCount > 0 && !transform && (
              <div className={shake ? 'animate-shake' : ''}>
                <GaugeBar
                  currentExp={pigData[0]?.boostCount}
                  totalExp={15}
                  boost
                />
              </div>
            )}
            <button
              type='button'
              onClick={() => {
                setMissionConfig({
                  ...missionConfig,
                  petOnceMission: {
                    ...missionConfig?.petOnceMission,
                    count: (missionConfig?.petOnceMission?.count || 0) + 1,
                  },
                })
                setShake(true)
                changeSpeechBubble('tickle')
                setTimeout(() => {
                  backendApis.logCheckInPigPet()
                  setShake(false)
                }, 1000)
              }}
            >
              <PigComponent />
            </button>
            {pigLevelData?.totalExp && (
              <button
                type='button'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  width: '100%',
                }}
                onClick={() => {
                  setModalConfig({
                    ...modalConfig,
                    visible: true,
                    modalType: 'CheckInPigModal',
                    contents: 'Introduction',
                  })
                }}
              >
                <GaugeBar
                  currentExp={pigData[0]?.exp}
                  totalExp={pigLevelData?.totalExp[currentExpLevel]}
                />
                <GaugeBarPercentage
                  currentExp={pigData[0]?.exp || 0}
                  totalExp={pigLevelData?.totalExp[currentExpLevel]}
                />
              </button>
            )}
          </div>

          <div className='absolute flex flex-col justify-center w-full pb-6 mt-2 jua'>
            <div className='flex flex-row items-end justify-between pb-2 mx-4'>
              <div className='flex flex-row mr-4'>
                <MissionButton missionConfig={missionConfig} />
                {pigData[0]?.happiness >= 0 && <HappinessMissionButton />}
                <BoostedPigMissionButton />
              </div>
              <FeedButton
                missionConfig={missionConfig}
                pigData={pigData}
                pigLevelData={pigLevelData}
              />
            </div>
            <div className='flex flex-row justify-center'>
              <div
                className='mt-1 mr-1 text-xs text-center'
                style={{ color: 'rgba(194, 233, 177, 1)' }}
              >
                밥주기 TIP |
              </div>
              <div className='mt-1 text-xs text-center text-white '>
                탭하면 100g이 소모돼요
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const PigComponent = () => {
    if (transform) {
      return <Poof className='fade-in-out w-52 h-52' />
    }
    if (pigData[0]?.boostCount > 0) {
      return (
        <>
          {!animatingOn ? (
            <SuperPig
              className={
                shake
                  ? `animate-shake w-40 h-40`
                  : `animate-float-slowly w-40 h-40`
              }
            />
          ) : (
            <EatingSuperPig className='w-40 h-40' />
          )}
        </>
      )
    }
    if (pigCustomizationData?.length > 0) {
      return (
        <>
          {!animatingOn ? (
            <PinkPig
              className={shake ? `animate-shake w-40 h-40` : `w-40 h-40`}
            />
          ) : (
            <EatingPigPink className='w-40 h-40' />
          )}
        </>
      )
    }
    return (
      <>
        {!animatingOn ? (
          <CheckInPigMain
            className={shake ? `animate-shake w-40 h-40` : `w-40 h-40`}
          />
        ) : (
          <EatingPig className='w-40 h-40' />
        )}
      </>
    )
  }

  return (
    <div
      style={
        modalConfig?.visible
          ? {
              position: 'fixed',
              overflow: 'hidden',
              width: '100%',
              height: '100vh',
              backgroundColor: '#FFF9D8',
            }
          : {
              // position: 'fixed',
              overflow: 'hidden',
              width: '100%',
              height: '100vh',
              // borderWidth: 4,
              // borderColor: 'black',

              backgroundColor: '#FFF9D8',
            }
      }
    >
      <HeaderComponent />
      <BonusFoodComponent pigData={pigData} pigLevelData={pigLevelData} />
      {missionConfig.onboardingMission.isShow && (
        <ConsecutiveCheckIn pigData={pigData} pigLevelData={pigLevelData} />
      )}
      {missionConfig?.luckyScratch?.isDisable &&
        missionConfig?.luckyScratch?.scratchReward &&
        missionConfig?.luckyScratch?.isAvailable && <LuckyScratch />}
      {pigCustomizationData?.length > 0 && <PigSkinIndicator />}
      <DailyRoulette />
      <MainComponent
        pigData={pigData}
        pigLevelData={pigLevelData}
        animatingOn={animatingOn}
        speechBubbleText={speechBubbleText}
      />

      {isLoading && <LoadingIndicator />}
      {loadingPage && (
        <div className='flex flex-col loadingPage jua'>
          <div className={`${play ? 'rotateCenter' : ''} pigNose`}>🐽</div>
          <div>돈돈이 만나러 가는 중...</div>
        </div>
      )}
      <BottomSheet
        open={openFoodBottomSheet}
        onDismiss={() => {
          onDismiss()
        }}
        expandOnContentDrag
      >
        <div>
          <div className='flex items-center justify-between h-16 pl-4 pr-9'>
            <div className='w-12' />
            <div className='flex items-center justify-center h-16 pl-1'>
              <PigFoodColored className='w-10 mr-2' />
              <h3 className='relative px-1 text-2xl jua z-1'>
                사료 상점
                <span
                  className='absolute inset-x-0 h-1'
                  style={{
                    bottom: 3,
                    borderBottom: '8px solid rgba(255, 223, 221, 0.9)',
                    zIndex: '-1',
                  }}
                />
              </h3>

              <div className='w-6' />
            </div>
            <div className='w-12' />
            <button
              type='button'
              className='absolute flex items-center justify-center w-10 h-10 rounded-full'
              style={{ right: 20, top: 20 }}
              onClick={onDismiss}
            >
              <CloseRounded className='' />
            </button>
          </div>

          <div className='px-4 pb-4'>
            <DailyCheckInScreenBottomSheetItem
              leftImage='PigMissionCheckIn'
              title='출석하기'
              reward='200g'
              content='오늘의 사료를 드려요'
              moveTo={async () => {
                if (buttonPressed) return
                buttonPressed = true
                setOpenFoodBottomSheet(false)
                gainedFood('checkInMission', 200, 'food', missionConfig)
                setMissionConfig({
                  ...missionConfig,
                  checkInMission: {
                    ...missionConfig?.checkInMission,
                    isDisable: true,
                  },
                })
                buttonPressed = false
              }}
              disabled={missionConfig?.checkInMission?.isDisable}
            />
            {codePushVersionAvailable(userCodePushVersion, '4.4.19') && (
              <DailyCheckInScreenBottomSheetItem
                leftImage='PigMissionTimer'
                title='구경하기'
                reward='200g'
                content='사료 상점을 40초동안 둘러보세요'
                disabled={missionConfig?.browsingMission?.isDisable}
                moveTo={() => {
                  setOpenFoodBottomSheet(false)
                }}
                button={
                  !missionConfig?.browsingMission?.isDisable && (
                    <button
                      type='button'
                      className='justify-center w-20 h-10 px-2 text-base text-white bg-red-500 rounded-lg'
                      onClick={async () => {
                        setOpenFoodBottomSheet(false)

                        window.location.href = `#navigate.${JSON.stringify({
                          screen: 'DailyCheckInCompatibleBrowsingMission',
                          prop: {
                            missionType: 'browsingMission',
                            missionName: '사료상점 구경하기',
                            rewardType: 'food',
                            rewardAmount: 200,
                            defaultLeftTime: 40,
                            defaultCategory: 'total',
                            showEnvelopeList: true,
                            showBrandDeal: true,
                            bannerUri: '',
                          },
                        })}`
                      }}
                      disabled={missionConfig?.browsingMission?.isDisable}
                    >
                      구경하기
                    </button>
                  )
                }
              />
            )}

            <DailyCheckInScreenBottomSheetItem
              leftImage='PigHappinessMissionShop'
              title='상품 구매하기'
              reward='10kg~'
              content='상점에서 상품을 구매해보세요'
              footerText='구매 시 사료 최소 10,000g (1일 1회)'
              disabled={missionConfig?.purchaseMission?.isDisable}
              moveTo={() => {
                setOpenFoodBottomSheet(false)
              }}
              button={
                !missionConfig?.purchaseMission?.isDisable && (
                  <button
                    type='button'
                    className='justify-center w-20 h-10 px-2 text-base text-white bg-red-500 rounded-lg'
                    onClick={async () => {
                      setOpenFoodBottomSheet(false)

                      window.location.href = `#navigate.${JSON.stringify({
                        screen: 'DailyCheckInCompatiblePurchaseMission',
                        prop: {
                          missionType: 'purchaseMission',
                          missionName: '사료상점에서 구매하기',
                          defaultCategory: 'total',
                          bannerUri:
                            'https://assets.ilevit.com/b6eddcbf-8c24-4cb7-bb62-c593d7ff10ae.png',
                        },
                      })}`
                    }}
                    disabled={missionConfig?.purchaseMission?.isDisable}
                  >
                    구경하기
                  </button>
                )
              }
            />

            <DailyCheckInScreenBottomSheetItem
              leftImage='PigMissionWakeUp'
              title='돈돈이 깨우기'
              reward='150g'
              // impressTextInContent={'🌞'}
              content=' 오전에만 깨울 수 있어요'
              moveTo={async () => {
                if (buttonPressed) return
                buttonPressed = true
                setOpenFoodBottomSheet(false)

                gainedFood('wakeUpMission', 150, 'food', missionConfig)
                setMissionConfig({
                  ...missionConfig,
                  wakeUpMission: {
                    ...missionConfig?.wakeUpMission,
                    isDisable: true,
                  },
                })
                setShake(true)
                setTimeout(() => {
                  setShake(false)
                }, 1500)
                buttonPressed = false
              }}
              disabled={missionConfig?.wakeUpMission?.isDisable}
            />

            <DailyCheckInScreenBottomSheetItem
              leftImage='PigMissionPlay'
              title='돈돈이 놀아주기'
              reward='150g'
              // impressTextInContent={'🌙'}
              content=' 오후에만 놀아줄 수 있어요'
              moveTo={async () => {
                if (buttonPressed) return
                buttonPressed = true
                setOpenFoodBottomSheet(false)

                gainedFood('playMission', 150, 'food', missionConfig)
                setMissionConfig({
                  ...missionConfig,
                  playMission: {
                    ...missionConfig?.playMission,
                    isDisable: true,
                  },
                })
                setShake(true)
                setTimeout(() => {
                  setShake(false)
                }, 1500)
                buttonPressed = false
              }}
              disabled={missionConfig?.playMission?.isDisable}
            />

            {missionConfig.onboardingMission.isShow && (
              <DailyCheckInScreenBottomSheetItem
                leftImage='PigMissionConsecutiveCheckIn'
                title='연속 출석하기'
                // reward={''}
                content='출석할수록 커지는 보상!'
                moveTo={async () => {
                  if (buttonPressed) return
                  buttonPressed = true
                  setOpenFoodBottomSheet(false)
                  setModalConfig({
                    ...modalConfig,
                    visible: true,
                    modalType: 'CheckInPigModal',
                    contents: 'ConsecutiveCheckIn',
                    setModalConfig,
                    triggerFoodToast: (value) => {
                      setOpenFoodBottomSheet(false)
                      setMissionConfig({
                        ...missionConfig,
                        onboardingMission: {
                          ...missionConfig?.onboardingMission,
                          isDisable: true,
                        },
                      })

                      const tempPigData = []
                      tempPigData.push({
                        bonusFood: pigData[0]?.bonusFood,
                        food: pigData[0]?.food + value,
                        exp: pigData[0]?.exp,
                        happiness: pigData[0]?.happiness,
                        toy: pigData[0]?.toy,
                        mud: pigData[0]?.mud,
                        weeklyFeedingCount: pigData[0]?.weeklyFeedingCount,
                      })
                      setPigData(tempPigData)
                      triggerFoodToast(value)
                    },
                    count: missionConfig?.onboardingMission?.count || 0,
                  })

                  buttonPressed = false
                }}
                disabled={missionConfig?.onboardingMission?.isDisable}
              />
            )}
          </div>
        </div>
      </BottomSheet>
      <BottomSheet
        open={openHappinessBottomSheet}
        onDismiss={() => {
          onDismiss()
        }}
        expandOnContentDrag
      >
        <div>
          <div className='flex items-center justify-between h-16 pl-4 pr-9'>
            <div className='w-12' />
            <div className='flex items-center justify-center h-16 pl-2 pr-3'>
              <PigHeartBordered className='w-16' />
              <h3 className='relative px-1 text-2xl jua z-1'>
                행복 상점
                <span
                  className='absolute inset-x-0 h-1'
                  style={{
                    bottom: 3,
                    borderBottom: '8px solid rgba(255, 223, 221, 0.9)',
                    zIndex: '-1',
                  }}
                />
              </h3>

              <div className='w-6' />
            </div>
            <div className='w-12' />
            <button
              type='button'
              className='absolute flex items-center justify-center w-10 h-10 rounded-full'
              style={{ right: 20, top: 20 }}
              onClick={onDismiss}
            >
              <CloseRounded className='' />
            </button>
          </div>

          <div className='flex flex-row px-2 pt-2 justify-evenly jua'>
            {/* <div className='flex flex-col items-center w-1/2 px-2 py-1 mx-1 bg-gray-100 border border-gray-200 justify-evenly rounded-3xl'>
                <div className='flex flex-row items-center justify-center py-2'>
                  <HappinessMud className='w-9' />
                  <div className='text-[#796950] px-2'>진흙</div>
                  <div className='text-xs text-red-500'>922,058g</div>
                </div>
                <div className='w-full py-2 mb-1 text-sm text-center text-white bg-red-500 rounded-full'>
                  진흙 목욕하기 (-100g)
                </div>
              </div> */}

            <div className='flex flex-col items-center w-4/5 px-2 py-1 mx-1 bg-gray-100 border border-gray-200 justify-evenly rounded-3xl'>
              <div className='relative flex flex-row items-center justify-center py-2'>
                <div
                  className='absolute px-3 py-1 rounded-full bg-[#FF3469] text-white text-sm'
                  style={{ top: -18, left: -100 }}
                >
                  행복도 +100
                </div>
                <HappinessToy className='w-9' />
                <div className='text-[#796950] px-2'>장난감</div>
                <div className='text-[#FF3469] text-sm'>
                  {pigData[0]?.toy || 0}개
                </div>
              </div>

              <button
                type='button'
                className={
                  pigData[0]?.toy > 0
                    ? 'flex flex-row items-center justify-center w-full text-center py-2 mb-1 bg-[#FF3469] rounded-full text-white text-sm'
                    : 'flex flex-row items-center justify-center w-full text-center py-2 mb-1 bg-gray-300 rounded-full text-white text-sm'
                }
                onClick={() => {
                  if (buttonPressed) {
                    return
                  }
                  buttonPressed = true
                  usePigItem('toy', 1)
                  setShake(true)
                  setTimeout(() => {
                    setShake(false)
                  }, 1000)
                  buttonPressed = false
                }}
                // disabled={pigData[0]?.toy === 0}
              >
                놀아주기 <div className='pl-1 text-xs'> (장난감 1개 사용)</div>
              </button>
            </div>
          </div>

          <div className='px-4 pt-2 pb-4'>
            <DailyCheckInScreenBottomSheetItem
              leftImage='PigHappinessMissionPet'
              title='1번 쓰다듬기'
              subTitle={
                missionConfig?.petOnceMission?.isDisable
                  ? ''
                  : `(${
                      missionConfig.petOnceMission.count > 1
                        ? 1
                        : missionConfig.petOnceMission.count
                    }/1)`
              }
              reward='10'
              rewardType='happiness'
              content='돈돈이를 눌러 쓰다듬어주세요'
              moveTo={async () => {
                if (buttonPressed) return
                buttonPressed = true
                setOpenHappinessBottomSheet(false)
                setMissionConfig({
                  ...missionConfig,
                  petOnceMission: {
                    ...missionConfig?.petOnceMission,
                    isDisable: true,
                  },
                })
                buttonPressed = false
              }}
              disabled={
                missionConfig?.petOnceMission?.count < 1 ||
                missionConfig?.petOnceMission?.isDisable
              }
              button={
                !missionConfig?.petOnceMission?.isDisable && (
                  <button
                    type='button'
                    className='w-20 h-10 bg-[#FF3469] px-2 rounded-lg justify-center text-white text-base'
                    onClick={async () => {
                      if (missionConfig?.petOnceMission?.count < 1) {
                        setOpenHappinessBottomSheet(false)
                        triggerPetToast()
                      } else if (missionConfig?.petOnceMission?.count >= 1) {
                        gainedHappiness(
                          'petOnceMission',
                          10,
                          'happiness',
                          missionConfig,
                        )
                      }
                    }}
                    disabled={missionConfig?.petOnceMission?.isDisable}
                  >
                    {missionConfig?.petOnceMission?.count >= 1
                      ? '보상받기'
                      : '쓰다듬기'}
                  </button>
                )
              }
            />

            {codePushVersionAvailable(userCodePushVersion, '2.2.85') && (
              <DailyCheckInScreenBottomSheetItem
                leftImage='PigHappinessMissionShop'
                title='상품 구매하기'
                reward='7개'
                rewardType='toy'
                content='상점에서 상품을 구매해보세요'
                footerText='구매 시 장난감 7개 (1일 1회)'
                disabled={missionConfig?.toyStorePurchaseMission?.isDisable}
                moveTo={() => {
                  setOpenHappinessBottomSheet(false)
                }}
                button={
                  !missionConfig?.toyStorePurchaseMission?.isDisable && (
                    <button
                      type='button'
                      className='w-20 h-10 bg-[#FF3469] px-2 rounded-lg justify-center text-white text-base'
                      onClick={async () => {
                        setOpenHappinessBottomSheet(false)

                        window.location.href = `#navigate.${JSON.stringify({
                          screen: 'DailyCheckInCompatiblePurchaseMission',
                          prop: {
                            missionType: 'toyStorePurchaseMission',
                            missionName: '장난감 상점에서 구매하기',
                            defaultCategory: 'total',
                            bannerUri:
                              'https://assets.ilevit.com/7a421525-de4d-4947-b47b-0795408e8be4.png',
                          },
                        })}`
                      }}
                      disabled={
                        missionConfig?.toyStorePurchaseMission?.isDisable
                      }
                    >
                      구매하기
                    </button>
                  )
                }
              />
            )}
            {codePushVersionAvailable(userCodePushVersion, '4.4.19') && (
              <DailyCheckInScreenBottomSheetItem
                leftImage='PigHappinessMissionTimer'
                title='행복상점 구경하기'
                reward='50'
                rewardType='happiness'
                content='행복 상점을 10초동안 둘러보세요'
                disabled={missionConfig?.mudBrowsingMission?.isDisable}
                moveTo={() => {
                  setOpenFoodBottomSheet(false)
                }}
                button={
                  !missionConfig?.mudBrowsingMission?.isDisable && (
                    <button
                      type='button'
                      className='w-20 h-10 bg-[#FF3469] px-2 rounded-lg justify-center text-white text-base'
                      onClick={async () => {
                        setOpenFoodBottomSheet(false)
                        window.location.href = `#navigate.${JSON.stringify({
                          screen: 'DailyCheckInCompatibleBrowsingMission',
                          prop: {
                            missionType: 'mudBrowsingMission',
                            missionName: '행복상점 구경하기',
                            rewardType: 'happiness',
                            rewardTypeKor: '행복',
                            unit: '',
                            rewardAmount: 50,
                            defaultLeftTime: 10,
                            defaultCategory: 'total',
                            showEnvelopeList: false,
                            showBrandDeal: true,
                            bannerUri: '',
                          },
                        })}`
                      }}
                      disabled={missionConfig?.mudBrowsingMission?.isDisable}
                    >
                      구경하기
                    </button>
                  )
                }
              />
            )}
          </div>
        </div>
      </BottomSheet>
      <BottomSheet
        open={openBoostedPigBottomSheet}
        onDismiss={() => {
          onDismiss()
        }}
        expandOnContentDrag
      >
        <div className='jua'>
          <div className='flex items-center justify-between h-16 pl-4 pr-9'>
            <div className='w-12' />
            <div className='flex items-center justify-center h-16 pl-1'>
              <ModalPigOnly className='w-10 mr-2' />
              <h3 className='relative px-1 text-2xl jua z-1'>
                슈퍼돈돈
                <span
                  className='absolute inset-x-0 h-1 ml-1'
                  style={{
                    bottom: 2,
                    borderBottom: '8px solid rgba(255, 223, 112, 1)',
                    zIndex: '-1',
                  }}
                />
              </h3>

              <div className='w-6' />
            </div>
            <div className='w-12' />
            <button
              type='button'
              className='absolute flex items-center justify-center w-10 h-10 rounded-full'
              style={{ right: 20, top: 20 }}
              onClick={onDismiss}
            >
              <CloseRounded className='' />
            </button>
          </div>
          <div className='text-[#796950] text-xs text-center'>
            * 진화한 돈돈이는 지속시간이 정해져 있어요
          </div>

          <div className='flex flex-row px-2 pt-2 justify-evenly'>
            <div className='relative flex flex-row items-center w-4/5 px-2 py-1 mx-1 bg-gray-100 border border-gray-200 justify-evenly rounded-xl'>
              <SuperPig className='mt-3 w-28' />
              <div className='absolute top-0 left-0 px-2 py-1 bg-[#FFB545] rounded-tl-xl rounded-br-xl text-white text-xs'>
                보유: {pigData[0]?.boostDondon || 0}
              </div>
              <div className='relative flex flex-col items-center justify-center w-full py-2 ml-1'>
                <div className='text-[#796950] px-2'>슈퍼돈돈이</div>
                <div className='text-xs text-red-500'>경험치 2배</div>
                <button
                  type='button'
                  className={
                    pigData[0]?.boostDondon > 0
                      ? 'flex flex-row items-center justify-center text-center mt-2 py-2 bg-[#FFB545] rounded-full text-white text-sm'
                      : 'flex flex-row items-center justify-center text-center mt-2 py-2 bg-gray-300 rounded-full text-white text-sm'
                  }
                  style={{ width: '90%' }}
                  disabled={
                    pigData[0]?.boostDondon <= 0 || !pigData[0]?.boostDondon
                  }
                  onClick={async () => {
                    if (buttonPressed) {
                      return
                    }
                    buttonPressed = true
                    usePigItem('boostDondon', 1)
                    setOpenBoostedPigBottomSheet(false)
                    await sleep(500)
                    setTransform(true)
                    setTimeout(() => {
                      setTransform(false)
                      const tempPigData = []
                      tempPigData.push({
                        bonusFood: pigData[0]?.bonusFood,
                        food: pigData[0]?.food,
                        exp: pigData[0]?.exp,
                        happiness: pigData[0]?.happiness,
                        toy: pigData[0]?.toy,
                        mud: pigData[0]?.mud,
                        weeklyFeedingCount: pigData[0]?.weeklyFeedingCount,
                        boostDondon: pigData[0]?.boostDondon - 1,
                        boostCount: 15,
                      })
                      setPigData(tempPigData)
                    }, 800)
                    buttonPressed = false
                  }}
                >
                  진화하기
                </button>
              </div>
            </div>
            <div className='relative flex flex-row items-center w-4/5 px-2 py-1 mx-1 bg-gray-100 border border-gray-200 justify-evenly rounded-xl'>
              <MysterySuperduperPig className='mt-3 w-28' />
              <div className='absolute top-0 left-0 px-2 py-1 text-xs text-white bg-gray-400 rounded-tl-xl rounded-br-xl'>
                보유: 0
              </div>
              <div className='relative flex flex-col items-center justify-center w-full py-2'>
                <div className='text-[#796950] px-2'>?? 돈돈이</div>
                <div className='text-xs text-red-500'>경험치 ??배</div>
                <button
                  type='button'
                  className='flex flex-row items-center justify-center py-2 mt-2 text-sm text-center text-white bg-gray-300 rounded-full'
                  style={{ width: '90%' }}
                  disabled
                  onClick={() => {}}
                >
                  잠겼어요
                </button>
              </div>
            </div>
          </div>

          <div className='px-4 py-4 pt-2'>
            <DailyCheckInScreenBottomSheetItem
              leftImage='PigBoostMissionStamp'
              title='접속도장 찍기'
              reward='x2'
              rewardType='boostDondon'
              subTitle={`(${missionConfig?.boostTimeCheckIn.count}/3)`}
              content='오늘의 접속도장을 3번 찍어요'
              timer={
                missionConfig?.boostTimeCheckIn?.lastLoggedAt &&
                missionConfig?.boostTimeCheckIn?.count < 3 ? (
                  <BlackTextTimer
                    timeStamp={
                      new Date(missionConfig?.boostTimeCheckIn?.lastLoggedAt)
                    }
                    timerMinutes={30}
                    noHours
                    fontSize={12}
                    marginBottom={-4}
                    onTimeEnd={() => {
                      fetchData()
                    }}
                  />
                ) : (
                  ''
                )
              }
              stampComponent={
                <div className='flex flex-row items-center mt-2'>
                  <div className='flex bg-[#FFCF72] border-2 border-[#FFDF70] rounded-full w-5 h-5 items-center justify-center'>
                    {missionConfig?.boostTimeCheckIn?.count > 0 && (
                      <Check className='w-4 h-4' />
                    )}
                  </div>
                  <div className='bg-[#FFCF72] w-10 h-1' />
                  <div className='flex bg-[#FFCF72] border-2 border-[#FFDF70] rounded-full w-5 h-5 items-center justify-center'>
                    {missionConfig?.boostTimeCheckIn?.count > 1 && (
                      <Check className='w-4 h-4' />
                    )}
                  </div>
                  <div className='bg-[#FFCF72] w-10 h-1' />
                  <div className='flex bg-[#FFCF72] border-2 border-[#FFDF70] rounded-full w-5 h-5 items-center justify-center'>
                    {missionConfig?.boostTimeCheckIn?.count > 2 && (
                      <Check className='w-4 h-4' />
                    )}
                  </div>
                </div>
              }
              moveTo={async () => {
                if (buttonPressed) return
                buttonPressed = true
                setOpenHappinessBottomSheet(false)
                buttonPressed = false
              }}
              disabled={
                missionConfig?.boostTimeCheckIn?.count > 2 ||
                missionConfig?.boostTimeCheckIn?.isDisable
              }
              button={
                !missionConfig?.boostTimeCheckIn?.isDisable && (
                  <button
                    type='button'
                    className='w-20 h-10 bg-[#FFB545] px-2 rounded-lg justify-center text-white text-base'
                    onClick={async () => {
                      if (buttonPressed) return
                      buttonPressed = true

                      if (missionConfig?.boostTimeCheckIn?.count < 3) {
                        await backendApis.setBoostTimeCheckInMission(
                          missionConfig?.boostTimeCheckIn?.count,
                        )
                        if (missionConfig?.boostTimeCheckIn?.count >= 2) {
                          setOpenBoostedPigBottomSheet(false)
                          triggerBoostToast()
                          const tempPigData = []
                          tempPigData.push({
                            bonusFood: pigData[0]?.bonusFood,
                            food: pigData[0]?.food,
                            exp: pigData[0]?.exp,
                            happiness: pigData[0]?.happiness,
                            toy: pigData[0]?.toy,
                            mud: pigData[0]?.mud,
                            weeklyFeedingCount: pigData[0]?.weeklyFeedingCount,
                            boostDondon: pigData[0]?.boostDondon + 2,
                            boostCount: pigData[0]?.boostCount,
                          })
                          setPigData(tempPigData)
                          fetchData()
                        }
                      }
                      setMissionConfig({
                        ...missionConfig,
                        boostTimeCheckIn: {
                          ...missionConfig?.boostTimeCheckIn,
                          isDisable: true,
                          lastLoggedAt: new Date(),
                          count: missionConfig?.boostTimeCheckIn.count + 1,
                        },
                      })

                      buttonPressed = false
                    }}
                    disabled={missionConfig?.boostTimeCheckIn?.isDisable}
                  >
                    {missionConfig?.boostTimeCheckIn?.count === 2
                      ? '보상받기'
                      : '도장찍기'}
                  </button>
                )
              }
            />
            <DailyCheckInScreenBottomSheetItem
              leftImage='PigBoostMissionFeed'
              title='밥 400번 주기'
              reward='x1'
              rewardType='boostDondon'
              subTitle={`(${
                pigData[0]?.weeklyFeedingCount > 400
                  ? 400
                  : pigData[0]?.weeklyFeedingCount || 0
              }/400)`}
              content='매주 일요일에 초기화돼요'
              moveTo={async () => {
                if (buttonPressed) return
                buttonPressed = true
                buttonPressed = false
              }}
              disabled={
                missionConfig?.boostWeeklyFeed?.isDisable ||
                pigData[0]?.weeklyFeedingCount < 400
              }
              button={
                !missionConfig?.boostWeeklyFeed?.isDisable && (
                  <button
                    type='button'
                    className={`w-20 h-10 ${
                      missionConfig?.boostWeeklyFeed?.isDisable ||
                      pigData[0]?.weeklyFeedingCount < 400
                        ? 'bg-gray-300'
                        : 'bg-[#FFB545]'
                    } px-2 rounded-lg justify-center text-white text-base`}
                    onClick={async () => {
                      if (buttonPressed) return
                      buttonPressed = true

                      if (pigData[0]?.weeklyFeedingCount >= 400) {
                        await backendApis.gainFood(
                          'boostWeeklyFeed',
                          1,
                          'boostDondon',
                        )
                        setOpenBoostedPigBottomSheet(false)
                        triggerBoostToast()
                        const tempPigData = []
                        tempPigData.push({
                          bonusFood: pigData[0]?.bonusFood,
                          food: pigData[0]?.food,
                          exp: pigData[0]?.exp,
                          happiness: pigData[0]?.happiness,
                          toy: pigData[0]?.toy,
                          mud: pigData[0]?.mud,
                          weeklyFeedingCount: pigData[0]?.weeklyFeedingCount,
                          boostDondon: pigData[0]?.boostDondon + 2,
                          boostCount: pigData[0]?.boostCount,
                        })
                        setPigData(tempPigData)
                        fetchData()
                      } else {
                        setOpenBoostedPigBottomSheet(false)
                        feedPig(pigData, pigLevelData, 1)
                      }
                      setMissionConfig({
                        ...missionConfig,
                        boostWeeklyFeed: {
                          ...missionConfig?.boostWeeklyFeed,
                          isDisable: !(
                            pigData[0]?.weeklyFeedingCount + 1 <
                            400
                          ),
                        },
                      })
                      buttonPressed = false
                    }}
                    disabled={
                      missionConfig?.boostWeeklyFeed?.isDisable ||
                      pigData[0]?.weeklyFeedingCount < 400
                    }
                  >
                    {pigData[0]?.weeklyFeedingCount >= 400
                      ? '보상받기'
                      : '진행 중'}
                  </button>
                )
              }
            />
            <DailyCheckInScreenBottomSheetItem
              leftImage='PigBoostMissionPurchase'
              title='상품 구매하기'
              reward='x5'
              rewardType='boostDondon'
              content='상점에서 상품을 구매해보세요'
              disabled={missionConfig?.boostItemPurchase?.isDisable}
              moveTo={() => {
                setOpenHappinessBottomSheet(false)
              }}
              button={
                !missionConfig?.boostItemPurchase?.isDisable && (
                  <button
                    type='button'
                    className='w-20 h-10 bg-[#FFB545] px-2 rounded-lg justify-center text-white text-base'
                    onClick={async () => {
                      setOpenHappinessBottomSheet(false)

                      window.location.href = `#navigate.${JSON.stringify({
                        screen: 'DailyCheckInCompatiblePurchaseMission',
                        prop: {
                          missionType: 'boostItemPurchase',
                          missionName: '슈퍼돈돈이 구매하기',
                          defaultCategory: 'total',
                          bannerUri:
                            'https://assets.ilevit.com/e3056e59-d67a-4e35-b395-8f1160c5328a.png',
                        },
                      })}`
                    }}
                    disabled={missionConfig?.boostItemPurchase?.isDisable}
                  >
                    구매하기
                  </button>
                )
              }
            />
          </div>
        </div>
      </BottomSheet>
      {/* <Toaster
        position='top-center'
        reverseOrder={false}
        gutter={8}
        containerClassName=''
        containerStyle={{
          position: 'absolute',
          top: '15%',
        }}
        toastOptions={{
          // Define default options
          className: '',
          duration: 2000,
          style: {
            background: '#363636',
            color: '#fff',
          },
        }}
      /> */}
      {modalConfig?.visible && (
        <DailyCheckInModalHandler
          setModalConfig={setModalConfig}
          {...modalConfig}
        />
      )}
      <link
        rel='stylesheet'
        href='https://unpkg.com/react-spring-bottom-sheet/dist/style.css'
        crossOrigin='anonymous'
      />
    </div>
  )
})

export default CheckInPigPage
