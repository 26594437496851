import { observer } from 'mobx-react-lite'
import React from 'react'

import CheckInPigDailyBonusFoodModal from '../checkInPig/CheckInPigDailyBonusFoodModal'
// eslint-disable-next-line import/no-cycle
import CheckInPigDailyRouletteModal from '../checkInPig/CheckInPigDailyRouletteModal'
import CheckInPigModal from '../checkInPig/CheckInPigModal'
import CheckInPigRouletteInviteModal from './CheckInPigRouletteInviteModal'
import CheckInPigTutorial from './CheckInPigTutorial'
import DailyCheckInFoodReceiveModal from './DailyCheckInFoodReceiveModal'
import DailyCheckInInviteFriendsNewModal from './DailyCheckInInviteFriendsNewModal'
import DailyCheckInInviteGuideModal from './DailyCheckInInviteGuideModal'
import DailyCheckInInviteNudging from './DailyCheckInInviteNudging'
import DailyCheckInNewUserRewardMultiply from './DailyCheckInNewUserRewardMultiply'
import DailyCheckInPurchaseRewardModal from './DailyCheckInPurchaseRewardModal'
import DailyCheckInQuizModal from './DailyCheckInQuizModal'
// import DailyCheckInOnBoarding from './DailyCheckInOnBoarding'
import DailyCheckInScreenRewardReceiveModal from './DailyCheckInScreenRewardReceiveModal'
import DailyCheckInTutorial from './DailyCheckInTutorial'
import ExchangeStoreNotice from './ExchangeStoreNotice'
import ExpirePointModal from './ExpirePointModal'
import MoneyTreeCloseNoticeModal from './MoneyTreeCloseNoticeModal'
import MoneyTreeExchangeModal from './MoneyTreeExchangeModal'
import MoneyTreeFriendInvite from './MoneyTreeFriendInvite'
import MoneyTreeFriendInviteReceived from './MoneyTreeFriendInviteReceived'
import MoneyTreeFriendInviteShakeNudge from './MoneyTreeFriendInviteShakeNudge'
import MoneyTreeNoticeModal from './MoneyTreeNoticeModal'
import TeamCheckInConfirmModal from './TeamCheckInConfirmModal'
import TeamCheckInCreateTeamModal from './TeamCheckInCreateTeamModal'
import TeamCheckInExplainModal from './TeamCheckInExplainModal'
import TeamCheckInInviteNudgeModal from './TeamCheckInInviteNudgeModal'
import TeamCheckInSearchTeamModal from './TeamCheckInSearchTeamModal'

/* eslint-disable react/jsx-props-no-spreading */

const DailyCheckInModalHandler = observer(
  ({ visible, modalType, setModalConfig, ...modalConfig }) => {
    // const showingQueue =
    // ModalStore.showingQueue.filter(
    //   (el) =>
    //     el?.visibleAt === UserStore.currentScreenName ||
    //     el?.visibleAt === 'All',
    // ).length <= 0

    // if (!showingQueue) return null

    // if (modalType === 'DailyCheckInOnBoarding') {
    //   return (
    //     <DailyCheckInOnBoarding
    //       showModal={visible}
    //       setShowModal={(bool) => {
    //         setModalConfig({ ...modalConfig, visible: bool })
    //       }}
    //       {...modalConfig}
    //     />
    //   )
    // }

    if (modalType === 'DailyCheckInNewUserRewardMultiply') {
      return (
        <DailyCheckInNewUserRewardMultiply
          visible={visible}
          close={(bool) => {
            setModalConfig({ ...modalConfig, visible: bool })
          }}
          {...modalConfig}
        />
      )
    }

    if (modalType === 'DailyCheckInQuizModal') {
      return (
        <DailyCheckInQuizModal
          visible={visible}
          close={(bool) => {
            setModalConfig({ ...modalConfig, visible: bool })
          }}
          {...modalConfig}
        />
      )
    }

    if (modalType === 'DailyCheckInScreenRewardReceiveModal') {
      return (
        <DailyCheckInScreenRewardReceiveModal
          visible={visible}
          close={(bool) => {
            setModalConfig({ ...modalConfig, visible: bool })
          }}
          setModalConfig={setModalConfig}
          {...modalConfig}
        />
      )
    }

    if (modalType === 'DailyCheckInInviteNudging') {
      return (
        <DailyCheckInInviteNudging
          visible={visible}
          close={(bool) => {
            setModalConfig({ ...modalConfig, visible: bool })
          }}
          {...modalConfig}
        />
      )
    }

    if (modalType === 'DailyCheckInInviteGuideModal') {
      return (
        <DailyCheckInInviteGuideModal
          visible={visible}
          close={(bool) => {
            setModalConfig({ ...modalConfig, visible: bool })
          }}
          {...modalConfig}
        />
      )
    }

    if (modalType === 'DailyCheckInInviteFriendsNewModal') {
      return (
        <DailyCheckInInviteFriendsNewModal
          visible={visible}
          close={(bool) => {
            setModalConfig({ ...modalConfig, visible: bool })
          }}
          {...modalConfig}
        />
      )
    }

    if (modalType === 'DailyCheckInTutorial') {
      return (
        <DailyCheckInTutorial
          visible={visible}
          close={(bool) => {
            setModalConfig({ ...modalConfig, visible: bool })
          }}
          {...modalConfig}
        />
      )
    }

    if (modalType === 'DailyCheckInPurchaseReward') {
      return (
        <DailyCheckInPurchaseRewardModal
          visible={visible}
          close={() => {
            setModalConfig({ ...modalConfig, visible: false })
          }}
          {...modalConfig}
        />
      )
    }

    if (modalType === 'DailyCheckInFoodReceiveModal') {
      return (
        <DailyCheckInFoodReceiveModal
          visible={visible}
          close={() => {
            setModalConfig({ ...modalConfig, visible: false })
          }}
          {...modalConfig}
        />
      )
    }

    if (modalType === 'TeamCheckInConfirm') {
      return (
        <TeamCheckInConfirmModal
          visible={visible}
          close={() => {
            setModalConfig({ ...modalConfig, visible: false })
          }}
          {...modalConfig}
        />
      )
    }

    if (modalType === 'TeamCheckInInviteNudge') {
      return (
        <TeamCheckInInviteNudgeModal
          visible={visible}
          close={() => {
            setModalConfig({ ...modalConfig, visible: false })
          }}
          {...modalConfig}
        />
      )
    }

    if (modalType === 'TeamCheckInExplainModal') {
      return (
        <TeamCheckInExplainModal
          visible={visible}
          close={() => {
            setModalConfig({ ...modalConfig, visible: false })
          }}
          {...modalConfig}
        />
      )
    }

    if (modalType === 'ExchangeStoreNotice') {
      return (
        <ExchangeStoreNotice
          visible={visible}
          close={() => {
            setModalConfig({ ...modalConfig, visible: false })
          }}
          {...modalConfig}
        />
      )
    }

    if (modalType === 'TeamCheckInCreateTeamModal') {
      return (
        <TeamCheckInCreateTeamModal
          visible={visible}
          close={() => {
            setModalConfig({ ...modalConfig, visible: false })
          }}
          {...modalConfig}
        />
      )
    }

    if (modalType === 'TeamCheckInSearchTeamModal') {
      return (
        <TeamCheckInSearchTeamModal
          visible={visible}
          close={() => {
            setModalConfig({ ...modalConfig, visible: false })
          }}
          {...modalConfig}
        />
      )
    }

    if (modalType === 'ExpirePointModal') {
      return (
        <ExpirePointModal
          visible={visible}
          close={() => {
            setModalConfig({ ...modalConfig, visible: false })
          }}
          {...modalConfig}
        />
      )
    }

    if (modalType === 'MoneyTreeNotice') {
      return (
        <MoneyTreeNoticeModal
          visible={visible}
          close={() => {
            setModalConfig({ ...modalConfig, visible: false })
          }}
          {...modalConfig}
        />
      )
    }

    if (modalType === 'MoneyTreeCloseNotice') {
      return (
        <MoneyTreeCloseNoticeModal
          visible={visible}
          close={() => {
            setModalConfig({ ...modalConfig, visible: false })
          }}
          {...modalConfig}
        />
      )
    }

    if (modalType === 'MoneyTreeFriendInvite') {
      return (
        <MoneyTreeFriendInvite
          visible={visible}
          close={() => {
            setModalConfig({ ...modalConfig, visible: false })
          }}
          {...modalConfig}
        />
      )
    }

    if (modalType === 'MoneyTreeFriendInviteReceived') {
      return (
        <MoneyTreeFriendInviteReceived
          visible={visible}
          close={() => {
            setModalConfig({ ...modalConfig, visible: false })
          }}
          {...modalConfig}
        />
      )
    }

    if (modalType === 'MoneyTreeFriendInviteShakeNudge') {
      return (
        <MoneyTreeFriendInviteShakeNudge
          visible={visible}
          close={(bool) => {
            setModalConfig({ ...modalConfig, visible: bool })
          }}
          {...modalConfig}
        />
      )
    }

    if (modalType === 'MoneyTreeExchange') {
      return (
        <MoneyTreeExchangeModal
          visible={visible}
          close={() => {
            setModalConfig({ ...modalConfig, visible: false })
          }}
          {...modalConfig}
        />
      )
    }

    if (modalType === 'CheckInPigRouletteInvite') {
      return (
        <CheckInPigRouletteInviteModal
          visible={visible}
          close={(bool) => {
            setModalConfig({ ...modalConfig, visible: bool })
          }}
          {...modalConfig}
        />
      )
    }

    if (modalType === 'CheckInPigTutorial') {
      return (
        <CheckInPigTutorial
          visible={visible}
          close={(bool) => {
            setModalConfig({ ...modalConfig, visible: bool })
          }}
          {...modalConfig}
        />
      )
    }

    if (modalType === 'CheckInPigModal') {
      return (
        <CheckInPigModal
          visible={visible}
          close={() => {
            setModalConfig({ ...modalConfig, visible: false })
          }}
          {...modalConfig}
        />
      )
    }

    if (modalType === 'CheckInPigDailyRoulette') {
      return (
        <CheckInPigDailyRouletteModal
          visible={visible}
          close={() => {
            setModalConfig({ ...modalConfig, visible: false })
          }}
          {...modalConfig}
        />
      )
    }

    if (modalType === 'CheckInPigBonusFoodAlert') {
      return (
        <CheckInPigDailyBonusFoodModal
          visible={visible}
          close={() => {
            setModalConfig({ ...modalConfig, visible: false })
          }}
          {...modalConfig}
        />
      )
    }

    return null
  },
)

export default DailyCheckInModalHandler
