import commaNumber from 'comma-number'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef, useState } from 'react'

import ApplePangAction from '../../actions/ApplePangAction'
import UserStore from '../../stores/ApplePang/UserStore'
import AuthStore from '../../stores/AuthStore'
import ToastStore from '../../stores/ToastStore'
import backendApis from '../../utils/backendApis'
import { codepushVersionCheck } from '../../utils/utility'
import RankItem from './RankItem'

const Ranking = observer(() => {
  const [currentTab, setCurrentTab] = useState('all')
  const scrollContainerRef = useRef(null)

  useEffect(() => {
    // currentTab이 변경될 때마다 실행
    if (scrollContainerRef.current) {
      // 스크롤 위치를 0으로 설정
      scrollContainerRef.current.scrollTop = 0
    }
  }, [])

  useEffect(() => {
    if (currentTab === 'all') {
      UserStore.set('rankLoadDone', false)
      // WatermelonAction.getRankingAll()
      ApplePangAction.getRankingAll()
    } else {
      UserStore.set('rankLoadDone', false)
      // WatermelonAction.checkContactPermission()
      checkContactPermission()
    }
  }, [currentTab])

  function checkContactPermission() {
    UserStore.set('contactSkip', 0)
    UserStore.set('uninstallUsers', [])

    window?.ReactNativeWebView?.postMessage(
      JSON.stringify({ type: 'checkContactPermission' }),
    )
  }

  return (
    <div
      className='default flex relative justify-center items-center w-full p-4 text-[#313138] max-h-[50vh] overflow-y-scroll bg-[#FBEFC2] rounded-2xl border-4 border-[#55C2EC]'
      style={{
        fontFamily: 'tmoney',
      }}
    >
      {/* {codepushVersionCheck(AuthStore.codePushVersion, '4.3.29') && (
        <div className='flex flex-row w-full justify-around items-center py-2 '>
          <div
            className={`px-4 ${
              currentTab === 'local'
                ? 'font-bold border-b-2 border-b-[#313138]'
                : 'font-normal'
            }`}
            onClick={() => {
              setCurrentTab('local')
            }}
          >
            친구
          </div>
          <div
            className={`px-4 ${
              currentTab === 'all'
                ? 'font-bold border-b-2 border-b-[#313138]'
                : 'font-normal'
            }`}
            onClick={() => {
              setCurrentTab('all')
            }}
          >
            전체
          </div>
        </div>
      )} */}
      <div
        className='block h-[70vh] overflow-y-scroll px-2 pointer-events-auto w-full space-y-4 '
        ref={scrollContainerRef}
      >
        <div className='font-bold pt-2'>내 순위</div>
        <div className='w-full bg-[#CDB97D] rounded-xl relative'>
          <RankItem
            key={Math.random()}
            user={{
              userImageUri: UserStore?.userImageUri,
              userName: UserStore?.userName,
              bestScore: UserStore?.bestScore ?? 0,
            }}
            currentTab={currentTab}
            index={
              currentTab === 'all'
                ? UserStore?.myRank - 3
                : UserStore?.myRankLocal - 3
            }
          />
        </div>
        {/* <div className='flex flex-row w-full space-x-2 justify-around relative'>
          <div className='absolute right-0 top-0 text-[2.6vw] font-[#313138]'>
            *매주 일요일 초기화
          </div>
          <div className='flex flex-col justify-center items-center h-[50vw] flex-1 relative pt-[20vw]'>
            <img
              className='absolute z-[0] bottom-0'
              src='/watermelonAssets/rankStand2.png'
              alt=''
            />
            <div className='z-[1] flex flex-col justify-center items-center'>
              <img
                className='w-10 h-10 rounded-full'
                src={
                  UserStore?.rankData?.[1]?.userImageUri ??
                  UserStore?.DEFAULT_IMAGE
                }
                onError={(e) => {
                  e.target.src = UserStore?.DEFAULT_IMAGE
                }}
                alt='mission profile'
              />
              <div className='text-[3.5vw] text-center mt-1'>
                {UserStore?.rankData?.[1]?.userName ?? ''}
              </div>
              <div className='text-[4vw] font-bold text-center'>{`${commaNumber(
                UserStore?.rankData?.[1]?.bestScore ?? 0,
              )}점`}</div>
            </div>
          </div>
          <div className='flex flex-col justify-center items-center h-[50vw] flex-1 relative pt-[10vw]'>
            <img
              className='absolute z-[0] bottom-0'
              src='/watermelonAssets/rankStand1.png'
              alt=''
            />
            <div className='z-[1] flex flex-col justify-center items-center'>
              <img
                className='w-10 h-10 rounded-full'
                src={
                  UserStore?.rankData?.[0]?.userImageUri ??
                  UserStore?.DEFAULT_IMAGE
                }
                onError={(e) => {
                  e.target.src = UserStore?.DEFAULT_IMAGE
                }}
                alt='mission profile'
              />
              <div className='text-[3.5vw] text-center mt-1'>
                {UserStore?.rankData?.[0]?.userName ?? ''}
              </div>
              <div className='text-[4vw] font-bold text-center'>{`${commaNumber(
                UserStore?.rankData?.[0]?.bestScore ?? 0,
              )}점`}</div>
            </div>
          </div>
          <div className='flex flex-col justify-center items-center h-[50vw] flex-1 relative pt-[20vw]'>
            <img
              className='absolute z-[0] bottom-0'
              src='/watermelonAssets/rankStand3.png'
              alt=''
            />
            <div className='z-[1] flex flex-col justify-center items-center'>
              <img
                className='w-10 h-10 rounded-full'
                src={
                  UserStore?.rankData?.[2]?.userImageUri ??
                  UserStore?.DEFAULT_IMAGE
                }
                onError={(e) => {
                  e.target.src = UserStore?.DEFAULT_IMAGE
                }}
                alt='mission profile'
              />
              <div className='text-[3.5vw] text-center mt-1'>
                {UserStore?.rankData?.[2]?.userName ?? ''}
              </div>
              <div className='text-[4vw] font-bold text-center'>{`${commaNumber(
                UserStore?.rankData?.[2]?.bestScore ?? 0,
              )}점`}</div>
            </div>
          </div>
        </div> */}
        <div className='font-bold'>전체 순위</div>
        {UserStore?.rankData?.slice(0)?.map((user, i) => {
          return <RankItem key={Math.random()} user={user} index={i} />
        })}
      </div>
    </div>
  )
})

export default Ranking
