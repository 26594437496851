import React, { useState } from 'react'

const Balloon = ({ visible, onClick = () => {}, contents = {} }) => {
  if (!visible) {
    return <></>
  }
  return (
    <div
      onClick={onClick}
      style={{
        ...styles.ballonContainer,
        height: contents.length > 17 ? '22%' : '18%',
      }}
      className='pulsate-fwd bubble'
    >
      <div
        style={{
          ...styles.ballonContent,
          whiteSpace: 'pre-line',
        }}
      >
        {contents}
      </div>
    </div>
  )
}

export default function SpeechBubble({ contents = {} }) {
  const [balloonVisible, setBalloonVisible] = useState(true)

  const onCloseBalloon = () => {
    setBalloonVisible(false)
  }

  return (
    <Balloon
      visible={balloonVisible}
      contents={contents}
      onClick={onCloseBalloon}
    />
  )
}

const styles = {
  ballonContainer: {
    position: 'absolute',
    fontSize: '3.6vw',
    top: '-10vh',
  },
  // position absolute 일 때 중앙 정렬 하는 법
  ballonContent: {
    position: 'absolute',
    margin: 'auto',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    lineHeight: 1.1,
    color: 'white',
    width: '90%',
  },
}
