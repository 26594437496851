import commaNumber from 'comma-number'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useInterval } from 'react-use'

import TreasureHuntAction from '../../actions/TreasureHuntAction'
import WatermelonAction from '../../actions/WatermelonAction'
import AuthStore from '../../stores/AuthStore'
import WatermelonUserStore from '../../stores/WatermelonUserStore'
import THBottomSheetStore from '../../stores/treasureHunt/THBottomSheetStore'
import THModalStore from '../../stores/treasureHunt/THModalStore'
import { codepushVersionCheck, random } from '../../utils/utility'
import TreasureHuntButton from './TreasureHuntButton'

const WatermelonBounty = observer(() => {
  const [currentTab, setCurrentTab] = useState('all')
  const scrollContainerRef = useRef(null)
  const loadingThrottle = useRef(false)
  const [showButton, setShowButton] = useState(true)

  useEffect(() => {
    const handleScroll = (e) => {
      const bottom =
        e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight
      if (
        bottom &&
        WatermelonUserStore?.contactSkip <=
          WatermelonUserStore?.contactList?.length &&
        !loadingThrottle.current
      ) {
        // Action to perform at the end of the scroll
        loadingThrottle.current = true
        setShowButton(false)
        setTimeout(() => {
          loadingThrottle.current = false
          setShowButton(true)
        }, 500)
        WatermelonUserStore.set('isBountyInviteLoad', true)
        WatermelonAction.getRankingLocal({
          contacts: WatermelonUserStore?.contactList,
          skip: WatermelonUserStore?.contactSkip,
        })
      }
    }

    const scrollContainer = scrollContainerRef.current
    if (scrollContainer) {
      scrollContainer.addEventListener('scroll', handleScroll)
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scroll', handleScroll)
      }
    }
  }, [])

  useEffect(() => {
    WatermelonUserStore.set('isBountyInviteLoad', true)
    WatermelonAction.checkContactPermission()
  }, [])

  return (
    <div
      ref={scrollContainerRef}
      className='default flex relative justify-start flex-col items-center w-full p-4 pt-6 text-[#313138] h-[60vh] overflow-y-scroll space-y-6'
      style={{
        fontFamily: 'maplestory',
      }}
    >
      {WatermelonUserStore?.churnedUsers?.map((user, index) => (
        <div className='w-full flex flex-row items-center' key={Math.random()}>
          <div className='mr-2 font-bold text-[4vw] truncate w-[22vw]'>
            {user?.name}
          </div>
          <div className='text-[3.5vw] text-[#8E8D9B]'>{`
            ${user?.phoneNumber?.slice(0, 3)}-${user?.phoneNumber?.slice(
            3,
            7,
          )}-${user?.phoneNumber?.slice(7, 11)}
            `}</div>
          <TreasureHuntButton
            className='self-end ml-auto text-[4vw]'
            clickable
            text='현상금: '
            secondTextImg='../images/treasureHunt/energy.png'
            secondText={`${user?.reward ?? 2}개`}
            onClick={() => {
              THModalStore.setType({
                type: 'watermelonRule',
                config: {
                  title: '현상금 미션',
                  forceOpen: true,
                  src: 'bountyInviteModal',
                  onClick: () => {
                    TreasureHuntAction.linkShare({
                      shareType: 'Watermelon_BountyInvite',
                      title: '[양파게임] 다양해진 양파게임을 만나보세요!',
                      description: '지금 시작하면 도전권 2개를 드려요! 😘',
                      imageUrl:
                        'https://assets.ilevit.com/5a778a82-2457-495f-97ef-543fd33180ae.png',
                      payload: {},
                    })
                  },
                  text: '초대하기',
                },
              })
            }}
          />
        </div>
      ))}

      <TreasureHuntButton
        clickable={
          WatermelonUserStore.contactSkip <=
            WatermelonUserStore?.contactList?.length &&
          !loadingThrottle.current &&
          showButton
        }
        className='w-full text-[4vw] py-4 mt-2'
        text='더 불러오기'
        onClick={() => {
          setShowButton(false)
          setTimeout(() => {
            loadingThrottle.current = false
            setShowButton(true)
          }, 500)
          WatermelonUserStore.set('isBountyInviteLoad', true)
          WatermelonAction.getRankingLocal({
            contacts: WatermelonUserStore?.contactList,
            skip: WatermelonUserStore?.contactSkip,
          })
        }}
      />
    </div>
  )
})

export default WatermelonBounty
