import commaNumber from 'comma-number'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import AnimatedNumbers from 'react-animated-numbers'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import { Virtuoso } from 'react-virtuoso'

import ExpireHistoryComponent from '../components/deal/item/ExpireHistoryComponent'
import { YellowMoney } from '../images/dailyCheckIn'
import AuthStore from '../stores/AuthStore'
import UserStore from '../stores/UserStore'
import backendApis from '../utils/backendApis'

const ExpireHistoryPage = observer(() => {
  const navigate = useNavigate()

  const [animatePoints, setAnimatePoints] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [accumulatedPoints, setAccumulatedPoints] = useState(0)
  const [expireHistory, setExpireHistory] = useState([])
  const SCREEN_HEIGHT = window.innerHeight

  useEffect(() => {
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 300)
    if (!AuthStore.token) {
      navigate(`/daily-check-in`)
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      const pointHistory = await backendApis.getCheckInPoint(AuthStore.token)
      const expireHistory = await backendApis.getExpiringPoint(AuthStore.token)

      if (pointHistory?.status === 200 && expireHistory?.status === 200) {
        setAccumulatedPoints(pointHistory?.data[0]?.totalCheckInPoints)
        setExpireHistory(expireHistory?.data)
      }
      if (isLoading) {
        setIsLoading(false)
      }
    }
    fetchData()
  }, [AuthStore.token])

  useEffect(() => {
    setTimeout(() => {
      setAnimatePoints(true)
      setAccumulatedPoints(UserStore?.accumulatedPoints)
      setTimeout(() => {
        setAnimatePoints(false)
      }, 3000)
    }, 200)
  }, [UserStore?.accumulatedPoints])

  const LoadingIndicator = () => {
    return (
      <div>
        <div className='fixed inset-0 z-10 w-full h-full bg-gray-800 opacity-70' />
        <div style={{ left: '40%', top: '40%' }} className='fixed z-20'>
          <ClipLoader
            color='#ff3e3e'
            loading={isLoading}
            size={80}
            aria-label='Loading Spinner'
            data-testid='loader'
          />
        </div>
      </div>
    )
  }

  const HeaderComponent = () => {
    return (
      <section className='flex flex-row overflow-hidden py-2 z-0 relative items-center justify-between'>
        <button
          type='button'
          className='px-3 py-2 whitespace-nowrap'
          onClick={() => {
            navigate(`/daily-check-in?token=${AuthStore.token}`)
          }}
        >
          <FiChevronLeft className='w-8 h-8 stroke-gray-600' />
        </button>
        <div
          style={{ fontSize: '5vw' }}
          className='p-3 text-lg font-bold text-center'
        >
          올포인트 소멸내역
        </div>
        <div className='w-10' />
      </section>
    )
  }

  const PointStatusComponent = () => {
    const year = new Date().getFullYear()
    const month = new Date().getMonth() + 1
    const expiringPoints = expireHistory?.filter(
      (item) => item.year === year && item.month === month && !item.deletedAt,
    )

    return (
      <div style={{ fontSize: '4.5vw' }} className='w-full flex flex-row'>
        <div className='w-full mx-6 flex flex-col'>
          <div className='mt-4 mb-6 px-6 py-4 flex flex-col items-start font-bold rounded-xl bg-white'>
            <div className='flex flex-row justify-between w-full'>
              <div>보유한 올포인트</div>
              <button
                type='button'
                className='flex flex-row text-gray-500 justify-end items-center text-xs'
                onClick={() => {
                  navigate(`/check-in-exchange-history`)
                }}
              >
                교환내역 <FiChevronRight className='w-4 h-4 stroke-gray-400' />
              </button>
            </div>
            <div
              style={{ fontSize: '7.6vw' }}
              className='flex flex-row items-center pt-1'
            >
              <YellowMoney className='w-8 mr-2' />
              {animatePoints ? (
                <AnimatedNumbers
                  includeComma
                  animateToNumber={accumulatedPoints || 0}
                  fontStyle={{ fontSize: '7.6vw' }}
                  configs={[{ mass: 1, tension: 320, friction: 70 }]}
                />
              ) : (
                commaNumber(accumulatedPoints || 0)
              )}
              <div className='ml-1'>P</div>
            </div>
            <div className='flex flex-row items-center justify-between w-full border-t border-gray-300 pt-4 pb-1 mt-4 text-base'>
              <div>{month}월 소멸 예정</div>
              <div>
                {expiringPoints[0]?.expiredPoints > 0
                  ? `${commaNumber(
                      Math.min(
                        expiringPoints[0]?.expiredPoints,
                        UserStore?.accumulatedPoints,
                      ),
                    )}P`
                  : '0P'}
              </div>
            </div>
            <div className='w-full text-gray-500 text-[2vw]'>
              <div>
                * 획득한지 6개월이 지난 포인트는 매달 말일 00시에 소멸되어요
              </div>
              <div className='font-light'>
                {' '}
                (예시: 1월 기준, 30일에서 31일이 되는 0시)
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div
      style={{
        overflow: 'hidden',
        width: '100%',
        height: '100%',
        backgroundColor: '#F4DBC9',
      }}
    >
      <HeaderComponent />
      <PointStatusComponent />
      <div className='bg-white rounded-t-2xl px-6 pt-8'>
        <div className='text-xl font-bold pb-8'>포인트 소멸 내역</div>
        {expireHistory?.filter(
          (item) => item.isExpired === true && !item.deletedAt,
        ).length < 1 && (
          <div className='text-center font-semibold text-lg py-4'>
            아직 소멸된 포인트가 없어요
          </div>
        )}
        <Virtuoso
          data={
            expireHistory?.filter(
              (item) => item.isExpired === true && !item.deletedAt,
            ) || []
          }
          itemContent={(index, data) => {
            return <ExpireHistoryComponent data={data} />
          }}
          style={{
            height: SCREEN_HEIGHT,
            backgroundColor: 'white',
          }}
        />
      </div>

      {isLoading && <LoadingIndicator />}
    </div>
  )
})

export default ExpireHistoryPage
