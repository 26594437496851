import commaNumber from 'comma-number'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'

import THModalStore from '../../../stores/treasureHunt/THModalStore'
import TreasureHuntButton from '../TreasureHuntButton'

function renderDescription(text) {
  return text.split('\n').map((line, index) => (
    <span key={Math.random()}>
      {line}
      {index < text.split('\n').length - 1 && <br />}
    </span>
  ))
}

const ApplePangRankModal = ({ config }) => {
  const [, set] = useState()
  return (
    <div className='default justify-center items-center'>
      <div className='default font-bold text-center text-[6vw] mb-4'>{`${
        config?.amount ? commaNumber(config?.amount) : ''
      } ${config?.rewardName}`}</div>

      <div className='flex flex-row w-full justify-between items-center my-4'>
        <div className='flex flex-col justify-center items-center flex-1'>
          <div className='font-bold'>현재 랭크</div>
          <img
            alt='rank'
            src={`../images/applePang/ranking/${config?.currentRank}.png`}
            className='w-14 h-14 my-2'
          />
          <div className='font-bold'>{`조건 ${commaNumber(
            config?.rankData?.[config?.currentRank],
          )}점`}</div>
        </div>

        <div className='flex flex-col justify-center items-center flex-1'>
          <div className='font-bold'>다음 목표</div>
          <img
            alt='rank'
            src={`../images/applePang/ranking/${config?.nextRank}.png`}
            className='w-14 h-14 my-2'
          />
          <div className='font-bold'>{`조건 ${commaNumber(
            config?.rankData?.[config?.nextRank],
          )}점`}</div>
        </div>
      </div>

      <div className='flex flex-col justify-center items-center flex-1 mt-8'>
        <div className='font-bold text-[5vw]'>최종 목표</div>
        <img
          alt='rank'
          src={`../images/applePang/ranking/10.png`}
          className='w-14 h-14 my-2'
        />
        <div className='font-bold'>{`조건 ${commaNumber(
          config?.rankData?.[10],
        )}점`}</div>
      </div>

      <TreasureHuntButton
        className='mt-6 w-full py-3'
        clickable
        text={config?.text ?? '닫기'}
        onClick={() => {
          THModalStore?.close()
          if (config?.onClick) {
            config?.onClick()
          }
        }}
      />
    </div>
  )
}

export default observer(ApplePangRankModal)
