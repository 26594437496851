import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSearchParam } from 'react-use'

import {
  ModalPigOnly,
  RaffleBanner,
  RedBox,
  YellowMoney,
} from '../../images/dailyCheckIn'
import backendApis from '../../utils/backendApis'

const SubMenuButtonGroup = (checkInAlarmConsent) => {
  const navigate = useNavigate()
  const token = useSearchParam('token')

  return (
    <div className='flex flex-col text-sm font-bold'>
      <div className='flex flex-row mx-4 text-sm font-bold'>
        <button
          type='button'
          className='flex flex-col items-center justify-around w-1/3 px-2 py-3 duration-200 bg-white rounded-lg box-shadow outline-0 active:brightness-90 active:scale-95'
          onClick={() => {
            navigate(`/check-in-pig?token=${token}&isFrom=web`)
            backendApis.recordEventLog(
              'CheckIn_PigMissionButton',
              `clicked_CheckIn_PigMissionButton`,
            )
          }}
        >
          <ModalPigOnly className='mb-2 w-9' />
          <div>돈돈이 키우기</div>
        </button>
        <div className='w-2' />
        <button
          type='button'
          className='flex flex-col items-center justify-end w-1/3 px-2 py-3 duration-200 bg-white rounded-lg box-shadow outline-0 active:brightness-90 active:scale-95'
          onClick={() => {
            navigate(`/exchange-store?token=${token}&isFrom=web`)
          }}
        >
          <YellowMoney className='mb-2 w-9' />
          <div>포인트 교환소</div>
        </button>
        <div className='w-2' />
        <button
          type='button'
          className='flex flex-col items-center justify-end w-1/3 px-2 py-3 duration-200 bg-white rounded-lg box-shadow outline-0 active:brightness-90 active:scale-95'
          onClick={() => {
            window.location.href = `#navigate.${JSON.stringify({
              screen: 'DailyCheckInCashBackDealScreen',
            })}`
          }}
        >
          <RedBox className='mb-2 w-9' />
          <div>캐시백 쇼핑</div>
        </button>
      </div>
      <button
        type='button'
        className='mt-4 duration-200 box-shadow outline-0 active:opacity-80'
        onClick={() => {
          navigate(
            `/raffle?token=${token}&isFrom=web&checkInAlarmConsent=${checkInAlarmConsent}`,
          )
        }}
      >
        <RaffleBanner className='w-full' />
      </button>
    </div>
  )
}

export default SubMenuButtonGroup
