import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { FiChevronLeft } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'

import LoadingIndicator from '../../components/atoms/loadingIndicator'
import DrawGifticonItemComponent from '../../components/deal/item/drawGifticonItemComponent'
import AuthStore from '../../stores/AuthStore'
import backendApis from '../../utils/backendApis'

const CheckInDrawHistoryScreen = observer(() => {
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)
  const [drawHistory, setDrawHistory] = useState()
  const [triggerFetch, setTriggerFetch] = useState(0)

  useEffect(() => {
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 300)
    if (!AuthStore.token) {
      navigate(-1)
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      const result = await backendApis.getGifticonDrawHistory(0)

      if (result?.status === 200) {
        const filteredResult = result?.data?.filter(
          (item) =>
            item?.targetGifticon?._id && item?.targetGifticon?.itemTitle,
        )
        setDrawHistory(filteredResult)
      }
      setIsLoading(false)
    }
    fetchData()
  }, [AuthStore.token, triggerFetch])

  const HeaderComponent = () => {
    return (
      <section className='relative z-0 flex flex-row items-center justify-between py-2 overflow-hidden'>
        <button
          type='button'
          className='px-3 py-2 whitespace-nowrap'
          onClick={() => {
            navigate(`/exchange-store?token=${AuthStore.token}`)
          }}
        >
          <FiChevronLeft className='w-8 h-8 stroke-gray-600' />
        </button>
        <div
          style={{ fontSize: '5vw' }}
          className='p-3 text-lg font-bold text-center text-gray-800'
        >
          기프티콘 보관함
        </div>
        <div className='w-10' />
      </section>
    )
  }
  if (isLoading) {
    return <LoadingIndicator />
  }

  if (drawHistory?.length === 0) {
    return (
      <div className='flex flex-col h-full text-center bg-white'>
        <HeaderComponent />
        보유중인 기프티콘 뽑기 내역이 없어요.
      </div>
    )
  }

  return (
    <div className='flex flex-col h-full bg-white'>
      <HeaderComponent />
      <div className='h-[4vw] w-full ' />
      {drawHistory?.length && (
        <div className='grid grid-cols-2 px-2 py-2'>
          {drawHistory?.map((item) => (
            <DrawGifticonItemComponent
              key={item?._id}
              drawInfo={item}
              triggerFetch={triggerFetch}
              setTriggerFetch={setTriggerFetch}
            />
          ))}
        </div>
      )}
    </div>
  )
})

export default CheckInDrawHistoryScreen
