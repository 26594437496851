import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'

import TreasureHuntAction from '../actions/TreasureHuntAction'
import THImageText from '../components/treasureHunt/THImageText'
import TreasureHuntButton from '../components/treasureHunt/TreasureHuntButton'
import FriendList from '../components/treasureHunt/molecules/FriendList'
import HeaderComponent from '../components/treasureHunt/molecules/HeaderComponent'
import AuthStore from '../stores/AuthStore'
import RouteStore from '../stores/RouteStore'
import ToastStore from '../stores/ToastStore'
import THUserStore from '../stores/treasureHunt/THUserStore'
import backendApis from '../utils/backendApis'

const TreasureHuntFriend = observer(() => {
  useEffect(() => {
    const fetchFriend = async () => {
      const result = await backendApis.fetchFriend({
        friendId: THUserStore?.currentFriend?.friendId,
      })
      if (result?.status === 200) {
        THUserStore.set('currentFriend', result?.data)
      } else {
        ToastStore.networkError()
      }
    }
    if (THUserStore?.currentFriend?.friendId) {
      fetchFriend()
    }
  }, [THUserStore?.currentFriend?.friendId])

  const MissionItem = observer(({ mission }) => {
    const [onClickHandler, setOnClickHandler] = useState(() => {})
    const [buttonClickable, setButtonClickable] = useState(false)
    const [buttonText, setButtonText] = useState(mission?.actionButtonText)

    useEffect(() => {
      if (mission?.type === 'reward') {
        setButtonClickable(true)
        setButtonText('보상받기')
      } else if (mission?.type === 'action') {
        setButtonClickable(true)
        setButtonText(mission?.actionButtonText)
      } else if (mission?.isAction && !mission?.type) {
        setButtonClickable(true)
        setButtonText(mission?.actionButtonText)
      } else if (!mission?.isAction && mission?.type === 'disabled') {
        if (
          moment().format('YYYY-MM-DD') ===
          moment(mission?.rewardedAt ?? '2023-01-01').format('YYYY-MM-DD')
        ) {
          setButtonClickable(false)
          setButtonText('내일가능')
        } else {
          setButtonClickable(false)
          setButtonText(mission?.actionButtonText)
        }
      } else if (mission?.type === 'disabled') {
        setButtonClickable(false)
        setButtonText('내일가능')
      }

      if (mission?.tag === 'hello') {
        setOnClickHandler(() => () => {
          TreasureHuntAction.friendHello()
        })
      } else if (mission?.tag === 'gift') {
        setOnClickHandler(() => () => {
          TreasureHuntAction.friendGiveGift()
        })
      } else if (mission?.tag === 'search') {
        setOnClickHandler(() => () => {
          TreasureHuntAction.friendNudgeToSearch()
          setButtonClickable(false)
        })
        if (mission?.type !== 'reward') {
          const friendNudgeSearch = window.localStorage.getItem(
            `friendNudgeSearch_${THUserStore?.currentFriend?.friendId?.toString()}`,
          )
          if (moment().format('YYYY-MM-DD') === friendNudgeSearch) {
            setButtonClickable(false)
          }
        }
      } else if (mission?.tag === 'find') {
        setOnClickHandler(() => () => {
          TreasureHuntAction.friendFind()
        })
      }
      if (mission?.type === 'reward') {
        setOnClickHandler(() => () => {
          TreasureHuntAction.rewardFriendMission({ tag: mission?.tag })
        })
      }

      if (THUserStore?.friends?.length === 0) {
        setButtonClickable(false)
      }
    }, [])

    return (
      <div className='flex flex-row justify-between items-center p-2 bg-[#F7F7F7] m-4 mb-0 rounded-xl text-[#313138]'>
        <div className='bg-[#FFE2BA] rounded-full w-12 h-12 flex justify-center items-center p-2'>
          <img className='w-10 rounded-full' alt='' src={mission?.src} />
        </div>
        <div className='flex flex-col justify-center items-start flex-1 pl-4'>
          <div className='font-bold text-[4.5vw] text-[#313138]'>
            {mission?.title ?? '미션 이름'}
          </div>
          <THImageText
            fontSize={3.5}
            style={{
              fontweight: 'semiBold',
              textAlign: 'left',
              color: '#595864',
            }}
            text={mission?.description ?? '보상 내용'}
          />
        </div>
        <TreasureHuntButton
          text={buttonText}
          clickable={buttonClickable}
          style={{ margin: '2vw', zIndex: 0 }}
          onClick={onClickHandler}
        />
      </div>
    )
  })

  return (
    <div
      className='fixed flex-col flex w-full h-full justify-between text-[#313138]'
      style={{
        backgroundImage: `url(../images/treasureHunt/friendBackground.png)`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    >
      <div>
        <HeaderComponent
          hideButtons
          backButtonOnClick={() => {
            RouteStore.navigate(`/treasureHunt?token=${AuthStore?.token}`)
            TreasureHuntAction.initTreasureHunt(AuthStore?.token, {
              routeParams: {},
              codepushVersion: AuthStore?.codepushVersion,
            })
          }}
          giftOn
        />
      </div>
      <div>
        <FriendList />
      </div>
      <div className='relative flex flex-1 flex-col justify-center items-center'>
        <div className='relative flex flex-col justify-center items-center m-4 w-full h-full'>
          <img
            className='rounded-full border-2 w-[20vw] h-[20vw] object-cover'
            src={
              THUserStore?.currentFriend?.userImageUri ??
              THUserStore?.DEFAULT_IMAGE
            }
            alt='profile'
          />
          <div className='text-[5vw] font-bold mt-2'>
            {THUserStore?.currentFriend?.userName ?? '보물친구'}
          </div>
        </div>

        <div className='bg-[#FFE2BA] px-8 py-2 rounded-full -mb-5 font-bold text-[#FF7A00]'>
          {(THUserStore?.currentFriend?.consecutiveDiscovery ?? 0) <= 0
            ? '보물친구 미션으로 보상을 받으세요!'
            : `🔥 ${
                THUserStore?.currentFriend?.consecutiveDiscovery ?? 0
              }일 연속 발견중!
            🔥`}
        </div>
      </div>

      <div
        className='flex flex-col bg-white pt-[8vw] max-h-[40vh] '
        style={{
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
        }}
      >
        <div className='h-full overflow-y-scroll pb-[4vw]'>
          {[...THUserStore?.friendMission]
            ?.map((item, i) => {
              return {
                ...item,
                type:
                  item?.type === 'reward'
                    ? item?.type
                    : THUserStore?.currentFriend?.missions?.[i]?.type,
                rewardedAt:
                  THUserStore?.currentFriend?.missions?.[i]?.rewardedAt,
              }
            })
            ?.sort((a, b) => {
              const typeA = a?.type
              const typeB = b?.type
              const isActionA = a?.isAction
              const isActionB = b?.isAction

              if (typeA === 'reward' && typeB !== 'reward') return -1
              if (typeA !== 'reward' && typeB === 'reward') return 1

              if (typeA === 'disabled' && typeB !== 'disabled') return 1
              if (typeA !== 'disabled' && typeB === 'disabled') return -1

              if (typeA === 'disabled' && typeB === 'disabled') {
                if (!isActionA && isActionB) return -1
                if (isActionA && !isActionB) return 1
              }

              return 0
            })
            ?.map((mission, i) => (
              <MissionItem key={mission?.tag} mission={mission} />
            ))}
        </div>
      </div>
      <div className='bg-white p-4'>
        <TreasureHuntButton
          clickable={THUserStore?.gift > 0}
          onClick={() => {
            TreasureHuntAction.openGift()
          }}
          text='선물상자 열기 '
          secondTextImg='../images/treasureHunt/gift.png'
          secondText='x 1'
          style={{
            fontSize: '5vw',
            padding: '4vw',
          }}
          onDisabledClick={() => {
            ToastStore.toastOn({
              type: 'error',
              message: '선물이 없어요!',
              duration: 2000,
            })
          }}
        />
      </div>
    </div>
  )
})

export default TreasureHuntFriend
