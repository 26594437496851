import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import TreasureHuntAction from '../actions/TreasureHuntAction'
import TreasureHuntButton from '../components/treasureHunt/TreasureHuntButton'
import AuthStore from '../stores/AuthStore'

const TreasureHuntLocationAuth = observer(() => {
  const [isFading, setIsFading] = useState(true)
  const navigate = useNavigate()

  const handleButtonClick = async () => {
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'requestAuthorization',
        data: {
          webscreenOptions: {
            hardwareBackPressEnabled: true,
          },
        },
      }),
    )
  }

  return (
    <div className='fixed flex w-full h-full'>
      <div className='default fixed w-full h-full z-[10] bg-black/70' />
      <div
        className={`
          default fixed w-full h-full z-[10] bg-black/70 transition-all duration-500
          ${isFading ? 'opacity-100' : 'opacity-0'}
          `}
        style={{
          backgroundImage: `url(../images/treasureHunt/tutorial/locationAuth.png)`,
        }}
      />

      <div className='absolute w-full p-4 z-[11] bottom-0'>
        <TreasureHuntButton
          className='w-full py-3 border-2 border-white text-[5vw]'
          clickable
          text='위치 권한 허용하기'
          onClick={handleButtonClick}
        />
      </div>
    </div>
  )
})

export default TreasureHuntLocationAuth
