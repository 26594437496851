import React from 'react'

import { TelepathicIcon, TripleShakeIcon } from '../images/dailyCheckIn'
import useDebounce from './useDebounce'

const MoneyTreeBottomSheetItem = ({
  leftImage = '',
  title,
  subTitle,
  reward,
  subReward,
  subRewardType,
  content,
  timer,
  headText,
  footerText,
  impressTextInContent = '',
  moveTo,
  disabled = false,
  addedComponent,
  button = '',
}) => {
  const debounce = useDebounce()
  const ImageMapper = ({ leftImage = '' }) => {
    if (leftImage === 'ShakeIconGreen') {
      return <TripleShakeIcon className='w-14' />
    }
    if (leftImage === 'DefaultProfileImage') {
      return <TelepathicIcon className='w-14' />
    }
  }

  return (
    <div
      style={{ fontSize: '3vw', height: '10vh' }}
      className='flex-row flex flex-1 items-center py-2 jua'
    >
      <div className='flex flex-1 items-center'>
        <div className='flex justify-center' style={{ width: '16vw' }}>
          <ImageMapper leftImage={leftImage} />
        </div>
        <div className='flex-col flex flex-1 justify-center items-start pl-2'>
          {headText && (
            <div className='text-red-500' style={{ fontSize: '3vw' }}>
              {headText}
            </div>
          )}
          <div className='flex flex-row justify-center items-center'>
            <div
              className='flex flex-row items-center'
              style={{ fontSize: '4vw' }}
            >
              {title}
              <div className='flex pl-1' style={{ fontSize: '3vw' }}>
                {subTitle}
              </div>
            </div>
            {reward && (
              <div
                className='flex flex-row rounded-lg items-center'
                style={{ color: '#DD524B', fontSize: '3vw' }}
              >
                {reward}
              </div>
            )}
            {subReward && (
              <div
                className='flex flex-row ml-2 items-center justify-center'
                style={{ color: '#DD524B', fontSize: '3vw' }}
              >
                <div className='mt-1 mr-1'>{subRewardType}</div>
                {subReward}
              </div>
            )}
          </div>
          <div style={{ color: '#5D877A', fontSize: '3vw' }}>
            {impressTextInContent}
            {content}
          </div>
          {footerText && (
            <div style={{ color: '#DD524B', fontSize: '3vw' }}>
              {footerText}
            </div>
          )}
          {addedComponent && (
            <div style={{ color: '#DD524B', fontSize: '3vw' }}>
              {addedComponent}
            </div>
          )}
        </div>
      </div>
      <div className='flex flex-col items-center justify-center'>
        {timer && <div style={{ fontSize: '3vw' }}>{timer} 후 가능</div>}

        {button || (
          <button
            type='button'
            style={{ width: '20vw', fontSize: '4vw' }}
            className={
              disabled
                ? 'bg-[#B5CFCA] px-2 py-2 rounded-lg justify-center text-white'
                : 'bg-[#008E5B] px-2 py-2 rounded-lg justify-center text-white'
            }
            onClick={() => {
              debounce(moveTo, 500)
            }}
            disabled={disabled}
          >
            {disabled ? '미션완료' : '보상받기'}
            {button}
          </button>
        )}
      </div>
    </div>
  )
}

export default MoneyTreeBottomSheetItem
