import commaNumber from 'comma-number'
import moment from 'moment'

const checkInExchangeHistoryComponent = ({
  type,
  title,
  exchangeData = '',
  exchangedAt,
  explanation = '',
  point,
}) => {
  const ExplanationComponent = () => {
    if (type === 'gifticon') {
      if (explanation) {
        return `발송 일시: ${
          moment(explanation).format('YYYY.MM.DD') ===
          moment().format('YYYY.MM.DD')
            ? `${moment(explanation)
                .add(1, 'day')
                .format('YYYY.MM.DD')} 내 발송 예정`
            : moment(explanation).format('YYYY.MM.DD')
        }`
      }
      return '발송 예정'
    }
  }

  return (
    <div
      className='w-full flex flex-row justify-center items-center pr-4 text-base'
      style={{ borderBottomWidth: 0.5 }}
    >
      <div className='w-1/6 py-5 border-r-2 text-center text-sm'>
        {type === 'cashback' && '적립'}
        {(type === 'coupon' || (type === 'gifticon' && explanation)) && '교환'}
        {type === 'gifticon' && !explanation && '신청'}
      </div>
      <div className='py-4 pl-2 flex flex-col w-4/6'>
        <div className='pb-1 text-xs text-gray-600'>
          {moment(exchangedAt).format('YYYY.MM.DD')}
        </div>
        <div>
          <div>{title}</div>
        </div>
        <div className='text-xs font-semibold text-gray-500'>
          <ExplanationComponent />
        </div>
      </div>
      <div className='w-1/6 text-sm font-bold'>
        {type === 'cashback' && `+ ${commaNumber(point)}P`}
        {type === 'coupon' &&
          exchangeData?.couponInfo?.origin === 'daily_check_in_special_1000' &&
          '- 500P'}
        {(type === 'coupon' || type === 'gifticon') &&
          `- ${commaNumber(point)}P`}
      </div>
    </div>
  )
}

export default checkInExchangeHistoryComponent
