import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { useInterval } from 'react-use'

import TreasureHuntAction from '../../actions/TreasureHuntAction'
import ModalStore from '../../stores/ModalStore'
import ToastStore from '../../stores/ToastStore'
import ABStore from '../../stores/treasureHunt/ABStore'
import THUserStore from '../../stores/treasureHunt/THUserStore'
import backendApis from '../../utils/backendApis'
import TreasureHuntButton from './TreasureHuntButton'

const THTutorialSecond = observer(() => {
  const [tutorialStep, setTutorialStep] = useState([])
  const [highlightPosition, setHighlightPosition] = useState({
    onClick: () => {},
  })
  const [currentSize, setCurrentSize] = useState(0)
  const [initialized, setInitialized] = useState(false)
  const [animation, setAnimation] = useState(true)
  const [textAnimationTrigger, setTextAnimationTrigger] = useState(false)
  const [tutorialText, setTutorialText] = useState('테스스트트트')
  const [turnOff, setTurnOff] = useState(false)

  const clickThrottle = useRef()
  const stepsType = [
    // intro 0
    {
      type: 'empty',
    },
    {
      type: 'MatchItem',
      onClick: () => {
        const dom = document.getElementById('MathItemInner')
        dom.click()
      },
      maskType: 'circle',
      textY: -30,
      textOn: true,
      text: '캐릭터를 누르면 바로 캐시를 받아요',
    },
    {
      type: 'empty',
    },
    {
      type: 'THCTA',
      onClick: () => {
        const dom = document.getElementById('THCTAButton')
        dom.click()
      },
      maskType: 'circle',
      textY: -30,
      textOn: true,
      text: '주변을 탐색하고 캐릭터를 더 찾아보세요',
      // boxHeight: '14vh',
      fingerLeft: 20,
      fingerTop: 0,
      fingerOn: true,
      // boxY: '90vh',

      buttonText: '다음',
    },
  ]

  // INIT
  const init = () => {
    const tempSteps = []
    for (const typeInfo of stepsType) {
      const doc = document.getElementById(typeInfo?.type)
      const rect = doc?.getBoundingClientRect()
      const position = {
        x: rect?.x + rect?.width / 2,
        y:
          rect?.y +
          rect?.height / 2 -
          (typeInfo?.maskType === 'box' ? rect?.height / 2 + 20 : 0),
        height: rect?.height + 40,
      }

      tempSteps.push({
        type: typeInfo?.type,
        x: Number.isNaN(position.x) ? -100 : position.x,
        y: Number.isNaN(position.y) ? -100 : position.y,
        height: Number.isNaN(position.height) ? 0 : position.height,
        boxY: typeInfo?.boxY,
        boxHeight: typeInfo?.boxHeight,
        fingerLeft: typeInfo?.fingerLeft,
        fingerTop: typeInfo?.fingerTop,
        fingerOn: typeInfo?.fingerOn,
        radius: Number.isNaN(position.x) ? 0 : 12,
        onClick: async () => {
          if (clickThrottle.current) return
          clickThrottle.current = true
          setTimeout(() => {
            clickThrottle.current = false
          }, 800)
          await typeInfo?.onClick()
        },
        maskType: typeInfo?.maskType ?? 'circle',
        textX: typeInfo?.textX,
        textY: typeInfo?.textY,
        textOn: typeInfo?.textOn ?? false,
        text: typeInfo?.text,
        textAppearDelay: typeInfo?.textAppearDelay ?? 150,
        buttonY: typeInfo?.buttonY,
        isVH: typeInfo?.isVH,
        buttonOn: typeInfo?.buttonOn ?? false,
        buttonText: typeInfo?.buttonText,
      })
    }
    setTutorialStep(tempSteps)
    setInitialized(true)
    THUserStore?.set('lastTutorialStep', stepsType.length)
  }

  // init
  useEffect(() => {
    setInitialized(false)
    setTimeout(() => {
      init()
    }, 100)
  }, [])

  // Data Control
  useEffect(() => {
    if (initialized) {
      const temp = tutorialStep?.[THUserStore?.tutorialMinorStep ?? 0]
      setHighlightPosition({
        ...temp,
      })
      setTurnOff(false)
      setCurrentSize(0)
      setAnimation(true)
      setTutorialText(temp?.text ?? '')
    }
  }, [THUserStore?.tutorialMinorStep, initialized])

  // Text Animation Control
  useEffect(() => {
    setTextAnimationTrigger(false)
    if (initialized) {
      setTimeout(() => {
        setTextAnimationTrigger(true)
      }, highlightPosition?.textAppearDelay)
    }
  }, [THUserStore?.tutorialMinorStep, initialized])

  // Animation Control
  useInterval(
    () => {
      setCurrentSize((prev) => {
        if (prev >= highlightPosition.radius) {
          setAnimation(false)
          return highlightPosition.radius
        }
        return prev + 0.3
      })
    },
    initialized && animation ? 1 : null,
  )

  if (THUserStore?.tutorialMinorStep === stepsType?.length) {
    return null
  }

  return (
    <div
      id='TutorialLayer'
      style={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 30,
      }}
      onClick={() => {
        highlightPosition?.onClick()
      }}
    >
      <svg width='100%' height='100%'>
        <defs>
          {!turnOff && (
            <mask id='mask' x='0' y='0' width='100%' height='100%'>
              <rect x='0' y='0' width='100%' height='100%' fill='white' />
              {highlightPosition?.maskType === 'circle' ? (
                <circle
                  cx={highlightPosition.x}
                  cy={highlightPosition.y}
                  r={`${currentSize}%`}
                  fill='black'
                />
              ) : (
                <rect
                  x={0}
                  y={highlightPosition?.boxY ?? highlightPosition.y}
                  width='100%'
                  height={
                    highlightPosition?.boxHeight ?? highlightPosition.height
                  }
                  fill='black'
                />
              )}
            </mask>
          )}
        </defs>
        <rect
          x='0'
          y='0'
          width='100%'
          height='100%'
          fill='rgba(0, 0, 0, 0.85)'
          mask='url(#mask)'
        />
      </svg>
      {!turnOff && (
        <>
          {highlightPosition?.textOn && (
            <div
              className={`absolute z-[101] transition-all pointer-events-none left-1/2 ${
                textAnimationTrigger
                  ? 'duration-300 scale-100 translate-x-[-50%] '
                  : 'duration-150 scale-0 translate-x-[-50%] '
              }`}
              style={{
                // left: `${highlightPosition.textX}vw`,
                top: `${highlightPosition.y}px`,
                marginTop: `${highlightPosition.textY}${
                  highlightPosition?.isVH ? 'vh' : 'vw'
                }`,
              }}
            >
              <div className='default pointer-events-none justify-center items-center absolute w-[90vw] left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 '>
                <div
                  className='default pointer-events-none text-center font-bold text-white default justify-center items-center h-[full] text-[5vw] border-2 rounded-full py-2'
                  style={{
                    width: '100%',
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'keep-all',
                    wordWrap: 'break-word',
                    overflowWrap: 'break-word',
                  }}
                >
                  {`${tutorialText}`}
                </div>
              </div>
            </div>
          )}
          {highlightPosition.fingerOn && (
            <img
              className={`${
                textAnimationTrigger ? 'flex' : 'hidden'
              } absolute z-[101] w-[15vw] pulsate-fwd pointer-events-none`}
              alt='txt'
              src='../images/treasureHunt/finger.png'
              style={{
                left: `${highlightPosition.x + highlightPosition.fingerLeft}px`,
                top: `${highlightPosition.y + highlightPosition.fingerTop}px`,
              }}
            />
          )}
          {highlightPosition?.buttonOn && (
            <TreasureHuntButton
              className={`pointer-events-none absolute w-[24vw] h-[12vw] -translate-x-1/2 -translate-y-1/2 ${
                textAnimationTrigger
                  ? 'duration-300 scale-100 '
                  : 'duration-150 scale-0 '
              }`}
              style={{
                left: `${50}%`,
                top: `${highlightPosition.buttonY}vw`,
              }}
              text={highlightPosition?.buttonText}
              clickable
              onClick={() => {
                // nextStep()
              }}
            />
          )}
        </>
      )}
    </div>
  )
})

export default THTutorialSecond
