import React from 'react'

function GaugeBarPercentage({ currentExp, totalExp }) {
  return (
    <div className='text-white gauge-percentage' style={{}}>
      {`${((1 - currentExp / totalExp) * 100).toFixed(2)}% 채우고 돈봉투 받기`}
    </div>
  )
}

export default GaugeBarPercentage
