import { observer } from 'mobx-react-lite'
import React from 'react'

import THBottomSheetStore from '../../../stores/treasureHunt/THBottomSheetStore'
import THUserStore from '../../../stores/treasureHunt/THUserStore'

const CollectionButton = observer(({ className }) => {
  return (
    <div
      className={`relative pointer-events-auto flex justify-center items-center rounded-full ${className}`}
      onClick={() => {
        THBottomSheetStore?.setType({ type: 'collection', config: {} })
        THUserStore.set('collecitonPingOn', false)
        window.localStorage.setItem('collecitonPingOn', 'false')
      }}
    >
      <img
        className='absolute absolute-center w-[16vw] '
        src='../images/treasureHunt/bottomButtons/collection.png'
        alt='collection'
      />
      {THUserStore?.collecitonPingOn && (
        <img
          className='absolute -right-1 -top-1 w-4 h-4'
          src='../images/treasureHunt/ping.png'
          alt='ping'
        />
      )}
    </div>
  )
})

export default CollectionButton
