import commaNumber from 'comma-number'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import AnimatedNumbers from 'react-animated-numbers'
import { FiChevronLeft } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import { useSearchParam } from 'react-use'
import { Virtuoso } from 'react-virtuoso'

import CheckInExchangeHistoryComponent from '../../components/deal/item/checkInExchangeHistoryComponent'
import { YellowMoney } from '../../images/dailyCheckIn'
import AuthStore from '../../stores/AuthStore'
import UserStore from '../../stores/UserStore'
import backendApis from '../../utils/backendApis'

const CheckInHistoryScreen = observer(() => {
  const token = useSearchParam('token')
  const navigate = useNavigate()
  const SCREEN_HEIGHT = window.innerHeight
  const SCREEN_WIDTH = window.innerWidth

  const [isLoading, setIsLoading] = useState(false)
  const [cashbackHistoryData, setCashbackHistoryData] = useState()
  const [couponHistoryData, setCouponHistoryData] = useState()
  const [gifticonHistoryData, setGifticonHistoryData] = useState()
  const [stopLoad, setStopLoad] = useState(false)
  const [tabBarIndex, setTabBarIndex] = useState(0)
  const [accumulatedPoints, setAccumulatedPoints] = useState(0)
  const [animatePoints, setAnimatePoints] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 300)
    if (!AuthStore.token) {
      navigate(-1)
      // setOpenLoginModal(true)
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true)
      const result = await backendApis.getCashbackHistory(0)
      if (result?.status === 200) {
        setCashbackHistoryData(result?.data)
        if (result?.data?.length < 32) {
          setStopLoad(true)
        }
      }
      const result2 = await backendApis.getCheckInCouponHistory(0)
      if (result2?.status === 200) {
        setCouponHistoryData(result2?.data)
        if (result2?.data?.length < 32) {
          setStopLoad(true)
        }
      }
      const result3 = await backendApis.getGifticonExchangeHistory(0)
      if (result3?.status === 200) {
        setGifticonHistoryData(result3?.data)
        if (result3?.data?.length < 32) {
          setStopLoad(true)
        }
      }
      setIsLoading(false)
    }
    fetchData()
  }, [AuthStore.token])

  useEffect(() => {
    setTimeout(() => {
      setAnimatePoints(true)
      setAccumulatedPoints(UserStore?.accumulatedPoints)
      setTimeout(() => {
        setAnimatePoints(false)
      }, 3000)
    }, 200)
  }, [UserStore?.accumulatedPoints])

  // const onEndReached = async (type, loadedLogsNum) => {
  //   setIsLoading(true)
  //   if (type === 'point') {
  //     const result = await backendApis.getCashbackHistory(loadedLogsNum)
  //     if (result?.status === 200) {
  //       setCashbackHistoryData([...cashbackHistoryData, ...result?.data])
  //     }
  //     if (result?.data?.length < 32) {
  //       setStopLoad(true)
  //     }
  //   }
  //   if (type === 'coupon') {
  //     const result = await backendApis.getCheckInCouponHistory(loadedLogsNum)
  //     if (result?.status === 200) {
  //       setCouponHistoryData([...couponHistoryData, ...result?.data])
  //     }
  //     if (result?.data?.length < 32) {
  //       setStopLoad(true)
  //     }
  //   }
  //   if (type === 'gifticon') {
  //     const result = await backendApis.getGifticonExchangeHistory(loadedLogsNum)
  //     if (result?.status === 200) {
  //       setGifticonHistoryData([...gifticonHistoryData, ...result?.data])
  //     }
  //     if (result?.data?.length < 32) {
  //       setStopLoad(true)
  //     }
  //   }
  //   setIsLoading(false)
  // }

  const HeaderComponent = () => {
    return (
      <section className='relative z-0 flex flex-row items-center justify-between py-2 overflow-hidden'>
        <button
          type='button'
          className='px-3 py-2 whitespace-nowrap'
          onClick={() => {
            navigate(-1)
          }}
        >
          <FiChevronLeft className='w-8 h-8 stroke-gray-600' />
        </button>
        <div
          style={{ fontSize: '5vw' }}
          className='p-3 text-lg font-bold text-center text-gray-800'
        >
          교환 내역
        </div>
        <div className='w-10' />
      </section>
    )
  }

  const PointStatusComponent = () => {
    return (
      <div style={{ fontSize: '4.5vw' }} className='flex flex-row w-full'>
        <div className='flex flex-col w-full mx-6'>
          <div className='flex flex-col items-start px-6 py-4 mt-4 mb-6 font-bold bg-white rounded-xl'>
            <div className='flex flex-row justify-between w-full'>
              <div>보유한 올포인트</div>
            </div>
            <div
              style={{ fontSize: '7.6vw' }}
              className='flex flex-row items-center pt-1'
            >
              <YellowMoney className='w-8 mr-2' />
              {animatePoints ? (
                <AnimatedNumbers
                  includeComma
                  animateToNumber={accumulatedPoints || 0}
                  fontStyle={{ fontSize: '7.6vw' }}
                  configs={[{ mass: 1, tension: 320, friction: 70 }]}
                />
              ) : (
                commaNumber(accumulatedPoints || 0)
              )}
              <div className='ml-1'>P</div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const TabBarComponent = ({ text, index }) => {
    if (index === tabBarIndex) {
      return (
        <button
          type='button'
          style={{
            flex: 1,
            borderBottomWidth: 2,
            borderColor: '#FF3E3E',
          }}
          className='flex flex-row items-center justify-center py-3 text-base font-bold text-red-500 bg-white'
          onClick={() => {
            setTabBarIndex(index)
            setStopLoad(false)
          }}
        >
          {text}
        </button>
      )
    }
    return (
      <button
        type='button'
        style={{
          flex: 1,
          borderBottomWidth: 1,
          borderColor: '#E0E0E0',
        }}
        className='flex flex-row items-center justify-center py-3 text-base text-gray-500 bg-white'
        onClick={() => {
          setTabBarIndex(index)
        }}
      >
        {text}
      </button>
    )
  }

  return (
    <div className='flex flex-col h-full bg-[#F4DBC9]'>
      <HeaderComponent />
      <PointStatusComponent />
      <div className='flex flex-row pt-3'>
        <TabBarComponent text='캐시백 적립' index={0} />
        <TabBarComponent text='쿠폰 교환' index={1} />
        <TabBarComponent text='기프티콘 교환' index={2} />
      </div>
      <div className='w-full h-full'>
        {tabBarIndex === 0 && cashbackHistoryData && (
          <Virtuoso
            data={cashbackHistoryData || []}
            // endReached={onEndReached}
            itemContent={(index, data) => {
              return (
                <CheckInExchangeHistoryComponent
                  type='cashback'
                  title={
                    data?.orderInfo?.itemInfo?.itemTitle || '캐시백 상품 구매'
                  }
                  // exchangeData={JSON.stringify(data.cashbackLog)}
                  exchangedAt={data?.cashbackLog?.checkedAt}
                  // explanation={data.cashbackLog.couponInfo.origin}
                  point={data?.cashbackLog?.point}
                />
              )
            }}
            style={{
              height: SCREEN_HEIGHT,
              backgroundColor: 'white',
            }}
          />
        )}
        {tabBarIndex === 0 && !cashbackHistoryData && (
          <div>
            <img
              src='https://assets.ilevit.com/847a2547-00d4-4fc8-ac7c-e3357714690a.png'
              alt='explanation'
              style={{ width: SCREEN_WIDTH, height: SCREEN_WIDTH / 0.343 }}
            />
          </div>
        )}
        {tabBarIndex === 1 && couponHistoryData && (
          <Virtuoso
            data={couponHistoryData || []}
            // endReached={onEndReached}
            itemContent={(index, data) => {
              return (
                <CheckInExchangeHistoryComponent
                  type='coupon'
                  title={data?.receivedCoupons?.couponInfo?.couponTitle}
                  exchangeData={data?.receivedCoupons}
                  exchangedAt={data?.receivedCoupons?.receivedAt}
                  explanation={data?.receivedCoupons?.couponInfo?.origin}
                  point={data?.receivedCoupons?.couponInfo?.value}
                />
              )
            }}
            style={{
              height: SCREEN_HEIGHT,
              backgroundColor: 'white',
            }}
          />
        )}
        {tabBarIndex === 1 && !couponHistoryData && (
          <div className='p-10 text-center text-gray-500 bg-white'>
            쿠폰 교환 내역이 없어요
          </div>
        )}
        {tabBarIndex === 2 && gifticonHistoryData && (
          <Virtuoso
            data={gifticonHistoryData || []}
            // endReached={onEndReached}
            itemContent={(index, data) => {
              return (
                <CheckInExchangeHistoryComponent
                  type='gifticon'
                  title={data.itemTitle}
                  exchangeData={data}
                  exchangedAt={data.createdAt}
                  explanation={data.sentAt}
                  point={data.usedPoint}
                />
              )
            }}
            style={{
              height: SCREEN_HEIGHT,
              backgroundColor: 'white',
            }}
          />
        )}
        {tabBarIndex === 2 && !gifticonHistoryData && (
          <div className='p-10 text-center text-gray-500 bg-white'>
            기프티콘 교환 내역이 없어요
          </div>
        )}
      </div>
    </div>
  )
})

export default CheckInHistoryScreen
