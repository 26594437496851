import { Button, Spinner } from '@material-tailwind/react'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'

import { LockedGifticonList } from '../../../images/dailyCheckIn'
import AuthStore from '../../../stores/AuthStore'
import UserStore from '../../../stores/UserStore'
import AB from '../../../utils/ab'
import backendApis from '../../../utils/backendApis'
import GifticonItemComponent from '../item/gifticonItemComponent'
import VerticalList from '../item/verticalList'

const gifticonItemSection = () => {
  const [gifticonInfo, setGifticonInfo] = useState([])
  const [loadNum, setLoadNum] = useState(0)
  const [reloadToggle, setReloadToggle] = useState(false)
  const SCREEN_HEIGHT = window.innerHeight
  const costAB = AB(UserStore?.userInfo._id, 'gifti1221', [2])

  const fetchItems = async () => {
    const itemsInfoList = await backendApis.getGifticonItems(0, 'checkIn')
    setGifticonInfo(itemsInfoList?.data)
    setLoadNum(loadNum + itemsInfoList?.data?.length)
  }

  // const renderItem = (index, data) => {
  //   if (!data) return <GifticonItemComponent key={`${index}`} empty />
  //   return <GifticonItemComponent key={`${index}`} itemInfo={data} />
  // }

  useEffect(() => {
    if (AuthStore.token) {
      fetchItems(0)
    }
  }, [AuthStore.token, reloadToggle])

  const LockedGifticonListComponent = () => {
    return (
      <div className='px-4 py-2 relative'>
        <LockedGifticonList className='w-full h-full' />
        <div className='absolute top-[38vw] left-[36vw] text-[6vw] font-bold'>
          VIP 교환소
        </div>
        <div className='absolute top-[54vw] left-[24vw] text-[5vw] font-bold'>
          30일 내 결제한 주문건 수:
        </div>
        <div className='absolute top-[62vw] left-[44vw] text-[6vw] font-bold'>
          0건
        </div>
        <div className='absolute top-[80vw] left-[24vw] text-[4vw] text-gray-500 font-bold'>
          이번달엔 1건만 채워도 VIP가 돼요
        </div>
        <div className='absolute top-[86vw] left-[32vw] text-[2.8vw] text-gray-500 font-bold'>
          * 배송 완료된 주문만 적용돼요
        </div>
      </div>
    )
  }

  const NormalGifticonListComponent = () => {
    return (
      <div className='px-4 py-2 grid grid-cols-2'>
        {gifticonInfo?.map((item) => (
          <GifticonItemComponent key={item?._id} itemInfo={item} />
        ))}
      </div>
    )
  }

  const CostEfficientGifticonListComponent = () => {
    return (
      <>
        <div className='pl-6 pt-4 flex justify-start font-bold text-[4vw] text-[#9898A3] text-start'>
          VIP 고객님을 위한 기프티콘 교환소
        </div>
        {!UserStore?.cherryPicker && <NormalGifticonListComponent />}
        {UserStore?.cherryPicker && <LockedGifticonListComponent />}
      </>
    )
  }
  return (
    <div className='bg-white'>
      {gifticonInfo?.length && (
        <div>
          {costAB === 'b' ? (
            <CostEfficientGifticonListComponent />
          ) : (
            <NormalGifticonListComponent />
          )}
        </div>
      )}
      {!gifticonInfo && (
        <div className='flex flex-col items-center justify-start h-screen'>
          <div className='flex flex-row items-center justify-center mt-20 text-lg font-bold text-black animate-pulse'>
            <Spinner className='w-4 h-4' />
            <span className='ml-2'>상품 정보를 불러오는 중입니다.</span>
          </div>
          <Button
            onClick={() => setReloadToggle(!!reloadToggle)}
            className='mt-2'
            color='gray'
          >
            새로고침
          </Button>
        </div>
      )}
      {/* {gifticonInfo?.length > 0 ? (
        <div className='px-4 py-2'>
          <VerticalList
            itemData={gifticonInfo}
            height={SCREEN_HEIGHT}
            renderItem={renderItem}
            isFrom='CheckInMain'
            isItemList
            columnCount={2}
          />
        </div>
      ) : (
        <div className='flex flex-col items-center justify-start h-screen'>
          <div className='flex flex-row items-center justify-center mt-20 text-lg font-bold text-blue-500 animate-pulse'>
            <Spinner className='w-4 h-4' />
            <span className='ml-2'>상품 정보를 불러오는 중입니다.</span>
          </div>
          <Button
            onClick={() => setReloadToggle(!!reloadToggle)}
            className='mt-2'
            color='gray'
          >
            새로고침
          </Button>
        </div>
      )} */}
    </div>
  )
}
export default observer(gifticonItemSection)
