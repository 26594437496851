// hooks/useChapterData.js
import moment from 'moment'
import { useEffect, useState } from 'react'

import ToastStore from '../../stores/ToastStore'
import backendApis from '../../utils/backendApis'

export default function useChapterData(chapterId, toonInfo, episode) {
  const [state, setState] = useState({
    chapter: null,
    previousChapter: null,
    nextChapter: null,
    viewLog: null,
    isLoading: true,
    error: null,
  })

  useEffect(() => {
    const fetchData = async () => {
      try {
        setState((prev) => ({ ...prev, isLoading: true }))
        const chapterData = await backendApis.loadChapter(chapterId)
        const previousChapterData =
          episode > 1
            ? await backendApis.loadChapterByEpisode(
                toonInfo._id,
                episode - 2, // 서버에서 +1을 해주기 때문에 -2로 설정
              )
            : undefined

        const nextChapterData = await backendApis.loadChapterByEpisode(
          toonInfo._id,
          episode,
        )

        const isAvailable =
          moment().isAfter(nextChapterData?.data?.publishedAt) ||
          moment().isAfter(
            moment(nextChapterData?.data?.publishedAt).subtract(8, 'days'),
          )

        // console.log('nextChapterData: ', nextChapterData?.data)

        const viewLog = await backendApis.loadToonViewLog(toonInfo?._id)

        // console.log('isAvailable: ', isAvailable)

        setState({
          chapter: chapterData?.data,
          previousChapter: previousChapterData?.data,
          nextChapter: isAvailable ? nextChapterData?.data : null,
          viewLog: viewLog?.data,
          isLoading: false,
          error: null,
        })
      } catch (error) {
        ToastStore.toastOn({
          type: 'error',
          message: '알 수 없는 오류가 발생했어요.\n 잠시후 다시 시도해주세요',
          duration: 2000,
        })
        setState({
          chapter: null,
          previousChapter: null,
          nextChapter: null,
          viewLog: null,
          isLoading: false,
          error: error.message,
        })
      }
    }

    fetchData()
  }, [chapterId, toonInfo, episode])

  return state
}
