import moment from 'moment'
import React from 'react'
import { FiChevronRight } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'

import AltoonUserStore from '../../stores/AltoonUserStore'
import backendApis from '../../utils/backendApis'
import resize from '../../utils/resize'

const MypageToonComponent = ({
  toon = {},
  index = {},
  type = 'viewed',
  viewedData = {},
  isEmpty = false,
}) => {
  const navigate = useNavigate()
  const onClickHandler = async () => {
    if (toon?.isAdultOnly) {
      if (AltoonUserStore.userData.isAdult === false) {
        alert('성인 작품입니다.')
        return
      }
      if (AltoonUserStore.userData.isAdult === true) {
        navigate(`/altoon-detail?toonId=${toon?._id}`)
      }
      if (!AltoonUserStore.userData.isAdult) {
        const authInfo = await backendApis.loadAuthInfo()
        if (authInfo?.status === 200) {
          const userData = await backendApis.loadUserData()
          AltoonUserStore.set('userData.isAdult', userData?.data?.isAdult)
          if (userData?.data?.isAdult === false) {
            alert('성인 작품입니다.')
            return
          }
          if (userData?.data?.isAdult === true) {
            navigate(`/altoon-detail?toonId=${toon?._id}`)
          }
        } else if (authInfo?.status === 201) {
          alert('성인 인증이 필요한 작품입니다.')
          window.location.href = `#navigate.${JSON.stringify({
            screen: 'SelfAuthenticationScreen',
            prop: {
              onSuccess: async () => {
                navigate(-1)
              },
              benefitType: 'toon',
              defaultSuccessHandler: true,
            },
          })}`
        }
      }
      return
    }

    navigate(`/altoon-detail?toonId=${toon?._id}`)
  }

  if (isEmpty) {
    return (
      <div className='flex flex-col justify-center items-center py-[4vh] text-[5vw]'>
        <img
          src='/images/toon/emptyToonIcon.png'
          alt=''
          className='w-20 h-20 mb-2'
        />
        <div>
          {type === 'viewed' ? '최근 조회한' : '아직 좋아요를 누른'} 작품이
          없어요
        </div>
        <div className='py-2 text-[3.6vw] text-center text-gray-600'>
          {type === 'viewed' ? '많이 본' : '좋아요를 많이 누른'} 작품을 바탕으로{' '}
          <br /> 콘텐츠를 추천해드려요!
        </div>
      </div>
    )
  }
  if (type === 'viewed') {
    return (
      <button
        type='button'
        className='flex flex-row justify-between items-center py-[1vh] text-[3vw] relative'
        key={toon?._id || index}
        onClick={() => {
          onClickHandler()
        }}
      >
        <div className='flex flex-row '>
          <img
            src={resize(toon?.thumbnail, 187, 234, 'fill')}
            alt={toon?.title}
            className='w-[24vw] h-[30vw] rounded-lg mr-3 border border-gray-200'
          />
          {toon?.isAdultOnly && (
            <img
              src='/images/toon/isAdultIcon.png'
              alt='ticket'
              className='absolute top-[3vw] left-[1vw] w-[5vw] h-[5vw]'
            />
          )}
          <div className='py-3 flex flex-col justify-between items-start text-start'>
            <div>
              <h3 className='font-bold text-[3.8vw] pr-2 mb-1'>
                {toon?.title}
              </h3>
              <div className='text-gray-600'>
                <p>{toon?.author}</p>
              </div>
            </div>
            <div className='text-gray-600'>
              {moment(toon?.lastViewedAt).format('YYYY.MM.DD 열람')}
            </div>
          </div>
        </div>
        <div
          type='button'
          className='w-[16vw] flex flex-row items-center justify-end'
          onClick={() => {
            navigate(`/altoon-detail?toonId=${toon?._id}`)
          }}
        >
          이어보기
          <FiChevronRight className='w-3 h-3' />
        </div>
      </button>
    )
  }
  if (type === 'liked') {
    return (
      <button
        type='button'
        className='flex flex-row justify-between items-center py-[1vh] text-[3vw]'
        key={toon?._id || index}
        onClick={() => {
          onClickHandler()
        }}
      >
        <div className='flex flex-row relative'>
          <img
            src={resize(toon?.thumbnail, 187, 234, 'fill')}
            alt={toon?.title}
            className='w-[24vw] h-[30vw] rounded-lg mr-3 border border-gray-200'
          />
          {toon?.isAdultOnly && (
            <img
              src='/images/toon/isAdultIcon.png'
              alt='ticket'
              className='absolute top-[1vw] left-[1vw] w-[5vw] h-[5vw]'
            />
          )}
          <div className='py-3 flex flex-col justify-between items-start text-start'>
            <div>
              <h3 className='font-bold text-[3.8vw] pr-2 mb-1'>
                {toon?.title}
              </h3>
              <div className='text-gray-600'>
                <p>{toon?.author}</p>
              </div>
            </div>
          </div>
        </div>
        <div className='w-[16vw] flex flex-row items-center justify-end'>
          이어보기
          <FiChevronRight className='w-3 h-3' />
        </div>
      </button>
    )
  }
}
export default MypageToonComponent
