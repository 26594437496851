import commaNumber from 'comma-number'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { FiChevronLeft } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import { useSearchParam } from 'react-use'

import ConfirmAlert from '../../components/atoms/confirmAlert'
import ImageAtom from '../../components/atoms/imageAtom'
import LoadingIndicator from '../../components/atoms/loadingIndicator'
import {
  GiftBoxClosed,
  GifticonRecoverFailed,
  GifticonRecovering,
  GifticonUpgradeExplain,
  GifticonUpgradeFailed,
  GifticonUpgrading,
  MoneyTreeBackgroundPing,
  PlusIcon,
  RedRoundCheckIcon,
  UpperArrowIcon,
} from '../../images/dailyCheckIn'
import AuthStore from '../../stores/AuthStore'
import backendApis from '../../utils/backendApis'

let isClicked = false

const CheckInGifticonUpgradeScreen = observer(() => {
  const navigate = useNavigate()
  const drawId = useSearchParam('drawId')
  const [isLoading, setIsLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [animate, setAnimate] = useState(false)
  const [upgradeResult, setUpgradeResult] = useState()
  const [openErrorModal, setOpenErrorModal] = useState(false)
  const [recoveryModal, setRecoveryModal] = useState(false)
  const [drawHistory, setDrawHistory] = useState()
  const [currentDrawData, setCurrentDrawData] = useState()
  const [selectedMaterial, setSelectedMaterial] = useState()
  const [selectedMaterialDrawInfo, setSelectedMaterialDrawInfo] = useState()
  const [nextLevelGifticon, setNextLevelGifticon] = useState()
  const [nextLevelProbability, setNextLevelProbability] = useState()

  useEffect(() => {
    setIsLoading(true)
    setTimeout(() => {
      setIsLoading(false)
    }, 300)
    if (!AuthStore.token) {
      navigate(-1)
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      const result = await backendApis.getGifticonDrawHistory(0)

      if (result?.status === 200) {
        setDrawHistory(result?.data)
        setCurrentDrawData(result?.data?.find((item) => item?._id === drawId))

        const fetchLevelData = async () => {
          const levelData = await backendApis.gifticonLevel()
          if (levelData?.status === 200) {
            const levels = [
              'level1',
              'level2',
              'level3',
              'level4',
              'level5',
              'level6',
            ] // 필요한 모든 레벨을 포함
            let foundLevel = null

            for (const currentLevel of levels) {
              const levelItemData = levelData?.data?.gifticonLevel[currentLevel]
              if (levelItemData) {
                const targetGifticonId = levelItemData.toString()
                const currentItemId = result?.data
                  ?.find((item) => item?._id === drawId)
                  ?.targetGifticon?._id.toString()

                if (targetGifticonId.includes(currentItemId)) {
                  foundLevel = currentLevel
                  break
                }
              }
            }
            const currentLevelIndex = levels.indexOf(foundLevel)
            const nextLevelIndex = currentLevelIndex + 1
            const nextLevel =
              nextLevelIndex < levels.length ? levels[nextLevelIndex] : null
            const nextLevelItem = await backendApis.getGifticonItemById(
              levelData?.data?.gifticonLevel[nextLevel][0],
            )

            setNextLevelGifticon(nextLevelItem?.data)
            setNextLevelProbability(
              levelData?.data?.upgradeProbability[foundLevel],
            )
          }
        }
        fetchLevelData()
      }
    }

    setIsLoading(true)
    if (AuthStore.token) {
      fetchData()
    }
    setIsLoading(false)
  }, [AuthStore.token])

  const handleUpgradeButton = () => {
    if (isClicked) return

    return async () => {
      isClicked = true
      setAnimate(true)
      setShowModal(true)

      const result = await backendApis.upgradeGifticon(
        currentDrawData,
        selectedMaterialDrawInfo,
      )

      setTimeout(() => {
        setAnimate(false)
        if (result?.status === 200) {
          window.location.href = '#vibrate.'
        }
      }, 3500)

      setUpgradeResult(result?.data)
      if (result?.status === 400) {
        // 기프티콘 데이터 오류
        setOpenErrorModal(true)
      } else {
        setShowModal(true)
      }
      isClicked = false
    }
  }

  const HeaderComponent = () => {
    return (
      <section className='relative z-0 flex flex-row items-center justify-between py-2 overflow-hidden'>
        <button
          type='button'
          className='px-3 py-2 whitespace-nowrap'
          onClick={() => {
            navigate(-1)
          }}
        >
          <FiChevronLeft className='w-8 h-8 stroke-gray-600' />
        </button>
        <div
          style={{ fontSize: '5vw' }}
          className='p-3 text-lg font-bold text-center text-gray-800'
        >
          기프티콘 강화하기
        </div>
        <div className='w-10' />
      </section>
    )
  }

  const GifticonItemCardComponent = ({ itemInfo, drawInfo, empty, type }) => {
    if (empty) {
      return (
        <div className='flex flex-col items-center mx-2 text-[4.2vw] text-[#B7B0B0] font-bold'>
          <div className='w-[30vw] h-[30vw] mb-2 border-[1vw] bg-[#E2D7D7] border-[#D5C7C7] rounded-lg' />
          <div className='-mb-1'>기프티콘을</div>
          <div>선택하세요</div>
        </div>
      )
    }
    if (type === 'result') {
      return (
        <button
          type='button'
          className='flex flex-row items-center justify-around w-full mx-2 p-4 text-[4.2vw] font-bold relative'
          disabled={type !== 'list'}
          onClick={() => {
            setSelectedMaterial(itemInfo)
            setSelectedMaterialDrawInfo(drawInfo)
          }}
        >
          {/* 이미지 */}
          <ImageAtom
            src={itemInfo?.mainImageUri}
            className='w-[30vw] h-[30vw] m-4'
          />
          <div className='w-full h-[30vw] flex flex-col items-start justify-between'>
            <div className='flex flex-col items-start '>
              <div>
                {itemInfo?.itemTitle.length > 10
                  ? `${itemInfo?.itemTitle.slice(0, 10)}..`
                  : itemInfo?.itemTitle}
              </div>
              <div className='flex flex-row text-[3.4vw] font-normal'>
                <div className='text-[#B1B3B5] mr-2'>
                  {commaNumber(itemInfo?.individualPurchasePrice) || 0}원
                </div>
                {itemInfo?.individualPurchasePrice -
                  2 * drawInfo?.targetGifticon?.individualPurchasePrice >
                  0 && (
                  <div className='text-[#FF5951]'>
                    {itemInfo?.individualPurchasePrice -
                      2 * drawInfo?.targetGifticon?.individualPurchasePrice}
                    원 이득!
                  </div>
                )}
              </div>
            </div>
            <div className='px-3 py-1 rounded-full bg-red-500 text-white text-[3.4vw]'>
              성공 확률 {nextLevelProbability || '??'}%
            </div>
          </div>
        </button>
      )
    }
    if (type === 'upgradeResult') {
      return (
        <div
          className='flex flex-col items-center mx-2 py-2 text-[4.2vw] font-bold relative'
          disabled={type !== 'list'}
        >
          {/* 이미지 */}
          <ImageAtom
            src={itemInfo?.mainImageUri}
            className='w-[40vw] h-[40vw] mb-2 rounded-lg border-0 animate-pulse'
          />

          <MoneyTreeBackgroundPing
            style={{
              position: 'absolute',
            }}
            className='w-full animate-ping '
          />

          <div>
            {itemInfo?.itemTitle.length > 20
              ? `${itemInfo?.itemTitle.slice(0, 20)}..`
              : itemInfo?.itemTitle}
          </div>
          <div className='text-[3.4vw] mb-1 font-semibold'>
            {commaNumber(itemInfo?.individualPurchasePrice) || 0}원
          </div>
          {itemInfo?.individualPurchasePrice -
            2 * drawInfo?.targetGifticon?.individualPurchasePrice >
            0 && (
            <div className='text-[#FF5951]'>
              {itemInfo?.individualPurchasePrice -
                2 * drawInfo?.targetGifticon?.individualPurchasePrice}
              원 이득!
            </div>
          )}
        </div>
      )
    }
    if (itemInfo?._id) {
      const selectedItem = selectedMaterialDrawInfo?._id === drawInfo?._id
      return (
        <button
          type='button'
          className='flex flex-col items-center mx-2 py-2 text-[4.2vw] font-bold relative'
          disabled={type !== 'list'}
          onClick={() => {
            setSelectedMaterial(itemInfo)
            setSelectedMaterialDrawInfo(drawInfo)
          }}
        >
          {/* 이미지 */}
          <ImageAtom
            src={itemInfo?.mainImageUri}
            className={`${
              !selectedItem && type === 'list'
                ? 'border-red-100'
                : 'border-red-500'
            } w-[30vw] h-[30vw] mb-2 border-[1vw] rounded-lg
            `}
          />

          {/* 아이콘 */}
          {selectedItem && (
            <RedRoundCheckIcon className='absolute top-0 w-8 h-8 -right-2' />
          )}

          <div>
            {itemInfo?.itemTitle.length > 6
              ? `${itemInfo?.itemTitle.slice(0, 6)}..`
              : itemInfo?.itemTitle}
          </div>
          <div className='text-[3.4vw] mb-1 font-semibold'>
            {commaNumber(itemInfo?.individualPurchasePrice) || 0}원
          </div>
          {type === 'list' && (
            <div className='text-[2.4vw] text-[#B1B3B5] font-normal'>
              유효기간: {moment(drawInfo?.expiresAt).format('YY.MM.DD')}
            </div>
          )}
        </button>
      )
    }
  }

  const UpgradeResultComponent = ({ nextLevelGifticon }) => {
    if (!nextLevelGifticon || !selectedMaterial?._id) {
      return (
        <div className='flex flex-col items-center py-4 mx-8 mb-4 font-bold bg-white rounded-lg'>
          <GifticonUpgradeExplain className='w-full h-full mb-2' />더 좋은
          기프티콘으로 강화해보세요!
        </div>
      )
    }
    if (nextLevelGifticon && selectedMaterial?._id) {
      return (
        <div className='flex flex-col items-center py-4 mx-8 mb-4 font-bold bg-white rounded-lg'>
          <GifticonItemCardComponent
            itemInfo={nextLevelGifticon}
            drawInfo={drawHistory?.find(
              (item) => item?._id === currentDrawData?._id,
            )}
            type='result'
          />
        </div>
      )
    }
  }

  const UpgradeMaterialComponent = () => {
    return (
      <>
        <UpperArrowIcon className='absolute left-[22vw] w-[6.4vw] h-[8vw]' />

        <div className='flex flex-row items-center justify-between mx-8 mt-10 rounded-lg'>
          {currentDrawData?._id && (
            <GifticonItemCardComponent
              itemInfo={currentDrawData?.targetGifticon}
              drawInfo={drawHistory?.find(
                (item) => item?._id === currentDrawData?._id,
              )}
            />
          )}
          <PlusIcon className='w-8 h-8' />
          {selectedMaterial?._id && (
            <GifticonItemCardComponent
              itemInfo={selectedMaterial}
              drawInfo={drawHistory}
            />
          )}
          {!selectedMaterial?._id && <GifticonItemCardComponent empty />}
        </div>
      </>
    )
  }

  const UpgradeButton = ({ selectedMaterial, currentDrawData }) => {
    return (
      <>
        <button
          type='button'
          className={`
        py-4 mx-8 mt-6 font-bold rounded-lg
        ${
          !selectedMaterial?._id || !currentDrawData || isClicked
            ? 'bg-gray-200 text-gray-600'
            : 'bg-red-500 text-white'
        }
        `}
          onClick={handleUpgradeButton()}
          disabled={!selectedMaterial?._id || !currentDrawData || isClicked}
        >
          강화하기
        </button>
        <div className='flexCol py-2 mb-2 text-[2.8vw] text-gray-500'>
          강화를 실패하면 사용한 기프티콘들이 소멸돼요
        </div>
      </>
    )
  }

  const CurrentGifticonList = () => {
    const filteredDrawHistory = drawHistory?.filter(
      (item) =>
        item?._id !== drawId &&
        item?.targetGifticon?._id === currentDrawData?.targetGifticon?._id,
    )
    return (
      <div className='w-full px-4 py-6 overflow-hidden bg-white rounded-t-2xl'>
        <div className='pl-2 text-[5vw] font-bold'>기프티콘을 선택하세요</div>
        <div className='pl-2 text-[4vw] text-gray-500'>
          같은 기프티콘을 합치면 더 좋은 기프티콘이 나와요
        </div>
        {drawHistory?.length > 0 && (
          <div className='w-[92vw] py-4 grid grid-cols-3'>
            {filteredDrawHistory.map((item) => (
              <GifticonItemCardComponent
                key={item?._id}
                drawInfo={item}
                itemInfo={{
                  ...item.targetGifticon,
                }}
                type='list'
              />
            ))}
          </div>
        )}
        {filteredDrawHistory?.length === 0 && (
          <div className='py-8 w-full bg-gray-100 rounded-lg items-center text-[5vw] text-gray-600 text-center'>
            보관중인 동일한 기프티콘이 없어요
          </div>
        )}
      </div>
    )
  }

  const UpgradeResultModal = ({ type }) => {
    if (type === 'recover') {
      return (
        <>
          <div className='fixed top-0 left-0 z-20 w-full h-full bg-gray-800 opacity-70' />
          <div className='absolute top-[20vh] left-[10vw] z-30 w-4/5 h-[50vh] bg-white font-bold flex flex-col items-center justify-between rounded-xl'>
            <div className='px-8 py-4 mb-2 text-[4vw] font-medium '>
              <div className='mb-4 text-[6vw] font-bold text-center'>
                {animate ? '복구 중..' : '복구 결과'}
              </div>
            </div>
            {animate && (
              <GifticonRecovering className='w-[40vw] mb-10 object-contain' />
            )}
            {!animate && upgradeResult?.status === 201 && (
              <>
                <div className='flex flex-col items-center py-4 mx-8 mb-4 font-bold bg-white rounded-lg'>
                  <GifticonItemCardComponent
                    itemInfo={currentDrawData?.targetGifticon}
                    drawInfo={drawHistory?.find(
                      (item) => item?._id === currentDrawData?._id,
                    )}
                    type='upgradeResult'
                  />
                  <div>운이 좋으시네요!</div>
                  <div>2개 중 1개를 복구했어요</div>
                </div>
              </>
            )}
            {!animate && upgradeResult?.status === 202 && (
              <div className='flex flex-col items-center py-4 mx-8 mb-4 font-bold bg-white rounded-lg'>
                <GifticonRecoverFailed className='w-[40vw] mb-10' />
                <div>복구에 실패했어요</div>
              </div>
            )}

            <div className='w-full'>
              <button
                type='button'
                className='w-full bg-[#EB4F47] hover:bg-red-700 transition ease-in duration-200 py-4 text-white rounded-b-lg'
                disabled={animate}
                onClick={async () => {
                  navigate(-1)
                }}
              >
                확인
              </button>
            </div>
          </div>
        </>
      )
    }
    return (
      <>
        <div className='fixed top-0 left-0 z-20 w-full h-full bg-gray-800 opacity-70' />
        <div className='absolute top-[20vh] left-[10vw] z-30 w-4/5 h-[50vh] bg-white font-bold flex flex-col items-center justify-between rounded-xl'>
          <div className='px-8 py-4 mb-2 text-[4vw] font-medium '>
            <div className='mb-4 text-[6vw] font-bold text-center'>
              {animate && '강화 중..'}
              {!animate && upgradeResult?.status === 200 && (
                <div>강화 성공!</div>
              )}
              {!animate && upgradeResult?.status !== 200 && (
                <div>강화 실패</div>
              )}
            </div>
          </div>
          {animate && <GifticonUpgrading className='w-[60vw] mb-10' />}
          {!animate && upgradeResult?.status === 200 && (
            <div className='flex flex-col items-center py-4 mx-8 mb-4 font-bold bg-white rounded-lg'>
              <GifticonItemCardComponent
                itemInfo={nextLevelGifticon}
                drawInfo={drawHistory?.find(
                  (item) => item?._id === currentDrawData?._id,
                )}
                type='upgradeResult'
              />
            </div>
          )}
          {!animate && upgradeResult?.status !== 200 && (
            <div className='flex flex-col items-center py-4 mx-8 mb-4 font-bold bg-white rounded-lg'>
              <GifticonUpgradeFailed className='w-[40vw] mb-10' />
              <div>강화에 실패해서 기프티콘이 사라졌어요</div>
            </div>
          )}

          <div className='w-full'>
            <button
              type='button'
              className='w-full bg-[#EB4F47] hover:bg-red-700 transition ease-in duration-200 py-4 text-white rounded-b-lg'
              disabled={animate}
              onClick={async () => {
                if (!animate && upgradeResult?.status !== 200) {
                  setShowModal(false)
                  setRecoveryModal(true)
                  setAnimate(true)
                  setTimeout(() => {
                    setAnimate(false)
                    if (upgradeResult?.status === 201) {
                      window.href = '#vibrate.'
                    }
                  }, 3500)
                } else {
                  navigate(-1)
                }
              }}
            >
              {!animate && upgradeResult?.status !== 200
                ? '복구 시도해보기'
                : '확인'}
            </button>
          </div>
        </div>
      </>
    )
  }

  if (isLoading) {
    return <LoadingIndicator />
  }

  return (
    <div
      className={`${
        showModal ? 'fixed' : ''
      } flex flex-col h-full bg-[#FFF4F4]`}
    >
      <HeaderComponent />
      <div className='h-[4vw] w-full' />
      <UpgradeResultComponent nextLevelGifticon={nextLevelGifticon} />
      <UpgradeMaterialComponent />
      <UpgradeButton
        selectedMaterial={selectedMaterial}
        currentDrawData={currentDrawData}
      />

      <CurrentGifticonList />
      {showModal && <UpgradeResultModal type='result' />}
      {recoveryModal && <UpgradeResultModal type='recover' />}
      {openErrorModal && (
        <ConfirmAlert
          modalTitle='연결이 불안정해요'
          modalContent='인터넷 연결이 불안정해요. 잠시 후 다시 시도해주세요.'
          buttonText='확인'
          buttonLink={() => {
            window.location.href = `#navigate.${JSON.stringify({
              screen: 'MainALargeCategoryTabNavigator',
            })}`
            setOpenErrorModal(false)
          }}
          showModal
          setShowModal={setOpenErrorModal}
          onlyOneButton
        />
      )}
    </div>
  )
})

export default CheckInGifticonUpgradeScreen
