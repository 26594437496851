import { Button, Spinner } from '@material-tailwind/react'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import useThrottle from '../../../hooks/useThrottle'
import { GiftBoxClosed, GiftBoxOpened } from '../../../images/dailyCheckIn'
import AuthStore from '../../../stores/AuthStore'
import UserStore from '../../../stores/UserStore'
import backendApis from '../../../utils/backendApis'
import timeUtils from '../../../utils/timeUtils'
import ConfirmAlert from '../../atoms/confirmAlert'
import ImageAtom from '../../atoms/imageAtom'
import LoadingIndicator from '../../atoms/loadingIndicator'
import BasicItemCard from '../item/itemCard/BasicItemCard'
import DrawItemCard from '../item/itemCard/DrawItemCard'

const GiftModal = () => {
  return (
    <div className='flexCol w-full h-[98vw] z-40'>
      <GiftBoxClosed className='shaking w-[20vw] h-[20vw] object-contain' />
    </div>
  )
}

const GiftOpenedModal = () => {
  return (
    <div className='flexCol w-full h-[98vw] z-40'>
      <GiftBoxOpened className='w-[50vw] object-contain' />
    </div>
  )
}

const getRandomGifticon = (gifticonInfo, gifticonLevelInfo) => {
  if (!gifticonInfo || gifticonInfo?.length === 0) {
    return {}
  }
  const randomNum = Math.random()
  const levelInfo = gifticonLevelInfo?.drawProbability500

  let target = 0
  for (let i = 0; i < levelInfo?.length; i += 1) {
    if (randomNum < Number(levelInfo[i]?.p)) {
      target = levelInfo[i]?.level
      break
    }
  }

  const candidates = gifticonLevelInfo.gifticonLevel[`level${target}`]
  const targetGifticonId = candidates[0]
  const matchingGifticon = gifticonInfo.find(
    (item) => item._id === targetGifticonId,
  )

  return matchingGifticon
}

const gifticonItemSection = () => {
  const [gifticonInfo, setGifticonInfo] = useState([])
  const [gifticonLevelInfo, setGifticonLevelInfo] = useState({})
  const [reloadToggle, setReloadToggle] = useState(false)
  const [noticeModalInfo, setNoticeModalInfo] = useState({
    title: '',
    content: '',
    onClick: () => {},
  })
  const [openNoticeModal, setOpenNoticeModal] = useState(false)
  const point = 500
  const [disabled, setDisabled] = useState(
    UserStore?.accumulatedPoints < point ?? true,
  )
  const [isLoading, setIsLoading] = useState(true)
  const throttledOnClick = useThrottle()
  const navigate = useNavigate()

  const fetchItems = async () => {
    // 기프티콘 정보 호출
    setIsLoading(true)
    const gifticon = await backendApis.getGifticonItems(0, 'checkInDraw')
    if (gifticon?.status === 200) {
      setGifticonInfo([...gifticon?.data, ...gifticon?.data, ...gifticon?.data])
      // 기프티콘 레벨 정보 호출
      const level = await backendApis.gifticonLevel()
      if (level?.status === 200) {
        setGifticonLevelInfo(level?.data)
        setIsLoading(false)
      }
    }
  }

  useEffect(() => {
    if (AuthStore.token) {
      fetchItems()
    } else {
      setIsLoading(true)
    }
  }, [AuthStore.token, reloadToggle])

  const containerRef = useRef(null)

  useEffect(() => {
    const handleAnimationIteration = () => {
      // 애니메이션이 반복될 때(마지막 컴포넌트가 화면을 벗어난 후) 첫 번째 컴포넌트를 뒤에 이어서 보여줌
      containerRef.current.appendChild(
        containerRef.current.firstChild.cloneNode(true),
      )
      containerRef.current.removeChild(containerRef.current.firstChild)
    }

    const container = containerRef.current

    if (container) {
      container.addEventListener('animationiteration', handleAnimationIteration)
    }

    return () => {
      if (container) {
        container.removeEventListener(
          'animationiteration',
          handleAnimationIteration,
        )
      }
    }
  }, [])

  const openDrawStartModalButton = (disabled) => {
    if (disabled) {
      return
    }
    setNoticeModalInfo({
      title: '기프티콘을 뽑으시겠어요?',
      content: (
        <div className='flexCol w-full h-[80vw] z-40'>
          <GiftBoxClosed className='w-[50vw] h-[50vw] object-contain' />
          <div className='text-[4.4vw] font-semibold py-4'>
            꽝없는 기프티콘 뽑기!
          </div>
        </div>
      ),
      buttonText: '뽑기 시작하기',
      onClick: () => {
        handlePress(disabled)
      },
    })
    setOpenNoticeModal(true)
  }

  const handlePress = (disabled) => {
    throttledOnClick(() => onClickDraw(disabled), 4500)
  }

  const onClickDraw = async (disabled) => {
    if (disabled) {
      return
    }
    setDisabled(true)

    setNoticeModalInfo({
      title: '기프티콘 뽑는 중...',
      content: <GiftModal />,
      noButton: true,
    })
    setOpenNoticeModal(true)
    await timeUtils.sleep(3500)

    setNoticeModalInfo({
      title: '기프티콘 뽑기 완료!',
      content: <GiftOpenedModal />,
      noButton: true,
    })
    await timeUtils.sleep(1000)
    setOpenNoticeModal(false)
    processDraw(disabled)
  }

  const processDraw = async (disabled) => {
    if (disabled) {
      return
    }
    const targetGifticon = getRandomGifticon(gifticonInfo, gifticonLevelInfo)
    const result = await backendApis.drawGifticon(point, targetGifticon)
    if (result?.status === 200 && result?.data) {
      UserStore.setAccumulatedPoints(UserStore?.accumulatedPoints - point)
      setNoticeModalInfo({
        title: '뽑기 성공!',
        content: (
          <div className='w-full h-full flexCol'>
            <BasicItemCard item={targetGifticon} />
            축하드려요!
            <br />
            {`${targetGifticon?.itemTitle} 기프티콘을 뽑았어요. \n\n`}
            <br />
            뽑기함에서 확인해볼까요?
          </div>
        ),
        buttonText: '뽑기함 확인하기',
        leftButtonText: '닫기',
        onClick: () => {
          setOpenNoticeModal(false)
          setNoticeModalInfo({})
          navigate(`/check-in-draw-history`)
        },
      })
    } else if (result?.status === 401) {
      setNoticeModalInfo({
        title: '안내',
        content:
          '아직 기프티콘 뽑기를 처리중이에요. \n 잠시 후에 다시 시도해주세요',
        onlyOneButton: true,
        onClick: () => {
          setOpenNoticeModal(false)
          setNoticeModalInfo({})
        },
      })
    } else {
      setNoticeModalInfo({
        title: '안내',
        content:
          '인터넷 연결이 불안정하여 기프티콘 뽑기에 실패했어요. 다음에 다시 시도해 주세요',
        onlyOneButton: true,
        onClick: () => {
          setOpenNoticeModal(false)
          setNoticeModalInfo({})
        },
      })
    }
    setOpenNoticeModal(true)
    const close = UserStore?.accumulatedPoints < point
    setDisabled(close)
  }

  if (isLoading) {
    return <LoadingIndicator isLoading />
  }

  if (!gifticonInfo) {
    return (
      <div className='flex flex-col items-center justify-start h-screen'>
        <div className='flex flex-row items-center justify-center mt-20 text-lg font-bold text-black animate-pulse'>
          <Spinner className='w-4 h-4' />
          <span className='ml-2'>상품 정보를 불러오는 중입니다.</span>
        </div>
        <Button
          onClick={() => setReloadToggle(!!reloadToggle)}
          className='mt-2'
          color='gray'
        >
          새로고침
        </Button>
      </div>
    )
  }

  return (
    <div className='bg-gradient-to-b from-[#ffffff] via-[#FFEBEB] to-[#ffffff] pt-[10vw] relative px-4 py-2'>
      <div className='pl-2 pb-4 flex justify-start font-bold text-[4vw] text-[#9898A3] text-start'>
        100% 당첨되는 기프티콘 뽑기
      </div>
      <div className='w-full flex flex-col items-center pb-[10vw] relative'>
        <>
          <ImageAtom
            src='https://assets.ilevit.com/f079b478-2abe-4cfc-88a6-ab8359005310.png'
            className='absolute top-[12vw] z-10 w-[40vw] h-auto'
          />
          <div className='scroll-container z-20 pt-[40vw]' ref={containerRef}>
            {gifticonInfo?.length && (
              <div className='gifticon-list'>
                {gifticonInfo?.map((item) => (
                  <DrawItemCard
                    item={item}
                    key={item._id + Math.floor(Math.random() * 100)}
                  />
                ))}
              </div>
            )}
          </div>
        </>
        <button
          className='w-[92vw] h-[16vw] bg-[#FF5858] font-bold text-lg rounded-2xl'
          style={{
            backgroundColor: disabled ? '#E0E0E0' : '#FF5858',
            color: disabled ? '#9E9E9E' : '#FFFFFF',
          }}
          type='button'
          onClick={() => openDrawStartModalButton(disabled)}
        >
          {disabled ? `${point}P가 부족해요` : `${point}P로 뽑기`}
        </button>
        <button
          className='w-[92vw] h-[16vw] bg-[#fefefe] text-[#FF5858] font-bold text-lg rounded-2xl mt-[2vw] border-[#FF5858] border-[0.5vw]'
          type='button'
          onClick={async () => {
            navigate(`/check-in-draw-history?token=${AuthStore.token}`)
          }}
        >
          뽑기함 이동
        </button>
      </div>
      {openNoticeModal && noticeModalInfo?.title && (
        <ConfirmAlert
          modalTitle={noticeModalInfo?.title}
          modalContent={noticeModalInfo?.content}
          buttonText={noticeModalInfo?.buttonText || '확인'}
          leftButtonText={noticeModalInfo?.leftButtonText ?? '취소'}
          buttonLink={noticeModalInfo?.onClick}
          showModal
          noButton={noticeModalInfo?.noButton}
          onlyOneButton={noticeModalInfo?.onlyOneButton}
          setShowModal={setOpenNoticeModal}
        />
      )}
    </div>
  )
}
export default observer(gifticonItemSection)
