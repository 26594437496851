import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'

import ConfirmAlert from '../../components/atoms/confirmAlert'
import {
  Close,
  DailyCheckInQuizHint,
  DailyCheckInQuizLogo,
} from '../../images/dailyCheckIn'
import UserStore from '../../stores/UserStore'
import backendApis from '../../utils/backendApis'
import DailyCheckInScreenRewardReceiveModal from './DailyCheckInScreenRewardReceiveModal'

const DailyCheckInQuizModal = observer(
  ({ visible, close, quizData, afterSuccessQuiz }) => {
    const [userAnswer, setUserAnswer] = useState('')
    const [wrongAnswer, setWrongAnswer] = useState(false)
    const [openRewardModal, setOpenRewardModal] = useState(false)
    const [openWrongAnswerAlert, setOpenWrongAnswerAlert] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)
    const [buttonDisable, setButtonDisable] = useState(false)

    async function checkDailyCheckInQuizAnswer(TYPE, quizName, userAnswer) {
      const result = await backendApis.checkDailyCheckInQuizAnswer(
        TYPE,
        quizName,
        userAnswer,
      )
      if (result?.status === 200) {
        if (result?.result.msg === 'correct') {
          setOpenRewardModal(true)
          const done = await backendApis.logDailyCheckInReward(
            'quiz',
            quizData.quizReward,
            false,
            true,
          )
          if (done?.status === 200) {
            afterSuccessQuiz()
            UserStore.setAccumulatedPoints(
              UserStore.accumulatedPoints + quizData.quizReward,
            )
          }
        } else {
          setUserAnswer('')
          setWrongAnswer(true)
          setOpenWrongAnswerAlert(true)
        }
      } else {
        console.log('error', result)
      }
    }

    return (
      <div>
        {/* 딤드 배경 */}
        <div
          className='fixed top-0 left-0 z-10 flex items-center justify-center w-full h-full bg-gray-800 opacity-70'
          onClick={close}
        />

        {/* 모달 상자 */}
        <div
          style={{ left: '8%', top: '20%', borderRadius: 8, height: 440 }}
          className='fixed z-20 w-5/6 bg-white'
        />
        <button
          type='button'
          className='fixed z-30 p-1 bg-gray-300 rounded-full'
          style={{ left: '80%', top: '22%' }}
          onClick={close}
        >
          <Close className='w-6 h-6' />
        </button>

        {/* 모달 내용 + 받기 버튼 */}
        <div
          style={{ left: '12%', top: '24%', width: '76%', height: '40%' }}
          className='fixed z-30 flex flex-col justify-between'
        >
          <div>
            <DailyCheckInQuizLogo className='w-16 h-full' />
            <div className='flex flex-col items-center pb-8 mx-4 text-base font-bold'>
              {quizData?.quizContent}
            </div>

            <DailyCheckInQuizHint className='w-16 h-full' />
            <div className='flex flex-col items-center pb-10 text-sm font-bold'>
              {quizData?.quizHint}
            </div>
          </div>
          <div className='flex flex-col items-center pt-3'>
            <button
              type='button'
              onClick={() => {
                // Linking.openURL('https://alwayz.co/')
              }}
              className='flex mb-2 text-xs font-bold text-red-500 border-b-2 border-red-500'
              style={{}}
            >
              Tip. 홈화면 위에 힌트가 있어요!
            </button>
            <input
              type='text'
              placeholder='답변 입력'
              value={userAnswer || ''}
              onChange={(e) => setUserAnswer(e.target.value)}
              className={
                wrongAnswer
                  ? 'my-2 block w-48 rounded-md border-gray-300 shadow-sm border-red-500 focus:border-black ring-red-500 focus:ring-black sm:text-sm'
                  : 'my-2 block w-48 rounded-md border-gray-300 shadow-sm focus:border-black focus:ring-black sm:text-sm'
              }
            />
            <button
              type='button'
              disabled={buttonDisable}
              onClick={async () => {
                setButtonDisable(true)
                await checkDailyCheckInQuizAnswer(
                  'ey-daily-check-in-220410-v1',
                  quizData?.quizName,
                  userAnswer,
                )
                setButtonDisable(false)
              }}
              className='flex flex-col items-center self-center py-3 text-base font-bold text-white bg-red-500 w-60 rounded-2xl'
            >
              <div>입력 완료</div>
            </button>
          </div>
        </div>
        {openRewardModal && (
          <DailyCheckInScreenRewardReceiveModal
            visible={openRewardModal}
            close={() => {
              setIsSuccess(false)
              close()
            }}
            rewardAmountForDisplay={quizData.quizReward}
            isConsecutiveBonusAdded={false}
            consecutiveBonus={1}
          />
        )}
        {openWrongAnswerAlert && (
          <ConfirmAlert
            modalTitle='올퀴즈'
            modalContent='정답이 아니에요. 다시 시도해보세요!'
            buttonText='확인'
            buttonLink={() => setOpenWrongAnswerAlert(false)}
            showModal
            setShowModal={setOpenWrongAnswerAlert}
          />
        )}
      </div>
    )
  },
)

export default DailyCheckInQuizModal
