/* eslint-disable no-nested-ternary */
// components/toon/BottomSection.js
import { faCommentDots } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback, useEffect, useState } from 'react'

import THBottomSheetStore from '../../stores/treasureHunt/THBottomSheetStore'
import backendApis from '../../utils/backendApis'
import ChapterHandler from './ChapterHandler'

const BottomSection = ({
  currentChapter,
  previousChapter,
  nextChapter,
  toonInfo,
  chapterInfo,
  guideOn,
  viewLog,
  rentPeriod,
}) => {
  const [liked, setLiked] = useState(false)
  const [animate, setAnimate] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      const viewLog = await backendApis.loadToonViewLog(toonInfo?._id)
      if (viewLog?.status === 200) {
        if (
          viewLog?.data?.likedChapterData?.some(
            (id) => id.toString() === chapterInfo._id,
          )
        ) {
          setLiked(true)
        }
      }
    }
    fetchData()
  }, [])

  const handleLikeButtonClick = useCallback(async () => {
    if (!liked) {
      setAnimate(true)
      setTimeout(() => {
        setAnimate(false)
      }, 1500)
    }
    try {
      // API 호출 시 현재 liked 상태의 반대 값을 전달
      await backendApis.logToonChapterLike(
        toonInfo?._id,
        chapterInfo._id,
        !liked, // 현재 liked의 반대 값을 전달
      )
      // API 호출이 성공적으로 완료된 후 liked 상태 업데이트
      setLiked((prevLiked) => !prevLiked)
    } catch (error) {
      console.error('Error toggling like:', error)
    }
  }, [liked, toonInfo, chapterInfo])

  const handleCommentButtonClick = useCallback(async () => {
    THBottomSheetStore.setType({
      type: 'toonComment',
      config: {
        title: `댓글(${chapterInfo?.commentCount ?? '0'})`,
        toonInfo,
        chapterInfo,
      },
    })
  }, [chapterInfo, toonInfo])

  return (
    <section
      className={`flex w-full fixed bottom-0 left-0 right-0 bg-[#000000ba] px-4 py-2 shadow-md z-10 ${
        guideOn
          ? 'opacity-100 animate-slideUp'
          : 'animate-slideDownFade opacity-0'
      }`}
      onClick={(e) => e.stopPropagation()} // 이벤트 버블링 방지
    >
      <button
        type='button'
        className='w-12 h-12 flex items-center justify-center'
        onClick={handleLikeButtonClick}
      >
        <img
          src={
            !liked
              ? '/images/toon/toonHeart.png'
              : '/images/toon/toonHeartFilled.png'
          }
          alt={chapterInfo?.title}
          className={`w-8 h-8 ${animate ? 'animate-shake' : ''}`}
        />
      </button>
      <button
        type='button'
        className='relative w-12 h-12 mx-4 flex items-center justify-center rounded-xl font-semibold'
        onClick={handleCommentButtonClick}
        style={{ display: guideOn ? 'flex' : 'none' }}
      >
        <FontAwesomeIcon
          icon={faCommentDots}
          size='2xl'
          style={{ color: '#ffffff' }}
        />
        <div className='ml-2 w-[10vw] text-center text-[4vw] text-white'>
          {chapterInfo?.commentCount
            ? chapterInfo?.commentCount > 99
              ? '99+'
              : chapterInfo?.commentCount
            : ''}
        </div>
      </button>
      <div className='flex-grow flex justify-end z-10'>
        <ChapterHandler
          currentChapter={currentChapter}
          previousChapter={previousChapter}
          nextChapter={nextChapter}
          toonInfo={toonInfo}
          rentPeriod={rentPeriod}
          isBottomSection
          guideOn={guideOn}
          viewLog={viewLog}
        />
      </div>
    </section>
  )
}

export default BottomSection
