import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import TreasureHuntAction from '../../../actions/TreasureHuntAction'
import useThrottle from '../../../hooks/useThrottle'
import AuthStore from '../../../stores/AuthStore'
import THUserStore from '../../../stores/treasureHunt/THUserStore'
import { isEffective } from '../../../utils/utility'
import THImageText from '../THImageText'
import TreasureHuntTimer from '../TreasureHuntTimer'

const FindButton = observer(
  ({ clickable = false, onDisabledClick = () => {}, onClick = () => {} }) => {
    const throttledOnClick = useThrottle() // Adjust the throttle delay as needed (in milliseconds)

    return (
      <div
        id='THCTAButton'
        className='px-4 mb-4 relative'
        onClick={() => {
          if (clickable) {
            throttledOnClick(onClick, 1000)
          } else {
            throttledOnClick(onDisabledClick, 1000)
          }
        }}
      >
        {isEffective(THUserStore?.searchButtonEnforceEndAt) ? (
          <>
            <img
              className='w-full '
              src={`../images/treasureHunt/findButton/enhanced/${
                clickable ? 'enabled' : 'disabled'
              }.png`}
              alt='findButton'
            />
            <div className='absolute -bottom-[4.5vw] w-[22vw] left-1/2 -translate-x-1/2 font-bold bg-[#292929] rounded-full whitespace-nowrap text-[#fff] border-[1px] border-[#292929] flex justify-center items-center'>
              <TreasureHuntTimer
                style={{
                  color: '#FFD252',
                  fontSize: '3.5vw',
                }}
                format='number'
                expiryTimestamp={moment(
                  THUserStore?.searchButtonEnforceEndAt,
                ).toDate()}
                onExpire={() => {
                  TreasureHuntAction.initTreasureHunt(AuthStore?.token, {
                    routeParams: {},
                    codepushVersion: AuthStore?.codepushVersion,
                  })
                }}
              />
            </div>
          </>
        ) : (
          <>
            <img
              className='w-full '
              src={`../images/treasureHunt/findButton/${
                clickable ? 'enabled' : 'disabled'
              }.png`}
              alt='findButton'
            />
            <div className='absolute -bottom-[4.5vw] pr-[2.5vw] pl-1 left-1/2 -translate-x-1/2 font-bold bg-white rounded-full whitespace-nowrap text-[#595864] border-[1px] border-[#FF847D] flex justify-center items-center'>
              <THImageText text='<energy>x1' fontSize={3} />
            </div>
          </>
        )}
      </div>
    )
  },
)

export default FindButton
