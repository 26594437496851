import commaNumber from 'comma-number'
import React, { useEffect, useState } from 'react'
import { FiChevronLeft } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import { useSearchParam } from 'react-use'

import LoadingIndicator from '../components/atoms/loadingIndicator'
import ToonModal from '../components/templates/ToonModal'
import AltoonUserStore from '../stores/AltoonUserStore'
import AuthStore from '../stores/AuthStore'
import backendApis from '../utils/backendApis'

/**
 * 올툰 열람권 상품 상세페이지
 */
const AltoonItemPage = () => {
  const itemId = useSearchParam('itemId')
  const navigate = useNavigate()
  const [itemInfo, setItemInfo] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [openTicketModal, setOpenTicketModal] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      const itemInfo = await backendApis.loadTicketItemInfo(itemId)
      setItemInfo(itemInfo?.data)
    }
    window.ReactNativeWebView?.postMessage(
      JSON.stringify({
        type: 'allowiPhoneGesture',
        boolean: false,
      }),
    )
    setIsLoading(true)
    if (
      AltoonUserStore?.dailyMission?.firstPurchase?.status === 'finish' &&
      itemId === '65e0160e3401440ed9cecde3'
    ) {
      setOpenTicketModal(true)
    }
    fetchData()
    setIsLoading(false)
  }, [])

  const HeaderComponent = () => {
    return (
      <section className='fixed w-full h-16 bg-white/10 z-10 py-2 flex flex-row items-center justify-between overflow-hidden font-bold'>
        <button
          type='button'
          className='px-3 py-2 whitespace-nowrap'
          onClick={async () => {
            navigate(-1)
          }}
        >
          <FiChevronLeft className='w-10 h-10 bg-gray-100 rounded-full p-1' />
        </button>
      </section>
    )
  }

  const ItemInfoSection = ({ itemInfo }) => {
    return (
      <div>
        <img
          className='w-[100vw] h-[100vw] mx-auto mb-4'
          alt='main'
          src={`${itemInfo?.mainImageUri}`}
        />
        <div className='px-4'>
          <div className='flex flex-row text-[4vw]'>
            <div className='font-bold mr-1'>
              {Math.floor(
                (1 -
                  itemInfo?.teamPurchasePrice /
                    itemInfo?.individualPurchasePrice) *
                  100,
              ) || 0}
              %
            </div>
            <div className='text-gray-600 line-through'>
              {commaNumber(itemInfo?.individualPurchasePrice) || 0}원
            </div>
          </div>
          <div className='font-bold text-[5vw]'>
            {commaNumber(itemInfo?.teamPurchasePrice) || 0}원
          </div>
          <div className='py-2 text-[4.5vw]'>
            {itemInfo?.itemTitle || '상품명'}
          </div>
          <div className='flex flex-row py-2 w-[90vw] border-t border-[#f5f5f5]'>
            <div className='w-[8vw] mr-2 text-gray-500 text-[3.6vw] mb-2'>
              배송
            </div>
            <div className='w-full '>
              <div className='text-red-500 text-[3.6vw] font-bold mb-2'>
                모든 상품 무료배송
              </div>
              <div className='flex flex-row py-4 pl-4 rounded bg-gray-100 text-[3.2vw]'>
                올툰 코인: <div className='ml-1 font-bold'>즉시 지급</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const ItemDetailSection = ({ itemInfo }) => {
    return (
      <div className='py-2 text-[3.8vw] font-bold'>
        <div className='px-4 py-2'>상품정보</div>
        <img
          className='w-full h-full mx-auto mb-4'
          alt='ticket'
          src={`${itemInfo?.detailImageUri}`}
        />
      </div>
    )
  }

  const BottomOrderBar = () => {
    return (
      <div className='fixed bottom-0 px-4 py-2 w-full h-[18vw] flex flex-row justify-center bg-white border-t border-gray-200'>
        <button
          type='button'
          className='py-2 flex flex-col items-center justify-center w-[90vw] bg-red-500 rounded text-white text-center text-[4vw] font-bold'
          onClick={() => {
            if (isLoading) return
            setIsLoading(true)
            navigate(
              `/altoon-ticket-purchase?token=${AuthStore?.token}&itemId=${itemId}`,
            )
            setIsLoading(false)
          }}
        >
          <div className='-mb-[0.5vw]'>
            {commaNumber(itemInfo?.teamPurchasePrice) || 0}원
          </div>
          <div>구매하기</div>
        </button>
      </div>
    )
  }

  return (
    <div className=''>
      <HeaderComponent />
      {itemInfo && itemInfo?.mainImageUri && (
        <ItemInfoSection itemInfo={itemInfo} />
      )}
      <div className='w-full my-1 h-2 bg-gray-100' />
      {itemInfo?.detailImageUri && <ItemDetailSection itemInfo={itemInfo} />}

      <BottomOrderBar />
      {isLoading && <LoadingIndicator isLoading={isLoading} />}
      {openTicketModal && (
        <ToonModal
          modalName='ticketPurchased'
          setOpenTicketModal={setOpenTicketModal}
        />
      )}
    </div>
  )
}

export default AltoonItemPage
