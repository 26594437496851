import React, { useEffect, useRef, useState } from 'react'
import Slider from 'react-slick'
import { uuid } from 'short-uuid'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

import backendApis from '../../utils/backendApis'
import AlwayzImage from '../atoms/AlwayzImage'
import AuthorCommentSection from './AuthorCommentSection'
import BottomSection from './BottomSection'
import ChapterHandler from './ChapterHandler'
import ItemConnectingSection from './ItemConnectionSection'
import RatingComponent from './RatingComponent'
import ToonCommentSection from './toonCommentSection'

function AltoonHorizontalReaderPage({
  contents,
  currentChapter,
  previousChapter,
  nextChapter,
  toonInfo,
  chapterInfo,
  guideOn,
  setGuideOn,
  handleScreenTap,
  viewLog,
  direction,
}) {
  const [slideIndex, setSlideIndex] = useState()
  const isRtl = direction === 'rtl'

  let sliderRef = useRef(null)
  const settings = {
    dots: false,
    infinite: false,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    rtl: isRtl,
    beforeChange: (current, next) => {
      setSlideIndex(next)
    },
  }

  const handleTapToSwipe = (e) => {
    const rect = e.target.getBoundingClientRect()
    const x = e.clientX - rect.left // x position within the element.
    const thirdWidth = rect.width / 3

    // 좌 1/3, 왼쪽 스와이프
    if (slideIndex !== (isRtl ? 0 : contents?.length)) {
      if (x < thirdWidth) {
        sliderRef.slickPrev()
        if (guideOn) setGuideOn(false)
      } else if (x < 2 * thirdWidth) {
        // 중앙 2/3, 가이드 보기/숨기기
        handleScreenTap()
      } else {
        // 우 3/3, 오른쪽 스와이프
        sliderRef.slickNext()
        if (guideOn) setGuideOn(false)
      }
    }
  }

  useEffect(() => {
    // 마지막 툰 컷에서 다 봤다고 로깅
    if (slideIndex === (isRtl ? 0 : contents?.length - 1)) {
      backendApis.logToonAction('AltoonReaderPage', 'reachedEnd', {
        toonId: toonInfo._id,
        chapterId: chapterInfo._id,
      })
      backendApis.logToonActionForAlfarmMission(toonInfo._id, chapterInfo._id)
    }

    // 마지막 슬라이드에서 가이드 숨기기
    if (guideOn && slideIndex === (isRtl ? 0 : contents?.length)) {
      setGuideOn(false)
    }
  }, [slideIndex])

  useEffect(() => {
    if (isRtl) {
      setSlideIndex(contents?.length)
      if (sliderRef && sliderRef.slickGoTo) {
        sliderRef.slickGoTo(contents?.length, true)
      }
    } else {
      setSlideIndex(0)
      if (sliderRef && sliderRef.slickGoTo) {
        sliderRef.slickGoTo(0, true)
      }
    }
  }, [contents, toonInfo])

  return (
    <div className='slider-container w-[100vw]' onClick={handleTapToSwipe}>
      <Slider
        ref={(slider) => {
          sliderRef = slider
        }}
        {...settings}
        key={contents?.length}
      >
        {contents?.map((content) => (
          <div
            key={uuid()}
            className='flex justify-center items-center h-screen'
          >
            <AlwayzImage
              src={content}
              alt={`content-${uuid()}`}
              className='w-full h-full object-contain'
              lazy
              fit='contain'
            />
          </div>
        ))}
        <div className='h-screen overflow-y-scroll'>
          <button type='button' className='text-left' onClick={handleScreenTap}>
            <AuthorCommentSection
              author={toonInfo?.author}
              comment={chapterInfo?.authorComment}
            />
          </button>
          {toonInfo?.connectingItemId && (
            <ItemConnectingSection
              connectingItemId={toonInfo?.connectingItemId}
            />
          )}
          {nextChapter && (
            <ChapterHandler
              currentChapter={currentChapter}
              nextChapter={nextChapter}
              toonInfo={toonInfo}
              viewLog={viewLog}
            />
          )}
          <RatingComponent
            toonId={toonInfo?._id}
            toonInfo={toonInfo}
            chapterInfo={chapterInfo}
          />
          <ToonCommentSection toonInfo={toonInfo} chapterInfo={chapterInfo} />
        </div>
      </Slider>
      <div
        className={`flex justify-center items-center fixed bottom-16 left-0 right-0 bg-[#000000ba] px-4 py-3 ${
          guideOn
            ? 'opacity-100 animate-slideUp'
            : 'opacity-0 animate-slideDownFade'
        }`}
        style={{ display: guideOn ? 'flex' : 'none' }}
      >
        {isRtl ? (
          <></>
        ) : (
          <input
            className='w-full appearance-none h-1.5 bg-white rounded-full'
            onChange={(e) => {
              sliderRef.slickGoTo(e.target.value, true)
            }}
            value={slideIndex}
            type='range'
            min={0}
            max={contents?.length - 1}
            style={{
              backgroundImage: `linear-gradient(90deg, #f8323e ${
                (slideIndex * 100) / (contents?.length - 1)
              }%, #EDF2F7 ${(slideIndex * 100) / (contents?.length - 1)}%)`,
            }}
            onClick={(e) => e.stopPropagation()} // 이벤트 버블링 방지
          />
        )}
        <div className='flex w-1/5 px-[2vw] text-white'>
          <strong className='mr-1'>
            {isRtl
              ? Math.min(contents?.length, contents?.length - slideIndex + 1)
              : slideIndex >= contents?.length - 1
              ? contents?.length
              : slideIndex + 1}
          </strong>
          {' / '}
          {contents?.length}
        </div>
      </div>
      <BottomSection
        currentChapter={currentChapter}
        previousChapter={previousChapter}
        nextChapter={nextChapter}
        toonInfo={toonInfo}
        chapterInfo={chapterInfo}
        guideOn={guideOn}
        viewLog={viewLog}
      />
    </div>
  )
}

export default AltoonHorizontalReaderPage
