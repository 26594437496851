class DailyCheckInAction {
  showRewardedAd() {
    window?.ReactNativeWebView?.postMessage?.(
      JSON.stringify({
        type: 'showRewardedAd',
        data: { adType: 'rewarded', placement: 'DAILYCHECKIN_MISSION_REWARD' },
      }),
    )
  }
}

export default new DailyCheckInAction()
