import commaNumber from 'comma-number'
import moment from 'moment'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'

import UserStore from '../../../stores/UserStore'
import backendApis from '../../../utils/backendApis'
import ConfirmAlert from '../../atoms/confirmAlert'
import ImageAtom from '../../atoms/imageAtom'

let buttonPressed = false

const DrawGifticonItemComponent = ({
  drawInfo,
  empty = false,
  imgStyle = {},
  triggerFetch = 0,
  setTriggerFetch = () => {},
}) => {
  const itemInfo = {
    ...drawInfo?.targetGifticon,
    teamPurchasePrice: 0,
  }
  const drawId = drawInfo?._id
  const disabled = false
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [openNoticeModal, setOpenNoticeModal] = useState(false)
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [noticeModalInfo, setNoticeModalInfo] = useState({
    title: '',
    content: '',
    onClick: () => {},
  })
  const [selectedRewardInfo, setSelectedRewardInfo] = useState({
    rewardType: '',
    rewardAmount: '',
    rewardDetail: {},
  })

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const handleOnClick = ({ isUpgrade }) => {
    if (isUpgrade) {
      navigate(`/check-in-gifticon-upgrade?drawId=${drawId}`)
    } else {
      setSelectedRewardInfo({
        rewardType: 'gifticon',
        rewardAmount: itemInfo?.teamPurchasePrice,
        rewardDetail: itemInfo,
      })
      setOpenConfirmModal(true)
    }
  }

  const LoadingIndicator = () => {
    return (
      <div>
        <div className='fixed inset-0 z-10 w-full h-full bg-gray-800 opacity-70' />
        <div style={{ left: '40%', top: '40%' }} className='fixed z-20'>
          <ClipLoader
            color='#ff3e3e'
            loading={isLoading}
            size={80}
            aria-label='Loading Spinner'
            data-testid='loader'
          />
        </div>
      </div>
    )
  }

  const EmptyComponent = () => {
    return (
      <div className='flex flex-col items-start justify-center flex-1 w-full p-1'>
        <div
          className='bg-white rounded-lg'
          style={{
            width: '98%',
            height: '98%',
            aspectRatio: 1,
          }}
        />
        <div
          className='bg-white rounded-lg'
          style={{
            height: 28,
            width: '90%',
            marginTop: 10,
            marginBottom: 20,
            paddingBottom: 6,
          }}
        />
      </div>
    )
  }

  const ItemImageComponent = ({ itemInfo }) => {
    return (
      <div className='flex justify-center mt-[1.6vw]'>
        <ImageAtom
          src={itemInfo?.mainImageUri}
          className='object-cover rounded-lg border-[1vw] border-[#FFD1B6] my-[2vw]'
          style={{
            width: '40vw',
            height: '40vw',
            borderRadius: 12,
          }}
        />
      </div>
    )
  }

  const ItemTextInfoComponent = ({ itemInfo }) => {
    return (
      <div className='flex flex-col text-left pt-[2vw] w-full'>
        <div className='w-full overflow-hidden leading-tight line-clamp-2 text-ellipsis text-start text-[3.6vw] font-bold '>
          {itemInfo?.itemTitle ?? '상품명'}
        </div>
        <div className='text-[3vw] mt-[1vw] text-[#B1B3B5]'>
          <div className='text-[#4d4e4f] '>
            정가: {commaNumber(itemInfo?.individualPurchasePrice) || 0}원
          </div>
          유효기간: {moment(drawInfo?.expiresAt).format('YY년 MM월 DD일')}
        </div>
      </div>
    )
  }

  const ExchangeButtonComponent = () => {
    const styleParsed = disabled
      ? {
          backgroundColor: 'lightgray',
          color: 'gray',
        }
      : {
          backgroundColor: '#FF5858',
          color: 'white',
        }
    return (
      <button
        type='button'
        style={{ ...styleParsed }}
        className='w-[100%] py-[3vw] my-[2vw] rounded-xl font-bold text-[3.6vw]'
        onClick={() => {
          handleOnClick({ isUpgrade: false })
        }}
        disabled={disabled}
      >
        {disabled ? '포인트가 부족해요' : `교환 신청하기`}
      </button>
    )
  }

  const UpgradeButtonComponent = () => {
    // 발송신청하면 강화 불가해야함
    return (
      <button
        type='button'
        style={{ fontSize: '3.6vw' }}
        className={
          disabled
            ? 'w-[100%] px-4 py-3 mb-[8vw] rounded-xl bg-gray-300 text-gray-500 font-bold'
            : 'w-[100%] px-4 py-3 mb-[8vw] rounded-xl border border-[#FF5858] bg-white text-[#FF5858] font-bold'
        }
        onClick={() => {
          handleOnClick({ isUpgrade: true })
        }}
        disabled={disabled}
      >
        강화하기
      </button>
    )
  }

  if (empty) {
    return <EmptyComponent />
  }

  return (
    <>
      <div
        aria-hidden='true'
        className='flex items-center justify-center w-full'
        disabled={disabled}
      >
        <div className='relative flex flex-col items-center justify-center flex-1 mt-[2vw]'>
          <div
            className='flexCol h-full relative w-[80%]'
            style={{
              ...imgStyle,
            }}
          >
            <ItemImageComponent itemInfo={itemInfo} />
            <ItemTextInfoComponent itemInfo={itemInfo} />
            <ExchangeButtonComponent />
            <UpgradeButtonComponent />
          </div>
        </div>
      </div>
      {openConfirmModal && (
        <ConfirmAlert
          modalTitle='포인트 교환'
          modalContent={[
            `${commaNumber(
              selectedRewardInfo?.rewardAmount,
            )}포인트를 사용하여 교환하시겠어요?`,
            <br key='line-break1' />,
            <div className='mt-4 text-xs' key='itemInfo'>
              상품명:{' '}
              {selectedRewardInfo?.rewardDetail?.itemTitle.length > 24
                ? `${selectedRewardInfo?.rewardDetail?.itemTitle.slice(
                    0,
                    24,
                  )}...`
                : selectedRewardInfo?.rewardDetail?.itemTitle}{' '}
              <br />
              판매가:{' '}
              {commaNumber(
                selectedRewardInfo?.rewardDetail?.individualPurchasePrice,
              )}
              원
              <br />
              할인가:{' '}
              {commaNumber(selectedRewardInfo?.rewardDetail?.teamPurchasePrice)}
              P
              <br />
              유효기간 : 29일 ~ 30일 (상품별 상이)
              <br /> 발송방법: 올웨이즈에 가입한 휴대폰번호로 발송
            </div>,
          ]}
          buttonText='확인'
          buttonLink={async () => {
            if (buttonPressed) return
            buttonPressed = true
            setIsLoading(true)
            setOpenConfirmModal(false)

            const result = await backendApis.recordReceivedGifticon({
              ...selectedRewardInfo,
              gifticonType: 'drawn',
              drawId,
            })
            if (result?.status === 200) {
              UserStore.setAccumulatedPoints(
                UserStore?.accumulatedPoints -
                  parseInt(selectedRewardInfo?.rewardAmount, 10),
              )
              setNoticeModalInfo({
                title: '교환 성공!',
                content:
                  '성공적으로 교환되었습니다. 2~3영업일 내로 최대한 빠르게 발송해드릴게요!',
                buttonText: '확인',
                onlyOneButton: true,
                onClick: () => {
                  setOpenNoticeModal(false)
                  scrollToTop()
                  setNoticeModalInfo({})
                  setTriggerFetch(triggerFetch + 1)
                },
              })
            } else if (result?.status === 202 || result?.status === 401) {
              setNoticeModalInfo({
                title: '안내',
                content:
                  '오늘 이미 상품을 교환하셨어요. \n 상품은 하루에 한번만 교환할 수 있어요',
                onlyOneButton: true,
                onClick: () => {
                  setOpenNoticeModal(false)
                  setNoticeModalInfo({})
                },
              })
            } else if (
              result?.status === 201 ||
              result?.status === 402 ||
              result?.status === 400
            ) {
              setNoticeModalInfo({
                title: '안내',
                content:
                  '고객 정보를 확인할 수 없어요. 고객센터에 문의해주세요.',
                onlyOneButton: true,
                onClick: () => {
                  setOpenNoticeModal(false)
                  setNoticeModalInfo({})
                },
              })
            } else {
              setNoticeModalInfo({
                title: '안내',
                content:
                  '인터넷 연결이 불안정하여 교환에 실패했어요. 다음에 다시 시도해 주세요',
                onlyOneButton: true,
                onClick: () => {
                  setOpenNoticeModal(false)
                  setNoticeModalInfo({})
                },
              })
            }
            setOpenNoticeModal(true)

            setSelectedRewardInfo({})
            buttonPressed = false
            setIsLoading(false)
          }}
          showModal
          setShowModal={setOpenConfirmModal}
        />
      )}
      {openNoticeModal && (
        <ConfirmAlert
          modalTitle={noticeModalInfo?.title}
          modalContent={noticeModalInfo?.content}
          buttonText={noticeModalInfo?.buttonText || '확인'}
          buttonLink={noticeModalInfo?.onClick}
          showModal
          onlyOneButton={noticeModalInfo?.onlyOneButton}
          setShowModal={setOpenNoticeModal}
        />
      )}
      {isLoading && <LoadingIndicator />}
    </>
  )
}

export default DrawGifticonItemComponent
